import get from 'lodash/get';
// import {BOATS_SRP_PATH, BRANDS_ID, US_COUNTRIES, US_STATES} from '../../../constants/boats';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {BOATS_SRP_PATH, BRANDS_ID, US_COUNTRIES, US_STATES} from '../../constants/boats';

const mergeFacetsToKV = (facets, kv) => {
  let result = kv.map((item) => {
    let expected = facets.filter((facet) => {
      return item.value === facet.value;
    });
    return {
      ...item,
      count: get(expected, '[0].count', 0)
    };
  });
  return result;
};

const getBasePath = (searchPage, params) => {
  if (searchPage === BRANDS_ID && 'oem' in params) {
    return BOATS_SRP_PATH;
  }
  return '';
};

// TODO: remove lodash get, unnecessary and ugly function.
export const parseLocationFilters = (props, data, dynamicUrlState) => {
  const stateCity = get(props, 'facets.stateCity', []);
  const cityZip = get(props, 'facets.cityPostalCode', []);
  const states = mergeFacetsToKV(get(props, 'facets.state', []), US_STATES);
  const country = mergeFacetsToKV(get(props, 'facets.country', []), US_COUNTRIES);
  const selectedState = data.state || dynamicUrlState.state;
  const selectedCities = data.city || dynamicUrlState.city;
  const selectedZip = data.zip || dynamicUrlState.zip;
  return {stateCity, cityZip, states, country, selectedState, selectedCities, selectedZip};
};

export const parseMakeModelFilters = (props, data, dynamicUrlState, currentStateInURL) => {
  const makes = get(props, 'facets.make', []);
  const models = get(props, 'facets.makeModel', []);
  const selectedMake = models.length === 1 ? models[0].value : '';
  const modelRanges = models.length === 1 ? models[0].modelRange : [];
  const makeModel = data.makeModel || dynamicUrlState.makeModel;
  const oem = data.oem || dynamicUrlState.oem;
  const makeModelsForFilters = !!oem && !isEmpty(oem) ? oem : makeModel;
  const paramsWithoutModelRange = omit({ ...currentStateInURL }, 'modelRange');
  return {makes, models, selectedMake, modelRanges, makeModel, makeModelsForFilters, paramsWithoutModelRange};
};

export const parseFuelTypesFilters = (props, data, dynamicUrlState) => {
  const fuelTypeFacets = get(props, 'facets.fuelType', []);
  const fuelTypes = sortBy(fuelTypeFacets, 'name');
  const selectedFuelTypes = data.fuelType || dynamicUrlState.fuelType || '';
  return {fuelTypes, selectedFuelTypes};
};

export const parseClassesFilters = (props, data, dynamicUrlState) => {
  const classfacets = get(props, 'facets.class', []);
  const multiFacetedBoatTypeClass = data.multiFacetedBoatTypeClass || dynamicUrlState.multiFacetedBoatTypeClass;
  return {
    classfacets, multiFacetedBoatTypeClass
  };
};

export const parsePriceFilters = (props, data, dynamicUrlState) => {
  const price = data.price || {};
  const priceRevised = get(props, 'facets.priceRevisedSince', []);
  const selectedPriceRevisedSince = dynamicUrlState.priceRevisedSince;
  return {price, priceRevised, selectedPriceRevisedSince};
};

export const parsePageProps = (props, data = {}, currentStateInURL = {}) => {
  const {position} = props;
  const isMobile = position === 'mobile';
  const isBranded = currentStateInURL.oem && !isEmpty(currentStateInURL.oem);
  const basePath = getBasePath(props.searchPage, currentStateInURL);
  const filterTagsTriggerProps = isMobile;
  const year = data.year || {};
  const length = data.length || {};
  return {isMobile, isBranded, basePath, filterTagsTriggerProps, year, length};
};

export const parseKeywordFilter = (props) => {
  return {
    value: get(props, 'params.keyword', '')
  };
};

export const parseBoatsSRPFilters = (props, currentStateInURL, currentParams, state) => {
  const data = state?.data;
  const dynamicUrlState = (props.mobileParams || currentStateInURL);
  const fuelTypeProps = parseFuelTypesFilters(props, data, dynamicUrlState);
  const priceProps = parsePriceFilters(props, data, dynamicUrlState);
  const pageProps = parsePageProps(props, data, currentStateInURL);
  const selectedEngines = dynamicUrlState.engine;
  const selectedForSale = data.forSale || dynamicUrlState.forSale;
  const classProps = parseClassesFilters(props, data, dynamicUrlState);
  const locationProps = parseLocationFilters(props, data, dynamicUrlState);
  const makeModelProps = parseMakeModelFilters(props, data, dynamicUrlState, currentStateInURL);
  const keywordProps = parseKeywordFilter(props);
  return {fuelTypeProps, priceProps, pageProps, selectedEngines, selectedForSale, classProps, locationProps, makeModelProps, keywordProps};
};

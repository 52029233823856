import React, { PureComponent } from 'react';
import SortSelector from '../SortSelector';
import { getDefaultParams } from '../../../utils/urlHelpers/listings';
import { getResultsCount } from '../../../utils/paginationHelper';
import get from 'lodash/get';
import { BRANDS_ID } from '../../../constants/boats';
import './styles.css';

class ResultsHeader extends PureComponent {
  render() {
    let pageParams = get(this.props, 'match.params', {});
    let params = getDefaultParams(pageParams, this.props.searchPage);
    let count = +get(this.props, 'search.count', 0);
    let cityPostalCode = get(this.props, 'facets.cityPostalCode');
    const oemDetails = this.props.oemDetails;
    const searchPage = get(this.props, 'searchPage');
    const radius = params?.radius;
    const resultsCount = this.props.isWorking
      ? 'loading...'
      : getResultsCount(
        params,
        count,
        cityPostalCode,
        searchPage,
        radius,
        oemDetails
      );
    return (
      <div className="results-header" data-e2e="srp-results-header">
        <div className="results-count" data-e2e="srp-results-count">
          {searchPage === BRANDS_ID ? <h2>{resultsCount}</h2> : resultsCount}
        </div>
        {
          !this.props.isMobile && <SortSelector {...this.props} />
        }
      </div>
    );
  }
}

export default ResultsHeader;

import React from 'react';
import Helmet from 'react-helmet';
import ScriptTag from 'react-script-tag';
import { version } from '@dmm/lib-angular-myboats/dist/version';
import { Amplify } from '@dmm/lib-react-userflow-components';
import * as utils from '../../../store/utils';
import './styles.css';

const MainContent = () => {

  /*
   * Disabled SSR here and on line 33 below to prevent the MyBoats angular app from loading and executing
   * before these environmental vars have been set.
  */
  if (!utils.isServer()) {
    window.amplifyLibrary = Amplify;
    window.env = {
      siteHost: process.env.REACT_APP_MYBOATS_SITE_HOST,
      publicApiEndpoint: process.env.REACT_APP_PUBLIC_API_ENDPOINT,
      publicApiKey: process.env.REACT_APP_PUBLIC_API_KEY,
      loginLanding: '/login-landing'
    };
  }

  return (
    <div className="myBoats">
      <Helmet>
        <link rel="stylesheet" href={`/myboats/resource/app.${version}.css`}/>
      </Helmet>
      <app>Loading...</app>
      <ScriptTag src="//cdnjs.cloudflare.com/ajax/libs/require.js/2.1.10/require.min.js"/>
      <ScriptTag src="//ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js"/>
      {!utils.isServer() && <ScriptTag type="text/javascript" src={`/myboats/resource/app.${version}.js`}/>}
    </div>
  );
};

export default MainContent;

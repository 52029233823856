import React from 'react';
import { frontloadConnect } from 'react-frontload';
import get from 'lodash/get';
import DefaultLayout from '../../layouts/Default';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';
import {runOnce} from '../../utils/runOnceHelper';
import {withCookies} from 'react-cookie';
import { connect } from 'react-redux';

import NotFoundPage from '../NotFoundPage';
import MainContent from './MainContent';

const EditorialContent = (props) => {
  return (
    props?.errors ? (
      <NotFoundPage {...props} />
    ) : (
      <DefaultLayout {...props} pageType="EditorialPage" id={'editorial_page'}>
        <MainContent {...props} />
      </DefaultLayout>
    )
  );
};

const mapStateToProps = state => {
  return {
    articles: get(state.app, 'data.records', [{}]),
    errors: state.app.errors,
    message: state.app.message,
    statusCode: state.app.statusCode,
  };
};

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

// eslint-disable-next-line no-empty-pattern
const frontLoadConnected = frontloadConnect(async ({ dispatch, location, debug = process.env.REACT_APP_LOCAL_DEBUG }) => {
  if (utils.isServer()) {
    // dispatch action to get boat types data
    return dispatch(actions.getEditorialContent(location?.pathname));
  }

  if (actions.shouldGetData(location)) {
    // dispatch action to get boat types data
    return dispatch(actions.getEditorialContent(location?.pathname));
  }

  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  if (justFirsTime() && debug) {
    return dispatch(actions.getEditorialContent(location?.pathname));
  }

}, {
  onMount: true,
  onUpdate: true
})(EditorialContent);

export default withCookies(connect(mapStateToProps)(frontLoadConnected));

import React, {useState} from 'react';
import Modal from '../Modal';
import './styles.css';

const DealerViewAll = ({title, href, modalContent, width, height}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const closeModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const customStyles = {
    content: {
      height: `${height}%`,
      width: `${width}%`,
      overflow: 'hidden',
      padding: '0'
    },
    overlay: {
      backgroundColor: 'rgb(34 27 34 / 50%)'
    }
  };
  return (
    <>
      <div className="all-dealers" data-e2e="all-dealers">
        <a id="modalDealers" data-e2e="view-all-dealers" href={href} onClick={openModal} >View All</a>
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} portalClassName="dealer-container-modal" injectedStyles={customStyles}>
        <div className="dealer-viewall-modal" data-e2e="dealer-viewall-modal">
          <button className="contact-close" data-e2e="contact-close" onClick={closeModal}><span className="visuallyhidden">Close</span></button>
          <h2>{title}</h2>
          <div className="dealer-viewall-content" data-e2e="dealer-viewall-content">{modalContent}</div>
        </div>
      </Modal>

    </>
  );
};

export default DealerViewAll;

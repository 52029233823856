import { setProductImpression, registerProductImpressions } from '../../../store/actions/dataLayer';
import {LISTING_STANDARD} from '../../../constants/boats';
import { getTitle } from '../../../utils/listingHelper';

export const setAdCounter = (index, setReloadAd) => {
  const refreshCouner = Math.floor(index / 3) || 0;
  setReloadAd((prev) => {
    if (prev.count === refreshCouner) {
      return prev;
    }
    return { count: refreshCouner, counter: prev.counter + 1 };
  });
};

export const trackProductImpression = (index, setImpressions, sbpListings, dispatch) => {
  setImpressions((impressions) => {
    if (impressions.includes(index)) {
      return impressions;
    }
    const { id, make } = sbpListings[index];
    dispatch(setProductImpression(id, LISTING_STANDARD, index, undefined, undefined, undefined, undefined, undefined, getTitle(sbpListings[index]), make));
    dispatch(registerProductImpressions());
    impressions.push(index);
    return impressions;
  });
};

import React, { useEffect } from 'react';
import { setOemBrandProductImpression } from '../../store/actions/dataLayer';
import { BrandHeaderImage } from '@dmm/lib-react-ui-components';
import { useDispatch } from 'react-redux';

export const PayingBrandHeader = ({showHeaderPayingBrands, oemInformation}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (showHeaderPayingBrands) {
      dispatch(setOemBrandProductImpression(oemInformation.id, 'brand banner', 'brand banner', 1));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (showHeaderPayingBrands) {
    return (
      <div className="paying-brand-header-image">
        <BrandHeaderImage
          img={oemInformation.oemHeroImageUrl}
          logo={oemInformation.logoUrl}
          alt={oemInformation.name}
        />
      </div>
    );
  }
  return null;

};
export default PayingBrandHeader;

import React from 'react';
import DefaultLayout from '../../layouts/Default';
import MainContent from './MainContent';
import { likeBoat, unlikeBoat } from '../../store/actions';
import * as commonHelper from '../../utils/commonHelper';

const MyBoats = (props) => {
  if (commonHelper.windowIsDefined()) {
    window.myboatsLikeBoat = likeBoat;
    window.myboatsUnlikeBoat = unlikeBoat;
  }

  return (
    <DefaultLayout {...props} pageType="MyBoats">
      <MainContent {...props} />
    </DefaultLayout>
  );
};

export default MyBoats;


import React, {PureComponent} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setGenericEvent} from '../../store/actions/dataLayer';
import './styles.css';

class Footer extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    if (e.target && e.target.dataset && e.target.dataset.action && e.target.dataset.label) {
      this.props.setGenericEvent('navigation - footer', e.target.dataset.action, e.target.dataset.label);
    }
  }

  render() {
    const currentYear = new Date().getFullYear();
    const tracking = (action, label) => ({
      'data-action': action,
      'data-label': label
    });

    return (
      <footer id="bt-footer">
        <div className="bt-footer-social">
          <div className="bt-container">
            <div className="connect-us">
              <div className="social-caption">
                Connect with Us
              </div>
              <div className="social-item">
                <a href="https://www.youtube.com/boattrader/"
                  data-e2e="youtube"
                  data-reporting-event-category="navigation - footer"
                  data-reporting-event-action="follow us"
                  data-reporting-event-label="youtube"
                  target="_blank"
                  rel="nofollow noopener">
                  <img loading="lazy" width="50" height="50" className="social-icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/youtube-circle.svg`} alt="Youtube"/>
                </a>
                <a href="https://www.instagram.com/boattrader/"
                  data-e2e="instagram"
                  data-reporting-event-category="navigation - footer"
                  data-reporting-event-action="follow us"
                  data-reporting-event-label="instagram"
                  target="_blank"
                  rel="nofollow noopener">
                  <img loading="lazy" width="50" height="50" className="social-icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/instagram-circle.svg`} alt="Instagram"/>
                </a>
                <a href="https://www.facebook.com/BoatTrader/"
                  data-e2e="facebook"
                  data-reporting-event-category="navigation - footer"
                  data-reporting-event-action="follow us"
                  data-reporting-event-label="facebook"
                  target="_blank"
                  rel="nofollow noopener">
                  <img loading="lazy" width="50" height="50" className="social-icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/facebook-circle.svg`} alt="Facebook"/>
                </a>
                <a href="https://twitter.com/Boattrader/"
                  data-e2e="xcorp"
                  data-reporting-event-category="navigation - footer"
                  data-reporting-event-action="follow us"
                  data-reporting-event-label="twitter"
                  target="_blank"
                  rel="nofollow noopener">
                  <img loading="lazy" width="50" height="50" className="social-icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/twitter-circle.svg`} alt="Twitter"/>
                </a>
              </div>
            </div>
            <div className="device-stores">
              <div className="social-caption">
                Download the BoatTrader app
              </div>
              <div className="social-item">
                <a href="https://apps.apple.com/us/app/boat-trader-boats-for-sale/id1403420067/"
                  data-e2e="appstore"
                  data-reporting-event-category="navigation - footer"
                  data-reporting-event-action="app download"
                  data-reporting-event-label="apple app store"
                  target="_blank"
                  rel="nofollow noopener noreferrer">
                  <img loading="lazy" width="135" height="40" className="social-icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/app-store.svg`} alt="App Store"/>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.boatsgroup.boattrader"
                  data-e2e="playstore"
                  data-reporting-event-category="navigation - footer"
                  data-reporting-event-action="app download"
                  data-reporting-event-label="google play"
                  target="_blank"
                  rel="nofollow noopener noreferrer">
                  <img loading="lazy" width="135" height="40" className="social-icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/google-play.svg`} alt="Google Play"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bt-footer-links" onClick={this.handleClick.bind(this)}>
          <div className="bt-container">
            <div className="tab">
              <LazyLoadImage className="logo" src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/bt-inc-release/boattrader-logo_grey.svg`} alt="Boat Trader Logo" threshold={200}/>
            </div>
            <div className="tab">
              <input className="accordion-footer" type="checkbox" id="accordion-footer1"/>
              <label className="tab-label" data-e2e="footer-explore-bt" htmlFor="accordion-footer1">Explore Boat Trader</label>
              <div className="tab-content" data-e2e="footer-explore-bt-tab-content">
                <ul>
                  <li><a data-e2e="boats-for-sale" href="/boats/" {...tracking('explore', 'search boats')} >Boats for Sale</a></li>
                  <li><a data-e2e="outboard-motors-engines" href="/engines/" {...tracking('explore', 'search engines')} >Outboard Motors &amp; Engines</a>
                  </li>
                  <li><a data-e2e="boat-trailers" href="/browse/parts-trailers/" {...tracking('explore', 'search trailers')}>Boat Trailers</a>
                  </li>
                  <li><a data-e2e="boat-research" href="/research/" {...tracking('explore', 'resources')} >Boat Research</a></li>
                  <li><a
                    data-e2e="boating-lifestyle-calculator" href="/digital/boating-lifestyle-calculator/" {...tracking('explore', 'boating-lifestyle-calculator')}>Boating
                    Lifestyle Calculator</a></li>
                  <li><a data-e2e="about-boat-trader" href="/about/" {...tracking('explore', 'about us')}>About Boat Trader</a></li>
                </ul>
              </div>
            </div>
            <div className="tab">
              <input className="accordion-footer" type="checkbox" id="accordion-footer2"/>
              <label className="tab-label" htmlFor="accordion-footer2">Sell Your Boat</label>
              <div className="tab-content">
                <ul>
                  <li>
                    <a data-e2e="private-sellers" href="/sell/" {...tracking('sell your boat', 'private seller')} >Private Sellers</a>
                  </li>
                  <li>
                    <a data-e2e="dealers" href="/boat-dealers/" {...tracking('sell your boat', 'dealer')}>Dealers</a>
                  </li>
                  <li>
                    <a data-e2e="my-boats" href="/myboats/" {...tracking('sell your boat', 'my boats')}>My Boats</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab">
              <input className="accordion-footer" type="checkbox" id="accordion-footer3"/>
              <label className="tab-label" htmlFor="accordion-footer3">Finance</label>
              <div className="tab-content">
                <ul>
                  <li>
                    <a data-e2e="boat-loans" href="/boat-loans/" {...tracking('financing', 'financing')}>Boat Loans</a>
                  </li>
                  <li>
                    <a data-e2e="calculate-your-payments" href="/boat-loans/calculator/" {...tracking('financing', 'calculate your payments')}>Boat Loan Calculator</a>
                  </li>
                  <li>
                    <a data-e2e="refinance" href="/boat-loans/apply/refi/enter/" {...tracking('refinance', 'refinance')}>Refinance</a>
                  </li>
                  <li>
                    <a data-e2e="how-to-finance" href="/boat-loans/#boat-financing" {...tracking('financing', 'faq - how to finance')}>FAQ - How to Finance</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab">
              <input className="accordion-footer" type="checkbox" id="accordion-footer4"/>
              <label className="tab-label" htmlFor="accordion-footer4">Services</label>
              <div className="tab-content">
                <ul>
                  <li>
                    <a data-e2e="extended-service-plan" href="/services/extended-service-plan/" {...tracking('services', 'extended service plan')}>Extended Service Plan</a>
                  </li>
                  <li>
                    <a data-e2e="tire-and-wheel-plan" href="/services/tire-wheel-protection/" {...tracking('services', 'tire wheel protection')}>Tire & Wheel Plan</a>
                  </li>
                  <li>
                    <a data-e2e="gap-protection" href="/services/gap-protection/" {...tracking('services', 'gap protection')}>GAP Protection</a>
                  </li>
                  <li>
                    <a data-e2e="roadside-assistance" href="/services/roadside-assistance/" {...tracking('services', 'roadside assistance')}>Roadside Assistance</a>
                  </li>
                  <li>
                    <a data-e2e="boat-insurance" href="/services/boat-insurance/" {...tracking('services', 'insurance')}>Boat Insurance</a></li>
                  <li>
                    <a data-e2e="boat-transport" href="/services/boat-transport-shipping/" {...tracking('services', 'transport')}>Boat
                      Transport</a></li>
                  <li>
                    <a data-e2e="boat-warranty" href="/services/boat-warranty/" {...tracking('services', 'warranty')}>Boat Warranty</a></li>
                  <li>
                    <a data-e2e="boat-documentation" href="/services/boat-documentation/" {...tracking('services', 'documentation')}>Boat
                      Documentation</a>
                  </li>
                  <li>
                    <a data-e2e="boat-rental" href="/services/boat-rental/" {...tracking('services', 'rental')}>Boat Rental
                    </a>
                  </li>
                  <li>
                    <a data-e2e="become-member" href="https://www.boatsgroup.com/our-brands/boat-trader/" target="_blank" rel="noreferrer noopener" {...tracking('services', 'become a member')}>Become a Member
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bt-footer-disclaimer">
          <div className="bt-container">
            <ul className="disclaimer-links">
              <li><a data-e2e="cookies" href="https://www.boatsgroup.com/cookies-policy/" target="_blank" rel="noopener nofollow" data-wpel-link="external">Cookies</a></li>
              <li><a data-e2e="do-not-sell-personal-info" href="https://www.boatsgroup.com/privacy/" target="_blank" rel="noopener nofollow" data-wpel-link="external">Do Not Sell My Personal Information</a></li>
              <li><a data-e2e="community-guidelines" href="/community-guidelines/" data-wpel-link="internal">Community Guidelines</a></li>
              <li><a data-e2e="security-center" href="/security/" data-wpel-link="internal">Security Center</a></li>
              <li><a data-e2e="site-map" href="/sitemap/" data-wpel-link="internal">Site Map</a></li>
            </ul>
            <div className="copyright-info">
              <span className="copyright">
                  Copyright &copy; { currentYear } <a href="https://www.boatsgroup.com/" title="Boats Group" target="_blank" rel="noopener nofollow" data-wpel-link="external">Boats Group.</a> All Rights Reserved
              </span>
              <span className="add-policy">
                <a data-e2e="ad-sponsorship-policy" href="/ad-and-sponsorship/" data-wpel-link="internal">Ad and Sponsorship Policy</a>
                <span>|</span>
                <a data-e2e="adviser-agreement" href="https://www.boatsgroup.com/advertiser-agreement/" target="_blank" rel="noopener nofollow" data-wpel-link="external">Advertiser Agreement</a>
                <span>|</span>
                <a data-e2e="copyright" href="https://www.boatsgroup.com/copyright/" target="_blank" rel="noopener nofollow" data-wpel-link="external">Copyright</a>
                <span>|</span>
                <a data-e2e="privacy-policy" href="https://www.boatsgroup.com/privacy/" target="_blank" rel="noopener nofollow" data-wpel-link="external">Privacy Policy</a>
                <span>|</span>
                <a data-e2e="terms-of-use" href="https://www.boatsgroup.com/terms-of-use/" target="_blank" rel="noopener nofollow" data-wpel-link="external">Terms of Use</a>
                <span>|</span>
                <a data-e2e="ad-choices" href="https://www.networkadvertising.org/choices/">
                  AdChoices
                  <LazyLoadImage loading="lazy" width="10" height="10" className="adchoices-icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/enhance-notice.svg`} alt="DAA Icon" threshold={200}/>
                </a>
              </span>
              <div id={'teconsent'}></div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect(null, dispatch => bindActionCreators({setGenericEvent}, dispatch))(Footer);


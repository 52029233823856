import React from 'react';
// FAQSection Content
export const FAQ_SECTION_TITLE = 'FAQs About Tire & Wheel Protection for Boats';
export const FAQ_ACCORDION_CONTENT = [
  {
    title:
      'What road hazards are typically covered by tire & wheel protection for boats?',
    content: (
      <p>
        Road hazards may include potholes, debris, curbs, and other obstacles
        encountered during transportation.
      </p>
    ),
  },
  {
    title:
      'Can I transfer my tire & wheel protection to a new boat owner if I sell my boat?',
    content: (
      <p>
        In some cases, tire & wheel protection can be transferable to a new
        owner, but it&apos;s important to check the terms and conditions of the
        contract.
      </p>
    ),
  },
  {
    title: 'Does tire & wheel protection cover normal wear and tear?',
    content: (
      <p>
        Tire & wheel protection typically covers damages caused by eligible road
        hazards but does not cover normal wear and tear.
      </p>
    ),
  },
  {
    title: 'Is cosmetic wheel repair covered?',
    content: (
      <p>
        If there is cosmetic damage to your alloy wheels that is deemed
        repairable using manufacturer alloy wheel repair techniques, such as
        minor curb rash or flaking paint, they will be repaired or reconditioned
        to the fullest extent possible. In the event the wheel is not
        repairable, it will be replaced. Cosmetic alloy wheel repair or
        replacement is limited to an aggregate of $600.
      </p>
    ),
  },
];

export const FAQ_DISCLAIMER_TEXT = '*Actual rate can vary depending on loan term and APR, or Annual Percentage Rate. Pricing reflects an example of an $8,000 service contract on a $50,000 boat financed for 240 months or 20 years. Trident Funding LLC markets and sells extended warranties on behalf of 3rd party providers and your extended warranty will be between you and the provider. Coverage, deductibles, and claims payment vary depending on vehicle age, mileage, preexisting conditions and program selected. Repairs can be made by any licensed repair facility. Program(s) available in most states. Not available in California or Florida. You consent to be contacted by email, phone, and/or text once we receive your quote submission. Texas and Wisconsin Residents: Contracts administered by Dealer Admin Services, 5810 W 78th Street, Suite 300, Bloomington, MN 55439, 877-746-4917. Vehicle names, logos, brands, & other trademarks featured or referred to within Trident Funding are property of their respective trademark holders. Extended warranties may not be sold where prohibited by law.';

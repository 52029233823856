export const TRIDENT_SERVICES_CDN_URL = `${process.env.REACT_APP_CDN_URL}/boat-services/`;

export const EXTENDED_SERVICES_PLAN_URL = '/services/extended-service-plan/';
export const EXTENDED_SERVICES_PLAN_META_TITLE = 'Extended Boat Service Plans';
export const EXTENDED_SERVICES_PLAN_META_DESCRIPTION = 'Learn more about extended boat service contracts. Contact us today for protection from unexpected boat repairs.';
export const EXTENDED_SERVICES_PLAN_HERO_BACKGROUND = 'extended-service-plan-hero.png';
export const EXTENDED_SERVICES_NATION_WIDE_ICON = 'nation-wide-coverage.svg';
export const EXTENDED_SERVICES_BOAT_TOWING_ICON = 'boat-towing.svg';
export const EXTENDED_SERVICES_PEACE_OF_MIND_ICON = 'peace-of-mind.svg';
export const EXTENDED_SERVICES_REPAIR_COVERAGE_ICON = 'repair-coverage.svg';
export const EXTENDED_SERVICES_LOW_MONTHLY_COST_ICON = 'low-monthly-cost.svg';
export const EXTENDED_SERVICES_WORRY_FREE_PROTECTION_ICON = 'worry-free-protection.svg';
export const EXTENDED_SERVICES_EASILY_TRANSFERABLE_ICON = 'easily-transferable.svg';
export const EXTENDED_SERVICES_COVERAGE_FOR_DEDUCTIBLES_ICON = 'coverage-for-deductibles.svg';
export const EXTENDED_SERVICES_FAST_RELIABLE_ICON = 'fast-reliable.svg';
export const EXTENDED_SERVICES_EMERGENCY_SERVICES_ICON = 'emergency-services.svg';
export const EXTENDED_SERVICES_CONCIERGE_SUPPORT_ICON = 'concierge-support.svg';
export const EXTENDED_SERVICES_COMPREHENSIVE_PROTECTION_ICON = 'comprehensive-protection.svg';
export const EXTENDED_SERVICES_FINANCIAL_SECURITY_ICON = 'financial-security.svg';
export const EXTENDED_SERVICES_GAP_PEACE_OF_MIND_ICON = 'gap-peace-of-mind.svg';
export const EXTENDED_SERVICES_GAP_LOW_MONTHLY_COST_ICON = 'gap-low-monthly-cost.svg';

export const TIRE_AND_WHEEL_PLAN_URL = '/services/tire-wheel-protection/';
export const TIRE_AND_WHEEL_PLAN_META_TITLE = 'Tire & Wheel Protection for Boats';
export const TIRE_AND_WHEEL_PLAN_META_DESCRIPTION = 'Tire & Wheel protection covers everything for your boat trailer’s tires, wheels and rims for up to 5 years. Contact us today for protection.';
export const TIRE_AND_WHEEL_HERO = 'tire-wheel-hero.png';
export const TIRE_AND_WHEEL_FLAT_TIRE = 'flat-tire.svg';
export const TIRE_AND_WHEEL_TOW_TRUCK = 'tow-truck.svg';
export const TIRE_AND_WHEEL_TRAILER = 'trailer.svg';
export const TIRE_AND_WHEEL_UMBRELLA = 'umbrella.svg';
export const TIRE_AND_WHEEL_SHIELD = 'shield.svg';
export const TIRE_AND_WHEEL_USA_MAP = 'usa-map.svg';
export const TIRE_AND_WHEEL_WRENCH = 'wrench.svg';

export const GAP_PROTECTION_PLAN_URL = '/services/gap-protection/';
export const GAP_PROTECTION_PLAN_META_TITLE = 'GAP Protection for Boats';
export const GAP_PROTECTION_PLAN_META_DESCRIPTION = 'Find out about GAP coverage, its importance and why it could save you from financial disaster. Contact us today for protection.';
export const GAP_PROTECTION_HERO = 'gap-protection-hero.png';

export const ROAD_ASSISTANCE_PLAN_URL = '/services/roadside-assistance/';
export const ROAD_ASSISTANCE_PLAN_META_TITLE = 'Roadside Assistance for Boats';
export const ROAD_ASSISTANCE_PLAN_META_DESCRIPTION = 'Learn more about emergency roadside assistance and concierge services that you can use both on and off the road. Contact us today for protection.';
export const ROAD_ASSISTANCE_HERO_BACKGROUND = 'road-assistance-hero.png';


import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Breadcrumb } from '@dmm/lib-react-ui-components';
import InfoSection from '../shared/InfoSection';
import DefaultLayout from '../../../../layouts/Default';
import { getBreakpoint } from '../../../../utils/commonHelper';
import {
  ROAD_ASSISTANCE_PLAN_URL,
  TRIDENT_SERVICES_CDN_URL,
  ROAD_ASSISTANCE_HERO_BACKGROUND,
} from '../../../../constants/TridentServices';
import {
  TRACKING_ROAD_ASSISTANCE_BOTTOM_FORM_ACTION_LABEL,
  TRACKING_ROAD_ASSISTANCE_TOP_FORM_ACTION_LABEL,
  TRACKING_ROAD_ASSISTANCE_TOP_FORM_ACTION_TYPE,
  TRACKING_ROAD_ASSISTANCE_TOP_FORM_EVENT
} from '../../../../constants/BoatLoans';
import {
  FAQ_ACCORDION_CONTENT,
  FAQ_DISCLAIMER_TEXT,
  FAQ_SECTION_TITLE
} from '../../../../constants/TridentServices/roadAssistanceFAQ';
import { Hero } from '../shared/Hero';
import { FAQSection } from '../shared/FAQSection';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import RichSnippet from '../../../../components/RichSnippet';
import metadata from './RoadAssistanceFAQMeta.json';

import './styles.css';
import { roadAssistanceInfo } from '../../../../constants/TridentServices/RoadsideAssistanceinfoSection';


const backgroundHero = `${TRIDENT_SERVICES_CDN_URL}${ROAD_ASSISTANCE_HERO_BACKGROUND}`;

const RoadAssistance = (props) => {
  const { isLeadSubmitted } = props;
  const [isMobile, setIsMobile] = useState(getBreakpoint() === 'mobile');
  const [isReady, setIsReady] = useState(false);

  const handleResize = () => {
    setIsMobile(getBreakpoint() === 'mobile');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setIsReady(true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <RichSnippet schema={getFAQSchema(metadata)} />
      <DefaultLayout {...props} pageType="TridentServicesRoadsideAssistancePlan" hideOverflow>
        <div className="breadcrumb-container">
          <Breadcrumb
            items={[
              {
                text: 'Home',
                url: '/',
              },
              {
                text: 'Services',
              },
              {
                text: 'Roadside Assistance',
                url: ROAD_ASSISTANCE_PLAN_URL,
              },
            ]}
          />
        </div>

        {isReady && (
          <Hero
            backgroundImageUrl={backgroundHero}
            title={<>24/7 Roadside{isMobile && <br/>} Assistance for{isMobile && <br/>} Boat Owners</>}
            text={<>Protection for boat, trailer and{isMobile && <br/>} vehicle for as Low as</>}
            price="$25/month"
            formTitle="Get Your Free Quote"
            leadPage="Roadside Assistance"
            isLeadSubmitted={isLeadSubmitted}
            dataTestId="road-assistance-hero"
            trackingEvent={
              {
                'action_type': TRACKING_ROAD_ASSISTANCE_TOP_FORM_ACTION_TYPE,
                'action_label': TRACKING_ROAD_ASSISTANCE_TOP_FORM_ACTION_LABEL,
                'event': TRACKING_ROAD_ASSISTANCE_TOP_FORM_EVENT
              }
            }
          />
        )}

        <div className="main-section">
          <div className="road-assitance-info">
            <InfoSection
              isMobile={isMobile}
              info={roadAssistanceInfo}
              leadPage="Extended Service Plan"
              isLeadSubmitted={isLeadSubmitted}
              getProtectedGALinkId="get protected button roadside assistance plan page"
              trackingEvent={
                {
                  'action_type': TRACKING_ROAD_ASSISTANCE_TOP_FORM_ACTION_TYPE,
                  'action_label': TRACKING_ROAD_ASSISTANCE_BOTTOM_FORM_ACTION_LABEL,
                  'event': TRACKING_ROAD_ASSISTANCE_TOP_FORM_EVENT
                }
              }/>
          </div>

          <div className="road-assistance-faqs">
            <FAQSection
              pageTitle={FAQ_SECTION_TITLE}
              accordionItems={FAQ_ACCORDION_CONTENT}
              disclaimerText={FAQ_DISCLAIMER_TEXT}
            />
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false)
  };
};

export default connect(
  mapStateToProps,
)(RoadAssistance);

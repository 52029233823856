import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pull from 'lodash/pull';
import size from 'lodash/size';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
import { getDefaultParams, getActiveParams, generateSearchPath, hyphenateUrlComponents } from '../../utils/urlHelpers/listings';
import { toggleSubFacet } from '../../utils/multiFacetHelper';
import { capitalizeEachWord, normalizeString } from '@dmm/lib-common/lib/formatting';

// Stylesheets
import './styles.css';

const List = ({ title, link}) => {

  return (
    <div className="related__list_item">
      <a href={link} >{title}</a>
    </div>
  );
};

List.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

class RelatedContent extends PureComponent {

  shouldShowDynamicList() {
    const { params } = this.props;
    const searchParams = omitBy(params, (value) => {return value === undefined;});
    let selectedParams = keys(searchParams);
    selectedParams = pull(selectedParams, 'make', 'makeModel', 'seoMakeInfo');
    if (selectedParams.length !== 0) {
      return false;
    }
    const defaultParams = getDefaultParams(params);
    const currentParams = getActiveParams(defaultParams);
    const makeModel = get(currentParams, 'makeModel', {});
    const makeModelKeys = Object.keys(makeModel);
    if (makeModelKeys.length === 1){
      const models = makeModel[makeModelKeys[0]];
      if (models.length === 0) {
        return true;
      }
    }
    return false;
  }

  render () {
    const { facets, params } = this.props;
    const defaultParams = getDefaultParams(params);
    let showDynamicList = this.shouldShowDynamicList();
    const makeModel = get(facets, 'makeModel', { value: ''});
    let conditions = get(facets, 'condition', {});
    conditions = sortBy(conditions, 'value');
    let allModelsArray = makeModel;
    if (showDynamicList && size(makeModel) > 1) {
      let paramsMakeNamesArray = Object.keys(defaultParams.makeModel);
      let selectedMake = head(paramsMakeNamesArray);
      allModelsArray = makeModel.filter(make => {
        return normalizeString(make.value) === normalizeString(selectedMake);
      });
    }
    const allModels = head(allModelsArray);
    const title = omit(allModels, ['count', 'model']);
    const models = slice(get(allModels, 'model', []), 0, 10);
    let make = get(title, 'value', '');
    if (make === 'other') {
      showDynamicList = false;
    }
    let slugMake = normalizeString(hyphenateUrlComponents(make));
    if (params.seoMakeInfo && params.seoMakeInfo.seoMakeName) {
      make = params.seoMakeInfo.seoMakeName;
    }
    return (
      <>
        {
          showDynamicList && slugMake &&
          <div className="related__content">
            <div className="related__model">
              <h2 className="by-model">{make} Models</h2>

              {map(models, model => {
                const title = get(model, 'value', '');
                let slugModel = hyphenateUrlComponents(title);
                const link = generateSearchPath(
                  { makeModel: toggleSubFacet(slugMake, slugModel, {}, true) }, defaultParams, true);
                return (
                  <List
                    key={title}
                    link={link}
                    title={`${make} ${title}`}
                  />
                );
              })}
            </div>
            <div className="related__condition">
              <h2 className="by-condition">New and Used {make}</h2>

              {map(conditions, condition => {
                const title = get(condition, 'value', '');
                const link = generateSearchPath(
                  { makeModel: toggleSubFacet(slugMake, '', {}, true), condition: title }, defaultParams, true);

                return (
                  <List
                    key={title}
                    link={link}
                    title={`${capitalizeEachWord(title)} ${make}`}
                  />
                );
              })}
            </div>
          </div>
        }
      </>
    );
  }
}

export default RelatedContent;

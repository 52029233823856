import { getDesktopMobileBreakpoint as getBreakpoint } from '../../../../utils/commonHelper';

export const adsConfig = {
  'hero-desktop': {
    unitPath: '/252108799/BoatTrader/home/hero',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [2000, 966]
    ],
    targeting: {
      pos: 'hero'
    },
    prebid: {
      bids: []
    }
  },
  'hero-mobile': {
    unitPath: '/252108799/BoatTrader/home/hero',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [2000, 966]
    ],
    targeting: {
      pos: 'hero'
    },
    prebid: {
      bids: []
    }
  }
};

export const getAdsConfig = () => {
  const config = { ...adsConfig };

  delete config['hero-desktop'];
  delete config['hero-mobile'];

  const breakpoint = getBreakpoint();
  config[`hero-${breakpoint}`] = adsConfig[`hero-${breakpoint}`];

  return config;
};

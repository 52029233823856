import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DefaultLayout from '../../../../layouts/Default';
import {
  SERVICES_URL,
  RENTAL_URL,
  RENTAL_SERVICE,
  LEARN_BUTTON,
  CLICK_AND_BOAT_URL
} from '../../../../constants/services';

import BreadCrumb from '../../../../components/BreadCrumb';
import Card from '../../../../components/CompanyCard';
import ServiceChildTitle from '../../../../components/ServiceChildTitle';
import { setExternalLinkImpression } from '../../../../store/actions/dataLayer';
import '../styles.css';

class Rental extends PureComponent {
  componentDidMount() {
    this.props.setExternalLinkImpression(RENTAL_SERVICE, 'dfp', 'logo');
    this.props.setExternalLinkImpression(RENTAL_SERVICE, 'dfp', 'bottom cta');
  }

  render() {
    return (
      <>
        <DefaultLayout {...this.props} pageType="RentalServices">
          <div className="service-page">
            <ServiceChildTitle title="Boat Rental" />
            <div className="service-content">
              <BreadCrumb items={[{
                title: 'Home',
                link: '/'
              }, {
                title: 'Services',
                link: SERVICES_URL
              }, {
                title: 'Boat Rental',
                link: RENTAL_URL
              }]} />
              <div className="main-section">
                <Card
                  logo="click-and-boat-logo.png"
                  buttonType={LEARN_BUTTON}
                  link={CLICK_AND_BOAT_URL}
                  rel="sponsored noopener"
                  altText="Click&Boat logo with redirection"
                  service={RENTAL_SERVICE}
                />
                <div className="service-info">
                  <h1>Boat Rental</h1>
                  <p>
                  Click&Boat makes <a href={CLICK_AND_BOAT_URL}>boat rentals</a> easy, whether clients want to rent a
                  pontoon boat, party boat or fishing boat. The boat rental platform makes it easy to become an owner,
                  create your listing, and rent out your boat.
                  </p>
                  <p>
                  Click&Boat is the leader in boat rentals services. From motorboats and catamarans that make perfect
                  party boats or pontoons that are ideal for visiting the closest beaches. Likewise, renters may choose
                  to charter fishing boats and try out a new sport! With more than 400,000 boats across 60+ countries,
                  you can find boat rentals near you, or discover sailing in a whole new country. Get complete freedom
                  to explore places and get exactly what you want out of your vacation. No matter where you are or if
                  you are looking for a relaxing vacation or one filled with adventure, a boat rental is flexible for
                  any occasion. Even if you are a boat owner, and would like to rent a different type of boat or sail
                  in another area, our platform is the perfect match for you.
                  </p>
                  <p>
                  Click&Boat connects boat owners and renters together so everyone can experience sailing on the
                  water. It started with two contractors, Edouard Gorioux and Jeremy Bismuth, who observed that among
                  millions of boats in France, very few were used for more than 10 days a year. Owning a boat is very
                  expensive with annual expenses accounting for 10% of a boat’s price on average every year. The
                  Click&Boat platform connects boat owners easily and securely to people interested in a boat rental
                  service. This not only gives boat owners a great profit, but it also makes sailing more accessible
                  and affordable so more people can sail.
                  </p>
                  <h2>How to rent your boat</h2>
                  <p>
                  Within the first 24 hours of subscribing to the boat rental service, boat owners are contacted by an
                  account manager. The account manager becomes the boat owner’s first point of contact for any
                  information, problems and tips that the owner needs during the boat rental service process. They help
                  check the boat’s listing, legal compliance and any certifications needed.
                  </p>
                  <p>
                  The owners also get an introduction to the features of the boat rental platform such as blocking the
                  calendar and specialised offers that can be given through the chat. The account manager also contacts
                  the owners when they receive their first booking to ensure the process goes smoothly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </DefaultLayout>
      </>
    );
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  setExternalLinkImpression
}, dispatch))(Rental);

import React from 'react';
import get from 'lodash/get';
import DealerLocation from './DealerLocation';

const LocatorBody = ({
  pageIndex,
  device,
  setDealerIndex,
  helper,
}) => {

  const PreviousBtn = () => <button className="previous-btn" data-e2e="previous-btn" disabled={!helper.hasPreviousPage()} onClick={() => helper.previousPage()}>Previous</button>;
  const NextBtn = () => <button className="next-btn" data-e2e="next-btn" disabled={!helper.hasNextPage()} onClick={() => helper.nextPage()}>Next</button>;
  return (
    <>
      <h4 data-e2e="num-of-dealers-found">{helper.totalListings()} dealers found</h4>
      <ol>
        {helper.dealers(device)?.map((dealer, key) => <li key={key} data-reporting-event-category="soft lead"
          data-reporting-event-action="view dealer" data-reporting-event-label={get(helper, 'partyId')} data-e2e="view dealer">
          <DealerLocation dealerKey={helper.getStartIndex() + key} dealer={dealer} setDealerIndex={setDealerIndex} partyId={get(helper, 'partyId')}/>
        </li>)}
      </ol>
      {device === 'desktop' &&
        <footer className="locator-footer" data-e2e="locator-footer">
          <PreviousBtn />
          <div className="pagination-container" data-e2e="pagination-container">
            {helper.pagination()?.map((term, key) => term && Number.isInteger(term) ? <button
              disabled={term - 1 === pageIndex || term > helper.getLastPage()}
              key={key}
              className={'pagination-button'}
              data-e2e={'pagination-button'}
              onClick={() => helper.goToPageIndex(term - 1)}
            >{term}</button> : <span key={key}>{term}</span>)}
          </div>
          <NextBtn />
        </footer>
      }
    </>
  );
};

export default LocatorBody;

export const GOAL_ALL_BDP_EMAIL_LEAD_FORM = 'custom-goal-all-bdp-email-lead-form';

export const activeGoals = {
  [GOAL_ALL_BDP_EMAIL_LEAD_FORM]: [
    'form-submission-mobile-finance-modal',
    'form-submission-desktop-finance-contact',
    'form-submission-desktop-conversational-modal',
    'form-submission-desktop-gallery-modal',
    'form-submission-desktop-oem',
    'form-submission-desktop-dealer',
    'form-submission-mobile-contact',
    'form-submission-desktop-finance-contact'
  ]
};

import { useMemo } from 'react';
import { getNoIndex } from '../../metaContentHelper';

const useNoIndex = (url) => {
  return useMemo(() => {
    const noindex = getNoIndex(url);
    return noindex;
  }, [url]);
};

export default useNoIndex;

import React from 'react';

import backBtn from '../back-btn.svg';
import closeBtn from '../close-btn.svg';

import OemContactForm from '../../OemContactForm';
import { getPrePopulatedText } from '../../../utils/listingHelper';
import { REACT_APP_PRIVACY_POLICY_URL, REACT_APP_TERMS_OF_USE_URL } from '../../../constants/boats';

const ContactPage = ({ device, setDealerIndex, closeModal, dealer, listing, isWorking, postalCode, oemDetails, type }) => {

  const BackBtn = () => <button className="back-btn" onClick={() => setDealerIndex(undefined)}><img src={backBtn}/><span>Back to Dealer List</span></button>;
  const CloseBtn = () => <button className="close-btn" onClick={closeModal}><img src={closeBtn}/><span className="visuallyhidden">Close Modal</span></button>;

  const onSuccess = () => {
    setTimeout(() => closeModal(), 3000);
  };

  const prePopulatedText = (listing) => {
    if (listing) {
      return getPrePopulatedText(listing);
    }
    // eslint-disable-next-line no-useless-escape
    return `I\'m interested in getting more information about your ${oemDetails.name} manufacturer listings. Please contact me.`;
  };
  const isOem = !!oemDetails;

  return (
    <>
      <header>
        <BackBtn />
        <CloseBtn />
      </header>
      <div className="oem-contact-form-from-dealer">
        <OemContactForm
          isLoading={isWorking}
          disableSticky={true}
          initialState="open"
          breakpoint={device}
          contact={dealer.contact}
          partyId={dealer.dealerId}
          prePopulatedText={prePopulatedText(listing)}
          type={type}
          proximity = { dealer?.distance[0] + ' ' + dealer?.distance[1] }
          zipCode = {postalCode}
          onSuccess={onSuccess}
          id={'oem-contact-form'}
          privacyPolicyUrl={REACT_APP_PRIVACY_POLICY_URL}
          termsOfUseUrl={REACT_APP_TERMS_OF_USE_URL}
          oemDetails={oemDetails}
          listingId={isOem && listing ? listing.id : undefined}
          isOem={isOem}
          listing={listing}
        />
      </div>
    </>
  );
};

export default ContactPage;

import React from 'react';
import {getPrice, getFirstListingImage, getTitle, addResizeParams } from '../../utils/listingHelper';
import { getListingReactUrlParams } from '../../utils/urlHelpers/boat';
import { DEFAULT_NO_BOAT_IMAGE } from '../../constants/boats';
import { setListingClick } from '../../store/actions/dataLayer';
import { ListingCarouselCard } from '@dmm/lib-react-ui-components';

/* separated L defaults will be what comes from listing, however now we can change the cards context if need like in BT-1511
 the naming convention of the Carousel Card should probably be refactor to be receptive to different types of text. ie: name -> header */
export const ListingCarouselItem = ({index, isDesktop, listing, listingTrackingClick, listingsType, page, sellerMarketingMessage }) => {

  const listingImage = getFirstListingImage(listing);
  const image = listingImage ? addResizeParams(listingImage?.original?.url ?? listingImage?.url, {width: 250, height: 180}, listingImage.date.modified, true, 'webp') : DEFAULT_NO_BOAT_IMAGE;
  const location = `${listing?.contact?.address?.city}, ${listing?.contact?.address?.state}, ${listing?.contact?.address?.zip}`;

  return (
    <ListingCarouselCard
      onClick = {() => setListingClick(listing?.id, listingsType, '', '', getTitle(listing), -1, listing?.make)}
      image = {image}
      imageLazyLoading={true}
      imageAlt = {getTitle(listing)}
      price = {getPrice(listing)}
      name = {getTitle(listing)}
      sellerMarketingMessage = {sellerMarketingMessage ? location : listing?.owner?.name}
      defaultImage = {DEFAULT_NO_BOAT_IMAGE}
      hyperlinkAttributes = {{
        'data-e2e': 'listingCarouselLink',
        'data-reporting-click-product-id': listing?.id,
        'data-reporting-click-listing-type': listingTrackingClick,
        'data-reporting-rank': index,
        'data-reporting-page': page,
        href: getListingReactUrlParams(listing).href,
        target: isDesktop ? '_blank' : '_self'
      }}
    />
  );
};

let states = require('usa-states').UsaStates;
export const LISTING_STANDARD = 'standard listing';
export const LISTING_ENHANCED = 'enhanced listing';
export const LISTING_SPONSORED = 'sponsored listing';
export const DEFAULT_NO_BOAT_IMAGE = `${process.env.REACT_APP_CDN_URL}/img/default/thumbnail.gif`;
export const REACT_APP_FACEBOOK_URL = 'https://www.facebook.com/BoatTrader';
export const REACT_APP_TWITTER_URL = 'https://twitter.com/Boattrader';
export const REACT_APP_YOUTUBE_URL = 'https://www.youtube.com/user/BoatTraderVideo';
export const REACT_APP_COOKIES_POLICY_URL = 'https://www.boatsgroup.com/cookies-policy/';
export const REACT_APP_COPYRIGHT_URL = 'https://www.boatsgroup.com/copyright/';
export const REACT_APP_ADVERTISER_AGREEMENT_URL = 'https://www.boatsgroup.com/advertiser-agreement/';
export const REACT_APP_PRIVACY_POLICY_URL = 'https://www.boatsgroup.com/privacy/';
export const REACT_APP_TERMS_OF_USE_URL = 'https://www.boatsgroup.com/terms-of-use/';
export const REACT_APP_AD_CHOICES_URL = 'https://www.networkadvertising.org/choices';

export const LEGACY_SEARCH_URL_PATTERN = '/search-results/:emptyCondition(NewOrUsed)?/:condition(NewOrUsed-[a-z]+)?/:types(Type-[A-Z|a-z|0-9|+|%|,|-]+)?/:classes(Category-[^/]+)?/:state(State-[A-Z|a-z|0-9|%]+)?/:zip(Zip-[0-9]+)?/:radius(Radius-[0-9|exact|any]+)?/:length(Length-[0-9|,]+)?/:price(Price-[0-9|,]+)?/:year(Year-[0-9|,]+)?/:make(Make-[^/]+)?/:model(Model-[^/]+)?/:makeModel(MakeModel-[^/]+)?/:fuelType(Fuel-[A-Z|a-z|,]+)?/:hullMaterial(Hull-[A-Za-z0-9+%, \\-]+)?/:engine(Engine-[A-Z|a-z| ,\\+,\\-]+)?/:forSaleBy(sourcecode-[A-Z|a-z]+)?/:page(Page-[0-9]+,[0-9]+)?/:sort(Sort-[A-Z|a-z]+:[A-Z]+)?';
export const LEGACY_SEARCH_URL_YEAR_PATTERN = /Year-([0-9]+)?,?([0-9]+)?/;
export const LEGACY_SEARCH_URL_STATE_PATTERN = /State-([A-Z|a-z]+)/;
export const LEGACY_SEARCH_URL_ZIP_PATTERN = /Zip-([0-9]+)/;
export const LEGACY_SEARCH_URL_RADIUS_PATTERN = /Radius-([0-9|exact|any]+)/;
export const LEGACY_SEARCH_URL_LENGTH_PATTERN = /Length-([0-9]+),?([0-9]+)?/;
export const LEGACY_SEARCH_URL_PRICE_PATTERN =  /Price-([0-9]+),?([0-9]+)?/;
export const LEGACY_SEARCH_URL_PAGE_PATTERN = /Page-([0-9]+),([0-9]+)/;
export const LEGACY_SEARCH_URL_SORT_PATTERN = /Sort-([A-Z|a-z]+:[A-Z]+)/;
export const LEGACY_SEARCH_URL_CONDITION_PATTERN = /NewOrUsed-([a-z]+)/;
export const LEGACY_SEARCH_URL_MAKE_PATTERN = /Make-([^/]+)/;
export const LEGACY_SEARCH_URL_MODEL_PATTERN = /Model-([^/]+)/;
export const LEGACY_SEARCH_URL_MAKE_MODEL_PATTERN = /MakeModel-([^/]+)/;
export const LEGACY_SEARCH_URL_CLASS_PATTERN = /Category-([^/]+)/;
export const LEGACY_SEARCH_URL_TYPE_PATTERN = /Type-([A-Z|a-z|0-9|+|%|,|-]+)/;
export const LEGACY_SEARCH_URL_FUEL_PATTERN = /Fuel-([A-Z|a-z|,]+)/;
export const LEGACY_SEARCH_URL_ENGINETYPE_PATTERN = /Engine-([A-Z|a-z|0-9| |+|-]+)/;
export const LEGACY_SEARCH_URL_HULL_PATTERN = /Hull-([A-Za-z0-9+%, -]+)/;
export const LEGACY_SEARCH_URL_FORSALEBY_PATTERN = /sourcecode-([A-Z|a-z|,]+)/;

export const NODE4_SEARCH_URL_PATTERN = '/search/refine(.+)?';

export const FACET_META_MAP = {
  radius: {
    suffix: 'mi',
    format: 'number'
  },
  price: {
    prefix: '$',
    format: 'number'
  },
  length: {
    suffix: 'ft',
    format: 'number'
  },
  fuelType: {
    formattedKey: 'Fuel Type'
  },
  multiFacetedBoatTypeClass: {
    formattedKey: 'Class'
  },
  makeModel: {
    formattedKey: 'Make / Model'
  },
  hullMaterial: {
    formattedKey: 'Hull Material'
  }
};

export const US_STATES = new states().format(
  {
    $value: 'abbr',
    $name: 'name'
  }
);

export const US_COUNTRIES = [
  {
    value: 'US',
    name: 'United States'
  }
];

export const AD_PORTAL_LEAD_SOURCE = 'BoatTrader PORTAL AD';
export const DEFAULT_NO_MAX_LENGTH = '101';

export const DEFAULT_MIN_YEAR = 1970;
export const DEFAULT_MAX_YEAR = new Date().getFullYear() + 1;
export const MYBOATS_USER_COOKIE = 'username';
export const MYBOATS_COOKIE = 'api-session';
export const allTypes = ['power-all','sail-all','small-all'];

export const DEFAULT_NO_MAX_PRICE = '100000';
export const SLIDER_MAX_VALUE = 'MAX';

export const ADS_TIMEOUT = 1000;
export const DEBOUNCE_TIMEOUT = 1000;

export const MAX_MAKE_COUNT = 10;
export const MAX_MODEL_COUNT = 10;

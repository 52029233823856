import React from 'react';

const Healthcheck = () => (
  <>
    <h1>BoatTrader</h1>
    <h2>Healthcheck</h2>
    <h3>Status: Ok</h3>
  </>
);

export default Healthcheck;

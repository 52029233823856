import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GetPreQualifiedModal } from '../../../../../components/GetPreQualified';
import { BOAT_LEAD_SERVICES_TYPE } from '../../../../../constants/BoatLoans';
import './styles.css';

class InfoSection extends Component {

  state = {
    showModal: false
  }
  showModalPreQualified = (show) => {
    this.setState({ showModal: show});
  };

  render() {

    const { info, isMobile, leadPage, trackingEvent, isLeadSubmitted, getProtectedGALinkId } = this.props;

    return (
      <>
        <div className="extended-services-info" data-testid="extended-services-info" >
          <div className="top-section">
            <h2 className="title">{info.topSection.title}</h2>
            {info.topSection.subtitle && <h2 className="subtitle">{info.topSection.subtitle}</h2>}
            <div className="list-items">
              {info.topSection.bullets.map((item) => {
                return (
                  <div className="item" key={item.title}>
                    <img src={item.icon} alt={item.title}/>
                    <div className="item-title">{item.title}</div>
                    <div className="item-text">{item.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="middle-section">
            <h2 className="title">{info.middleSection.title}</h2>
            <div className="sub-title">{info.middleSection.text}</div>
            {!isLeadSubmitted &&
              <div
                className="send-request-button"
                aria-label="Send Request Button"
                data-testid="extended-services-get-protected-button"
                onClick={() => this.showModalPreQualified(true)}
                data-reporting-event-category="internal link"
                data-reporting-event-action="Get Protected - click"
                data-reporting-event-label="get protected button service page"
                data-reporting-click-internal-link-id={getProtectedGALinkId}
                data-reporting-click-internal-link-type="click">
                  Get Protected
              </div>
            }
          </div>
          <div className="bottom-section">
            <h2 className="title">{info.bottomSection.title}</h2>
            <div className="list-cards">
              {info.bottomSection.bullets.firstRow.map((item) => {
                return (
                  <div className="card" key={item.title}>
                    <img src={item.icon} alt={item.title}/>
                    <div className="card-title">{item.title}</div>
                    <div className="card-text">{item.text}</div>
                  </div>
                );
              })
              }
            </div>
            <div className="list-cards">
              {info.bottomSection.bullets.secondRow.map((item) => {
                return (
                  <div className="card" key={item.title}>
                    <img src={item.icon} alt={item.title}/>
                    <div className="card-title">{item.title}</div>
                    <div className="card-text">{item.text}</div>
                  </div>
                );
              })
              }
            </div>
          </div>
        </div>

        {this.state.showModal && <GetPreQualifiedModal
          onClose={() => this.showModalPreQualified(false)}
          isMobile={isMobile}
          leadPage={leadPage}
          leadType={BOAT_LEAD_SERVICES_TYPE}
          isLeadSubmitted={isLeadSubmitted}
          trackingEvent={trackingEvent}
        />}
      </>

    );
  }

}

InfoSection.propTypes = {
  info: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  leadPage: PropTypes.string,
  trackingEvent: PropTypes.object,
  isLeadSubmitted: PropTypes.bool,
  getProtectedGALinkId: PropTypes.string,
};

export default InfoSection;

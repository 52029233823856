import {
  TRIDENT_SERVICES_CDN_URL,
  EXTENDED_SERVICES_NATION_WIDE_ICON,
  EXTENDED_SERVICES_BOAT_TOWING_ICON,
  EXTENDED_SERVICES_PEACE_OF_MIND_ICON,
  EXTENDED_SERVICES_REPAIR_COVERAGE_ICON,
  EXTENDED_SERVICES_WORRY_FREE_PROTECTION_ICON,
  EXTENDED_SERVICES_LOW_MONTHLY_COST_ICON,
  EXTENDED_SERVICES_EASILY_TRANSFERABLE_ICON,
} from '.';

export const extendedServicesInfo = {
  topSection: {
    title: 'Boat Coverage With a Worry-free Investment',
    bullets: [
      {
        title: 'Nationwide Coverage',
        text: 'Pick the repair facility of your choice, with immediate credit card payments.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_NATION_WIDE_ICON}`,
      },
      {
        title: 'Boat Towing',
        text: 'Protection for unexpected boat repairs and on-water boat towing.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_BOAT_TOWING_ICON}`,
      },
      {
        title: 'Peace of Mind',
        text: 'Get on the water with confidence, knowing that major repairs are covered.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_PEACE_OF_MIND_ICON}`,
      },
    ],
  },
  middleSection: {
    title: 'Why Invest in an Extended Service Plan for Your Boat?',
    text: 'An extended service plan offers an added layer of protection against unexpected repair costs, ensuring that you can enjoy your boating adventures worry-free.',
  },
  bottomSection: {
    title: 'Extended Service Plan Benefits',
    bullets: {
      firstRow: [
        {
          title: 'Repair Coverage',
          text: 'Avoid expensive repair bills by having the plan cover the costs once the manufacturer warranty expires.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_REPAIR_COVERAGE_ICON}`,
        },
        {
          title: 'Worry-free Protection',
          text: 'Pro-rated coverage for parts and labor of unexpected mechanical/electrical failures.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_WORRY_FREE_PROTECTION_ICON}`,
        },
      ],
      secondRow: [
        {
          title: 'Low Monthly Cost',
          text: 'Coverage starting as low as $30* per month based on the year, make, and model of your boat.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_LOW_MONTHLY_COST_ICON}`,
        },
        {
          title: 'Easily Transferable',
          text: 'Upgrading your boat? Service contracts can be transferred to a new owner, increasing the resale value of your boat.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_EASILY_TRANSFERABLE_ICON}`,
        },
      ],
    },
  },
};

import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import { ApplyNowButton } from '../ApplyNowButton';
import { TRACKING_BOAT_LOANS_HOW_TO_APPLY_CLICK_ACTION_LABEL } from '../../../../constants/BoatLoansV2';

export const HowToApply = ({ isMobile, dataTestId = 'how-to-apply-component' }) => {
  return (
    <div className="how-to-apply-section" data-testid={dataTestId}>
      <div className="apply-title-top">
        <span>How to Apply for<br />a Boat Loan</span>
      </div>
      <div className="steps-container">
        <div className="apply-title">
          {!isMobile && <span>How to<br />Apply for a<br />Boat Loan</span>}
          {isMobile && <span>How to Apply for<br />a Boat Loan</span>}
        </div>
        <div className="step-section">
          <div className="step-circle">1</div>
          <span className="step-name">
            Shop
          </span>
          <div className="step-text">
            Estimate your payment with our <a href="/boat-loans/calculator/" target="_blank">boat loan calculator</a>.
            <br/>
            Find your dream boat on <a href="/boats/" target="_blank">Boat Trader</a>, the number one boating marketplace.
          </div>
        </div>
        <div className="step-section middle">
          <div className="step-circle">2</div>
          <span className="step-name">
            Pre-qualify
          </span>
          <div className="step-text middle">
            Get approved & on the water faster.
          </div>
          <ApplyNowButton
            text="Get pre-qualified"
            type="button"
            size="small"
            utmCampaign="btboatloanspage"
            utmContent="btboatloanspage"
            data-reporting-click-internal-link-id="how to apply - apply online in 5 minutes"
            data-reporting-click-internal-link-type="click"
            trackingEvent={{
              'action_label': TRACKING_BOAT_LOANS_HOW_TO_APPLY_CLICK_ACTION_LABEL
            }}
          />
        </div>
        <div className="step-section">
          <div className="step-circle">3</div>
          <span className="step-name">
            Apply
          </span>
          <div className="step-text">
            We’ll compare over 15 marine lenders to get you the best rate with the best terms.
            <br/>
            Finalize your loan within 24 hours.
          </div>
        </div>
      </div>
    </div>
  );
};

HowToApply.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  dataTestId: PropTypes.string,
};

import React from 'react';
export const FAQ_ACCORDION_CONTENT = [
  {
    question: 'What Do You Need To Qualify For A Boat Loan?',
    answer: (
      <>
        You will need to show the lender your financial ability to pay back a boat loan. Banks will look at your credit rating,
        so it’s important for you to look at it first. Three major bureaus provide this kind of information to lending institutions:
        Experian, Equifax and Trans Union. Start there.
        <br/><br/>
        It’s important for you to check your credit rating before the bank looks into it. Here’s why: If you find a problem or some inaccuracies,
        it’s much easier for you to get those straightened out before you apply for a loan.
        <br/><br/>
        Be sure there are no closed accounts that are being reported as open, and close any open accounts you don’t use anymore.
        By accessing your credit report first, you can stop problems before they start. There are lenders out there who will do subprime financing,
        which provides options for people with lower credit scores, but the national lenders will likely require credit scores to be 680 or higher.
        So, tidy things up before you begin
        <br/><br/>
        Banks also look at your debt-to-income ratio, which is determined by dividing your monthly debt payments
        by your monthly income. Many lenders prefer a debt-to-income ratio of around 40 or 45 percent, including payment on the boat loan for which
        you are applying.
        <br/><br/>
        Lenders also will look at your net worth and liquidity. Your net worth is defined as assets minus liabilities – usually
        properties owned less any debt you have. A liquid asset is anything that can be turned into cash within 30 days or less.
      </>
    ),
  },
  {
    question: 'Boat Down Payments and Ownership Costs',
    answer: (
      <>
        “A cash down payment, net trade equity, or a combination of the two, will be required by the lender in all but rare circumstances” said Robert Dunford,
        Director of Sales & Operations for Trident Funding. “But borrowers need to keep in mind that lenders will also require you to have sufficient cash reserves on hand
        after you have paid your full down payment. Lenders want to ensure that you have sufficient funds to cover the other expenses related to boat ownership, like insurance,
        fuel, slip fees, maintenance, in addition to your other household obligations.“
        <br/><br/>
        ”Boat down payments are usually between 10-20 percent, but can depend on the cost and value
        of the boat as well as your location and finances.“
        <br/><br/>
        The boat loan rate for which you qualify is going to hinge on factors mentioned above, but it also could swing on the
        term of the loan — how many years you’ll be paying on it — or even the age of the boat you’re buying. For example, you might be able to get a better interest rate on a
        boat that’s as little as one year newer. In general, smaller loans for shorter terms usually have higher interest rates than larger loans for longer terms. Again, rates
        can vary with your credit history.
        <br/><br/>
        “One thing some first-time boat buyers forget or are unaware of is sales tax” Dunford said. “Sales tax applies to boat and trailer
        purchases in all but a few states. Tax rates can be a flat charge or a percent of the purchase price. In addition to sales tax, buyers may also have costs associated with
        title and registration, U.S. Coast Guard documentation, lien perfection, and other service or administrative fees.”
      </>
    )
  },
  {
    question: 'What Information Will I Need to Apply for Boat Financing?',
    answer: (
      <>
        Essentially, you will need bank statements and tax returns that demonstrate income. Banks also will consider your credit rating, your debt-to-income ratio, net worth and liquidity.
      </>
    )
  },
  {
    question: 'How Much Can I Afford to Purchase a Boat?',
    answer: (
      <>
        Banks can help you determine that, but you can also use our <a href="/boat-loans/calculator/" target="_blank">online boat loan payment calculator</a> to find a payment you are comfortable with.
      </>
    )
  },
  {
    question: 'What Credit Score Do I Need for a Boat Loan?',
    answer: (
      <>
        The higher the better. Higher credit scores will help you secure loans at lower interest rates. There are subprime lenders out there, but by and large, major lenders want
        to see credit scores of 680 or above.
      </>
    )
  },
  {
    question: 'How Long are Boat Loan Terms?',
    answer: (
      <>
        Terms vary with the size of the loan and your ability to pay, but expect to have a loan term of 24 to 48 months for trailerable boats and up to 240 months – or 20 years – for larger craft.
      </>
    )
  },
  {
    question: 'What is a Typical Down Payment for a Boat?',
    answer: (
      <>
        The amount of the down payment on a boat loan varies. It’s usually anywhere from 10 percent to more than 30 percent. It depends on the borrower’s credit score, income, and overall financial
        profile. It also depends on the loan amount and the loan term.
        <br/><br/>
        Here are some estimates to give you an example. The average cost of a new boat is around $70,000. If you have excellent credit,
        you should expect to put down at least $7,000. If your credit is less than excellent, you may need to put down anywhere from $14,000 to more than $21,000. This is just the amount needed for
        the downpayment and doesn’t include closing costs, insurance, etc.
      </>
    )
  },
  {
    question: 'Estimating Your Prospective Boat’s Value for Loan Purposes',
    answer: (
      <>
        Boat Trader offers <a href="/research/boat-values-and-pricing-guide/" target="_blank">resources to help you estimate your boat’s value</a>. The criteria used to determine the value of a pre-owned boat can include size, age, model, and condition among others.
        Use Boat Trader’s <a href="/boats/" target="_blank">search results</a> to find the most current pricing on boats.
        <br/><br/>
        We also offer a <a href="/research/tools/price-checker/" target="_blank">boat price checker tool</a> to help you understand the range of listed prices for your boat search.
      </>
    )
  },
  {
    question: 'Estimate Your Boat Loan Rate',
    answer: (
      <>
        Boat Trader’s preferred lending partner, Trident Funding provides customers competitive boat loans tailored for different financial situations. Trident Funding can help with dealer
        and private party purchases, boat refinancing and title and U.S. Coast Guard documentation services.  <a href="/boat-loans/calculator/" target="_blank">Estimate your boat loan rate</a> today.
      </>
    )
  },
];

import get from 'lodash/get';

const stateCodeMap = {
  'CA-AB': 'alberta',
  'CA-BC': 'british columbia',
  'CA-MB': 'manitoba',
  'CA-NB': 'new brunswick',
  'CA-NL': 'newfoundland and labrador',
  'CA-NS': 'nova scotia',
  'CA-NT': 'northwest territories',
  'CA-NU': 'nunavut',
  'CA-ON': 'ontario',
  'CA-PE': 'prince edward island',
  'CA-QC': 'quebec',
  'CA-SK': 'saskatchewan',
  'CA-YT': 'yukon',
  'US-AK': 'alaska',
  'US-AL': 'alabama',
  'US-AR': 'arkansas',
  'US-AZ': 'arizona',
  'US-CA': 'california',
  'US-CO': 'colorado',
  'US-CT': 'connecticut',
  'US-DC': 'district of columbia',
  'US-DE': 'delaware',
  'US-FL': 'florida',
  'US-GA': 'georgia',
  'US-HI': 'hawaii',
  'US-IA': 'iowa',
  'US-ID': 'idaho',
  'US-IL': 'illinois',
  'US-IN': 'indiana',
  'US-KS': 'kansas',
  'US-KY': 'kentucky',
  'US-LA': 'louisiana',
  'US-MA': 'massachusetts',
  'US-MD': 'maryland',
  'US-ME': 'maine',
  'US-MI': 'michigan',
  'US-MN': 'minnesota',
  'US-MO': 'missouri',
  'US-MS': 'mississippi',
  'US-MT': 'montana',
  'US-NC': 'north carolina',
  'US-ND': 'north dakota',
  'US-NE': 'nebraska',
  'US-NH': 'new hampshire',
  'US-NJ': 'new jersey',
  'US-NM': 'new mexico',
  'US-NV': 'nevada',
  'US-NY': 'new york',
  'US-OH': 'ohio',
  'US-OK': 'oklahoma',
  'US-OR': 'oregon',
  'US-PA': 'pennsylvania',
  'US-RI': 'rhode island',
  'US-SC': 'south carolina',
  'US-SD': 'south dakota',
  'US-TN': 'tennessee',
  'US-TX': 'texas',
  'US-UT': 'utah',
  'US-VA': 'virginia',
  'US-VT': 'vermont',
  'US-WA': 'washington',
  'US-WI': 'wisconsin',
  'US-WV': 'west virginia',
  'US-WY': 'wyoming',
};

export const getStateName = (countryCode, stateCode) => {
  return get(stateCodeMap, `${countryCode}-${stateCode}`, stateCode);
};

export const getStateCode = (value) => Object.keys(stateCodeMap).find(key => stateCodeMap[key] === value);

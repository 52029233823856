import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import DefaultLayout from '../../../../layouts/Default';
import {
  SERVICES_URL,
  DOCUMENTATION_URL,
  YACHT_CLOSER_URL,
  QUOTE_BUTTON,
  DOCUMENTATION_SERVICE,
  YACHTCLOSER_PROVIDER
} from '../../../../constants/services';

import BreadCrumb from '../../../../components/BreadCrumb';
import Card from '../../../../components/CompanyCard';
import ServiceChildTitle from '../../../../components/ServiceChildTitle';
import LinkButton from '../../../../components/LinkButton';
import RichSnippet from '../../../../components/RichSnippet';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import { setExternalLinkImpression } from '../../../../store/actions/dataLayer';

import faq from './faq.json';
import '../styles.css';

class Documentation extends PureComponent {
  componentDidMount() {
    this.props.setExternalLinkImpression(DOCUMENTATION_SERVICE, 'dfp', 'logo');
    this.props.setExternalLinkImpression(DOCUMENTATION_SERVICE, 'dfp', 'bottom cta');
  }

  renderAccordionTitle (text) {
    return (
      <AccordionItemHeading>
        <AccordionItemButton>
          <h2 className="accordion__title">
            {text}
          </h2>
        </AccordionItemButton>
      </AccordionItemHeading>
    );
  }

  render() {
    return (
      <>
        <RichSnippet schema={getFAQSchema(faq)} />
        <DefaultLayout {...this.props} pageType="DocumentationServices">
          <div className="service-page">
            <ServiceChildTitle title="Boat Documentation" />
            <div className="service-content">
              <BreadCrumb items={[{
                title: 'Home',
                link: '/'
              }, {
                title: 'Services',
                link: SERVICES_URL
              }, {
                title: 'Boat Documentation',
                link: DOCUMENTATION_URL
              }]} />
              <div className="main-section">
                <Card
                  logo="yacht-closer-logo.png"
                  buttonType={QUOTE_BUTTON}
                  link={YACHT_CLOSER_URL}
                  rel="noopener"
                  altText="Yacht closer documentation logo with redirection"
                  service={DOCUMENTATION_SERVICE}
                  provider={YACHTCLOSER_PROVIDER}
                />
                <div className="service-info">
                  <h1>Boat Documentation</h1>
                  <p>
                  Buying or selling a boat requires some amount of paperwork and documentation,
                  ranging from a boat title and proof of ownership to a boat bill of sale agreement and other
                  similar related contracts, documents and certificates. Specific requirements for
                  documenting your vessel vary from state to state and larger more expensive yachts may
                  require more documentation than smaller boats. Specialist companies like YachtCloser,
                  as well as professional yacht brokers and boat dealerships, can handle some or all of this
                  process, making life easier for both the boat buyer and seller.
                  </p>
                </div>
              </div>
              <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                preExpanded={['documented-vessel-id']}
              >
                <AccordionItem uuid="documented-vessel-id">
                  {this.renderAccordionTitle('What is a documented vessel?')}
                  <AccordionItemPanel>
                    <p>
                      A documented vessel is one that has been federally registered through an application
                      process with the U.S. Coast Guard, via either the National Vessel Documentation Center
                      or an authorized third party. Typically vessels over 26 feet in length may obtain a
                      Certificate of Documentation, while vessels over 5 tons are required to have one.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('Do I need to document my boat?')}
                  <AccordionItemPanel>
                    <p>
                      If you&apos;re planning to finance your boat purchase, most lenders will require documentation
                      to secure the mortgage. For smaller boats under 26 feet in length, a title and proof of
                      ownership may be enough to satisfy many of the lender&apos;s requirements. However, loans for
                      larger vessels will often require a US Coast Guard Certificate of Documentation which under
                      Title 46 Code of Federal Regulations can help secure a “Preferred Ship Mortgage”. Some states
                      may require both a US Coast Guard certificate and a state registration to operate a motorized
                      vessel on public waterways.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('Do you need a bill of sale to sell a boat?')}
                  <AccordionItemPanel>
                    <p>
                      Most if not all states require a bill of sale be signed by both the buyer and seller in order
                      to complete the sale. In many cases, the title itself acts as the bill of sale, used to transfer
                      ownership of the vessel from one party to another.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('Can you title a boat with a bill of sale?')}
                  <AccordionItemPanel>
                    <p>
                      In most states, in order to obtain a new boat title, you’ll typically need the existing
                      title from the previous owner - although some states may accept just a bill of sale with the
                      existing HIN (hull identification number). If the title has been lost or misplaced you will
                      likely have to apply for a new title through a separate process before you can move on to the
                      registration process. Most titles have language on them that indicates the seller is releasing
                      the vessel to the buyer.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('Boat Registration')}
                  <AccordionItemPanel>
                    <p>
                      {
                        faq.map((faqItem, index) => (
                          <>
                            <h3 key={`question-${index}`} className="question">{faqItem.question}</h3>
                            <p key={`answer-${index}`}>{faqItem.answer}</p>
                          </>
                        ))
                      }
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <LinkButton
                className="quote-btn"
                buttonType={QUOTE_BUTTON}
                buttonLink={YACHT_CLOSER_URL}
                rel="noopener"
                reportingAttributes={{
                  'data-reporting-external-link-id': DOCUMENTATION_SERVICE,
                  'data-reporting-external-link-name': YACHTCLOSER_PROVIDER,
                  'data-reporting-external-link-position': 'bottom cta'
                }}
              />
            </div>
          </div>
        </DefaultLayout>
      </>
    );
  }
}

export default connect(null, {
  setExternalLinkImpression
})(Documentation);

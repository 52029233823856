import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { DEFAULT_NO_BOAT_IMAGE } from '../../constants/boats';
import { addResizeParams } from '../../utils/listingHelper';
import './styles.css';
import JWPlayer from '../JWPlayer';
import get from 'lodash/get';



export default class CarouselItem extends PureComponent {
  state = {
    mediaLoaded: false,
    mediaError: false
  };

  handleMediaLoaded() {
    this.setState({mediaLoaded: true});
  }

  handleMediaError() {
    this.setState({mediaError: true});
  }


  render() {
    let { media, title, resize = {} } = this.props;

    let mediaComponent;
    if (get(media, 'mediaType') === 'image') {

      const mediaUrl = media.original ? media.original.url : media.url;

      const imageParams = {
        srcSet: `${addResizeParams(mediaUrl, {width: 300,height: 222}, media.date.modified, false)} 300w,
                ${addResizeParams(mediaUrl, {width: 400, height: 267}, media.date.modified, false)} 400w,
                ${addResizeParams(mediaUrl, {width: 700, height: 466}, media.date.modified, false)} 700w,
                ${addResizeParams(mediaUrl, {width: 969, height: 646}, media.date.modified, false)} 969w,
                ${addResizeParams(mediaUrl, {width: 800, height: 533}, media.date.modified, false)} 1200w,
                ${addResizeParams(mediaUrl, {width: 1028, height: 690}, media.date.modified, false)} 1600w`,
        sizes: `(max-width: 300px) 300px,
                (max-width: 400px) 400px,
                (max-width: 700px) 700px,
                (max-width: 969px) 969px,
                (max-width: 1200px) 1040px,
                1600px`,
        src: addResizeParams(mediaUrl, resize, media.date.modified, false),
        alt: title
      };

      mediaComponent = <>
        <img {...imageParams} className={classnames('blur image-results', {'wide': media.width > 700})} />
        <img {...imageParams} className={classnames('centered image-results', {'wide': media.width > 700})}
          onLoad={this.handleMediaLoaded.bind(this)} onError={this.handleMediaError.bind(this)} />
      </>;

    } else if (get(media, 'mediaType') === 'video') {
      mediaComponent = <JWPlayer title={title} media={media} />;
    } else {
      let videoEmbedUrl = this.props.current ? get(media, 'videoEmbedUrl').replace(/\/v\//i, '/embed/') : '';
      mediaComponent = <iframe src={videoEmbedUrl} frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}/>;
    }


    return <>
      <div className={classnames('item-wrapper', { video: get(media, 'mediaType') !== 'image'})} >
        {
          media && !this.state.mediaError && (this.props.show || this.state.mediaLoaded) ?
            mediaComponent
            :
            <img loading="lazy" width="200" height="132" src={DEFAULT_NO_BOAT_IMAGE} alt={title}
              className={classnames('image-results', 'default', 'error')}/>
        }
      </div>
    </>;
  }
}

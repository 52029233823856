import {useCallback, useEffect, useState} from 'react';
import {safeWindow} from '../commonHelper';

export const logoFromDealer = (dealer, imgHost = process.env.REACT_APP_IMG_HOST) => {
  const {logoPath, logoUrl} = dealer;
  return logoPath ? `${imgHost}${logoPath}` : logoUrl;
};

export const usePhone = (phone, text = 'Call Now') => {
  const [showPhone, setShowPhone] = useState(false);

  useEffect(() => {
    setShowPhone(false);
  }, [phone]);

  const call = useCallback(() => {
    setShowPhone(true);
  }, []);

  const callText = showPhone ? phone : text;
  return [callText, call];
};

export const useForeignLogo = (url) => {
  const [logoUrl, setLogoUrl] = useState(url);
  if (url && typeof Image === 'function') {
    const picture = new Image();
    picture.onerror = () => setLogoUrl(null);
    picture.src = logoUrl;
  }
  return logoUrl;
};

const csrUrl = (url) => Object.keys(safeWindow()).length === 0 ? null : url;

export const useLoadedLogo = (url) => {
  const [logoUrl, setLogoUrl] = useState(null);
  useEffect(() => {
    if (csrUrl(url)) {
      const picture = new Image();
      picture.onerror = () => setLogoUrl(null);
      picture.onload = () => setLogoUrl(url);
      picture.src = url;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return logoUrl;
};

export const toColumns = (arr, len, totalCols) => {
  let chunks = [];
  let i = 0;
  let n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }
  return chunks.splice(0, totalCols);
};

export const hasCountedKey = item => item && 'key' in item && 'doc_count' in item;


import React from 'react';
import './styles.css';
import { addNotModifiedResizeParams } from '../../../utils/listingHelper';
import { ImageWithErrorHandler } from '../ImageWithErrorHandler';

const getAppImageUrl = (url, blogId) => {
  if (!blogId) {
    return url;
  }
  return process.env.REACT_APP_IMG_HOST + '/resize/wp/' + blogId + String(url).substring(String(url).indexOf('/files/'));
};

const getImageParams = (url, blogId) => ({
  srcset: `
    ${addNotModifiedResizeParams(getAppImageUrl(url, blogId), {width: 300, height: 200})} 300w,
    ${addNotModifiedResizeParams(getAppImageUrl(url, blogId), {width: 500, height: 300})} 500w,
    ${addNotModifiedResizeParams(getAppImageUrl(url, blogId), {width: 700, height: 400})} 700w,
  `,
  sizes: `
    (max-width: 700px) 500px,
    (max-width: 959px) 700px,
    (max-width: 1360px) 300px,
    500px
  `,
  src: addNotModifiedResizeParams(getAppImageUrl(url, blogId), {width: 500, height: 300}),
});


const Article = ({title, link, category, description, image, blogId}) => {
  return (
    <div>
      <a href={link}>
        <article>
          <div>
            <ImageWithErrorHandler
              {...getImageParams(image, blogId)}
              alt={title}
              defaultSrc={image}
            />
          </div>
          <div className="content">
            <div className="category">Category: {category}</div>
            <div>
              <a className="title-link" href={link}>
                {title}
              </a>
            </div>
            <div className="excerpt">{description}</div>
          </div>
        </article>
      </a>
    </div>
  );
};

export default Article;

import * as React from 'react';
import { ABTestConsumer } from '../abTest';

export const withABTest = (Component) => {
  return function ABTestComponent(props) {
    return (
      <ABTestConsumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </ABTestConsumer>
    );
  };
};

import React from 'react';
import DefaultLayout from '../../layouts/Default';

import './oauthRedirect.css';

const OauthRedirect = (props) => {
  return (
    <DefaultLayout {...props} pageType={props.pageType} noIndex={true}>
      <div className="oauth-redirect container">
        <div className="panel">
          <h1 className="heading" style={{}}>Hang Tight!</h1>
          <p className="body">You’re being redirected to another page, this may take a few seconds.</p>
          <div className="image-container">
            <img src={`${process.env.REACT_APP_CDN_URL}/app/redirect-icon.png`}></img>
            <img src={`${process.env.REACT_APP_CDN_URL}/app/redirect-icon.png`}></img>
            <img src={`${process.env.REACT_APP_CDN_URL}/app/redirect-icon.png`}></img>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default OauthRedirect;

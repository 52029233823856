import React from 'react';
import PropTypes from 'prop-types';
import { RenderHTML } from '@dmm/react-common-components';
import BreadCrumb from '../../../components/BreadCrumb';
import { Helmet } from 'react-helmet';
import { getEditorialTitle, getEditorialMetaDescription } from '../../../utils/editorialHelper';

import './styles.css';

const MainContent = ({ articles = [], location: {pathname} }) => {
  const title = articles[0]?.title;
  const content = articles[0]?.content?.body;
  const featuredImage = articles[0]?.content?.featuredImage;
  const breadCrumbTitle = pathname.replace(/\//g, '');
  const breadCrumbLink = pathname;
  const metaTitle = getEditorialTitle(pathname);
  const metaDescription = getEditorialMetaDescription(pathname);
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="og:title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
        <link rel="canonical" href={`${process.env.REACT_APP_HOST}${breadCrumbLink}`} />
      </Helmet>
      <div className="editorial-content-container">
        <BreadCrumb items={[{ title: 'Home', link: '/' }, { title: breadCrumbTitle, link: breadCrumbLink }]} />
        <div className="featured-image">
          {featuredImage && <img src={featuredImage} alt={title} />}
        </div>
        {title && <h1>{title}</h1>}
        <div className="editorial-data">
          <RenderHTML html={content}/>
        </div>
      </div>
    </>
  );
};

MainContent.propTypes = {
  /* Article element to be rendered */
  articles: PropTypes.array,
};

export default MainContent;

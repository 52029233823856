export const HOME_URL_PATTERN = '/';

export const META_TITLE = 'Boat Trader - #1 marketplace to buy & sell boats in the US';
export const META_DESCRIPTION = 'Find thousands of New & Used Boats, Outboard Motors, Engines, Trailers. Sell your Boat fast online today, read our in-depth boating guides & more!';
export const BOATS_NEAR_YOU_LISTING_TYPE = 'listing near you';
export const DEFAULT_THUMBNAIL = '/img/default/thumbnail.gif';
export const FEATURED_DEALER = 'featured dealer';
export const LOCALSTORAGE_KEY_LOCATION = 'location';
export const ONE_WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
export const BOATS_ID = 'boats';
export const ENGINES_ID = 'engines';
export const BOATS_FOR_SALE = 'Boats for Sale';
export const ENGINES_FOR_SALE = 'Engines for Sale';
export const ENGINES_ALL = 'all';
export const US_ZIP_CODE_REGEX = new RegExp(/^(\d{5})$/);
export const LISTING_ENHANCED = 'enhanced listing';
export const LISTING_STANDARD = 'standard listing';

export const TOP_BOAT_MAKES_DROPDOWN = [
  {
    value: 'mastercraft',
    name: 'MasterCraft'
  }
];

export const BOAT_TYPES = {
  all: {
    id: 0,
    data: [{ value: 'all', name: 'All Boat Types'}]
  },
  power: { value: 'power-all', name: 'All Power Boats' },
  sail: { value: 'sail-all', name: 'All Sailboats' },
  small: { value: 'small-all', name: 'All Small Boats' }
};

export const BOAT_MAKES = {
  top: {
    id: 0,
    data: [
      { value: 'all', name: 'All Manufacturers'},
      ...TOP_BOAT_MAKES_DROPDOWN
    ]
  },
  other: {
    id: 1,
    name: '---------------------------------------',
    data: []
  }
};

export const ENGINE_TYPES_MAP = {
  'inboard': {name: 'Inboard', value: 'inboard-engine'},
  'outboard': {name: 'Outboard', value: 'outboard-motor'},
  'outboard-2s': {name: 'Outboard 2 Stroke', value: '2-stroke-outboard-motor'},
  'outboard-4s': {name: 'Outboard 4 Stroke', value: '4-stroke-outboard-motor'},
  'stern-drive': {name: 'Stern Drive', value: 'sterndrive'},
  'jet': {name: 'Jet', value: 'jet'},
  'all': {name: 'All Engine Types'}
};

export const ENGINE_MAKES = {
  top: {
    id: 0,
    data: [{ value: 'all', name: 'All Engine Manufacturers'}]
  },
  other: {
    id: 1,
    name: '---------------------------------------',
    data: []
  }
};

export const ENGINE_TYPES = {
  all: {
    id: 0,
    data: [{ value: 'all', name: 'All Engine Types'}]
  }
};

export const INITIAL_EMPTY_FACETS = {
  class: [
    {
      value: 'class',
      count: 1,
      name: 'class',
      heading: 'class'
    }
  ],
  make: [
    {
      value: 'make',
      count: 1
    }
  ],
  category: [
    {
      value: 'category',
      count: 1
    }
  ]
};

export const allBoatsFacet = {
  productTypes: { 0: BOAT_TYPES.all },
  productMakes: { 0: BOAT_MAKES.top }
};
export const alltEnginesFacet = {
  productTypes: { 0: ENGINE_TYPES.all },
  productMakes: { 0: ENGINE_MAKES.top }
};

import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import thunk from 'redux-thunk';
import reducer from './reducer';
import * as utils from './utils';

const rootReducer = reducer;

export default async (url = '/', httpClient) => {

  // TODO Reenable client-side CRA.
  // Create a history depending on the environment
  const history = utils.isServer()
    ? createMemoryHistory({
      initialEntries: [url]
    })
    : createBrowserHistory({
      basename: url
    });

  const enhancers = [];

  // Development mode only code
  /* istanbul ignore next */
  if (process.env.NODE_ENV === 'development' && !utils.isServer()) {
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [thunk.withExtraArgument(httpClient), routerMiddleware(history)];
  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

  const initialState = utils.isServer() ? {} : window.__REDUX_STATE__;
  const abTestContext = utils.isServer() ? {} : window.__AB_TEST__;

  // Create the store
  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
  );


  // Development mode only code
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer', () => {
        store.replaceReducer(connectRouter(history)(reducer));
      });
    }
  }

  return {
    store,
    history,
    abTestContext
  };
};

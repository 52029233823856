import get from 'lodash/get';
import React, { PureComponent, Suspense, lazy } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BRAND_BANNER_IMPRESSION } from '../../constants/boats';
import { addLead, setConnectionClick, setOemBrandProductImpression, trackPhoneCall, trackPhoneNumberReveal } from '../../store/actions/dataLayer';
import { getStateByCode } from '../../utils/commonHelper';
import { generateDealerAddress } from '../../utils/dealerSRPHelper';
import { LOGO_SIZES, getResizedImageSrc } from '../../utils/listingHelper';
import './styles.css';

const ModalFormDealerWrapper = lazy(() => import('../ModalFormDealerWrapper'));
class BrandedHeader extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showContactModal: false,
      form: null
    };
    this.handleContactFormVisibility = this.handleContactFormVisibility.bind(this);
  }

  state = {
    showPhone: false,
    showContactModal: false
  }

  handleContactFormVisibility(showModal) {
    this.props.addLead(this.props.partyDetails.id, 'email lead', 'contact dealer');
    this.setState({
      showContactModal: showModal,
      form:
        <Suspense fallback={this.renderLoader()}>
          <ModalFormDealerWrapper dealer={this.getDealerInfo()} onClose={this.closeModalForm} show={showModal}/>
        </Suspense>
    });
  }


  generateDealerDescription = (partyDetails) => {
    let dealerDescription = '';
    if (partyDetails.englishDescription) {
      dealerDescription = partyDetails.englishDescription.length > 300 ? partyDetails.englishDescription.slice(0, 300) + '...' : partyDetails.englishDescription;
    }
    return dealerDescription;
  }

  generateBrokerPostalAddress = (address) => {
    let postalAddress = '';
    if (address) {
      if (address.street) {
        postalAddress = `${address.street}`;
      }
      if (address.city) {
        postalAddress += `, ${address.city}`;
      }
      if (address.countrySubDivisionCode) {
        const state = get(getStateByCode(address.countrySubDivisionCode), 'name');
        if (state) {
          postalAddress += `, ${state}`;
        }
      }
    }
    return postalAddress;
  }

  onCallClick = () => {
    this.props.trackPhoneCall();
    this.props.addLead(this.props.listingId, 'phone lead');
    this.setState({ showPhone: true });
  }

  onWebsiteClick = () => {
    this.props.setConnectionClick(this.props.listingId, 'website referral');
    this.props.setExternalLinkImpression(this.props.listingId, this.props.listingId, 'website cta');
  }


  closeModalForm = () => {
    this.handleContactFormVisibility(false);
  }
  getDealerInfo = () => {
    const dealer = get(this.props,'partyDetails', {});
    const dealerAddress = get(dealer,'address', {});
    return {
      id: get(dealer,'id'),
      name: get(dealer,'displayName'),
      address: generateDealerAddress(dealerAddress.street, dealerAddress.city, get(getStateByCode(dealerAddress.countrySubDivisionCode), 'name')),
      phoneNumber: get(dealer,'address.phone'),
      linkContactInfo: false,
      displayPhoneMessage: false,
      showCloseButton: true
    };
  };

  renderLoader = () => <div className="preloader"></div>;

  render() {
    const { partyDetails, brandedHeaderH1 } = this.props;
    const logo = getResizedImageSrc(partyDetails, LOGO_SIZES.brandedHeader.w, LOGO_SIZES.brandedHeader.h);
    const altText = `${partyDetails.name} Logo`;
    const telNumber = partyDetails.address && `tel:${(partyDetails.address.phone)}`;
    const address = get(partyDetails, 'address');
    let websiteUrl = get(address, 'website');
    if (websiteUrl && !websiteUrl.includes('http')) {
      websiteUrl = `http://${websiteUrl}`;
    }

    return (
      <><div className="branded-header" data-e2e="branded-header">
        <div className={logo ? 'logo-container' : 'hide-logo-container'} data-e2e={logo ? 'logo-container' : 'hide-logo-container'}>
          <div className="logo-frame" data-e2e="logo-frame">
            {logo &&
              <LazyLoadImage
                className="logo"
                alt={altText}
                src={logo}
                threshold={200}
                onLoad={() => this.props.setOemBrandProductImpression(get(partyDetails, 'name', '').toLowerCase(), BRAND_BANNER_IMPRESSION, BRAND_BANNER_IMPRESSION, 1)} />}
          </div>
        </div>

        <div className="brand-information" data-e2e="brand-information">
          <div className="information-brand-container" data-e2e="information-brand-container">
            <h1 className="brand-name" data-e2e="brand-name"> {brandedHeaderH1}</h1>
            {websiteUrl &&
              <a className="broker-website-desktop" data-e2e="broker-website-desktop" href={websiteUrl} rel="nofollow noopener" onClick={this.onWebsiteClick} target="_blank">View Website</a>}
            <p className="brand-address" data-e2e="brand-address"> {this.generateBrokerPostalAddress(address)}</p>
          </div>
          <div className="information-brand-container" data-e2e="information-brand-container">
            <p className="brand-description" data-e2e="brand-description">{this.generateDealerDescription(partyDetails)}</p>
            {websiteUrl &&
              <a className="broker-website-mobile" data-e2e="broker-website-mobile" href={websiteUrl} rel="nofollow noopener" onClick={this.onWebsiteClick} target="_blank">View Website</a>}
          </div>
          <div className="information-brand-container buttons-container">
            <div className="buttons-container-flex">
              <div className="single-button-container">
                <button type="button" data-e2e="contact-dealer display-contact-form" className="contact-dealer display-contact-form" onClick={() => this.handleContactFormVisibility(true)}>Contact</button>
                <div className="formModalWrapper">
                  {this.state.form}
                </div>
              </div>
              <div className="single-button-container">
                {(partyDetails.address && partyDetails.address.phone) &&
                  <a type="button" className="call-now" href={telNumber} target="_self" onClick={this.onCallClick}>{this.state.showPhone ? partyDetails.address.phone : 'Call Now'}</a>}
              </div>
            </div>
          </div>
        </div>
      </div><hr className="branded-seperator"></hr></>
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    addLead,
    trackPhoneCall,
    trackPhoneNumberReveal,
    setOemBrandProductImpression,
    setConnectionClick
  }, dispatch)
)(BrandedHeader);

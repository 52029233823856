import React from 'react';
import { get } from 'lodash';
import DealerLocations from '../../../components/DealerLocations';
import {frontloadConnect} from 'react-frontload';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import * as utils from '../../../store/utils';
import {runOnce} from '../../../utils/runOnceHelper';
import DealerMakes from '../../../components/DealerMakes';

const LocationModalContent = ({cities, states, zips, filters}) => {
  const {countrySubDivisionCode, city} = filters;
  let locations;
  if (city) {
    locations = zips;
  } else if (countrySubDivisionCode) {
    locations = cities;
  } else {
    locations = states;
  }

  const colSize = parseInt(locations.length.toFixed(0));
  return (
    <html lang="en">
      <head>
        <meta name="robots" content="noindex,follow" />
      </head>
      <body id="modalDealersSRP">
        <DealerLocations locations={locations} filters={filters} colSize={colSize} modal={'true'} singleView={true} />
      </body>
    </html>
  );
};

const MakesModalContent = ({brands, filters}) => {
  return (
    <html lang="en">
      <head>
        <meta name="robots" content="noindex,follow" />
      </head>
      <body id="modalDealersSRP">
        <DealerMakes allMakes={brands} filters={filters} singleView={true}/>
      </body>
    </html>
  );
};

const ModalContent = (props) => {
  const {cities, states, params, zips, brands} = props;
  const locationProps = { cities, states, zips, filters: params };
  const makesProps = { brands, filters: params };
  const location = props.location;
  const isMakes = location?.pathname.indexOf('modal-make') !== -1;

  return isMakes ? <MakesModalContent {...makesProps} /> : <LocationModalContent {...locationProps} />;
};


const mapStateToProps = state => ({
  params: get(state.app, 'params', {}),
  cities: get(state.app, 'data.cities', []),
  states: get(state.app, 'data.states', []),
  brands: get(state.app, 'data.brands', []),
  zips: get(state.app, 'data.zips', [])
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

const frontloadConnected = frontloadConnect(({dispatch, location, debug = process.env.REACT_APP_LOCAL_DEBUG}) => {
  if (utils.isServer()) {
    return dispatch(actions.getDealersData(location.pathname));
  }

  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  if (justFirsTime() && debug) {
    return dispatch(actions.getDealersData(location.pathname));
  }
}, {
  onUpdate: true,
  onMount: true
})(ModalContent);

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnected);

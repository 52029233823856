export const sortTypes = [
  {
    id: 'recommended-sort',
    title: 'Recommended',
    value: 'recommended:desc'
  },
  {
    id: 'updated-newest-first',
    title: 'Recently Updated: Newest first',
    value: 'updated:desc'
  },
  {
    id: 'updated-oldest-first',
    title: 'Recently Updated: Oldest first',
    value: 'updated:asc'
  },
  {
    id: 'distance-nearest-first',
    title: 'Distance: Nearest first',
    value: 'distance:asc'
  },
  {
    id: 'distance-farthest-first',
    title: 'Distance: Farthest first',
    value: 'distance:desc'
  },
  {
    id: 'year-newest-first',
    title: 'Year: Newest first',
    value: 'year:desc'
  },
  {
    id: 'year-oldest-first',
    title: 'Year: Oldest first',
    value: 'year:asc'
  },
  {
    id: 'length-longest-first',
    title: 'Length: Longest first',
    value: 'length:desc'
  },
  {
    id: 'length-shortest-first',
    title: 'Length: Shortest first',
    value: 'length:asc'
  },
  {
    id: 'price-high-first',
    title: 'Price: High to Low',
    value: 'price:desc'
  },
  {
    id: 'price-low-first',
    title: 'Price: Low to High',
    value: 'price:asc'
  },
];

import { useLayoutEffect } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import * as actions from '../../store/actions/dataLayer';
import { getPageParams } from '../../utils/dataLayerHelper';
import { getDefaultParams} from '../../utils/urlHelpers/listings';
import {MainRequest} from '../../store/reducers/app';
import {IDLE_MAIN_REQUEST} from '../../store/constants';

const DataLayer = ({ location, pageType = 'default', oemDetails = {}, match }) => {
  const dispatch = useDispatch();
  const {isWorking, mainRequestState} = useSelector((state) => {
    const isWorking = state.app.isWorking;
    const mainRequestState = state.app.mainRequestState;
    return {isWorking, mainRequestState};
  });
  const pathname = get(location, 'pathname', '');

  useLayoutEffect(() => {
    const params = {
      ...getPageParams(pageType, oemDetails.brandId),
      searchParams: getDefaultParams(get(match, 'params', {}))
    };
    dispatch(actions.initDataLayer(params));
    if (isWorking) {
      return;
    }
    dispatch(actions.registerPageView({ pathname }));
    dispatch(actions.registerProductImpressions());
    if (mainRequestState === MainRequest.LOADED){
      dispatch({type: IDLE_MAIN_REQUEST});
    }

  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default withRouter(DataLayer);

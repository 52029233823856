import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Draggable from 'react-draggable';
import { clickCarouselThumbnail } from '../../store/actions/dataLayer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MAX_CAROUSEL_THUMBNAILS_WIDTH, CAROUSEL_THUMBNAILS_NUMBER } from '../../constants/boatDetails';
import './styles.css';

class CarouselThumbs extends PureComponent {
  state = {
    firstSelectedItem: this.props.selectedItem,
    index: 0,
    maxIndexNum: parseInt(this.props.mediaItems.length / CAROUSEL_THUMBNAILS_NUMBER),
    controlledPosition: {
      x: 0,
      y: 0
    }
  }

  handleThumbnailClick = (event, key) => {
    this.setState({
      firstSelectedItem: key
    });
    this.props.clickCarouselThumbnail();
    this.props.handleThumbClick(event,key);
  }


  handlePrevArrow = () => {
    const {x, y} = this.state.controlledPosition;
    this.setState(prevState => {
      return {
        index: Math.max(prevState.index - 1, 0),
        controlledPosition: {
          x: prevState.index === this.state.maxIndexNum ? x + ((this.state.totalPixelsLength) - (prevState.index * this.state.maxPixelsToMove)) : Math.min(x + this.state.maxPixelsToMove, 0),
          y
        }
      };
    });
    this.props.handleCarouselClickCount();
  }

  handleNextArrow = () => {
    const {x, y} = this.state.controlledPosition;
    this.setState(prevState => {
      return {
        index: Math.min(prevState.index + 1, this.state.maxIndexNum),
        controlledPosition: {
          x: Math.max(x - this.state.maxPixelsToMove, this.state.maxIndexPixels),
          y
        }
      };
    });
    this.props.handleCarouselClickCount();
  }

  onControlledDrag = (e, position) => {
    const {x} = position;
    this.setState({
      controlledPosition: {
        x,
        y: 0}
    });
  };

  showSelectedItemThumbnail = (selectedItem) => {
    const thumbStep = parseInt(selectedItem / CAROUSEL_THUMBNAILS_NUMBER);
    const positionsToMove = this.state.index - thumbStep;
    const {x, y} = this.state.controlledPosition;
    if (positionsToMove < 0) {
      this.setState(prevState => {
        return {
          index: Math.min(prevState.index - positionsToMove, this.state.maxIndexNum),
          controlledPosition: {
            x: Math.max((x - this.state.maxPixelsToMove) * positionsToMove * -1, this.state.maxIndexPixels),
            y
          },
          firstSelectedItem: selectedItem
        };
      });
    } else if (positionsToMove > 0) {
      this.setState(prevState => {
        return {
          index: Math.max(prevState.index - positionsToMove, 0),
          controlledPosition: {
            x: prevState.index === this.state.maxIndexNum ?
              x + ((this.state.totalPixelsLength) - (prevState.index * this.state.maxPixelsToMove) + this.state.maxPixelsToMove * (positionsToMove - 1)) :
              Math.min(x + this.state.maxPixelsToMove * positionsToMove, 0),
            y
          },
          firstSelectedItem: selectedItem
        };
      });
    }
  }

  componentDidMount = () => {
    let maxPixelsToMove = Math.min(window.innerWidth, MAX_CAROUSEL_THUMBNAILS_WIDTH);
    this.setState({
      maxPixelsToMove: maxPixelsToMove,
      totalPixelsLength: maxPixelsToMove / CAROUSEL_THUMBNAILS_NUMBER * this.props.mediaItems.length,
      maxIndexPixels: -1 * (this.props.mediaItems.length / CAROUSEL_THUMBNAILS_NUMBER * maxPixelsToMove - maxPixelsToMove)
    }, () => {
      if (this.state.firstSelectedItem > CAROUSEL_THUMBNAILS_NUMBER - 1) {
        this.showSelectedItemThumbnail(this.state.firstSelectedItem);
      }
    });
  }

  carouselThumbsWrapper = () => {
    let { mediaItems, selectedItem } = this.props;

    return (
      <div className="carousel-thumbs__wrapper">
        {
          mediaItems.length !== 0 && mediaItems.map((item, key) => {
            let itemThumbnail;
            if (item.mediaType === 'image') {
              itemThumbnail = item.url;
            } else {
              itemThumbnail = item.videoVideoThumbnailUri;
            }
            return (
              <div className={classnames('carousel-thumbs__img-wrapper', {'selected': selectedItem === key})} key={key} >
                <img draggable="false" className="carousel-thumbs__img" onClick={(event) => this.handleThumbnailClick(event, key)} src={itemThumbnail} alt="carousel thumbnail"/>
              </div>
            );
          })
        }
      </div>
    );
  }


  render () {

    let { mediaItems, selectedItem } = this.props;
    let { controlledPosition } = this.state;
    if (selectedItem !== this.state.firstSelectedItem){
      this.showSelectedItemThumbnail(selectedItem);
    }
    return (
      <div className="thumbs-wrapper">
        <div className="carousel-thumbs">
          {this.props.device === 'desktop' && <Draggable
            onStart={() => this.props.device === 'desktop'}
            axis="x"
            bounds={{left: this.state.maxIndexPixels, right: 0}}
            position={controlledPosition}
            onDrag={this.onControlledDrag}>
            {this.carouselThumbsWrapper()}
          </Draggable>}

          {this.props.device !== 'desktop' && this.carouselThumbsWrapper()}
        </div>
        {this.props.device === 'desktop' && mediaItems.length > CAROUSEL_THUMBNAILS_NUMBER && <div onClick={this.handlePrevArrow} className="control-arrow control-prev"></div>}
        {this.props.device === 'desktop' && mediaItems.length > CAROUSEL_THUMBNAILS_NUMBER && <div onClick={this.handleNextArrow} className="control-arrow control-next"></div> }
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    clickCarouselThumbnail
  }, dispatch)
)(CarouselThumbs);

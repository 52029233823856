import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { frontloadConnect } from 'react-frontload';
import {getParamsFromLocationAndHistoryQueryString} from './utils/featureToggles';
import { kameleoonClientInitialize } from './utils/experiment/kameleoon.client';
import { checkMarketingParams } from './utils/urlHelpers/utmParams';
import ErrorBoundary from './components/ErrorBoundary';
import * as actions from './store/actions';
import * as utils from './store/utils';
import Routes from './routes';
import './App.css';

const App = (props) => {
  const COOKIES_TO_BE_REMOVED = [
    {'name': 'ANA-1142', 'path': '*'},
    {'name': 'gdpr', 'path': '*'}
  ];

  useEffect(() => {
    /* istanbul ignore next */
    if (props.cookies) {
      COOKIES_TO_BE_REMOVED.forEach(({ name, path }) => {
        props.cookies.remove(name, { path: path });
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <ErrorBoundary>
        <Routes {...props} />
      </ErrorBoundary>
    </div>
  );
};

const FrontloadConnectedApp = frontloadConnect(async ({ getUserCountryCode, location }) => {
  if (utils.isServer()) {
    const params = getParamsFromLocationAndHistoryQueryString({
      location
    });

    await getUserCountryCode(params);
  } else {
    await kameleoonClientInitialize();
    checkMarketingParams();
  }
})(App);

const ConnectedApp = connect(
  state => state,
  dispatch => bindActionCreators({
    ...actions,
  }, dispatch),
)(FrontloadConnectedApp);

const connectedAppWithCookie = withCookies(ConnectedApp);
export default withRouter(connectedAppWithCookie);

import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { SelectTypeAhead } from '@dmm/lib-react-typeahead';
import '@dmm/lib-react-typeahead/dist/@dmm/lib-react-typeahead.min.css';
import { Action } from '@dmm/lib-react-ui-components';
import React, { useEffect, useRef, useState } from 'react';
import { fetchCitiesFromState, fetchStatesFromMake, fetchZipsFromCity, getSubdivisionCode, urlFromDealerFilters } from '../../../utils/dealerSRPHelper';
import { initialFilters, selectValue, useDealersReducer } from './filters/state';
import './index.css';

const defaultFilterProps = () => {
  const stateProps = {
    options: [1,2],
    name: 'dfState',
    tabIndex: 3,
    label: 'State',
    selected: 'Select State',
    disabled: true
  };
  const cityProps = {
    options: [1,2],
    name: 'dfCity',
    tabIndex: 4,
    label: 'City',
    selected: 'Select City',
    disabled: true
  };
  const zipProps = {
    options: [1,2],
    name: 'dfZip',
    tabIndex: 5,
    label: 'Zip',
    selected: 'Select Zip Code',
    disabled: true
  };
  return { stateProps, cityProps, zipProps };
};

const DFSelect = ({options, name, tabIndex, label, onChange, selected, searchable = false, disabled = false, placeholder = '', dataE2e = ''}) => {
  if (searchable) {
    return (
      <label htmlFor={name} className = "df-select" >
        <span>{label}</span>
        <SelectTypeAhead
          list={options}
          onChange={onChange}
          originalValue={selected}
          disabled={disabled}
          inputId={name}
          placeholderText={placeholder}
          dataE2e={dataE2e}
        />
      </label>
    );
  }
  return (
    <label htmlFor={name} className = "df-select" >
      <span>{label}</span>
      <select aria-label={label} id={name} name={name} data-e2e={name} tabIndex={tabIndex} disabled={disabled} value={selected} onChange={onChange}>
        { options.map((option, i) => <option value={option} key={i} >{option}</option>) }
      </select>
    </label>
  );
};

const DFTextField = ({value, id, tabIndex, label, placeholder, onChange, onEnter}) => {
  const keyUp = ev => {
    if (ev.code === 'Enter') {
      onEnter();
    }
  };
  return (
    <label htmlFor={id} className = "df-input" >
      <span>{label}</span>
      <input type="text"
        placeholder={placeholder}
        aria-label={label}
        id={id}
        name={id}
        data-e2e={id}
        tabIndex={tabIndex}
        value={value}
        onChange={onChange}
        onKeyUp={keyUp} />
    </label>
  );
};

const selectChanger = (label, onChanged, isEvent = true) => {
  const changeAction = (ev) => {
    const selected = isEvent ? selectValue(ev) : ev;
    const val = selected !== label ? selected : '';
    onChanged(val);
  };
  return changeAction;
};

const DealerFilters = ({brands, cities, zips, startParams, history, states}) => {
  const { setKeyword, setBrand, setState, setCity, setZip, setCities, setZips, setStates, setBrands, state, reset } = useDealersReducer(startParams, brands, cities, zips, states);
  const [isActive, setActive] = useState(false);
  const dealerLabel = ' Dealer Name';
  const defaultBrand = 'Select Make';
  const defaultState = 'Select State';
  const defaultCity = 'Select City';
  const defaultZip = 'Select Zip Code';
  const paramsRef = useRef({...startParams});
  const originalParamsRef = useRef({...startParams});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // On first render, if we get data from container, we do not make an extra request
    if (paramsRef.current.city === normalizeString(state.city) && state.cityList.length) {
      paramsRef.current.city = -1;
    } else {
      const cities = state.state ? await fetchCitiesFromState(state.state, state.brand) : [];
      resetCities(cities, '');
    }
  }, [state.state]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // On first render, if we get data from container, we do not make an extra request
    if (paramsRef.current.postalCode === state.zip && state.zipList.length) {
      paramsRef.current.postalCode = -1;
    } else {
      const zips = state.city ? await fetchZipsFromCity(state.state, state.city, state.brand) : [];
      resetZips(zips, '');
    }
  }, [state.city]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // On first render, if we get data from container, we do not make an extra request
    if (paramsRef.current.countrySubDivisionCode === getSubdivisionCode(state.state) && state.stateList.length) {
      paramsRef.current.countrySubDivisionCode = -1;
    } else {
      const states = await fetchStatesFromMake(state.brand);
      resetStates(states, '');
    }
  }, [state.brand]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        if ( history.location.state ){
          const previousState = history.location.state;
          paramsRef.current.city = normalizeString(previousState.city);
          paramsRef.current.countrySubDivisionCode = getSubdivisionCode(previousState.state);
          paramsRef.current.postalCode = previousState.zip;
          paramsRef.current.brand = previousState.brand; // eslint-disable-line react-hooks/exhaustive-deps
          reset(previousState);
        } else {
          reset(initialFilters(originalParamsRef.current, brands, cities, zips, states)); // eslint-disable-line react-hooks/exhaustive-deps
        }
      }
    };
  }, [startParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(brands)) {
      setBrands(brands);
    }
  }, [brands]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetCities = (cities, city) => {
    setCities(cities);
    setCity(city);
    resetZips([]);
  };

  const resetZips = (zipCodes, zip) => {
    setZips(zipCodes);
    setZip(zip);
  };

  const resetStates = (states, state) => {
    setStates(states);
    setState(state);
    resetCities([]);
    resetZips([]);
  };

  const  { stateProps, cityProps, zipProps } = defaultFilterProps();

  const brandList = [defaultBrand, ...state.brandList];
  const stateList = [defaultState, ...state.stateList];
  const cityList = [defaultCity, ...state.cityList];
  const zipList = [defaultZip, ...state.zipList];
  const changeBrands = selectChanger(defaultBrand, setBrand, false);
  const changeStates = selectChanger(defaultState, setState);
  const changeCity = selectChanger(defaultCity, setCity);
  const changeZips = selectChanger(defaultZip, setZip);
  const onChangeKeyword = (ev) => setKeyword(ev.target.value);

  const search = () => {
    const url = urlFromDealerFilters(state);
    history.push(url, {...state});
  };

  stateProps.disabled = state.stateList.length === 0;
  cityProps.disabled = state.cityList.length === 0;
  zipProps.disabled = state.zipList.length === 0;
  return (
    <div className="dealer-filters-content">
      <p className="dealer-filters-title">Find a Boat Dealer</p>
      <div className="df-inputs">
        <DFTextField
          value={state.keyword} onEnter={search}
          onChange={onChangeKeyword} id={'dfName'}
          tabindex={1} label={dealerLabel}
          placeholder={'Enter Dealer Name'} />
        <span className={!isActive ? 'mobileAdvSearch' : 'mobileAdvSearch-hidden'} onClick={() => setActive(true)}>Advanced Search</span>
        <div className={!isActive ? 'mobileHiddenSearch' : 'desktopSearch'}>
          <DFSelect
            options={brandList} name={'dfBrand'}
            label={'Make'}  tabIndex={2}
            searchable={true}
            placeholder = {defaultBrand}
            dataE2e = "dfBrand"
            selected={state.brand} onChange={changeBrands} />
          <DFSelect
            {...stateProps} options={stateList}
            selected={state.state} onChange={changeStates} />
          <DFSelect
            {...cityProps} options={cityList}
            selected={state.city} onChange={changeCity} />
          <DFSelect
            {...zipProps} options={zipList}
            selected={state.zip} onChange={changeZips} />
          <label htmlFor={'df-searchbtn'} className="df-searchbtn" >
            <span>&nbsp;</span>
            <Action onClick={search} data-e2e="search-btn" label="Search" size="small" variant="secondary" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default DealerFilters;

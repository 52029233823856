import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import { SEARCH_URL_PATTERN, SEARCH_URL_PATTERN_BRAND, SEARCH_URL_BRAND_ONLY } from '../constants/boats';
import { LEGACY_SEARCH_URL_PATTERN, NODE4_SEARCH_URL_PATTERN } from '../constants/searchResults';
import { HULL_TYPE_FIBERGLASS_REINFORCED_OLD, HULL_TYPE_WOOD_OLD } from '../constants/boats';
import { LEGACY_BROWSE_URL_PATTERN } from '../constants/browse';
import { BOAT_DETAILS_URL_PATTERN } from '../constants/boatDetails';
import { HEALTHCHECK_URL_PATTERN } from '../constants/healthcheck';
import { DRIVEWAY_DIRECT_URL_PATTERN} from '../constants/drivewayDirect';
import { MYBOATS_URL, MYBOATS_TAB_URL } from '../constants/myboats';
import { BOAT_LOANS_URL } from '../constants/BoatLoans';
import { BOAT_LOANS_CALCULATOR_URL } from '../constants/BoatLoansCalculator';
import Healthcheck from '../containers/Healthcheck';
import { rewriteLegacySearchResultsUrl } from '../utils/urlHelpers/listings';
import { rewriteLegacyBrowseUrl } from '../utils/urlHelpers/browse';
import { rewriteLegacyDealerSearchResultsUrl } from '../utils/urlHelpers/boatDealers';
import { rewriteLegacyEnginesPage } from '../utils/urlHelpers/engines';
import { rewriteOriginalSearchResultsUrl } from '../utils/urlHelpers/searchResults';
import { redirectOldFiberglassReinforced, redirectOldWood } from '../utils/seoContentHelper';
import { OAUTH_REDIRECT_URL, LOGIN_LANDING_URL, FSBO_LANDING_URL } from '../constants/engagement';
import { HOME_URL_PATTERN } from '../constants/home';
import { SOCIAL_BROWSE_PAGE_URL_PATTERN } from '../constants/SocialBrowsePage';

import {
  SERVICES_URL,
  WARRANTY_URL,
  SHIPPING_URL,
  INSURANCE_URL,
  DOCUMENTATION_URL,
  HISTORY_URL,
  RENTAL_URL,
  SERVICES_URL_PATTERN,
} from '../constants/services';

import { EXTENDED_SERVICES_PLAN_URL, TIRE_AND_WHEEL_PLAN_URL, GAP_PROTECTION_PLAN_URL, ROAD_ASSISTANCE_PLAN_URL } from '../constants/TridentServices';
import {AD_AND_SPONSORSHIP_URL} from '../constants/adSponsorship';
import { ABOUT_URL } from '../constants/editorialContent';
import { BOAT_CLASS_URL_PATTERN } from '../constants/BoatClass';

/* React Loadable Routes */
import LoadableSearchResults from '../containers/SearchResults/BoatsIndex';
import LoadableBoatDetails from '../containers/BoatDetails';
import LoadableWarranty from '../containers/Services/ChildPages/Warranty';
import LoadableInsurance from '../containers/Services/ChildPages/Insurance';
import LoadableShipping from '../containers/Services/ChildPages/Shipping';
import LoadableDocumentation from '../containers/Services/ChildPages/Documentation';
import History from '../containers/Services/OtherServices/History';
import LoadableRental from '../containers/Services/ChildPages/Rental';
import LoadableServices from '../containers/Services';
import LoadableAdandSponsorship from '../containers/AdandSponsorship';
import LoadableNotFoundPage from '../containers/NotFoundPage';
import LoadableEngines from '../containers/SearchResults/EnginesIndex';
import DrivewayDirect from '../containers/DrivewayDirect';
import OtherServiceRoutes from '../containers/Services/OtherServices';
import LoadableBoatLoansV2 from '../containers/BoatLoansV2';
import LoadableBoatLoansCalculator from '../containers/Services/ChildPages/BoatLoansCalculatorV2';
import LoadableExtendedServicesPlan from '../containers/Services/ChildPages/ExtendedServicesPlan';
import LoadableRoadAssistancePlan from '../containers/Services/ChildPages/RoadAssistance';
import LoadableTireAndWheelPlan from '../containers/Services/ChildPages/TireAndWheelPlan';
import LoadableGAPProtectionPlan from '../containers/Services/ChildPages/GAPProtectionPlan';
import MyBoats from '../containers/MyBoats';
import { BOAT_DEALERS_URL_PATTERN, BOAT_DEALER_LEGACY_URL, BOAT_DEALER_LEGACY_SRP_URL,
  BOAT_DEALERS_MODAL_URL_PATTERN, BOAT_DEALERS_MODAL_MAKE_URL_PATTERN } from '../constants/BoatDealers';
import LoadableBoatDealers from '../containers/BoatDealers';
import LoadableModalBoatDealers from '../containers/BoatDealers/ModalContent';
import {ENGINES_SEARCH_URL_PATTERN} from '../constants/Engines';
import OauthRedirect from '../containers/OauthRedirect';
import LoginLanding from '../containers/LoginLanding';
import { ENGINES_LEGACY_URL } from '../constants/Engines';
import FSBOLanding from '../containers/FSBOLanding';
import Home from '../containers/Home';
import {BOAT_TYPES_URL_PATTERN} from '../constants/BoatTypes';
import BoatTypes from '../containers/BoatTypes';
import SocialBrowsePage from '../containers/SocialBrowsePage';
import EditorialContent from '../containers/EditorialContent';
import BoatClass from '../containers/BoatClass';
import { ConciergeContainer, CONCIERGE_URL, } from '../containers/Concierge';

/* eslint-disable react/display-name */
export default () => (
  <Switch>
    <Route exact path={HOME_URL_PATTERN} component={Home} />
    <Route exact path={HEALTHCHECK_URL_PATTERN} component={Healthcheck} />
    <Route exact path={HULL_TYPE_FIBERGLASS_REINFORCED_OLD} render={
      () => <Redirect to={redirectOldFiberglassReinforced()} />
    } />
    <Route exact path={HULL_TYPE_WOOD_OLD} render={
      () => <Redirect to={redirectOldWood()} />
    } />
    <Route exact path={SEARCH_URL_PATTERN} component={LoadableSearchResults} />
    <Route exact path={SEARCH_URL_BRAND_ONLY} component={LoadableNotFoundPage} />
    <Route exact path={SEARCH_URL_PATTERN_BRAND} component={LoadableSearchResults} />
    <Route path={LEGACY_SEARCH_URL_PATTERN} render={
      (props) => <Redirect to={rewriteLegacySearchResultsUrl(props)} />
    } />
    <Route path={NODE4_SEARCH_URL_PATTERN} render={
      (props) => <Redirect to={rewriteOriginalSearchResultsUrl(props)} />
    } />
    <Route path={ENGINES_LEGACY_URL}  render={
      (props) => <Redirect to={rewriteLegacyEnginesPage(props)} />
    } />
    <Route path={LEGACY_BROWSE_URL_PATTERN} render={
      (props) => <Redirect to={rewriteLegacyBrowseUrl(props)} />
    } />
    <Route path={BOAT_DETAILS_URL_PATTERN} component={LoadableBoatDetails} />
    <Route path={WARRANTY_URL} component={LoadableWarranty} />
    <Route path={BOAT_LOANS_CALCULATOR_URL} component={LoadableBoatLoansCalculator} />
    <Route path={EXTENDED_SERVICES_PLAN_URL} component={LoadableExtendedServicesPlan} />
    <Route path={ROAD_ASSISTANCE_PLAN_URL} component={LoadableRoadAssistancePlan} />
    <Route path={TIRE_AND_WHEEL_PLAN_URL} component={LoadableTireAndWheelPlan} />
    <Route path={GAP_PROTECTION_PLAN_URL} component={LoadableGAPProtectionPlan} />
    <Route path={BOAT_LOANS_URL} component={LoadableBoatLoansV2} />
    <Route path={SHIPPING_URL} component={LoadableShipping} />
    <Route path={INSURANCE_URL} component={LoadableInsurance} />
    <Route path={DOCUMENTATION_URL} component={LoadableDocumentation}/>
    <Route path={RENTAL_URL} component={LoadableRental}/>
    <Route path={HISTORY_URL} component={History}/>
    {OtherServiceRoutes}
    <Route exact path={SERVICES_URL} component={LoadableServices} />
    <Route path={AD_AND_SPONSORSHIP_URL} component={LoadableAdandSponsorship} />
    <Route exact path={DRIVEWAY_DIRECT_URL_PATTERN} component={DrivewayDirect}/>
    <Route path={MYBOATS_URL} component={MyBoats} />
    <Route path={MYBOATS_TAB_URL} component={MyBoats} />
    <Route path={OAUTH_REDIRECT_URL} component={OauthRedirect}/>
    <Route path={LOGIN_LANDING_URL} component={LoginLanding}/>
    <Route exact path={BOAT_DEALERS_URL_PATTERN} component={LoadableBoatDealers} />
    <Route exact path={BOAT_DEALERS_MODAL_MAKE_URL_PATTERN} component={LoadableModalBoatDealers} />
    <Route path={BOAT_DEALER_LEGACY_URL}  render={
      (props) => <Redirect to={rewriteLegacyDealerSearchResultsUrl(props)} />
    } />
    <Route path={BOAT_DEALER_LEGACY_SRP_URL} render={
      (props) => <Redirect to={rewriteLegacyDealerSearchResultsUrl(props)} />
    } />
    <Route exact path={BOAT_DEALERS_MODAL_URL_PATTERN} component={LoadableModalBoatDealers} />
    <Route exact path={ENGINES_SEARCH_URL_PATTERN} component={LoadableEngines} />
    <Route path={FSBO_LANDING_URL} component={FSBOLanding} />
    <Route exact path={BOAT_TYPES_URL_PATTERN} component={BoatTypes} />
    <Route exact path={BOAT_CLASS_URL_PATTERN} component={BoatClass}/>
    <Route exact path={SOCIAL_BROWSE_PAGE_URL_PATTERN} component={SocialBrowsePage}/>
    <Route exact path={ABOUT_URL} component={EditorialContent}/>
    <Route exact path={CONCIERGE_URL} component={ConciergeContainer} />
    <Route path={SERVICES_URL_PATTERN} component={LoadableNotFoundPage} />
    <Route path="*" component={LoadableNotFoundPage} />
  </Switch>
);

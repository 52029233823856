import React, { Component } from 'react';
import DefaultLayout from '../../layouts/Default';
import DataLayer from '../../components/DataLayer';
import './styles.css';

class FSBOLanding extends Component {

  componentDidMount() {
    //--for page overrides
    document.body.classList.add('fsbo-landing');
  }

  render() {
    return (
      <DefaultLayout {...this.props} pageType="fsbo-landing" hideAuth={true} noIndex={true}>
        <DataLayer pageType="fsbo-landing" />
        <div className="fsbo-landing-container">
          <img src="https://www.boattrader.com/static/app/boattrader-icon.png" alt="logo" />
          <p>Whether you&apos;re cashing out of your current boat or upgrading to a bigger one, Boat Trader will help you sell your used boat online fast.</p>
          <a className="button" ref={el => (this.nofollow = el)} href="/sell/?utm_source=btma&utm_medium=banner&utm_campaign=mobile">Sell your boat now!</a>
          <a className="anchor" ref={el => (this.nofollow = el)} href="boattrader://home/?utm_source=portal&utm_campaign=fsbo_redirect">Back to the app</a>
        </div>
      </DefaultLayout>
    );
  }
}

export default FSBOLanding;

import { useEffect, useReducer, useRef, useState } from 'react';

export const DEFAULT_DEBOUNCE_TIMEOUT = 1000;

const reducer = (prevState, updated) => ({
  ...prevState,
  ...updated
});

/* istanbul ignore next */
export const USE_DEBOUNCE_DEFAULTS = {
  delay: DEFAULT_DEBOUNCE_TIMEOUT,
  initialState: {},
  initStateFn: (value) => value,
  callback: () => {} // <= This was the only line uncovered in tests, any help is welcome
};

/**
 * useDebounce
 * - Based on https://usehooks.com/useDebounce/
 * - Slightly customized to allow update state with no debounce too.
 * - We use useReducer to have a similar behavior as this.setState().
 * - Track last change.
 * - Allow edit without calling callback with setStateWithNoCallback.
 */
export default function useDebounce(options = {}) {
  const { delay, initialState, initStateFn, callback } = { ...USE_DEBOUNCE_DEFAULTS, ...options };

  // State and setters for debounced value
  const [state, setState] = useReducer(reducer, initialState, initStateFn);
  const [debouncedState, setDebouncedState] = useReducer(reducer, initialState, initStateFn);
  const [lastChange, setLastChange] = useState(() => initStateFn(initialState));

  const timeoutHandlerRef = useRef();

  // Auxiliar variable to prevent calling callback twice on-mount (one per useEffect)
  // or when we use setStateImmediately
  const skipDebounce = useRef(2);

  const setStateWithDebounce = (newState) => {
    setLastChange(newState);
    setState(newState);
  };

  // In case we require update value with no debounce
  const setStateImmediately = (newState) => {
    setLastChange(newState);
    setState(newState);
    setDebouncedState(newState);
    skipDebounce.current = 1;
  };

  const setStateWithNoCallback = (newState) => {
    setLastChange(newState);
    setState(newState);
    setDebouncedState(newState);
    skipDebounce.current = 2;
  };

  useEffect(
    () => {
      if (skipDebounce.current > 0) {
        skipDebounce.current--;
        return;
      }
      // Update debounced value after delay
      timeoutHandlerRef.current = setTimeout(() => {
        setDebouncedState(state);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => clearTimeout(timeoutHandlerRef.current);
    },
    [state, delay] // Only re-call effect if value or delay changes
  );

  useEffect(() => {
    if (skipDebounce.current > 0) {
      skipDebounce.current--;
      return;
    }
    callback && callback(debouncedState);
  }, [debouncedState]); // eslint-disable-line react-hooks/exhaustive-deps

  return [state, setStateWithDebounce, setStateImmediately, lastChange, setStateWithNoCallback];
}

import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const RichSnippet = ({ schema }) => (
  <Helmet>
    <script type="application/ld+json">{JSON.stringify(schema)}</script>
  </Helmet>
);

RichSnippet.propTypes = {
  schema: PropTypes.shape({}).isRequired
};

export default  RichSnippet;

import React, { Component } from 'react';
import classnames from 'classnames';
import './styles.css';
import Link from '../SEO/Link';

class PaginationItem extends Component {
  render() {
    let { disabled, href, type, children, onClick, active } = this.props;
    return <li>
      {disabled ?
        <a className={classnames({ disabled, [type]: type })}>{children}</a>
        :
        <Link onClick={onClick} to={href} className={classnames({ disabled, [type]: type, active })}>{children}</Link>}
    </li>;
  }
}

export default PaginationItem;

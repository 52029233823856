import {Ad} from '@dmm/react-common-components';
import React from 'react';

export const BoatTypeAdd = ({id, classes}) => {
  const layoutClass = id.match(/mobile/) ? 'boattype-add-mobile' : 'boattype-add-desktop';
  return (
    <div className={layoutClass}>
      <Ad adId={id} classes={classes} />
    </div>
  );
};

import React from 'react';
import { Route } from 'react-router-dom';
import Layout from './Layout';
import configs from './configs';
import { values } from 'lodash';

const ResourceRoutes = values(configs).map((config, index) => {
  const { formattedText: FormattedText } = config;

  return (
    <Route
      key={ index }
      path={ config.path }
      component={
        (props) => <Layout {...props}
          link={ config.path }
          pageType={ config.pageType }
          pageName={ config.pageName }
          title={ config.title }
          upperTitle={ config.upperTitle }
          paragraph={ config.paragraph }
          formattedText={ FormattedText }
          prebids={ config.prebids }
        />
      }
    />
  );
});

export default ResourceRoutes;

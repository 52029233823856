import {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import { BRANCH_EVENT, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import {
  cleanActiveParams,
  currentUrlState,
  generateSearchPath, getActiveParams,
  getDefaultParams,
  shouldBypassUrl
} from '../../urlHelpers/listings';
import * as actions from '../../../store/actions';
import {getParamsFromLocationAndHistoryQueryString} from '../../featureToggles';
import {readGroupedUrlParamsState} from '../../urlHelpers/searchResults';
import {BOATS_SRP_PATH, BRANDS_ID} from '../../../constants/boats';
import stringify from 'qs/lib/stringify';
import {setSearchTracking} from '../../../store/actions/dataLayer';
import {getEnginesFacets, getFacets} from '../../../store/actions';
import {ENGINES_ID} from '../../../constants/home';
import {enginePillBoxFormat} from '../../engineTypeHelper';

const generatePathFromFilters = (filters, location, history, match, searchPage) => {
  let otherParams = getParamsFromLocationAndHistoryQueryString({ location, history });
  const matchParams = match?.params || {};
  let params = getDefaultParams(matchParams);
  // When we have groups we need to decouple the state from the url.
  // I don't include the ungrouped state on generateSearchPath because that function is called thousands of times!!!!
  const ungroupedState = readGroupedUrlParamsState(filters);
  let newPath = generateSearchPath(ungroupedState, {...params}, true, Object.keys(params.oem)[0] ? BRANDS_ID : searchPage);
  if (Object.keys(otherParams).length > 0) {
    newPath = `${newPath}?${stringify(otherParams)}`;
  }
  return newPath;
};

const goToNewPath = (newPath, dispatch, history, cookies, abTestConfiguration) => {
  if (shouldBypassUrl(newPath)) {
    dispatch(actions.bypassingUrl(newPath));
    dispatch(actions.getData(newPath, cookies, {}, abTestConfiguration));
  } else {
    history.push(newPath);
  }
};

export const useBTFilters = () => {
  const [filters, setFilters] = useState(null);
  const dispatch = useDispatch();
  const changeFilters = useCallback((newFilters) => {
    console.log('changeFilters', newFilters);
    setFilters(newFilters);
  } , []);
  return [filters, changeFilters, dispatch];
};

const getMobileParams = (filters, searchPage) => {
  let mobileActiveParams = filters ? getActiveParams(filters, searchPage) : null;
  if (searchPage === ENGINES_ID) {
    mobileActiveParams = enginePillBoxFormat(mobileActiveParams);
  }
  return mobileActiveParams;
};

const getCurrentParams = (match, searchPage) => {
  const urlState = currentUrlState(match, searchPage);
  let currentParams = getActiveParams(urlState, searchPage);
  if (searchPage === ENGINES_ID) {
    currentParams = enginePillBoxFormat(currentParams);
  }
  return currentParams;
};

const defaultMobileRequestData = (urlState, filters, searchPage, dispatch, history, cookies, abTestConfiguration) => {
  const ungroupedState = readGroupedUrlParamsState(filters);
  const path = generateSearchPath(ungroupedState, urlState, true, searchPage);
  const newPath = searchPage === BRANDS_ID && path.endsWith('/brands/') ? BOATS_SRP_PATH : path;
  goToNewPath(newPath, dispatch, history, cookies, abTestConfiguration);
};

// TODO: update action for trailers, engines and rest of possible pages if needed.
// On boats, getFacets makes a request after we click on a filter to "repaint" facets
// It makes a second request after search so, basically, on mobile devices we make two requests.
// Probably we should change this behavior.
const getDefaultFacets = (filters, urlState, searchPage, dispatch) => {
  const params = urlState;
  // When we have groups we need to decouple the state from the url.
  // readGroupedUrlParamsState is not included on generateSearchPath because that function is called thousands of times!!!!
  const ungroupedData = readGroupedUrlParamsState(filters);
  let path = generateSearchPath(ungroupedData, params, true, searchPage);
  if (searchPage === ENGINES_ID) {
    dispatch(getEnginesFacets(path));
    return;
  }
  dispatch(getFacets(path));
};

export const useMobileRefineSearchHandlers = ({history, match, cookies, abTestConfiguration, searchPage, onDataChange}) => {
  const [filters, setFilters, dispatch] = useBTFilters();
  const [open, setOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const urlState = useMemo(() => currentUrlState(match, searchPage), [match, searchPage]);

  const handleOpen = useCallback(() => {
    document.body.classList.add('filters-open');
    setOpen(true);
    setChanged(false);
  }, []);

  const handleDataChange = useCallback((newFilters) => {
    setFilters(newFilters);
    setChanged(true);
    getDefaultFacets(newFilters, urlState, searchPage, dispatch);
  }, [urlState, searchPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback(() => {
    document.body.classList.remove('filters-open');
    if (changed && typeof onDataChange === 'function') {
      onDataChange(filters);
    } else {
      if (changed) {
        const cleanUrlState = cleanActiveParams(urlState);
        const cleanFilters = cleanActiveParams(filters);
        if (cleanUrlState !== cleanFilters) {
          defaultMobileRequestData(urlState, filters, searchPage, dispatch, history, cookies, abTestConfiguration);
        }
      }
      dispatch(setSearchTracking(filters, 'group'));
      branchIoEventsManager(BRANCH_EVENT.SEARCH);
    }
    setOpen(false);
    setFilters(null);
  }, [urlState, changed, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const mobileActiveState = useMemo(() => {
    const activeMobileParams = getMobileParams(filters, searchPage);
    const activeUrlState = getCurrentParams(match, searchPage);
    return { activeMobileParams, activeUrlState };
  }, [filters, searchPage, match]);

  return {
    mobileParams: mobileActiveState.activeMobileParams,
    currentParams: mobileActiveState.activeUrlState,
    open,
    changed,
    handleOpen,
    handleDataChange,
    handleSubmit
  };
};

export const useRefineSearchHandler = ({location, history, match, cookies, abTestConfiguration, searchPage, onDataChange}) => {
  const [filters, changeFilters, dispatch] = useBTFilters();
  const defaultHandleDataChange = useCallback((newFilters) => {
    changeFilters(newFilters);
    const newPath = generatePathFromFilters(newFilters, location, history, match, searchPage);
    goToNewPath(newPath, dispatch, history, cookies, abTestConfiguration);
  }, [location, history, searchPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDataChange = typeof onDataChange === 'function' ? onDataChange : defaultHandleDataChange;
  return [filters, handleDataChange];
};


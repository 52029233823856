import React, {Component} from 'react';

import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';
import Spinner from '../../Spinner';

export class AddressMap extends Component {

  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false
  };

  latLong = Array.isArray(this.props.coordinates) ? {
    lat: this.props.coordinates[1],
    lng: this.props.coordinates[0]
  } : null;

  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });
  }

  onInfoWindowClose = () => {
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });
  }

  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
    }
  }

  render() {
    if (!this.props.loaded) {
      return (
        <div>
          <Spinner />
        </div>
      );
    }

    if (!this.latLong) {
      return null;
    }

    return (
      <div className="address-map">
        <Map
          className="map"
          title="map showing the location of the dealer"
          google={this.props.google}
          disableDefaultUI={true}
          onClick={this.onMapClicked}
          style={{ height: '100%', position: 'relative', width: '100%' }}
          zoom={14}
          initialCenter={this.latLong}
        >
          <Marker
            name="Marker 1"
            onClick={this.onMarkerClick}
            position={this.latLong}
          />

          <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h4>{this.props.name}</h4>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
  LoadingContainer: Spinner
})(AddressMap);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TrustBox from '../TrustBox';

import './styles.css';
import { TRUST_PILOT_TEXT, TRUST_PILOT_TEXT_MOBILE, TRUST_PILOT_TITLE } from '../../../../../../constants/BoatLoansCalculator';

export const TrustPilotReviews = ({ goToApplyNow, dataTestId = 'trust-pilot-component', isMobile }) => {

  const scrollHandler = () =>{
    const buttonsContainer = document.querySelector('.calculator-container');
    const buttonsOffset = (buttonsContainer.offsetTop + buttonsContainer.offsetHeight - 80) - window.scrollY;
    const stickyButtonsContainer = document.querySelector('.trust-pilot-main-container .sticky-buttons');

    if (buttonsOffset < 0){
      stickyButtonsContainer.classList.remove('hide');
    } else {
      stickyButtonsContainer.classList.add('hide');
    }
  };


  useEffect(() =>{
    if (isMobile){
      window.addEventListener('scroll', scrollHandler);
    }
    return () =>{
      if (isMobile){
        window.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [isMobile]);

  return (
    <>
      <div className="trust-pilot-main-container">
        <div className="trust-pilot-header">
          <div className="trust-pilot-title">{TRUST_PILOT_TITLE}</div>
          <div className="trust-pilot-text">{isMobile ? TRUST_PILOT_TEXT_MOBILE : TRUST_PILOT_TEXT}</div>
        </div>
        <div className="trust-pilot-section desktop" data-testid={dataTestId}>
          <TrustBox
            locale="en-US"
            templateId="53aa8912dec7e10d38f59f36"
            height="140px"
            width="100%"
            theme="light"
            color="#676767"
            stars="3,4,5"
            reviewLanguages="en"
          />
        </div>
        <div className="trust-pilot-section mobile" data-testid={dataTestId}>
          <TrustBox
            locale="en-US"
            templateId="53aa8912dec7e10d38f59f36"
            height="350px"
            width="100%"
            theme="light"
            color="#676767"
            stars="3,4,5"
            reviewLanguages="en"
          />
        </div>
        {!isMobile &&
        <div className="trust-pilot-cta">
          <a className="prequalify-button-calc" onClick={goToApplyNow}>
            Get Pre-qualified
          </a>
        </div>
        }

        {isMobile &&
          <div className="sticky-buttons hide">
            <button
              onClick={goToApplyNow}
              className="sticky-prequalify-button-calc">
              Get Pre-qualified
            </button>
          </div>
        }

      </div>
    </>
  );
};

TrustPilotReviews.propTypes = {
  dataTestId: PropTypes.string,
  isMobile: PropTypes.bool,
  goToApplyNow: PropTypes.func,
};

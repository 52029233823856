import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Ad, AdProvider } from '@dmm/react-common-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { adsConfig } from './resources/adsConfig';
import DefaultLayout from '../../../../layouts/Default';
import {
  SERVICES_URL,
  SHIPPING_URL,
  SHIPPING_SERVICE
} from '../../../../constants/services';

import BreadCrumb from '../../../../components/BreadCrumb';
import ServiceChildTitle from '../../../../components/ServiceChildTitle';
import RichSnippet from '../../../../components/RichSnippet';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import { setExternalLinkImpression } from '../../../../store/actions/dataLayer';
import faq from './faq.json';
import '../styles.css';

class Shipping extends PureComponent {
  componentDidMount() {
    this.props.setExternalLinkImpression(SHIPPING_SERVICE, 'dfp', 'box 1');
    this.props.setExternalLinkImpression(SHIPPING_SERVICE, 'dfp', 'box 2');
    this.props.setExternalLinkImpression(SHIPPING_SERVICE, 'dfp', 'box 3');
  }

  renderAccordionTitle = (text) => {
    return (
      <AccordionItemHeading>
        <AccordionItemButton>
          <h2 className="accordion__title">
            {text}
          </h2>
        </AccordionItemButton>
      </AccordionItemHeading>
    );
  }

  render() {
    return (
      <>
        <RichSnippet schema={getFAQSchema(faq)} />
        <AdProvider targeting={{ page: 'shipping' }} adsConfig={adsConfig}>
          <DefaultLayout {...this.props} pageType="ShippingServices">
            <div className="service-page">
              <ServiceChildTitle title="Boat Transport" />
              <div className="service-content">
                <BreadCrumb items={[{
                  title: 'Home',
                  link: '/'
                }, {
                  title: 'Services',
                  link: SERVICES_URL
                }, {
                  title: 'Boat Transport',
                  link: SHIPPING_URL
                }]} />
                <div className="main-section dfp">
                  <div className="service-info">
                    <h1>Boat Transport</h1>
                    <p>
                      When it comes to shipping your boat, you want the safest transport at the best rate. Boat Trader
                      can help you find reliable transporters and helpful tips for preparing your boat for transport.
                    </p>
                  </div>
                </div>
                <div className="ads">
                  <Ad {...{ adId: 'div-gpt-custom-box-1', classes: 'box-ad' }} />
                  <Ad {...{ adId: 'div-gpt-custom-box-2', classes: 'box-ad' }} />
                  <Ad {...{ adId: 'div-gpt-custom-box-3', classes: 'box-ad' }} />
                </div>
                <Accordion
                  allowMultipleExpanded
                  allowZeroExpanded
                  preExpanded={['shipping-steps']}
                >
                  <AccordionItem uuid="shipping-steps">
                    {this.renderAccordionTitle('Steps in the Boat Shipping Process')}
                    <AccordionItemPanel>
                      <ol>
                        <li>
                          Determine the best boat transport option for the size and type of craft, whether that’s
                           shipping over land, via a yacht transport ship, or on its own bottom with a delivery captain.
                        </li>
                        <li>
                          Detail the shipping requirements the boat movers will need to know, including the timeframe,
                            locations for pick-up and delivery, measurements and dimensions of the boat, trailer
                            requirements that may exist, and your budget.
                        </li>
                        <li>
                          Shop for boat shipping companies, yacht hauling, and delivery services as appropriate, and
                            compare their quotes.
                        </li>
                        <li>
                          Contact the boat delivery specialists you have chosen and set up the dates. Then share the
                           logistical information with the marinas, boat storage facilities or other businesses that may
                           be involved to coordinate pickup and drop-off of the vessel.
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Determining the Best Boat Transport Option')}
                    <AccordionItemPanel>
                      <p>
                        Most boat shipment can take place over land. This is commonly the most cost-effective, fastest,
                        and safest method. In order for boat haulers to trailer down the highway without special permits
                        and road surveys, a boat usually has to meet the following requirements:
                      </p>
                      <ol>
                        <li>
                          Under 12’ wide
                        </li>
                        <li>
                          No higher than 13’6” including masts, keels, and trailer height
                        </li>
                        <li>
                          Located in and brought to an accessible area with at least 14’ of overhead clearance
                        </li>
                      </ol>
                      <p>
                      If your boat fits these parameters, then boat hauling over land is most likely your best option.
                      When it comes to yacht delivery for larger vessels, shipping the boat over water may offer a
                      better alternative. There are many yacht delivery captains and services that maintain entire
                      crews, but determining final prices can be challenging because you may also have to plan the
                      costs of fuel, transient mooring, the crew’s return travel, and food and provisions. The contract
                      should also clearly indicate who is responsible for additional costs that may arise due to
                      weather delays or mechanical difficulties. As you choose a boat delivery service or crew, check
                      their references and make sure the proper insurance is in place, as well.
                      </p>
                      <p>
                      Boats over 40’ in length, especially those headed for distant ports or overseas, are often placed
                      on yacht transport ships. This means getting in touch with a freight forwarding service (an
                      ocean transport intermediary, or OTI) or a common carrier (called a non-vessel operating common
                      carrier, or NVOCC). These services deal with shipping carriers on a regular basis and know how
                      to reserve the space aboard a ship, often have contracts with shipping companies already in
                      place, and can complete all the necessary paperwork.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Boat Shipping Requirements')}
                    <AccordionItemPanel>
                      <p>
                        If your boat isn’t already sitting on its own trailer, boat haulers will use the dimensions of
                        your boat to determine the equipment they need to use and the price they will charge – so it’s
                        very important to make sure you have the correct specifications. Even if it does have a trailer,
                        this information may be necessary because some boat transport companies will keep the boat and
                        trailer together and place the entire package on a larger trailer along with other boats. To
                        ensure the service provides can give you an accurate quote and that the job goes smoothly, make
                        sure you have all the specifications for your boat plus specific pick-up and drop-off locations
                        nailed down prior to contacting them.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Shopping for Boat Shipping Services')}
                    <AccordionItemPanel>
                      <p>
                        Just as you shop around for your boat, now it makes sense to shop around for a boat mover.
                        Getting several estimates is a good idea and will allow you to identify the most affordable
                        option. Don’t make a decision based purely on price, however. Your new boat is important and you
                        want to ensure that the boat transport specialists you entrust it to are professionals who will
                        take care of it like it was their own. Checking references is the best way to research a boat
                        shipping services’ reputation. You’ll also want to be sure to verify that they have sufficient
                        insurance coverage.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Boat Transport Contracts')}
                    <AccordionItemPanel>
                      <p>
                        Reputable professional boat shippers will have standard contracts that cover all the bases. An
                        important factor that many people forget about is making sure that the other pieces of the
                        puzzle are in place before the boat is shipped. The marina (or other locations) at either end of
                        the shipping process need to be informed of the pick-up and delivery. It’s also important to
                        make sure there’s sufficient space for loading and unloading of the boat, which requires both
                        access by road and overhead clearance (often of 14’ though this may vary by shipper and by
                        boat).
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Final Boat Delivery Considerations')}
                    <AccordionItemPanel>
                      <p>
                        In certain circumstances, there are a few final considerations to be made prior to boat
                        transport. One issue that may arise when you buy a used boat with a trailer online is that you
                        can’t inspect it prior to shipping. Since you can’t really confirm the mechanical condition of
                        the trailer in this case, it’s best to inform the shipping company that the trailer needs to be
                        hauled, too, as opposed to being towed on its own wheels.
                      </p>
                      <p>
                        Another unusual circumstance can arise when an on-water delivery gets derailed by uncontrollable
                        circumstances, like a crew member falling ill or a hurricane barreling up the coast. Because of
                        issues like these, it’s a good idea to save all the information you initially gathered when
                        researching boat transport so you have the contact names and numbers for some back-up close at
                        hand.
                      </p>
                      <p>
                      Although the vast majority of the time shipping a boat goes smoothly, like anything in life there
                      are occasional bumps in the road. But if you do your research thoroughly, contract with a
                      reputable company, and prepare properly, chances are you’ll be enjoying your new boat in no time.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('FAQs')}
                    <AccordionItemPanel>
                      {
                        faq.map((faqItem, index) => (
                          <>
                            <h3 key={`question-${index}`} className="question">{faqItem.question}</h3>
                            <p key={`answer-${index}`}>{faqItem.answer}</p>
                          </>
                        ))
                      }
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </DefaultLayout>
        </AdProvider>
      </>
    );
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  setExternalLinkImpression
}, dispatch))(Shipping);

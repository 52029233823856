import {
  TRIDENT_SERVICES_CDN_URL,
  EXTENDED_SERVICES_LOW_MONTHLY_COST_ICON,
  EXTENDED_SERVICES_EASILY_TRANSFERABLE_ICON,
  EXTENDED_SERVICES_COVERAGE_FOR_DEDUCTIBLES_ICON,
  EXTENDED_SERVICES_FAST_RELIABLE_ICON,
  EXTENDED_SERVICES_EMERGENCY_SERVICES_ICON,
  EXTENDED_SERVICES_CONCIERGE_SUPPORT_ICON,
  EXTENDED_SERVICES_REPAIR_COVERAGE_ICON,
} from '.';

export const roadAssistanceInfo = {
  topSection: {
    title: 'Emergency Assistance Both On and Off the Water',
    subtitle: 'Coverage for cars, boats, trailers, RVs, motorcycles, and ATVs',
    bullets: [
      {
        title: 'Fast & Reliable',
        text: 'Access to our extensive service network across the country',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_FAST_RELIABLE_ICON}`,
      },
      {
        title: '24/7 Emergency Services',
        text: 'Towing, jump starts, tire assistance, fuel delivery, lockout services, and winch outs',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_EMERGENCY_SERVICES_ICON}`,
      },
      {
        title: '24/7 Concierge Support',
        text: 'Expert service for trip planning, reservations and other boating needs',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_CONCIERGE_SUPPORT_ICON}`,
      },
    ],
  },
  middleSection: {
    title: 'Why Invest in a Roadside Assistance Plan for Your Boat?',
    text: 'Roadside assistance for boats is designed to provide immediate help and support when you encounter issues on and off the water. It ensures that you have access to professional assistance whenever you need it, giving you the confidence to navigate the waterways without worry.',
  },
  bottomSection: {
    title: 'Roadside Assistance Plan Benefits',
    bullets: {
      firstRow: [
        {
          title: 'Hassle-Free Peace of Mind',
          text: 'On the water or on the road, assistance is a call away.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_REPAIR_COVERAGE_ICON}`,
        },
        {
          title: 'Low Monthly Cost',
          text: 'Coverage starting as low as $25* per month.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_LOW_MONTHLY_COST_ICON}`,
        },
      ],
      secondRow: [
        {
          title: 'Cost Efficient',
          text: 'Roadside assistance is often more cost-effective than paying for services like towing out of pocket.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_COVERAGE_FOR_DEDUCTIBLES_ICON}`,
        },
        {
          title: 'Easily Transferable',
          text: 'Upgrading your boat? Many plans can be transferred to a new owner, increasing the resale value of your boat.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_EASILY_TRANSFERABLE_ICON}`,
        },
      ],
    },
  },
};

import React, { Component } from 'react';
import { Ad } from '@dmm/react-common-components';
import '../styles.css';

export default class LeaderboardMid extends Component {
  render() {
    let {adNum} = this.props;
    const leaderboardMidParams = {
      adId: 'div-gpt-leaderboard-mid-' + adNum,
      classes: 'ad w728'
    };

    return (
      <li className="desktop-liner-ad">
        <Ad {...leaderboardMidParams}  />
      </li>
    );
  }
}

export const adsConfig = {
  'div-gpt-custom-box-1': {
    unitPath: '/252108799/BoatTrader/shipping/custom-box-1',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [288, 200]
    ],
    targeting: {
      pos: 'custom-box-1'
    },
    prebid: {
      bids: []
    },
    isCollapsible: true
  },
  'div-gpt-custom-box-2': {
    unitPath: '/252108799/BoatTrader/shipping/custom-box-2',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [288, 200]
    ],
    targeting: {
      pos: 'custom-box-2'
    },
    prebid: {
      bids: []
    },
    isCollapsible: true
  },
  'div-gpt-custom-box-3': {
    unitPath: '/252108799/BoatTrader/shipping/custom-box-3',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [288, 200]
    ],
    targeting: {
      pos: 'custom-box-3'
    },
    prebid: {
      bids: []
    },
    isCollapsible: true
  }
};


import React from 'react';
import PropTypes from 'prop-types';
import {useLoadedLogo} from '../../utils/dealerHelpers/custom';

const SafeLogo = ({src, alt, className}) => {
  const safeLogo = useLoadedLogo(src);
  if (!safeLogo) {
    return null;
  }

  return (
    <img src={src} alt={alt} className={className}/>
  );
};

SafeLogo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string
};

export default SafeLogo;

import React, { useEffect, useState } from 'react';
import './styles.css';
import GetPreQualifiedForm from '../GetPreQualifiedForm';
import { REACT_APP_PRIVACY_POLICY_URL } from '../../constants/boats';
import { BOAT_LEAD_PURCHASE_TYPE, CDN_URL } from '../../constants/BoatLoans';
import Modal from '../Modal';
import classNames from 'classnames';

const customStyles = {
  content: {
    overflow: 'visible',
    padding: '0',
    borderRadius: '1rem',
    border: 'unset',
  },
  overlay: {
    backgroundColor: 'rgb(34 27 34 / 50%)'
  }
};

export const GetPreQualifiedHeaderServices = ({ title }) => {
  return <div className="services-lead-title" >{title}</div>;
};

export const GetPreQualifiedHeader = ({ title }) => {
  return <div className="prequalified-title">
    <img className="prequalified-icon-boat" src={`${CDN_URL}/boat-get-prequalied.svg`} alt="Boat Icon" />
    <div className="prequalified-logo-financing">
      <img className="prequalified-img-logo"  src={`${process.env.REACT_APP_CDN_URL}/img/boattrader-logo_blue.svg`} alt="Boat Trader Logo" width="108px" height="14px"/>
      <div className="prequalified-financing" >{title}</div>
    </div>
  </div>;
};

export const GetPreQualifiedFooter = () => {
  return <>
    <div className="prequalified-powered-by">
      <div className="powered-by-title">Powered by</div>
      <img className="trident-logo" src={`${process.env.REACT_APP_CDN_URL}/services/trident-funding.svg`} alt="Trident Funding Logo" width="120.438px" height="16px"/>
    </div>
    <div className="prequalified-privacy-policy">
      <a href={REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noopener nofollow" data-wpel-link="external" data-testid="prequalified-privacy-policy">Privacy Policy</a>
    </div>
  </>;
};

export const GET_PREQUALIFIED_SUCCESS_CLOSE_TIMEOUT = 9000;
export const GetPreQualifiedSuccess = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, GET_PREQUALIFIED_SUCCESS_CLOSE_TIMEOUT);
  });

  const onClose = () => {
    setIsOpen(false);
    if (props.onClose){
      props.onClose();
    }
  };

  return <Modal
    isOpen={isOpen}
    portalClassName="prequalified-modal-container"
    contentLabel="Prequalified success"
    injectedStyles={customStyles}
  >
    <div className="prequalified-success" data-testid="prequalified-success-toast" data-e2e="prequalified-success-toast">
      <div className="prequalified-success-logo" data-e2e="prequalified-success-logo"></div>
      <div className="prequalified-success-info" data-e2e="prequalified-success-info">
        Your request has been sent! A member of our Boat Trader financing team from Trident Funding
        will be in touch shortly.
      </div>
      <button className="modal-close" data-testid="close-prequalified-success" data-e2e="close-prequalified-success" onClick={() => onClose()}>
        <span className="visuallyhidden">Close</span>
      </button>
    </div>
  </Modal>;
};

export const GetPreQualifiedModal = (props) => {

  const {interestRate, monthlyPrice, ficoScores, leadType, leadPage, listing, trackingEvent, abTest} = props;
  const [isOpen, setIsOpen] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const title = (leadType === BOAT_LEAD_PURCHASE_TYPE) ? 'Financing' : 'Services';

  const onClose = () => {
    setIsOpen(false);
    props.onClose(false);
  };

  const onSuccess = () => {
    setIsSuccess(true);
  };

  return <>
    {!isSuccess && (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        portalClassName="prequalified-modal-container"
        contentLabel="Prequalified modal"
        injectedStyles={customStyles}
      >
        <div className="prequalified-container" data-testid="prequalified-modal-container" data-e2e="prequalified-modal-container">
          <div className="prequalified-bar" data-e2e="prequalified-bar">
            <GetPreQualifiedHeader title={title}/>
            <button
              data-testid="prequalified-modal-container-modal-close"
              data-e2e="prequalified-modal-container-modal-close"
              className="modal-close"
              onClick={() => onClose()}>
              <span className="visuallyhidden">Close</span>
            </button>
          </div>
          <GetPreQualifiedForm
            listing={listing}
            interestRate={interestRate}
            ficoScores={ficoScores}
            monthlyPrice={monthlyPrice}
            onSuccess={onSuccess}
            leadPage={leadPage}
            leadType={leadType}
            trackingEvent={trackingEvent}
            utmContent={abTest}
          />
          <GetPreQualifiedFooter/>
        </div>

      </Modal>
    )}
    {isSuccess &&
        <GetPreQualifiedSuccess onClose={onClose}/>
    }
  </>;
};

export const GetPreQualifiedInlineForm = (props) => {

  const {
    listing,
    isLeadSubmitted = false,
    title,
    leadType,
    leadPage = 'BDP',
    trackingEvent,
    buttonText = 'Send Request',
    showCloseButton = false,
    showNotification = true,
    preFillLoanAmount = false,
    hidePurchaseTimeFrame = false,
    onCloseModal,
    onFormSuccess,
    ficoScores,
    monthlyPrice,
    loanAmount,
    interestRate,
    kameleoonGoal,
    utmContent
  } = props;

  const [isSuccess, setIsSuccess] = useState(false);

  const onSuccess = () => {
    setIsSuccess(showNotification);
    if (onFormSuccess){
      onFormSuccess();
    }
  };

  const onClose = () => {
    setIsSuccess(false);
  };

  return <>
    {!isSuccess && (
      <div data-testid="inline-prequalified-form" className="inline-prequalified-form">
        <div className={classNames({'prequalified-bar': showCloseButton})}>
          <GetPreQualifiedHeaderServices title={title}/>
          {showCloseButton &&
              <button
                data-testid="prequalified-modal-container-modal-close"
                className="modal-close"
                onClick={() => onCloseModal()}>
                <span className="visuallyhidden">Close</span>
              </button>
          }
        </div>
        <GetPreQualifiedForm
          listing={listing}
          onSuccess={onSuccess}
          leadPage={leadPage}
          leadType={leadType}
          ficoScores={ficoScores}
          buttonText={buttonText}
          trackingEvent={trackingEvent}
          isLeadSubmitted={isLeadSubmitted}
          monthlyPrice={monthlyPrice}
          loanAmount={loanAmount}
          interestRate={interestRate}
          kameleoonGoal={kameleoonGoal}
          utmContent={utmContent}
          preFillLoanAmount={preFillLoanAmount}
          hidePurchaseTimeFrame={hidePurchaseTimeFrame}
        />
        <GetPreQualifiedFooter/>
      </div>
    )}

    {isSuccess &&
          <GetPreQualifiedSuccess onClose={onClose}/>
    }
  </>;
};

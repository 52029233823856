import React, { PureComponent } from 'react';
import PaginationItem from '../PaginationItem';
import { getPaginationData } from '../../utils/paginationHelper';
import get from 'lodash/get';
import * as scroll from '../../utils/scrollTo';

class Pagination extends PureComponent {

  onClickPagination = () => {
    scroll.scrollToTop();
  }

  render() {
    let { getUrl, params } = this.props;
    let count = get(this.props, 'count', 8);
    let pagination = getPaginationData(params, count, 5);

    return (
      <ul className="pagination">
        <PaginationItem
          data-e2e="srp-pagination"
          key="first"
          onClick={this.onClickPagination}
          disabled={pagination.isFirstPage}
          type="prev"
          href={!pagination.isFirstPage ? getUrl(pagination.page - 1) : null}>&lt;
        </PaginationItem>
        {pagination.visibleNumbers.map((value) => (
          <PaginationItem
            key={value}
            onClick={this.onClickPagination}
            href={getUrl(value)}
            active={pagination.page === value}
          >
            { value }
          </PaginationItem>
        ))}
        <PaginationItem key="last" onClick={this.onClickPagination} disabled={pagination.isLastPage} type="next" href={!pagination.isLastPage ? getUrl(pagination.page + 1) : null}>&gt;</PaginationItem>
      </ul>
    );
  }
}

export default Pagination;

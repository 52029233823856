import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Breadcrumb } from '@dmm/lib-react-ui-components';
import { getBreakpoint } from '../../../../utils/commonHelper';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import DefaultLayout from '../../../../layouts/Default';
import RichSnippet from '../../../../components/RichSnippet';
import { FAQSection } from '../shared/FAQSection';
import {
  GAP_PROTECTION_PLAN_URL,
  TRIDENT_SERVICES_CDN_URL,
  GAP_PROTECTION_HERO,
} from '../../../../constants/TridentServices';
import {
  FAQ_SECTION_TITLE,
  FAQ_ACCORDION_CONTENT,
  FAQ_DISCLAIMER_TEXT
} from '../../../../constants/TridentServices/GAPProtectionFAQs';
import {
  TRACKING_GAP_PROTECTION_TOP_FORM_ACTION_TYPE,
  TRACKING_GAP_PROTECTION_TOP_FORM_ACTION_LABEL,
  TRACKING_GAP_PROTECTION_TOP_FORM_EVENT,
  TRACKING_GAP_PROTECTION_BOTTOM_FORM_ACTION_LABEL,
} from '../../../../constants/BoatLoans';
import metadata from './GAPProtectionPlanMetadata.json';
import { Hero } from '../shared/Hero';

const backgroundHero = `${TRIDENT_SERVICES_CDN_URL}${GAP_PROTECTION_HERO}`;

import './styles.css';
import InfoSection from '../shared/InfoSection';
import { gapProtectionInfo } from '../../../../constants/TridentServices/GAPProtectionPlanInfoSection';

const GAPProtectionPlanPage = (props) => {
  const { isLeadSubmitted } = props;
  const [isMobile, setIsMobile] = useState(getBreakpoint() === 'mobile');
  const [isReady, setIsReady] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setIsReady(true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = () => {
    setIsMobile(getBreakpoint() === 'mobile');
  };

  return (
    <>
      <RichSnippet schema={getFAQSchema(metadata)} />
      <DefaultLayout {...props} pageType="TridentServicesGAPProtectionPlan" hideOverflow>
        <div className="breadcrumb-container">
          <Breadcrumb
            items={[
              {
                text: 'Home',
                url: '/',
              },
              {
                text: 'Services',
              },
              {
                text: 'GAP Protection',
                url: GAP_PROTECTION_PLAN_URL,
              },
            ]}
          />
        </div>

        {isReady && (
          <Hero
            backgroundImageUrl={backgroundHero}
            title={<>GAP Protection{isMobile && <br/>} for Boat Owners</>}
            text={<>Coverage for as Low{isMobile && <br/>} as</>}
            price="$25/month"
            formTitle="Get Your Free Quote"
            leadPage="GAP Protection"
            isLeadSubmitted={isLeadSubmitted}
            dataTestId="gap-protection-hero"
            trackingEvent={
              {
                'action_type': TRACKING_GAP_PROTECTION_TOP_FORM_ACTION_TYPE,
                'action_label': TRACKING_GAP_PROTECTION_TOP_FORM_ACTION_LABEL,
                'event': TRACKING_GAP_PROTECTION_TOP_FORM_EVENT
              }
            }
          />
        )}

        <div className="main-section">
          <div className="gap-protection-info">
            <InfoSection
              isMobile={isMobile}
              info={gapProtectionInfo}
              leadPage="GAP Protection Plans"
              getProtectedGALinkId=" get protected button gap protection page"
              isLeadSubmitted={isLeadSubmitted}
              trackingEvent={
                {
                  'action_type': TRACKING_GAP_PROTECTION_TOP_FORM_ACTION_TYPE,
                  'action_label': TRACKING_GAP_PROTECTION_BOTTOM_FORM_ACTION_LABEL,
                  'event': TRACKING_GAP_PROTECTION_TOP_FORM_EVENT
                }
              }/>
          </div>

          <div className="gap-protection-faqs">
            <FAQSection
              pageTitle={FAQ_SECTION_TITLE}
              accordionItems={FAQ_ACCORDION_CONTENT}
              disclaimerText={FAQ_DISCLAIMER_TEXT}
            />
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};


// We will use state to Anti SPAM actions with the lead form
const mapStateToProps = (state) => {
  return {
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false)
  };
};

export default connect(
  mapStateToProps,
)(GAPProtectionPlanPage);

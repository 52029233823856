import React from 'react';
import ReactJWPlayer from 'react-jw-player';
import './styles.css';
import { v4 as uuidv4 } from 'uuid';

const displayName = 'JWPlayer';

class JWPlayer extends React.Component {

  state = {
    id: uuidv4()
  }

  render() {
    const { media } = this.props;
    return (
      <div className="react-jw-player-container">
        <ReactJWPlayer
          playerId={this.state.id}
          playerScript={process.env.REACT_APP_JW_PLAYER}
          file={media.videoEmbedUrl}
          image={media.videoVideoThumbnailUri}
        />
      </div>
    );
  }
}

JWPlayer.displayName = displayName;

export default JWPlayer;

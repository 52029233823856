import {normalizeString} from '@dmm/lib-common/lib/formatting';
import {asArray} from './commonHelper';

/**
 * These functions allow two level elements in facets.
 * If we want to allow more than two this has to be refactored to make it recursive.
 * We can generate a two level hierarchical structure visually this way:
 *
 * On the orchestrator we add the following properties to the facet.
 * "parentName": "SomeParentName" // ie. "Fiberglass",
 * "displayName": "Some name to display" // ie "Composite"
 *
 * Then we generate a two level structure based on those properties. *
 * This way we don't have to change anything in the orchestrator
 * that can break the app or other parts of the application.
 *
 * Adding those properties to the facets we can, with the following functions, define
 * a one-dimensional array mapping a two level structure.
 *
 * Each parent precedes the children
 */

/**
 * Object that defines the default mapping of parent objects.
 * When we sort a list of facets to generate a new "two-level, one-dimensional array", we can generate
 * the parent objects from this map.
 * @type {{Fiberglass: {isParent: boolean, name: string, urlId: string, value: string}}}
 */
// We could refactor all this logic to create a new structure that allows a grouped nesting
// of n levels in a unidimensional array of facets. It should not be that hard.
const defaultTwoLevelMap = {
  Fiberglass: {
    value: 'Fiberglass',
    name: 'Fiberglass',
    isParent: true,
    urlId: 'fiberglass'
  }
};
const sameUrlId = (item, facet) => item?.urlId && item?.urlId === facet?.urlId;
const twoLevelsSorter = (a, b) => {
  let na = a?.name;
  let nb = b?.name;
  if (a.parentName && !b.parentName) {
    na = a.parentName;
  }
  if (!a.parentName && b.parentName) {
    nb = b.parentName;
  }
  if (a.parentName && b.parentName) {
    if (a.parentName !== b.parentName) {
      na = a.parentName;
      nb = b.parentName;
    }
  }
  if (a.isParent && b.parentName === a.name) {
    return -1;
  }
  if (b.isParent && a.parentName === b.name) {
    return 1;
  }
  return na > nb ? 1 : -1;
};
const isFacetChild = facet => !!(facet?.parentName);
const isFacetParent = facet => !!(facet?.isParent);
const equalFacets = (a, b) => a.urlId && a.urlId === b.urlId && a.name === b.name;

/**
 * From a list of facets we generate a new list, ordered, with all the facets and the parents for the two level list.
 * @param facets list of facets it might (or might not) have the parent objects. However, we should get the parent objects from the twoLevelFacetParents map.
 * @param twoLevelFacetParents: {key: string} map of parent objects. Check test as example.
 * @returns {*}
 */
const sortTwoLevelFacets = (facets, twoLevelFacetParents = defaultTwoLevelMap) => {
  const withParents = facets.reduce((newFacets, facet) => {
    const inList = newFacets.some(item => sameUrlId(item, facet));
    if (!inList) {
      newFacets.push(facet);
      if (facet.parentName) {

        const parent = twoLevelFacetParents[facet.parentName];
        const inListParent = newFacets.some(item => sameUrlId(item, parent));
        if (!inListParent) {
          newFacets.push(parent);
        }
      }
    }
    return newFacets;
  }, []);
  return withParents.sort(twoLevelsSorter);
};

const twoLevelFacetsReducer = (node, item) => {
  if (!Array.isArray(node.children)) {
    node.children = [];
    node.parent = {};
  }
  if (item.parentName) {
    node.children.push(item);
  }
  if (item.isParent) {
    node.parent = item;
  }
  return node;
};

const allSelected = children => !children.some(child => child.selected === false);

/**
 * This function returns a "check" function that allows to select and deselect items in a group.
 * Logic of two level selections:
 * - only one parent and n children
 * - Selecting parent selects all children
 * - Deselecting parent deselects all children
 * - For n children, selecting s<n selects each children
 * - For n children selecting s=n selects all children and parent
 * - For n children selected, deselecting one children deselects parent.
 */
const checkGroup = (group) => (facet, ok) => {
  const {parent, children} = group;
  const activeFacet = equalFacets(parent, facet) ? facet : children.find(child => equalFacets(child, facet));
  if (!activeFacet) {
    return false;
  }
  activeFacet.selected = ok;
  if (activeFacet.isParent) {
    children.forEach(child => {
      child.selected = ok;
    });
    return ok;
  }
  parent.selected = ok ? allSelected(children) : false;
  return ok;
};

/**
 * Generates a "group" of facets composed of the parent facet and the children.
 * For each item, and for the group, we have a select/ deselect methods to mark them as selected.
 *
 * @param facets list of facets
 * @returns {{chidren: object[], parent: object}}
 */
const twoLevelGroupFacets = (facets) => {
  const group = {};
  const {children, parent} = facets.reduce(twoLevelFacetsReducer, {});
  group.children = children?.map(child => ({...child, selected: false})) || [];
  group.parent = {...parent, selected: false};
  const check = checkGroup(group);
  const checkItem = (item, ok) => () => check(item, ok);
  group.select = (facet) => check(facet, true);
  group.deselect = (facet) => check(facet, false);
  group.parent.select = () => check(group.parent, true);
  group.parent.deselect = () => check(group.parent, false);
  group.children.forEach((item) => {
    item.select = checkItem(item, true);
    item.deselect = checkItem(item, false);
  });
  return group;
};

/**
 * In order to get one instance we memoize groups.
 * @param id: unique id of the group
 * @param factory generator function
 * @param reset if we want to re-create the group
 * @returns {(function(*=): (*|null))|*}
 */
const memoizedFacetGroup = (id, factory) => {
  let group = {};

  return (factoryArgs, reset = false) => {
    if (id in group && !reset) {
      return group[id];
    }
    const tmp = factory(factoryArgs);
    if (tmp) {
      group[id] = tmp;
      return tmp;
    }
    return null;
  };
};

const withNormalizedUrlId = item => {
  item.normalizedUrlId = normalizeString(item.urlId);
  return item;
};

const groupItemByUrlId = (group, id) => {
  if (!group || !id) {
    return null;
  }
  const {parent, children} = group;
  const idKey = 'normalizedUrlId' in parent ? 'normalizedUrlId' : 'urlId';
  const item = parent[idKey] === id ? parent : children.find(item => item[idKey] === id);
  return item;
};

const selectedGroupUrlIds = (group) => {
  const {parent, children} = group;
  let types = [];
  if (parent?.selected) {
    types.push(parent.normalizedUrlId);
  }
  children?.forEach(child => {
    if (child.selected) {
      types.push(child.normalizedUrlId);
    }
  });
  return types;
};

const selectGroupFromTypes = (group, selectedTypes) => {
  const selectedItems = asArray(selectedTypes)
    .map(id => groupItemByUrlId(group, id))
    .filter(item => !!item);
  selectedItems.forEach(item => item.select());
  if (selectedItems.length === 0) {
    group?.parent.deselect();
  }
  return group;
};

/**
 * Utility functions for fiberglass group
 */
const getHullTypesWithFiberglass = (selectedHullTypes, fiberglassGroup) => {
  if (!fiberglassGroup) {
    return selectedHullTypes;
  }
  const types = fiberglassGroup.types;
  const noFiberglassTypes = asArray(selectedHullTypes)?.concat().filter(type => !types.includes(type));
  const newTypes = [...selectedGroupUrlIds(fiberglassGroup), ...noFiberglassTypes];
  return newTypes;
};

const groupFiberGlass = hullTypes => {
  const fiberGlassItems = hullTypes?.filter(hull => hull.urlId.match(/fiberglass/i)) || [];
  if (!fiberGlassItems.length) {
    return null;
  }
  // we add normalizedUrlId to avoid calling always that function;
  const group = twoLevelGroupFacets(fiberGlassItems?.map(withNormalizedUrlId));
  group.types = [group.parent.normalizedUrlId, ...group.children.map(child => child.normalizedUrlId)];
  group.selectFromTypes = types => selectGroupFromTypes(group, types);
  group.addFiberGlassToHullTypes = (hullTypes) => getHullTypesWithFiberglass(hullTypes, group);
  group.itemByNormalizedId = (id) => groupItemByUrlId(group, id);
  return group;
};

const memoizedFiberGlassGroup = () => {
  return memoizedFacetGroup('fiberglass', groupFiberGlass);
};

export {
  isFacetChild,
  isFacetParent,
  equalFacets,
  sortTwoLevelFacets,
  twoLevelGroupFacets,
  memoizedFacetGroup,
  memoizedFiberGlassGroup,
  withNormalizedUrlId,
  groupItemByUrlId,
  getHullTypesWithFiberglass
};

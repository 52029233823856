import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';
import { DEFAULT_MIN_YEAR } from '../../../../constants/boats';
import { adsHelper } from '@dmm/react-common-components';
import { getLegacyMappingFromBoatClass } from '../../../../utils/classHelper';
import { getFilterCount } from '../../../../utils/commonHelper';
import { canonicalizeDataString, isValidCondition} from '../../../../utils/ads/adsUtils';
import { unhyphenateUrlComponents } from '../../../../utils/urlHelpers/listings';

export const adsConfig = {
  // Boats list first ad mobile (sticky ad)
  'div-gpt-mobile-leaderboard-custom': {
    unitPath: '/252108799/BoatTrader/boat-search/mobile-leaderboard-custom',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [320, 50]
    ],
    targeting: {
      pos: 'mobile-leaderboard-custom'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309628'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '285668276'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599585'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199162'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346712'
          }
        }
      ]
    },
    refreshAdEveryXSeconds: 30
  },
  // Boats list second ad mobile
  'div-gpt-mobile-box-1': {
    unitPath: '/252108799/BoatTrader/boat-search/mobile-box-1',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309629'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '188838085'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599586'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199158'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346710'
          }
        }
      ]
    }
  },
  // Boats list third ad mobile
  'div-gpt-mobile-box-2': {
    unitPath: '/252108799/BoatTrader/boat-search/mobile-box-2',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309630'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '402932794'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599587'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199160'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346711'
          }
        }
      ]
    }
  },
  // Boats list fourth ad mobile
  'div-gpt-mobile-box-3': {
    unitPath: '/252108799/BoatTrader/boat-search/mobile-box-3',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-3'
    },
    prebid: {
      bids: []
    }
  },
  'div-gpt-ad-inline-box-1': {//3 column ad
    unitPath: '/252108799/BoatTrader/boat-search/inline-box-1',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300,250]
    ],
    targeting: {
      pos: 'ad-inline-box-1'
    },
    prebid: {
      bids: []
    },
  },
  'div-gpt-ad-inline-box-2': {//3 column ad
    unitPath: '/252108799/BoatTrader/boat-search/inline-box-2',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300,250]
    ],
    targeting: {
      pos: 'ad-inline-box-2'
    },
    prebid: {
      bids: []
    },
  },
  'div-gpt-ad-inline-box-3': {//3 column ad
    unitPath: '/252108799/BoatTrader/boat-search/inline-box-3',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300,250]
    ],
    targeting: {
      pos: 'ad-inline-box-3'
    },
    prebid: {
      bids: []
    },
  },
  'div-gpt-ad-inline-box-4': {//3 column ad
    unitPath: '/252108799/BoatTrader/boat-search/inline-box-4',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300,250]
    ],
    targeting: {
      pos: 'ad-inline-box-4'
    },
    prebid: {
      bids: []
    }
  },
  'div-gpt-ad-leaderboard-large-bottom': {//3 column bottom ad (only rendered with less than 14 results)
    unitPath: '/252108799/BoatTrader/boat-search/leaderboard-large-bottom',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [970, 90]
    ],
    targeting: {
      pos: 'ad-leaderboard-large-bottom'
    },
    prebid: {
      bids: []
    },
    isLazy: true
  },
  'div-gpt-ad-box-1': {
    unitPath: '/252108799/BoatTrader/branded-search/box-1',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [720,300]
    ],
    targeting: {
      pos: 'box-1'
    },
    prebid: {
      bids: []
    },
  },
  'div-gpt-ad-mobile-box-1': {
    unitPath: '/252108799/BoatTrader/branded-search/mobile-box-1',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-1'
    },
    prebid: {
      bids: []
    }
  }
};

export const getTargeting = (searchQuery, sponsoredBoats, props) => {
  const params = get(props, 'match.params', {});
  const makeModel = get(props, 'makeModel', undefined);
  const isBranded = !!params.oem && !isEmpty(params.oem);
  const make = searchQuery.makeModel && !isEmpty(searchQuery.makeModel) ? canonicalizeDataString(keys(searchQuery.makeModel)[0]) : isBranded ? canonicalizeDataString(makeModel) : undefined;

  const lengthMin = get(searchQuery.length, 'min') !== '0' || get(searchQuery.length, 'max') ? get(searchQuery.length, 'min') : undefined;
  const lengthMax = get(searchQuery.length, 'max');
  // eslint-disable-next-line eqeqeq
  const yearMin = get(searchQuery.year, 'min') != DEFAULT_MIN_YEAR || get(searchQuery.year, 'max') ? get(searchQuery.year, 'min') : undefined;
  const yearMax = get(searchQuery.year, 'max');
  let legacyBoatClass;
  if (!isEmpty(searchQuery.multiFacetedBoatTypeClass)) {
    const boatClass = searchQuery.multiFacetedBoatTypeClass[keys(searchQuery.multiFacetedBoatTypeClass)[0]][0];
    legacyBoatClass = canonicalizeDataString(getLegacyMappingFromBoatClass(boatClass));
  }
  let dealerId;
  if (!isEmpty(get(searchQuery, 'ownerId'))) {
    const ownerId = get(searchQuery, 'ownerId');
    dealerId = unhyphenateUrlComponents(ownerId.substring(ownerId.lastIndexOf('-') + 1));
  }

  let targeting = {
    type: canonicalizeDataString(keys(searchQuery.multiFacetedBoatTypeClass)[0]),
    class: legacyBoatClass,
    condition: isValidCondition(searchQuery.condition) ? searchQuery.condition : undefined,
    length: adsHelper.getLengthRanges(lengthMin, lengthMax),
    year: adsHelper.getYearRanges(yearMin, yearMax),
    make: make,
    state: searchQuery.state || undefined,
    // eslint-disable-next-line camelcase
    zip_code: searchQuery.zip ? searchQuery.zip : undefined,
    page: 'boat-search',
    filterCount: getFilterCount(searchQuery) || undefined,
    sponsoredBoats: sponsoredBoats || undefined,
    // eslint-disable-next-line camelcase
    dealer_id: dealerId,
    pagination: get(searchQuery, 'page', undefined)
  };

  //sets oem = true when a brands page is opened, otherwise is passed as false.
  targeting.oem = get(props, 'searchPage', '') === 'brands';

  targeting = omitBy(targeting, isUndefined);

  return targeting;
};

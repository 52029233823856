import React, { useState, useEffect } from 'react';
import { FixedSizeList } from 'react-window';
import get from 'lodash/get';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import classnames from 'classnames';
const ListHeight = 200;
const ListItemHeight = 27;

const getActiveItem = (selectedItem, dropdownData) => {
  if (selectedItem === '') {
    return get(dropdownData, '[0].data[0]', '');
  }

  let prevSelectedFacet;

  for (const keyName in dropdownData) {
    prevSelectedFacet = dropdownData[keyName].data?.find((item) => item.value === selectedItem);
    if (prevSelectedFacet) {
      break;
    }
  }
  return prevSelectedFacet ? prevSelectedFacet : get(dropdownData, '[0].data[0]', '');
};

const SelectDropdown = ({ dropdownData, style, onSelectedItem, defaultItem, selectedItem, inputAriaLabel }) => {
  const [isOpen, setOpen] = useState(false);
  const [item, setItem] = useState(defaultItem);
  const [data, setData] = useState(dropdownData);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    setData(dropdownData);
    setItem(getActiveItem(get(selectedItem, 'value', ''), dropdownData));
  }, [dropdownData]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectValue = (li) => {
    setItem(li);
    setOpen(false);
    onSelectedItem(li);
  };

  const filterItems = (ev) => {
    const filteredData = {};

    const value = ev.nativeEvent.target.value;

    for (const keyName in dropdownData) {
      const currentArr = dropdownData[keyName].data;
      set(filteredData, `${keyName}.name`, dropdownData[keyName].name);
      set(filteredData, `${keyName}.id`, dropdownData[keyName].id);
      set(filteredData, `${keyName}.data`, filter(currentArr, facet => {
        const filterValueWithoutSpaces = value.toLowerCase().replace(/ /g, '');
        const facetValueWithoutDashes = facet.value.toLowerCase().replace(/-/g, '');

        return facet.name.toLowerCase().includes(value.toLowerCase()) ||
          facetValueWithoutDashes.includes(filterValueWithoutSpaces);
      }));
    }

    setData(filteredData);
  };

  const handleBlur = (ev) => {
    if (!ev.currentTarget.contains(ev.relatedTarget)) {
      setOpen(false);
    }
  };

  const formattedData = [];
  forEach(data, (headingElement) => {
    if (headingElement.name){
      formattedData.push({
        name: headingElement.name,
        value: null,
        heading: true
      });
    }
    forEach(headingElement.data, (selectableElement) => {
      formattedData.push({
        name: selectableElement.name,
        value: selectableElement.value,
        heading: false
      });
    });
  });

  return (
    <fieldset style={style} onBlur={handleBlur}>
      <div className={classnames('custom-select filter-opts txt-filter', { 'open': isOpen })} tabIndex="1">
        <div className="heading" onClick={handleClick}>
          <span className="txt">{item.name}</span>
          <span className="icon"></span>
        </div>

        <div data-e2e={item.name} className={classnames('custom-dropdown', { 'custom-dropdown-open': isOpen })}>
          <div className="text-filter">
            <input onChange={filterItems} className="filter-input" tabIndex="1" id="type" aria-label={inputAriaLabel} />
          </div>

          <FixedSizeList
            className="fixedSizeList"
            height={ListHeight}
            itemCount={formattedData.length}
            itemSize={ListItemHeight }
            width="100%"
            itemData={formattedData}
            innerElementType="ul"
          >
            {
              ({data, index, style }) => {
                return (<li style={style}
                  onClick={!data[index].heading ? () => selectValue(data[index]) : null}
                  key={`${data[index].value}-${index}`}
                  data-val={data[index].value}
                  className={classnames( { 'activated': !data[index].heading, 'selected': data[index].value === item.value, 'group-heading': data[index].heading })}>{data[index].name}
                </li>);
              }
            }
          </FixedSizeList>
        </div>
      </div>
    </fieldset>
  );
};

export default SelectDropdown;

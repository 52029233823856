import { Ad } from '@dmm/react-common-components';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BREAKPOINTS } from '../../../utils/commonHelper';
import './styles.css';

const { mobile, desktop } = BREAKPOINTS;

const HeroAd = ({ breakpoint }) => {
  const [isAdBlockerActive, setsAdBlockerActive] = useState(false);

  useEffect(() => {
    // Set up a window.onload event handler to check for an ad blocker
    window.onload = () => {
      // Wait for 2 seconds to allow extra time for the ad to load
      setTimeout(() => {
        // Select the element with the class 'hero-slot'
        const heroSlotAttribute = document.querySelector('.hero-slot')?.getAttribute('data-google-query-id');
        // If the element does not have a 'data-google-query-id' attribute injected by Ads, assume an ad blocker is active
        if (isNil(heroSlotAttribute)) {
          setsAdBlockerActive(true);
        }
      }, 2000);
    };
  }, []);

  return (
    <div className={isAdBlockerActive ? 'hero-ad-container hero-ad-fallback' : 'hero-ad-container'}>
      <div className={classnames('hero-ad', 'responsive')}>
        { mobile === breakpoint && (
          <div id="hero-mobile" className="hero-slot">
            <Ad adId="hero-mobile" />
          </div>
        ) }
        { desktop === breakpoint && (
          <div id="hero-desktop" className="hero-slot">
            <Ad adId="hero-desktop" />
          </div>
        ) }
      </div>
    </div>
  );
};

HeroAd.propTypes = {
  breakpoint: PropTypes.string.isRequired
};

export default HeroAd;

import { useState, useEffect } from 'react';

const  useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {


    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.intersectionRatio >= options.threshold),
      options
    );

    if (ref.current){
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current){
        observer.unobserve(ref.current);
      }

    };
  }, [ref, options]);


  return isIntersecting;
};

export default useIntersectionObserver;

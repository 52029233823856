import React from 'react';
import PropTypes from 'prop-types';
import ContactForm from '../../../components/ContactForm';
import { getPrePopulatedText } from '../../../utils/listingHelper';
import './styles.css';

const SOCIAL_FORM_LEAD_SOURCE = 'BoatTrader Social Browse';
const SOCIAL_FORM_TITLE = 'Contact the seller';
const SOCIAL_FORM_BUTTON = 'Send message';

const SocialContactForm = ({ listing, isOpen, closeForm }) => {

  if (!isOpen) { return null; }
  return (
    <ContactForm
      id="modal-social-contact-form"
      key={ listing.id }
      title={ SOCIAL_FORM_TITLE }
      contactButtonText={ SOCIAL_FORM_BUTTON }
      leadSourceOverride={ SOCIAL_FORM_LEAD_SOURCE }
      open={ true }
      onClose={ closeForm }
      listingId={ listing.id }
      listing={ listing }
      emailLead={ true }
      prePopulatedText={ getPrePopulatedText(listing) }
      placeholder={ {name: 'Name', email: 'Email', comments: 'Comment', firstName: 'First Name'} }
      orderInputs={ ['name', 'email', 'comments'] }
      contactSubmitTrack={true}
    />
  );
};

SocialContactForm.propTypes = {
  listing: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default SocialContactForm;

import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  ACCORDION_MAIN_TITLE_V2,
  ACCORDION_ITEM_1_TEXT_P1_V2,
  ACCORDION_ITEM_1_TEXT_P2_V2,
  ACCORDION_ITEM_1_TITLE_V2,
  ACCORDION_ITEM_2_TITLE_V2,
  ACCORDION_ITEM_2_TEXT_P1_V2,
  ACCORDION_ITEM_3_TITLE_V2,
  ACCORDION_ITEM_3_TEXT_P1_V2,
  ACCORDION_ITEM_3_TEXT_P2_V2,
  ACCORDION_ITEM_4_TITLE_V2,
  ACCORDION_ITEM_4_TEXT_P1_V2,
  ACCORDION_ITEM_5_TITLE_V2,
  ACCORDION_ITEM_5_TEXT_P1_V2,
  ACCORDION_ITEM_6_TITLE_V2,
  ACCORDION_ITEM_6_TEXT_P1_V2,
  ACCORDION_ITEM_7_TITLE_V2,
  ACCORDION_ITEM_7_TEXT_P1_V2,
  ACCORDION_ITEM_8_TITLE_V2,
  ACCORDION_ITEM_8_TEXT_P1_V2,
  ACCORDION_ITEM_9_TITLE_V2,
  ACCORDION_ITEM_9_TEXT_P1_V2,
  ACCORDION_ITEM_9_TEXT_P2_V2,
} from '../../../../../../constants/BoatLoansCalculator';

import './styles.css';

const renderAccordionTitle = (text) => {
  return (
    <AccordionItemHeading>
      <AccordionItemButton>
        <h2 className="accordion__title">{text}</h2>
      </AccordionItemButton>
    </AccordionItemHeading>
  );
};

export const InfoAccordion = ({ dataTestId = 'info-accordion-component' }) => {
  return (
    <>
      <div className="accordion-section-title">{ACCORDION_MAIN_TITLE_V2}</div>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        data-testid={dataTestId}
      >
        <AccordionItem uuid="first-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_1_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="first-accordion-item-text">{ACCORDION_ITEM_1_TEXT_P1_V2}</p>
            <p data-e2e="first-accordion-item-text">{ACCORDION_ITEM_1_TEXT_P2_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="second-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_2_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="second-accordion-item-text">{ACCORDION_ITEM_2_TEXT_P1_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="third-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_3_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="third-accordion-item-text">{ACCORDION_ITEM_3_TEXT_P1_V2}</p>
            <p data-e2e="third-accordion-item-text">{ACCORDION_ITEM_3_TEXT_P2_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="fourth-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_4_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="fourth-accordion-item-text">{ACCORDION_ITEM_4_TEXT_P1_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="fifth-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_5_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="fifth-accordion-item-text">{ACCORDION_ITEM_5_TEXT_P1_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="sixth-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_6_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="sixth-accordion-item-text">{ACCORDION_ITEM_6_TEXT_P1_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="seventh-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_7_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="seventh-accordion-item-text">{ACCORDION_ITEM_7_TEXT_P1_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="eight-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_8_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="eight-accordion-item-text">{ACCORDION_ITEM_8_TEXT_P1_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem uuid="nineth-accordion-item">
          {renderAccordionTitle(ACCORDION_ITEM_9_TITLE_V2)}
          <AccordionItemPanel>
            <p data-e2e="nineth-accordion-item-text">{ACCORDION_ITEM_9_TEXT_P1_V2}</p>
            <p data-e2e="nineth-accordion-item-text">{ACCORDION_ITEM_9_TEXT_P2_V2}</p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

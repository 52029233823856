import React from 'react';
import { ApplyNowButton } from '../ApplyNowButton';
import { TRACKING_BOAT_LOANS_CLICK_TYPE_OF_LOANS_ACTION_LABEL } from '../../../../constants/BoatLoansV2';
export const BOAT_TYPES_ACCORDION_CONTENT = [
  {
    uuid: 'new-boat-loans',
    title: 'New Boat Loans',
    content: (
      <>
        New boat loans come with several advantages, as marine lenders perceive them as less risky compared to used boat loans, leading to better rates and more favorable terms.
        One key benefit of new boat loans is the generally lower interest rates when compared to used boat loans. This is because new boats tend to hold their value better over time.
        The higher initial cost of new boats also means that you can secure a more substantial loan amount with new boat loans compared to used boat loans. Additionally,
        the expected longer lifespan of new boats contributes to extended loan terms for new boat financing. This allows for more flexible repayment options and aligns
        with the durable nature of new boats. Explore new boat loans today to sail into your dream maritime adventure with financial ease.
        <br/>
        <br/>
        <ApplyNowButton
          data-reporting-click-internal-link-id="types of loans - apply now"
          data-reporting-click-internal-link-type="click"
          text="Apply Now"
          type="link"
          utmCampaign="btboatloanspage"
          utmContent="btboatloanspage"
          trackingEvent={{
            'action_label': TRACKING_BOAT_LOANS_CLICK_TYPE_OF_LOANS_ACTION_LABEL
          }}
        />
      </>
    ),
  },
  {
    uuid: 'used-boat-loans',
    title: 'Used Boat Loans',
    content: (
      <>
        Pre-owned or used boat loans are provided by a variety of marine lenders, catering to diverse vessels such as lightly used wakeboard boats or well-maintained 15-year-old center consoles.
        Used boat loans serve as a financial solution designed to facilitate the purchase of pre-owned boats. Similar to new boat loans, used boat loans involve providing borrowers with upfront
        funds to acquire the boat, with repayment obligations over time, often accompanied by interest. It´s important to note that interest rates for used boat loans may be higher,
        considering the potential risk of mechanical issues or other challenges associated with pre-owned boats. However, savvy borrowers can secure more favorable terms by taking strategic
        measures such as making a substantial down payment, maintaining a strong credit score, and opting for shorter loan terms. Navigate the waters of pre-owned boat ownership with confidence
        by exploring the tailored options offered by used boat loans. <a href="/boat-loans/calculator/" target="_blank">Calculate your payments</a> and <ApplyNowButton text="pre-qualify" type="link" utmCampaign="btboatloanspage" utmContent="btboatloanspage"/> to get a better idea of available terms and payment options.
        <br/>
        <br/>
        <ApplyNowButton
          data-reporting-click-internal-link-id="types of loans - apply now"
          data-reporting-click-internal-link-type="click"
          text="Apply Now"
          type="link"
          utmCampaign="btboatloanspage"
          utmContent="btboatloanspage"
          trackingEvent={{
            'action_label': TRACKING_BOAT_LOANS_CLICK_TYPE_OF_LOANS_ACTION_LABEL
          }}
        />
      </>
    ),
  },
  {
    uuid: 'secured-loans',
    title: 'Secured Loans',
    content: (
      <>
        Secured boat loans necessitate the provision of collateral, with your boat serving as the security for the loan. The collateral, in this case, is the boat itself,
        acting as a guarantee that secures the debt on behalf of the lender. Opting for secured boat loans often results in more favorable interest rates, enhancing affordability for borrowers.
        It´s crucial to note, however, that defaulting on the loan poses the risk of losing your boat, as it stands as the pledged collateral. We strongly advise a thorough evaluation of your
        personal financial situation and repayment capability before deciding between a secured or unsecured boat loan. Understanding the terms and implications of secured loans ensures a
        well-informed financial decision aligned with your specific circumstances. <ApplyNowButton text="Pre-qualify" type="link" utmCampaign="btboatloanspage" utmContent="btboatloanspage"/> today to see what types of loans you’ll qualify for.
        <br/>
        <br/>
        <ApplyNowButton
          data-reporting-click-internal-link-id="types of loans - apply now"
          data-reporting-click-internal-link-type="click"
          text="Apply Now"
          type="link"
          utmCampaign="btboatloanspage"
          utmContent="btboatloanspage"
          trackingEvent={{
            'action_label': TRACKING_BOAT_LOANS_CLICK_TYPE_OF_LOANS_ACTION_LABEL
          }}
        />
      </>
    ),
  },
  {
    uuid: 'unsecured-loans',
    title: 'Unsecured Loans',
    content: (
      <>
        Unsecured boat loans differ from secured loans in that they do not require any collateral for loan security. This type of financing is often associated with higher costs,
        as the lender assumes a greater level of risk. Essentially, an unsecured loan relies solely on the borrower´s creditworthiness and is not tied to a specific asset,
        such as a boat or real estate. Despite the potentially higher expenses, opting for an unsecured loan means that, in the event of a default, the lender can only pursue
        legal action to recover the debt but cannot seize a specific collateral, like your boat. While the borrower remains obligated to repay the unsecured loan as agreed,
        the lender lacks the direct authority to seize a particular asset in case of default. Although unsecured boat loans may come with increased costs, they offer the advantage
        of not jeopardizing your boat as collateral in the event of payment difficulties. <ApplyNowButton text="Pre-qualify" type="link" utmCampaign="btboatloanspage" utmContent="btboatloanspage"/> today to see what types of loans you’ll qualify for.
        <br/>
        <br/>
        <ApplyNowButton
          data-reporting-click-internal-link-id="types of loans - apply now"
          data-reporting-click-internal-link-type="click"
          text="Apply Now"
          type="link"
          utmCampaign="btboatloanspage"
          utmContent="btboatloanspage"
          trackingEvent={{
            'action_label': TRACKING_BOAT_LOANS_CLICK_TYPE_OF_LOANS_ACTION_LABEL
          }}
        />
      </>
    ),
  }
];

import React from 'react';
import PropTypes from 'prop-types';
import {
  QUOTE_BUTTON,
  LEARN_BUTTON
} from '../../constants/services';
import LinkButton from '../LinkButton';
import './styles.css';

const CompanyCard = (props) =>  {
  const { buttonType, link, rel, target,
    altText, service, provider, buttonTypeSecondary,
    linkSecondary, relSecondary, targetSecondary, serviceSecondary, providerSecondary } = props;
  const reportingAttributes = {
    'data-reporting-external-link-id': service,
    'data-reporting-external-link-name': provider,
    'data-reporting-external-link-position': 'logo'
  };

  const reportingAttributesSecondary = {
    'data-reporting-external-link-id': serviceSecondary,
    'data-reporting-external-link-name': providerSecondary,
    'data-reporting-external-link-position': 'logo'
  };

  return (
    <div className="card">
      <a href={link} rel={rel} target={target} {...reportingAttributes}>
        <img src={`${process.env.REACT_APP_CDN_URL}/services/${props.logo}`} className="logo" alt={altText} />
      </a>
      <LinkButton
        buttonType={buttonType}
        buttonLink={link}
        rel={rel}
        target={target}
        reportingAttributes={reportingAttributes}
      />
      {buttonTypeSecondary && linkSecondary &&
        <LinkButton
          buttonType={buttonTypeSecondary}
          buttonLink={linkSecondary}
          rel={relSecondary}
          target={targetSecondary}
          reportingAttributes={reportingAttributesSecondary}
          className="secondary"
        />
      }
    </div>
  );
};

CompanyCard.propTypes = {
  logo: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf([QUOTE_BUTTON, LEARN_BUTTON]).isRequired,
  link: PropTypes.string.isRequired,
  rel: PropTypes.string,
  target: PropTypes.string,
  altText: PropTypes.string,
  service: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  buttonTypeSecondary: PropTypes.oneOf([QUOTE_BUTTON, LEARN_BUTTON]).isRequired,
  linkSecondary: PropTypes.string.isRequired,
  relSecondary: PropTypes.string,
  targetSecondary: PropTypes.string,
  serviceSecondary: PropTypes.string.isRequired,
  providerSecondary: PropTypes.string.isRequired,
};

CompanyCard.defaultProps = {
  rel: '',
  altText: '',
  target: '_blank'
};

export default CompanyCard;

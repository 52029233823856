export const serviceGroupsConfig = [
  {
    title: 'Boat Buying & Marine Supplies',
    image: 'boat_buying_service.jpg',
    services: [
      {
        text: 'Boat Loans',
        link: '/boat-loans'
      },
      {
        text: 'Boat Loan Calculator',
        link: '/boat-loans/calculator'
      },
      {
        text: 'Boat Insurance',
        link: '/services/boat-insurance'
      },
      {
        text: 'Boat History',
        link: '/services/boat-history'
      },
      {
        text: 'Boat Surveyors',
        link: '/boat-surveyors'
      },
      {
        text: 'New Boat Owner Program',
        link: '/services/new-boat-owner-program'
      },
      {
        text: 'Repower Your Boat',
        link: '/services/repower'
      },
      {
        text: 'Bad Credit Boats',
        link: '/services/bad-credit-boats'
      },
      {
        text: 'Ext. Service Contracts',
        link: '/services/extended-service-contracts'
      },
      {
        text: 'Boat Lettering',
        link: '/services/boat-lettering'
      },
      {
        text: 'Boat Auctions',
        link: '/services/boat-auctions'
      },
      {
        text: 'Marine Interiors',
        link: '/services/marine-interiors'
      },
      {
        text: 'Marine Electronics',
        link: '/services/marine-electronics'
      },
      {
        text: 'Marine Supplies',
        link: '/services/marine-supplies'
      },
      {
        text: 'NADA Boat Values',
        link: 'http://www.nadaguides.com/partner.aspx?adid=167268'
      }
    ]
  },
  {
    title: 'Boat Storage & Transport',
    image: 'boat_storage_service.jpg',
    services: [
      {
        text: 'Boat Transport and Shipping',
        link: '/research/boat-transport-and-shipping'
      },
      {
        text: 'Docks',
        link: '/services/docks'
      },
      {
        text: 'International Boat Export',
        link: '/services/international-boat-export'
      },
      {
        text: 'Boat Lifts',
        link: '/services/boat-lifts'
      },
      {
        text: 'Boat Covers',
        link: '/services/boat-covers'
      },
      {
        text: 'Boat Donation',
        link: '/services/boat-donation'
      }
    ]
  },
  {
    title: 'Boat Specials & Business Information',
    image: 'boat_specials_service.jpg',
    services: [
      {
        text: 'Boating Industry\'s Best Ideas',
        link: '/services/industry-ideas'
      },
      {
        text: 'Factory Specials',
        link: '/services/factory-specials'
      },
      {
        text: 'Business Opportunities',
        link: '/services/business-opportunities'
      },
      {
        text: 'Dealer Certification',
        link: '/services/dealer-certification'
      },
      {
        text: 'Dealer Resources',
        link: '/services/dealer-resources'
      },
      {
        text: 'Waterfront Property',
        link: '/services/waterfront-property'
      }
    ]
  }
];


import React, { PureComponent } from 'react';
import MainContent from './MainContent';
import DefaultLayout from '../../layouts/Default';

export default class Services extends PureComponent {
  render() {
    return (
      <DefaultLayout {...this.props} pageType="Services">
        <MainContent {...this.props} />
      </DefaultLayout>
    );
  }
}


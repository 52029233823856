import React from 'react';
import classnames from 'classnames';
import './styles.css';
import {ToolSet, ToolSetInner} from '../../../components/ToolSet';
import RefineSearch from '../RefineSearch';
import {useRefineSearchHandler} from '../../../utils/hooks/refineSearch/refineSearchHelpers';


const RefineSearchSideBar = (props) => {
  const [filters, handleDataChange] = useRefineSearchHandler(props);
  const {loading, componentWorking = []} = props;
  const loadingFacets = componentWorking.includes('facets');
  return (
    <div id="refine-search" className={classnames('search-tools refine-search', {submittable: filters})}>
      <ToolSet>
        <ToolSetInner>
          <RefineSearch {...props} onDataChange={handleDataChange} position="desktop" loading={loading} loadingFacets={loadingFacets} />
        </ToolSetInner>
      </ToolSet>
    </div>
  );
};

export default RefineSearchSideBar;

import React from 'react';
import { Helmet } from 'react-helmet';
import DataLayerV2 from '../../components/DataLayerV2';
import classnames from 'classnames';
import { getMetaTitle, getMetaDescription } from '../../utils/metaContentHelper';

const LightLayout = ({ pageType, id, hideOverflow = false, statusCode, children }) => {

  const metaTitle = getMetaTitle();
  const metaDescription = getMetaDescription();
  return (
    <div className={classnames('page-container', { 'hide-overflow': hideOverflow === true })} id={id}>
      {statusCode !== 500 ?
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="og:title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta name="og:description" content={metaDescription} />
          <meta name="nodeVersion" content="14" />
          <meta name="robots" content="noindex" />
        </Helmet> :
        null
      }
      <DataLayerV2 pageType={pageType} />
      {children}
    </div>
  );
};

export default LightLayout;

import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';

const RecaptchaV2 = props => {

  useEffect(() => {
    const textarea = document.getElementById('g-recaptcha-response');
    textarea?.setAttribute('aria-hidden', 'true');
    textarea?.setAttribute('aria-label', 'do not use');
    textarea?.setAttribute('aria-readonly', 'true');
  }, []);

  return (
    <ReCAPTCHA className="recaptcha-visible" sitekey={props.sitekey} onChange={props.onChange} />
  );

};

export default RecaptchaV2;

export const redirectAuthenticatedUserToHomePage = (isAuthenticated) => {
  let redirectUrl;
  const homepageUrl = window.location.origin;

  try {
    redirectUrl = window.sessionStorage.getItem('userflow_redirectUrl');
  } catch (err) {
    redirectUrl = null;
  }

  if (!redirectUrl && isAuthenticated) {
    window.location.href = homepageUrl;
  }
};

import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTitle, getPrice, getLocation, getPriceAlternate } from '../../utils/listingHelper';
import { getListingReactUrlParams } from '../../utils/urlHelpers/boat';
import { setProductImpression, addProduct, addProductClick, setListingClick, addLead } from '../../store/actions/dataLayer';
import ListingImage from '../ListingImage';
import LikedBoat from '../LikedBoat';
import Link from '../SEO/Link';
import { likeBoat, unlikeBoat } from '../../store/actions';

const MiniListing = ({ myboats = [], listing, listingType, url = '', position = -1,
  cookies, setProductImpression
}) => {

  useEffect(() => {
    let { id } = listing;
    setProductImpression(id, listingType, position, undefined, undefined, undefined, undefined, undefined, getTitle(listing), listing.make);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const title = getTitle(listing);
  let listingAnchorParams = {
    'data-reporting-click-product-id': listing.id,
    'data-reporting-click-listing-type': listingType,
    ...getListingReactUrlParams(listing),
    onClick: () => {
      setListingClick(listing.id, listingType, '', '', title, position, listing.make);
    }
  };

  return (<li data-listing-id={listing.id} data-reporting-click-product-id={listing.id}
    data-reporting-click-listing-type={listingType}>
    <div className="boat-image">
      <div className="banner sponsored display-none">Sponsored</div>
      {
        listing.showSaveButton &&
          <LikedBoat listingId={listing.aliases['boat-trader']} imtId={listing.id} myboats={myboats}
            cookies={cookies} url={url}/>
      }
      <Link {...listingAnchorParams}>
        <ListingImage listing={listing} resize={{width: 200, height: 146}} lazyLoad={true}/>
      </Link>
    </div>
    <Link {...listingAnchorParams}>
      <div className="information">
        <div className="make-model">{getTitle(listing)}</div>
        <div className="price alternate hide">{getPriceAlternate(listing)}</div>
        <div className="price">{getPrice(listing)}</div>
        <div className="location">
          <span className="location hide"></span>
          <span>{getLocation(listing)}</span>
        </div>
        <div className="seller-name">
          <span className="broker hide"></span>
          <span>{listing.owner?.name}</span>
        </div>
      </div>
    </Link>
  </li>);
};

const mapStateToProps = (state) => {
  const app = state?.app || {};
  return {
    userRef: app.userRef
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    setProductImpression,
    addProduct,
    addProductClick,
    addLead,
    likeBoat, unlikeBoat
  }, dispatch)
)(MiniListing);

import { Details } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import MapOnly from '../../../components/LocationMap/components/MapOnly';
import {
  formatMoreDetailsDescriptions,
  getFeaturesData,
  getLocation,
  getMeasurementsData,
  getPriceData,
  getPropulsionData
} from '../../../utils/listingHelper';
import DataDetails from './components/DataDetails';
import DataHtml from './components/DataHtml';

const accordionDetailsItemsClass = 'accordion-details-items';

const ConditionalRender = ({
  data,
  title,
  onClick,
  isHtml = false,
  isOpen = false,
  isPricing = false,
  isMultiItem = false,
  extraRenderStatement = true,
}) => {
  if (isEmpty(data) || data?.length === 0 || !extraRenderStatement) {
    return null;
  }

  const hasSublevel = typeof data[0]?.value !== 'string';

  return (
    <Details heading={<h3>{title}</h3>} isOpen={isOpen} onClick={onClick}>
      {isHtml
        ? <DataHtml data={data} isMultiItem={isMultiItem} widthSelector={`.${accordionDetailsItemsClass}`} />
        : <DataDetails data={data} isPricing={isPricing} isSubLevel={hasSublevel} />
      }
    </Details>
  );
};

const AccordionDetails = ({ isPrivateSeller = false, listing, setCollapseContentTracking }) => {
  const pricingData = getPriceData(listing);
  const locationAddress = getLocation(listing);
  const featuresData = getFeaturesData(listing);
  const propulsionData = getPropulsionData(listing);
  const measurementsData = getMeasurementsData(listing);

  const description = get(listing, 'description');
  const oemName = get(listing, 'oemDetails.name');
  const oemIntro = get(listing, 'oemDetails.intro');
  const [lng, lat] = get(listing, 'location.coordinates', []);

  const moreDetails = formatMoreDetailsDescriptions(get(listing, 'descriptions', []));

  return (
    <div className="accordion-details-wrapper">
      <section className="accordion-details-section">
        <h2>Boat Details</h2>
        <div className={accordionDetailsItemsClass}>
          <ConditionalRender
            data={description}
            title={'Description'}
            isHtml
            isOpen
            onClick={() => {
              setCollapseContentTracking('boat-description');
            }}
          />
          <ConditionalRender
            data={oemIntro}
            title={`About ${oemName}`}
            isHtml
          />
          <ConditionalRender
            data={pricingData}
            title={'Pricing Details'}
            isPricing
            onClick={() => {
              setCollapseContentTracking('pricing-details');
            }}
            extraRenderStatement={listing.price && !listing.price.hidden && !isEmpty(listing.price.detail)}
          />
          <ConditionalRender
            data={measurementsData}
            title={'Measurements' }
            onClick={() => {
              setCollapseContentTracking('measurements');
            }}
          />
          <ConditionalRender
            data={propulsionData}
            title={'Propulsion' }
            onClick={() => {
              setCollapseContentTracking('propulsion');
            }}
          />
          <ConditionalRender
            data={featuresData}
            title={'Features' }
            onClick={() => {
              setCollapseContentTracking('features');
            }}
          />
          <ConditionalRender
            data={moreDetails}
            title={'More Details'}
            isHtml
            isMultiItem
            onClick={() => {
              setCollapseContentTracking('more-details');
            }}
          />
          {lat && lng && !isPrivateSeller &&
            <Details heading="Location">
              <MapOnly address={locationAddress} coordinates={{ lat, lng }} />
            </Details>
          }
        </div>
      </section>
    </div>
  );
};

export default AccordionDetails;

import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import {
  BANNER_ATTRIBUTES,
  BANNER_TEXTS,
  LOCAL_DELIVERY,
  LIVE_VIDEO_TOUR
} from '../constants/boats';

export const defaultTagLimit = 1;
export const tagLimitForListingType = {
  standard: 1,
  enhanced: 2,
  premium: 1,
  sponsored: 1,
  oem: 0
};

/**
 * Generates the listing tags.
 * @param {Array<Object>} listing - The data associated with a listing.
 * @param {Object} listingType - The type of listing i.e. Standard, Enhanced, Premium or Sponsored.
 * @example
 * const listingType = {
    enhanced: true,
    sponsored: false,
    standard: false,
    premium: false
  };
 * @returns {Array<Object>} Returns and array of objects containing the tags information.
 */
export const generateTags = (listing, listingType) => {
  if (!listing && isEmpty(listingType)) {
    return [];
  }
  const listingAttributes = get(listing,  'attributes', []);
  const bannerAttribute = find(listingAttributes, (a) => BANNER_ATTRIBUTES.includes(a));
  const bannerText = get(BANNER_TEXTS, bannerAttribute, '');
  const priceDiscount = get(listing, 'price.discount');
  const hasLocalDelivery = listingAttributes.includes(LOCAL_DELIVERY);
  const hasLiveVideoTour = listingAttributes.includes(LIVE_VIDEO_TOUR);
  const activeListingType = Object.keys(listingType).filter((active) => listingType[active]).join('');
  const tagLimit = tagLimitForListingType[activeListingType] || defaultTagLimit;

  const createdTags = createTags({
    bannerText,
    priceDiscount,
    hasLocalDelivery,
    hasLiveVideoTour
  });

  const tags = formatTags(createdTags, tagLimit);

  return tags;
};

/**
 * Formats the tags for use by a listing.
 * @param {Array<Object>} createdTags - The tags created for a listing.
 * @param {Number} tagLimit - The number of tags that can be displayed by a listing type.
 * @returns {Array<Object>} The required tags.
 */
export const formatTags = (createdTags, tagLimit) => {
  if (createdTags.length > 0) {
    return createdTags.sort((a, b) => a.priorityId - b.priorityId)
      .slice(0, tagLimit)
      .map((tag) => ({ text: tag.text }));
  }
  return [];
};

/**
 * Provides the tags a listing can use, including their priority order.
 * @param {Object} tagOptions - The options required to provide a tag.
 * @returns {Array<Object> | Array}  Available tags for a listing or an empty array.
 */
export const createTags = ({ priceDiscount, bannerText, hasLocalDelivery, hasLiveVideoTour }) => {
  let arr = [];

  if (priceDiscount) {
    arr.push({
      priorityId: 1,
      text: 'Price Drop'
    });
  }

  if (bannerText) {
    arr.push({
      priorityId: 2,
      text: bannerText
    });
  }

  if (hasLocalDelivery) {
    arr.push({
      priorityId: 3,
      text: 'Local Delivery'
    });
  }

  if (hasLiveVideoTour) {
    arr.push({
      priorityId: 4,
      text: 'Live Video Tour'
    });
  }

  return arr;
};

import React from 'react';
import './styles.css';
import {US_STATES} from '../../constants/boats';
import {getDealerSearchUrl, makeDealerUrl} from '../../utils/urlHelpers/boatDealers';
import {pluralize, safeWindowSize} from '../../utils/dealerSRPHelper';
import DealerViewAll from '../DealerViewAll';
import {hasCountedKey, toColumns} from '../../utils/dealerHelpers/custom';
import DealerColumn from '../DealerColumn';

const toDealerState = (location, city, zip, make) => {
  if (!hasCountedKey(location)) {
    return null;
  }
  const state = US_STATES.find(usState => usState.value === location.key);
  if (!state) {
    return null;
  }
  return {
    name: state.name,
    count: location.doc_count,
    url: makeDealerUrl(location.key, city, zip, make)
  };
};

const toDealerCity = (location, state, zip, make) => hasCountedKey(location) &&
  { name: location.key,
    count: location.doc_count,
    url: makeDealerUrl(state, location.key, zip, make)
  };

const toDealerZip = (location, state, city, make) => hasCountedKey(location) &&
  { name: location.key,
    count: location.doc_count,
    url: makeDealerUrl(state, city, location.key, make)
  };


const byName = (a, b) => a.name > b.name ? 1 : -1;

const DealerLocations = ({locations, colSize = 13, singleView = false, filters = {}, hasResults = true}) => {
  if (!Array.isArray(locations)) {
    return null;
  }

  const {make, postalCode, countrySubDivisionCode, city} = filters;
  const modalUrlParams = { modal: true, city, countrySubDivisionCode, make, postalCode };
  let dealerLocations;
  let byLocation;
  if (city && hasResults) {
    dealerLocations = locations.map((l) => toDealerZip(l, countrySubDivisionCode, city, make));
    byLocation = 'by Zip Code';
  } else if (countrySubDivisionCode && hasResults) {
    dealerLocations = locations.map((l) => toDealerCity(l, countrySubDivisionCode, postalCode, make));
    byLocation = 'by City';
  } else if (make && hasResults) {
    dealerLocations = locations.map((l) => toDealerState(l, city, postalCode, make));
    byLocation = 'by State';
  } else {
    dealerLocations = locations.map((l) => toDealerState(l, undefined, undefined, undefined));
    byLocation = 'by State';
  }

  const dealerStr = pluralize('Dealer', locations.length);
  const title = `${dealerStr} ${byLocation}`;
  dealerLocations = dealerLocations.filter(l => !!l).sort(byName);
  const totalCols = safeWindowSize().innerWidth <= 768 ? 1 : 4;
  const width = totalCols === 1 ? 90 : 60;
  const height = 50;
  const locationColums = toColumns(dealerLocations, colSize, totalCols);
  const dealerColums = locationColums.map((colList, i) => <DealerColumn dealerCols={colList} key={i} />);
  const modalColSize = Math.ceil(locations.length / totalCols);
  const modalLocationColums = toColumns(dealerLocations, modalColSize, totalCols);
  const modalDealerColumns = modalLocationColums.map((colList, i) => <DealerColumn dealerCols={colList} key={i} />);

  return (
    <div id="dealer-locations-id" className="dealer-locations-container">
      <h2>{title}</h2>
      <div className="dealer-locations">
        {dealerColums}
      </div>
      { singleView ? null : <DealerViewAll title={title} href={getDealerSearchUrl(modalUrlParams)} modalContent={modalDealerColumns} width={width} height={height}/> }
    </div>
  );
};

export default DealerLocations;

/**
 * This initializes the Kameleoon client side SDK
 *
 * Requires: window.KameleoonSDK to be loaded, see: src/server/render.js for `kameleoonSDK-X.X.X.js`
 * For the backend (node) SDK initialization, see: src/utils/experiment/kameleoon.js
 */

const options = {
  siteCode: process.env.REACT_APP_KAMELEOON_SITE_CODE,
  configuration: {
    updateInterval: 20,
    environment: process.env.REACT_APP_KAMELEOON_ENV_KEY
  }
};

let kameleoonClient;

export const kameleoonClientInitialize = async () => {
  if (!window.KameleoonSDK) {
    return;
  }
  kameleoonClient = new window.KameleoonSDK.KameleoonClient(options);
  await kameleoonClient.initialize();
};
export { kameleoonClient };

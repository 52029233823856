
export const formatReportFraudEmailWithParams = (email, listing) => {
  if (listing?.portalLink) {
    const windowLocation = listing.portalLink.replace(/\/$/, '').split('/');
    const imtID = windowLocation[windowLocation.length - 1]; // Since we want the las section of the URL we don't use listing.id, use window.location.pathname instead
    const encodedSubjectWithImdID = encodeURIComponent(`Fraud Alert for ${imtID}`);
    const body = encodeURIComponent(`${listing.portalLink}\nPlease write your message below\n-----------\n\n`);
    return `${email}?subject=${encodedSubjectWithImdID}&body=${body}`;
  }
  return email;
};

import * as utils from '../../store/utils';

import {useMemo} from 'react';
import get from 'lodash/get';
import {BRANDS_ID} from '../../constants/boats';
import {normalizeString} from '@dmm/lib-common/lib/formatting';
import queryString from 'query-string';
import {
  generateSearchPath,
  getDefaultParams,
  getLocationRedirectUrl,
  getMatchedParams,
  redirectPartialMakeNameToFullMakeName,
  reduceURLForRedirect
} from '../urlHelpers/listings';
import {getClassCategory} from '../classHelper';
import head from 'lodash/head';
import {cookiesLib} from '../cookies';

export const getUrlRedirect = (props) => {
  const isBranded = !!get(props, 'match.params.oem');
  const makeModel = head(get(props, 'facets.makeModel', []));
  const makeUrl = get(props, 'match.params.oem') || get(props, 'match.params.make', '');
  const fullMakeNameUrl = makeModel ? `${isBranded ? 'oem' : 'make'}-${normalizeString(makeModel.value)}` : '';
  return makeUrl !== fullMakeNameUrl && makeModel ?
    redirectPartialMakeNameToFullMakeName(props, fullMakeNameUrl) : reduceURLForRedirect(props);

};

export const locationRedirectUrl = (urlMatchParams = {}, bypassUrl, zipGeoData, canBeRedirected) => {
  if (!canBeRedirected) {
    if (!zipGeoData && bypassUrl) {
      return bypassUrl;
    }
    return '';
  }
  if (bypassUrl) {
    const matched = getMatchedParams(bypassUrl);
    return getLocationRedirectUrl(matched, zipGeoData, '', bypassUrl);
  }
  return getLocationRedirectUrl(urlMatchParams, zipGeoData);
};

export const typeClassRedirectUrl = (urlMatchParams = {}) => {
  if (urlMatchParams?.classes && !urlMatchParams?.types){
    const type = urlMatchParams.classes.replace('class-','').split('+').reduce(
      (acc, value) => acc + getClassCategory(value) + '+', 'type-'
    ).slice(0,-1);
    const update = getDefaultParams({types: type, classes: urlMatchParams.classes});
    return generateSearchPath(update, getDefaultParams(urlMatchParams), true);
  }
  return '';
};

export const validMakeRedirectUrl = (urlMatchParams, records)=>  {
  const make = records?.[0]?.make;
  const validMake = records?.[0]?.validMake;
  if (validMake === 'other' && validMake !== make && urlMatchParams.make?.includes(normalizeString(make))) {
    return generateSearchPath({make: validMake}, getDefaultParams(urlMatchParams), true);
  }
  return '';
};

export const doubleHyphenRedirect = (path, isNotRoot) => {
  let regexMakeDoubleHyphen = new RegExp('/make-(.)+--(.)+/?');
  if (isNotRoot && regexMakeDoubleHyphen.test(path)) {
    let groups = regexMakeDoubleHyphen.exec(path);
    // Redirect makes with two hyphens on its name to the same make with a single hyphen
    let newPath = path.replace(groups[0], groups[0].split('--').join('-'));
    newPath = newPath.charAt(newPath.length - 1) !== '/' ? `${newPath}/` : newPath;
    return newPath;
  }
  return '';
};

export const parseBoatIndexPageProps = (props, isServer) => {
  const hasNoListings = props.search?.count === 0;
  const records = props.search?.records;
  const pathName = props.location?.pathname;
  const isNotRoot = pathName !== '/boats';
  const makeModel = props.facets?.makeModel?.[0];
  const success = props.success;
  const params = props.params || {};
  const urlMatchParams = props.match?.params || {};
  const shouldRenderOnServer = isServer && isNotRoot && hasNoListings && success;
  const canBeRedirected = isNotRoot && !hasNoListings && success;
  const isBrandsPage = get(props, 'match.url', '').startsWith(`/${BRANDS_ID}/`);
  const searchPage = isBrandsPage ? BRANDS_ID : props.searchPage;
  const isBranded = !!urlMatchParams.oem;
  const makeUrl = params.oem || params.make || '';
  const bypassUrl = props.bypassUrl;
  const partyDetails = props.partyDetails;
  const dealerId = urlMatchParams?.ownerId;
  const zipGeoData = props.zipGeoData;
  let emptyWrongPage = false;
  let cookiesRedirectOnServer = false;
  const cookies = props.cookies;
  if (shouldRenderOnServer) {
    emptyWrongPage = !params.ownerId;
    const canCreateCookies = cookiesLib.canCreateCookies('required', cookies?.cookies);
    cookiesRedirectOnServer = !makeModel && canCreateCookies;
  }

  let pageType = isBranded ? 'OemSearchResults' : 'SearchResults';
  pageType = dealerId ? 'DealerGallery' : pageType;
  return {
    hasNoListings,
    records,
    isNotRoot,
    makeModel,
    success,
    cookiesRedirectOnServer,
    emptyWrongPage,
    searchPage,
    isBranded,
    makeUrl,
    canBeRedirected,
    urlMatchParams,
    bypassUrl,
    pathName,
    partyDetails,
    dealerId,
    pageType,
    zipGeoData,
    cookies
  };
};

const getNoAdsParam = (props) => {
  return get(queryString.parse(props.location?.search), 'noAds', null) === 'true';
};

export const useBoatsIndexProps = (props) => {
  const {location, match, search, facets, params, success, cookies} = props;
  const isServer = utils.isServer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const noAdsParam = useMemo(() => getNoAdsParam(props), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pageProps = useMemo(() => parseBoatIndexPageProps(props, isServer), [location, match, search, facets, params, success, cookies]);
  pageProps.noAdsParam = noAdsParam;
  return pageProps;
};

import React from 'react';
import './styles.css';
import { ApplyNowButton } from '../ApplyNowButton';
import { TRACKING_BOAT_LOANS_APPLY_ONLINE_ACTION_LABEL } from '../../../../constants/BoatLoansV2';

export const FromBoatTraderFinancing = () => {
  return (
    <>
      <div className="from-boat-trader-financing-main">
        <div className="container">
          <img className="whyTrident-logos" alt="BoatTrader and Trident Funding" src={`${process.env.REACT_APP_CDN_URL}/boat-loans/BT-TridentLogo-dark.svg`} />
          <h2 className="title">Best Boat Loans</h2>
          <div className="text">
            Boat Trader partners with Trident Funding to offer best in class boat loans, making it easy to find and buy your dream boat.
            We make boat financing simple to take the stress and guesswork out of finding the right lender and best rate. We’ll take into
            account your desired loan amount, down payment, boat type and age, current boat loan interest rates, terms and credit to compare lenders and find the right loan for you.
          </div>
          <div className="list-container">
            <div className="column">
              <div className="title">Loan Benefits</div>
              <ul>
                <li><span>Instant access to over 15 marine lenders</span></li>
                <li><span>Get finalized and approved within 24 hours</span></li>
                <li><span>60 day deferred payment</span></li>
                <li><span>Best in class terms: 10 to 20 years boat loan terms</span></li>
              </ul>
            </div>
            <div className="column">
              <div className="title">Features</div>
              <ul>
                <li><span>Boat loans from $10,000 to $2,000,000</span></li>
                <li><span>Zero down boat loans up to $100,000</span></li>
                <li><span>Stated income boat loans up to $250,000</span></li>
              </ul>
            </div>
            <div className="column">
              <div className="title">Types</div>
              <ul>
                <li><span>New boat loans</span></li>
                <li><span>Used boat loans</span></li>
                <li><span>Boat loan refinancing</span></li>
                <li><span>Special purpose entity (LLC or Trust) ownership</span></li>
                <li><span>Recreational & limited charter options available</span></li>
                <li><span>Private party purchase</span></li>
                <li><span>Dealer purchase</span></li>
              </ul>
            </div>
          </div>
          <ApplyNowButton
            text="Get pre-qualified"
            type="button"
            utmCampaign="btboatloanspage"
            utmContent="btboatloanspage"
            data-reporting-click-internal-link-id="boat loans - apply online in 5 minutes"
            data-reporting-click-internal-link-type="click"
            trackingEvent={{
              'action_label': TRACKING_BOAT_LOANS_APPLY_ONLINE_ACTION_LABEL
            }}
          />
        </div>
      </div>
    </>
  );
};

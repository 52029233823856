import React, { PureComponent } from 'react';

import DefaultLayout from '../../layouts/Default';
import ErrorMessage from '../../components/ErrorMessage';

export default class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
  }

  componentDidCatch(error, info) {
    console.log('error catch: ', error);
    console.log('error catch info: ', info);
    this.setState({ hasError: true, error: error, info: info });
  }

  render() {
    if (this.state.hasError) {
      return (
        <DefaultLayout {...this.props} pageType="notFound">
          <ErrorMessage {...this.props} message="Please reset your search and try again." altUrl="/boats"/>
        </DefaultLayout>
      );
    }
    return this.props.children;

  }
}

export const GET_DATA_REQUEST = 'GET_DATA_REQUEST';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
export const GET_FACET_REQUEST = 'GET_FACET_REQUEST';
export const IDLE_MAIN_REQUEST = 'IDLE_MAIN_REQUEST';
export const GET_FACET_SUCCESS = 'GET_FACET_SUCCESS';
export const GET_MODELS_REQUEST = 'GET_MODELS_REQUEST';
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS';
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const MYBOATS_LISTINGS_SUCCESS = 'MYBOATS_LISTINGS_SUCCESS';
export const MYBOATS_LISTINGS_FAILURE = 'MYBOATS_LISTINGS_FAILURE';
export const MYBOATS_REQUIRE_LOGIN = 'MYBOATS_REQUIRE_LOGIN';
export const GET_SORT_PARAM = 'GET_SORT_PARAM';
export const PRICE_PER_MONTH = 'PRICE_PER_MONTH';
export const INCREMENT_CAROUSEL_CLICKCOUNT = 'INCREMENT_CAROUSEL_CLICKCOUNT';
export const INCREMENT_CAROUSEL_MODAL_CLICKCOUNT = 'INCREMENT_CAROUSEL_MODAL_CLICKCOUNT';
export const NEXT_PREVIOUS_DATA = 'NEXT_PREVIOUS_DATA';
export const SET_USER_COUNTRY_CODE = 'SET_USER_COUNTRY_CODE';
export const SET_USER_COUNTRY_CODE_ERROR = 'SET_USER_COUNTRY_CODE_ERROR';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_MARKET = 'SET_USER_MARKET';
export const SET_USER_REF_VALUE = 'SET_USER_REF_VALUE';
export const SET_USER_RADIUS_MILES = 'SET_USER_RADIUS_MILES';
export const SET_USER_ZIPCODE = 'SET_USER_ZIPCODE';
export const GET_ENGINES_FACETS_REQUEST = 'GET_ENGINES_FACETS_REQUEST';
export const GET_ENGINES_FACETS_SUCCESS = 'GET_ENGINES_FACETS_SUCCESS';
export const TRIDENT_SET_RATES = 'TRIDENT_SET_RATES';
export const TRIDENT_SET_BOAT_LOANS_CONTENT = 'TRIDENT_SET_BOAT_LOANS_CONTENT';
export const TRIDENT_LEAD_SUBMITTED = 'TRIDENT_LEAD_SUBMITTED';
export const BYPASSING_URL = 'BYPASSING_URL';
export const BYPASSED_URL = 'BYPASSED_URL';
export const SET_ADDITIONAL_LEADS_DATA = 'SET_ADDITIONAL_LEADS_DATA';
export const TOGGLE_ADDITIONAL_LEADS_MODAL = 'TOGGLE_ADDITIONAL_LEADS_MODAL';
export const TOGGLE_ADDITIONAL_LEADS_ALERT = 'TOGGLE_ADDITIONAL_LEADS_ALERT';
export const TOGGLE_SUBMISSION_SUCCESS_ALERT = 'TOGGLE_SUBMISSION_SUCCESS_ALERT';
export const SET_CONTACT_FORM_MESSAGE = 'SET_CONTACT_FORM_MESSAGE';
export const SHOW_POSTLEAD_MODAL = 'SHOW_POSTLEAD_MODAL';
export const GET_DATA_SUCCESS_HOME = 'GET_DATA_SUCCESS_HOME';
export const PAGES_WITH_API_REQUEST = ['SearchResults', 'engines', 'BoatDealers'];

import {normalizeString} from '@dmm/lib-common/lib/formatting';
import { ABOUT_META_DESCRIPTION, ABOUT_TITLE } from '../constants/editorialContent';

export const getEditorialTitle = (pathname) => {
  const normalizedPathname = normalizeString(pathname);
  switch (normalizedPathname) {
  case 'about':
    return ABOUT_TITLE;
  default:
    return '';
  }
};

export const getEditorialMetaDescription = (pathname) => {
  const normalizedPathname = normalizeString(pathname);
  switch (normalizedPathname) {
  case 'about':
    return ABOUT_META_DESCRIPTION;
  default:
    return '';
  }
};

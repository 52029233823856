import React, {useState} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const ImageWithErrorHandler = ({src, defaultSrc, alt, ...props }) => {
  const [error, setError] = useState(false);
  if (!error) {
    return (
      <LazyLoadImage {...props} src={src} alt={alt} onError={() => setError(true)} />
    );
  }

  return (
    <LazyLoadImage src={defaultSrc} alt={alt} />
  );
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OemContactForm from '../OemContactForm';
import { setModalEvent } from '../../store/actions/dataLayer';
import { REACT_APP_PRIVACY_POLICY_URL, REACT_APP_TERMS_OF_USE_URL, OEM_CONTACT_MESSAGE } from '../../constants/boats';

import './styles.css';

const MODAL_TYPE = {
  oem: {
    type: 'oem',
    trackingLabel: 'contact oem'
  }
};

const OemModal = ({ type = 'oem', ...props }) => {
  const onClose = (delay) => {
    setModalEvent('close', MODAL_TYPE[type].trackingLabel);
    if (delay) {
      setTimeout(() => props.onClose(), 3000);
      return;
    }
    props.onClose();
  };

  useEffect(() => {
    setModalEvent('open', MODAL_TYPE[type].trackingLabel);

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className="broker-modal-container">
    <div className="broker-modal">
      <div className="header bar">
        <button className="contact-close" onClick={() => onClose()}>
          <span className="visuallyhidden">Close</span>
        </button>
      </div>
      <OemContactForm
        isLoading={props.isWorking}
        disableSticky={true}
        initialState="open"
        breakpoint={props.isMobile ? 'mobile' : 'desktop'}
        contact={props.listing.contact}
        partyId={props.oemDetails.ownerId}
        type={type}
        onSuccess={() => onClose(true)}
        id={'oem-contact-form'}
        prePopulatedText={props.prePopulatedText ? props.prePopulatedText : OEM_CONTACT_MESSAGE}
        privacyPolicyUrl={REACT_APP_PRIVACY_POLICY_URL}
        termsOfUseUrl={REACT_APP_TERMS_OF_USE_URL}
        listingId={props.listing.id}
        listing={props.listing}
        cta={props.cta}
      />
    </div>
  </div>;
};

export default connect(
  null,
  dispatch => bindActionCreators({
    setModalEvent
  }, dispatch)
)(OemModal);

import React, { PureComponent } from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import { DEFAULT_NO_BOAT_IMAGE } from '../../constants/boats';
import { getTitle, addResizeParams } from '../../utils/listingHelper';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class ListingImage extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      imageError: false
    };
    this.handleImageError = this.handleImageError.bind(this);
  }

  handleImageError() {
    this.setState({ imageError: true });
  }

  render() {
    let { listing, lazyLoad} = this.props;
    let listingMedia = get(listing, 'media', []);
    let images = listingMedia.filter((media) => {
      return media.mediaType === 'image';
    });
    let image = images[0];
    let title = getTitle(listing);
    let resize = this.props.resize || {};

    return <>
      {
        image && !this.state.imageError ?
          <LazyLoadImage
            src={addResizeParams(get(image, 'original.url', image.url), resize, image.date.modified, true, 'webp')}
            alt={title}
            onError={this.handleImageError}
            className={classnames('image-results', { 'wide': image.width > 700 })}
            visibleByDefault={!lazyLoad}
            threshold={200}
            placeholder={<div className={classnames('preloader')}>{title}</div>}
            width={resize.width}
            height={resize.height}
          /> :
          <LazyLoadImage
            src={DEFAULT_NO_BOAT_IMAGE}
            alt={title}
            className={classnames('image-results', 'default')}
            visibleByDefault={!lazyLoad}
            threshold={200}
            placeholder={<div className={classnames('preloader')}>{title}</div>}
            width={resize.width}
            height={resize.height}
          />
      }
    </>;
  }
}

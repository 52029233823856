import React from 'react';
import {Helmet} from 'react-helmet';


import {getBreadCrumbSchema, getProductSchema} from '../../../utils/metaContentHelper';

const BDPHelmet = ({listing, mediaImages, breadCrumbItems}) => {
  const productSchemaContent = getProductSchema(listing);
  const breadCrumbSchema = <script type="application/ld+json">{JSON.stringify(getBreadCrumbSchema(breadCrumbItems))}</script>;
  const productSchema = productSchemaContent ? <script type="application/ld+json">{JSON.stringify(productSchemaContent)}</script> : '';
  const ogImageMetaTag = mediaImages?.length > 0 ? <meta name="og:image" content={mediaImages[0].url} /> : '';
  return (
    <Helmet>
      {breadCrumbSchema}
      {productSchema}
      {ogImageMetaTag}
    </Helmet>
  );
};

export default BDPHelmet;

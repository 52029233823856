import React from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.css';

import { BRAND_BANNER_IMPRESSION } from '../../constants/boats';
import { setGenericEvent, setOemBrandProductImpression } from '../../store/actions/dataLayer';

const DealerLocatorForm = ({ oemDetails, showModalDealerLocator, setOemBrandProductImpression, setGenericEvent }) => {
  const [ imageLoadError, setImageLoadError ] = React.useState(false);
  const onClick = () => {
    setGenericEvent('modal action', 'find a dealer', 'page navbar');
    showModalDealerLocator();
  };

  return (
    <div className={classnames('dealer-locator-container')} data-testid="dealer-locator-form">
      <div className={classnames('dealer-locator-logo')}>
        <div className="flex-logo">
          {!imageLoadError && <LazyLoadImage
            src = {oemDetails.logoUrl}
            alt={`${oemDetails.name || 'brand'} logo`}
            onError={() => setImageLoadError(true)}
            onLoad={() => setOemBrandProductImpression(
              oemDetails.brandId,
              BRAND_BANNER_IMPRESSION,
              BRAND_BANNER_IMPRESSION
            )}
          />}
        </div>
        {get(oemDetails, 'headline') && <span className="flex-title">{get(oemDetails, 'headline')}</span>}
      </div>
      <button
        onClick={onClick}
        type="submit"
        className="btn-action flex-button btn-primary"
        data-reporting-event-category="soft lead"
        data-reporting-event-action="find a dealer"
        data-reporting-event-label="page navbar"
        data-testid="find-a-dealer-near-you"
      >Find a Dealer Near You</button>
    </div>
  );
};

export default connect(
  null,
  dispatch => bindActionCreators({
    setOemBrandProductImpression,
    setGenericEvent
  }, dispatch)
)(DealerLocatorForm);

import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import replace from 'lodash/replace';
import set from 'lodash/set';
import { generatePath, matchPath } from 'react-router-dom';
import { formatMultiArrayForURL, normalizeString }  from '@dmm/lib-common/lib/formatting';
import {
  SEARCH_URL_PATTERN,
  SEARCH_URL_PATTERN_BRAND,
  SEARCH_URL_STATE_PATTERN,
  SEARCH_URL_ZIP_PATTERN,
  SEARCH_URL_RADIUS_PATTERN,
  SEARCH_URL_LENGTH_PATTERN,
  SEARCH_URL_ENGINETYPE_PATTERN,
  SEARCH_URL_PAGE_PATTERN,
  SEARCH_URL_SORT_PATTERN,
  SEARCH_URL_CONDITION_PATTERN,
  SEARCH_URL_KEYWORD_PATTERN,
  SEARCH_URL_MAKE_PATTERN,
  SEARCH_URL_MODEL_PATTERN,
  SEARCH_URL_MODEL_RANGE_PATTERN,
  SEARCH_URL_CLASS_PATTERN,
  SEARCH_URL_TYPE_PATTERN,
  SEARCH_URL_YEAR_PATTERN,
  SEARCH_URL_FUEL_PATTERN,
  SEARCH_URL_HULL_PATTERN,
  SEARCH_URL_PRICE_PATTERN,
  SEARCH_URL_PRICE_REDUCED_PATTERN,
  SEARCH_URL_CITY_PATTERN,
  SEARCH_URL_CLASS_GROUP_PATTERN,
  SEARCH_URL_MODAL_PATTERN,
  SEARCH_URL_BY_PATTERN,
  SEARCH_URL_OWNER_PATTERN,
  DEFAULT_REFINE_SEARCH,
  DEFAULT_MAX_YEAR,
  FACET_META_MAP,
  allTypes,
  SEARCH_URL_MAKE_MODEL_PATTERN,
  SLIDER_MAX_VALUE,
  INDEXABLE_PARAMS,
  GROUPS_CLASSES_MAPPING,
  SEARCH_URL_OEM_PATTERN,
  MAKE_MODEL_EXEMPTED_SPECIAL_CHARACTERS, US_STATES,
  BOATS_PAGE_SIZE,
  BRANDS_ID,
  BOATS_SRP_PATH
} from '../../constants/boats';
import { sortTypeMap, getDefaultSort, ENGINES_SORT_TYPES, getDefaultEnginesSort } from '../sortHelper';
import { multiFacetUrlToObject, multiFacetUrlToArray, toggleSubFacet } from '../multiFacetHelper';
import { getClassCategory, checkNeedsDefaults, mapSmallBoatsToAPI,
  mapAllClassForType, movePWCToPower, handleLegacyAllType, handleLegacyType } from '../classHelper';
import {
  engineLegacyTypes,
  engineQueryParam,
  enginePathName,
  engineKeyFromValue,
  nonNegativeIntegerString,
  engineSearchParams, getActiveEngineParams
} from '../engineTypeHelper';

import {getDefaultParams as legacyGetDefaultParams} from './searchResults';
import {
  ENGINES_ID,
  ENGINES_SEARCH_URL_PATTERN,
  ENGINES_SEARCH_URL_TYPE_PATTERN,
  ENGINES_SEARCH_URL_HORSEPOWER_PATTERN,
  ENGINES_SEARCH_URL_PRICE_PATTERN,
  DEFAULT_ENGINE_REFINE_SEARCH,
  ENGINES_PAGE_SIZE
} from '../../constants/Engines';


const HULL_MATERIAL_BOAT_CLASS_MAPPING = {
  inflatable: 'power-inflatable',
  'rigid+inflatable': 'power-rib'
};

const FUEL_TYPE_MAPPING = {
  gas: 'gasoline'
};

export const generateMakeAndModelPathParam = (makeModel) => {
  let makes = Object.keys(makeModel).length;
  if (makes === 1) {
    let models = 0;
    Object.values(makeModel).map(item => {
      models += item.length;
    });
    if (models < 2) {
      let make = Object.keys(makeModel)[0];
      let makeMod = { 'make': make };
      if (models === 1) {
        makeMod.model = makeModel[make][0];
      }
      return makeMod;
    }
  }
  return null;
};

// Update in future with other possible patterns (trailers)
const getSearchPattern = (searchPage) => {
  if (searchPage === BRANDS_ID) {
    return SEARCH_URL_PATTERN_BRAND;
  }

  return searchPage === ENGINES_ID ? ENGINES_SEARCH_URL_PATTERN : SEARCH_URL_PATTERN;
};

const getRefineSearchDefaults = (searchPage) => {
  return searchPage === ENGINES_ID ? DEFAULT_ENGINE_REFINE_SEARCH : DEFAULT_REFINE_SEARCH;
};

export const clearPathParamsByPage = (params, searchPage) => {
  if (searchPage === ENGINES_ID) {
    return engineSearchParams(params);
  }
  return params;
};

export const generateSearchPath = (changes, urlProps, newSearch, searchPage = '') => {
  let initialParams = {
    ...urlProps,
    ...changes
  };

  let finalParams = {};
  initialParams = clearPathParamsByPage(initialParams, searchPage);

  //************ Common filter patterns for all pages ***************
  if (initialParams.modal) {
    delete initialParams.modal;
  }

  if (initialParams.makeModel && !isEmpty(initialParams.makeModel)) {
    let seperateMakeModel = generateMakeAndModelPathParam(initialParams.makeModel);
    if ( seperateMakeModel ) {
      finalParams.make = `make-${normalizeString(seperateMakeModel.make, MAKE_MODEL_EXEMPTED_SPECIAL_CHARACTERS)}`;
      if (seperateMakeModel.model) {
        finalParams.model = `model-${normalizeString(seperateMakeModel.model, MAKE_MODEL_EXEMPTED_SPECIAL_CHARACTERS)}`;
      }
    }
    else {
      const models = flatten(Object.keys(initialParams.makeModel)
        .map(makeName => {
          const models = Object.values(get(initialParams.makeModel, makeName, []));
          if (models.length) {
            return models.map(modelName => {
              return `${makeName}:${normalizeString(modelName, MAKE_MODEL_EXEMPTED_SPECIAL_CHARACTERS)}`;
            });
          }
          return makeName;
        })).filter(makeModel => !!makeModel);
      finalParams.makeModel = `makemodel-${models.join('+')}`;
    }
    delete initialParams.makeModel;
    delete initialParams.oem;
  }

  if ((initialParams.modelRange && !isEmpty(initialParams.modelRange) || (initialParams['model-range'] && !isEmpty(initialParams['model-range'])))) {
    finalParams.modelRange = initialParams.modelRange ? `model-range-${normalizeString(initialParams.modelRange)}` : `model-range-${initialParams['model-range']}`;
    delete initialParams.modelRange;
    delete initialParams['model-range'];
  }

  if (initialParams.oem && !isEmpty(initialParams.oem)) {
    let seperateMakeModel = generateMakeAndModelPathParam(initialParams.oem);
    if (seperateMakeModel) {
      finalParams.oem = searchPage === BRANDS_ID ? seperateMakeModel.make : `oem-${seperateMakeModel.make}`;
      if (seperateMakeModel.model) {
        finalParams.model = `model-${normalizeString(seperateMakeModel.model)}`;
      }
    } else {
      const models = flatten(Object.keys(initialParams.oem)
        .map(makeName => {
          const models = Object.values(get(initialParams.oem, makeName, []));
          return models.map(modelName => {
            return `${makeName}:${normalizeString(modelName)}`;
          });
        })).filter(makeModel => !!makeModel);
      finalParams.oem = searchPage === BRANDS_ID ? `${models.join('+')}` : `oem-${models.join('+')}`;
    }
    delete initialParams.oem;
    delete initialParams.model;
    delete initialParams.makeModel;
  }

  if (initialParams.sort) {
    finalParams.sort = `sort-${initialParams.sort}`;
    delete initialParams.sort;
  }

  if (initialParams.condition) {
    if (initialParams.condition !== 'any') {
      finalParams.condition = `condition-${initialParams.condition}`;
    }
    delete initialParams.condition;
  }

  if (initialParams.ownerId) {
    finalParams.ownerId = `dealer-${initialParams.ownerId}`;
    delete initialParams.ownerId;
  }

  if (!isEmpty(initialParams.year)) {
    if (searchPage === ENGINES_ID) {
      const min = nonNegativeIntegerString(initialParams.year.min);
      const max = nonNegativeIntegerString(initialParams.year.max);
      finalParams.year = `year-${min || ''}`;
      if (Number(max) >= 0) {
        finalParams.year += `,${max}`;
      }
      if (finalParams.year === 'year-') {
        delete finalParams.year;
      }
    } else {
      let year = [initialParams.year.min, DEFAULT_MAX_YEAR + 1];
      if (initialParams.year.max && initialParams.year.max < DEFAULT_MAX_YEAR + 1) {
        year[1] = initialParams.year.max;
      }
      finalParams.year = 'year-' + year.join(',');
    }
    delete initialParams.year;
  }

  if (initialParams.fuelType) {
    if (
      initialParams.fuelType.length > 0 &&
      initialParams.fuelType !== 'all' &&
      !isEqual(initialParams.fuelType, ['all'])
    ) {
      finalParams.fuelType = `fuel-${initialParams.fuelType}`;
    }
    delete initialParams.fuelType;
  }

  if (initialParams.hullMaterial) {
    if (initialParams.hullMaterial.length > 0) {
      finalParams.hullMaterial = `hull-${initialParams.hullMaterial}`;
    }
    delete initialParams.hullMaterial;
  }

  if (initialParams.city) {
    if (initialParams.city.length > 0) {
      finalParams.city = `city-${initialParams.city.join('+')}`;
    }
    delete initialParams.city;
  }

  if (!isEmpty(initialParams.length)) {
    if (initialParams.length.max && initialParams.length.max !== SLIDER_MAX_VALUE) {
      finalParams.length = `length-${initialParams.length.min || 0},${initialParams.length.max}`;
    } else {
      finalParams.length = `length-${initialParams.length.min || 0}`;
    }
    delete initialParams.length;
  }

  if (initialParams.multiFacetedBoatTypeClass) {
    let typeList = [];
    let classList = [];

    if (initialParams.multiFacetedBoatTypeClass.power) {
      typeList.push('power');
      let filtered = initialParams.multiFacetedBoatTypeClass.power.filter( item => item !== 'power-all');
      if (filtered.length) {
        classList = [...classList, filtered];
      }
    }
    if (initialParams.multiFacetedBoatTypeClass.sail) {
      typeList.push('sail');
      let filtered = initialParams.multiFacetedBoatTypeClass.sail.filter( item => item !== 'sail-all');
      if (filtered.length) {
        classList = [...classList, filtered];
      }
    }
    if (initialParams.multiFacetedBoatTypeClass.pwc) {
      typeList.push('pwc');
      let filtered = initialParams.multiFacetedBoatTypeClass.pwc.filter( item => item !== 'power-pwc');
      if (filtered.length) {
        classList = [...classList, filtered];
      }
    }
    if (initialParams.multiFacetedBoatTypeClass.small) {
      typeList.push('small');
      let filtered = initialParams.multiFacetedBoatTypeClass.small.filter( item => item !== 'small-all');
      if (filtered.length) {
        classList = [...classList, filtered];
      }
    }
    if (typeList.length) {
      finalParams.types = `type-${typeList.join('+')}`;
    }
    if (classList.length) {
      finalParams.classes = `class-${flatten(classList).join('+')}`;
    }
    delete initialParams.multiFacetedBoatTypeClass;
    if (typeList.length || classList.length) {
      delete initialParams.group;
    }
  }

  if (!isEmpty(initialParams.price)) {
    if (searchPage === ENGINES_ID) {
      const min = nonNegativeIntegerString(initialParams.price.min);
      const max = nonNegativeIntegerString(initialParams.price.max);
      finalParams.price = `price-${min || ''}`;
      if (Number(max) >= 0) {
        finalParams.price += `,${max}`;
      }
      if (finalParams.price === 'price-') {
        delete finalParams.price;
      }
    } else {
      if (initialParams.price.max && initialParams.price.max !== SLIDER_MAX_VALUE) {
        finalParams.price = `price-${initialParams.price.min || 0},${initialParams.price.max}`;
      } else {
        finalParams.price = `price-${initialParams.price.min || 0}`;
      }
    }
    delete initialParams.price;
  }

  if (initialParams.priceRevisedSince && initialParams.priceRevisedSince.length > 0) {
    finalParams.priceRevisedSince = 'price-drop';
    delete initialParams.priceRevisedSince;
  }

  if (get(initialParams, 'state', 'all').toLowerCase() === 'all') {
    delete initialParams.state;
  }
  if (!isEmpty(initialParams.state)) {
    finalParams.state = `state-${initialParams.state.toLowerCase()}`;
    delete initialParams.state;
  }

  if (initialParams.page) {
    if (newSearch) {
      delete initialParams.page;
      delete initialParams.pageSize;
    }
    else {
      if (initialParams.page !== '1' && initialParams.page !== 1) {
        finalParams.page = `page-${initialParams.page}`;
      }
      delete initialParams.page;
      delete initialParams.pageSize;
    }
  }

  if (initialParams.forSale) {
    if (initialParams.forSale !== 'all') {
      finalParams.by = `by-${initialParams.forSale}`;
    }
    delete initialParams.forSale;
  }

  //************ End common filters for all pages ***************

  // Here the filters that can have different patterns for each searchPage
  if (initialParams.engine) {
    if (initialParams.engine !== 'all') {
      if (searchPage === ENGINES_ID) {
        finalParams.type = `type-${enginePathName(initialParams.engine)}`;
      } else {
        finalParams.engine = `engine-${initialParams.engine}`;
      }
    }
    delete initialParams.engine;
  }

  if (!isEmpty(initialParams.powerHp)) {
    const min = nonNegativeIntegerString(initialParams.powerHp.min);
    const max = nonNegativeIntegerString(initialParams.powerHp.max);
    finalParams.horsepower = `horsepower-${min || ''}`;
    if (Number(max) >= 0) {
      finalParams.horsepower += `,${max}`;
    }
    if (finalParams.horsepower === 'horsepower-') {
      delete finalParams.horsepower;
    }
    delete initialParams.powerHp;
  }

  if (initialParams.fuelType === '' && searchPage === ENGINES_ID) {
    delete initialParams.fuelType;
  }

  if (!isEmpty(initialParams.zip)) {
    if (get(initialParams, 'zip', 'all').toLowerCase() !== 'all') {
      finalParams.zip = `zip-${initialParams.zip.toLowerCase()}`;
    }
    delete initialParams.zip;
  }

  //************ End filters with different patterns ***************
  const refineSearchDefaults = getRefineSearchDefaults(searchPage);
  for (let key in initialParams) {
    if (!isEqual(initialParams[key], refineSearchDefaults[key])) {
      let lower = key.charAt(0).toLowerCase() + key.slice(1);
      finalParams[key] = `${lower}-${initialParams[key]}`;
    }
  }

  const pathPattern = getSearchPattern(searchPage);
  return generatePath(pathPattern, finalParams);
};


export const getDefaultParams = (params, searchPage) => {

  let parsed = {
    ...parseStateParams(params.state),
    ...parseCityParams(params.city),
    ...parseZipParams(params.zip),
    ...parseRadiusParams(params.radius, searchPage),
    ...parseLegacyMakeModelParams(params.make, params.model),
    ...parseMakeModelParams(params.makeModel),
    ...parseLengthParams(params.length),
    ...parseEngineTypeParams(params.engine),
    ...parsePageParams(params.page, searchPage),
    ...parseSortParams(params.sort),
    ...parseConditionParams(params.condition),
    ...parseYearParams(params.year),
    ...parseFuelParams(params.fuelType),
    ...parseTypeAndClassParams(params.types,params.classes),
    ...parsePriceParams(params.price),
    ...parseClassGroupParams(params.group),
    ...parseModalParams(params.modal),
    ...parseByParams(params.by),
    ...parseOwnerParams(params.ownerId),
    ...parsePriceRevisedSince(params.priceRevisedSince),
    ...parseOemParams(params.oem, params.model),
    ...parseReplaceModelRangeParams(params.modelRange),
    ...parseReplaceKeywordParams(params.keyword)
  };
  parsed = {
    ...parsed,
    ...parseHullParams(params.hullMaterial, parsed.multiFacetedBoatTypeClass)
  };

  let defaultRefineSearch = DEFAULT_REFINE_SEARCH;
  if (searchPage === ENGINES_ID) {
    // We need to override engine from boats
    let engine = parseEngineCategoryTypeKey(params.type);
    parsed = {
      ...parsed,
      engine,
      ...parseHorsepowerParams(params.horsepower),
      ...parseEnginePriceParams(params.price)
    };
    defaultRefineSearch = DEFAULT_ENGINE_REFINE_SEARCH;
    delete parsed.multiFacetedBoatTypeClass;
  }

  let defaults = {
    ...defaultRefineSearch
  };

  defaults = Object.assign(defaults, parsed);
  return defaults;
};

export const getActiveParams = (params, searchPage) => {
  const defaults = getDefaultParams({}, searchPage);
  const active = Object.entries(params).filter(([key, value]) => {
    return defaults[key] !== value && key !== 'oem-model' && key !== 'shouldRemoveOemListing';
  });

  const allActive = active
    .reduce((object, [key, value]) => ({ ...object, [key]: value }), {});

  if (searchPage === ENGINES_ID) {
    return getActiveEngineParams(allActive);
  }
  return allActive;
};

export const cleanActiveParams = (params, searchPage) => {
  const allParams = params || {};
  return JSON.stringify(Object.entries(getActiveParams(allParams, searchPage)).map((value) => {
    return !isEmpty(value[1]) ? value : '';
  }));
};

export const currentUrlState = (match, searchPage) => {
  const matchParams = match?.params || {};
  return getDefaultParams(matchParams, searchPage);
};

export const getFormattedParamsArray = (active, makes, makeModels, searchPage = '') => {
  let params = getDefaultParams({}, searchPage);
  let href;
  let newValue;
  const notJoined = ['hullMaterial'];
  return flatten(Object.entries(active).map(([key, value]) => {
    const paramsMapping = FACET_META_MAP[key] || {};
    switch (key) {
    case 'fuelType':
    case 'hullMaterial':
      return value
        .map(type => {
          newValue = value.filter((facet) => facet !== type);
          if (!notJoined.includes(key)) {
            newValue = newValue.join(',');
          }
          href = generateSearchPath({
            ...omit(active, key),
            [key]: newValue
          }, params, true, searchPage);
          return { ...paramsMapping, key, indexKey: `${key}.${type}`,
            value: `${unhyphenateUrlComponents(type)}`, href, newValue };
        });
    case 'oem':
    case 'makeModel':
    case 'multiFacetedBoatTypeClass': {
      const makeModelWithoutModelRange = ['makeModel','oem'].includes(key) ? omit(active, 'modelRange') : active;
      return flatten(Object.entries(value).map(([facet, subFacets]) => {
        const makeModel = getCorrectMakeFromFacetValue(facet, makes, makeModels);
        if (subFacets && subFacets.length) {
          return subFacets.map((subFacetItem) => {
            newValue = {
              ...omit(value, facet),
              ...toggleSubFacet(facet, subFacetItem, value)
            };
            let formattedName;
            if (includes(allTypes, subFacetItem)){
              formattedName = `All ${facet}`;
            }

            if (searchPage === BRANDS_ID && subFacets.length === 1) {
              href = BOATS_SRP_PATH;
            } else {
              href = generateSearchPath({
                ...omit(makeModelWithoutModelRange, key),
                [key]: newValue
              }, params, true, searchPage);
            }

            return { ...paramsMapping, key, indexKey: `${key}.${facet}.${subFacetItem}`,
              value: formattedName || `${makeModel ?
                makeModel : unslugify(facet)} ${unslugify(subFacetItem)}`, href, newValue };
          });
        }
        newValue = omit(value, facet);

        if (searchPage === BRANDS_ID) {
          href = BOATS_SRP_PATH;
        } else {
          href = generateSearchPath({
            ...makeModelWithoutModelRange,
            [key]: newValue
          }, params, true, searchPage);
        }

        const makeAndModelRange = active.modelRange ? ` ${unslugify(active.modelRange, '-')}` : '';
        return { ...paramsMapping, key, indexKey: `${key}.${facet}`, value: `All ${makeModel ?
          makeModel : unslugify(facet)}${makeAndModelRange}`, href, newValue };
      }));
    }
    case 'city':
      newValue = params[key];
      href = generateSearchPath({
        ...omit(active, ['city', 'zip']),
        [key]: newValue
      }, params, true, searchPage);
      return {  ...paramsMapping, key, value, newValue, href };
    case 'state':
      newValue = params[key];
      href = generateSearchPath({
        ...omit(active, ['state', 'city', 'zip']),
        [key]: newValue
      }, params, true, searchPage);
      return {  ...paramsMapping, key, value, newValue, href };
    case 'priceRevisedSince':
      href = generateSearchPath({
        ...omit(active, key),
      }, params, true, searchPage);
      return { ...paramsMapping, indexKey: `${key}.${key}`, key: 'priceRevisedSince', formattedKey: 'Price', value: 'Price Drop', newValue: undefined, href};
    case 'ownerId':
      newValue = params[key];
      href = generateSearchPath({
        ...omit(active, key),
        [key]: newValue
      }, params, true, searchPage);
      return {  ...paramsMapping, key, value: unhyphenateUrlComponents(value.slice(0, value.lastIndexOf('-'))), href };
    case 'zip':
      newValue = params[key];
      href = generateSearchPath({
        ...omit(active, key, ['radius']),
        [key]: newValue
      }, params, true, searchPage);
      return {...paramsMapping, key, value, newValue, href};
    case 'modelRange':
      break;
    default:
    {
      newValue = params[key];
      let { prefix = '', suffix = '' } = paramsMapping;
      if (isObject(value)) {
        if (value.min && !value.max) {
          suffix += '+';
        } else if (!value.min && value.max) {
          prefix = 'Up to ' + prefix;
        }
      }
      href = generateSearchPath({
        ...omit(active, key),
        [key]: newValue
      }, params, true, searchPage);
      return {  ...paramsMapping, key, value, newValue, prefix, suffix, href };
    }
    }
  })).filter(value => value !== undefined);
};

export const getCorrectMakeFromFacetValue = (facet, makes, makeModels) => {
  if (makes) {
    return makes.reduce((filters, make) => {
      const slugMake = normalizeString(hyphenateUrlComponents(make.value));
      if (get(makeModels, slugMake) && slugMake === facet) {
        return make.value;
      }
      return filters;
    }, []);
  }
  return null;
};

const defaultParseSearchParams = (params, requestType, searchPage) => {
  return {
    ...parseReplaceStateParams(params.state),
    ...parseReplaceCityParams(params.city),
    ...parseZipParams(params.zip),
    ...parseReplaceRadiusParams(params.radius, searchPage),
    ...parseReplaceLegacyMakeModelParams(params.make, params.model, requestType),
    ...parseReplaceMakeModelParams(params.makeModel, requestType),
    ...parseReplaceLengthParams(params.length),
    ...parseReplaceEngineTypeParams(params.engine),
    ...parsePageParams(params.page, searchPage),
    ...parseReplaceSortParams(params.sort),
    ...parseReplaceConditionParams(params.condition),
    ...parseReplaceYearParams(params.year),
    ...parseReplaceFuelParams(params.fuelType),
    ...parseReplaceHullParams(params.hullMaterial),
    ...parseTypeAndClassParamsToArray(params.types, params.classes, params.group, requestType),
    ...parseReplaceClassGroupParams(params.group),
    ...parseReplacePriceParams(params.price),
    ...parseReplacePriceRevisedSinceParams(params.priceRevisedSince),
    ...parseReplaceByParams(params.by),
    ...parseReplaceOwnerParams(params.ownerId),
    ...parseReplaceOemParams(params.oem, params.model),
    ...parseReplaceModelRangeParams(params.modelRange),
    ...parseReplaceKeywordParams(params.keyword)
  };
};

const engineParseSearchParams = params => {
  return {
    ...parseEngineCategoryTypeParams(params.type),
    ...parseHorsepowerParams(params.horsepower),
    ...parseEnginePriceParams(params.price),
    ...parseReplaceFuelParamsSingleFaceted(params.fuelType)
  };
};

export const parseSearchParams = (url, requestType, searchPage = '') => {
  if (!url) {return {};}
  const page = url.startsWith(`/${BRANDS_ID}/`) ? BRANDS_ID : searchPage;
  const pattern = getSearchPattern(page);
  let { params } = matchPath(url, pattern);
  let parsed = defaultParseSearchParams(params, requestType, searchPage);
  if (searchPage === ENGINES_ID) {
    parsed = {
      ...parsed,
      ...engineParseSearchParams(params),
      ...parseEngineReplaceSortParams(params.sort, params.zip, params.city, params.state)
    };
  }
  return parsed;
};

export const parseSearchAppParams = (url, pageType) => {
  if (!url) {return {};}
  const pattern = getSearchPattern(pageType);
  let { params } = matchPath(url, pattern);
  return getDefaultParams(params, pageType);
};

export const parseOemParams = (oem = '', model = '') => {
  if (!oem) {
    return {};
  }

  let modelKey;
  if (SEARCH_URL_OEM_PATTERN.test(oem)) {
    const matches = SEARCH_URL_OEM_PATTERN.exec(oem);
    modelKey = matches[1];
  } else {
    modelKey = oem;
  }

  if (SEARCH_URL_MODEL_PATTERN.test(model)) {
    const modelMatches = SEARCH_URL_MODEL_PATTERN.exec(model);
    const modelValue = [unslugify(modelMatches[1], ' ', '-')];
    return {
      oem: { [modelKey]: modelValue }
    };
  }

  return {
    oem: multiFacetUrlToObject(modelKey, true, '+')
  };
};

export const parseReplaceOemParams = (oem = '', model) => {
  if (!oem) {
    return {};
  }

  let make;
  let makemodel;
  if (SEARCH_URL_OEM_PATTERN.test(oem)) {
    const matches = SEARCH_URL_OEM_PATTERN.exec(oem);
    make = `make-${matches[1]}`;
    makemodel = `makemodel-${matches[1]}`;
  } else {
    make = `make-${oem}`;
    makemodel = `makemodel-${oem}`;
  }

  const makeModelParams = model ?
    parseReplaceLegacyMakeModelParams(make, model) :
    parseReplaceMakeModelParams(makemodel);

  return {
    'oem-model': true,
    shouldRemoveOemListing: false,
    ...makeModelParams,
    facets: [
      'country',
      'state',
      'make',
      'makeModel',
      'class',
      'fuelType',
      'hullMaterial',
      'stateCity',
      'priceRevisedSince',
      'minMaxPercentilPrices',
      'condition',
      'model',
      'minYear',
      'maxYear',
      'hullShape',
      'enginesConfiguration',
      'activities'
    ].join(','),
    make: undefined
  };
};

export const parseStateParams = (state = '') => {
  if (SEARCH_URL_STATE_PATTERN.test(state)) {
    const matches = SEARCH_URL_STATE_PATTERN.exec(state);
    let twoLetterState = matches[1].split('|');
    twoLetterState = twoLetterState.map( state => { return state.toUpperCase(); });
    return {
      state: twoLetterState[0] //TODO add some validation to this data.
    };
  }
  return {};
};

export const parseReplaceStateParams = (state = '') => {
  if (SEARCH_URL_STATE_PATTERN.test(state)) {
    const matches = SEARCH_URL_STATE_PATTERN.exec(state);
    let twoLetterState = matches[1].split('|');
    twoLetterState = twoLetterState.map( state => { return state.toUpperCase(); });
    return {
      state: twoLetterState[0] //TODO add some validation to this data.
    };
  }
  return {};
};

export const parseReplaceLegacyMakeModelParams = (make = '', model = '', requestType) => {
  if (SEARCH_URL_MAKE_PATTERN.test(make)) {
    const makeMatches = SEARCH_URL_MAKE_PATTERN.exec(make);
    const makeValue = unslugify(makeMatches[1], ' ', '-');
    const modelMatches = model ? SEARCH_URL_MODEL_PATTERN.exec(model) : '';
    let modelValue = [];
    if (modelMatches) {
      if (requestType === 'POST') {
        modelMatches[1] = replace(replace(modelMatches[1], new RegExp('®', 'g'), '%C2%AE'), new RegExp('™', 'g'), '%E2%84%A2');
      }
      modelValue = [unslugify(modelMatches[1], ' ', '-')];
    }
    return {
      multiFacetedMakeModel: requestType === 'POST' ? [[makeValue, modelValue]] : formatMultiArrayForURL([[makeValue, modelValue]])
    };
  }
  return {};
};

export const parseReplaceMakeModelParams = (makeModel = '', requestType) => {
  if (SEARCH_URL_MAKE_MODEL_PATTERN.test(makeModel)) {
    const matches = SEARCH_URL_MAKE_MODEL_PATTERN.exec(makeModel);
    if (requestType === 'POST') {
      matches[1] = replace(replace(matches[1], new RegExp('®', 'g'), '%C2%AE'), new RegExp('™', 'g'), '%E2%84%A2');
    }
    const multiFacetedMakeModel = multiFacetUrlToArray(matches[1], '+');
    return {
      multiFacetedMakeModel: requestType === 'POST' ? multiFacetedMakeModel : formatMultiArrayForURL(multiFacetedMakeModel)
    };
  }
  return {};
};

export const parseLegacyMakeModelParams = (make = '', model = '') => {
  if (SEARCH_URL_MAKE_PATTERN.test(make)) {
    const makeMatches = SEARCH_URL_MAKE_PATTERN.exec(make);
    const makeValue = unslugify(makeMatches[1], ' ', '-');
    const modelMatches = model ? SEARCH_URL_MODEL_PATTERN.exec(model) : '';
    let modelValue = [];
    if (modelMatches) {
      modelValue = [unslugify(modelMatches[1], ' ', '-')];
    }
    return {
      makeModel: {
        [makeValue]: modelValue
      }
    };
  }
  return {};
};

export const parseMakeModelParams = (makeModel) => {
  if (SEARCH_URL_MAKE_MODEL_PATTERN.test(makeModel)) {
    const matches = SEARCH_URL_MAKE_MODEL_PATTERN.exec(makeModel);
    return {
      makeModel: multiFacetUrlToObject(matches[1], true, '+')
    };
  }
  return {};
};

export const parseZipParams = (zip = '') => {
  if (SEARCH_URL_ZIP_PATTERN.test(zip)) {
    const matches = SEARCH_URL_ZIP_PATTERN.exec(zip);
    return {
      zip: matches[1] //TODO add some validation to this data.
    };
  }
  return {};
};

export const parseRadiusParams = (radius = '', searchPage) => {
  if (SEARCH_URL_RADIUS_PATTERN.test(radius)) {
    const matches = SEARCH_URL_RADIUS_PATTERN.exec(radius);
    const radiusVal = matches[1];
    if (radiusVal === 'any') {
      return {
        radius: 4000
      };
    }
    return {
      radius: radiusVal //TODO add some validation to this data.
    };
  }

  return searchPage === ENGINES_ID ? { radius: '200' } : { radius: '25' };
};

export const parseReplaceRadiusParams = (radius = '', searchPage) => {
  if (SEARCH_URL_RADIUS_PATTERN.test(radius)) {
    const matches = SEARCH_URL_RADIUS_PATTERN.exec(radius);
    let radiusVal = matches[1];
    if (radiusVal === 'any') {
      return {
        distance: '4000mi'
      };
    }
    if (radiusVal !== 'exact') {
      return {
        distance: `${radiusVal}mi` //TODO add some validation to this data.
      };
    }
    return { distance: `${radiusVal}` };

  }

  return searchPage === ENGINES_ID ? { distance: '200mi' } : { distance: '25mi' };
};

export const parseYearParams = (year = '') => {
  if (SEARCH_URL_YEAR_PATTERN.test(year)) {
    const matches = SEARCH_URL_YEAR_PATTERN.exec(year);
    const ret = { year: {} };
    if (matches[1]){
      ret.year.min = matches[1];
    }
    if (matches[2]){
      ret.year.max = matches[2];
    }
    return ret;
  }
  return { };
};

export const parseReplaceYearParams = (year = '') => {
  if (SEARCH_URL_YEAR_PATTERN.test(year)) {
    const matches = SEARCH_URL_YEAR_PATTERN.exec(year);
    return {
      year: `${matches[1] || ''}-${matches[2] || ''}`
    };
  }
  return { };
};

export const parseTypeAndClassParams = (types = '', classes = '' ) => {
  if (SEARCH_URL_TYPE_PATTERN.test(types)) {
    let decodedClasses = {};
    const typeMatches = SEARCH_URL_TYPE_PATTERN.exec(types);
    let typeValue = typeMatches[1].toLowerCase();
    if (typeValue.split('+')[0] === 'any') {
      return {multiFacetedBoatTypeClass: {}};
    }
    typeValue.split('+').map( type => {
      decodedClasses = { ...decodedClasses, [type]: [] };
    });
    if (SEARCH_URL_CLASS_PATTERN.test(classes)) {
      const classMatches = SEARCH_URL_CLASS_PATTERN.exec(classes);
      let classValue = classMatches[1];
      classValue = unslugify(classValue, '--', '/');
      classValue = unslugify(classValue, ' ', '+');
      classValue.split('+').map( (boatClass) => {
        boatClass = handleLegacyAllType(handleLegacyType(typeValue),boatClass);
        decodedClasses[getClassCategory(boatClass)].push(boatClass);
      });
    }
    decodedClasses = checkNeedsDefaults(decodedClasses);
    return {multiFacetedBoatTypeClass: decodedClasses};

  }
  return {multiFacetedBoatTypeClass: {}};
};

export const  parseTypeAndClassParamsToArray = (types = '', classes = '', classGroup = '', requestType) => {
  const cleanClassGroup = classGroup.replace('group-', '');
  if (classGroup && includes(keys(GROUPS_CLASSES_MAPPING), cleanClassGroup)) {
    types = 'type-' + GROUPS_CLASSES_MAPPING[cleanClassGroup].type;
    classes = 'class-' + GROUPS_CLASSES_MAPPING[cleanClassGroup].classes;
  }

  let parsedBoatTypes = parseTypeAndClassParams(types,classes);
  let mappedSmallBoats = mapSmallBoatsToAPI(parsedBoatTypes.multiFacetedBoatTypeClass);
  parsedBoatTypes = {...parsedBoatTypes, ...mappedSmallBoats};
  delete parsedBoatTypes.multiFacetedBoatTypeClass.small;
  let multiFacetedBoatTypeClass = {...parsedBoatTypes.multiFacetedBoatTypeClass, ...mappedSmallBoats};
  multiFacetedBoatTypeClass = movePWCToPower(multiFacetedBoatTypeClass);
  multiFacetedBoatTypeClass = mapAllClassForType(multiFacetedBoatTypeClass);
  let boatTypeArray = Object.entries(multiFacetedBoatTypeClass);
  if (boatTypeArray.length) {
    return { multiFacetedBoatTypeClass: requestType === 'POST' ? boatTypeArray : formatMultiArrayForURL(boatTypeArray) };
  }
  return {};
};

export const parseClassGroupParams = (classGroup = '') => {
  if (SEARCH_URL_CLASS_GROUP_PATTERN.test(classGroup)) {
    const matches = SEARCH_URL_CLASS_GROUP_PATTERN.exec(classGroup);
    let classGroupValue = matches[1];
    return {
      group: classGroupValue
    };
  }
  return {};
};

export const parseReplaceClassGroupParams = (classGroup = '') => {
  if (SEARCH_URL_CLASS_GROUP_PATTERN.test(classGroup)) {
    const matches = SEARCH_URL_CLASS_GROUP_PATTERN.exec(classGroup);
    let classGroupValue = matches[1];
    classGroupValue = unslugify(classGroupValue, /[+ ]|--/, '-');
    return {
      group: classGroupValue
    };
  }
  return {};
};

export const parseLengthParams = (length = '') => {
  if (SEARCH_URL_LENGTH_PATTERN.test(length)) {
    const matches = SEARCH_URL_LENGTH_PATTERN.exec(length);
    let value = {min: matches[1]};
    if (matches[2]) {
      value.max = matches[2];
    }
    return {
      length: value,
    };
  }
  return {};
};

export const parseReplaceLengthParams = (length = '') => {
  if (SEARCH_URL_LENGTH_PATTERN.test(length)) {
    const matches = SEARCH_URL_LENGTH_PATTERN.exec(length);
    let value = matches[1];
    if (matches[2]) {
      value += `-${matches[2]}`;
    }
    return {
      length: value,
      uom: 'ft'
    };
  }
  return {};
};

const padDigits = (num, val) =>`${val}`.padStart(num, '0');
export const makePriceRevisionDate = () => {
  let date = new Date(new Date().setDate(new Date().getDate() - 30));
  let human = `${date.getFullYear()}-${padDigits(2, date.getMonth() + 1)}-${padDigits(2, date.getDate())}`;
  return human;
};

export const parsePriceRevisedSince = (priceRevisedSince = '') => {
  const matches = SEARCH_URL_PRICE_REDUCED_PATTERN.exec(priceRevisedSince);
  if (matches) {
    return {
      priceRevisedSince: '-30'
    };
  }
  return {};
};

export const parseReplacePriceRevisedSinceParams = (priceRevisedSince = '') => {
  const matches = SEARCH_URL_PRICE_REDUCED_PATTERN.exec(priceRevisedSince);
  if (matches) {
    return {
      priceRevisedSince: makePriceRevisionDate()
    };
  }

  return {};
};

export const parsePriceParams = (price = '') => {
  if (SEARCH_URL_PRICE_PATTERN.test(price)) {
    const matches = SEARCH_URL_PRICE_PATTERN.exec(price);
    let value = {min: matches[1]};
    if (matches[2]) {
      value.max = matches[2];
    }
    return {
      price: value,
    };
  }
  return {};
};

export const parseReplacePriceParams = (price = '') => {
  if (SEARCH_URL_PRICE_PATTERN.test(price)) {
    const matches = SEARCH_URL_PRICE_PATTERN.exec(price);
    let value = `${matches[1]}-`;
    if (matches[2]) {
      value += `${matches[2]}`;
    }
    return {
      price: value,
    };
  }
  return {};
};

export const parseReplaceByParams = (by = '') => {
  if (SEARCH_URL_BY_PATTERN.test(by)) {
    const matches = SEARCH_URL_BY_PATTERN.exec(by);
    let value = matches[1];
    return {
      sellerType: value,
    };
  }
  return {};
};

export const parseReplaceModelRangeParams = (modelRange = '') => {
  if (SEARCH_URL_MODEL_RANGE_PATTERN.test(modelRange)) {
    const matches = SEARCH_URL_MODEL_RANGE_PATTERN.exec(modelRange);
    return {
      'modelRange': matches[1]
    };
  }
  return {};
};

export const parsePageParams = (page = '', searchPage) => {
  let def = { page: '1', pageSize: searchPage === ENGINES_ID ? `${ENGINES_PAGE_SIZE}` : `${BOATS_PAGE_SIZE}` };
  if (SEARCH_URL_PAGE_PATTERN.test(page)) {
    const matches = SEARCH_URL_PAGE_PATTERN.exec(page);
    return {
      page: matches[1],
      pageSize: searchPage === ENGINES_ID ? `${ENGINES_PAGE_SIZE}` : `${BOATS_PAGE_SIZE}`
    };
  }
  return def;
};

export const parseSortParams = (sort = '') => {
  const matches = SEARCH_URL_SORT_PATTERN.exec(sort);
  if (matches) {
    return { sort: matches[1] };
  }
  return { sort: undefined };
};

export const parseReplaceSortParams = (sort = '') => {
  const matches = SEARCH_URL_SORT_PATTERN.exec(sort);
  if (matches) {
    return { sort: sortTypeMap[matches[1]] || 'length-desc' };
  }
  return { sort: sortTypeMap[getDefaultSort()] };
};

export const parseConditionParams = (condition = '') => {
  if (SEARCH_URL_CONDITION_PATTERN.test(condition)) {
    const matches = SEARCH_URL_CONDITION_PATTERN.exec(condition);
    return {
      condition: matches[1]
    };
  }
  return { condition: 'any' };
};

export const parseReplaceKeywordParams = (keyword = '') => {
  if (SEARCH_URL_KEYWORD_PATTERN.test(keyword)) {
    const matches = SEARCH_URL_KEYWORD_PATTERN.exec(keyword);
    return {
      keyword: matches[1]
    };
  }
  return {keyword: undefined};
};

export const parseReplaceConditionParams = (condition = '') => {
  const matches = SEARCH_URL_CONDITION_PATTERN.exec(condition);
  if (matches) {
    return { condition: matches[1] };
  }
  return {};
};

export const parseOwnerParams = (ownerId = '') => {
  if (SEARCH_URL_OWNER_PATTERN.test(ownerId)) {
    const matches = SEARCH_URL_OWNER_PATTERN.exec(ownerId);
    return {
      ownerId: matches[1]
    };
  }
  return {};
};

export const parseReplaceOwnerParams = (ownerId = '') => {
  if (SEARCH_URL_OWNER_PATTERN.test(ownerId)) {
    const matches = SEARCH_URL_OWNER_PATTERN.exec(ownerId);
    return {
      ownerId: matches[1]
    };
  }
  return {};
};

export const parseEngineTypeParams = (engine = '') => {
  engine = slugify(decodeURI(engine));
  if (SEARCH_URL_ENGINETYPE_PATTERN.test(engine)) {
    const matches = SEARCH_URL_ENGINETYPE_PATTERN.exec(engine);
    matches[1] = engineLegacyTypes[matches[1]] || matches[1];
    if (matches[1] === 'all') {
      return {};
    }
    return { engine: matches[1] };
  }
  return {};
};

export const parseReplaceEngineTypeParams = (engine = '') => {
  const matches = SEARCH_URL_ENGINETYPE_PATTERN.exec(engine);
  if (matches) {
    return { engine: matches[1] };
  }
  return {};
};

export const parseFuelParams = (fuelType = '') => {
  if (SEARCH_URL_FUEL_PATTERN.test(fuelType)) {
    const matches = SEARCH_URL_FUEL_PATTERN.exec(fuelType);
    let fuelTypes = matches[1].toLowerCase().split(',');
    return {
      fuelType: fuelTypes
    };
  }
  return {};
};

export const parseReplaceFuelParams = (fuelType = '') => {
  if (SEARCH_URL_FUEL_PATTERN.test(fuelType)) {
    const matches = SEARCH_URL_FUEL_PATTERN.exec(fuelType);
    let fuelTypeValue = matches[1].toLowerCase();
    forEach(FUEL_TYPE_MAPPING, (value, key) => {
      fuelTypeValue = fuelTypeValue.replace(key, value);
    });
    return {
      fuelType: fuelTypeValue
    };
  }
  return {};
};


export const parseReplaceFuelParamsSingleFaceted = (fuelType = '') => {
  if (SEARCH_URL_FUEL_PATTERN.test(fuelType)) {
    const matches = SEARCH_URL_FUEL_PATTERN.exec(fuelType);
    let fuelTypeValue = matches[1].toLowerCase();
    forEach(FUEL_TYPE_MAPPING, (value, key) => {
      fuelTypeValue = fuelTypeValue.replace(key, value);
    });
    return {
      fuel: fuelTypeValue
    };
  }
  return {};
};

export const parseHullParams = (hullMaterial = '', multiFacetedBoatTypeClass = {}) => {
  if (SEARCH_URL_HULL_PATTERN.test(hullMaterial)) {
    const matches = SEARCH_URL_HULL_PATTERN.exec(hullMaterial);
    let hullMaterialValue = matches[1];
    hullMaterialValue = unslugify(hullMaterialValue, ' ', '+').split(',');
    let returnObject = {
      hullMaterial: hullMaterialValue
    };
    let multiFacetedBoatTypeClassNew;
    let classToAdd = get(HULL_MATERIAL_BOAT_CLASS_MAPPING, hullMaterialValue);
    if (classToAdd) {
      if (multiFacetedBoatTypeClass.small) {
        multiFacetedBoatTypeClassNew = {
          ...multiFacetedBoatTypeClass,
          small: [...multiFacetedBoatTypeClass.small, classToAdd]
        };
      }
      else {
        multiFacetedBoatTypeClassNew = {
          ...multiFacetedBoatTypeClass,
          small: [classToAdd]
        };
      }

      returnObject = {
        multiFacetedBoatTypeClass: multiFacetedBoatTypeClassNew
      };

    }
    return returnObject;
  }
  return {};
};

export const parseReplaceHullParams = (hullMaterial = '') => {
  if (SEARCH_URL_HULL_PATTERN.test(hullMaterial)) {
    const matches = SEARCH_URL_HULL_PATTERN.exec(hullMaterial);
    let hullMaterialValue = matches[1];
    hullMaterialValue = unslugify(hullMaterialValue, /[+ ]|--/, '-');
    return {
      hullMaterial: hullMaterialValue
    };
  }
  return {};
};

export const parseCityParams = (city = '') => {
  if (SEARCH_URL_CITY_PATTERN.test(city)) {
    const matches = SEARCH_URL_CITY_PATTERN.exec(city);
    let cityValue = matches[1].split('+');
    return {
      city: cityValue
    };
  }
  return {};
};

export const parseReplaceCityParams = (city = '') => {
  if (SEARCH_URL_CITY_PATTERN.test(city)) {
    const matches = SEARCH_URL_CITY_PATTERN.exec(city);
    let cityValue = unslugify(matches[1], '-', ' ').split('+');
    return {
      city: cityValue.join(',')
    };
  }
  return {};
};

export const parseModalParams = (modal = '') => {
  if (SEARCH_URL_MODAL_PATTERN.test(modal)) {
    const matches = SEARCH_URL_MODAL_PATTERN.exec(modal);
    let modals = matches[1].split('+');
    return {
      modal: modals
    };
  }
};

export const parseByParams = (by = '') => {
  if (SEARCH_URL_BY_PATTERN.test(by)) {
    const matches = SEARCH_URL_BY_PATTERN.exec(by);
    let match = matches[1];
    return {
      forSale: match
    };
  }
};

export const slugify = (string, joiner = '+') => string.toLowerCase().split(' ').join(joiner);
export const unslugify = (string, splitter = '+', joiner = ' ') => {
  if (typeof string === 'string') {
    return string?.toLowerCase().split(splitter).join(joiner);
  }
  return '';
};

export const hyphenateTrimmed = val => typeof val === 'string' ? hyphenateUrlComponents(val.trim()) : '';

export const hyphenateUrlComponents = (string) => {
  string = string.toLowerCase();
  string = string.split('&').join('$');
  string = string.split('-').join('--');
  string = string.replace(/#|_|\//g,'-');
  string = slugify(string, '-');
  return string;
};

export const unhyphenateUrlComponents = (string) => {
  string = string.toLowerCase();
  string = string.split('--').join('..');
  string = string.split('-').join(' ');
  string = string.split('$').join('&');
  string = unslugify(string, '..', '-');
  return string;
};

export const rewriteLegacySearchResultsUrl = props => {
  let params = get(props, 'match.params', {});
  let urlProps = legacyGetDefaultParams(params);
  let path = generateSearchPath(urlProps);
  return path;
};

export const reduceURLForRedirect = props => {
  let params = get(props, 'match.params', {});
  let lastParam;
  let modified = false;
  forEach(params, (value, key) => {
    if (!INDEXABLE_PARAMS.includes(key)) {
      if (params[key]) {
        params[key] = undefined;
        modified = true;
      }
    }
  });
  let reducedUrl = generateSearchPath({}, getDefaultParams(params), true);
  if (!modified) {
    INDEXABLE_PARAMS.forEach(matchParam => {
      if (params[matchParam]) {
        lastParam = matchParam;
      }
    });
    delete params[lastParam];
    reducedUrl = generateSearchPath({}, getDefaultParams(params, lastParam), true);
  }

  return reducedUrl;

};

export const redirectPartialMakeNameToFullMakeName = (props, fullMakeNameUrlParam) => {
  let params = get(props, 'match.params', {});
  forEach(params, (value, key) => {
    if (!INDEXABLE_PARAMS.includes(key)) {
      if (params[key]) {
        params[key] = undefined;
      }
    }
  });
  set(params, params.oem ? 'oem' : 'make', fullMakeNameUrlParam);
  return generateSearchPath({}, getDefaultParams(params), true);
};

/***** Engines page parsers *****/

export const parseEngineCategoryTypeParams = (type = '') => {
  const matches = ENGINES_SEARCH_URL_TYPE_PATTERN.exec(type);
  if (matches && matches[1] && matches[1].toLowerCase() !== 'all') {
    const value = engineKeyFromValue(matches[1]);
    const category = engineQueryParam(value);
    return { category };
  }
  return {};
};

export const parseEngineCategoryTypeKey = (type = '') => {
  const matches = ENGINES_SEARCH_URL_TYPE_PATTERN.exec(type);
  if (matches && matches[1] && matches[1].toLowerCase() !== 'all') {
    return engineKeyFromValue(matches[1]);
  }
  return '';
};

export const parseHorsepowerParams = (horsepower = '') => {
  if (ENGINES_SEARCH_URL_HORSEPOWER_PATTERN.test(horsepower)) {
    const matches = ENGINES_SEARCH_URL_HORSEPOWER_PATTERN.exec(horsepower);
    return { powerHp: { min: matches[1], max: matches[2] } };
  }
  return {};
};

export const parseEnginePriceParams = (price = '') => {
  if (ENGINES_SEARCH_URL_PRICE_PATTERN.test(price)) {
    const matches = ENGINES_SEARCH_URL_PRICE_PATTERN.exec(price);
    return { price: { min: matches[1], max: matches[2] } };
  }
  return {};
};

export const parseEngineSortParams = (sort = '') => {
  const matches = SEARCH_URL_SORT_PATTERN.exec(sort);
  if (matches) {
    return { sort: matches[1] };
  }
  return { sort: undefined };
};

export const parseEngineReplaceSortParams = (sort = '', zip = '', city = '', state = '') => {
  const matches = SEARCH_URL_SORT_PATTERN.exec(sort);
  if (matches) {
    return { sort: get(ENGINES_SORT_TYPES[matches[1]], 'apiParam', 'year-desc') };
  }
  const response = { sort: ENGINES_SORT_TYPES[getDefaultEnginesSort(zip, city, state)].apiParam };
  return response;
};
/*******************************/

export const parseSearchEnginesParams = (url) => {
  let defaultSearchParams = parseSearchParams(url, 'GET', ENGINES_ID);

  // TODO: Update accordingly as filters require
  // reset unnecessary filters:
  if (defaultSearchParams.fuelType) {
    delete defaultSearchParams.fuelType;
  }

  let powerHp;
  if (defaultSearchParams.powerHp) {
    powerHp = `${defaultSearchParams.powerHp.min}-${defaultSearchParams.powerHp.max || ''}`;
  }

  let price;
  if (defaultSearchParams.price) {
    price = `${defaultSearchParams.price.min}-${defaultSearchParams.price.max || ''}`;
  }

  return {
    ...defaultSearchParams,
    powerHp,
    price
  };
};
/**************************/

const isOnlyZip = ob => !!(ob?.zip && (!ob?.state || !ob?.city));
const isOnlyCity = ob => !!(ob?.city && !ob?.zip && !ob?.state);
const isSameZip = (matchZip, zip) => matchZip === `zip-${zip}`;
const properZipData = zipGeoData => zipGeoData?.zip && zipGeoData.city && zipGeoData.state;

const fullLocationParams = (state, city, zip, original, searchPage) => {
  const stateCode = US_STATES.find(usState => usState.name === state)?.value?.toLowerCase() || '';
  if (!stateCode) {
    return null;
  }
  const normalizedCity = normalizeString(city);
  const newParams = {city: `city-${normalizedCity}`, state: `state-${stateCode}`};
  if (zip) {
    newParams.zip = `zip-${zip}`;
  }
  return getDefaultParams({...original, ...newParams}, searchPage);
};

export const urlFromMissingLocationData = (urlMatchParams, zipGeoData, searchPage) => {
  const {state, city, zip} = zipGeoData;
  if (!state || !city || !zip) {
    return '';
  }
  try {
    /* eslint-disable indent */
    const locationParams = isOnlyCity(urlMatchParams) ? fullLocationParams(state, city, null, urlMatchParams, searchPage) :
                           isOnlyZip(urlMatchParams)  ? fullLocationParams(state, city, zip, urlMatchParams, searchPage) : null;
    return locationParams ? generateSearchPath({}, locationParams, true, searchPage) : '';
  } catch (e) {
    return '';
  }
};

export const getLocationRedirectUrl = (urlMatchParams, zipGeoData, original) => {
  if (!properZipData(zipGeoData)) {
    return '';
  }
  const okZipData = isSameZip(urlMatchParams?.zip, zipGeoData?.zip);
  const onlyZip = isOnlyZip(urlMatchParams);
  const onlyCity = isOnlyCity(urlMatchParams);
  const findNewLocation = (onlyZip && okZipData) || onlyCity;
  if (findNewLocation) {
    const newLocation = urlFromMissingLocationData(urlMatchParams, zipGeoData);
    return newLocation !== original ? newLocation : '';
  }

  return '';
};

const memoMatchParams = () => {
  const match = {};
  return (url) => {
    if (match[url]) {
      return match[url];
    }
    try {
      let {params: matched} = matchPath(url, getSearchPattern());
      match[url] = matched;
    } catch (e) {
      return {};
    }

    return match[url];
  };
};

export const getMatchedParams = memoMatchParams();

export const shouldBypassUrl = (url) => {
  let matched = getMatchedParams(url);
  return isOnlyZip(matched);
};

export const generateReductiveSearchUrl = (url) => {
  const newUrl = url.split('/');
  if (newUrl.pop() === ''){
    newUrl.pop();
  }
  newUrl.push('');
  return newUrl.join('/');
};

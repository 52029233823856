import React, {useCallback, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import DefaultLayout from '../../../layouts/Default';
import './styles.css';
import { ENGINES_ID, PARAGRAPH_TEXT } from '../../../constants/Engines';
import LoadingListingList from '../../../components/LegacyListingList/LoadingListingList';
import LegacyListingList from '../../../components/LegacyListingList';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ResultsHeader from '../ResultsHeader';
import ResultsFooter from '../ResultsFooter';
import { getDefaultParams } from '../../../utils/urlHelpers/listings';
import AdProvider from '../../../components/Ads/AdProvider';
import {generateSearchPath} from '../../../utils/urlHelpers/listings';
import RefineSearchSideBar from '../RefineSearchSideBar';
import {isMobileResolution as isMobileResolutionFn} from '../../../utils/commonHelper';
import MobileSearchFilters from '../MobileSearchFilters';
import {Ad} from '@dmm/react-common-components';
import {engineAds} from './resources/enginesAdsConfig';
import BreadCrumb from '../../../components/BreadCrumb';
import { getSearchResultBreadCrumb } from '../../../utils/breadCrumbsHelper';
import {getEnginesSRPHeader} from '../../../utils/engineTypeHelper';
import { getActiveParams } from '../../../utils/urlHelpers/listings';
import BrandedHeader from '../../../components/BrandedHeader';

export const getOffset = () => {
  const leaderBoard = document.body.querySelector('#div-gpt-mobile-box-1');

  if (leaderBoard) {
    return leaderBoard.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
  }
  return 0;
};

const LeaderBoarScroll = {
  TOP: 1,
  MIDDLE: 0,
  NONE: -1
};

export const leaderBoardPosition = () => {
  if (isMobileResolutionFn()) {
    let windowPos = document.documentElement.scrollTop || window.pageYOffset;
    let offset = getOffset() || 9;
    if (windowPos > offset + 42) {
      return LeaderBoarScroll.TOP;
    } else if (windowPos < offset - 8) {
      return LeaderBoarScroll.MIDDLE;
    }
  }
  return LeaderBoarScroll.NONE;
};

export const resizeEffect = ({ resizeHandler }) =>() => {
  window.addEventListener('resize', resizeHandler);
  resizeHandler();
  return () => {
    window.removeEventListener('resize', resizeHandler);
  };
};

export const scrollEffect = ({ handleLeaderboard }) => () => {
  window.addEventListener('scroll', handleLeaderboard.bind(this));
  handleLeaderboard();
  return () => {
    window.removeEventListener('scroll', handleLeaderboard.bind(this));
  };
};

const initialState = { isMobileResolution: false, stickyLeaderBoard: false };
const reducer = (prevState, updated) => ({
  ...prevState,
  ...updated
});

const getLeaderBoard = () => document.getElementById('div-gpt-mobile-box-1');

const engineDataLayer = {category: 'engine', region: null, container: null};

// TODO: I wish we could remove props and get real properties...
// but ListingList component and its children...
const EnginesMainContent = (props) => {
  const [state, setState] = useReducer(reducer, initialState);
  const [stickyMobileAd, setstickyMobileAd] = useState(() => false);
  const [currentPath, setCurrentPath] = useState(props?.location?.pathname);
  const { isMobileResolution } = state;
  const renderController = useRef({firstPath: currentPath, isFirst: true});

  const resizeHandler = () => {
    setState({ isMobileResolution: isMobileResolutionFn() });
  };

  const onEngineFiltersChanged = (data) => {
    const newPath = generateSearchPath(data, {}, true, ENGINES_ID);
    setCurrentPath(newPath);
  };

  const { isWorking, noAdsParam } = props;
  const showListingList = !isWorking;
  const urlParsedParams = useMemo(() => getDefaultParams(props.match?.params || {}, ENGINES_ID), [props.match]);
  const search = get(props, 'search', {});
  const pos = { page: urlParsedParams.page, pageSize: urlParsedParams.pageSize };

  const {adsConfig, maxDesktopAds, initialMobileCount, initialDesktopCount, adPosition} = engineAds;
  const {
    leaderBoardAlpha,
    /*sideBarTopAd, sideBarAd1, sideBarAd2, */
    nativeDesktopAd,
    nativeMobile,
    sponsoredAd,
    leaderBoardAdhesion,
    mobileLeaderBoardAdhesion
  } = adPosition;
  const isFirstPage = pos.page === '1';
  const dealerId = get(urlParsedParams, 'ownerId');
  const noActiveFilters = isEmpty(getActiveParams(urlParsedParams, ENGINES_ID));

  // On boats SRP we have div-gpt-mobile-leaderboard-custom, a sticky ad.
  // here we have this functionality through div-gpt-mobile-box-1
  const handleLeaderboard = useCallback(() => {
    const pos = leaderBoardPosition();
    if (pos === LeaderBoarScroll.TOP) {
      if (!stickyMobileAd) {
        setstickyMobileAd(() => true);
      }
    } else if (pos === LeaderBoarScroll.MIDDLE) {
      if (stickyMobileAd) {
        setstickyMobileAd(() => false);
      }
    }
  }, [stickyMobileAd]);

  const targeting = engineAds.getTargeting(urlParsedParams);

  const makeModel = get(props, 'makeModel', '');
  const partyDetails = get(props, 'partyDetails', {});
  const breadCrumbs = getSearchResultBreadCrumb(urlParsedParams, makeModel, partyDetails, {}, ENGINES_ID);
  const SRPHeader = useMemo(() => getEnginesSRPHeader(urlParsedParams, partyDetails), [urlParsedParams, partyDetails]);
  const pageType = dealerId ? 'DealerGallery' : ENGINES_ID;

  useEffect(() => {
    // bypass first render
    if (renderController.current.firstPath === currentPath) {
      renderController.current.firstPath = null;
    } else {
      props?.history?.push(currentPath);
    }
  }, [currentPath]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(resizeEffect({ resizeHandler }), []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(scrollEffect({ handleLeaderboard }), [stickyMobileAd]); // eslint-disable-line react-hooks/exhaustive-deps

  sponsoredAd.classes = stickyMobileAd ? 'bp2max ad-320x80 sticky-leaderboard' : 'bp2max ad-320x80';

  const [shouldLoadAd, setShouldLoadAd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 500) {
        setShouldLoadAd(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <DefaultLayout
      {...props}
      pageType={pageType}
      getLeaderBoard={getLeaderBoard}
      urlParsedParams={urlParsedParams}
      id={ENGINES_ID}
    >
      <AdProvider
        url={get(props, 'locastion.pathname', '')}
        isWorking={props.isWorking}
        targeting={targeting}
        adsConfig={adsConfig}
        noAdsParam={noAdsParam}
      >
        <div className="content-head">
          <BreadCrumb items={breadCrumbs.breadCrumbs} />
          {isFirstPage && dealerId ? (
            <BrandedHeader partyDetails={partyDetails} brandedHeaderH1={SRPHeader} searchPage={ENGINES_ID} />
          ) : (
            <h1>{SRPHeader}</h1>
          )}
          {isFirstPage && noActiveFilters && <p className="head-paragraph">{PARAGRAPH_TEXT}</p>}
        </div>
        <div id="engines-content" className="engines-container">
          {!isMobileResolution && (
            <div className="left-content">
              <RefineSearchSideBar
                {...props}
                params={urlParsedParams}
                loading={isWorking}
                searchPage={ENGINES_ID}
                onDataChange={onEngineFiltersChanged}
              />
            </div>
          )}

          <div className="right-content">
            <div className="search-results">
              <ResultsHeader {...props} isMobile={isMobileResolution} searchPage={ENGINES_ID} />
              {showListingList ? (
                <LegacyListingList
                  {...props}
                  listings={search.records || []}
                  isEngine={true}
                  dealerId={dealerId}
                  pos={pos}
                  dataLayer={engineDataLayer}
                  leaderBoardAlphaParams={leaderBoardAlpha}
                  sponsoredAdsParams={sponsoredAd}
                  maxDesktopAds={maxDesktopAds}
                  nativeDesktopAd={nativeDesktopAd}
                  nativeMobileAd={nativeMobile}
                  mobileCount={initialMobileCount}
                  desktopCount={initialDesktopCount}
                  noAdsParam={true}
                />
              ) : (
                <LoadingListingList number={28} />
              )}
              <ResultsFooter {...props} searchPage={ENGINES_ID}/>
            </div>
            {isMobileResolution && (
              <MobileSearchFilters
                {...props}
                params={urlParsedParams}
                searchPage={ENGINES_ID}
                onDataChange={onEngineFiltersChanged}
              />
            )}
          </div>
          {/*<div className="far-right-content">
            <Ad {...sideBarTopAd} />
            <div id="right-ad" className="sticky-ads">
              <Ad {...sideBarAd2} />
              <Ad {...sideBarAd1} />
            </div>
          </div>*/}
          <div className="adhesion-ads">
            { shouldLoadAd && <Ad {...(!isMobileResolution ? leaderBoardAdhesion : mobileLeaderBoardAdhesion)} />}
          </div>
        </div>
      </AdProvider>
    </DefaultLayout>
  );
};

export default EnginesMainContent;

import React  from 'react';
import { sanitizeHtml } from '@dmm/lib-common/lib/sanitizeHtml';
import './styles.css';

const PopularBoats = ({ posts }) => {
  return (
    <section id="home-popular-boats" data-e2e="home-popular-boats">
      <div className="row">
        <div className="col">
          <h2 data-e2e="popular-boats">Popular Boats</h2>
          {
            posts.map(({ id, title, content }, index) => {
              return (
                <div key={id} className="tab" data-e2e={`tab${index}`}>
                  <input type="checkbox" id={`check${index}`} data-e2e={`check${index}`}/>
                  <label className="tab-label" htmlFor={`check${index}`} data-e2e={`tab-label${index}`}>{title}</label>
                  <div className="tab-content" data-e2e={`tab-content${index}`} dangerouslySetInnerHTML={{ __html: sanitizeHtml(content.body) }} />
                </div>
              );
            })
          }
        </div>
      </div>
    </section>
  );
};

export default PopularBoats;

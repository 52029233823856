import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import React from 'react';
import useBranchIoMetaTag from '../../utils/hooks/useBranchIoMetaTag';
import './styles.css';


const LikedBoat = ({ discount, showText, liked, onClickLike }) => {
  const { BranchIoMetaTagComponent } = useBranchIoMetaTag();
  const hiddenText = liked ? 'Saved' : 'Save';
  return (
    <>
      <BranchIoMetaTagComponent/>
      <Action
        data-testid="liked-boat-btn"
        data-e2e="heart-icon-btn"
        className={classnames('heart', { 'liked': liked }, { 'discount': discount} )}
        onClick={onClickLike}
        label={showText ? hiddenText : ''}
        aria-label={liked ? 'Unlike Boat' : 'Like Boat'}
      />
    </>
  );
};

export default LikedBoat;

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { formatNumber } from '@dmm/lib-common/lib/formatting';
import { ToolSetTextInput } from '../../ToolSet';
import {compareNormalizations, typeAheadMatchesValue} from '../../../utils/typeAheadHelper';
import {nil} from '../../../utils/runOnceHelper';

export default class FilterTypeAhead extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      typeAheadText: '',
      showAll: props.showAll || false
    };

    this.handleTypeAhead = this.handleTypeAhead.bind(this);
  }

  handleTypeAhead(value){
    this.setState({ typeAheadText: value });
  }
  handleShowAll(){
    this.setState({ showAll: true });
  }
  handleSelection(){
    this.setState({ typeAheadText: '' });
  }

  onClickFilterMore = (e) => {
    this.handleShowAll();
    e.preventDefault();
  }

  render(){
    const { items = [], selectedItems = [], render, id, name, placeholder, max, url } = this.props;
    const { typeAheadText, showAll } = this.state;
    const topItems = items
      .filter((item, index) => index < max && !typeAheadText);
    const selectedItemsNotTopItems = items
      .filter(item => !typeAheadText
        // to be 100% sure that normalized and sluggified values are correct
        // we should perform data validation when we input data on any persistence layer.
        && compareNormalizations(selectedItems, item.value)
        && !find(topItems, { value: item.value }));
    const filteredItems = items
      .filter(item => typeAheadText
        && typeAheadMatchesValue(typeAheadText, item.value))
      .slice(0, max);
    const otherItems = sortBy(items
      .filter(item =>
        typeAheadText ? !typeAheadMatchesValue(typeAheadText, item.value) : true)
      .filter((item, index) => index >= max), 'value');

    return <div className="filter-typeahead" data-e2e="srp-seach-filter-make">
      <ToolSetTextInput id={id} name={id} icon={true} placeholder={placeholder} value={typeAheadText} onChange={this.handleTypeAhead} />
      { !!selectedItemsNotTopItems.length && render(selectedItemsNotTopItems, (value) => this.handleSelection(value)) }
      { !!filteredItems.length && render(filteredItems, (value) => this.handleSelection(value)) }
      { !!topItems.length && render(topItems, (value) => this.handleSelection(value)) }
      {
        <div className={classnames('show-more', { show: showAll })}>
          {
            showAll && render(otherItems, nil, false)
          }
        </div>
      }
      <div className="filter-more">{ !!otherItems.length && !showAll &&
          <a href={url} onClick={this.onClickFilterMore}>{formatNumber(otherItems.length, 'en-US')} more {name}...</a>
      }</div>
    </div>;
  }
}

import { Action } from '@dmm/lib-react-ui-components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGenericEvent } from '../../../store/actions/dataLayer';
import { isHomepageDesktop, isMobileResolution } from '../../../utils/commonHelper';
import './styles.css';

const CDN_URL = process.env.REACT_APP_CDN_URL;

const trackingDataAttr = (action, label ,custom) => {
  return {
    'data-action': action,
    'data-label': label,
    'data-custom': 'clickLocation,' + custom
  };
};

const FSBOBanner = ({ hasImage }) => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileResolution());
  }, []);

  const handleClick = (e) => {
    if (e?.target?.dataset?.action && e?.target?.dataset?.label && e?.target?.dataset?.custom) {
      setGenericEvent('navigation - body', e.target.dataset.action, e.target.dataset.label, '', e.target.dataset.custom)(dispatch);
    }
  };

  return (
    <>
      {
        hasImage ?
          <div className="fsbo-banner-wrapper fsbo-banner-wrapper--last">
            <div className="fsbo-banner">
              <div className="fsbo-banner__content">
                <h2 className="fsbo-banner__title">Sell Your Boat Fast!</h2>
                <p className="fsbo-banner__text">We want you to have options selling your boat. Listing your boat is easy, or get a cash offer!</p>
                <div className="fsbo-banner__buttons" onClick={ handleClick }>
                  <a id="listYourBoatSections" className="fsbo-banner__btn" href="/sell/" {  ...trackingDataAttr('sell your boat', 'sell your boat', 'internal banner') }>Sell Your Boat</a>
                  <div className="fsbo-banner__spacer"></div>
                </div>
              </div>
              <div className="fsbo-banner__img-box">
                <img className="fsbo-banner__img"
                  alt="Sell your boat"
                  src={`${CDN_URL}/img/BT_HP_CTA-banner.jpg`}
                  fetchpriority={!isMobile && 'high'}/>
              </div>
            </div>
          </div> :
          <div className="sell-your-boat" data-e2e="sell-your-boat" onClick={ handleClick }>
            <h2 data-e2e="sell-your-boat-header">Sell Your Boat Fast!</h2>
            <p>We want you to have options selling your boat. Listing your boat is easy, or get a cash offer!</p>
            <Action as="a" id="listYourBoat"  data-e2e="listYourBoat" size={isHomepageDesktop() ? 'small' : 'large'} variant="secondary" href="/sell/" { ...trackingDataAttr('sell your boat', 'sell your boat', 'below search cta') } label="Sell Your Boat" />
          </div>
      }
    </>
  );

};

export default FSBOBanner;

import React, {PureComponent} from 'react';

class LoadingMiniListing extends PureComponent {

  render() {
    return (
      <li>
        <div className="boat-image">
          <a>
            <div className="preloader"></div>
          </a>
        </div>
        <a>
          <div className="information">
            <div className="make-model">&nbsp;</div>
            <div className="price">&nbsp;</div>
            <div className="location">&nbsp;</div>
            <div className="seller-name">&nbsp;</div>
          </div>
        </a>
      </li>
    );
  }
}

export default LoadingMiniListing;

import React from 'react';
import { CDN_URL } from '../../../../constants/BoatLoans/index';
import './styles.css';
import { ApplyNowButton } from '../ApplyNowButton';

export const BoatLoanExplanationSection = () => {
  return (
    <div className="boat-loan-expl-container">
      <div className="page-header-container">
        <h2 className="boat-loans-page-header">How Boat Loans Work</h2>
        <p className="boat-loans-subtitle">Understand the ins and outs of boat loans before you apply</p>
      </div>

      <div className="explanation-body-container">
        <div className="image-container">
          <img src={`${CDN_URL}/boat-riders.webp`} alt="Wakeboat for how boat loans work" className="boat-riders-img" />
        </div>
        <p className="explanation-text">If you’re new to the boat financing process, you may wonder how boat loans work.
              There are a few important things you should know. A boat loan is similar to an auto loan but for a longer period of time.
              Boat loans typically have 10 to 20 year terms. This is the length of the loan and the time period you have to pay off the loan.</p>

        <p className="explanation-text">The boat loan term is determined by factors like your credit score, the boat´s age,
              and the cost of the vessel. The more expensive the boat, the longer the loan term, making monthly payments more manageable.
              For example, a $1 million yacht may have a 20-year loan term and a $50,000 fishing boat may only have a 10-year loan term.
              It’s important to choose a boat loan term that works best for your budget and your goals. Additionally, consider any fees or
              charges associated with your loan, such as origination fees, closing costs, and pre-payment penalties.</p>

        <p className="explanation-text">If you’re a well qualified buyer with a good credit score, you may have some say over your loan term.
              If you can manage higher monthly payments and want to pay the loan off as quickly as possible to avoid extra interest,
              then you can choose a shorter term. If you want your monthly payments as low as possible, you may want to choose the longest term available.</p>

        <p className="explanation-text">We make it easy to get <ApplyNowButton text="pre-qualified" type="link" utmCampaign="btboatloanspage" utmContent="btboatloanspage"/> and <a href="/boat-loans/calculator/" target="_blank">calculate your monthly payments</a> to
            determine your <a href="/boat-loans/calculator/" target="_blank">budget</a> and preferred terms. When you’re ready to <ApplyNowButton text="apply" type="link" utmCampaign="btboatloanspage" utmContent="btboatloanspage"/>,
            we’ll make it easy to provide your information to secure a boat loan. Just remember,
            boat loans aren’t hard to get if you pay your bills on time, have consistent income, can afford a boat, and have a good credit score.</p>

        <p className="explanation-text">In addition to loan terms, you should also know about boat loans rates. This is the interest rate, you,
            the borrower, will be charged to borrow money from the lender to purchase the boat. You’re agreeing to pay back the loan amount
            plus the agreed upon interest during the loan term. Boat loan interest rates can vary greatly, so it’s important to compare rates
            from multiple lenders in order to find the best deal. We make it easy by comparing over 15 marine lenders to get you the best rate
            and terms. You should also consider the size of your down payment, as this can affect the overall cost of your loan and your monthly payments.
            Use our <a href="/boat-loans/calculator/" target="_blank">boat loans calculator</a> to see how your down payment will impact your monthly payments.</p>

        <p className="explanation-text">With boat loans, the boat serves as collateral for the lender, in the unlikely event that you don’t pay back the loan,
            and nothing can be worked out. Boat loan rates vary by market conditions, the age and price of the boat, as well as the borrower’s credit score,
            and overall financial profile.</p>

        <p className="explanation-text">When you’re ready to <ApplyNowButton text="apply" type="link" utmCampaign="btboatloanspage" utmContent="btboatloanspage"/> for a boat loan, there are a few requirements you need to meet. Lenders will typically require proof of income and good credit,
            as well as documentation about the boat you’re purchasing, such as its make and model, year, and value. <ApplyNowButton text="Pre-qualify" type="link" utmCampaign="btboatloanspage" utmContent="btboatloanspage"/> to get confidence in your estimated monthly payments and in your loan approval.</p>
      </div>

    </div>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import {
  GetPreQualifiedInlineForm,
} from '../../../../../components/GetPreQualified';

import { BOAT_LEAD_SERVICES_TYPE } from '../../../../../constants/BoatLoans';

import './Hero.styles.css';

export const Hero = ({ title, text, price, formTitle, leadPage, trackingEvent, backgroundImageUrl, isLeadSubmitted, dataTestId = 'hero-component' }) => {
  return (
    <div className="extended-services-hero-container" data-testid={dataTestId} style={{ backgroundImage: `url(${backgroundImageUrl})` }}>

      <div className="hero-sub-container">
        <PerksSection title={title} text={text} price={price}/>
        <GetPreQualifiedInlineForm
          title={formTitle}
          leadPage={leadPage}
          leadType={BOAT_LEAD_SERVICES_TYPE}
          isLeadSubmitted={isLeadSubmitted}
          trackingEvent={trackingEvent}
          buttonText="Get Protected"/>
      </div>
    </div>
  );
};

Hero.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  title: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  leadPage: PropTypes.string.isRequired,
  trackingEvent: PropTypes.object.isRequired,
  isLeadSubmitted: PropTypes.bool
};

export const PerksSection = ({ title, text, price }) => {
  return (
    <div className="hero-text-container" data-testid="hero-perks">
      <h1 className="hero-title">{title}</h1>
      <div className="hero-subtitle">{text} <span className="plan-price">{price}</span></div>
      <div className="hero-perks">Call us today to get your free quote<br/><span className="phone">(866) 631-3105</span></div>
    </div>
  );
};

PerksSection.propTypes = {
  title: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
};

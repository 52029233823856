import React from 'react';
import { FinancingContentSectionOne, FinancingContentSectionTwo } from './FinancingContentSections';
import { CDN_URL } from '../../../../../constants/BoatLoans';
import { FINANCING_CONTENT } from './DesktopTabView';
import '../styles.css';

export const MobileAccordion = () => {
  return (
    <div className="financing-mobile-view">

      <section className="bf-accordion">
        <input type="checkbox" name="collapse" id="handle1" />
        <div className="handle">
          <label htmlFor="handle1" className="handle1">
            {FINANCING_CONTENT[0]}
            <img src={`${CDN_URL}/up-arrow.png`} alt="arrow up" className="arrow"/>
          </label>
        </div>
        <div className="mob-accordion-content mob-finance-section-1">
          <FinancingContentSectionOne />
          <img src={`${CDN_URL}/iphone-credit-score.webp`} alt="mobile phone" className="iphone-credit-score" />
        </div>
      </section>

      <section className="bf-accordion">
        <input type="checkbox" name="collapse2" id="handle2" />
        <div className="handle last-option">
          <label htmlFor="handle2" className="handle2">
            {FINANCING_CONTENT[1]}
            <img src={`${CDN_URL}/up-arrow.png`} alt="arrow up" className="arrow" />
          </label>
        </div>
        <div className="mob-accordion-content">
          <FinancingContentSectionTwo />
        </div>
      </section>

    </div>
  );
};

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import DefaultLayout from '../../../../layouts/Default';
import {
  SERVICES_URL,
  WARRANTY_URL,
  WARRANTY_SERVICE,
} from '../../../../constants/services';

import BreadCrumb from '../../../../components/BreadCrumb';
import ServiceChildTitle from '../../../../components/ServiceChildTitle';
import RichSnippet from '../../../../components/RichSnippet';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import { setExternalLinkImpression } from '../../../../store/actions/dataLayer';
import faq from './faq.json';
import '../styles.css';

class Warranty extends PureComponent {
  componentDidMount() {
    this.props.setExternalLinkImpression(WARRANTY_SERVICE, 'dfp', 'logo');
    this.props.setExternalLinkImpression(WARRANTY_SERVICE, 'dfp', 'bottom cta');
  }

  renderAccordionTitle (text) {
    return (
      <AccordionItemHeading>
        <AccordionItemButton>
          <h2 className="accordion__title">
            {text}
          </h2>
        </AccordionItemButton>
      </AccordionItemHeading>
    );
  }

  render() {
    return (
      <>
        <RichSnippet schema={getFAQSchema(faq)} />
        <DefaultLayout {...this.props} pageType="WarrantyServices">
          <div className="service-page">
            <ServiceChildTitle title="Boat Warranty" />
            <div className="service-content">
              <BreadCrumb items={[{
                title: 'Home',
                link: '/'
              }, {
                title: 'Services',
                link: SERVICES_URL
              }, {
                title: 'Boat Warranty',
                link: WARRANTY_URL
              }]} />
              <div className="main-section">
                <div className="service-info">
                  <h1>Boat Warranty</h1>
                  <p>
                  Extended Marine Service Contracts (commonly known as “Extended Warranties”) help boat owners eliminate excessive,
                  out-of-pocket expenses from unforeseen mechanical breakdowns.
                  Boat Trader can help you access affordable coverage. Get a quote now and get yourself protected today.
                  </p>
                </div>
              </div>
              <Accordion
                allowMultipleExpanded
                allowZeroExpanded
                preExpanded={['warranty-types-id']}
              >
                <AccordionItem uuid="warranty-types-id">
                  {this.renderAccordionTitle('Boat Manufacturer Warranties')}
                  <AccordionItemPanel>
                    <p>
                      Manufacturer warranties may or may not be transferable, in some cases are transferable for a fee,
                      and in some other cases will be partially transferable. The major portions of a boat warranty usually can be broken down into the following categories:
                    </p>
                    <ul>
                      <li>
                      Bow to Stern Warranty
                      </li>
                      <li>
                      Structural Warranty
                      </li>
                      <li>
                      Hull Warranty
                      </li>
                      <li>
                      Blister and/or Gel Coat Warranty
                      </li>
                    </ul>
                    <p>
                    A bow to stern warranty covers the entire boat and all its pieces-parts, aside from the engine. Many
                    builders don’t include this in their warranty, some do for a single year, and some builders offer it
                    for a three-, four-, or even five-year period. Bow to stern warranties that last for years at a time
                    should be considered a valuable perk, though they’re not usually transferrable if the boat is
                    re-sold.
                    </p>
                    <p>
                    Structural warranties are usually limited to the major components of the boat: its hull, deck,
                    bulkheads, stringers, and sometimes other major parts. Again, these vary from manufacturer to
                    manufacturer in duration and in how comprehensive the coverage is. Many builders, however, offer
                    structural warranties for a significant period of time that is often transferrable.
                    </p>
                    <p>
                    Hull warranties cover just that – the boat’s hull. Catastrophic hull failure is a rare event with
                    modern boats, and this is where you’ll sometimes see extremely long or even lifetime
                    warranties apply.
                    </p>
                    <p>
                    Blister and gel coat warranties usually apply specifically to blisters, which form when water gets
                    between the gel coat and fiberglass and causes a bubble-like appendage to form. At one time this was
                    a major issue, but with modern boat construction techniques, these days it’s actually very rare to
                    see a boat develop serious blistering issues. Still, it’s nice when a manufacturer has enough
                    confidence in their own craftsmanship to warrant against blisters forming.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('Boat Extended Service Contracts (Extended Warranty)')}
                  <AccordionItemPanel>
                    <p>
                    Boat or yacht extended warranties are technically extended service contracts.
                    Since people commonly use the word “warranty”, they’re often marketed with that terminology.
                    Warranties are a guarantee made by a manufacturer and cannot be extended or purchased.
                    They must be included with the purchase of a product.
                    Companies offering service contracts, on the other hand, are third party administrators.
                    These companies offer mechanical failure contracts that will pay for the cost of parts and labor if you have a mechanical failure.
                    It is important to understand that an extended service contract is not insurance.
                    It will not pay for damage caused by an accident, weather, or operator error.
                    The coverage of your service contract really depends on the company you purchase your service contract through.
                    It is important to understand that not all the coverage is created equal.
                    Every contract has coverage limits and exclusions and it is important to understand what those are prior to purchasing.
                    Extended Service Contracts can be purchased to protect you after your manufacturer warranty expires
                    or for those customers purchasing a used boat where no warranty exists.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('What to look for in a Service Contract')}
                  <AccordionItemPanel>
                    <p>
                    A service contract can save a boat owner thousands of dollars so there is definitely value there.
                    It is important to find the right coverage and company, so you are maximizing the value.
                    Here are some recommendations on finding a quality service contract.
                    </p>
                    <ol>
                      <li>
                        <strong>Read the coverage</strong>. Before purchasing a contract,
                        always read the coverage book or contract to make sure it has comprehensive protection.
                        Not all contracts are the same and each company has differences in the things they include and exclude.
                      </li>
                      <li>
                        <strong>Make sure the contract is backed by insurance.</strong> It’s not unheard of for extended
                        service contract companies to go out of business. Make sure the coverage is backed by an insurance company.
                        If the administrator of the contract goes out of business, the insurance company will pay for your claims.
                      </li>
                      <li>
                        <strong>Check the track record.</strong> Since there are a lot of companies that will go in and out of this business,
                        it is important to find a company that has experience dealing with marine claims.
                        Make sure the company has been in the marine business for an extended period of time.
                      </li>
                    </ol>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('The Bottom Line on Extended Service Contracts')}
                  <AccordionItemPanel>
                    <p>
                    Buying a boat is a big decision and we are all familiar with the concerns of dealing with constant repairs that take away from the joy of boat ownership.
                    Extended service contracts can remove that fear and enhance your ownership experience.
                    It is important to weigh the cost versus the benefits and make sure you are clear about the coverage, so you don’t have any surprises at the repair facility.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  {this.renderAccordionTitle('FAQs')}
                  <AccordionItemPanel>
                    {
                      faq.map((faqItem, index) => (
                        <>
                          <h3 key={`question-${index}`} className="question">{faqItem.question}</h3>
                          <p key={`answer-${index}`}>{faqItem.answer}</p>
                        </>
                      ))
                    }
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </DefaultLayout>
      </>
    );
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  setExternalLinkImpression
}, dispatch))(Warranty);

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useSeoLink from '../../utils/hooks/useSeoLink/useSeoLink';

const Link = (props) => {
  const { href, to } = props;
  const url = href || to;
  const rel = useSeoLink(url);

  if (to) {
    return (
      <RouterLink rel={rel} {...props}>
        {props.children}
      </RouterLink>
    );
  }

  return (
    <a rel={rel} {...props}>
      {props.children}
    </a>
  );
};

export default Link;

import React from 'react';
import './styles.css';

const LocationBasedHeader = (props) => {
  return (
    <header className="location-based-header" data-e2e="location-based-header">
      <h2 data-e2e="baots-near-you-header">{props.children} <small>Based on your location</small></h2>
    </header>
  );
};

export default LocationBasedHeader;

import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '@dmm/lib-react-ui-components';
import RichSnippet from '../../components/RichSnippet';
import DefaultLayout from '../../layouts/Default';
import { getBreakpoint } from '../../utils/commonHelper';
import { BOAT_LOANS_URL, CDN_URL, HERO_BACKGROUND_V2 } from '../../constants/BoatLoans';
import { Hero } from './components/Hero/';
import { HowToApply } from './components/HowToApply/';
import { TrustPilotReviews } from './components/TrustPilotReviews/';
import { BoatLoansTypes } from './components/BoatLoansTypes';
import { FromBoatTraderFinancing } from './components/FromBoatTraderFinancing';
import { GuideToolsSection } from './components/GuideToolsSection';
import { FrequentlyQuestionsSection } from './components/FrequentlyQuestionsSection';
import { BoatFinancingSection } from './components/BoatFinancingSection';
import { BoatLoanExplanationSection } from './components/BoatLoanExplanationSection';
import { getFAQSchema } from '../../utils/metaContentHelper';
import { faq } from './components/FrequentlyQuestionsSection/faqSchema';
import './styles.css';

const BoatLoansPageV2 = (props) => {
  const backgroundImageUrl = `${ CDN_URL }${ HERO_BACKGROUND_V2 }`;
  const [isMobile, setIsMobile] = useState(getBreakpoint() === 'mobile');
  const handleResize = () => {
    setIsMobile(getBreakpoint() === 'mobile');
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <RichSnippet schema={getFAQSchema(faq)} />
      <DefaultLayout {...props} pageType="BoatLoansV2" hideOverflow>
        <div className="breadcrumb-container">
          <Breadcrumb items={[{
            text: 'Home',
            url: '/'
          }, {
            text: 'Boat Loans',
            url: BOAT_LOANS_URL,
          }]} />
        </div>

        <div className="main-section">
          <div className="boatloans-info-v2">
            <Hero backgroundImageUrl={backgroundImageUrl} />
            <HowToApply isMobile={isMobile || false} />
            <TrustPilotReviews />
            <BoatFinancingSection />
            <BoatLoanExplanationSection />
            <BoatLoansTypes />
            <FromBoatTraderFinancing isMobile={isMobile}/>
            <GuideToolsSection />
            <FrequentlyQuestionsSection />
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default BoatLoansPageV2;



export const getFriendlyFeatureName = (key) => {
  const prettifyFeatures = {
    'customContactInformation': 'Contact Information',
    'electrical-equipment': 'Electrical Equipment',
    'inverter': 'Inverter',
    'shore-power-inlet': 'Shore Power Inlet',
    'generator': 'Generator',
    'electronics': 'Electronics',
    'cockpit-speakers': 'Cockpit Speakers',
    'depthsounder': 'Depthsounder',
    'computer': 'Computer',
    'radio': 'Radio',
    'log-speedometer': 'Log-Speedometer',
    'navigation-center': 'Navigation Center',
    'vcr': 'VCR',
    'vhf': 'VHF',
    'autopilot': 'Autopilot',
    'wind-speed-and-direction': 'Wind Speed and Direction',
    'compass': 'Compass',
    'gps': 'GPS',
    'radar-detector': 'Radar Detector',
    'cd-player': 'CD Player',
    'radar': 'Radar',
    'repeater': 'Repeater(s)',
    'tv-set': 'TV Set',
    'dvd-player': 'DVD Player',
    'plotter': 'Plotter',
    'covers': 'Covers',
    'cockpit-cover': 'Cockpit Cover',
    'spray-hood': 'Spray Hood',
    'lazy-bag': 'Lazy Bag',
    'lazyjacks': 'Lazyjacks',
    'mainsail-cover': 'Mainsail Cover',
    'genoa-cover': 'Genoa Cover',
    'bimini-top': 'Bimini Top',
    'additional-equipment': 'Additional Equipment',
    'launching-trailer': 'Launching Trailer',
    'beaching-legs': 'Beaching Legs',
    'road-trailer': 'Road Trailer',
    'outside-equipment-extras': 'Outside Equipment/Extras',
    'teak-cockpit': 'Teak Cockpit',
    'cockpit-shower': 'Cockpit Shower',
    'gangway': 'Gangway',
    'cockpit-table': 'Cockpit Table',
    'teak-sidedecks': 'Teak Sidedecks',
    'tender': 'Tender',
    'davits': 'Davit(s)',
    'liferaft': 'Liferaft',
    'swimming-ladder': 'Swimming Ladder',
    'hydraulic-gangway': 'Hydraulic Gangway',
    'wind-generator': 'Wind Generator',
    'cockpit-cushions': 'Cockpit Cushions',
    'radar-reflector': 'Radar Reflector',
    'solar-panel': 'Solar Panel',
    'outboard-engine-brackets': 'Outboard Engine Brackets',
    'inside-equipment': 'Inside Equipment',
    'battery-charger': 'Battery Charger',
    'washing-machine': 'Washing Machine',
    'oven': 'Oven',
    'manual-bilge-pump': 'Manual Bilge Pump',
    'electric-bilge-pump': 'Electric Bilge Pump',
    'air-conditioning': 'Air Conditioning',
    'sea-water-pump': 'Sea Water Pump',
    'dishwasher': 'Dishwasher',
    'air-compressor': 'Air Compressor',
    'electric-head': 'Electric Head',
    'refrigerator': 'Refrigerator',
    'stern-thruster': 'Stern Thruster',
    'deep-freezer': 'Deep Freezer',
    'hot-water': 'Hot Water',
    'microwave-oven': 'Microwave Oven',
    'fresh-water-maker': 'Fresh Water Maker',
    'marine-head': 'Marine Head',
    'bow-thruster': 'Bow Thruster',
    'heating': 'Heating',
    'chemical-head': 'Chemical Head',
    'rigging': 'Rigging',
    'electric-winch': 'Electric Winch',
    'hydraulic-winch': 'Hydraulic Winch',
    'spinnaker-pole': 'Spinnaker Pole',
    'steering-wheel': 'Steering Wheel',
    'tiller': 'Tiller',
    'sails': 'Sails',
    'asymmetric-spinnaker': 'Asymmetric Spinnaker',
    'battened-mainsail': 'Battened Mainsail',
    'fully-battened-mainsail': 'Fully Battened Mainsail',
    'furling-genoa': 'Furling Genoa',
    'furling-mainsail': 'Furling Mainsail',
    'gennaker-cruising-spinnaker': 'Gennaker Cruising Spinnaker',
    'genoa': 'Genoa',
    'spinnaker': 'Spinnaker',
    'storm-jib': 'Storm-jib',
    'trisail': 'Trisail',
    'seakeeper': 'Seakeeper',
    'ais': 'AIS',
    'camper-cover': 'Camper Cover',
    'cinema': 'Cinema',
    'crane': 'Crane',
    'dance-floor': 'Dance Floor',
    'elevator': 'Elevator',
    'fin-stabilizer': 'Fin Stabilizer',
    'flat-screen-tv': 'Flat Screen TV',
    'garage': 'Garage',
    'gym': 'Gym',
    'hard-top': 'Hard Top',
    'heli-pad': 'Helipad',
    'hydraulic-bathing-platform': 'Hydraulic Bathing Platform',
    'hydraulic-swim-step': 'Hydraulic Swim Step',
    'gyroscopic-stabilizer': 'Gyroscopic Stabilizer',
    'jacuzzi': 'Jacuzzi',
    'office': 'Office',
    'outriggers': 'Outriggers',
    'pool': 'Pool',
    'power-poles': 'Power Poles',
    'pwc': 'PWC',
    'sauna': 'Sauna',
    'scuba-equipment': 'Scuba Equipment',
    'surf-system': 'Surf System',
    'touch-&-go': 'Touch & Go',
    'touch-screen': 'Touch Screen',
    'underwater-lights': 'Underwater Lights',
    'walk-around': 'Walk Around',
    'water-toys': 'Water Toys',
    'wheelchair-friendly': 'Wheelchair Friendly',
    'wi-fi': 'Wi-Fi',
    'wine-cellar': 'Wine Cellar'
  };
  if (prettifyFeatures[key]) {
    return prettifyFeatures[key];
  }
  return key;

};

import React, { PureComponent } from 'react';
import { Switcher } from '@dmm/lib-react-ui-components';

const conditionOptions = [
  {
    label: 'All',
    name: 'condition-any',
    value: 'any'
  },
  {
    label: 'New',
    name: 'condition-new',
    value: 'new',
  },
  {
    label: 'Used',
    name: 'condition-used',
    value: 'used'
  }
];

class FilterCondition extends PureComponent {
  handleDataChange = (_, value) => {
    if (this.props.tracking) {
      this.props.tracking.facetAdded({ ['condition']: value});
    }

    this.props.handleDataChange('condition', value);
  }

  render() {
    return <div className="search-filter condition" data-e2e="srp-search-filter-condition">
      <div className="search-filter-group">
        <Switcher
          defaultIndex={conditionOptions.findIndex(option => option.value === this.props.condition)}
          fullWidth
          options={conditionOptions}
          onSwitch={this.handleDataChange}
        />
      </div>
    </div>;
  }
}

export default FilterCondition;

export const isOverlapped = (element) => {
  return element.scrollHeight > element.clientHeight;
};

export const isOuterClick = (element, container) => {
  let target = element;
  while (target){
    if (target === container) {
      return false;
    }
    target = target.parentNode;
  }
  return true;
};

export const toShortFormat = (dateString) => {
  const monthNames = ['Jan','Feb','Mar','Apr',
    'May','Jun','Jul','Aug',
    'Sep', 'Oct','Nov','Dec'];

  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];
  return `${monthName} ${day}`;
};

export const overflowContainer = (overflow, container) => {
  const el = container || document.querySelector('.page-container');
  const className = 'overflow-hidden';
  if (overflow) {
    el?.classList.add(className);
  } else {
    el?.classList.remove(className);
  }
};

export {setUpEngineCatalogs} from './engineTypeHelper';

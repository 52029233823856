import React, { PureComponent } from 'react';
import BreadCrumb from '../../components/BreadCrumb';
import './styles.css';
import DefaultLayout from '../../layouts/Default';

export default class AdandSponsorship extends PureComponent {
  render() {
    return (
      <DefaultLayout {...this.props} pageType="AdandSponsorship">
        <BreadCrumb items={[{ title: 'Home', link: '/' }, { title: 'Ad and Sponsorship', link: '/ad-and-sponsorship/' }]} />
        <div className="adandsponsorship-head">
          <h1>AD AND SPONSORSHIP POLICY </h1>
          <span>Written by the Boat Trader Team. June 2020.</span>
        </div>
        <div className="adandsponsorship-content">
          <div className="left-content">
            <p>
              Boat Trader is committed to providing authoritative content from trusted industry experts and knowledgeable
              partners in order to aid our readers in their research about boats, marine products, boating practices and the marine industry as a whole.
              We provide this editorial content as a free resource for aspiring boating novices and experienced boat owners alike,
              as well as the broader boating community, with the aim of enabling boaters everywhere in their pursuits to spend more time
              on the water with family and friends in an informed, safe and responsible manner.
            </p>
            <h2>Guidelines for advertising</h2>
            <ol>
              <li>
                <span>Review of ads</span>
                <p>We reserve the right to add, modify or delete advertisements on our platforms in any manner and at our sole discretion.</p>
              </li>
              <li>
                <span>Endorsements for Products or Services</span>
                <p>
                  The display of advertisements on our site does not mean that we endorse those products and/or services,
                  manufacturers, distributors, sellers in any manner. Boat Trader may rate or rank products or services,
                  but this is done solely by the editorial team and/or contributing writers, without regard to any financial
                  or advertising relationship.
                </p>
              </li>
              <li>
                <span>Will not allow certain ads</span>
                <p>
                  We do not allow advertising and promotion of products and/or services known to be faulty, fraudulent or unsafe,
                  furthermore ads must not contain fraudulent, deceptive, or offensive material, including material that misrepresents,
                  ridicules, or attacks an individual or group on the basis of age, color, national origin, race, religion,
                  sex, sexual orientation, or disability. Ads must not be related to alcohol, firearms, ammunition, fireworks,
                  gambling, pornography, tobacco, vaping, e-cigarettes, weight loss products, smoking or smoking cessation products,
                  experimental or homeopathic treatments, therapy programs, astrology or the simulation of news or an emergency.
                  Additionally we do not allow ads with content pertaining to health guidelines, medical advice or medical treatment of any kind,
                  including but not limited to general health conditions or concerns, long term or short term illnesses, diseases,
                  viruses, or any other medical, neurological or developmental conditions or disorders.
                  We do not allow ads pertaining to cosmetic procedures, body modification, dating advice, downloadable utilities and/or software/plugins,
                  drugs or supplements, get-rich-quick schemes or offers, politics, references to sex or sexuality, religion,
                  sensationalism, sexual reproductive health, significant skin exposure, social casino games, gambling, betting,
                  video games, weight loss and any other categories that are not listed here that pertain to sensitive personal
                  issues and/or may pose inherent risks to our visitors.
                </p>
              </li>
              <li>
                <span>Clearly distinguish between editorial, sponsored content and advertising</span>
                <p>
                  Boat Trader’s intention is to make a clear distinction between editorial content and paid advertisements,
                  and we will label sponsored resources or paid advertisements as such. Sponsored content refers to content
                  that is created by Boat Trader covering a topic area that is relevant to a sponsor.
                  We retain full autonomy over our editorial content and do not allow sponsors to influence the topics we cover.
                  Labels of sponsored content include: “Sponsored by”, “From our sponsor” or “Brought to you by”.
                </p>
              </li>
              <li>
                <span>Affiliate Program with Amazon Associates</span>
                <p>
                  Our contributing writers and editorial staff may occasionally include links to relevant products that
                  they feel could be useful to readers. Payment may be received by Boat Trader for a purchase made from our site to a product.
                  We may earn money or receive products from the companies mentioned in our articles and pages via affiliate links to products
                  or services associated with the content in those articles and pages.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </DefaultLayout>
    );
  }
}


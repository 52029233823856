import validation from '@dmm/lib-common/lib/validation';
import isEmpty from 'lodash/isEmpty';

export default function validate(val, type, isRequired = false) {
  let pass = true;
  switch (type) {
  case 'text':
    pass = !isEmpty(val) && val.replace(/\s/g, '').length >= 4;
    break;
  case 'name':
    pass = validation.isValidFirstSecondName(val);
    break;
  case 'email':
    pass = (validation.isValidEmail(val));
    break;
  case 'phone':
    pass = isRequired ? (!isEmpty(val) && validation.isValidPhoneNumber(val)) : (isEmpty(val) || validation.isValidPhoneNumber(val));
    break;
  case 'phone-not-empty':
    pass = (!isEmpty(val) && validation.isValidPhoneNumber(val));
    break;
  case 'zip':
    pass = validation.isValidUsZipCode(val);
    break;
  default:
    break;
  }
  return pass;
}

import {useReducer} from 'react';
import {normalizeString} from '@dmm/lib-common/lib/formatting';
import { getStateByCode } from '../../../../utils/commonHelper';

const actions = {
  KEYWORD: 1,
  BRAND: 2,
  STATE: 3,
  CITY: 4,
  ZIP: 5,
  CITIES: 6,
  ZIPS: 7,
  BRANDS: 8,
  STATES: 9,
  RESET: 10
};

export const initialFilters = (startParams, brands, cities, zips, states) => {
  const {name, make, countrySubDivisionCode, city, postalCode} = startParams;
  const selectedState = String(countrySubDivisionCode).toUpperCase();
  const mappedStates = states.map(stateObj => getStateByCode(stateObj.key));
  const usStates = mappedStates.reduce((acc, state) => {
    acc.states.push(state.name);
    if (selectedState === state.value) {
      acc.selected = state.name;
    }
    return acc;
  }, {states: [], selected: ''});
  const selectedBrand = brands.find(t => normalizeString(t) === make) || '';
  const cityList = cities?.map(city => city.key)?.sort();
  const zipList = zips?.map(zip => zip.key)?.sort();
  const selectedCity = cityList?.find(c => normalizeString(c) === city) || '';
  const selectedPostalCode = zipList?.find(pc => pc === String(postalCode)) || '';

  return {
    keyword: name || '',
    brand: selectedBrand,
    state: usStates.selected,
    city: selectedCity,
    zip: selectedPostalCode,
    brandList: brands,
    stateList: usStates.states.sort(),
    cityList,
    zipList
  };
};

const dealerFiltersReducer = (state, action) => {
  const {KEYWORD, CITY, BRAND, ZIP, STATE, CITIES, ZIPS, BRANDS, STATES, RESET} = actions;
  switch (action.type) {
  case KEYWORD:
    return { ...state, keyword: action.keyword };
  case STATE:
    return { ...state, state: action.state};
  case CITY:
    return { ...state, city: action.city};
  case BRAND:
    return { ...state, brand: action.brand};
  case ZIP:
    return { ...state, zip: action.zip};
  case CITIES:
    return { ...state, cityList: action.cities };
  case ZIPS:
    return { ...state, zipList: action.zips };
  case BRANDS:
    return { ...state, brandList: action.brandList };
  case STATES:
    return { ...state, stateList: action.states };
  case RESET:
    return {...state, ...action.newState};
  default:
    return state;
  }
};

export const selectValue = (ev) => {
  if (!ev || !ev.target) {
    return null;
  }
  const {options, selectedIndex} = ev.target;
  return options[selectedIndex] ? options[selectedIndex].value : null;
};

export const useDealersReducer = (startParams, brands = [], cities = [], zips = [], states = []) => {
  const [state, dispatch] = useReducer(dealerFiltersReducer, initialFilters(startParams, brands, cities, zips, states));
  const {KEYWORD, CITY, BRAND, ZIP, STATE, CITIES, ZIPS, BRANDS, STATES, RESET} = actions;
  return {
    setKeyword: (value) => dispatch({type: KEYWORD, keyword: value}),
    setCity: (value) => dispatch({type: CITY, city: value}),
    setBrand: (value) => dispatch({type: BRAND, brand: value}),
    setZip: (value) => dispatch({type: ZIP, zip: value}),
    setState: (value) => dispatch({type: STATE, state: value}),
    setCities: (value) => dispatch({type: CITIES, cities: value}),
    setZips: (value) => dispatch({type: ZIPS, zips: value}),
    setBrands: (value) => dispatch({type: BRANDS, brandList: value}),
    setStates: (value) => dispatch({type: STATES, states: value}),
    reset: (newState) => {
      dispatch({type: RESET, newState});
    },
    dispatch,
    state
  };
};


export const faqContent = [{
  id: 1,
  question: 'Do Boat Dealers Offer Financing',
  answer: 'Many boat dealers are able to offer financing options for customers who want to purchase a boat. Financing a boat can be a significant investment, and most dealers understand that not everyone may have  the cash on hand to make a boat purchase outright. Therefore, many dealers work with lenders to offer financing options that can make buying a boat more affordable. Financing options can vary depending on the dealer, location, year, make and model of the boat you’re interested in purchasing. Some dealers may work with banks or credit unions to offer traditional boat loans, while others may have in-house financing options or work with third-party financing companies who specialize in boat loans. If you’re interested in financing a boat, it’s a good idea to research different boat dealers and their financing options, as well as shop around for competitive interest rates. You should also review your own credit history and financial situation to ensure that you’re able to qualify for financing and can afford the monthly payments.'
},
{
  id: 2,
  question: 'Do boat dealers take trade-ins?',
  answer: 'Yes,  boat dealers will often consider trade-ins when a customer purchases a new or used boat. Trading in a boat can be a convenient way to offset the cost of a new boat and potentially save on sales tax as well. The value of a trade-in boat will depend on various factors, including the age, condition, and market demand for that particular model. Dealers will typically inspect the boat and assess its value before offering a trade-in credit towards the purchase of a new or used boat. If you’re considering trading in your boat, it’s a good idea to do some research and get an idea of the boat’s current market value before approaching a dealer. This can help you negotiate a fair trade-in value and ensure that you get the best possible deal on your new or used boat purchase.'
},
{
  id: 3,
  question: 'Why do boat dealers not list prices online?',
  answer: 'Boat dealers may not list prices online for a variety of reasons. One reason is that boat prices can vary widely depending on the make, model, features, and customization options. Therefore, it can be difficult for dealers to provide accurate pricing information for every boat they have in stock. Another reason is that some dealers prefer to negotiate prices in person, as this can allow for more flexibility and personalization in the sales process. By not listing prices online, dealers may be able to work with customers to find the best possible deal that meets their specific needs and budget. Additionally, boat dealers may not list prices online to encourage customers to contact them directly. This can allow dealers to build relationships with potential buyers, provide personalized service, and answer any questions or concerns they may have before making a purchase. Overall, while not listing prices online may make it more difficult for customers to compare prices and features across different dealers, it can also allow for more personalized service and negotiation opportunities in the sales process.'
}
];

import React, { Component } from 'react';
import './styles.css';
import validate from '../../utils/dataValidation';
import classnames from 'classnames';
import get from 'lodash/get';

class ValidatedInput extends Component{

  state = {
    valid: false
  }

  isValid(validation, value)  {
    let valid = validate(value, validation);
    this.setState({valid: valid});
    return valid;
  }

  componentDidMount() {
    this.isValid(this.props.validation, get(this.props, 'defaultValue', ''));
  }

  render() {
    const forceTypingRules = {
      onPaste: (event) => event.preventDefault(),
      onDrop: (event) => event.preventDefault(),
      autoComplete: 'off'
    };
    return (
      <fieldset className={classnames('fieldset', `fieldset-${this.props.name}`, {'hidden': this.props.hidden, 'error': !this.state.valid})} data-e2e={`fieldset-${this.props.name}`}>
        {this.props.displayLabel && <label className={classnames({'hidden': !this.props.labelText})} htmlFor={this.props.name}>{this.props.labelText}</label>}
        { this.props.type === 'textarea' ?
          <textarea
            placeholder={this.props.placeholder}
            aria-label={this.props.labelText}
            defaultValue={this.props.defaultValue}
            {...(this.props.forceTyping && { ...forceTypingRules })}
            id={this.props.id}
            data-e2e={this.props.id}
            name={this.props.name}
            onChange={(e) =>
              this.props.onChange(this.props.name, this.isValid(this.props.validation, e.target.value), e.target.value)}
          />
          :
          <input
            placeholder={this.props.placeholder}
            aria-label={this.props.labelText}
            defaultValue={this.props.defaultValue}
            {...(this.props.forceTyping && { ...forceTypingRules })}
            type={this.props.type}
            id={this.props.id}
            data-e2e={this.props.id}
            name={this.props.name}
            readOnly={this.props.readOnly}
            onChange={(e) =>
              this.props.onChange(this.props.name, this.isValid(this.props.validation, e.target.value), e.target.value)}
          />
        }
      </fieldset>
    );
  }
}

export default ValidatedInput;

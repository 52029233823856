import React, { useEffect, useRef } from 'react';
import BoatType from '../../../components/BoatType';
import { useState } from 'react';
import { LinkListModal } from '@dmm/lib-react-ui-components';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import { normalizeString }  from '@dmm/lib-common/lib/formatting';
import './styles.css';

const addModalListeners = (boatTypeModalElement, addModalClassHandler, addModalMakesHandler) => {
  if (!boatTypeModalElement?.getElementsByTagName) {
    return () => {};
  }
  let linkModalListeners = [];
  const modalListener = (boatClass) => () => {
    addModalClassHandler(boatClass);
  };
  const modalMakesListener = (index) => (event) => { addModalMakesHandler(event, index); };
  const linksInModal = Array.from(boatTypeModalElement.getElementsByTagName('A'));
  const classLink = linksInModal?.shift();
  const boatClass = boatTypeModalElement.getElementsByTagName('H3')[0].innerText;
  const boatModalListener = modalListener(boatClass);
  classLink?.addEventListener('click', boatModalListener);
  linkModalListeners.push({link: classLink, modalListener: boatModalListener});
  linksInModal.forEach((link, index) => {
    const makeLinkModalListener = modalMakesListener(index);
    link.addEventListener('click', makeLinkModalListener);
    linkModalListeners.push({link, modalListener: makeLinkModalListener});
  });
  const removeModalListeners = () => {
    linkModalListeners.forEach(({link, modalListener}) => {
      link?.removeEventListener('click', modalListener);
    });
  };
  return removeModalListeners;
};

const endsWithSpecialWords = (title) => title.trim().toLowerCase().endsWith('boats') || title.trim().toLowerCase().endsWith('yachts');

const createModalProps = (listing, boatType, onCloseModal) => {
  const seeAllLink = endsWithSpecialWords(listing.title) ? `See all ${listing.title} for sale` : `See all ${listing.title} boats for sale`;
  let srpLink = listing.type === 'group' ?
    generateSearchPath({group: listing.id}, {}, false) :
    generateSearchPath({multiFacetedBoatTypeClass: {[boatType]: [listing.id]}}, {}, false);
  if (srpLink.includes('pwc')) {
    const splittedSrpLink = srpLink.split('/');
    srpLink = splittedSrpLink.slice(0, splittedSrpLink.length - 2).join('/') + '/';
  }

  return {
    id: listing.id,
    title: listing.title,
    content: listing.description.trim(),
    subtitle: 'Popular Makes',
    link: {
      text: seeAllLink,
      url: srpLink,
      title: seeAllLink
    },
    list: listing?.normalizedMakesList.map( make => {
      return {
        text: make,
        url: generateSearchPath({makeModel: {[normalizeString(make)]: [ '' ] } }, {}, false)
      };
    }),
    onClose: () => onCloseModal()
  };
};

const byListingId = (obj, listing) => {
  obj[listing.id] = listing;
  delete obj[listing.id].id;
  return obj;
};

const BoatTypesModal = ({modalRef, linkListModalProps, display}) => {
  const displayClass = display ? 'boat-type-modal' : 'boat-type-modal-hidden';
  const testId = display ? 'modal-overlay' : 'modal-overlay-hidden';
  return (
    <div ref={modalRef} className={displayClass}>
      <span className="modal-overlay" data-testid={testId}></span>
      <LinkListModal {...linkListModalProps} data-testid="modal-container"></LinkListModal>
    </div>
  );
};

const BoatTypeList = ({setGenericEvent, showSticky, listings = [], boatType = '', typeTitle = ''}) => {
  const [modalIndex, setModalIndex] = useState(null);
  const boatTypeModalRef = useRef(null);

  const addModalClassHandler = (boatClass) => {
    setGenericEvent('See All', typeTitle, boatClass, '', 'detailed_page_name,Boat Types');
  };

  const addModalMakesHandler = (event, index) => {
    const makeName = event.target.innerText;
    setGenericEvent('Select Make', 'click', makeName, ++index, 'detailed_page_name,Boat Types');
  };

  useEffect(() => {
    const boatTypeModalElement = boatTypeModalRef?.current;
    const removeModalListeners = addModalListeners(boatTypeModalElement, addModalClassHandler, addModalMakesHandler);
    return () => {
      removeModalListeners();
    };
  }, [modalIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!listings || !listings.length) {
    return null;
  }

  const openModal = (index) => {
    setModalIndex(index);
  };

  const onClickCardHandler = (index, boatClass, boatType) => {
    setGenericEvent('Boat Type Modal Open', boatType, boatClass, '', 'detailed_page_name,Boat Types');
    openModal(index);
    showSticky(false);
  };

  const onCloseModal = () => {
    setModalIndex(null);
    showSticky(true);
  };
  const boatList = listings.map((listing, i) => <BoatType key={i} listing={listing} typeTitle={typeTitle} onClickCardHandler={onClickCardHandler}/>);
  const boatModalProps = listings
    .map(listing => createModalProps(listing, boatType, onCloseModal))
    .reduce(byListingId, {});

  const boatTypeModals = Object.keys(boatModalProps).map((boatTypeKey, index) => {
    const modalProps = boatModalProps[boatTypeKey];
    const display = modalIndex === boatTypeKey;
    const properRef = display ? boatTypeModalRef : null;
    return (<BoatTypesModal key={index} modalRef={properRef} linkListModalProps={modalProps} display={display}/>);
  });

  return (
    <>
      <section className="boat-type-list">
        {boatList}
        {boatTypeModals}
      </section>
    </>
  );
};

export default BoatTypeList;

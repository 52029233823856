import React, { Component } from 'react';
import { Ad } from '@dmm/react-common-components';
import '../styles.css';

export default class NativeAdDesk extends Component {
  render() {
    let {adNum, adParams} = this.props;
    const nativeAdParams = adParams || {
      adId: `div-gpt-ad-native-${adNum}`,
      classes: 'ad ad-desk-native'
    };

    return (
      <li className="desktop-native-ad">
        <Ad {...nativeAdParams}  />
      </li>
    );
  }
}

export const sortTypeMap = {
  'updated:desc': 'modified-desc',
  'updated:asc': 'modified-asc',
  'distance:asc': 'proximity-asc',
  'distance:desc': 'proximity-desc',
  'year:desc': 'year-desc',
  'year:asc': 'year-asc',
  'length:desc': 'length-desc',
  'recommended:desc': 'recommended-desc',
  'score:desc': 'score',
  'length:asc': 'length-asc',
  'price:asc': 'price-asc',
  'price:desc': 'price-desc',
  'dynamic:desc': 'dynamic',
  'dynamicpreview:desc': 'dynamicpreview',
};

export const sortNameMap = {
  'updated:desc': 'Recently Updated: Newest first',
  'updated:asc': 'Recently Updated: Oldest first',
  'recommended:desc': 'Recommended',
  'distance:asc': 'Distance: Nearest first',
  'distance:desc': 'Distance: Farthest first',
  'year:desc': 'Year: Newest first',
  'year:asc': 'Year: Oldest first',
  'length:desc': 'Length: Longest first',
  'length:asc': 'Length: Shortest first',
  'price:asc': 'Price: Low to High',
  'price:desc': 'Price: High to Low',
  'dynamic:desc': 'Dynamic',
  'dynamicpreview:desc': 'Dynamic Preview',
  'score:desc': 'Score'
};

export const getDefaultSort = () => {
  return 'recommended:desc';
};


// Engines ========
export const ENGINES_SORT_TYPES = {
  'proximity:asc': { label: 'Location - Nearest', apiParam: 'proximity-asc' },
  'proximity:desc': { label: 'Location - Farthest', apiParam: 'proximity-desc' },
  'price:desc': { label: 'Price - High to Low', apiParam: 'price-desc' },
  'price:asc': { label: 'Price - Low to High', apiParam: 'price-asc' },
  'year:desc': { label: 'Year - Newest first', apiParam: 'year-desc' },
  'year:asc': { label: 'Year - Oldest first', apiParam: 'year-asc' },
  'horsepower:desc': { label: 'Horsepower - Most Powerful', apiParam: 'powerHp-desc' },
  'horsepower:asc': { label: 'Horsepower - Least Powerful', apiParam: 'powerHp-asc' }
};

export const getDefaultEnginesSort = (zip, city, state) => {
  if (zip || city || state) {
    return 'proximity:asc';
  }
  return 'year:desc';
};

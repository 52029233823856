import React, { useEffect, useState } from 'react';
import { checkPreQualVariation, makeFinanceableSummaryTrackingEvent } from '../../../../utils/trident';
import './styles.css';
import classNames from 'classnames';
import { getBreakpoint } from '../../../../utils/commonHelper';

const trackPreQualLinkClick = (actionLabel) =>{
  const preQualTracking = makeFinanceableSummaryTrackingEvent(actionLabel, 'click');
  window.dataLayer.push(preQualTracking);
};


export const StickyButtons = (props) => {
  const {
    isUserLoginExperiment,
    userEmailAvailable,
    listing,
    userEmail,
    handleLoginContactButtonClick,
    showDetailsContact,
    setShowLeadOverlay,
    setShowDetailsContact,
    isHidden,
    showFinanceCTAs,
    setShowDetailsSuccess,
    fadeButtons
  } = props;

  const [hiddenButtons, setHiddenButtons] = useState(isHidden);
  const breakpoint = getBreakpoint();

  const handleContactButtonClick = (event, actionLabel) => {
    event.preventDefault();
    const contactTracking = makeFinanceableSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(contactTracking);
    setShowLeadOverlay(true);
    setShowDetailsContact(true);
    setShowDetailsSuccess(false);
    if (isUserLoginExperiment) {
      setHiddenButtons(true);
    }
  };

  const updateStickyBtnsVisibility = () => {
    const infoContainer = document.querySelector('#prequalified-and-contact-seller');
    if (isInViewport(infoContainer)) {
      setHiddenButtons(true);
    } else {
      setHiddenButtons(false);
    }
  };

  const isInViewport = element => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom >= 0
    );
  };

  useEffect(() => {
    if (isUserLoginExperiment && userEmailAvailable) {
      window.addEventListener('scroll', updateStickyBtnsVisibility);
    }

    return () => {
      window.removeEventListener('scroll', updateStickyBtnsVisibility);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoginExperiment, userEmailAvailable]);

  return <>
    { isUserLoginExperiment ? (
      <div id="privateSellerBoatSummaryV3-stickyButtons" className={classNames('sticky-buttons','sticky-contact', {'hidden': hiddenButtons, fade: fadeButtons})}>
        {userEmailAvailable &&
            <>
              {showFinanceCTAs &&
              <button
                onClick={() => {
                  trackPreQualLinkClick('pre-qualify sticky footer');
                  checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
                }}
                className="sticky-prequalify-button">Get Pre-Qualified</button>
              }
              {!userEmail &&
                <button
                  className="sticky-contact-button"
                  onClick={(e) => handleLoginContactButtonClick(e, 'contact seller sticky footer')}>Contact Seller</button>
              }
              {userEmail && (
                <button
                  className="sticky-contact-button"
                  data-testing-class={`contact-seller-${breakpoint}-sticky`}
                  onClick={(e) => handleContactButtonClick(e, 'contact seller sticky footer')}>Contact Seller</button>
              )}
            </>
        }
      </div>
    ) : (
      !showDetailsContact && (
        <div className={`sticky-buttons sticky-contact ${fadeButtons ? 'fade' : ''}`}>
          {showFinanceCTAs &&
            <button
              className="sticky-prequalify-button"
              data-testing-class={`contact-seller-${breakpoint}-sticky`}
              onClick={() => {
                trackPreQualLinkClick('pre-qualify sticky footer');
                checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
              }}>Get Pre-Qualified
            </button>
          }
          <button
            className="sticky-contact-button"
            data-testing-class={`contact-seller-${breakpoint}-sticky`}
            onClick={(e) => handleContactButtonClick(e, 'contact seller sticky footer')}>Contact Seller
          </button>
        </div>
      )
    )}
  </>;
};

import React, { useState } from 'react';
import './styles.css';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import { GetPreQualifiedFooter, GetPreQualifiedHeader, GetPreQualifiedSuccess } from '../../../components/GetPreQualified';
import GetPreQualifiedForm from '../../../components/GetPreQualifiedForm';
import classnames from 'classnames';
import { BOAT_LEAD_PURCHASE_TYPE, TRACKING_EVENT_LEAD_PURCHASE_SUBMISSION } from '../../../constants/BoatLoans';

export const GetPreQualifiedCollapsible = (props) => {

  const [isSuccess, setIsSuccess] = useState(false);
  const { isMobile, isLeadSubmitted = false, ficoScores, listing, isBranded } = props;

  const onSuccess = () => {
    setIsSuccess(true);
  };

  const onClose = () => {
    setIsSuccess(false);
  };

  return  <div className={classnames({'prequalified-collapsible': !isLeadSubmitted})}>
    {!isSuccess && !isLeadSubmitted && (
      <CollapsibleContent initialState={(isMobile || isBranded ? 'closed' : 'open')}>
        <CollapsibleHeader>
          <GetPreQualifiedHeader title="Financing"/>
        </CollapsibleHeader>
        <Collapsible>
          <GetPreQualifiedForm listing={listing} ficoScores={ficoScores} leadPage="SRP" onSuccess={onSuccess} leadType={BOAT_LEAD_PURCHASE_TYPE} trackingEvent={{'event': TRACKING_EVENT_LEAD_PURCHASE_SUBMISSION}}/>
          <GetPreQualifiedFooter/>
        </Collapsible>
      </CollapsibleContent>
    )}
    {isSuccess &&
        <GetPreQualifiedSuccess onClose={onClose}/>
    }
  </div>;

};

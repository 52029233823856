import React from 'react';
import {Ad} from '@dmm/react-common-components';

const BDPAd = ({show, addParams, noAdsParam}) => {
  if (!show || noAdsParam) {
    return null;
  }

  if (addParams?.reloadAd) {
    addParams.targeting = {
      refresh: addParams.reloadAd.toString()
    };
  }

  return (
    <div className="bdp-ad"><Ad {...addParams} /></div>
  );
};

export default BDPAd;

import React, { PureComponent } from 'react';
import {Range} from 'rc-slider';

class Slider extends PureComponent {

  state = {
    lMin: this.props.initialMin || '',
    lMax: this.props.initialMax || '',
    handle: this.props.handle || ''
  }

  maxHandleMoved(val) {
    if ( val !== this.state.lMax ) {
      return true;
    }
  }

  minHandleMoved(val) {
    if ( val !== this.state.lMin ) {
      return true;
    }
  }

  handleSliderDrag = (val) => {
    if (this.minHandleMoved(val[0].toString())) {
      this.setState({lMin: val[0].toString(), handle: 'min'});
      this.props.onChange('min', val[0]);
    }
    if (this.maxHandleMoved(val[1].toString())) {
      this.setState({lMax: val[1].toString(), handle: 'max'});
      this.props.onChange('max', val[1]);
    }
  }

  handleSliderFinish = (val) => {
    if (this.state.handle === 'min' ){
      this.props.onAfterChange('min', val[0].toString());
    } else {
      this.props.onAfterChange('max', val[1].toString());
    }
  }

  render() {
    return (
      <Range
        min={parseInt(this.props.min)}
        max={parseInt(this.props.max)}
        value={this.props.value}
        marks={this.props.marks}
        onChange={this.handleSliderDrag}
        onAfterChange={this.handleSliderFinish}
      />
    );
  }
}

export default Slider;

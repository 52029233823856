export const BASE_TRIDENT_APP_URL = '/boat-loans/apply/?purpose=Boat&source=101458';

/**
 * @param {{ utmSource?: string, utmMedium?: string, utmCampaign?: string, utmContent?: string, boatInfo?: any }} param
*/
export const getTridentAppUrl = ({ utmSource, utmMedium, utmCampaign, utmContent } = {}) => {
  return `${BASE_TRIDENT_APP_URL}${utmSource ? `&utm_source=${utmSource}` : '' }${utmMedium ? `&utm_medium=${utmMedium}` : ''}${utmCampaign ? `&utm_campaign=${utmCampaign}` : ''}${utmContent ? `&utm_content=${utmContent}` : ''}`;
};

export const TRIDENT_APP_URL_FRM = {
  BoatDetails: BASE_TRIDENT_APP_URL,
  BoatLoans: getTridentAppUrl({ utmSource: 'boattrader', utmMedium: 'internalreferral', utmCampaign: 'boatloanspage', utmContent: 'BDPcalculatorapply' }),
  BoatLoansCalculator: getTridentAppUrl({ utmSource: 'boattrader', utmMedium: 'internalreferral', utmCampaign: 'boatloancalculator', utmContent: 'boatloancalculator'  })
};

export const TRIDENT_SESSION_STORAGE_KEYS = new Set([
  'keycode',
  'promocode',
  'subsource',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
]);

import { Action } from '@dmm/lib-react-ui-components';
import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch } from 'react-redux';
import { setOemBrandProductImpression } from '../../../store/actions/dataLayer';
import { logoFromDealer, useForeignLogo, usePhone } from '../../../utils/dealerHelpers/custom';
import { dealerUrl, generateDealerAddress } from '../../../utils/dealerSRPHelper';
import { LOGO_SIZES, getResizedImageSrc } from '../../../utils/listingHelper';
import Link from '../../SEO/Link';

const DealerAddress = ({street, city, countrySubDivisionCode, onClick}) => {
  const address = generateDealerAddress(street, city, countrySubDivisionCode);
  return  (
    <p className="dealer-location" data-e2e="dealer-location" onClick={onClick}>
      {address}
    </p>
  );
};

const DealerPage = ({name, id, url, anchorProperties}) => {
  if (!name) {
    return null;
  }
  if (!id) {
    return <p>{name}</p>;
  }
  return (
    <Link className="dealer-page-link" {...anchorProperties} href={url} onClick={() => {}}>
      {name}
    </Link>
  );
};

const onCallClick = (id, addLead, setGenericEvent, call) => {
  addLead(id, 'phone lead');
  setGenericEvent('phone click', 'dealer item', 'dealer search page');
  call();
};

const DealerPhone = ({phone, addLead, setGenericEvent, dealerId}) => {
  const [callText, call] = usePhone(phone);
  if (!phone) {
    return null;
  }
  return (
    <p className="dealer-phone" data-e2e="dealer-phone">
      <a className="tel-number" data-e2e = "call-now-link" data-reporting-event-category="soft lead" data-reporting-event-action="find a dealer" href={`tel:${phone}`} onClick={() => onCallClick(dealerId, addLead, setGenericEvent, call)}>
        {callText}
      </a>
    </p>
  );
};

export const DealerItem = ({dealer, onClickContact, onSelectLocation, page, addLead, setGenericEvent}) => {
  const logoUrl = logoFromDealer(dealer);
  const logo = useForeignLogo(logoUrl);
  const {name, phone, id, street, city, countrySubDivisionCode, coordinates} = dealer;
  const address = {street, city, countrySubDivisionCode, coordinates, onClick: () => onSelectLocation(dealer)};
  const url = dealerUrl(name, id);
  const anchorProperties = {
    'data-reporting-click-product-id': id,
    'data-reporting-click-listing-type': 'broker-dealer listing',
    'data-reporting-page': page,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOemBrandProductImpression(dealer.id, 'broker-dealer listing', 'broker-dealer', page));
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="dealer-item" data-e2e="dealer-item">
      <div className="dealer-card" data-e2e="dealer-card">
        {logo &&
        <div className="dealer-logo" data-e2e="dealer-logo">
          <LazyLoadImage
            className="dealer-logo-img"
            alt={name}
            src={getResizedImageSrc({ logoUrl: logo }, LOGO_SIZES.dealerListLogo.w, LOGO_SIZES.dealerListLogo.h)}
            threshold={200}/>
        </div>
        }
        <div className="dealer-data" data-e2e="dealer-data">
          <DealerPage name={name} data-e2e={name} id={id} url={url} anchorProperties={anchorProperties}/>
          <div className="dealer-data-items" data-e2e="dealer-data-items">
            <DealerAddress {...address} addLead={addLead} dealerId={dealer.id}/>
            <DealerPhone phone={phone} addLead={addLead} setGenericEvent={setGenericEvent} dealerId={dealer.id}/>
          </div>
        </div>
        <div className="contact" data-e2e="contact">
          <Action className="contact-dealer" onClick={() => onClickContact(dealer)} label="Contact Dealer" size="small" stretch />
        </div>
        <div className="inventory" data-e2e="inventory">
          <Action className="inventory-dealer" as="a" variant="secondary" {...anchorProperties} href={url} label="View Inventory" size="small" stretch />
        </div>
      </div>
    </div>
  );
};
export default DealerItem;

export const getLocalItem = (item) => {
  return localStorage.getItem(item);
};

export const setLocalItem = (itemName, data) => {
  return localStorage.setItem(itemName, data);
};

export const removeLocalItem = (item) => {
  return localStorage.removeItem(item);
};

export const getSessionItem = (item) => {
  return sessionStorage.getItem(item);
};

export const setSessionItem = (itemName, data) => {
  return sessionStorage.setItem(itemName, data);
};

export const removeSessionItem = (item) => {
  return sessionStorage.removeItem(item);
};

export const safeParse = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
};

export const getSessionArray = (arrayName) => {
  const sessionValue = getSessionItem(arrayName);
  const parsedData = safeParse(sessionValue);

  if (!parsedData) {
    return [];
  }

  if (Array.isArray(parsedData)) {
    return parsedData;
  }

  return [parsedData];
};

export const setSessionArray = (arrayName, data = []) => {
  if (!Array.isArray(data)) {
    return false;
  }

  return setSessionItem(arrayName, JSON.stringify(data));
};

export const setSessionArrayItem = (arrayName, arrayItem) => {
  const sessionArray = getSessionArray(arrayName);
  sessionArray.push(arrayItem);

  return setSessionArray(arrayName, sessionArray);
};

export const setLocalItemWithExpiry = (key, value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  return localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

import React, { useRef, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Modal from '../../../Modal';

import { getBreakpoint } from '../../../../utils/commonHelper';
import { CDN } from '../../../../constants/BoatLoansCalculator';
import { calculateMonthlyPrice, isFinanceable } from '../../../../utils/trident';

import './styles.css';

export const MonthlyPaymentTooltip = ({ listing, teaserRate }) => {
  const [tipOpen, setTipOpen] = useState(false);
  const buttonRef = useRef(null);
  const tipContentRef = useRef(null);

  const hiddenPrice = get(listing, 'price.hidden') || !listing.price?.type?.amount || listing.price?.type?.amount.USD <= 1;
  const price = get(listing, 'price.type.amount.USD');
  const term = price && price >= 50000 ? 240 : 180;
  const monthlyPrice = calculateMonthlyPrice(teaserRate, term, price);
  const isDesktop = getBreakpoint() === 'desktop';
  const closeImg = isDesktop ? `${CDN}/media/modal-close-button.d186d655.svg` : `${CDN}/img/icons/close-icon-black.svg`;
  const isPremium = get(listing, 'featureType.enhanced', false) ? 'enhanced' : '';
  const boatYear = get(listing, 'year');
  const showMonthlyPayment = !hiddenPrice && monthlyPrice && isFinanceable(price, boatYear);
  let direction =  'down-end';

  // I resolutions the tootip looks heaped, so its better to force it to show below
  if (!isDesktop && typeof window !== 'undefined') {
    if (window.innerWidth < 380) {
      direction = 'up-end';
    }
  }

  const toggleTip = () => {
    setTipOpen(prevState => !prevState);
  };

  const toggleTipMobile = () => {
    if (!isDesktop) {
      setTipOpen(prevState => !prevState);
    }
  };

  return showMonthlyPayment ? (
    <>
      <div onClick={toggleTip}>
        <Modal
          isOpen={tipOpen}
          portalClassName="tooltip-modal-actionable"
          contentLabel="Tooltip modal"
        />
      </div>
      <Tooltip
        direction={direction}
        className={`tooltip-wrapper ${direction} ${isPremium}`}
        isOpen={tipOpen}
        forceDirection
        content={(
          <div ref={tipContentRef} className="tooltip-monthly">
            <div className="tooltip-monthly-head">
              <span className="tooltip-monthly-head-monthlyPayment" data-testid="tooltip-monthlyPayment" onClick={toggleTipMobile}>{`$${monthlyPrice}/month`}</span>
              <span className="tooltip-monthly-head-close" onClick={toggleTip} >
                <img alt="tooltip close button" src={closeImg} />
              </span>
            </div>
            <div className="tooltip-monthly-body" onClick={toggleTipMobile}>
              <p className="tooltip-monthly-body-paragraph">{`Estimated monthly payment based on a ${term}-month loan at ${teaserRate}% APR.`}</p>
            </div>
          </div>
        )}
      >
        <div
          ref={buttonRef}
          onClick={toggleTip}
          className="monthly-price"
        >
          {`$${monthlyPrice}/mo`}
          {
            <span className="info-icon"></span>
          }
        </div>
      </Tooltip>
    </>
  ) : null;
};

MonthlyPaymentTooltip.propTypes = {
  listing: PropTypes.object.isRequired,
  teaserRate: PropTypes.number,
};

export default MonthlyPaymentTooltip;

import './styles.css';

import React, {Suspense, useEffect, useMemo, useReducer, useState} from 'react';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import { SearchAlertsFilterTags } from '../../../components/RefineSearch';

import loadable, { lazy } from '@loadable/component';
/* istanbul ignore next */
const FilterCondition = lazy(() => import('../../../components/RefineSearch/FilterCondition'));
/* istanbul ignore next */
const FilterEngineType = loadable(() => import('../../../components/RefineSearch/FilterEngineType'));
/* istanbul ignore next */
const FilterFuelType = loadable(() => import('../../../components/RefineSearch/FilterFuelType'));
/* istanbul ignore next */
const FilterHorsepower = loadable(() => import('../../../components/RefineSearch/FilterHorsepower'));
/* istanbul ignore next */
const FilterMake = loadable(() => import('../../../components/RefineSearch/FilterMakeModel'), {
  resolveComponent: (components) => components.FilterMake
});
/* istanbul ignore next */
const FilterModels = loadable(() => import('../../../components/RefineSearch/FilterMakeModel'), {
  resolveComponent: (components) => components.FilterModels
});
/* istanbul ignore next */
const FilterPrice = lazy(() => import('../../../components/RefineSearch/FilterPrice'));
/* istanbul ignore next */
const FilterYear = lazy(() => import('../../../components/RefineSearch/FilterYear'));
/* istanbul ignore next */
const FilterLocation = loadable(() => import('../../../components/RefineSearch/FilterLocation'));

import {DEFAULT_ENGINE_REFINE_SEARCH, ENGINES_ID} from '../../../constants/Engines';
import { clearEmptyEngineProps } from '../../../utils/commonHelper';
import {getActiveParams, getDefaultParams} from '../../../utils/urlHelpers/listings';
import { nil } from '../../../utils/runOnceHelper';
import { useDebounce, usePrevious } from '../../../utils/hooks';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {enginePillBoxFormat, setUpEngineCatalogs} from '../../../utils/engineTypeHelper';

import Spinner from '../../../components/Spinner';

const getCurrentState = (match, urlParsedParams = null) => {
  const def = urlParsedParams || getDefaultParams(get(match, 'params', {}), ENGINES_ID);
  return def;
};

const setInitialState = (startParams) => {
  let powerHp = { min: startParams.powerHp?.min, max: startParams.powerHp?.max };
  let price = { min: startParams.price?.min, max: startParams.price?.max };
  let year = { min: startParams.year?.min, max: startParams.year?.max };
  // TODO: Rest of filters
  const initialState = {
    sort: startParams.sort,
    engine: startParams.engine || 'all',
    condition: startParams.condition,
    fuelType: get(startParams, 'fuelType[0]') || 'all',
    zip: startParams.zip,
    state: startParams.state,
    city: startParams.city,
    makeModel: startParams.makeModel,
    powerHp,
    year,
    price,
    ownerId: startParams.ownerId,
    radius: startParams.radius
  };
  return clearEmptyEngineProps(initialState);
};

const reducer = (prevState, updated) => ({
  ...prevState,
  ...updated
});

const EnginesRefineSearch = ({ startParams, onDataChange, position, loading, loadingFacets, facets, urlParsedParams }) => {
  const [urlParams, setUrlParams] = useState(() => getCurrentState(startParams.match, urlParsedParams));
  const prevStartParamsMatch = usePrevious(startParams.match);
  const [data, setDebouncedData, setDataImmediately, lastChange, setDataWithNoCallback] = useDebounce({
    initialState: startParams,
    initStateFn: setInitialState,
    callback: () => {
      onDataChange(data, lastChange);
    }
  });

  const [engineCatalogs, setEngineCatalogs] = useReducer(reducer, setUpEngineCatalogs(facets, data));

  const [lazyLoadFilter, setLazyLoadFilter] = useState(false);

  // TODO: Deal with facets when needed
  let tracking = { facetAdded: nil, facetRemoved: nil };

  const handleDataChange = (type, value, debounce) => {
    const updatedData = { [type]: value };
    debounce ? setDebouncedData(updatedData) : setDataImmediately(updatedData);
  };

  const handleMultiDataChange = (newChanges, debounce) => {
    delete newChanges.modelRange;
    debounce ? setDebouncedData(newChanges) : setDataImmediately(newChanges);
  };

  useEffect(() => {
    const { engineTypes, states, country, selectedState, stateCity, cityZip, makes, fuelTypes, models } = setUpEngineCatalogs(
      facets,
      data
    );
    setEngineCatalogs({ engineTypes, fuelTypes, states, country, selectedState, stateCity, cityZip, makes, models });
  }, [facets]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fixes back button
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isEqual(prevStartParamsMatch, startParams.match)) {
      return;
    }
    const newParams = getCurrentState(startParams.match);
    setUrlParams(newParams);
    const newData = setInitialState(newParams, data);
    setDataWithNoCallback(newData);
  });

  useEffect(() => {
    setLazyLoadFilter(true);
  }, []);

  const isMobile = position === 'mobile';

  const currentParams = useMemo(() => {
    const activeParams = isMobile ? data : urlParams;
    const res = getActiveParams(activeParams, ENGINES_ID);
    return enginePillBoxFormat(res);
  }, [urlParams, data]); // eslint-disable-line react-hooks/exhaustive-deps
  const makeModel = data.makeModel;
  const locationOpen = data.state || data.zip ? 'open' : 'closed';
  const makeModelOpen = !isEmpty(makeModel) ? 'open' : 'closed';
  const engineTypeOpen = get(data, 'engine', 'all') !== 'all' || urlParams.engine ? 'open' : 'closed';
  const showPillBox = Object.keys(currentParams).filter(param => param !== 'page').length > 0;

  const resetState = () => {
    const newParams = getDefaultParams({}, ENGINES_ID);
    setDataImmediately(setInitialState(newParams));
  };
  const renderLoader = () => <Spinner />;

  // TODO: Review in all filters to pass searchPage and to add it as argument to generateSearchPath
  return (
    <div className="engines-srp">
      {showPillBox && <SearchAlertsFilterTags
        active={currentParams}
        onClear={() => isMobile && resetState()}
        params={DEFAULT_ENGINE_REFINE_SEARCH}
        handleDataChange={(type, value, debounce) => isMobile && handleDataChange(type, value, debounce)}
        makes={engineCatalogs.makes}
        makeModels={makeModel}
        isMobile={isMobile}
        basePath={'/engines/'}
        searchPage={ENGINES_ID}
      />}
      <CollapsibleContent
        data-id="collapsible-engine-type"
        initialState={engineTypeOpen}
      >
        <CollapsibleHeader>Engine Type</CollapsibleHeader>
        <Collapsible>
          <FilterEngineType
            engineTypes={engineCatalogs.engineTypes}
            engine={data.engine}
            position={position}
            handleDataChange={handleDataChange}
            params={urlParams}
            tracking={tracking}
            searchPage={ENGINES_ID}
          />
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent
        data-id="collapsible-fuel-type"
        initialState={get(data, 'fuelType', 'all') !== 'all' ? 'open' : 'closed'}
      >
        <CollapsibleHeader>Fuel Type</CollapsibleHeader>
        <Collapsible>
          <FilterFuelType
            fuelTypes={engineCatalogs.fuelTypes}
            selectedFuelTypes={[data.fuelType]}
            position={position}
            handleDataChange={handleDataChange}
            params={urlParams}
            searchPage={ENGINES_ID}
            isSingleFaceted
          />
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent data-id="collapsible-horsepower" initialState="open">
        <CollapsibleHeader>Horse Power</CollapsibleHeader>
        <Collapsible>
          <FilterHorsepower
            min={data.powerHp.min}
            max={data.powerHp.max}
            onChange={(change) => {
              handleMultiDataChange(change, true);
            }}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent data-id="collapsible-condition" initialState="open">
        <CollapsibleHeader>Condition</CollapsibleHeader>
        <Collapsible>
          {lazyLoadFilter && <Suspense fallback={renderLoader()}>
            <FilterCondition
              position={position}
              condition={data.condition}
              handleDataChange={handleDataChange}
              tracking={tracking}
            />
          </Suspense>}
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent data-id="collapsible-yeay" initialState="open">
        <CollapsibleHeader>Year</CollapsibleHeader>
        <Collapsible>
          {lazyLoadFilter && <Suspense fallback={renderLoader()}>
            <FilterYear
              min={data.year.min}
              max={data.year.max}
              handleDataChange={(field, value) => handleDataChange(field, value, true)}
              tracking={tracking}
            />
          </Suspense>}
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent initialState="open">
        <CollapsibleHeader>Price</CollapsibleHeader>
        <Collapsible>
          {lazyLoadFilter && <Suspense fallback={renderLoader()}>
            <FilterPrice
              min={data.price.min}
              max={data.price.max}
              handleDataChange={(type, value) => handleMultiDataChange({ [type]: value }, true)}
              tracking={tracking}
              hidePriceDrop
            />
          </Suspense>}
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent data-id="collapsible-location" initialState={locationOpen}>
        <CollapsibleHeader>
            Location
        </CollapsibleHeader>
        <Collapsible type="no-pad" doubleHeight>
          <FilterLocation
            loading={loading}
            position={position}
            radius={data.radius}
            zip={data.zip}
            state={data.state}
            states={engineCatalogs.states}
            cityZip={engineCatalogs.cityZip}
            country={engineCatalogs.country}
            handleMultiDataChange={handleMultiDataChange}
            params={urlParams}
            selectedState={engineCatalogs.selectedState}
            selectedCities={data.city}
            stateCity={engineCatalogs.stateCity}
            tracking={tracking}
            searchPage={ENGINES_ID}
            hideUserLocation={true}
          />
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent data-id="collapsible-make" initialState={makeModelOpen}>
        <CollapsibleHeader>Make</CollapsibleHeader>
        <Collapsible>
          <FilterMake
            makes={engineCatalogs.makes}
            makeModels={makeModel}
            position={position}
            loading={loadingFacets}
            handleDataChange={handleDataChange}
            handleMultiDataChange={handleMultiDataChange}
            params={urlParams}
            tracking={tracking}
            searchPage={ENGINES_ID}
          />
        </Collapsible>
      </CollapsibleContent>

      <FilterModels
        makes={engineCatalogs.makes}
        makeModels={makeModel}
        models={engineCatalogs.models}
        position={position}
        loading={loadingFacets}
        handleMultiDataChange={handleMultiDataChange}
        params={urlParams}
        tracking={tracking}
        searchPage={ENGINES_ID}
      />
    </div>
  );
};

export default EnginesRefineSearch;

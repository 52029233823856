import React from 'react';
import {useAdditionalLeads} from '../MainContent/bdpController/bdpManager';
import {useBdpActions} from '../MainContent/bdpController/bdpActions';
import Alert from '../../../components/Alert';
import {ADDITIONAL_LEADS_ALERT_CONTENT} from '../../../constants/boats';

const BDPAlert = () => {
  const {showPostLeadAlert, isSuccessfulAlert, isOemAlert} = useAdditionalLeads();
  const {closeAdditionalLeadsAlert} = useBdpActions();

  return (
    <>
      <Alert isOpen={showPostLeadAlert}
        isSuccessful={isSuccessfulAlert}
        isOem={isOemAlert}
        closeModal={()=> closeAdditionalLeadsAlert()}
        content={ADDITIONAL_LEADS_ALERT_CONTENT}
      />
    </>
  );
};

export default BDPAlert;

import React, { PureComponent } from 'react';
import { ToolSet, ToolSetInner } from '../../../components/ToolSet';
import SortBy from '../SortBy';
import EnginesSortBy from '../EnginesSortBy';
import './styles.css';
import get from 'lodash/get';
import { generateSearchPath, getDefaultParams } from '../../../utils/urlHelpers/listings';
import * as utils from '../../../utils/scrollTo';
import { ENGINES_ID } from '../../../constants/Engines';

class SortSelector extends PureComponent {
  handleSortChange = (data) => {
    let urlParams = get(this.props, 'match.params', {});
    let params = getDefaultParams(urlParams, this.props.searchPage);
    const path = generateSearchPath({ sort: data }, params, true, this.props.searchPage);
    this.props.history.push(path);
    if (this.props.scrollTo) {
      utils.scrollTo(this.props.scrollTo, { behavior: 'smooth' });
    }
  };

  render() {
    return (
      <div id="sort" className="search-tools sort custom-select">
        <ToolSet>
          <ToolSetInner>
            {this.props.searchPage === ENGINES_ID ? (
              <EnginesSortBy
                initialState="closed"
                closeOnOuterClick={true}
                closeOnInnerClick={true}
                {...this.props}
                onDataChange={this.handleSortChange}
              />
            ) : (
              <SortBy
                initialState="closed"
                closeOnOuterClick={true}
                closeOnInnerClick={true}
                {...this.props}
                onDataChange={this.handleSortChange}
              />
            )}
          </ToolSetInner>
        </ToolSet>
      </div>
    );
  }
}

export default SortSelector;

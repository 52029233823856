import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Stylesheets
import './styles.css';

class ExpertReviewsTitle extends PureComponent {
  render() {
    const { title } = this.props;

    return <h3 data-test-sp="srp-related-articles-title" className="expert_reviews_title">{title}</h3>;
  }
}

ExpertReviewsTitle.propTypes = {
  title: PropTypes.string
};

export default ExpertReviewsTitle;

import React from 'react';

import DefaultLayout from '../../../../layouts/Default';
import BreadCrumb from '../../../../components/BreadCrumb';

import { SERVICES_URL } from '../../../../constants/services';

const History = (props) => (
  <DefaultLayout {...props} pageType="BoatHistory">
    <div className="service-content">
      <BreadCrumb items={[{
        title: 'Home',
        link: '/'
      }, {
        title: 'Services',
        link: SERVICES_URL
      }, {
        title: 'Boat History Report',
      }]} />
    </div>
    <div className="content nav-slide" id="boat-history">
      <section className="boat-history">
        <h1>BOAT HISTORY REPORT</h1>

        <div className="page-content">
          <img src={`${process.env.REACT_APP_CDN_URL}/img/boat-history-banner.png`} alt="Boat History Report"/>

          <div className="text">

            <div className="description">
              <div className="tagline">
                        Has the boat been in a... <em>Hurricane, Accident, Sinking?</em>
              </div>
              <p>Be sure to run a history report on every boat you&apos;re considering purchasing.
                        BoatHistoryReport.com is the first step you should take when looking to purchase a used
                        boat to help you discover costly hidden problems, accidents,
                        hurricane damage and other negative events. Get a Boat History Report from your dealer
                        or go to www.BoatHistoryReport.com.</p>
            </div>

            <div className="cta">
              <span className="tagline">Ready to get your <br/>boat history report?</span>

              <a target="_blank" href="http://www.boathistoryreport.com/main.cfm?f=partners&amp;partnerid=59334" className="link"
                rel="nofollow noopener">Get Started Now</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </DefaultLayout>
);

export default History;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from '../SEO/Link';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setExternalLinkImpression,
  setGenericEvent
} from '../../store/actions/dataLayer';

import './styles.css';

class ServicesItem extends PureComponent {

  componentDidMount() {
    let { setExternalLinkImpression, serviceName } = this.props;
    if (serviceName) {
      setExternalLinkImpression(serviceName, serviceName, 'service link');
    }
  }

  handleItemClick = () => {
    const { serviceType } = this.props;
    this.props.setGenericEvent('navigation-body', 'services', serviceType, '');
  }

  render() {
    let icon = this.props.serviceIcon;
    let { serviceName, rel, target } = this.props;

    return (
      <div className="service-item" onClick={this.handleItemClick}>
        <Link href={this.props.link} property="service-item" typeof="WebPage" data-reporting-external-link-id={serviceName} data-reporting-exit-link-id={serviceName} rel={rel} target={target}>
          <div className={classnames('icon', {[icon]: icon})}>
          </div>
          <div className="service-title">
            {this.props.serviceTitle}
          </div>
        </Link>
      </div>
    );
  }
}

ServicesItem.propTypes = {
  link: PropTypes.string.isRequired,
  serviceTitle: PropTypes.string.isRequired,
  serviceIcon: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired
};

export default connect(null, (dispatch) => bindActionCreators({
  setExternalLinkImpression,
  setGenericEvent
}, dispatch))(ServicesItem);

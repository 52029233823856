import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Breadcrumb } from '@dmm/lib-react-ui-components';
import { Hero } from '../shared/Hero';
import InfoSection from '../shared/InfoSection';
import { getBreakpoint } from '../../../../utils/commonHelper';
import DefaultLayout from '../../../../layouts/Default';
import {
  TRIDENT_SERVICES_CDN_URL,
  EXTENDED_SERVICES_PLAN_HERO_BACKGROUND,
  EXTENDED_SERVICES_PLAN_URL,
} from '../../../../constants/TridentServices';
import {
  FAQ_DISCLAIMER_TEXT,
  FAQ_SECTION_TITLE,
  FAQ_ACCORDION_CONTENT
} from '../../../../constants/TridentServices/extendedServicesFAQs';
import {
  TRACKING_EVENT_LEAD_SERVICES_SUBMISSION,
  TRACKING_EVENT_LEAD_SERVICES_SUBMIT,
  TRACKING_EVENT_LEAD_SERVICES_TOP_PAGE_SUBMIT,
  TRACKING_EVENT_LEAD_SERVICES_BOTTOM_PAGE_SUBMIT
} from '../../../../constants/BoatLoans';
import { FAQSection } from '../shared/FAQSection';
import RichSnippet from '../../../../components/RichSnippet';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import metadata from './ExtendedServicePlanFQAMeta.json';
import { extendedServicesInfo } from '../../../../constants/TridentServices/infoSections';
import './styles.css';

const ExtendedServicesPlanPage = (props) => {

  const { isLeadSubmitted } = props;

  const [isMobile, setIsMobile] = useState(getBreakpoint() === 'mobile');
  const [isReady, setIsReady] = useState(false);

  const backgroundImageUrl = `${ TRIDENT_SERVICES_CDN_URL }${ EXTENDED_SERVICES_PLAN_HERO_BACKGROUND }`;

  const handleResize = () => {
    setIsMobile(getBreakpoint() === 'mobile');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setIsReady(true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <RichSnippet schema={getFAQSchema(metadata)} />
      <DefaultLayout {...props} pageType="TridentExtendedServicePlan" hideOverflow>
        <div className="breadcrumb-container">
          <Breadcrumb
            items={[
              {
                text: 'Home',
                url: '/',
              },
              {
                text: 'Services',
              },
              {
                text: 'Extended Service Plan',
                url: EXTENDED_SERVICES_PLAN_URL,
              },
            ]}
          />
        </div>

        {isReady && (
          <Hero
            backgroundImageUrl={backgroundImageUrl}
            title={<>Extended Boat{isMobile && <br/>} Service Plans</>}
            text={<>Premium Boat Protection for{isMobile && <br/>} as Low as</>}
            price="$30/month"
            formTitle="Get Your Free Quote"
            leadPage="Extended Service Plan"
            isLeadSubmitted={isLeadSubmitted}
            trackingEvent={
              {
                'action_type': TRACKING_EVENT_LEAD_SERVICES_SUBMIT,
                'action_label': TRACKING_EVENT_LEAD_SERVICES_TOP_PAGE_SUBMIT,
                'event': TRACKING_EVENT_LEAD_SERVICES_SUBMISSION
              }
            }
          />
        )}

        <div className="main-section">
          <div className="main-extended-services-info">
            <InfoSection
              isMobile={isMobile}
              info={extendedServicesInfo}
              leadPage="Extended Service Plan"
              isLeadSubmitted={isLeadSubmitted}
              getProtectedGALinkId="get protected button service page"
              trackingEvent={
                {
                  'action_type': TRACKING_EVENT_LEAD_SERVICES_SUBMIT,
                  'action_label': TRACKING_EVENT_LEAD_SERVICES_BOTTOM_PAGE_SUBMIT,
                  'event': TRACKING_EVENT_LEAD_SERVICES_SUBMISSION
                }
              }/>
          </div>
          <div className="extended-services-faqs">
            <FAQSection
              pageTitle={FAQ_SECTION_TITLE}
              accordionItems={FAQ_ACCORDION_CONTENT}
              disclaimerText={FAQ_DISCLAIMER_TEXT}
            />
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};


// We will use state to Anti SPAM actions with the lead form
const mapStateToProps = (state) => {
  return {
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false)
  };
};

export default connect(
  mapStateToProps,
)(ExtendedServicesPlanPage);

import React, {PureComponent} from 'react';

class AlertMessage extends PureComponent {
  render() {
    return (
      <div className="alert">
        <div className="icon"></div>
        <div className="text">
          <span className="bold">{this.props.mainMessage}</span>
          <br/>
          {this.props.secondaryMessage}
        </div>
      </div>
    );
  }
}

export default AlertMessage;

import React from 'react';
import PropTypes from 'prop-types';
import {
  QUOTE_BUTTON,
  LEARN_BUTTON,
  APPLY_NOW_BUTTON,
  ESTIMATE_MY_RATE_BUTTON
} from '../../constants/services';

const LinkButton = (props) =>  {
  const {
    buttonType,
    buttonLink,
    rel,
    target,
    className,
    reportingAttributes
  } = props;

  return (
    <div className={className}>
      <a href={buttonLink} rel={rel} className="quote-button" target={target} {...reportingAttributes}>
        {buttonType}
      </a>
    </div>
  );
};

LinkButton.propTypes = {
  buttonType: PropTypes.oneOf([QUOTE_BUTTON, LEARN_BUTTON, APPLY_NOW_BUTTON, ESTIMATE_MY_RATE_BUTTON]).isRequired,
  buttonLink: PropTypes.string.isRequired,
  rel: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  reportingAttributes: PropTypes.instanceOf(Object)
};

LinkButton.defaultProps = {
  rel: '',
  target: '_blank',
  className: '',
  reportingAttributes: {}
};

export default LinkButton;

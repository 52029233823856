import React from 'react';

const DrivewayDirectProcess = ({getTrackingEvent, questions}) => {

  const scrollToTop = () => {
    getTrackingEvent('driveway direct', 'cta click');
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <>
      <div className={'drivewayDirect-process'}>
        <h2>How Driveway Direct Works</h2>
        <p>In three easy steps, you can get a fast offer from a dealer near you.</p>
        <div className={'container-step'}>
          <div className={'step'}>
            <div className={'step-icon'}>
              <img src={process.env.REACT_APP_CDN_URL + '/driveway/details_icon.svg'} alt=""/>
            </div>
            <h3>The Details</h3>
            <p>Share a few details about your boat and present it to a group of dealers purchasing in your area.</p>
          </div>
          <div className={'step'}>
            <div className={'step-icon'}>
              <img src={process.env.REACT_APP_CDN_URL + '/driveway/offer_icon.svg'} alt=""/>
            </div>
            <h3>The Offer</h3>
            <p>A dealer will present a cash offer for your boat after an on-site review.</p>
          </div>
          <div className={'step'}>
            <div className={'step-icon'}>
              <img src={process.env.REACT_APP_CDN_URL + '/driveway/deal_icon.svg'} alt=""/>
            </div>
            <h3>The Deal</h3>
            <p>After closing the deal, you will receive your funds from a dealer in your area!</p>
          </div>
        </div>
      </div>
      <div className={'drivewayDirect-comments'}>
        <h2>What our customers are saying</h2>
        <div className={'container-comments'}>
          <div className={'comment'}>
            <div className={'container-stars'}>
              <img className={'star'} src={process.env.REACT_APP_CDN_URL + '/driveway/star.svg'} alt={'star'}/>
              <img className={'star'} src={process.env.REACT_APP_CDN_URL + '/driveway/star.svg'} alt={'star'}/>
              <img className={'star'} src={process.env.REACT_APP_CDN_URL + '/driveway/star.svg'} alt={'star'}/>
              <img className={'star'} src={process.env.REACT_APP_CDN_URL + '/driveway/star.svg'} alt={'star'}/>
              <img className={'star'} src={process.env.REACT_APP_CDN_URL + '/driveway/star.svg'} alt={'star'}/>
            </div>
            <p>“This process was so simple, I plugged in my boat information and received an offer!”</p>
            <span>Scott J. | Miami, FL</span>
          </div>
        </div>
      </div>
      <div className={'drivewayDirect-faq'}>
        <h2>Frequently Asked Questions </h2>
        <div className={'container-questions'}>
          <div className={'questions-row-1'}>
            {questions.map(({question, answer}, idx) => (
              idx < (questions.length / 2) &&
              <div className={'wrapper-question'} key={idx}>
                <hr/>
                <h3>{question}</h3>
                <p>{answer}</p>
              </div>
            ))}
          </div>
          <div className={'questions-row-2'}>
            {questions.map(({question, answer}, idx) => (
              idx >= (questions.length / 2) &&
              <div className={'wrapper-question'} key={idx}>
                <hr/>
                <h3>{question}</h3>
                <p>{answer}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={'faq-footer'}>
          <p>
          Not all boats are eligible for Driveway Direct’s fast cash offer.
          The Offer may be less than published trade-in values or range.
          Boat Trader does not redeem Offers, purchase boats or own/operate Participating Dealers.
          Dealer inspection required. If the info you provide about the boat differs from the dealer inspection,
          the Offer amount may be decreased.
          Boat Trader and its affiliates are not responsible for Offer adjustments or if a Participating Dealer won’t accept your vehicle.
          Dealers pay a fee to participate in the Driveway Direct Fast Cash Offer program.</p>
        </div>
        <button className={'button-offer'} onClick={() => scrollToTop()}>Get My Cash Offer</button>
      </div>
    </>
  );
};

export default DrivewayDirectProcess;

import React from 'react';
import './styles.css';

const DealerRelatedArticles = () => {
  return (
    <section className="recent-articles-review" data-e2e="recent-articles-review">
      <h2>Dealer Related Articles</h2>
      <div className="articles" data-e2e="articles">
        <div onClick="location.href='/research/buyers-guide-to-brokers-and-dealers/'">
          <article data-e2e="article">
            <div data-e2e="image-container">
              <img loading="lazy" src="/research/files/working_with_boat_dealers_and_yacht_brokers.jpg" alt="Boat Dealers And Brokers: Tips For Boat Buyers" />
            </div>
            <div className="content" data-e2e="content">
              <div className="category" data-e2e="category">Category: buying</div>
              <div>
                <a className="title-link" data-e2e="title-link" href="/research/buyers-guide-to-brokers-and-dealers/">Boat Dealers And Brokers: Tips For Boat Buyers</a>
              </div>
              <div className="excerpt" data-e2e="excerpt">Captain Zuzana Prochazka provides helpful tips for dealing with boat dealers and yacht brokers.</div>
            </div>
          </article>
        </div>
        <div onClick="location.href='/research/boating-industrys-top-100-dealers/'">
          <article data-e2e="article">
            <div data-e2e="image-container">
              <img loading="lazy" src="/research/files/Top100Logo_2-e1513701132645.jpg" alt="Boating Industry’s Top 100 Dealers" />
            </div>
            <div className="content" data-e2e="content">
              <div className="category" data-e2e="category">Category: boating</div>
              <div>
                <a className="title-link" data-e2e="title-link" href="/research/boating-industrys-top-100-dealers/">Boating Industry’s Top 100 Dealers</a>
              </div>
              <div className="excerpt" data-e2e="excerpt">Boating Industry names the top 100 boat dealers in the country for 2021.</div>
            </div>
          </article>
        </div>
        <div onClick="location.href='/research/how-to-sell-a-boat/'">
          <article data-e2e="article">
            <div data-e2e="image-container">
              <img loading="lazy" src="/research/files/sold-boat-boat-trader.jpg" alt="How To Sell A Boat: The Complete Guide" />
            </div>
            <div className="content" data-e2e="content">
              <div className="category" data-e2e="category">Category: selling</div>
              <div>
                <a className="title-link" data-e2e="title-link" href="/research/how-to-sell-a-boat/">How To Sell A Boat: The Complete Guide</a>
              </div>
              <div className="excerpt" data-e2e="excerpt">Pro tips for selling your boat quickly for top dollar to the perfect buyer.</div>
            </div>
          </article>
        </div>
        <div onClick="location.href='/research/how-to-buy-boat-ultimate-boat-buying-guide/'">
          <article data-e2e="article">
            <div data-e2e="image-container">
              <img loading="lazy" src="/research/files/buying-selling-boats-photo-ryan-mcvinney-boat-trader.jpg" alt="How to Buy a Boat: The Complete Buying Guide"/>
            </div>
            <div className="content" data-e2e="content">
              <div className="category" data-e2e="category">Category: boating</div>
              <div>
                <a className="title-link" data-e2e="title-link" href="/research/how-to-buy-boat-ultimate-boat-buying-guide/">How to Buy a Boat: The Complete Buying Guide</a>
              </div>
              <div className="excerpt" data-e2e="excerpt">Looking to buy a boat? Read our comprehensive guide to boat buying.</div>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default DealerRelatedArticles;

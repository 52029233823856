import React, {useState} from 'react';
import {withCookies} from 'react-cookie';
import DefaultLayout from '../../../layouts/Default';
import BreadCrumb from '../../../components/BreadCrumb';
import './styles.css';
import * as AiHelper from '../../../utils/aiHelper';
import last from 'lodash/last';
import loader from './resources/loader.gif';
import {getListingReactUrlParams} from '../../../utils/urlHelpers/boat';
import {addResizeParams, getLocation, getPrice, getTitle, hasMedia} from '../../../utils/listingHelper';
import get from 'lodash/get';

const getTimeOfDay = (hourOfDay) => {
  return hourOfDay < 12 ?
    'morning' :
    hourOfDay < 18 ?
      'afternoon' :
      'evening';
};

const getGreetingText = (timeOfDayText) => {
  return `Good ${timeOfDayText}, I am your BoatTrader concierge, how can I help you today?`;
};

const normalizeMessage = ({ role, content, boatTraderUrl, boats }) => {
  return {
    role,
    content,
    boatTraderUrl,
    records: normalizeBoatRecords(boats)
  };
};

const normalizeBoatRecords = (boats) => {
  const records = boats?.records ?? [];

  return records
    .filter(hasMedia)
    .map(normalizeListing);
};

const normalizeListing = (listing) => {
  const portalLink = listing.portalLink ||
    listing.portalLinks?.find(pl => pl.portal === 'boattrader')?.url ||
    undefined;

  return {
    ...listing,
    portalLink,
    location: { address: listing.location },
    price: {
      hidden: listing.price.hide,
      type: { amount: { USD: listing.price.amount } }
    },
    media: listing.media.filter((m) => m.mediaType === 'image')[0]
  };
};

const animateScroll = () => {
  document.querySelector('.concierge-body-anchor').scrollIntoView({ behavior: 'smooth', block: 'end' });
};

const ConciergeContainer = (props) => {

  const {
    cookies,
    currentTime = new Date()
  } = props;

  const isAiActive = cookies.get('aiActive') === 'convo';

  const [isSearching, setIsSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [conversation, setConversation] = useState([{
    role: 'assistant',
    content: getGreetingText(getTimeOfDay(currentTime.getHours()))
  }]);

  const performSearch = async () => {
    setIsSearching(true);

    const userMsg = { role: 'user', content: searchText };

    setConversation([ ...conversation, userMsg ]);
    setSearchText('');

    setTimeout(animateScroll, 100);

    const result = await AiHelper.convoBoats([
      ...conversation.slice(1),
      userMsg
    ]);

    setConversation([
      ...conversation,
      userMsg,
      normalizeMessage(last(result.conversation))
    ]);

    setIsSearching(false);

    setTimeout(animateScroll, 100);
  };

  const title = 'Concierge';
  const description = 'Welcome to the first page dedicated to conversational AI.';
  const crumbs = [
    { title: 'Home', link: '/' },
    { title: 'Concierge', link: '/concierge' }
  ];

  return (
    <DefaultLayout { ...props } pageType={ title }>
      <BreadCrumb items={ crumbs } />
      <div id={ 'main-content' }>
        { isAiActive &&
          <div id={ 'concierge-page-content' }>
            <div>
              <h1>{ title }</h1>
              <div>{ description }</div>
            </div>
            <ConversationModal
              isLoading={isSearching}
              searchText={searchText}
              conversation={conversation}
              onSearchTextChange={(text) => setSearchText(text)}
              onSearchClick={() => performSearch()}
            />
          </div>
        }
      </div>
    </DefaultLayout>
  );

};

const ConversationModal = (props) => {

  const {
    isLoading,
    searchText,
    conversation,
    onSearchTextChange,
    onSearchClick
  } = props;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onSearchClick();
  };

  const handleSearchTextChange = (e) => {
    onSearchTextChange(e.target.value);
  };

  return (
    <div className="concierge">
      <div className="concierge-header">
        <img src="https://www.boattrader.com/static/app/boattrader-icon.png" alt="logo" />
        <h3>Natural Language Search<br/><i>powered by AI</i></h3>
      </div>

      <div className="concierge-body">
        <div>
          <div>
            {conversation.map((message, index) =>
              <ConciergeMessage message={message} key={index} />
            )}
            {isLoading && <img src={ loader } alt="loader"/>}
          </div>
          <div className="concierge-body-anchor"></div>
        </div>
      </div>

      <div className="concierge-footer">
        <form id="concierge-form" autoComplete="off" onSubmit={ handleFormSubmit }>
          <input id="ai-search-value" type="text" aria-label="ai-search" onChange={handleSearchTextChange} value={searchText} />
          <button type="submit" disabled={isLoading}>Submit</button>
        </form>
      </div>
    </div>
  );
};

const ConciergeMessage = (props) => {

  const {
    message
  } = props;

  return (
    <div className={ `concierge-message ${message.role}` }>
      <p>{message.content}</p>
      { message.records?.length > 0 &&
        <div className="concierge-message-records">
          { message.records.map((record, index) => (
            <a key={index} href={getListingReactUrlParams(record, false).href}>
              <img src={addResizeParams(get(record.media, 'original.url', record.media.url), { width: 118, height: 80 }, record.media.date.modified)} />
              <h3>{getTitle(record)}</h3>
              <h4>{getPrice(record)}</h4>
              <p>{getLocation(record, false, true)}</p>
            </a>
          ))
          }
        </div>
      }
      <ViewBoatsLink boatTraderUrl={message.boatTraderUrl} />
    </div>
  );
};

const ViewBoatsLink = ({ boatTraderUrl = '/' }) => {
  if (boatTraderUrl === '/') {
    return null;
  }

  return (
    <a href={boatTraderUrl} target={'_blank'} rel="noreferrer">See more of these Boats</a>
  );
};

export default withCookies(ConciergeContainer);

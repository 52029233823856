import { isFSBOContact } from '../listingHelper';
import { FEATURE_KEYS, findFeatureFlagActive } from '../experiment/kameleoonHelper';

const isSegmentCandidate = (listing) => {
  const isPrivateSeller = isFSBOContact(listing.contact);
  const { isOemModel } = listing;

  return isPrivateSeller && !isOemModel;
};

const isActive = (abTestConfig, listing) => {
  const featureFlag = findFeatureFlagActive(abTestConfig, FEATURE_KEYS.BOATS_BDP_BUYER_ACCOUNT_LOGIN);
  return featureFlag?.variation === 'on' && isSegmentCandidate(listing);
};

export const buyerAccountLoginExperiment = {
  isActive,
  isSegmentCandidate
};

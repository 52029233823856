/* eslint-disable */

import { capitalizeEachWord, formatNumber, formatPrice } from '@dmm/lib-common/lib/formatting';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isInteger from 'lodash/isInteger';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import { MAX_AGE_TO_PREQUALIFY, MIN_PRICE_TO_PREQUALIFY } from '../constants/BoatLoans';
import { BRANDS_ID, LISTING_ENHANCED, LISTING_MANUFACTURER, LISTING_STANDARD } from '../constants/boats';
import { DEFAULT_THUMBNAIL } from '../constants/home';
import { getStateName } from './../utils/locationMapperHelper';
import { getClass } from './commonHelper';
import { getFriendlyFeatureName } from './dataFeaturesHelper';
import { isSingleMake } from './seoContentHelper';
import { isSegmentCandidateForFinance } from './trident';

const _url = require('url');

//function used for "Boats Near You"
export const numberOfListingsPerRow = (width) => {
  if (width >= 1360){
    return 4;
  }
  else if (width >= 700){
    return 3;
  }
  else if (width >= 500){
    return 2;
  }
  return 1;
};

//function used for "Boats Near You"
//calculates the size of the image to be rendered so we don't load large images for small devices
export const calculateBestResizeParams = (width) => {
  const defaultSizeParams = { width: 480, height: 350 };
  const marginSize = 12;
  const heightRatio = defaultSizeParams.height / defaultSizeParams.width;
  if (!width){
    return defaultSizeParams;
  }
  const listingsPerRow = numberOfListingsPerRow(width);
  const usableWidth = width - marginSize * (listingsPerRow + 1);
  const calculatedWidth = parseInt(usableWidth / listingsPerRow);
  const calculatedHeight = parseInt(calculatedWidth * heightRatio);

  const result = { width: calculatedWidth, height: calculatedHeight};
  return result;
};

export const getTitle = (listing, bdp) => {
  if (listing.id) {
    if (bdp) {
      const year = shouldDisplayYear(listing) ? listing.year + ' ' : '';
      const model = get(listing, 'model', '');
      const make = get(listing, 'make', '');
      return `${year}${make} ${model}`;
    }
    return (shouldDisplayYear(listing) ? listing.year + ' ' : '') + listing.make + ' ' + get(listing, 'model', '');
  }
  return '';
};

export const getBoatAge = (listing) => {
  return isInteger(listing.year) ? new Date().getFullYear() - listing.year : 0;
};

const shouldDisplayYear = (listing) =>
  isInteger(listing.year) && (!listing.isOemModel || listing.year <= new Date().getFullYear() + 1);

const _extractElectronics = (data) => {
  let electronics = [];
  data.map((electronic) => {
    electronics.push(
      {
        title: getFriendlyFeatureName(electronic.code),
        value: (electronic.value) ? electronic.value : '✓'
      });
  });
  return electronics;
};

const _extractEquipements = (data) => {
  let equipements = [];
  data.map((equipement) => {
    equipements.push(
      {
        title: getFriendlyFeatureName(equipement.code),
        value: (equipement.value) ? equipement.value : '✓'
      });
  });
  return equipements;
};

const _extractRigging = (data) => {
  let riggings = [];
  data.map((rigging) => {
    riggings.push({title: getFriendlyFeatureName(rigging.code), value: (rigging.value) ? rigging.value : '✓'});
  });
  return riggings;
};

const _extractSails = (data) => {
  let sails = [];
  data.map((sail) => {
    sails.push({title: getFriendlyFeatureName(sail.code), value: (sail.value) ? sail.value : '✓'});
  });
  return sails;
};

export const getFeaturesData = (listing) => {
  if (listing.id && listing.features) {
    let features = [];
    let electronicsData = get(listing, 'features.electronics');
    let electricalEquipmentsData = get(listing, 'features.electricalEquipment');
    let insideEquipmentsData = get(listing, 'features.insideEquipment');
    let outsideEquipmentsData = get(listing, 'features.outsideEquipmentExtras');
    let additionalEquipmentsData = get(listing, 'features.additionalEquipment');
    let riggingData = get(listing, 'features.rigging');
    let sailsData = get(listing, 'features.sails');
    if (electricalEquipmentsData) {
      const electricalEquipments = _extractElectronics(electricalEquipmentsData);
      features.push({title: 'Electrical Equipment', value: electricalEquipments});
    }

    if (electronicsData) {
      const electronics = _extractElectronics(electronicsData);
      features.push({title: 'Electronics', value: electronics});
    }

    if (insideEquipmentsData) {
      const insideEquipments = _extractEquipements(insideEquipmentsData);
      features.push({title: 'Inside Equipment', value: insideEquipments});
    }

    if (outsideEquipmentsData) {
      const outsideEquipments = _extractEquipements(outsideEquipmentsData);
      features.push({title: 'Outside Equipment', value: outsideEquipments});
    }

    if (additionalEquipmentsData) {
      const additionalEquipment = _extractEquipements(additionalEquipmentsData);
      features.push({title: 'Additional Equipment', value: additionalEquipment});
    }

    if (riggingData) {
      const rigging = _extractRigging(riggingData);
      features.push({title: 'Rigging', value: rigging});
    }

    if (sailsData) {
      const sails = _extractSails(sailsData);
      features.push({title: 'Sails', value: sails});
    }
    return features;
  }
  return [];
};
export const getSocialTitle = (listing) => {
  if (listing.id) {
    return `${listing.condition} ${getTitle(listing)}`;
  }
  return '';
};

export const getPrice = (listing) => {
  return !listing.price?.hidden && listing.price?.type?.amount && listing.price?.type?.amount.USD > 1 ?
    (formatPrice(get(listing, 'price.type.amount.USD'), 'USD', 'en-US')) :
    'Request a Price';
};

export const getFirstListingImage = (listing) => {
  let listingMedia = get(listing, 'media', []);
  let images = listingMedia.filter((media) => {
    return media.mediaType === 'image';
  });
  return images[0];
};

export const getPriceAlternate = (listing) => {
  return !listing.price.hidden ?
    (formatPrice(get(listing, 'price.type.amount.USD'), 'USD', 'en-US')) :
    'Call for Price!';
};

export const getPriceBeforeDiscount = (listing) => {
  return !listing.price.hidden ?
    (formatPrice(get(listing, 'price.type.amount.USD') + Math.abs(get(listing, 'price.discount.amount.USD', 0)), 'USD', 'en-US')) :
    'Call for Price!';
};

export const getPriceCut = (listing) => {
  return formatPrice(Math.abs(get(listing, 'price.discount.amount.USD')), 'USD', 'en-US');
};

export const addResizeParams = (url, resize, modified, exact = true, format = 'webp') => {
  if (resize.width) {
    let timestamp = (modified ? new Date(modified) : new Date()).getTime();
    let params = _url.parse(url, true).query;
    if (isEmpty(modified) && !isEmpty(params)) {
      timestamp = get(params, 't', timestamp);
    }
    return  _stripQueryParams(url) + `?w=${resize.width}&h=${resize.height}&t=${timestamp}&format=${format}${exact ? '&exact' : ''}`;
  }
  return url;
};

export const addNotModifiedResizeParams = (url, resize, exact = true, format = 'webp') => {
  if (resize.width) {
    return  _stripQueryParams(url) + `?w=${resize.width}&h=${resize.height}&format=${format}${exact ? '&exact' : ''}`;
  }
  return url;
};

export const getThumbnailUrl = (thumbnail, lastUpdated, resize = {}) => {
  if (isNil(thumbnail)) {
    return `${process.env.REACT_APP_CDN_URL}${DEFAULT_THUMBNAIL}`;
  }

  return addResizeParams(thumbnail, resize, lastUpdated);
};

export const roundLength = (length) => {
  if (length > 999) {
    return 999;
  }
  return Math.round(length);
};

export const getHullParam = (value) => {
  return value.split('-').join(' ');
};

export const getFullCountry = (country) => {
  if (!country) {
    return country;
  }

  try {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return regionNames.of(country);
  } catch (e) {
    return country;
  }
};

export const getLocation = (listing, isLocationMap, fullState = false, fullCountry = false) => {
  const { city, zip } = get(listing, 'location.address', {});
  let { country, state } = get(listing, 'location.address', {});
  state = fullState && state ? capitalizeEachWord(getStateName(country, state)) : state;
  country = fullCountry && country !== 'US' ? getFullCountry(country) : country;
  if (zip && !isLocationMap) {
    return `${city}, ${(state || country)} ${zip}`;
  }
  if (city) {
    return `${city}, ${(state || country)}`;
  }
  if (state) {
    return state;
  }
  return country;
};

export const getWarrantyLabel = (listing) => {
  const warranty = get(listing, 'legal.hullWarranty');
  if (!warranty) {return;}
  const isMonth = last(warranty) === 'm';
  if (isMonth) {
    return warranty.replace('m', ' months');
  }
  const years = Number(warranty);
  if (years === 1) {
    return `${years} year`;
  }
  if (years === 99) {
    return 'Lifetime';
  }
  if (years > 0) {
    return `${years} years`;
  }
};

export const getDetailsData = (listing) => {
  let details = [];
  if (shouldDisplayYear(listing)) {
    details.push({title: 'Year', value: listing.year});
  }
  if (listing.make) {
    details.push({title: 'Make', value: listing.make});
  }
  if (listing.model) {
    details.push({title: 'Model', value: listing.model});
  }
  if (listing.class) {
    details.push({title: 'Class', value: getClass(listing.class).name});
  }
  if (listing.specifications && get(listing, 'specifications.dimensions.lengths.nominal.ft')) {
    let length = roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft')) + '\'';
    details.push({title: 'Length', value: length});
  }
  if (listing.fuelType) {
    details.push({title: 'Fuel Type', value: capitalizeEachWord(listing.fuelType)});
  }
  let address = get(listing, 'location.address');
  if (address) {
    let location = getLocation(listing);
    details.push({title: 'Location', value: location});
  }
  if (listing.hull) {
    if (listing.hull.material) {
      details.push({title: 'Hull Material', value: capitalizeEachWord(getHullParam(listing.hull.material))});
    }
    if (listing.hull.shape) {
      details.push({title: 'Hull Shape', value: capitalizeEachWord(getHullParam(listing.hull.shape))});
    }
  }
  const warrantyLabel = getWarrantyLabel(listing);
  if (warrantyLabel) {
    details.push({title: 'Hull Warranty', value: warrantyLabel});
  }
  const dealerName = get(listing, 'owner.rootName') || get(listing, 'owner.name');
  if (dealerName) {
    details.push({title: 'Offered By', value: dealerName});
  }
  return details;
};

export const getPriceData = (listing) => {
  let pricing = [];

  if (listing && listing.price) {
    if (listing.price.type && listing.price.type.baseAmount) {
      const baseAmount = Math.abs(get(listing, 'price.type.baseAmount.USD'));
      pricing.push({title: 'Base Price', value: formatPrice(baseAmount, 'USD', 'en-US')});
      if (listing.price.type.specialAmount) {
        const specialAmount = Math.abs(get(listing, 'price.type.specialAmount.USD'));
        const savings = baseAmount - specialAmount;
        if (savings > 0) {
          pricing.push({subtitle: 'Savings', value: formatPrice(baseAmount - specialAmount, 'USD', 'en-US')});
          pricing.push({title: 'Special Price', value: formatPrice(specialAmount, 'USD', 'en-US')});
        }
      }
    }
    if (listing.price.detail) {
      if (listing.price.detail['included-options']) {
        pricing.push({subtitle: 'Included Options', value: formatPrice(Math.abs(get(listing, 'price.detail["included-options"].USD')), 'USD', 'en-US')});
      }
      if (listing.price.detail.prep) {
        pricing.push({subtitle: 'Prep', value: formatPrice(Math.abs(get(listing, 'price.detail.prep.USD')), 'USD', 'en-US')});
      }
      if (listing.price.detail.freight) {
        pricing.push({subtitle: 'Freight', value: formatPrice(Math.abs(get(listing, 'price.detail.freight.USD')), 'USD', 'en-US')});
      }
    }
    if (listing.price.type && listing.price.type.amount) {
      pricing.push({title: 'Your Price', value: formatPrice(Math.abs(get(listing, 'price.type.amount.USD')), 'USD', 'en-US')});
    }
  }

  return pricing;
};

export const getPropulsionData = (listing) => {
  let details = [];
  if (listing.propulsion && !isEmpty(listing.propulsion.engines)) {
    if (listing.propulsion.engines.length > 1) {
      details = listing.propulsion.engines.map( (engine, index) => {
        return {title: `Engine ${index + 1}`, value: _extractPropulsionData(engine)};
      });
    } else {
      details = _extractPropulsionData(listing.propulsion.engines[0]);
    }
  }
  return details;
};

const _extractPropulsionData = (engine) => {
  let engineDetails = [];
  if (engine.make) {
    engineDetails.push({title: 'Engine Make', value: `${engine.make}`});
  }
  if (engine.make) {
    engineDetails.push({title: 'Engine Model', value: `${engine.model}`});
  }
  if (engine.year) {
    engineDetails.push({title: 'Engine Year', value: `${engine.year}`});
  }
  if (engine.power) {
    if (engine.power.hp) {
      engineDetails.push({title: 'Total Power', value: `${engine.power.hp}hp`});
    } else if  (engine.power.kW) {
      engineDetails.push({title: 'Total Power', value: `${engine.power.kW}kW`});
    }
  }
  if (engine.hours) {
    engineDetails.push({title: 'Engine Hours', value: `${engine.hours}`});
  }
  if (engine.category) {
    engineDetails.push({title: 'Engine Type', value: `${engine.category}`});
  }
  if (engine.driveType) {
    engineDetails.push({title: 'Drive Type', value: `${engine.driveType}`});
  }
  if (engine.location) {
    engineDetails.push({title: 'Location', value: `${engine.location}`});
  }
  if (engine.fuel) {
    engineDetails.push({title: 'Fuel Type', value: `${engine.fuel}`});
  }
  if (engine.ropeCutter) {
    engineDetails.push({title: 'Rope Cutter', value: true});
  }
  if (engine.propellerType) {
    engineDetails.push({title: 'Propeller Type', value: `${engine.propellerType}`});
  }
  if (engine.propellerMaterial) {
    engineDetails.push({title: 'Propeller Material', value: `${engine.propellerMaterial}`});
  }
  if (engine.foldingPropeller) {
    engineDetails.push({title: 'Folding Propeller', value: true});
  }
  return engineDetails;
};

export const getMeasurementsData = (listing) => {
  let details = [];

  const specs = get(listing, 'specifications', {});
  const equipment = get(listing, 'features.equipment', {});

  if (specs.speedDistance) {
    let speed = _extractSpeedAndDistanceData(specs.speedDistance);
    if (speed.length !== 0) {
      details.push({title: 'Speed & Distance', value: speed});
    }
  }
  if (specs.dimensions) {
    let dim = _extractDimensions(specs.dimensions);
    if (dim.length !== 0) {
      details.push({title: 'Dimensions', value: dim});
    }
  }
  if (specs.weights) {
    let weights = _extractWeights(specs.weights);
    if (weights.length !== 0) {
      details.push({title: 'Weights', value: weights});
    }
  }
  let misc = _extractMiscDimensions(specs,equipment);
  if (misc.length !== 0) {
    details.push({title: 'Miscellaneous', value: misc});
  }

  if (listing.tanks) {
    let tanks = _extractTanks(listing.tanks);
    if (tanks.length !== 0) {
      details.push({title: 'Tanks', value: tanks});
    }
  }

  if (specs.accommodation) {
    let accom = _extractAccomodations(specs.accommodation);
    if (accom.length !== 0) {
      details.push({title: 'Accommodations', value: accom});
    }
  }
  return details;
};

const _extractSpeedAndDistanceData = (data) => {
  let speed = [];
  if (data.cruisingSpeed) {
    speed.push({title: 'Cruising Speed', value: `${data.cruisingSpeed.kn}kn`});
  }
  if (data.maxHullSpeed) {
    speed.push({title: 'Max Speed', value: `${data.maxHullSpeed.kn}kn`});
  }
  if (data.range) {
    speed.push({title: 'Range', value: `${data.range.nmi}nm`});
  }
  return speed;
};

const _extractDimensions = (data) => {
  let dimensions = [];
  const nominal = get(data, 'lengths.nominal');
  const overall = get(data, 'lengths.overall');
  const deck = get(data, 'lengths.deck');
  const waterline = get(data, 'lengths.waterline');
  if (nominal) {
    dimensions.push({title: 'Nominal Length', value: `${nominal.ft}ft`});
  }
  if (overall) {
    dimensions.push({title: 'Length Overall', value: `${overall.ft}ft`});
  }
  if (deck) {
    dimensions.push({title: 'Length on Deck', value: `${deck.ft}ft`});
  }
  if (data.maxBridgeClearance) {
    dimensions.push({title: 'Max Bridge Clearance', value: `${data.maxBridgeClearance.ft}ft`});
  }
  if (data.maxDraft) {
    dimensions.push({title: 'Max Draft', value: `${data.maxDraft.ft}ft`});
  }
  if (data.freeboard) {
    dimensions.push({title: 'Freeboard', value: `${data.freeboard.ft}ft`});
  }
  if (data.beam) {
    dimensions.push({title: 'Beam', value: `${data.beam.ft}ft`});
  }
  if (data.cabinHeadroom) {
    dimensions.push({title: 'Cabin Headroom', value: `${data.cabinHeadroom.ft}ft`});
  }
  if (waterline) {
    dimensions.push({title: 'Length at Waterline', value: `${waterline.ft}ft`});
  }
  return dimensions;
};

const _extractWeights = (weights) => {
  let weight = [];
  if (weights.dry) {
    let formattedDry = formatNumber(weights.dry.lb, 'en-US');
    weight.push({title: 'Dry Weight', value: `${formattedDry}Lb`});
  }
  return weight;
};

const _extractMiscDimensions = (specs,equipment) =>{
  let miscellaneous = [];

  const seats = get(specs, 'capacity.seatingCapacity');
  const windlass = get(equipment, 'windlass');
  const liferaft = get(specs, 'capacity.liferaftCapacity');
  const deadrise = get(specs, 'dimensions.deadriseAtTransom');
  const circuit = get(equipment, 'electricalCircuit');
  if (seats) {
    miscellaneous.push({title: 'Seating Capacity', value: seats});
  }
  if (windlass) {
    miscellaneous.push({title: 'Windlass', value: windlass});
  }
  if (liferaft) {
    miscellaneous.push({title: 'Liferaft Capacity', value: liferaft});
  }
  if (deadrise) {
    miscellaneous.push({title: 'Deadrise At Transom', value: `${deadrise.value}${deadrise.unit}`});
  }
  if (circuit) {
    miscellaneous.push({title: 'Electrical Circuit', value: circuit});
  }
  return miscellaneous;
};

export const formatMoreDetailsDescriptions = (descriptions) => {
  if (descriptions) {
    return descriptions.filter(desc => desc.visibility === 'public').map(description => {
      return {title: description.title, value: description.description};
    });
  }
};

const _describeTank = (capacity, quantity) => {
  if (capacity) {
    if (quantity) {
      return `${quantity} × ${capacity.gal}gal`;
    }
    return `${capacity.gal}gal`;
  }
  return `${quantity}`;
};

const _extractTanks = (tank) => {
  let tanks = [];

  const freshwaterCapacity = get(tank, 'freshWater[0].capacity');
  const freshwaterQuantity = get(tank, 'freshWater[0].quantity');
  const freshwaterMaterial = get(tank, 'freshWater[0].tankMaterial');
  const fuelCapacity = get(tank, 'fuel[0].capacity');
  const fuelQuantity = get(tank, 'fuel[0].quantity');
  const fuelMaterial = get(tank, 'fuel[0].tankMaterial');
  const holdingCapacity = get(tank, 'holding[0].capacity');
  const holdingQuantity = get(tank, 'holding[0].quantity');
  const holdingMaterial = get(tank, 'holding[0].tankMaterial');

  if (freshwaterCapacity || freshwaterQuantity) {
    tanks.push({title: 'Fresh Water Tanks', value: _describeTank(freshwaterCapacity, freshwaterQuantity)});
  }
  if (freshwaterMaterial) {
    tanks.push({title: 'Fresh Water Tank Material', value: freshwaterMaterial});
  }
  if (fuelCapacity || fuelQuantity) {
    tanks.push({title: 'Fuel Tanks', value: _describeTank(fuelCapacity, fuelQuantity)});
  }
  if (fuelMaterial) {
    tanks.push({title: 'Fuel Tank Material', value: fuelMaterial});
  }
  if (holdingCapacity || holdingQuantity) {
    tanks.push({title: 'Holding Tanks', value: _describeTank(holdingCapacity, holdingQuantity)});
  }
  if (holdingMaterial) {
    tanks.push({title: 'Holding Tank Material', value: holdingMaterial});
  }

  return tanks;
};

const _extractAccomodations = (accommodation) => {
  let accommodations = [];
  if (accommodation.singleBerths) {
    accommodations.push({title: 'Single Berths', value: accommodation.singleBerths});
  }
  if (accommodation.doubleBerths) {
    accommodations.push({title: 'Double Berths', value: accommodation.doubleBerths});
  }
  if (accommodation.twinBerths) {
    accommodations.push({title: 'Twin Berths', value: accommodation.twinBerths});
  }
  if (accommodation.cabins) {
    accommodations.push({title: 'Cabins', value: accommodation.cabins});
  }
  if (accommodation.heads) {
    accommodations.push({title: 'Heads', value: accommodation.heads});
  }
  return accommodations;
};

const _stripQueryParams = (url) => {
  return url.split('?')[0];
};

export const getPrePopulatedText = (listing, messageType = '') => {
  switch (messageType) {
  case 'description':
    return `I'd like to receive more information about your ${getTitle(listing)}.`;
  case 'measurements':
    return `I'd like to receive more specs or features of your ${getTitle(listing)}.`;
  case 'propulsion':
    return 'Please share more information regarding the boat engine(s).';
  case 'location':
    return `I'd like to schedule a visit or sea trial with the ${getTitle(listing)}.`;
  default: return `I'm interested in getting more information about your ${getTitle(listing)}. Please contact me.`;
  }
};

export const getMediaImages = (listing) => {
  const listingMedia = get(listing, 'media', []);
  return listingMedia.filter((item) => {
    return item.mediaType === 'image';
  });
};

export const getMediaVideos = (listing) => {
  const listingMedia = get(listing, 'media', []);
  return listingMedia.filter((item) => {
    return _isYouTubeVideo(item) || item.mediaType === 'video';
  });
};

const _isYouTubeVideo = (item) => {
  if (item.mediaType === 'external') {
    let videoUrl = get(item, 'videoEmbedUrl') || '';
    var regExp = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/;
    return videoUrl.match(regExp);
  }
};

export const isFSBOContact = (contact) => {
  return get(contact, 'name') === 'Private Seller';
};

export const isFSBOorOEMListing = (listing) => {
  return listing && (listing.isOemModel || isFSBOContact(listing.contact));
};

export const getSanitisedDealerName = (owner) => {
  let dealerName = '';
  let name = owner.name;
  if (owner.rootName && !name.includes(owner.rootName)) {
    dealerName = owner.rootName + ' - ';
  }
  dealerName += name;
  return dealerName;
};

export const cleanExternalHREF = (url) => {
  if (url.startsWith('http')) {
    return url;
  }
  return `//${url}`;
};

export const removeAdsForBrandedSrp = (listings, isBranded) => {
  if (!isBranded){
    return listings;
  }
  const filteredListings = listings.filter(listing => !get(listing, 'featureType.enhanced', false) && !get(listing, 'featureType.sponsored', false));
  return filteredListings;
};


export const injectOemListingsRandomly = (listingsArray, oemListings) => {
  const oemListingsToInject = oemListings.filter(a => listingsArray.filter(b => b.id === a.id).length === 0);
  if (oemListingsToInject.length > 0){
    oemListingsToInject.forEach(element => {
      element.oemPosition = true;
      const random = (listingsArray.length + 1) * Math.random();
      listingsArray.splice(random | 0, 0, element);
    });
  }
};

export const getListingType = (listing) => {
  if (listing.isOemModel){
    return LISTING_MANUFACTURER;
  }
  else if (get(listing, 'featureType.enhanced', false)){
    return LISTING_ENHANCED;
  }
  return LISTING_STANDARD;
};

export const setDataReportingClickListingType = ({listing, isSponsored, dealerId, clickListingType  }) => {
  if (isSponsored) {
    return clickListingType.LISTING_SPONSORED;
  }
  return (dealerId ? clickListingType.LISTING_DEALER : get(listing, 'featureType.enhanced') ? clickListingType.LISTING_ENHANCED : clickListingType.LISTING_STANDARD);
};

export const canPrequalify = (listing) => {

  const price = getPrice(listing);
  const boatAge = getBoatAge(listing);
  if (price === 'Request a Price') {
    return false;
  }

  const priceAsNumber = Number(price.replace(/[^0-9.-]+/g, ''));
  if (priceAsNumber < MIN_PRICE_TO_PREQUALIFY || boatAge > MAX_AGE_TO_PREQUALIFY) {
    return false;
  }

  return true;
};

export const getEnhancedLogo = (listing) => {
  let logo = listing?.owner?.logos?.enhanced;
  return logo?.replace(/^http:\/\//, 'https://') || '';
};

export const hasMedia = (listing) => {
  return listing.media?.some(m => m.mediaType === 'image');
};

const parseOemDetails = (oemDetails) => {
  return {
    brandedSearch: oemDetails.brandedSearch,
    logoUrl: oemDetails.logoUrl,
    oemHeroImageUrl: oemDetails.oemHeroImageUrl,
    name: oemDetails.name,
    id: oemDetails.brandId,
    externalLinks: oemDetails.externalLinks,
  };
};

const parseBrandDetails = (brandDetails) => {
  const images = brandDetails?.images;
  return {
    brandedSearch: brandDetails.brandedSearch,
    oemHeroImageUrl: images?.heroImageUrl,
    logoUrl: images?.logoUrl,
    name: '',
    id: null,
    externalLinks: []
  };
};

export const getBrandDetailsData = (oemDetails, brandDetails, searchPage) => {
  if (brandDetails && searchPage !== BRANDS_ID) {
    return parseBrandDetails(brandDetails);
  }
  if (oemDetails) {
    return parseOemDetails(oemDetails);
  }
  return {
    brandedSearch: false
  };
};

export const getBoatDetails = (listing) => {
  const engineData = listing?.propulsion?.engines && listing.propulsion.engines.length > 0 ? listing.propulsion.engines[0] : null;
  const capacity = listing?.specifications?.capacity?.seatingCapacity;
  const engineName = engineData?.make && engineData?.model ? `${engineData.make} ${engineData.model}` : engineData?.make || engineData?.model;
  const length = listing?.specifications?.dimensions?.lengths?.nominal?.ft;

  let totalPower = 0;
  const engineHours = engineData?.hours || 0;
  listing?.propulsion?.engines?.forEach(engine => {
    totalPower += engine.power?.hp || 0;
  });

  const moreThanOneEngine = listing?.propulsion?.engines?.length > 1;
  const tooltip = moreThanOneEngine ? 'This boat has multiple engines. Please see propulsion details for more info.' : undefined;
  const engineHoursTitle = moreThanOneEngine ? 'Engine(s) Hours' : 'Engine Hours';

  return [
    { icon: 'engine', title: 'Engine', content: engineName },
    { icon: 'power', title: 'Total Power', content: totalPower ? `${totalPower}hp` : undefined },
    { icon: 'engineHours', title: engineHoursTitle, content: engineHours || undefined, tooltip },
    { icon: 'boatClass', title: 'Class', content: getClass(listing?.class) ? getClass(listing.class).name : undefined },
    { icon: 'length', title: 'Length', content: length ? `${roundLength(length)}ft` : undefined },
    { icon: 'year', title: 'Year', content: listing?.year },
    { icon: 'boatModel', title: 'Model', content: listing?.model },
    { icon: 'capacity', title: 'Capacity', content: capacity && `${capacity} people` }
  ];
};


export const showOemHeaderForPayingBrandsSRP = (currentPage, makeModel, brandInformation, url) => {
  const displayBrand = brandInformation.brandedSearch && brandInformation.logoUrl && brandInformation.oemHeroImageUrl;
  return currentPage === 1 && makeModel && displayBrand && isSingleMake(url);
};

export const formatMediaItems = (mediaImages, mediaVideos, fullListingName, resize, addSources) => {
  const formattedMediaImages = mediaImages.map( ({ title, original, url, date }) => {
    if (addSources) {
      const sources = [
        {media: '(min-width: 970px)', srcset: addResizeParams(original ? original.url : url, {width: 890, height: 500}, date.modified, true)},
        {media: '(min-width: 750px)', srcset: addResizeParams(original ? original.url : url, {width: 969, height: 450}, date.modified, true)},
        {media: '(min-width: 430px)', srcset: addResizeParams(original ? original.url : url, {width: 749, height: 320}, date.modified, true)}
      ];

      return {
        alt: title || fullListingName,
        src: addResizeParams(original ? original.url : url, {width: 429, height: 280}, date.modified, true),
        sources: sources
      };
    }

    const src = addResizeParams(original ? original.url : url, resize, date.modified, false);
    const srcset = `
        ${addResizeParams(original ? original.url : url, {width: 429, height: 280}, date.modified)} 430w,
        ${addResizeParams(original ? original.url : url, {width: 969, height: 450}, date.modified)} 969w,
        ${addResizeParams(original ? original.url : url, {width: 890, height: 500}, date.modified)} 
      `;
    const sizes = `
        (max-width: 430px) 430px,
        (max-width: 969px) 969px
      `;

    return {
      alt: title || fullListingName,
      src,
      srcset,
      sizes
    };
  });

  const formattedMediaVideos = mediaVideos.map(mediaVideo => ({
    title: mediaVideos.title || fullListingName,
    src: mediaVideo.videoEmbedUrl?.replace(/\/v\//i, '/embed/'),
    mediaType: mediaVideo.mediaType,
    overlay: true,
    poster: mediaVideo.videoVideoThumbnailUri,
  }));

  const formattedMediaThumbnailImages = mediaImages.map( ({ title, original, url, date }) => {
    const src = addResizeParams(original ? original.url : url, { width: 175, height: 116 }, date.modified, false);

    return {
      alt: title || fullListingName,
      src,
    };
  });

  let mediaItems = [];
  let mediaThumbnailItems = [];

  if (formattedMediaVideos.length > 0 && formattedMediaImages.length > 0) {
    const [firstImage, ...otherImages] = formattedMediaImages;
    const [firstThumbnailImage, ...otherThumbnailImages] = formattedMediaThumbnailImages;
    mediaItems = [firstImage, ...formattedMediaVideos, ...otherImages];
    mediaThumbnailItems = [firstThumbnailImage, ...formattedMediaVideos, ...otherThumbnailImages];

  } else if (formattedMediaVideos.length > 0) {
    mediaItems = formattedMediaVideos;
    mediaThumbnailItems = formattedMediaVideos;

  } else {
    mediaItems = formattedMediaImages;
    mediaThumbnailItems = formattedMediaThumbnailImages;
  }

  return { formattedMediaImages, formattedMediaVideos, mediaItems, mediaThumbnailItems };
};

export const normalizeLogoPath = (url) => {
  if (!url) { return; }
  return `${process.env.REACT_APP_IMG_HOST}/resize${url.replace('/resize/', '/')}`;
};

export const normalizeResizeHost = (url) => {
  const domains = ['boatsgroup.com', 'boattrader.com'];
  const regex = new RegExp(`(${domains.join('|')})/images`, 'g');
  return url?.replace(regex, '$1/resize');
};

export const getResizedImageSrc = (imgDetails, width, height) => {
  const resizeParams = `?w=${width}&h=${height}&format=webp`;
  const logo = normalizeLogoPath(imgDetails?.logoPath) || normalizeResizeHost(imgDetails?.logoUrl);
  return logo && logo + resizeParams;
};

export const LOGO_SIZES = {
  brandedHeader: { w: 680, h: 354 },
  dealerListLogo: { w: 400, h: 340 },
  listingCardLogo: { w: 244, h: 92 },
  emailLeadFormLogo: { w: 668, h: 240 }
};


export const showBoatDetails = (listing, breakpoint) => {

  const financeable = isSegmentCandidateForFinance(listing);
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;

  return ((
    (listing.condition === 'new' && listing.featureType?.enhanced === true && listing.featureType?.sponsored === true) ||
    (listing.condition === 'used' && listing.featureType?.enhanced === false && listing.featureType?.sponsored === false && hideTridentFeatures)
  )
  && !financeable)
  ||
  breakpoint === 'desktop';
};

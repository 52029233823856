import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import React, { Children, PureComponent } from 'react';
import { ToolSetRadioGroup, ToolSetRadioItem } from './ToolSetRadio';
import './styles.css';

class ToolSet extends PureComponent {

  render() {
    let { open, children, id } = this.props;

    return (
      <div id={id} className={classnames('tool-set', { open })}>
        {children}
      </div>
    );
  }
}

class ToolSetInner extends PureComponent {

  render() {
    let { id, children } = this.props;

    return (
      <div id={id} className="inner">
        {children}
      </div>
    );
  }
}

class ToolSetOptions extends PureComponent {

  render() {
    let { children, filterText = '' } = this.props;
    return (
      <ul className="opts">
        {filterText ? Children.map(children, child => {
          //if there is filter text passed down as a prop
          //pass the filter value down to the ToolSetOptionsItems
          if (child.type.displayName === 'ToolSetOptionsItem') {
            let filtered = !child.props.value.toLowerCase().includes(filterText.toLowerCase());
            return React.cloneElement(child, {
              filtered
            });
          }
          return child;

        }) : children}
      </ul>
    );
  }
}

ToolSetOptions.displayName = 'ToolSetOptions';

class ToolSetOptionsItem extends PureComponent {

  render() {
    let { children, id, name, value, trackingData, trackingDataExtra, selected, filtered, type = 'radio', onClick, disabled, optionClass = '' } = this.props;
    return (
      <li className={classnames({ filtered, selected }, optionClass)}>
        <label htmlFor={id} className="visuallyhidden">
          <span className="txt">{name}</span>
        </label>
        <input id={id} type={type} disabled={disabled} checked={selected}
          value={value} name={name} data-tracking-data={trackingData}
          data-tracking-extra-data={trackingDataExtra} onChange={() => onClick(value)} />
        <div data-custom-option={id} className={classnames('custom-option', { selected })} tabIndex="-1" onClick={() => onClick(value)}>{children}</div>
      </li>
    );
  }
}

ToolSetOptionsItem.displayName = 'ToolSetOptionsItem';

class ToolSetTypeAhead extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(text) {
    this.setState({
      text
    });
  }

  render() {
    let { children } = this.props;
    return (
      <div className="type-ahead">{Children.map(children, child => {
        if (child.type.displayName === 'ToolSetOptions') {
          //if the child is a ToolSetOptions component,
          //pass down the filter text
          return React.cloneElement(child, {
            filterText: this.state.text
          });
        } else if (child.type.displayName === 'ToolSetTextInput') {
          //if the child is a ToolSetTextInput component,
          //add an onChange prop to detect the value of the filter text
          return React.cloneElement(child, {
            onChange: this.handleChange
          });
        }
        return child;

      })}</div>
    );
  }
}

class ToolSetTextInput extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
  }

  handleDataChange(text){
    if (this.props.debounce){
      this.debouncedOnDataChange(text);
    } else {
      this.onChange(text);
    }
  }

  onChange(text) {
    this.props.onChange(text);
    this.setState({
      value: text
    });
  }

  UNSAFE_componentWillReceiveProps(props){
    let { value } = props;
    if (value !== undefined) {
      this.setState({
        value
      });
    }
  }

  debouncedOnDataChange = debounce((data) => this.onChange(data), 500);

  onChangeFilter = (e) => {
    this.handleDataChange(e.target.value);
  }

  render() {
    let { id, name, type = 'text', min, max, placeholder = '', icon } = this.props;
    let { value } = this.state;
    return (
      <div className={classnames('filter-txt', { 'filter-txt-icon': icon })}>
        <input title={placeholder} data-e2e="srp-filter-input" className="filter-input" type={type} id={id} placeholder={placeholder} min={min} max={max} name={name} value={value} onChange={this.onChangeFilter} />
      </div>
    );
  }
}

ToolSetTextInput.displayName = 'ToolSetTextInput';

class ToolSetButtonTray extends PureComponent {
  render() {
    let { children, hidden } = this.props;
    return (
      <div className={classnames('buttons', { hidden })}>
        <div className="btn-container">
          {children}
        </div>
      </div>
    );
  }
}

const ToolSetHeader = (props) => {
  const {onClick } = props;
  return (
    <>
      <div className="tool-set-header">
        <h2 className="tool-set-header-title">Filters</h2>
        <button className="tool-set-btn-close" type="button" aria-label="tool-set-header" onClick={onClick}></button>
      </div>
    </>
  );
};

const ToolSetSearchButton = (props) => {
  const { onClick, isBranded } = props;

  let buttonText = 'Search';
  /* istanbul ignore next */
  if (typeof window !== 'undefined') {
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/boats')) {
      buttonText = 'View boats';
    } else if (currentPath.startsWith('/engines')) {
      buttonText = 'View engines';
    }
  }

  return (
    <Action
      className={ classnames('tool-set-btn-search', {'tool-set-btn-search-branded': isBranded}) }
      onClick={onClick}
      label={buttonText}
    />
  );
};

export {
  ToolSet, ToolSetButtonTray, ToolSetHeader, ToolSetInner,
  ToolSetOptions,
  ToolSetOptionsItem, ToolSetRadioGroup,
  ToolSetRadioItem, ToolSetSearchButton, ToolSetTextInput, ToolSetTypeAhead
};


import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import has from 'lodash/has';
import * as nextPreviousActions from '../../store/actions/nextPrevious';
import * as utils from '../../store/utils';
import { getBoatUrl } from '../../utils/urlHelpers/boat';

const NextPreviousDataProvider = (props) => {
  const { location, listing, navLinks, setNextPrevious, storage } = props;

  useEffect(() => {
    if (!utils.isServer()) {
      updateNextPreviousLinks(0,1);
    }
  }, []);

  useEffect(() => {
    const url = get(location, 'pathname', '');
    if (!utils.isServer()) {
      if (!urlRef.current) {
        urlRef.current = url;
        updateNextPreviousLinks(1,2);
        return;
      }
      if (url !== urlRef.current) {
        urlRef.current = url;
        updateNextPreviousLinks(1,2);
      }
    }
  }, [location, navLinks]);

  const urlRef = useRef(null);

  const getInitialRelatedListing = (listing) => {
    let relatedListing = {
      id: get(listing, 'id'),
      type: get(listing, 'type'),
      class: get(listing, 'class'),
      make: get(listing, 'make'),
      model: get(listing, 'model'),
      length: get(listing, 'specifications.dimensions.lengths.nominal.ft')
    };

    return relatedListing;
  };

  const locateNextPreviousData = (currentUrl, isOemListing) => {
    let nextPreviousLocalData = storage.getLocalItem('nextPreviousData');
    let nextPreviousSessionData = storage.getSessionItem('nextPreviousData');

    if (nextPreviousSessionData) {
      nextPreviousSessionData = JSON.parse(nextPreviousSessionData);
    }

    if (nextPreviousLocalData) {
      nextPreviousLocalData = JSON.parse(nextPreviousLocalData);
    }

    if (nextPreviousSessionData && nextPreviousSessionData.urls.indexOf(currentUrl) > -1 ) {
      if (nextPreviousLocalData) {
        nextPreviousSessionData.searchUrl = nextPreviousLocalData.searchUrl;
      }

      return nextPreviousSessionData;
    }

    if (nextPreviousSessionData && isOemListing && nextPreviousSessionData.oemUrls.indexOf(currentUrl) > -1 ) {
      if (nextPreviousLocalData) {
        nextPreviousSessionData.searchUrl = nextPreviousLocalData.searchUrl;
      }

      return nextPreviousSessionData;
    }

    if (nextPreviousLocalData && nextPreviousLocalData.urls.indexOf(currentUrl) > -1 ) {
      storage.setSessionItem('nextPreviousData', JSON.stringify(nextPreviousLocalData));
      return nextPreviousLocalData;
    }

    if (nextPreviousLocalData && isOemListing && nextPreviousLocalData.oemUrls.indexOf(currentUrl) > -1 ) {
      storage.setSessionItem('nextPreviousData', JSON.stringify(nextPreviousLocalData));
      return nextPreviousLocalData;
    }
  };

  const updateNextPreviousLinks = async (lowIndex, highIndexOffset) => {
    let navLinks = {};
    const currentUrl = get(location, 'pathname', '');
    const isOemListing = !!get(listing, 'oemDetails');
    let nextPreviousData = locateNextPreviousData(currentUrl, isOemListing);
    let currentIndex;
    let listingUrl;

    if (nextPreviousData && has(nextPreviousData, 'searchUrl')) {
      navLinks.searchUrl = nextPreviousData.searchUrl;
      await nextPreviousActions.checkGetNextUrls(storage, nextPreviousData, lowIndex, highIndexOffset, currentUrl, undefined, isOemListing);
      listingUrl = currentUrl;
    } else if (listing) {
      if (nextPreviousData) {
        await nextPreviousActions.checkGetNextUrls(storage, nextPreviousData, lowIndex, highIndexOffset, currentUrl, true, isOemListing);
      } else {
        let initialRelatedListing = getInitialRelatedListing(listing);

        nextPreviousData = {
          searchParams: {},
          pageLow: 1,
          initialBoatUrl: currentUrl,
          relatedListing: initialRelatedListing,
          urls: [currentUrl]
        };

        storage.setSessionItem('nextPreviousData', JSON.stringify(nextPreviousData));

        await nextPreviousActions.getNextRelatedUrls(storage, nextPreviousData, initialRelatedListing, isOemListing);
        const locatedNextPreviousData = locateNextPreviousData(currentUrl, isOemListing);
        if (locatedNextPreviousData) {
          navLinks.searchUrl = locatedNextPreviousData.searchUrl;
        }
      }
      listingUrl = getBoatUrl(listing);
    }

    const urls = isOemListing ? nextPreviousData?.oemUrls : nextPreviousData?.urls;
    if (urls) {
      currentIndex = urls.indexOf(listingUrl);
      updateNavLinks(currentIndex, navLinks, nextPreviousData, isOemListing);
    }
  };

  const updateNavLinks = (currentIndex, navLinks, nextPreviousData, isOemListing) => {
    if (currentIndex === -1) {
      setNextPrevious({});
      return;
    }
    const urls = isOemListing ? nextPreviousData.oemUrls : nextPreviousData.urls;
    if (currentIndex > 0) {
      navLinks.previous = urls[currentIndex - 1];
    }
    let next = urls[currentIndex + 1];
    if (next) {
      navLinks.next = next;
    }
    setNextPrevious(navLinks);
  };

  return null;
};

const mapDispatchToProps = dispatch => ({
  setNextPrevious: bindActionCreators(nextPreviousActions.setNextPrevious, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(NextPreviousDataProvider);

import React from 'react';

import './styles.css';
import { Ad } from '@dmm/react-common-components';
import LegacyListingResult from '../LegacyListingResult';
import LeaderBoardMid from '../Ads/LeaderBoardMid';
import MobileLiner from '../Ads/MobileLiner';
import NativeAdDesk from '../Ads/NativeAdDesk';
import NativeAdMobile from '../Ads/NativeAdMobile';
import { setPosition } from '../../utils/paginationHelper';
import { isLaptopLResolution, isMobileResolution } from '../../utils/commonHelper';
import { removeAdsForBrandedSrp } from '../../utils/listingHelper';
import get from 'lodash/get';

/*This component is only used for Engines SRP */
class LegacyListingList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      contactForm: {
        type: '',
        form: undefined
      },
      window: false
    };
  }

  componentDidMount() {
    this.setState({
      window: window
    });
  }

  addContactForm(contactForm) {
    this.setState({
      contactForm: contactForm
    });
  }

  getContactForm(listingId) {
    if (get(this.state.contactForm.form, 'props.children.props.listingId', 0) === listingId) {
      return this.state.contactForm;
    }

    return {
      type: '',
      form: undefined
    };
  }

  render() {
    const AD_POSITION = 6;
    const LEADERBOARD_ALPHA_POSITION = 2;
    const MAX_DESKTOP_ADS = this.props?.maxDesktopAds || 2;
    const NATIVE_AD_POSITION = 16;
    const MOBILE_LEADERBOARD_CUSTOM_POSITION = 1;
    let itemCount = 0;
    let desktopAdCount = this.props?.desktopCount || 0;
    let mobileAdCount = this.props?.mobileCount || 0;
    let {
      noAdsParam,
      myboats,
      cookies,
      url,
      pos,
      sponsoredAdsParams,
      leaderBoardAlphaParams,
      dealerId,
      tridentTeaserRate,
      ficoScores,
      isLeadSubmitted
    } = this.props;
    let listingArr;
    const listings = removeAdsForBrandedSrp(this.props.listings, this.props.isBrandedSrp);
    return (
      <ol className="boat-list">
        {this.state.contactForm.form}
        {
          listings.map((listing, i) => {
            itemCount += 1;
            let position = setPosition(pos, i + 1);
            const listingResultProperties = {
              listing: listing,
              position,
              myboats,
              cookies,
              url,
              itemCount,
              dealerId,
              dataLayer: this.props?.dataLayer
            };
            if (this.props.isBrandedSrp) {
              listingResultProperties.oemDetails = this.props.oemDetails;
              listingResultProperties.handleContactDealer = this.props.handleFindDealerButtonClick;
              listingResultProperties.isBrandedSrp = this.props.isBrandedSrp;
            }
            if (this.props.isEngine) {
              listingResultProperties.isEngine = this.props.isEngine;
            }
            listingArr = [
              <LegacyListingResult
                key={listing.id}
                addContactForm={(contactForm) => this.addContactForm(contactForm)}
                contactForm={this.getContactForm(listing.id)}
                tridentTeaserRate={tridentTeaserRate}
                ficoScores={ficoScores}
                isLeadSubmitted={isLeadSubmitted}
                {...listingResultProperties}
              />
            ];

            if (this.state.window) {
              if (!noAdsParam && isMobileResolution() && itemCount === MOBILE_LEADERBOARD_CUSTOM_POSITION) {
                listingArr.push(<Ad key={`ad${listing.id}`} {...sponsoredAdsParams} />);
              }

              if (!noAdsParam && itemCount % AD_POSITION === 0 && desktopAdCount < MAX_DESKTOP_ADS ) {
                listingArr.push(<LeaderBoardMid key={`deskAd${listing.id}`} adNum={++desktopAdCount}/>, <MobileLiner
                  key={`mobAd${listing.id}`} adNum={++mobileAdCount}/>);
              }

              if (!noAdsParam) {
                if (isLaptopLResolution() && itemCount === LEADERBOARD_ALPHA_POSITION) {
                  listingArr.push(<Ad key={`leaderAd${listing.id}`} {...leaderBoardAlphaParams}  />);
                }

                if (!isLaptopLResolution() && itemCount === 1) {
                  listingArr.unshift(<Ad key={`leaderAd${listing.id}`} {...leaderBoardAlphaParams}  />);
                }
              }

              if (!noAdsParam && itemCount === NATIVE_AD_POSITION) {
                listingArr.push(
                  <NativeAdDesk key={`deskAdNative${listing.id}`} adNum={1} adParams={this.props?.nativeDesktopAd} />,
                  <NativeAdMobile key={`mobAdNative${listing.id}`} adNum={1} adParams={this.props?.nativeMobileAd}  />
                );
              }
            }
            return listingArr;
          }).reduce((prev, next) => [prev, ...next], [])
        }
      </ol>
    );
  }
}

export default LegacyListingList;

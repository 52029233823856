
import get from 'lodash/get';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import SocialShare from '../../../components/SocialShare';
import { setSoftLeadEvent } from '../../../store/actions/dataLayer';
import { BRANCH_EVENT, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import { BDPContext } from '../MainContent/BoatDetailsPage';
import { useBdpActions } from '../MainContent/bdpController/bdpActions';
import BDPMainGallery from './BDPMainGallery';
import './styles.css';


const BDPCarouselModal = (props) => {
  const {listing, carouselItems, cookies, url, myboats, carouselThumbnails} = useContext(BDPContext);
  const hasImmersiveTour = !!get(listing, 'virtualTour.0', false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const {changeCarouselImage, carouselButtonClick} = useBdpActions();
  const {
    breakpoint, isWorking, showMoreBoats,
    showThumbnails, show, carouselIndex, hideModal,
    carouselAddParams, carouselButtons, handleOpenContactForm,
    openImmersiveTourModal, handleCarouselItemClick, openModalDealerLocator,
    liked, onClickLike, setStackedGalleryScroll
  } = props;

  const enableThumbnails = showThumbnails && breakpoint === 'desktop' && carouselThumbnails.length > 0;
  carouselThumbnails[0].onClick = (e) => {
    handleCarouselItemClick(e, 0);
  };

  const hideLikeButton = props.hideLikeButton || false;
  const mediaItems = get(carouselItems, 'mediaItems', []);
  const onClickPreviousNext = () => {
    changeCarouselImage();
    carouselButtonClick();
  };
  const handleClickShare = () => {
    setOpenShareModal(!openShareModal);
    branchIoEventsManager(BRANCH_EVENT.SHARE_BOAT, {
      sku: get(listing, 'id', ''),
      product_name: get(listing, 'model', ''),
      product_brand: get(listing, 'validMake', ''),
    });

  };

  const dispatch = useDispatch();
  const openImmersiveTourModalHandler = () => {
    dispatch(setSoftLeadEvent('immersive tour'));
    openImmersiveTourModal();
  };

  const galleryProps = {
    breakpoint,
    isWorking,
    listing,
    cookies,
    url,
    myboats,
    showMoreBoats,
    show,
    carouselIndex,
    hideModal,
    carouselAddParams,
    handleOpenContactForm,
    openModalDealerLocator,
    openImmersiveTourModal,
    onClickPreviousNext,
    mediaItems,
    hideLikeButton,
    carouselButtons,
    openImmersiveTourModalHandler,
    hasImmersiveTour,
    handleCarouselItemClick,
    handleClickShare,
    enableThumbnails,
    carouselThumbnails,
    liked,
    onClickLike,
    setStackedGalleryScroll
  };
  return (
    <>
      <BDPMainGallery {...galleryProps} />
      <SocialShare
        type="overlay"
        listing={listing}
        handleOpenContactForm={handleOpenContactForm}
        showMoreBoats={showMoreBoats}
        breakpoint={breakpoint}
        openShareModal={openShareModal}
        handleShareButton={handleClickShare}
        handleShareToggle={handleClickShare}
        isBdp={true}
        openImmersiveTourModal={() => {
          openImmersiveTourModal();
          setOpenShareModal(false);
        }}
      />
    </>
  );
};

export default BDPCarouselModal;

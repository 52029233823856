import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Ad } from '@dmm/react-common-components';
import { setExternalLinkImpression } from '../../../store/actions/dataLayer';
import {
  INSURANCE_SERVICE,
  SHIPPING_SERVICE,
  RENTAL_SERVICE
} from '../../../constants/services';
import '../styles.css';

const serviceNames = {
  INSURANCE: 'Insurance',
  SHIPPING: 'shipping',
  RENTAL: 'ad-rental'
};

class ServicesAd extends Component {
  componentDidMount () {
    switch (this.props.name) {
    case serviceNames.INSURANCE:
      this.props.setExternalLinkImpression(INSURANCE_SERVICE, 'dfp', 'detail page cta');
      break;
    case serviceNames.SHIPPING:
      this.props.setExternalLinkImpression(SHIPPING_SERVICE, 'dfp', 'detail page cta');
      break;
    case serviceNames.RENTAL:
      this.props.setExternalLinkImpression(RENTAL_SERVICE, 'dfp', 'detail page cta');
      break;
    default:
      break;
    }
  }

  render() {
    const { name } = this.props;
    const adParams = {
      adId: `div-gpt-${name}`,
      classes: 'service-item service-ad'
    };

    return (
      <Ad {...adParams} />
    );
  }
}

export default connect(null, { setExternalLinkImpression })(ServicesAd);

import React from 'react';
import PropTypes from 'prop-types';
import TrustBox from '../TrustBox';

import './styles.css';

export const TrustPilotReviews = ({ dataTestId = 'trust-pilot-component' }) => {
  return (
    <>
      <div className="trust-pilot-section desktop" data-testid={dataTestId}>
        <TrustBox
          locale="en-US"
          templateId="53aa8912dec7e10d38f59f36"
          height="140px"
          width="100%"
          theme="light"
          color="#616161"
          stars="3,4,5"
          reviewLanguages="en"
        />
      </div>
      <div className="trust-pilot-section mobile" data-testid={dataTestId}>
        <TrustBox
          locale="en-US"
          templateId="539ad0ffdec7e10e686debd7"
          height="350px"
          width="100%"
          theme="light"
          color="#616161"
          stars="3,4,5"
          reviewLanguages="en"
        />
      </div>
    </>
  );
};

TrustPilotReviews.propTypes = {
  dataTestId: PropTypes.string,
};

/**
 * Part of MOOL-417 was to make this `DealerLocatorModal` a common component; however,
 * `@dmm/react-common-components` does not currently support [React] functional components,
 * until it does, the component will live in the `portal-react-boattrader` project.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from '../Modal';

import './styles.css';

import ContactPage from './ContactPage';
import LocatorHeader from './LocatorHeader';
import LocatorBody from './LocatorBody';
import dealerLocatorModalHelper from '../../utils/dealerLocatorModalHelper';
import { setModalEvent } from '../../store/actions/dataLayer';

const MODAL_TYPE = {
  dealer: {
    type: 'dealer',
    trackingLabel: 'locate dealer'
  }
};

const DealerLocatorModal = ({
  device,
  hideModal,
  isOpen,
  oemDetails,
  oemDealers,
  setOemDealers,
  dealerIndex,
  setDealerIndex,
  listing,
  isWorking,
  postalCode,
  setPostalCode,
  origin,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const type = 'dealer';

  const helper = dealerLocatorModalHelper({
    pageIndex,
    oemDealers,
    oemDetails,
    setOemDealers,
    setPageIndex,
    setPostalCode
  });

  const closeModal = () => {
    setModalEvent('close', MODAL_TYPE[type].trackingLabel);
    hideModal();
    setDealerIndex(undefined);
    helper.goToPageIndex(0);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (isOpen && !oemDealers) {
      await helper.fetchDealers();
    }
    setModalEvent('open', MODAL_TYPE[type].trackingLabel);
  });

  return (
    <Modal
      contentLabel={`Find a ${helper.oemTerm()} Dealer Modal`}
      injectedStyles={{
        content: {
          background: 'none',
          border: 'none',
          ...(device !== 'desktop' && { padding: 0, height: '100%', width: '100%' }) // Full-screen modal
        },
        overlay: { background: '#333333E5' }
      }}
      isOpen={isOpen}
      onRequestClose={closeModal}
      portalClassName="dealer-locator-modal"
    >
      <div className="modal-container" data-testid="dealer-locator-modal" data-e2e="dealer-locator-modal">
        {dealerIndex > -1 &&
          <div className="info-container" data-e2e="info-container">
            <ContactPage
              type={type}
              device={device}
              setDealerIndex={setDealerIndex}
              closeModal={closeModal}
              dealer={oemDealers[dealerIndex]}
              listing={listing}
              isWorking={isWorking}
              postalCode={postalCode}
              oemDetails={oemDetails}
              origin={origin}
            />
          </div>
        ||
          <div className="locator-container" data-e2e="locator-container">
            <LocatorHeader
              closeModal={closeModal}
              oemTerm={helper.oemTerm}
              fetchDealers={helper.fetchDealers}
              postalCode={postalCode}
            />
            <LocatorBody
              pageIndex={pageIndex}
              device={device}
              setDealerIndex={setDealerIndex}
              helper={helper}
            />
          </div>
        }
      </div>
    </Modal>
  );
};

DealerLocatorModal.defaultProps = {
  device: 'mobile'
};

DealerLocatorModal.propTypes = {
  device: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  oemDetails: PropTypes.object.isRequired,
  setOemDealers: PropTypes.func.isRequired,
  dealerIndex: PropTypes.number,
  setDealerIndex: PropTypes.func.isRequired
};

export default connect(
  null,
  dispatch => bindActionCreators({
    setModalEvent
  }, dispatch)
)(DealerLocatorModal);

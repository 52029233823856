import {useContext, useEffect, useReducer} from 'react';
import {getBreakpoint} from '../../../../utils/commonHelper';
import {BDPContext} from '../BoatDetailsPage';
import {createCarouselButtons, createSummaryHandlers, useBdpHandlers} from './bdpHelpers';
import {useSelector} from 'react-redux';
import {isFSBOContact} from '../../../../utils/listingHelper';

const defaultState = () => {
  let state = {
    openDetailsSection: false,
    openBoatLoansComponent: 'boat-loans',
    breakpoint: undefined,
    showModalCarousel: false,
    showModalDealerLocator: false,
    showModalImmersiveTour: false,
    showModalPreQualified: false,
    showModalPostLead: false,
    leadPage: '',
    leadType: '',
    trackingEvent: '',
    carouselIndex: 0,
    carouselCount: 1,
    carouselClicker: 0,
    listingViewSent: false,
    UIEvent: {},
    oemBrandProductImpressionSent: false,
    setListingViewSent: false
  };
  return state;
};


const addBdpListeners = (resizeHandler, scrollHandler) => {
  window.addEventListener('resize', resizeHandler);
  window.addEventListener('scroll', scrollHandler);
  return () => {
    window.removeEventListener('resize', resizeHandler);
    window.removeEventListener('scroll', scrollHandler);
  };
};


const reducer = (prevState, updated) => ({
  ...prevState,
  ...updated
});

export const useAdditionalLeads = () => {
  const additionalLeadsState = useSelector(state => {
    const additionalLeadsAlert = state?.app?.additionalLeads?.alert || {};
    const showPostLeadAlert = additionalLeadsAlert.open || false;
    const isSuccessfulAlert = additionalLeadsAlert.success || undefined;
    const isOemAlert = additionalLeadsAlert.isOem || false;
    return {showPostLeadAlert, isSuccessfulAlert, isOemAlert};
  });
  return additionalLeadsState;
};

export const useBdpPageType = ({listing}) => {
  const fsbo = isFSBOContact(listing.contact);
  if (fsbo) {
    return 'fsbo';
  } else if (listing.isOemModel) {
    return 'oem';
  }
  return 'broker';
};

export const useBdp = () => {
  const [state, setState] = useReducer(reducer, defaultState());
  const bdpContext = useContext(BDPContext);
  const openHandlers = useBdpHandlers(setState);

  const summaryHandlers = createSummaryHandlers(setState);
  const bdpHandlers = {summaryHandlers, ...openHandlers};
  const {mediaImages = [], mediaVideos = []} = bdpContext;
  const carouselButtons = createCarouselButtons(mediaImages, mediaVideos, bdpHandlers.openCarousel);
  const resizeHandler = openHandlers.resizeHandler;
  useEffect(() => {
    setState({breakpoint: getBreakpoint()});
    const removeListeners = addBdpListeners(resizeHandler);
    return removeListeners;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const bdp = { bdpHandlers, bdpContext, carouselButtons };
  return {state, setState, bdp};
};

import { useMemo } from 'react';
import { entriesToString, getNoFollow } from '../../metaContentHelper';

const useSeoLink = (url) => {
  return useMemo(() => {
    const nofollow = getNoFollow(url);
    const rel = entriesToString({ nofollow });
    return rel;
  }, [url]);
};

export default useSeoLink;

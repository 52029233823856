import React, { PureComponent } from 'react';
import classnames from 'classnames';
import './index.css';

class ToolSetRadioGroup extends PureComponent {
  render() {
    let { children } = this.props;
    return <div className="tool-set-radio-group">
      {children}
    </div>;
  }
}

class ToolSetRadioItem extends PureComponent {
  render() {
    let { name, id, value, checked, onChange, children } = this.props;
    return <div className={classnames('tool-set-radio-item', { checked })}>
      <input type="radio" data-e2e={`filter-condition-${value}`} id={id} name={name} value={value} checked={checked} className="visuallyhidden" onChange={onChange} />
      <label htmlFor={id}>{children}</label>
    </div>;
  }
}

export {
  ToolSetRadioGroup,
  ToolSetRadioItem
};

import React from 'react';

export const FAQ_SECTION_TITLE = 'FAQs About Boat Roadside Assistance';
export const FAQ_DISCLAIMER_TEXT =
  '*Actual rate can vary depending on loan term and APR, or Annual Percentage Rate. Pricing reflects an example of an $8,000 service contract on a $50,000 boat financed for 240 months or 20 years. Trident Funding LLC markets and sells extended warranties on behalf of 3rd party providers and your extended warranty will be between you and the provider. Coverage, deductibles, and claims payment vary depending on vehicle age, mileage, preexisting conditions and program selected. Repairs can be made by any licensed repair facility. Program(s) available in most states. Not available in California or Florida. You consent to be contacted by email, phone, and/or text once we receive your quote submission. Texas and Wisconsin Residents: Contracts administered by Dealer Admin Services, 5810 W 78th Street, Suite 300, Bloomington, MN 55439, 877-746-4917. Vehicle names, logos, brands, & other trademarks featured or referred to within Trident Funding are property of their respective trademark holders. Extended warranties may not be sold where prohibited by law.';
export const FAQ_ACCORDION_CONTENT = [
  {
    title: 'What is the coverage area for boat roadside assistance?',
    content: (
      <p>
        The coverage area for boat roadside assistance can vary depending on the
        provider. Some providers offer coverage within specific regions, while
        others may have nationwide or international coverage. It&apos;s
        important to review the coverage area details before selecting a
        roadside assistance plan.
      </p>
    ),
  },
  {
    title: 'Can I transfer my boat roadside assistance plan to another boat?',
    content: (
      <p>
        Whether or not you can transfer your roadside assistance plan to another
        boat depends on the terms and conditions set by the provider. Some plans
        allow for transferability, while others are tied to the specific boat.
        It&apos;s recommended to check with your provider to understand their
        policy on plan transfers.
      </p>
    ),
  },
  {
    title:
      'Are there any restrictions on the number of service calls covered in a given period?',
    content: (
      <p>
        Roadside assistance plans may have certain limitations on the number of
        service calls covered within a specific timeframe. These restrictions
        can vary between providers and plans. It&apos;s important to review the
        details of your chosen plan to understand any limitations or exclusions
        that may apply.
      </p>
    ),
  },
];

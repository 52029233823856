import * as httpClient from './httpClient';

export const searchBoats = async (text) => {

  const url = `/app/nl-search/boat?text=${text}`;

  const apiClient = httpClient.getSecureProxyHttpClient();
  const response = await apiClient.get(url);

  return response.data;

};

export const convoBoats = async (messages) => {

  const url = '/app/nl-search/concierge';

  const apiClient = httpClient.getSecureProxyHttpClient();
  const response = await apiClient.post(url, { conversation: messages, pageSize: 4 });

  return response.data;

};

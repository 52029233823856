import React from 'react';
// FAQSection Content
export const FAQ_SECTION_TITLE = 'FAQs About Extended Service Plans for Boats';
export const FAQ_DISCLAIMER_TEXT =
  '*Actual rate can vary depending on loan term and APR, or Annual Percentage Rate. Pricing reflects an example of an $8,000 service contract on a $50,000 boat financed for 240 months or 20 years. Trident Funding LLC markets and sells extended warranties on behalf of 3rd party providers and your extended warranty will be between you and the provider. Coverage, deductibles, and claims payment vary depending on vehicle age, mileage, preexisting conditions and program selected. Repairs can be made by any licensed repair facility. Program(s) available in most states. Not available in California or Florida. You consent to be contacted by email, phone, and/or text once we receive your quote submission. Texas and Wisconsin Residents: Contracts administered by Dealer Admin Services, 5810 W 78th Street, Suite 300, Bloomington, MN 55439, 877-746-4917. Vehicle names, logos, brands, & other trademarks featured or referred to within Trident Funding are property of their respective trademark holders. Extended warranties may not be sold where prohibited by law.';
export const FAQ_ACCORDION_CONTENT = [
  {
    title: 'Can I purchase an extended service plan for a used boat?',
    content: (
      <p>
        Yes, extended service plans are available for both new and used boats,
        although coverage options may vary.
      </p>
    ),
  },
  {
    title:
      'Are routine maintenance and wear-and-tear repairs covered by extended service plans?',
    content: (
      <p>
        No, routine maintenance and wear-and-tear repairs are typically not
        covered.
      </p>
    ),
  },
  {
    title:
      'How soon after purchasing a new boat should I consider getting an extended service contract?',
    content: (
      <p>
        It is recommended to purchase an extended service plan for your new boat
        as soon as possible. Ideally, you should explore your contract options
        before or shortly after your boat&apos;s manufacturer warranty expires.
        This ensures continuous protection against unexpected repairs and gives
        you peace of mind throughout your ownership.
      </p>
    ),
  },
  {
    title:
      'Can I cancel an extended service plan for a boat if I no longer need it?',
    content: (
      <p>
        Yes, you can usually cancel an extended service plan for your boat if
        you no longer need it. However, the process and terms for cancellation
        may vary depending on the contract provider. It is important to review
        the contract and contact the provider directly to understand their
        cancellation policy, any applicable fees, and the timeframe for
        cancellation.
      </p>
    ),
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import { sanitizeHtml } from '@dmm/lib-common/lib/sanitizeHtml';

import './styles.css';

const ExpansibleHeader = ({title, type = 'h2'}) => {
  return type === 'h3' ? <h3>{title}</h3> : <h2>{title}</h2>;
};

const ExpansibleInfoTable = ({ sectionId, titleHeader, content, titleType }) => {
  return (
    <section id={sectionId} >
      <div className={`${sectionId}-container`}>
        <div>
          <ExpansibleHeader title={titleHeader} type={titleType} />
          {
            content.map(({ id, title, content }, index) => {
              return (
                <div key={id} className="tab">
                  <input type="checkbox" id={`check${index}`} />
                  <label className="tab-label" htmlFor={`check${index}`}>{title}</label>
                  <div className="tab-content" dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
                </div>
              );
            })
          }
        </div>
      </div>
    </section>
  );
};

ExpansibleInfoTable.propTypes = {
  sectionId: PropTypes.string,
  titleHeader: PropTypes.string,
  titleType: PropTypes.string,
  content: PropTypes.array
};

export default ExpansibleInfoTable;

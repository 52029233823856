import React from 'react';
import { YoutubeVideos } from '@dmm/lib-react-videos';

const VideoReviews = ({ videos, breakpoint }) => {
  return (
    <section className="video-reviews-section">
      <h2>Expert Video Reviews</h2>
      <YoutubeVideos
        videos={videos}
        device={breakpoint}
      />
    </section>
  );
};

export default VideoReviews;

export const adsConfig = {
  'div-gpt-mobile-leaderboard-1': {
    unitPath: '/252108799/BoatTrader/social-browse/mobile-leaderboard-1',
    breakpoint: {
      min: 0,
      max: 970
    },
    size: [
      [320, 50]
    ],
    targeting: {
      pos: 'mobile-leaderboard-1'
    },
    prebid: {
      bids: []
    }
  }
};

export const getAdsConfig = () => {
  return adsConfig;
};

import React, { PureComponent } from 'react';
import get from 'lodash/get';
import BreadCrumb from '../BreadCrumb';
import './styles.css';
import Status from '../Status';

export default class ErrorMessage extends PureComponent {
  render(){
    let defaultMessage = 'Something went wrong while serving your request. Please try again shortly. If this problem persists, please contact customer support.';
    let message = get(this.props, 'message', defaultMessage);
    let altUrl = get(this.props, 'altUrl');
    return <>
      <Status {...this.props} />
      <BreadCrumb items={[{
        title: 'Home',
        link: '/'
      },{
        title: 'Server Error'
      }]} />
      <div id="main-content" className="error-msg">
        <h1>Something went wrong</h1>
        <div>
          <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/btol-error-img.jpg`} alt="Server Error" className="error" />
          <p>{ message }</p>
          { altUrl ? <p><a href={altUrl}>Reset Search</a></p> : null }

        </div>
      </div>
    </>;
  }
}

import {
  formatPrice,
  formatPriceWithDecimal,
  formatWithDecimal,
} from '@dmm/lib-common/lib/formatting';
import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  CALCULATOR_SMALL_PRINT,
  CDN_BOAT_LOANS,
  LOAN_AMOUNT_CALCULATOR,
  MONTHLY_PAYMENT_CALCULATOR,
} from '../../constants/BoatLoansCalculator';

import { BOAT_LEAD_PURCHASE_TYPE, TRACKING_EVENT_LEAD_PURCHASE_SUBMISSION } from '../../constants/BoatLoans';
import './Calculator.styles.css';

export const Calculator = ({
  setGenericEvent,
  tridentLoanAmount,
  tridentTeaserRate,
  tridentTermInMonths,

  goToApplyNow,
  showMonthlyCalculatorOnly,
  dataTestId = 'calculator-component',
  showPreQualified = true,
  showModalPreQualified,
  trackGetPreQualifiedClicked,
  isBDP = false,
  isLeadSubmitted,
  hideTridentFeatures = false,
  calcMode,
}) => {
  const [calculator, setCalculator] = useState({
    mode: calcMode || MONTHLY_PAYMENT_CALCULATOR,
    autoCalculate: false,
    showErrorOnCalculate: {
      loanAmountOrMonthlyPayment: false,
      loanTermYears: false,
      loanTermMonths: false,
      interestRate: false,
    },
    formFields: {
      purchasePrice: '$0.00',
      downPayment: '$0.00',
      monthlyPayment: '',
      desiredMonthlyPayment: '$0.00',
      loanTermYears: '',
      loanTermMonths: '',
      interestRate: '',
      loanAmount: ''
    },
    result: {
      totalLoanAmount: '$0.00',
      monthlyPayment: '$0.00',
      totalPrincipal: '$0.00',
      totalInterest: '$0.00',
    },
  });

  useEffect(() => {
    let priceAmount = '$0.00';
    let termYears;
    let formattedRate;
    let termMonths;
    let autoCalc = false;

    if (tridentLoanAmount) {
      priceAmount = formatPriceWithDecimal(tridentLoanAmount, 'USD', 'en-US', 2);
      termYears = String(tridentLoanAmount >= 50000 ? 20 : 15);
      autoCalc = true;
    }

    if (tridentTeaserRate) {
      formattedRate = formatWithDecimal(
        tridentTeaserRate,
        'en-US',
        2,
        true
      );
    }

    if (tridentTermInMonths) {
      termMonths = String(tridentTermInMonths);
      if (!termYears) {
        termYears = String(tridentTermInMonths / 12);
      }
    }

    setCalculator({
      ...calculator,
      autoCalculate: autoCalc,
      formFields: {
        ...calculator.formFields,
        purchasePrice: priceAmount,
        loanAmount: priceAmount,
        ...(formattedRate && { interestRate: formattedRate }),
        ...(termYears && { loanTermYears: termYears }),
        ...(termMonths && { loanTermMonths: termMonths })
      },
      result: {
        ...calculator.result,
        totalPrincipal: priceAmount
      }
    });

  }, [tridentLoanAmount, tridentTeaserRate, tridentTermInMonths]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (calculator.autoCalculate && tridentTeaserRate) {
      const triggerAutoCalculate = async () => {
        await handleCalculateClick();
      };
      triggerAutoCalculate();
    }
  }, [calculator.autoCalculate, tridentTeaserRate]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeLastOccurrence = (str, char) => {
    const lastIndexOfL = str.lastIndexOf(char);
    return str.slice(0, lastIndexOfL) + str.slice(lastIndexOfL + 1);
  };

  const validatedownPayment = (processedValue) =>{
    const purchasePriceNumber = Number(calculator.formFields.purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number((processedValue).replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber > purchasePriceNumber) {
      return calculator.formFields.purchasePrice;
    }
    return processedValue;
  };

  const setCalculatorFormValue = (name, value) => {
    let processedValue = value;

    if (name === 'purchasePrice' || name === 'desiredMonthlyPayment' || name === 'downPayment') {
      const numOfPeriods = (value.match(/\./g) || []).length;
      let decimalPart = '';

      if (numOfPeriods > 1) {
        processedValue = removeLastOccurrence(processedValue, '.');
      }
      decimalPart =
        processedValue.indexOf('.') > -1
          ? processedValue.substring(processedValue.indexOf('.'))
          : '';
      processedValue =
        formatPrice(
          processedValue.replace(/[^0-9.]/g, '').replace(/\..*/, ''),
          'USD',
          'en-US'
        ) + decimalPart.replace(/[^0-9.]/g, '');

      if (!processedValue) {
        processedValue = '$0';
      }

      if (name === 'downPayment'){
        processedValue = validatedownPayment(processedValue);
      }

    } else if (name === 'loanTermYears' || name === 'loanTermMonths') {
      processedValue = value.replace(/[^0-9]/g, '');
    } else {
      const numOfPeriods = (value.match(/\./g) || []).length;
      processedValue =
        numOfPeriods > 1
          ? removeLastOccurrence(value, '.')
          : value.replace(/[^0-9.]/g, '');
    }

    let updatedFormValues = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        [name]: processedValue,
      },
    };

    if (name === 'loanTermYears') {
      updatedFormValues = {
        ...updatedFormValues,
        formFields: {
          ...updatedFormValues.formFields,
          loanTermMonths: processedValue.length
            ? (parseInt(processedValue) * 12).toString()
            : '',
        },
      };
    } else if (name === 'loanTermMonths') {
      updatedFormValues = {
        ...updatedFormValues,
        formFields: {
          ...updatedFormValues.formFields,
          loanTermYears: processedValue.length
            ? parseInt(processedValue.charAt(processedValue.length - 1)) <= 5
              ? Math.floor(parseInt(processedValue) / 12).toString()
              : Math.ceil(parseInt(processedValue) / 12).toString()
            : '',
        },
      };
    }
    setCalculator(updatedFormValues);
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setCalculatorFormValue(name, value);
  };

  const handleBlurInterestRate = async (event) => {
    const { name, value } = event.target;

    const processedValue = formatWithDecimal(
      value.replace(/[^0-9.]/g, ''),
      'en-US',
      2,
      true
    );
    setCalculatorFormValue(name, processedValue);
  };

  const handleBlurMonthlyPaymentLoanAmount = async (event) => {
    const { name, value } = event.target;

    const processedValue = formatPriceWithDecimal(
      value.replace(/[^0-9.]/g, ''),
      'USD',
      'en-US',
      2
    );
    setCalculator({
      ...calculator,
      formFields: {
        ...calculator.formFields,
        [name]: processedValue,
      },
    });
  };

  const handleMonthlyPaymentModeSelection = async () => {
    if (calculator.mode === LOAN_AMOUNT_CALCULATOR) {

      setCalculator({
        ...calculator,
        mode: MONTHLY_PAYMENT_CALCULATOR,
        formFields: {
          ...calculator.formFields
        },
      });
    }
  };

  const handleLoanAmountModeSelection = async () => {
    if (calculator.mode === MONTHLY_PAYMENT_CALCULATOR) {
      setCalculator({
        ...calculator,
        mode: LOAN_AMOUNT_CALCULATOR,
        formFields: {
          ...calculator.formFields,
          desiredMonthlyPayment:
          calculator.result.monthlyPayment !== '$0.00' && calculator.formFields.desiredMonthlyPayment === '$0.00'
            ? calculator.result.monthlyPayment
            : calculator.formFields.desiredMonthlyPayment,
        },
      });
    }
  };

  const updateLoanAmount = (loanAmount) => {
    let updatedCalculator = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        loanAmount
      },
    };
    setCalculator(updatedCalculator);
  };

  const updateDownPayment = (downPayment) => {
    let updatedCalculator = {
      ...calculator,
      formFields: {
        ...calculator.formFields,
        downPayment
      },
    };
    setCalculator(updatedCalculator);
  };

  const calculateMonthlyPayment = (rate, term, loanAmount) => {
    // https://www.oreilly.com/library/view/javascript-the-definitive/0596000480/ch01s08.html
    const parsedLoanAmount = parseFloat(loanAmount.replace(/[$, ]/g, ''));
    rate = parseFloat(rate.replace(/ /g, ''));
    term = parseInt(term.replace(/ /g, ''));

    const ratePerMonth = rate / 100 / 12;
    const x = Math.pow(1 + ratePerMonth, term);
    const monthlyPayment = ((x - 1) === 0)
      ? (parsedLoanAmount / term).toFixed(2)
      : (
        (parsedLoanAmount * ratePerMonth * x) /
        (x - 1)
      ).toFixed(2);
    const totalLoanAmount = (term * monthlyPayment).toFixed(2);

    return {
      monthlyPayment: formatPriceWithDecimal(monthlyPayment, 'USD', 'en-US', 2),
      totalLoanAmount: formatPriceWithDecimal(
        totalLoanAmount,
        'USD',
        'en-US',
        2
      ),
      totalPrincipal: formatPriceWithDecimal(
        parsedLoanAmount,
        'USD',
        'en-US',
        2
      ),
      totalInterest: formatPriceWithDecimal(
        totalLoanAmount - parsedLoanAmount,
        'USD',
        'en-US',
        2
      ),
    };
  };

  const calculateTotalLoanAmount = (rate, term, monthlyPayment) => {
    const parsedMonthlyPayment = parseFloat(
      monthlyPayment.replace(/[$, ]/g, '')
    );
    rate = parseFloat(rate.replace(/ /g, ''));
    term = parseInt(term.replace(/ /g, ''));

    const ratePerMonth = rate / 100 / 12;
    const x = Math.pow(1 + ratePerMonth, term);
    const totalPrincipal =  ((x * ratePerMonth) === 0)
      ? (parsedMonthlyPayment * term)
      : (parsedMonthlyPayment * (x - 1)) / (x * ratePerMonth);
    const totalLoanAmount = (term * parsedMonthlyPayment).toFixed(2);
    const totalInterest = (totalLoanAmount - totalPrincipal).toFixed(2);

    return {
      monthlyPayment: formatPriceWithDecimal(
        parsedMonthlyPayment,
        'USD',
        'en-US',
        2
      ),
      totalLoanAmount: formatPriceWithDecimal(
        totalLoanAmount,
        'USD',
        'en-US',
        2
      ),
      totalPrincipal: formatPriceWithDecimal(totalPrincipal, 'USD', 'en-US', 2),
      totalInterest: formatPriceWithDecimal(totalInterest, 'USD', 'en-US', 2),
    };
  };

  const calculateLoan = (mode) => {
    switch (mode) {
    case MONTHLY_PAYMENT_CALCULATOR:
      return calculateMonthlyPayment;
    case LOAN_AMOUNT_CALCULATOR:
      return calculateTotalLoanAmount;
    }
  };

  const handleCalculateClick = async () => {
    const blankLoanAmountOrMonthlyPayment = !purchasePrice?.replace(/ /g, '').length > 0;
    const blankLoanTermYears = !loanTermYears?.replace(/ /g, '').length > 0;
    const blankLoanTermMonths = !loanTermMonths?.replace(/ /g, '').length > 0;
    const blankInterestRate = !interestRate?.replace(/ /g, '').length > 0;

    const formMissingFields =
      blankLoanAmountOrMonthlyPayment ||
      blankLoanTermYears ||
      blankLoanTermMonths ||
      blankInterestRate;

    setCalculator({
      ...calculator,
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: blankLoanAmountOrMonthlyPayment,
        loanTermYears: blankLoanTermYears,
        loanTermMonths: blankLoanTermMonths,
        interestRate: blankInterestRate,
      },
    });

    if (!formMissingFields) {
      const rate = interestRate;
      const term = loanTermMonths;
      const loanAmountOrMonthlyPayment = calculator?.mode ===  MONTHLY_PAYMENT_CALCULATOR
        ? calculator?.formFields?.loanAmount
        : calculator?.formFields?.desiredMonthlyPayment;

      setGenericEvent(
        'loan calculation',
        'calculate - click',
        calculator.mode === MONTHLY_PAYMENT_CALCULATOR
          ? 'monthly payment'
          : 'total loan amount'
      );

      const { monthlyPayment, totalPrincipal, totalInterest, totalLoanAmount } =
        calculateLoan(calculator.mode)(rate, term, loanAmountOrMonthlyPayment);

      if (calculator.mode === MONTHLY_PAYMENT_CALCULATOR){
        setCalculator({
          ...calculator,
          autoCalculate: false,
          result: {
            ...calculator.result,
            totalLoanAmount,
            monthlyPayment,
            totalInterest,
          },
        });
      }
      else {
        setCalculator({
          ...calculator,
          autoCalculate: false,
          result: {
            ...calculator.result,
            totalPrincipal,
            totalInterest,
          },
        });
      }
    }
  };

  const clearErrors = async () => {
    setCalculator({
      ...calculator,
      showErrorOnCalculate: {
        loanAmountOrMonthlyPayment: false,
        loanTermYears: false,
        loanTermMonths: false,
        interestRate: false,
      },
    });
  };

  const {
    mode,
    showErrorOnCalculate,
    formFields: {
      purchasePrice,
      downPayment,
      loanAmount,
      desiredMonthlyPayment,
      loanTermYears,
      loanTermMonths,
      interestRate,
    },
    result: { totalLoanAmount, monthlyPayment, totalPrincipal, totalInterest },
  } = calculator;

  return (
    <div className="calc-calculator-container" data-testid={dataTestId}>
      <div className="calc-calculator-sub-container">
        {!showMonthlyCalculatorOnly && (
          <div className="calc-calculator-mode-selector">
            <div
              className={classnames('calc-calculator-selector-button monthly-mode', {
                selected: mode === MONTHLY_PAYMENT_CALCULATOR,
              })}
              onClick={handleMonthlyPaymentModeSelection}
              data-testid="monthly-payment-button"
              data-e2e="monthly-payment-button"
            >
              Monthly Payment
            </div>
            <div
              className={classnames(
                'calc-calculator-selector-button loan-amount-mode',
                {
                  selected: mode === LOAN_AMOUNT_CALCULATOR,
                }
              )}
              onClick={handleLoanAmountModeSelection}
              data-testid="loan-amount-button"
              data-e2e="loan-amount-button"
            >
              Total Loan Amount
            </div>
          </div>)}
        <div className="calc-calculator-body" data-e2e="calc-calculator-body">
          <CalculatorForm
            handleInputChange={handleInputChange}
            handleBlurInterestRate={handleBlurInterestRate}
            handleBlurMonthlyPaymentLoanAmount={
              handleBlurMonthlyPaymentLoanAmount
            }
            updateLoanAmount={updateLoanAmount}
            updateDownPayment={updateDownPayment}
            handleCalculateClick={handleCalculateClick}
            clearErrors={clearErrors}
            purchasePrice={purchasePrice}
            downPayment={downPayment}
            loanAmount={loanAmount}
            desiredMonthlyPayment={desiredMonthlyPayment}
            loanTermYears={loanTermYears}
            loanTermMonths={loanTermMonths}
            interestRate={interestRate}
            showErrorOnCalculate={showErrorOnCalculate}
            mode={mode}
          />
          <CalculatorResult
            totalLoanAmount={totalLoanAmount}
            monthlyPayment={monthlyPayment}
            totalPrincipal={totalPrincipal}
            totalInterest={totalInterest}
            showPreQualified={showPreQualified}
            mode={mode}
            goToApplyNow={goToApplyNow}
            trackGetPreQualifiedClicked={trackGetPreQualifiedClicked}
            showModalPreQualified={showModalPreQualified}
            isBDP={isBDP}
            isLeadSubmitted={isLeadSubmitted}
            hideTridentFeatures={hideTridentFeatures}
          />
        </div>
      </div>
    </div>
  );
};

Calculator.propTypes = {
  setGenericEvent: PropTypes.func.isRequired,
  tridentLoanAmount: PropTypes.number,
  tridentTeaserRate: PropTypes.number,
  tridentTermInMonths: PropTypes.number,
  goToApplyNow: PropTypes.func,
  showMonthlyCalculatorOnly: PropTypes.bool,
  showPreQualified: PropTypes.bool,
  calcMode: PropTypes.string,
};

const CalculatorForm = ({
  handleInputChange,
  handleBlurInterestRate,
  handleBlurMonthlyPaymentLoanAmount,
  handleCalculateClick,
  clearErrors,
  updateLoanAmount,
  updateDownPayment,
  purchasePrice,
  downPayment,
  loanAmount,
  desiredMonthlyPayment,
  loanTermYears,
  loanTermMonths,
  interestRate,
  showErrorOnCalculate,
  mode,
}) => {

  useEffect(() => {
    const purchasePriceNumber = Number(purchasePrice.replace(/[^0-9.-]+/g, ''));
    let downPaymentNumber = Number(downPayment.replace(/[^0-9.-]+/g, ''));
    if (downPaymentNumber > purchasePriceNumber){
      downPaymentNumber = purchasePriceNumber;
      downPayment = formatPriceWithDecimal(downPayment, 'USD', 'en-US', 2); // eslint-disable-line react-hooks/exhaustive-deps
      updateDownPayment(downPayment);
    }
    const loanAmount = formatPriceWithDecimal(purchasePriceNumber - downPaymentNumber, 'USD', 'en-US', 2);
    updateLoanAmount(loanAmount);

  }, [purchasePrice, downPayment]);

  return (
    <div className="calc-calculator-form" data-e2e="calc-calculator-form">
      <form>
        <fieldset className="form-fieldset" data-e2e="form-fieldset">
          {mode === MONTHLY_PAYMENT_CALCULATOR && (
            <>
              <label className="calc-form-label" data-e2e="loan-amount-monthly-payment" htmlFor="loan-amount-or-monthly-payment">
                Purchase Price
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-amount-or-monthly-payment"
                data-e2e="purchasePrice"
                name="purchasePrice"
                placeholder="Enter purchase price"
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                value={purchasePrice}
                data-testid="loan-amount-or-payment-form"
              />

              <label className="calc-form-label" data-e2e="downpayment" htmlFor="loan-downpayment">
                Down Payment
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-downpayment"
                data-e2e="loan-downpayment"
                name="downPayment"
                placeholder="Enter Down Payment"
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                value={downPayment}
                data-testid="loan-downpayment-form"
              />

              <label className="calc-form-label calc-real-loan-amount-label" data-e2e="real-loan-amount-label">
                Loan amount
              </label>
              <div className="calc-real-loan-amount" data-e2e="calc-real-loan-amount">
                {loanAmount}
              </div>
            </>
          )}
          {mode === LOAN_AMOUNT_CALCULATOR && (
            <>
              <label className="calc-form-label" data-e2e="loan-amount-monthly-payment" htmlFor="loan-amount-or-monthly-payment">
                Desired monthly payment
              </label>
              <input
                className="calc-form-data input"
                type="text"
                id="loan-amount-or-monthly-payment"
                data-e2e="loan-monthly-payment"
                name="desiredMonthlyPayment"
                placeholder="Enter monthly payment"
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurMonthlyPaymentLoanAmount}
                value={desiredMonthlyPayment}
                data-testid="loan-amount-or-payment-form"
              />
            </>
          )}
          <br />
          <div
            className="calc-free-text-input-error"
            data-testid="calculate-button-validation-error"
            data-e2e="calculate-button-validation-error"
            hidden={!showErrorOnCalculate.loanAmountOrMonthlyPayment}
          >
            * Missing required field
          </div>
          <label className="calc-form-label loan-amount-or-monthly-payment" data-e2e="loan-term-years" htmlFor="loan-term-years">
            Loan term in years
          </label>
          <input
            className="calc-form-data input years"
            type="text"
            id="loan-term-years"
            data-e2e="loanTermYears"
            name="loanTermYears"
            placeholder="Enter term in years"
            onChange={handleInputChange}
            onFocus={clearErrors}
            value={loanTermYears}
            data-testid="loan-term-years-form"
          />
          <br />
          <div
            className="calc-free-text-input-error"
            data-testid="calculate-button-validation-error"
            data-e2e="calculate-button-validation-error"
            hidden={!showErrorOnCalculate.loanTermYears}
          >
            * Missing required field
          </div>
          <div className="calc-or-text" data-e2e="calc-or-text">Or</div>
          <label className="calc-form-label" data-e2e="loan-term-months" htmlFor="loan-term-months">
            Loan term in months
          </label>
          <input
            className="calc-form-data input"
            type="text"
            id="loan-term-months"
            data-e2e="loanTermMonths"
            name="loanTermMonths"
            placeholder="Enter term in months"
            onChange={handleInputChange}
            onFocus={clearErrors}
            value={loanTermMonths}
            data-testid="loan-term-months-form"
          />
          <br />
          <div
            className="calc-free-text-input-error"
            data-testid="calculate-button-validation-error"
            data-e2e="calc-button-validation-error"
            hidden={!showErrorOnCalculate.loanTermMonths}
          >
            * Missing required field
          </div>
          <label className="calc-form-label interest-rate" data-e2e="interest-rate" htmlFor="interest-rate">
            Interest Rate (APR) *
          </label>
          <div className="calc-calculate-row" data-e2e="calc-calculate-row">
            <div className="calc-calculate-interest-row" data-e2e="calc-calculate-interest-row">
              <input
                className="calc-form-data input"
                type="text"
                id="interest-rate"
                data-e2e="interestRate"
                name="interestRate"
                placeholder="Enter interest rate"
                onChange={handleInputChange}
                onFocus={clearErrors}
                onBlur={handleBlurInterestRate}
                value={interestRate}
                data-testid="interest-rate-form"
              />
              <div className="calc-percent-sign" data-e2e="calc-percent-sign">%</div>
            </div>
            <div
              className="calc-free-text-input-error interest-rate"
              data-testid="calculate-button-validation-error"
              data-e2e="calc-free-text-input-error-interest-rate"
              hidden={!showErrorOnCalculate.interestRate}
            >
              * Missing required field
            </div>
            <Action
              data-testid="calculate-button"
              data-e2e="calc-calculate-button-secondary cta"
              onClick={handleCalculateClick}
              label="Calculate"
              variant="secondary"
              stretch
              type="button"
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

CalculatorForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleBlurInterestRate: PropTypes.func.isRequired,
  handleBlurMonthlyPaymentLoanAmount: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  updateLoanAmount: PropTypes.func.isRequired,
  purchasePrice: PropTypes.string,
  downPayment: PropTypes.string,
  desiredMonthlyPayment: PropTypes.string,
  loanAmount: PropTypes.string,
  loanTermYears: PropTypes.string,
  loanTermMonths: PropTypes.string,
  interestRate: PropTypes.string,
  mode: PropTypes.string.isRequired,
  showErrorOnCalculate: PropTypes.shape({
    loanAmountOrMonthlyPayment: PropTypes.bool.isRequired,
    loanTermYears: PropTypes.bool.isRequired,
    loanTermMonths: PropTypes.bool.isRequired,
    interestRate: PropTypes.bool.isRequired,
  }),
};

const CalculatorResult = ({
  totalLoanAmount,
  totalPrincipal,
  monthlyPayment,
  mode,
  goToApplyNow,
  showPreQualified,
  trackGetPreQualifiedClicked,
  isBDP,
  isLeadSubmitted,
  hideTridentFeatures
}) => {
  const [customStyle, setCustomStyle] = useState({});
  useEffect(() => {
    const displayAmount =
      mode === MONTHLY_PAYMENT_CALCULATOR ? monthlyPayment : totalLoanAmount;

    if (displayAmount.length > 15) {
      setCustomStyle({ fontSize: '1.8rem'});
    } else if (displayAmount.length > 11) {
      setCustomStyle({ fontSize: '2.46rem'});
    } else {
      setCustomStyle({ fontSize: '3.46rem'});
    }
  }, [monthlyPayment, totalLoanAmount, mode]);

  const sendTrackingForPreQualify = () => {
    if (!trackGetPreQualifiedClicked) {
      return;
    }

    trackGetPreQualifiedClicked({
      leadPage: 'BDP-PaymentCalculator',
      leadType: BOAT_LEAD_PURCHASE_TYPE,
      trackingEvent: {'event': TRACKING_EVENT_LEAD_PURCHASE_SUBMISSION },
    });
  };

  return (
    <div className="calc-calculator-summary" id="calc-summary" data-e2e="calc-summary">
      <div className="calc-summary-wrapper" data-e2e="calc-summary-wrapper">
        {mode === MONTHLY_PAYMENT_CALCULATOR && (
          <>
            <div className="calc-summary-title" data-e2e="calc-summary-title">Monthly Payment</div>
            <div
              className="calc-summary-data calc-monthly-payment"
              data-testid="loan-amount-or-monthly-payment"
              id="summary-data-monthly-payment"
              data-e2e="summary-data-monthly-payment"
              style={customStyle}
            >
              {monthlyPayment}
            </div>
          </>
        )}
        {mode === LOAN_AMOUNT_CALCULATOR && (
          <>
            <div className="calc-summary-title" data-e2e="calculate-summary-title">Total Loan Amount</div>
            <div
              className="calc-summary-data calc-loan-amount"
              data-testid="loan-amount-or-monthly-payment"
              id="summary-data-loan-amount"
              data-e2e="summary-data-loan-amount"
              style={customStyle}
            >
              {totalPrincipal}
            </div>
          </>
        )}
        {!hideTridentFeatures && (
          <>
            <div className="calc-summary-data calc-payment-label" data-e2e="calc-summary-data-calc-payment-label">
              Ready for the next step?
            </div>
            <div className="calc-summary-button-row" data-testid="calc-summary-buttons">
              <Action
                stretch
                onClick={(e) =>{
                  e.preventDefault();
                  goToApplyNow();
                }}
                data-reporting-event-category="loan calculation"
                data-reporting-event-action="Apply Now - click"
                data-reporting-event-label="Apply Now"
                data-testid="apply-now-button-link"
                data-e2e="apply-now-button-link cta-get-prequalified"
                data-reporting-click-internal-link-type="click"
                data-reporting-click-internal-link-id="payment calculator - apply now"
                icon={<img width="20" height="20" alt="link arrow" src={`${CDN_BOAT_LOANS}/external-link.svg`} />}
                label="Get Pre-qualified"
                className="cta"
              />
              {!isLeadSubmitted && showPreQualified && (
                <>
                  <div className="calc-or" data-e2e="calc-or" data-testid="calc-or">Or</div>
                  <a
                    className="calc-get-prequal-button"
                    data-reporting-click-internal-link-id="payment calculator - get prequalified button"
                    data-reporting-click-internal-link-type="click"
                    data-testid="calc-get-prequal-button"
                    data-e2e="calc-get-prequalified-button"
                    onClick={(e) =>{
                      e.preventDefault();
                      sendTrackingForPreQualify();
                      goToApplyNow();
                    }}
                  >
                    Get Pre-Qualified
                  </a>
                </>
              )}
            </div>
          </>
        )}
        {isBDP &&
          (<div
            className="calc-calculator-footer-container"
            data-testid="calc-calculator-footer-container"
            data-e2e="calc-calculator-footer-container"
          >
            <div className="calc-calculator-footer" data-e2e="calc-calculator-footer">
                Adjust the loan term, down payment amount and interest rate to see results based on the numbers you provide – and how any changes to those numbers may affect your payment. *For more information on the APR rates, please visit our <a className="calc-boat-loans-link" data-e2e="calc-boat-loans-link" href="/boat-loans/calculator/" target="_blank">Boat Loans</a> page.
            </div>
          </div>
          )
        }
      </div>
      <div className="calc-summary-small-print" data-e2e="calc-summary-small-print">{CALCULATOR_SMALL_PRINT}</div>
    </div>
  );
};

CalculatorResult.propTypes = {
  totalLoanAmount: PropTypes.string.isRequired,
  monthlyPayment: PropTypes.string.isRequired,
  totalPrincipal: PropTypes.string.isRequired,
  totalInterest: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  isLeadSubmitted: PropTypes.bool,
  hideTridentFeatures: PropTypes.bool
};

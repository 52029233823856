import {DEFAULT_ENGINE_REFINE_SEARCH, ENGINES_ALL} from '../constants/Engines';
import {US_COUNTRIES, US_STATES} from '../constants/boats';
import get from 'lodash/get';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import {isEmpty, isPlainObject} from 'lodash';
import {clearEmptyProps, sortByDefinedOrder} from './commonHelper';
import {getPaginationText, getProperDynamicText, prepareBasicPattern} from './metaContentHelper';
import {capitalizeEachWord, formatPriceWithDecimal} from '@dmm/lib-common/lib/formatting';
import {urlParamToFormattedString} from './urlHelpers/boatDealers';
import { unslugify } from './urlHelpers/listings';


export const  engineTypes = [
  {id: 'all', name: 'All'},
  {id: 'direct+drive', name: 'Direct Drive'},
  {id: 'jet+drive', name: 'Jet Drive'},
  {id: 'none', name: 'None'},
  {id: 'other', name: 'Other'},
  {id: 'single+inboard', name: 'Single Inboard'},
  {id: 'single+i--o', name: 'Single I/O'},
  {id: 'single+outboard', name: 'Single Outboard'},
  {id: 'twin+inboard', name: 'Twin Inboard'},
  {id: 'twin+i--o', name: 'Twin I/O'},
  {id: 'twin+outboard', name: 'Twin Outboard'},
  {id: 'triple+i--o', name: 'Triple I/O'},
  {id: 'triple+outboard', name: 'Triple Outboard'},
  {id: 'v+drive', name: 'V-Drive'},
];

export const engineLegacyTypes = {
  'DIRECT DRIVE': 'direct+drive',
  'JET DRIVE': 'jet+drive',
  'NONE': 'none',
  'OTHER': 'other',
  'SINGLE': 'single+inboard',
  'SINGLE I--O': 'single+i--o',
  'SINGLE OUTBOARD': 'single+outboard',
  'TWIN': 'twin+inboard',
  'TWIN I--O': 'twin+i--o',
  'TWIN OUTBOARD': 'twin+outboard',
  'TRIPLE I--O': 'triple+i--o',
  'TRIPLE OUTBOARD': 'triple+outboard',
  'V-DRIVE': 'v+drive'
};

export const FUELTYPE_ALL_ITEM = { urlId: 'all', name: 'All', heading: 'All', value: 'all' };

/**
 * We do not get the proper names or a recursive structure from api.
 * Name mapping and indentation is only visual, not logical, so we need to create it through a workaround
 * to indent through styles.
 * We indent through the id, so we need to know its name in advanced.
 */
const mappedEngineNames = {
  'inboard': {displayName: 'Inboard', h1: 'Inboard', seoName: 'Inboard Engines', url: 'inboard-engine', queryParam: 'inboard'},
  'outboard': {displayName: 'Outboard', h1: 'Outboard', seoName: 'Outboard Motors', url: 'outboard-motor', queryParam: 'outboard,outboard-2s,outboard-4s'},
  'outboard-2s': {displayName: 'Outboard 2 Stroke', h1: '2 Stroke Outboard', seoName: '2 Stroke Outboard Motors', url: '2-stroke-outboard-motor', queryParam: 'outboard-2s'},
  'outboard-4s': {displayName: 'Outboard 4 Stroke', h1: '4 Stroke Outboard', seoName: '4 Stroke Outboard Motors', url: '4-stroke-outboard-motor', queryParam: 'outboard-4s'},
  'stern-drive': {displayName: 'Stern Drive', h1: 'Sterndrives', seoName: 'Sterndrives', url: 'sterndrive', queryParam: 'stern-drive'},
  'jet': {displayName: 'Jet', h1: 'Jet', seoName: 'Jets', url: 'jet', queryParam: 'jet'},
  'all': {displayName: 'All', h1: '', seoName: 'All',  url: 'all', queryParam: 'all'}
};

const mapKeys = Object.keys(mappedEngineNames);

const engineDisplayName = name => mappedEngineNames[name]?.displayName || name;

const enginePathName = name => mappedEngineNames[name]?.url || name;

const engineSeoName = name => mappedEngineNames[name]?.seoName || name;

const engineH1Complement = name => mappedEngineNames[name]?.h1 || name;

const engineQueryParam = name => mappedEngineNames[name]?.queryParam || name;

const engineKeyFromValue = val => mapKeys.find(
  key => mappedEngineNames[key].displayName === val ||
    mappedEngineNames[key].url === val ||
    mappedEngineNames[key].queryParam === val
);

const mappedEngine = (engine) => ({
  ...engine,
  name: engineDisplayName(engine.value),
  id: `${engine.value}`,
  pathName: enginePathName(engine.value)
});

const byValue = (a, b) => a?.value > b?.value ? 1 : -1;

// we map the categories and add the first "All" item;
const mapEngineCategories = engineCategories => {
  const mapped = engineCategories.sort(byValue).map(mappedEngine);
  const allCategory = mappedEngine({ value: ENGINES_ALL, count: 1 });
  return [allCategory ,...mapped];
};

const mergeEngineFacetsToKV = (facets, kv) => {
  let result = kv.map((item) => {
    let expected = facets.filter((facet) => {
      return item.value === facet.value;
    });
    return {
      ...item,
      count: get(expected, '[0].count', 0)
    };
  });
  return result;
};

const setUpEngineCatalogs = (facets, data) => {
  const engineTypes = mapEngineCategories(facets?.category || []);
  const fuelTypes = get(facets, 'fuelType', []);
  if (get(fuelTypes, '[0].value') !== 'all') {
    fuelTypes.unshift(FUELTYPE_ALL_ITEM);
  }
  const country = mergeEngineFacetsToKV(get(facets, 'country', []), US_COUNTRIES);
  const states = mergeEngineFacetsToKV(get(facets, 'state', []), US_STATES);
  const cities = get(facets, 'city', []);
  const makes = get(facets, 'make', []);
  const models = get(facets, 'makeModel', []);
  const selectedState = find(states, ['value', data.state]);

  // We are doing single faceted so we take element of index 0:
  const firstCity = get(data, 'city[0]');
  const selectedCity = find(cities, ['value', startCase(firstCity)]);

  // TODO: Remove stateCity logic as we will be pulling stateCity directly from api:
  const stateCity = [];
  if (selectedState) {
    selectedState.city = get(facets, 'city', []);
    stateCity.push(selectedState);
  }

  // TODO: Remove cityZip logic once we get it directly from api:
  const cityZip = [];
  if (selectedCity) {
    selectedCity.zip = get(facets, 'zip', []);
    cityZip.push(selectedCity);
  }
  return { engineTypes, states, country, selectedState, selectedCity, stateCity, cityZip, makes, fuelTypes, models };
};

const engineRefineSearchProps = (props) => {
  const { params, onDataChange, position, searchPage, loading, loadingFacets, facets, match } = props;
  const engineTypes = mapEngineCategories(props?.facets?.category || []);
  const engineCategory = params?.category;
  const engine = engineKeyFromValue(engineCategory);
  const displayName = engineDisplayName(engine);
  const pathName = enginePathName(engine);
  const queryParam = engineQueryParam(engine);
  const makeModel = params?.makeModel;
  const urlParsedParams = params;
  const { state, city, zip, ownerId } = urlParsedParams || {};
  const startParams = {
    engine,
    displayName,
    pathName,
    queryParam,
    searchPage,
    match,
    makeModel,
    state,
    city,
    zip,
    ownerId,
    radius: '200'
  };
  return { startParams, onDataChange, position, loading, loadingFacets, facets, engineTypes, urlParsedParams };
};

const nonNegativeString = n => isNaN(n) || n < 0 ? undefined : String(n);
const nonNegativeIntegerString = strNum => nonNegativeString(parseInt(strNum));
const nonNegativeFloatString = strNum => nonNegativeString(parseFloat(strNum));

const engineSearchParams = params => {
  const engineParams = {...params};
  const keys = Object.keys(DEFAULT_ENGINE_REFINE_SEARCH);
  if (engineParams.year?.min === undefined && engineParams.year?.max === undefined) {
    engineParams.year = {};
  }

  for (let key in engineParams) {
    if (!keys.includes(key)) {
      delete engineParams[key];
    }
  }
  return engineParams;
};

const getActiveEngineParams = (active) => {
  for (let key in active) {
    if (isPlainObject(active[key])) {
      active[key] = clearEmptyProps(active[key]);
    }
    if (isEmpty(active[key]) || active[key] === 'all' || active[key] === 'any') {
      delete active[key];
    }
  }

  return active;
};

const enginePillBoxFormat = active => {
  const pillboxArgs = {...active};
  if (pillboxArgs.fuelType && typeof pillboxArgs.fuelType === 'string'){
    pillboxArgs.fuelType = [pillboxArgs.fuelType];
  }
  return pillboxArgs;
};

const replaceMakeModelWithItsFirstValue = active => {
  const makeModel = active?.makeModel;
  if (makeModel) {
    const firstValid = Object.keys(makeModel).find(make => Array.isArray(makeModel[make]));
    active.make = firstValid;
    if (makeModel[firstValid].length) {
      active.model = makeModel[firstValid][0];
    }
    delete active.makeModel;
  }
  return active;
};

const replaceFuelTypeWithItsFirstValue = active => {
  const fuelType = active?.fuelType;
  if ((typeof fuelType === 'string' && fuelType !== '') || !Array.isArray(fuelType)) {
    return active;
  }
  const first = fuelType[0];
  if (!first || first === 'all') {
    delete active.fuelType;
    return active;
  }
  active.fuelType = first;
  return active;
};

/**
 * We clear state, zip and city and join them in a single property location
 * @param active active urlParams
 * @returns {*}
 */
const replaceStateCityZipWithLocation = active => {
  // If we have location we must have at least state
  if (!active?.state) {
    return active;
  }
  const state = active?.state;
  const zip = active?.zip;
  let city = active?.city;
  delete active?.state;
  delete active?.city;
  delete active?.zip;
  city = Array.isArray(city) && city[0] || null;
  active.location = {state, city, zip};
  return active;
};

const prepareFiltersToGeneratePatterns = params => {
  const active = getActiveEngineParams({...params});
  const cleared = replaceMakeModelWithItsFirstValue(replaceFuelTypeWithItsFirstValue(replaceStateCityZipWithLocation(active)));
  return cleared;
};

const preparePatternWithLocation = (filters) => {
  // Greater than 2, because one of them is: complement.
  const preparedFilters = filters.length > 2 ? filters.filter(f => f !== 'location') : filters;
  const pattern = prepareBasicPattern(preparedFilters);
  const hasLocation = preparedFilters.length === filters.length - 1;
  return { pattern, hasLocation };
};

/**
 * We iterate over filters to prepare a string pattern like this:
 * // -> :engine :powerHp :fuelType motors and engines for sale in :location
 * @param filters
 * @returns {string}
 */
const prepareHeaderTextsPattern = (filters) => {
  const basicPattern = preparePatternWithLocation(filters);
  let {pattern, hasLocation} = basicPattern;
  pattern = pattern.replace(':complement', '').trim();
  if (!pattern) {
    return '';
  }
  if (hasLocation) {
    return `${pattern} :complement in :location`;
  }
  return `${pattern} :complement`;
};

/**
 * We get different description for one filter and combined ones
 * @param filters
 * @returns {string}
 */
const prepareMetaDescriptionTextPattern = (filters) => {
  const basicPattern = preparePatternWithLocation(filters);
  const {pattern, hasLocation} = basicPattern;
  if (!pattern) {
    return '';
  }

  const justOne = filters.length === 1;
  const justMakeModel = filters.length === 2 && filters[1] === 'model';
  const justEngineType = justOne && filters[0] === 'engine';
  const justLocation = justOne && filters[0] === 'location';
  const inLocation = justLocation || hasLocation ? ' in :location ' : ' ';
  const repeatedPattern = justOne || justMakeModel ? ` ${pattern} ` : ' ';

  if (justEngineType) {
    return 'Find :engine motors and engines for your boat today on Boat Trader! Shop :count :engine motors and engines for sale';
  }

  if (justLocation) {
    return `Find motors and engines${inLocation}at Boat Trader today. Shop the best selection of :count outboard motors \u0026 inboard`;
  }

  return `Find your ${pattern} motors and engines${inLocation}at Boat Trader today. Shop the best selection of :count${repeatedPattern}outboard motors \u0026 inboard`;
};

/**
 * We can add as many corrections to the text as we want here.
 * @param headerText original header text for engines
 * @returns {string|*}
 */
const fixEnginesHeaderFormat = headerText => {
  if (!headerText) {
    return '';
  }
  return headerText;
};

const minMaxHeaderName = minMax => {
  const min = minMax?.min || '';
  const max = minMax?.max || '';

  if (min && max) {
    return `${min} to ${max}`;
  }
  const minMaxName = min || max || '';
  return minMaxName;
};

const powerHpHeaderName = powerHp => {
  const minMaxName = minMaxHeaderName(powerHp);
  return minMaxName ? `${minMaxName} hp` : '';
};

const priceHeaderName = price => {
  const min = formatPriceWithDecimal(price?.min);
  const max = formatPriceWithDecimal(price?.max);
  const priceName = minMaxHeaderName({min, max});
  return priceName ? `${priceName}` : '';
};

const locationHeaderName = location => {
  const {state, city, zip} = location;
  if (!state) {
    return '';
  }
  const usState = US_STATES.find(usState => usState.value === state);
  if (!city) {
    return usState.name;
  }
  if (!zip) {
    return `${capitalizeEachWord(unslugify(city, '-', ' '))}, ${usState.value}`;
  }

  return `${zip}, ${capitalizeEachWord(unslugify(city, '-', ' '))}, ${usState.value}`;
};

const conditionH1MetaName = (conditionText, patternText) => {
  return patternText.startsWith(':condition') ? capitalizeEachWord(conditionText) : conditionText;
};

const DEFAULT_H1_COMPLEMENT = 'Motors and Engines for sale';
const H1Complement = (_, patternText, headerParamsKeys, params) => {
  // We compare length 2, for 'engine' and 'complement':
  if (headerParamsKeys.length === 2 && headerParamsKeys.includes('engine')) {
    switch (params.engine) {
    case 'inboard':
    case 'jet':
      return 'Engines for sale';
    case 'outboard':
    case 'outboard-2s':
    case 'outboard-4s':
      return 'Motors for sale';
    case 'stern-drive':
      return 'for sale';
    }
  }
  return DEFAULT_H1_COMPLEMENT;
};

const headerFilterFormatters = {
  engine: engineH1Complement,
  make: urlParamToFormattedString,
  model: urlParamToFormattedString,
  fuelType: capitalizeEachWord,
  powerHp: powerHpHeaderName,
  condition: conditionH1MetaName,
  location: locationHeaderName,
  price: priceHeaderName,
  year: minMaxHeaderName,
  complement: H1Complement
};

const noLocationKeys = ['condition', 'engine', 'make', 'model', 'fuelType', 'powerHp', 'price', 'year'];
const engineHeaderKeys = [...noLocationKeys, 'location', 'complement'];

const getSortedFilters = (filters) => {
  const oneFilter = filters.length === 1 && engineHeaderKeys.includes(filters[0]);
  const sortedFilters = oneFilter ? filters : sortByDefinedOrder(filters, engineHeaderKeys);
  return sortedFilters;
};

const headerTextFinder = (filters) => {
  const headerPattern = prepareHeaderTextsPattern(getSortedFilters(filters));
  return headerPattern;
};

const metaDescriptionTextFinder = (filters) => {
  const headerPattern = prepareMetaDescriptionTextPattern(getSortedFilters(filters));
  return headerPattern;
};

export const getEnginesSRPHeader = (params, partyDetails) => {
  const defaultHeader = 'Outboard Motors and Boat Engines';
  const enginePreparedFilters = prepareFiltersToGeneratePatterns(params);

  if (enginePreparedFilters.ownerId && partyDetails) {
    let dealersHeader = partyDetails.name;
    if (partyDetails.address && partyDetails.address.city) {
      dealersHeader += `, ${partyDetails.address.city}`;
    }
    return dealersHeader;
  }

  enginePreparedFilters.complement = true;
  const headerTexts = {};
  const headerText = getProperDynamicText(
    enginePreparedFilters,
    headerTextFinder,
    headerFilterFormatters,
    engineHeaderKeys,
    headerTexts
  );
  const enginesHeaderText = fixEnginesHeaderFormat(headerText);
  return enginesHeaderText ? enginesHeaderText : defaultHeader;
};

export const getEnginesSRPMetaDescription = (params, count) => {
  const defaultDescription = 'Find Outboard Motors and Engines for your boat today on Boat Trader! Shop :count for sale from leading brands inc. Yamaha, Mercury, Tohatsu, Honda';
  const enginePreparedFilters = prepareFiltersToGeneratePatterns(params);
  const metaTexts = {};
  const greedy = true;
  const metaText = getProperDynamicText(
    enginePreparedFilters,
    metaDescriptionTextFinder,
    headerFilterFormatters,
    engineHeaderKeys,
    metaTexts,
    greedy
  );
  let description = metaText ? metaText : defaultDescription;
  const pageInfo = getPaginationText(params.page, params.pageSize, count).trim();
  if (pageInfo) {
    description += ` - ${pageInfo}`;
  } else {
    description += '.';
  }
  return description.replace(':count', count);
};

export {
  engineDisplayName,
  engineQueryParam,
  enginePathName,
  engineSeoName,
  mapEngineCategories,
  engineRefineSearchProps,
  engineKeyFromValue,
  setUpEngineCatalogs,
  mergeEngineFacetsToKV,
  nonNegativeIntegerString,
  nonNegativeFloatString,
  engineSearchParams,
  getActiveEngineParams,
  enginePillBoxFormat,
  prepareFiltersToGeneratePatterns,
  headerFilterFormatters
};

import React from 'react';
import get from 'lodash/get';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 9999 }
};

export default function Modal({ children, contentLabel, isOpen, onRequestClose, portalClassName, injectedStyles }) {
  return (
    <ReactModal
      portalClassName={portalClassName}
      contentLabel={contentLabel}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        content: { ...customStyles.content, ...get(injectedStyles, 'content', {}) },
        overlay: { ...customStyles.overlay, ...get(injectedStyles, 'overlay', {}) },
      }}
    >
      {children}
    </ReactModal>
  );
}

import React from 'react';
import classnames from 'classnames';
import './styles.css';
import { ToolSet, ToolSetButtonTray, ToolSetHeader, ToolSetInner, ToolSetSearchButton } from '../../../components/ToolSet';
import RefineSearch from '../RefineSearch';
import { SearchAlerts, SearchAlertsButton, SearchAlertsContainer, SearchAlertsFilterButton } from '../../../components/RefineSearch';
import SortSelector from '../SortSelector';
import {asArray} from '../../../utils/commonHelper';
import {useMobileRefineSearchHandlers} from '../../../utils/hooks/refineSearch/refineSearchHelpers';

const MobileSearchFilters = (props) => {
  const {mobileParams, currentParams, open, handleOpen, handleDataChange, handleSubmit} = useMobileRefineSearchHandlers(props);
  const {searchPage, match, facets = {}, componentWorking = []} = props;
  const loading = componentWorking.includes('facets');
  const isBranded = !!match?.params?.oem;
  const makes = asArray(facets?.make);
  const makeModels = asArray(facets?.makeModel);
  return (
    <div className={classnames('mobile-search-filters', { loading })}>
      <ToolSet id="tool-set-no-padding" open={open}>
        <ToolSetHeader onClick={handleSubmit}></ToolSetHeader>
        <ToolSetInner id="tool-set-inner-padding">
          <div className={classnames('spinner-preloader', { hidden: !loading })}></div>
          <RefineSearch {...props} onDataChange={handleDataChange} position="mobile" mobileParams={mobileParams}/>
        </ToolSetInner>
        <ToolSetButtonTray>
          {
            !isBranded && <SearchAlerts
              active={(mobileParams || currentParams)}
              makes={makes}
              makeModels={makeModels}
              {...props}
            />
          }
          <ToolSetSearchButton onClick={handleSubmit} isBranded={isBranded}></ToolSetSearchButton>
        </ToolSetButtonTray>
      </ToolSet>

      <div className="mobile-filter-header">
        <SearchAlertsContainer type="light" isBranded={isBranded}>
          <SearchAlertsFilterButton onClick={handleOpen} />
          { !isBranded && <SearchAlertsButton searchPage={searchPage} eventCategory="sort bar" {...props} active={currentParams} makes={makes} makeModels={makeModels} /> }
          <SortSelector scrollTo=".mobile-filter-anchor" {...props}/>
        </SearchAlertsContainer>
      </div>
    </div>
  );
};

export default MobileSearchFilters;

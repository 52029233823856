import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import app from './reducers/app';
import dataLayer from './reducers/dataLayer';

const rootReducer = combineReducers({
  app: app,
  dataLayer: dataLayer,
  routing: routerReducer
});

export default rootReducer;

import React, { useEffect, useState } from 'react';
import { Listing } from '@dmm/lib-react-ui-components';
import { useLoadedLogo } from '../../../../utils/dealerHelpers/custom';
import { getResizedImageSrc, LOGO_SIZES } from '../../../../utils/listingHelper';

const useSafeLogoProps = (props) => {
  const listingProps = {...props};
  const logoVisible = useLoadedLogo(listingProps?.logo);
  const listingWrapClass = logoVisible ? 'listing-wrap' : 'listing-wrap logo-hidden';
  if (!logoVisible) { delete listingProps.logo; }
  return {listingProps, listingWrapClass};
};

/**
 * Wrapper component for the Listing component that allows us to operate with props
 * in a much simpler way than in the long, class-based ListingResult component.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PreparedListing = (props) => {
  const { listingProps, listingWrapClass } = useSafeLogoProps(props);
  const [key, setKey] = useState(`ssr-${listingProps.id}`);

  useEffect(() => {
    setKey(`csr-${listingProps.id}-${listingProps.like.state}`);
  }, [listingProps.like.state]); // eslint-disable-line react-hooks/exhaustive-deps

  if (listingProps.logo) {
    listingProps.logo = getResizedImageSrc({ logoUrl: listingProps.logo }, LOGO_SIZES.listingCardLogo.w, LOGO_SIZES.listingCardLogo.h);
  }

  return (
    <div className={listingWrapClass} data-testid={key}>
      <Listing key={key} {...listingProps} />
    </div>
  );
};

export default PreparedListing;

import isEqual from 'lodash/isEqual';
import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Conveyer } from '@dmm/lib-react-ui-components';

import {
  getTitle,
  getPrice,
  getLocation,
  getDetailsData,
  getMediaImages, addResizeParams
} from '../../../utils/listingHelper';
import * as utils from '../../../store/utils';

const SocialCarouselConveyor = ({ listings = [], openContactForm, handleUserEvent, openShareModal, handleCarouselSpin }) => {
  const mapSbpDataToCarousel = () => {
    const mappedListings = listings.map((listing, index) => {
      const fullListingName = getTitle(listing);
      const location = getLocation(listing, false);
      const listingImages = getMediaImages(listing).map((image) => {
        return {
          image: addResizeParams(image.url, {width: 350, height: 250}, image.date.modified),
          imageAlt: fullListingName
        };
      });

      return {
        Listing: {
          onClick: undefined,
          price: getPrice(listing),
          name: fullListingName,
          location: location,
          like: {
            onClick: undefined,
            state: 'off',
            on: 'on',
            off: 'off'
          },
          contact: {
            onClick: (e) => openContactForm(e, index, listing),
            text: 'Request Info'
          },
          tags: [],
          classNames: {
            social: true
          },
          carousel: {
            prevClick: () => handleUserEvent(listing),
            nextClick: () => handleUserEvent(listing),
            images: listingImages.length > 0 ? listingImages : []
          },
          details: {
            header: 'Boat Details',
            items: [
              ...getDetailsData(listing)
                .filter(detail => detail.title !== 'Location')
                .map((detail => ({
                  key: detail.title,
                  value: detail.value
                })))
            ]
          },
          share: {
            onClick: () => openShareModal(index, listing),
            text: 'Share'
          }
        }
      };
    });

    return {
      Carousel: {
        items: mappedListings,
        callBack: handleCarouselSpin
      }
    };
  };

  const sbpListingItems = useMemo(mapSbpDataToCarousel, [listings, utils.isServer()]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="sbp-carousel">
      {sbpListingItems.Carousel.items.length && <Conveyer {...sbpListingItems} />}
    </div>
  );
};

SocialCarouselConveyor.PropTypes = {
  listings: PropTypes.array.isRequired,
  openContactForm: PropTypes.func.isRequired,
  handleUserEvent: PropTypes.func.isRequired,
  openShareModal: PropTypes.func.isRequired,
  handleCarouselSpin: PropTypes.func.isRequired,
};

export default memo(SocialCarouselConveyor, isEqual);

/**
 * One layer of abstraction is required for the function setOemDealers.
 * This is because it will be set by an async call within DealerLocatorModal
 * Without one layer of abstraction it will run and complete, but it will throw errors
 *  because of running an async set state function.
 */
import React from 'react';

import OemModal from '../../components/OemModal';
import { fetchListing } from '../../utils/oemContactFormHelper';

const OemModalContainer = (props) => {

  return (
    <>
      <OemModal
        oemDetails={props.oemDetails}
        onClose={props.onClose}
        isMobile={props.isMobile}
        listing={props.listing ? props.listing : props.listingId ? fetchListing(props.listingId) : {}}
        prePopulatedText={props.prePopulatedText}
        cta={props.cta}
      />
    </>
  );
};

export default OemModalContainer;

import React, { useEffect } from 'react';
import './styles.css';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { SuccessMessage } from '@dmm/lib-react-ui-components';

const oemMessage = 'Congratulations! We have sent your information directly to the seller.';

const Alert = ({ isOpen, isSuccessful, isOem, closeModal, content }) => {
  const title = isSuccessful ? content.success.title : content.failure.title;
  const message = isSuccessful ? content.success.message : content.failure.message;

  useEffect(() => {
    if (isOpen) {
      setTimeout(closeModal, 3000);
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isOpen) {
    return null;
  }

  return (
    <div className={'alert-modal-container modal'}>
      <div className={classnames('alert-modal', {'success': isSuccessful, 'failure': !isSuccessful})}>
        {!isOem &&
          (<button type="button" className="close" onClick={ () => closeModal() }>
            <span className="visuallyhidden">Close</span>
          </button>)
        }
        {isOem ?
          <SuccessMessage
            message={oemMessage}
            show={true}
            classNames={{'successCard': true}}
          /> :
          (<div className={'alert-message'} data-e2e="additional-leads-alert-message">
            <b>{ title }</b><br/>{ message }
          </div>)
        }
      </div>
    </div>
  );

};

Alert.propTypes = {
  isSuccessful: PropTypes.bool,
  isOEM: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  content: PropTypes.shape({
    failure: PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
    }),
    success: PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
    }),
  }).isRequired
};

export default Alert;

import { formatNumber } from '@dmm/lib-common/lib/formatting';
import classnames from 'classnames';
import includes from 'lodash/includes';
import isObject from 'lodash/isObject';
import React from 'react';
import { getFormattedParamsArray } from '../../../utils/urlHelpers/listings';
import Link from '../../SEO/Link';

const SearchAlertsFilterOptions = (props) => {
  const basePath = props.basePath || '/boats/';
  return (
    <Link to={!props.isMobile ? basePath : null} className="search-alerts-filter-options" data-e2e="srp-search-alerts-filter-options" onClick={props.onClear}>Clear All</Link>
  );
};

const SearchAlertsFilterTags = (props) => {
  const { active, handleDataChange, makes, makeModels, onClear, isMobile, basePath, searchPage } = props;
  let paramsAsArray = getFormattedParamsArray(active, makes, makeModels, searchPage).filter(param => param.key !== 'page');

  return (
    <>
      {paramsAsArray.length === 0
        ? <div className="search-alerts-filter-tags" data-e2e="srp-search-alerts-filter-tags"></div>
        : <div className="search-alerts-filter-tags" data-e2e="srp-search-alerts-filter-tags">
          <div className="search-alerts-filter-tags-scroller" data-e2e="srp-search-alerts-filter-tags-scroller">
            <SearchAlertsFilterOptions isMobile={isMobile} onClear={onClear} basePath={basePath} />
            {
              paramsAsArray.map(({ indexKey, key, value, prefix, suffix, formattedKey, format, href, newValue }) => {
                return <SearchAlertsFilterTagItem
                  isMobile={isMobile}
                  key={indexKey || key}
                  formattedKey={formattedKey}
                  href={href}
                  onClick={() => handleDataChange(key, newValue)}>

                  {prefix}
                  {!isObject(value) && value}
                  {isObject(value) && Object.entries(value)
                    .filter(([key]) => key !== 'prefix' && key !== 'suffix')
                    .map(([subKey, subValue], index) => {
                      return <span key={`${key}.${subKey}`}>{ !!index && ' - ' }{format === 'number' ? formatNumber(subValue, 'en-US') : subValue}</span>;
                    })}
                  {suffix}
                </SearchAlertsFilterTagItem>;
              })
            }
          </div>
        </div>
      }
    </>
  );
};

const SearchAlertsFilterTagItem = (props) => {
  const { formattedKey, children, href, onClick, isMobile } = props;
  return (
    <Link data-e2e="srp-search-alerts-filter-tag-item" className={classnames({'make': formattedKey && includes(formattedKey.toLowerCase(), 'make'), 'search-alerts-filter-tag-item': true})} to={(!isMobile ? href : null)} onClick={onClick}>
      {children}
    </Link>
  );
};

export {
  SearchAlertsFilterOptions, SearchAlertsFilterTagItem, SearchAlertsFilterTags
};


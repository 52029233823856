import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import DataLayer from '../../components/DataLayer';
import { withCookies } from 'react-cookie';

import { getMetaTitle, getMetaDescription } from '../../utils/metaContentHelper';

class DataLinkLayout extends PureComponent {

  render() {
    const { children, pageType, listing } = this.props;
    const params = get(this.props, 'match.params', {});
    const searchCount = get(this.props, 'search.count', 28);

    const linkSEO = <meta name="robots" content="noindex"></meta>;
    const metaTitle = getMetaTitle(pageType, params, searchCount, listing);
    const metaDescription = getMetaDescription(pageType, params, searchCount, listing);
    params.seoMakeInfo = this.props.seoMakeInfo;
    return (
      <div className="page-container datalink" id={this.props.id}>
        {this.props.statusCode !== 500 ?
          <Helmet>
            <title>{metaTitle}</title>
            <meta name="og:title" content={metaTitle} />
            <meta name="description" content={metaDescription} />
            <meta name="og:description" content={metaDescription} />
            {linkSEO}
          </Helmet> :
          null
        }
        <DataLayer pageType={pageType} />
        <div className="content nav-slide extra-padding">
          {children}
        </div>
      </div>
    );
  }
}

export default withCookies(connect(
  (state) => ({
    user: state.app.user,
    myboats: state.app.myboats
  }),
)(DataLinkLayout));

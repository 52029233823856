import { Action } from '@dmm/lib-react-ui-components';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pickBy from 'lodash/pickBy';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BOATS_FOR_SALE, BOATS_ID, BOAT_MAKES, BOAT_TYPES, ENGINE_MAKES, ENGINE_TYPES, ENGINE_TYPES_MAP } from '../../../constants/home';
import { trackSearchForm } from '../../../store/actions/dataLayer';
import { BRANCH_EVENT, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import { generateSearchPath } from '../../../utils/urlHelpers/listings';
import LocationEntry from '../LocationEntry';
import { buildParams, isValidZipCodeUS } from '../SearchSubmission/searchHelper';
import SelectDropdown from '../SelectDropdown';
import { getReportedBoatClassName, getReportedBoatTypeByClass } from './dataLayerHelper';

const TabForm = ({ heading, activeTab, formAttributes, productTypes, productMakes, locationEntryId, zip, onZipCodeChange, radiusMiles }) => {
  const { id, name, tabIndex } = formAttributes;
  const [facetType, setFacetType] = useState();
  const [facetMake, setFacetMake] = useState();
  const [searchPath, setSearchPath] = useState('');
  const dispatch = useDispatch();

  const buildSearchPath = () => {
    const params = buildParams({
      zipcode: zip,
      facetType: get(facetType, 'value', ''),
      facetMake: get(facetMake, 'value', ''),
      activeTab
    });

    if (params.zip) {
      params.radius = radiusMiles || 200;
    }
    const generatedPath = generateSearchPath({}, params, true, activeTab);
    setSearchPath(generatedPath);
  };

  useEffect(
    buildSearchPath,
    [zip, facetType, facetMake, activeTab] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFormOnSubmit = (e) => {
    e.preventDefault();
    const zipcodeValue = e?.currentTarget?.location?.value || zip;
    if (zipcodeValue === '' || isValidZipCodeUS(zipcodeValue)) {
      e.currentTarget.submit();

      handleTrackSearchForm(facetType, facetMake, zipcodeValue);
    }

    branchIoEventsManager(BRANCH_EVENT.SEARCH);
  };

  const handleTrackSearchForm = (facetType, facetMake, zipCode) => {
    let trackingData;
    facetMake = ['all'].includes(facetMake?.value) ? undefined : facetMake;
    if (activeTab === BOATS_ID) {
      const targetType = getReportedBoatTypeByClass(facetType?.value);
      const targetClass = getReportedBoatClassName(facetType?.value);
      trackingData = {type: targetType, class: targetClass, make: facetMake?.name, postalCode: zipCode};
    } else {
      const target = find(ENGINE_TYPES_MAP, (value, key) => key === facetType?.value);
      trackingData = {type: target?.value, class: target?.value, make: facetMake?.name};
    }
    dispatch(trackSearchForm(pickBy(trackingData, (item) => !isNil(item) && !isEmpty(item))));
  };

  return (
    <div data-e2e="hp-quick-search" className="quick-search">
      <h1 data-e2e="hp-quick-search-header">{heading}</h1>
      <form id={id} name={name} action={searchPath} onSubmit={handleFormOnSubmit}>
        <SelectDropdown
          dropdownData={productTypes}
          style={{ zIndex: 3 }}
          onSelectedItem={(item) => setFacetType(item)}
          defaultItem={ heading === BOATS_FOR_SALE ? BOAT_TYPES.all.data[0] : ENGINE_TYPES.all.data[0] }
          inputAriaLabel={ heading === BOATS_FOR_SALE ? 'All Boat Types' : 'All Engine Types' }
          selectedItem={facetType} />
        <SelectDropdown
          dropdownData={productMakes}
          style={{ zIndex: 2 }}
          onSelectedItem={(item) => setFacetMake(item)}
          defaultItem={ heading === BOATS_FOR_SALE ? BOAT_MAKES.top.data[0] : ENGINE_MAKES.top.data[0] }
          inputAriaLabel={ heading === BOATS_FOR_SALE ? 'All Boat Makes' : 'All Engine Makes' }
          selectedItem={facetMake} />
        <LocationEntry id={locationEntryId} style={{ zIndex: 1 }} zipcode={ zip } onZipCodeChange={(zipcode) => onZipCodeChange(zipcode)} radiusMiles={radiusMiles}/>
        <Action data-e2e="hp-quick-search-search-btn" stretch size="large" type="submit" tabIndex={tabIndex} label="Search" href="/link/" />
      </form>
    </div>
  );
};

export default TabForm;

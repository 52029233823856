import { UsaStates } from 'usa-states';
const US_STATE_NAMES = new UsaStates().arrayOf('names').map(name => name.toLowerCase().split(' ').join('-'));
export const LEGACY_BROWSE_URL_PATTERN = `/browse/:ignoreMake(make/all|make/%23|make/[A-Z])?/:makeState(make/[^/]+/[^/]+)?/:make(make/[^/]+)?/:city(city/[a-z|-]+)?/:rootCity(city)?/:state(state/[a-z|-]+)?/:rootState(state)?/:type([a-z|-]+)?/:typeState(${US_STATE_NAMES.join('|')})?/:typeMake(make/[^/]+)?/:class([a-z|-]+)?/`;
export const LEGACY_BROWSE_MAKE_PATTERN = new RegExp('make/([^/]+)');
export const LEGACY_BROWSE_MAKE_STATE_PATTERN = new RegExp(`make/([^/]+)/(${US_STATE_NAMES.join('|')})`);
export const LEGACY_BROWSE_MAKE_MODEL_PATTERN = new RegExp('make/([^/]+)/([^/]+)');
export const LEGACY_BROWSE_MAKE_MAPPER = {
  'c&c yachts': 'c&c',
  'sea doo': 'sea-doo',
  'o\' day': 'o\'day',
  'l & h boats': 'l&h',
  's&s pilothouse': 'sparkman & stephens',
  'jc tritoon marine': 'jc',
  'nautic star': 'nauticstar',
  'mystic catboat': 'mystic',
  'galaxie boat works': 'galaxie',
  'clark boats san juan': 'clark',
  'downeast yachts': 'downeast',
  'aftershock power boats': 'aftershock',
  'ranger boats': 'ranger',
  'lund boats': 'lund',
  'everglades boats': 'everglades',
  'montgomery ward': 'montgomery',
  'judge yachts': 'judge',
  'cnm-france': 'cnm',
  'pro-gator': 'progator',
  'whitewater boats': 'whitewater',
  'apex inflatables': 'apex inflatable',
  'challenger boats': 'challenger',
  'heyday inboards': 'heyday',
  'halvorsen marine': 'halvorsen',
  'beachcat boats inc': 'beachcat',
  'hurricane boats': 'hurricane',
  'catalina yachts': 'catalina',
  'benetti shipyard': 'benetti',
  'ankona boats': 'ankona',
  'kenner mfg co': 'kenner',
  'kachina boats': 'kachina',
  'hcb yachts': 'hcb',
  'gulf craft inc': 'gulf craft',
  'conquest boats': 'conquest',
  'diesel ducks': 'diesel duck',
  'baystealth vip': 'bay stealth',
  'holby marine': 'holby',
  'gillgetter pontoons': 'gillgetter',
  'malibu boats': 'malibu',
  'imperial boats': 'imperial',
  'bad cats mfg, inc.': 'bad cats',
  'tidewater boats': 'tidewater',
  'aac marine': 'aac marine',
  'sun tracker boats': 'sun tracker',
  'cabo yachts inc': 'cabo',
  'robertson and caine': 'robertson',
  'nantucket boat works': 'nantucket',
  'commander boats': 'commander',
  'premium parasail inc': 'premium',
  'performance cruising': 'performance',
  'tmc inc': 'tmc',
  'goldline': 'grand inflatables',
  'havoc boats': 'havoc',
  'dusky marine': 'dusky',
  'ocean kayak inc': 'ocean kayak',
  'skiffcraft': 'skiff craft',
  'xcursion pontoons': 'xcursion',
  'cheetah boats': 'cheetah',
  'gibson houseboats': 'gibson',
  'bennington boats': 'bennington',
  'boulton powerboats': 'boulton',
  'hydrodyne boat co inc': 'hydrodyne',
  'cobia boats': 'cobia',
  'forester boats': 'forester',
  'advantage boats': 'advantage',
  'c-hawk boats': 'c hawk',
  'mach i-freedom boats': 'mach 1',
  'bay hawk industries inc': 'bay hawk',
  'aries boats': 'aries',
  'boatel houseboats': 'boatel',
  'bass cat boats': 'bass cat',
  'andros boatworks': 'andros',
  'essex performance boats': 'essex boats',
  'haynie\'s boat works': 'haynie',
  'fountain powerboats': 'fountain',
  'sea vee': 'seavee',
  'hanse yachts': 'hanse',
  'eastern boats': 'eastern',
  'competition boats': 'competition',
  'triton boats': 'triton',
  'carver boats': 'carver',
  'chrysler corp': 'chrysler',
  'baretta manufacturing ltd': 'baretta',
  'barracuda boats inc': 'barracuda',
  'paragon gears': 'paragon',
  'vanquish boats': 'vanquish',
  'black water': 'blackwater',
  'crest pontoon boats': 'crest',
  'sea hunter': 'seahunter',
  'blazer boats': 'blazer',
  'angler boats': 'angler',
  'grumman boats': 'grumman',
  'gregor boat co': 'gregor',
  'stoner\'s boat works': 'stoner',
  'yankee yachts': 'yankee',
  'tracker boats': 'tracker',
  'craig blackwell': 'blackwell',
  'sloop palmer johnson': 'sloop',
  'hacker craft co': 'hacker craft',
  'absolute powerboats': 'absolute',
  'jones brothers marine': 'jones brothers',
  'symbol yachts': 'symbol',
  'godfrey marine': 'godfrey',
  'lowe boats': 'lowe',
  'howard custom boats': 'howard',
  'sea craft': 'seacraft',
  'continental trailers': 'continental',
  'creative dynamics llc': 'creative marine',
  'olympic boat co': 'olympic',
  'gause built boats': 'gause built',
  'fun country marine ind inc': 'fun county',
  'winner boats': 'winner',
  'calyber boatworks': 'calyber',
  'nordia van dam': 'nordia',
  'willis beals': 'willis beal',
  'envision boats inc': 'envision',
  'osborn boats': 'osborne'
};

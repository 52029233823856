import React from 'react';
import { checkPreQualVariation, makeFinanceableSummaryTrackingEvent } from '../../../../utils/trident';
import './styles.css';

const trackPreQualLinkClick = (actionLabel) => {
  const preQualTracking = makeFinanceableSummaryTrackingEvent(actionLabel, 'click');
  window.dataLayer.push(preQualTracking);
};

export const GetPrequalifiedCTA = (props) => {
  const { listing } = props;
  return <div className="prequalify-container">
    <div className="prequalify-title">
        Get pre-qualified in minutes
    </div>
    <ul className="summary-step-text">
      <li>We’ll compare over 15 lenders to get you the best rate & terms</li>
      <li>Customized financing to meet your needs</li>
      <li>Over 25 years of marine lending experience</li>
    </ul>
    <button
      onClick={() => {
        trackPreQualLinkClick('pre-qualify & get started');
        checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
      }}
      className="prequalify-button"
    >Get Pre-Qualified</button>
  </div>;
};

import { adsHelper } from '@dmm/react-common-components';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { COMMERCIAL, FSBO } from '../../../../constants/boatDetails';
import { canonicalizeDataString, isValidCondition } from '../../../../utils/ads/adsUtils';
import { getLegacyMappingFromBoatClass } from '../../../../utils/classHelper';
import { isFSBOContact } from '../../../../utils/listingHelper';

export const adsConfig = {
  'div-gpt-leaderboard-top': {
    unitPath: '/252108799/BoatTrader/boat-details/leaderboard-top',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728,90]
    ],
    targeting: {
      pos: 'leaderboard-top'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  },
  'div-gpt-modal-leaderboard-top': {
    unitPath: '/252108799/BoatTrader/image-carousel/leaderboard-top',
    breakpoint: {
      min: 768,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728,90]
    ],
    targeting: {
      pos: 'leaderboard-top'
    },
    isLazy: true,
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309832'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '667342044'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599762'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199040'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346705'
          }
        }
      ]
    }
  },
  'div-gpt-carousel-box-1': {
    unitPath: '/252108799/BoatTrader/image-carousel/box-1',
    breakpoint: {
      min: 1440,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250],
      [300, 600]
    ],
    targeting: {
      pos: 'box-1'
    },
    isLazy: true,
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309832'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '667342044'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599762'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199040'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346705'
          }
        }
      ]
    }
  },
  'div-gpt-leaderboard-bottom': {
    unitPath: '/252108799/BoatTrader/boat-details/leaderboard-bottom',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728, 90]
    ],
    targeting: {
      pos: 'leaderboard-bottom'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309655'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '667342044'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599608'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198852'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346678'
          }
        }
      ]
    },
    isLazy: true
  },
  'div-gpt-box-1': {
    unitPath: '/252108799/BoatTrader/boat-details/box-1',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300,250],
      [300,600]
    ],
    targeting: {
      pos: 'box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309652'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '767284432'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599609'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198842'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346674'
          }
        }
      ]
    }
  },
  'div-gpt-box-2': {
    unitPath: '/252108799/BoatTrader/boat-details/box-2',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309653'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '487618234'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599610'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198844'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346675'
          }
        }
      ]
    },
    isLazy: true
  },
  'div-gpt-box-3': {
    unitPath: '/252108799/BoatTrader/boat-details/box-3',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-3'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309654'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '155588488'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599612'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198846'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346676'
          }
        }
      ]
    },
    isLazy: true
  },
  'div-gpt-mobile-leaderboard-1': {
    unitPath: '/252108799/BoatTrader/boat-details/mobile-leaderboard-1',
    breakpoint: {
      min: 0,
      max: 1199
    },
    size: [
      [320, 50]
    ],
    targeting: {
      pos: 'mobile-leaderboard-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14383528'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '638614139'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540610998'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199156'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346709'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-carousel-leaderboard-1': {
    unitPath: '/252108799/BoatTrader/image-carousel/mobile-leaderboard-1',
    breakpoint: {
      min: 0,
      max: 1199
    },
    size: [
      [320, 50]
    ],
    targeting: {
      pos: 'mobile-leaderboard-1'
    },
    isLazy: true,
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14383528'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '638614139'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540610998'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199156'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346709'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-box-1': {
    unitPath: '/252108799/BoatTrader/boat-details/mobile-box-1',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309631'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '188838085'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599588'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199154'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346708'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-box-2': {
    unitPath: '/252108799/BoatTrader/boat-details/mobile-box-2',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '15803730'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '429854578'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540724224'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1271356'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346706'
          }
        }
      ]
    }
  },
  'div-gpt-Insurance': {
    unitPath: '/252108799/BoatTrader/boat-details/Insurance',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: ['fluid'],
    targeting: {
      pos: 'Insurance'
    },
    prebid: {
      bids: []
    },
    isLazy: true
  },
  'div-gpt-shipping': {
    unitPath: '/252108799/BoatTrader/boat-details/shipping',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: ['fluid'],
    targeting: {
      pos: 'shipping'
    },
    prebid: {
      bids: []
    },
    isLazy: true
  },
  'div-gpt-ad-rental': {
    unitPath: '/252108799/BoatTrader/boat-details/rental',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: ['fluid'],
    targeting: {
      pos: 'ad-rental'
    },
    prebid: {
      bids: []
    },
    isLazy: true
  },
  'div-gpt-ad-service-1': {
    unitPath: '/252108799/BoatTrader/boat-details/service-1',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: ['fluid'],
    targeting: {
      pos: 'ad-service-1'
    },
    prebid: {
      bids: []
    },
    isLazy: true
  },
  'div-gpt-ad-service-2': {
    unitPath: '/252108799/BoatTrader/boat-details/service-2',
    breakpoint: {
      min: 0,
      max: Number.MAX_SAFE_INTEGER
    },
    size: ['fluid'],
    targeting: {
      pos: 'ad-service-2'
    },
    prebid: {
      bids: []
    },
    isLazy: true
  }
};

export const getTargeting = (listing, refresh) => {

  let length = get(listing, 'specifications.dimensions.lengths.nominal.ft');
  let state = get(listing, 'location.address.state');
  let zip = get(listing, 'location.address.zip');
  let ownerId = get(listing, 'owner.id');
  let isFSBO = isFSBOContact(get(listing, 'contact'), {});

  let targeting = {
    type: canonicalizeDataString(listing.type),
    class: canonicalizeDataString(getLegacyMappingFromBoatClass(listing.class)),
    condition: isValidCondition(listing.condition) ? listing.condition : undefined,
    length: adsHelper.getLengthRanges(length, length),
    year: adsHelper.getYearRanges(listing.year, listing.year),
    make: listing.make ? canonicalizeDataString(listing.make) : undefined,
    // eslint-disable-next-line camelcase
    dealer_id: ownerId,
    state: state || undefined,
    // eslint-disable-next-line camelcase
    zip_code: zip ? zip : undefined,
    page: 'boat-details',
    modelyear: listing.year?.toString(),
    // eslint-disable-next-line camelcase
    li_customer_type: isFSBO ? FSBO : COMMERCIAL,
    refresh
  };

  if (listing.isOemModel) {
    targeting.oem = true;
  }

  targeting = omitBy(targeting, isUndefined);

  return targeting;
};

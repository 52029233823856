//Adding { behavior: 'smooth' } will animate the scroll
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
export const scrollTo = (selector, options) => {
  try {
    document.querySelector(selector).scrollIntoView(options);
  } catch (e) {
    console.error(e);
  }
};

export const scrollToTop = () => {
  window.scrollTo(0,0);
};

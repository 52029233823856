import * as types from '../constants';
import * as httpClient from '../../utils/httpClient';
import get from 'lodash/get';
import { RATES_TRIDENT_API_URL } from '../../constants/BoatLoans';
import cache from 'memory-cache';

const cacheTime = 15 * 60 * 1000;

const setTridentRates = data => ({
  type: types.TRIDENT_SET_RATES,
  data
});

export const getFicoScores = (tridentRates) => {
  const rate = tridentRates?.[0] || [];
  const ficoScores = Object.keys(rate).filter(key => {
    return key.match(/^\d+-\d+$/);
  });
  return ficoScores;
};
const setTridentLeadSubmitted = data => ({
  type: types.TRIDENT_LEAD_SUBMITTED,
  data
});

export const getRatesFromTridentAPI = () => async (dispatch, _getState, http) => {
  let result;
  try {
    const cachedData = cache.get('rates');
    if (!cachedData) {
      const apiClient = http || httpClient.getHttpClient();
      const res = await apiClient.get(RATES_TRIDENT_API_URL);
      cache.put('rates', res.data, cacheTime);
      cache.put('rates-last-success', res.data);
      result = dispatch(setTridentRates(res.data));
    } else {
      result = dispatch(setTridentRates(cachedData));
    }
  } catch (err) {
    const data = get(err, 'response.data', err.toString());
    // eslint-disable-next-line no-console
    console.error(data.toString());
    const cachedLastSuccess = cache.get('rates-last-success');
    if (cachedLastSuccess) {
      result = dispatch(setTridentRates(cachedLastSuccess));
    }
  }

  return result;
};

export const setSubmittedPreQualifyForm = () => dispatch => {
  dispatch(setTridentLeadSubmitted(true));
};

import classnames from 'classnames';
import React, { PureComponent } from 'react';
import Link from '../SEO/Link';
import './styles.css';

class BreadCrumb extends PureComponent {
  render() {
    let { items = [], dataTestId = 'breadcrumb-component', payingBrandSRP, dataTestSp = 'breadcrumbs' } = this.props;
    return (
      <>
        <ul className={classnames('breadcrumb',{ payingBrandSRP: payingBrandSRP})} vocab="https://schema.org/" typeof="BreadcrumbList" data-testid={dataTestId} data-test-sp={dataTestSp}>
          {
            items.map(({ type, link, title }, index) => {
              let last = index + 1 === items.length;
              return link ?
                <li key={index} property="itemListElement" typeof="ListItem" className={classnames({[type]: type})}>
                  <Link href={link} property="item" typeof="WebPage">
                    <span property="name">{title}</span>
                  </Link>
                  <meta property="position" content={index + 1} />
                  { !last && <span className="divider">
                    <span className="divider-desktop">/</span>
                    <span className="divider-mobile">/</span>
                  </span> }
                </li>
                :
                <li key={index} className="active">
                  {title}
                  { !last && <span className="divider">
                    <span className="divider-desktop">/</span>
                    <span className="divider-mobile">/</span>
                  </span> }
                </li>;
            })

          }
        </ul>
      </>
    );
  }
}

export default BreadCrumb;

import { BOAT_DETAILS_URL_PATTERN } from '../../constants/boatDetails';
import { matchPath } from 'react-router-dom';
import get from 'lodash/get';
import last from 'lodash/last';

export const parseBoatDetailParams = (url) => {
  let { params } = matchPath(url, BOAT_DETAILS_URL_PATTERN);
  return {
    id: last(params.id.split('-'))
  };
};

export const getListingReactUrlParams = (listing, isEngine ) => {
  let url = '';
  if (isEngine && listing.portalLinks) {
    url = get(listing, 'portalLinks[0].url', '');
  } else {
    url = get(listing, 'portalLink', '');
  }

  return { href: url.replace(/^(http(s)?:)?(\/\/)?([^/]+)?/g, '') };
};

export const getBoatUrl = (listing, fullUrl) => {
  if (fullUrl){
    return listing.portalLink;
  }
  let portalLink = get(listing, 'portalLink', '');
  let url = portalLink.replace(/^(http(s)?:)?(\/\/)?([^/]+)?/g, '');
  return url;
};

export const prefixedUrl = (url, protocol = null) => {
  if (!url) {
    return null;
  }
  if (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//')) {
    return url;
  }
  return `${protocol ? protocol : '//'}${url}`;
};

import get from 'lodash/get';
import {DEFAULT_MIN_YEAR} from '../../../../constants/boats';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import {canonicalizeDataString, isValidCondition} from '../../../../utils/ads/adsUtils';
import {unhyphenateUrlComponents} from '../../../../utils/urlHelpers/listings';
import {adsHelper} from '@dmm/react-common-components';
import {getFilterCount} from '../../../../utils/commonHelper';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

const adsConfig = {
  // Right side desktop adds
  'div-gpt-box-1': {
    unitPath: '/252108799/BoatTrader/engine-search/box-1',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 600],
      [300, 250]
    ],
    targeting: {
      pos: 'box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309643'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '767284432'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599600'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198894'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346679'
          }
        }
      ]
    }
  },
  'div-gpt-box-2': {
    unitPath: '/252108799/BoatTrader/engine-search/box-2',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309644'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '487618234'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599601'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198896'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346680'
          }
        }
      ]
    },
    isLazy: true
  },
  'div-gpt-box-3': {
    unitPath: '/252108799/BoatTrader/engine-search/box-3',
    breakpoint: {
      min: 970,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'box-3'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309645'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '155588488'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599602'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198898'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346681'
          }
        }
      ]
    },
    isLazy: true
  },

  // Engines list first ad desktop: in boats -> div-gpt-leaderboard-alpha
  'div-gpt-leaderboard-alpha': {
    unitPath: '/252108799/BoatTrader/engine-search/leaderboard-alpha',
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728, 90],
      [728, 180]
    ],
    targeting: {
      pos: 'leaderboard-alpha'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309646'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '747750205'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599603'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198900'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346682'
          }
        }
      ]
    }
  },
  // Engines list second ad desktop
  'div-gpt-leaderboard-mid-1': {
    unitPath: '/252108799/BoatTrader/engine-search/leaderboard-mid-1',
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'leaderboard-mid-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309648'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '289762647'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599605'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198906'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346683'
          }
        }
      ]
    },
    isLazy: true
  },
  // Engines list third ad desktop
  'div-gpt-leaderboard-mid-2': {
    unitPath: '/252108799/BoatTrader/engine-search/leaderboard-mid-2',
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'leaderboard-mid-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309649'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '228393545'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599606'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198908'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346684'
          }
        }
      ]
    },
    isLazy: true
  },
  // Engines list fourth ad desktop
  'div-gpt-leaderboard-mid-3': {
    unitPath: '/252108799/BoatTrader/engine-search/leaderboard-mid-3',
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'leaderboard-mid-3'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309649'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '228393545'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599606'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198908'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346684'
          }
        }
      ]
    },
    isLazy: true
  },
  // Boats list fifth ad desktop
  'div-gpt-leaderboard-bottom': {
    unitPath: '/252108799/BoatTrader/engine-search/leaderboard-bottom',
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'leaderboard-bottom'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309649'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '228393545'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599606'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198908'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346684'
          }
        }
      ]
    },
    isLazy: true
  },
  // Engines list adhesion ad desktop
  'div-gpt-ad-leaderboard-adhesion': {
    unitPath: '/252108799/BoatTrader/engine-search/leaderboard-adhesion',
    breakpoint: {
      min: 1116,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [728, 90],
    targeting: {
      pos: 'adhesion-bottom'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309649'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '228393545'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599606'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198908'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346684'
          }
        }
      ]
    },
    isLazy: true
  },

  // Mobile adds
  'div-gpt-mobile-box-1': {
    unitPath: '/252108799/BoatTrader/engine-search/mobile-box-1',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [320, 50],
      [320, 80],
      [320, 100]
    ],
    targeting: {
      pos: 'mobile-box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309629'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '188838085'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599586'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199158'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346710'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-box-2': {
    unitPath: '/252108799/BoatTrader/engine-search/mobile-box-2',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309630'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '402932794'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599587'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199160'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346711'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-box-3': {
    unitPath: '/252108799/BoatTrader/engine-search/mobile-box-3',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [300, 250]
    ],
    targeting: {
      pos: 'mobile-box-3'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309630'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '402932794'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599587'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199160'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346711'
          }
        }
      ]
    }
  },
  'div-gpt-ad-mobile-leaderboard-adhesion': {
    unitPath: '/252108799/BoatTrader/engine-search/mobile-leaderboard-adhesion',
    breakpoint: {
      min: 0,
      max: 969
    },
    size: [
      [320, 50]
    ],
    targeting: {
      pos: 'mobile-adhesion-bottom'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309630'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '402932794'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599587'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199160'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346711'
          }
        }
      ]
    }
  }
};

const getTargeting = (searchQuery) => {
  const lengthMin = get(searchQuery.length, 'min') !== '0' || get(searchQuery.length, 'max') ? get(searchQuery.length, 'min') : undefined;
  const lengthMax = get(searchQuery.length, 'max');
  const yearMin = get(searchQuery.year, 'min') !== String(DEFAULT_MIN_YEAR) || get(searchQuery.year, 'max') ? get(searchQuery.year, 'min') : undefined;
  const yearMax = get(searchQuery.year, 'max');
  let horsePowerMin = get(searchQuery.powerhp, 'min') !== '0' || get(searchQuery.powerhp, 'max') ? get(searchQuery.powerhp, 'min') : undefined;
  let horsePowerMax = get(searchQuery.powerhp, 'max');

  // TODO: when we setUp dealer gallery for engines we need to review this section.
  // It should work fine if we follow the same approach we defined in the corresponding tickets
  let dealerId;
  if (!isEmpty(get(searchQuery, 'ownerId'))) {
    const ownerId = get(searchQuery, 'ownerId');
    dealerId = unhyphenateUrlComponents(ownerId.substring(ownerId.lastIndexOf('-') + 1));
  }
  let targeting = {
    length: adsHelper.getLengthRanges(lengthMin, lengthMax),
    year: adsHelper.getYearRanges(yearMin, yearMax),
    powerhp: adsHelper.getHorsePowerRanges(horsePowerMin, horsePowerMax),
    condition: isValidCondition(searchQuery.condition) ? searchQuery.condition : undefined,
    category: searchQuery.category || undefined,
    make: searchQuery.makeModel ? canonicalizeDataString(keys(searchQuery.makeModel)[0]) : undefined,
    state: searchQuery.state || undefined,
    // eslint-disable-next-line camelcase
    zip_code: searchQuery.zip ? searchQuery.zip : undefined,
    page: 'engine-search',
    filterCount: getFilterCount(searchQuery) || undefined,
    dealerId
  };
  targeting = omitBy(targeting, isUndefined);
  return targeting;
};

// This ad gets sticky on mobile.
// the class gets added at run time
const sponsoredAd = {
  adId: 'div-gpt-mobile-box-1'
};

const sideBarTopAd = {
  adId: 'div-gpt-box-3',
  classes: 'right-rail-ad ad-300'
};

const sideBarAd1 = {
  adId: 'div-gpt-box-1',
  classes: 'right-rail-ad ad-300 box-holder'
};

const sideBarAd2 = {
  adId: 'div-gpt-box-2',
  classes: 'right-rail-ad ad-300 box-holder'
};

const nativeDesktopAd = {
  adId: 'div-gpt-leaderboard-bottom',
  classes: 'ad ad-desk-native'
};

const nativeMobile = {
  adId: 'div-gpt-ad-mobile-native-1',
  classes: 'ad ad-desk-native'
};

const leaderBoardAlpha = {
  adId: 'div-gpt-leaderboard-alpha',
  classes: 'ad header footer w728'
};

const leaderBoardAdhesion = {
  adId: 'div-gpt-ad-leaderboard-adhesion',
  classes: 'ad adhesion footer'
};

const mobileLeaderBoardAdhesion = {
  adId: 'div-gpt-ad-mobile-leaderboard-adhesion',
  classes: 'ad adhesion footer'
};

const maxDesktopAds = 3;
const initialMobileCount = 1;
const initialDesktopCount = 0;

export const engineAds = {
  getTargeting,
  adsConfig,
  initialDesktopCount,
  initialMobileCount,
  maxDesktopAds,
  adPosition: {
    leaderBoardAlpha,
    nativeDesktopAd,
    nativeMobile,
    sideBarTopAd,
    sponsoredAd,
    sideBarAd2,
    sideBarAd1,
    leaderBoardAdhesion,
    mobileLeaderBoardAdhesion
  }
};

import classnames from 'classnames';
import get from 'lodash/get';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BreadCrumb from '../../../components/BreadCrumb';
import LikedBoat from '../../../components/LikedBoat';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import Link from '../../../components/SEO/Link';
import ContactGroup from '../ContactGroup';
import * as scroll from '../../../utils/scrollTo';
import { getLocation, getPrice, getTitle } from '../../../utils/listingHelper';
import './styles.css';

class NextPrevious extends PureComponent {
  state = {
    showHeader: false,
  };

  scrollPos = 0;

  handleHeader() {
    let windowPos = document.documentElement.scrollTop || window.pageYOffset;

    if (windowPos < this.scrollPos) {
      if (this.state.showHeader) {
        this.updateHeader(false);
      }
    } else if (windowPos > 50) {
      if (!this.state.showHeader) {
        this.updateHeader(true);
      }
    }

    this.scrollPos = windowPos;
  }

  trackGenericClick = (buttonName) => () => {
    if (this.props.tracking.setGenericEvent) {
      this.props.tracking.setGenericEvent(
        'listings interactions',
        'button click',
        buttonName
      );
    }
  };

  updateHeader(sticky) {
    this.setState({ showHeader: sticky });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleHeader.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleHeader.bind(this));
  }

  render() {
    let { showHeader } = this.state;
    const {
      listing = {},
      myboats = [],
      breadCrumbs,
      cookies,
      url,
      isLoading,
      navLinks = {},
      showInfo,
      breakpoint,
      liked,
      onClickLike
    } = this.props;
    const { next, previous, searchUrl } = navLinks;
    return (
      <>
        <div
          className={classnames(
            'next-previous',
            {
              'show-info': showInfo,
              hidden: !previous && !next && breakpoint !== 'desktop',
            },
            { 'next-previous-top': showHeader }
          )}
        >
          <div>
            {searchUrl && (
              <Link
                data-e2e="back-to-search-button"
                className="next-previous-button next-previous-back"
                href={searchUrl}
                onClick={this.trackGenericClick('back to search')}
              >
                <span>Back to </span>Search
              </Link>
            )}
            {breakpoint === 'desktop' && (
              <div className="next-previous-info-container">
                {isLoading && <LoadingPlaceholder type="text" />}
                <BreadCrumb items={breadCrumbs.slice(0, -1)} />
                {!isLoading && listing.id && (
                  <div className="next-previous-info">
                    <div className="next-previous-listing-name">
                      {getTitle(listing)}
                    </div>
                    <div className="next-previous-listing-price">
                      {getPrice(listing)}
                    </div>
                    <div className="next-previous-listing-location">
                      {getLocation(listing)}
                    </div>
                    <div className="next-previous-listing-heart">
                      {listing.showSaveButton && (
                        <LikedBoat
                          listingId={get(listing, 'aliases.boat-trader')}
                          listing={listing}
                          myboats={myboats}
                          imtId={get(listing, 'aliases.imt')}
                          cookies={cookies}
                          url={url}
                          liked={liked}
                          onClickLike={onClickLike}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {breakpoint === 'desktop' && (
              <div className="button-tray">
                {listing.id && listing.showSaveButton && (
                  <LikedBoat
                    listingId={listing.aliases['boat-trader']}
                    listing={listing}
                    myboats={myboats}
                    imtId={listing.id}
                    cookies={cookies}
                    url={url}
                    showText={true}
                  />
                )}
              </div>
            )}
            <div className="next-previous-contact">
              <ContactGroup
                listing={listing}
                showIcons={true}
                onOpenContact={() =>
                  scroll.scrollTo('#details-contact-anchor', {
                    behavior: 'smooth',
                  })
                }
              />
            </div>
            <div
              className={classnames('next-previous-button-tray', {
                'no-next-previous': !previous && !next,
              })}
            >
              {previous && (
                <Link
                  data-e2e="previous-boat-button"
                  className="next-previous-button next-previous-prev"
                  href={previous}
                  onClick={this.trackGenericClick('previous boat')}
                >
                  Prev<span>ious Boat</span>
                </Link>
              )}
              {next && (
                <Link
                  data-e2e="next-boat-button"
                  className="next-previous-button next-previous-next"
                  href={next}
                  onClick={this.trackGenericClick('next boat')}
                >
                  Next<span> Boat</span>
                </Link>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, (dispatch) => bindActionCreators({}, dispatch))(
  NextPrevious
);

import React from 'react';
import { CDN_URL } from '../../../../../constants/BoatLoans';
import '../styles.css';

export const IntroductoryContent = () => {
  return (
    <div  className="financing-intro-container">
      <div className="bt-and-trident-logo-container">
        <img className="whyTrident-logos" alt="BoatTrader and Trident Funding" src={`${CDN_URL}/BT-TridentLogo-dark.svg`}/>
      </div>
      <div className="financing-intro-header-container">
        <h2 className="financing-intro-header">Boat Financing</h2>
      </div>
      <p className="financing-intro-text">Boats are a large purchase, second only to a home, and the upfront cost may be challenging for many individuals to cover with their savings alone. Most people who buy boats will get a loan to fund this expense. Boat loans provide a great way to spread out this cost over time, making them more affordable. Boat loans can also open up the possibility of affording a higher-quality or larger boat than you could purchase outright, enhancing your overall boating experience.</p>
      <p className="financing-intro-text">A boat loan is a type of loan used to finance the purchase of a boat, such as a yacht, sailboat, or fishing boat. Boat loans are similar to other types of loans, such as auto loans or home mortgages, in that they provide borrowers with the funds needed to purchase the asset upfront, and then require the borrower to repay the loan over time, typically with interest.</p>
      <p className="financing-intro-text">Boat loans are typically secured loans, meaning that the boat serves as collateral for the loan, which can help lower the interest rate on the loan. Boat loans can be obtained from a variety of lenders and the terms and conditions of the loan will depend on the borrower’s credit history, the amount of the loan, and the length of the loan term.</p>
    </div>

  );
};

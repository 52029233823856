
import React from 'react';
import '../styles.css';
import { ApplyNowButton } from '../../ApplyNowButton';

export const FinancingContentSectionOne = () => {
  return (
    <>
      <p className="financing-tab-content tab-content-one">Boat loans aren’t hard to get, but each lender will have their own set of boat loan requirements.
              You don’t have to have perfect credit or make a million dollars to get a boat loan. You will generally need a credit score of at least 600,
              enough money for a down payment of 10 to 20 percent of the cost of the boat, and you should be able to prove that you can afford the boat loan payments.
              If you’re not sure if you qualify for a boat loan, use a <a href="/boat-loans/calculator/" target="_blank">boat loan calculator</a> to see how much boat you can afford and your estimated monthly payment.
      </p>
    </>
  );
};

export const FinancingContentSectionTwo = () => {
  return (
    <p className="financing-tab-content tab-content-two">
      <ul>
        <li>
          <ApplyNowButton
            text="Pre-qualify"
            type="link"
            utmCampaign="btboatloanspage"
            utmContent="btboatloanspage"
          /> in minutes with just a few details about you, your desired loan amount, and downpayment.
        </li>
        <li>
          Apply with just a few more details about your income, employment history, and if applicable, your co-borrower’s information.
        </li>
        <li>
          To finalize your loan application, we’ll ask for your social security number and contact information.
        </li>
        <li>To expedite your funding, you’ll want to have the following documents available as PDFs or images on your phone or computer to upload via a secure document portal:
          <ul>
            <li>A purchase agreement (signed by you and the seller)</li>
            <li>Seller’s contact information (name, email, phone number)</li>
            <li>A copy of your driver’s license</li>
            <li>Proof of down payment (copy of down payment check or wire receipt)</li>
          </ul>
        </li>
      </ul>
    </p>
  );
};

import React, { PureComponent } from 'react';
import CarouselThumbs from '../CarouselThumbs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from '../Spinner';
import {Carousel} from 'react-responsive-carousel';
import CarouselItem from '../CarouselItem';
import { getTitle, getMediaImages, getMediaVideos } from '../../utils/listingHelper';
import { carouselButtonClick, carouselModalButtonClick } from '../../store/actions';
import { changeCarouselImage, openCarouselModal } from '../../store/actions/dataLayer';
import classnames from 'classnames';
import head from 'lodash/head';
import tail from 'lodash/tail';
import { DEFAULT_NO_BOAT_IMAGE } from '../../constants/boats';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './styles.css';

class CarouselDisplayer extends PureComponent {

  state = {
    index: 0
  }

  populateMediaArray(listing) {
    let images = getMediaImages(listing);
    let videos = getMediaVideos(listing);
    if (images.length > 0 || videos.length > 0) {
      if (images.length > 0) {
        return [head(images), ...videos, ...tail(images)];
      }
      return videos;
    }
    return [];

  }

  handleThumbClick = (event, index) => {
    this.setState({
      index: index
    });

    this.handleCarouselClickCount();
  }

  handleOnChangeCarouselItem = (index) => {
    if (index !== this.state.index) {
      this.setState({ index: index });
      this.props.changeCarouselImage();
    }

    this.handleCarouselClickCount();
  }

  handleCarouselClickCount = () => {
    if (this.props.show) {
      this.props.carouselModalButtonClick();
    } else {
      this.props.carouselButtonClick();
    }
    if (this.props.trackCarouselCount) {
      this.props.trackCarouselCount(this.state.index + 1);
    }
  }

  renderNoImage() {
    return (
      <div className="carousel-item" key={0}>
        <div className="item-wrapper">
          <img loading="lazy" width="200" height="132" src={DEFAULT_NO_BOAT_IMAGE} alt="Default no image"
            className={classnames('image-results', 'default', 'error')} />
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    const { selectedItem = 0 } = this.props;
    this.setState({
      index: selectedItem
    });
  }

  renderCarousel(listing, mediaItems) {
    let title = getTitle(listing);
    let mediaItemsToShow = [this.state.index - 1 === -1 ? mediaItems.length - 1 : this.state.index - 1,
      this.state.index, this.state.index + 1 === mediaItems.length ? 0 : this.state.index + 1];

    return (
      <>
        <Carousel className={classnames({ 'carousel-slider-modal': this.props.isModal })} useKeyboardArrows={true} showIndicators={false} showThumbs={false} infiniteLoop={true}
          selectedItem={this.state.index} onChange={this.handleOnChangeCarouselItem} >
          {mediaItems.length !== 0 ? mediaItems.map((item, key) => {
            return (
              <div className={classnames('carousel-item', { 'zoom-in': !this.props.isModal })} key={key} onClick={() => { if (!this.props.isModal) { this.props.showModal(key); } }}>
                <div className={classnames('carousel-div-over', {'hide': this.props.isModal})}></div>
                <CarouselItem current={key === mediaItemsToShow[1]} media={item} title={title}
                  show={mediaItemsToShow.includes(key)} />
              </div>
            );
          }) :
            this.renderNoImage()
          }
        </Carousel>
        {this.props.showThumbs &&
          <CarouselThumbs
            device={this.props.device}
            selectedItem={this.state.index}
            handleThumbClick={this.handleThumbClick}
            handleCarouselClickCount={this.handleCarouselClickCount}
            mediaItems={mediaItems}
          />
        }
      </>
    );
  }

  renderLoading() {
    return (
      <Spinner />
    );
  }

  render() {
    const { isLoading, listing } = this.props;
    const mediaItems = this.populateMediaArray(listing);

    return (
      <>
        {this.props.isModal ? <>
          {isLoading || !listing.id ?
            this.renderLoading() :
            this.renderCarousel(listing, mediaItems)
          } </> :
          <div className="carousel-container">
            {isLoading || !listing.id ?
              this.renderLoading() :
              this.renderCarousel(listing, mediaItems)
            }
          </div>}
      </>
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    changeCarouselImage, carouselButtonClick, carouselModalButtonClick, openCarouselModal
  }, dispatch)
)(CarouselDisplayer);

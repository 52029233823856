import React from 'react';
import PropTypes from 'prop-types';
import {normalizeString} from '@dmm/lib-common/lib/formatting';
import classnames from 'classnames';
import {generateSearchPath} from '../../../utils/urlHelpers/listings';
import BoatTypeList from '../BoatTypeList';

const BoatTypeSection = ({item, setGenericEvent, showSticky}) => {

  return (
    <div className="boat-type-content">
      <div id={normalizeString(item.title)} className={classnames('type-section', item.typeId)}>
        <h2>{item.title}</h2>
        <p>
          <span dangerouslySetInnerHTML={{__html: `${item.description}` }}></span>
          <a
            onClick={()=> {setGenericEvent('View More', 'click', item.title, '', 'detailed_page_name,Boat Types');}}
            href={generateSearchPath({multiFacetedBoatTypeClass: {[item.typeId]: []}}, {}, false)}>
              View All
          </a>
        </p>
      </div>
      <div className="boat-types-listings">
        <BoatTypeList listings={item.sections} boatType={item.typeId} typeTitle={item.title} setGenericEvent={setGenericEvent} showSticky={showSticky}/>
      </div>
    </div>
  );
};

BoatTypeSection.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    sections: PropTypes.array,
    typeId: PropTypes.string | PropTypes.number,
    description: PropTypes.string
  }),
  tmpListings: PropTypes.array,
  showSticky: PropTypes.func
};

export default BoatTypeSection;

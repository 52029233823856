/* eslint-disable max-len */

import React from 'react';

export const FAQ_SECTION_TITLE = 'FAQs About GAP Protection for Boats';
export const FAQ_DISCLAIMER_TEXT = '*Actual rate can vary depending on loan term and APR, or Annual Percentage Rate. Pricing reflects an example of an $8,000 service contract on a $50,000 boat financed for 240 months or 20 years. Trident Funding LLC markets and sells extended warranties on behalf of 3rd party providers and your extended warranty will be between you and the provider. Coverage, deductibles, and claims payment vary depending on vehicle age, mileage, preexisting conditions and program selected. Repairs can be made by any licensed repair facility. Program(s) available in most states. Not available in California or Florida. You consent to be contacted by email, phone, and/or text once we receive your quote submission. Texas and Wisconsin Residents: Contracts administered by Dealer Admin Services, 5810 W 78th Street, Suite 300, Bloomington, MN 55439, 877-746-4917. Vehicle names, logos, brands, & other trademarks featured or referred to within Trident Funding are property of their respective trademark holders. Extended warranties may not be sold where prohibited by law.';
export const FAQ_ACCORDION_CONTENT = [
  {
    title: 'Can I purchase GAP protection if I paid for my boat in full without financing?',
    content: <p>
      GAP protection is typically only available for boats with an outstanding loan balance.
    </p>
  },
  {
    title: 'Does GAP protection cover all types of boat loans?',
    content: <p>
      GAP protection can cover various types of boat loans, including retail installment contracts and leases.
    </p>
  },
  {
    title: 'Can I add GAP protection to my existing boat protection policy?',
    content: <p>
      GAP protection for boats is typically a separate coverage and cannot be added to an existing boat protection policy. It is a specialized protection product that specifically covers the &quot;gap&quot; between the actual cash value of your boat and the remaining loan balance in the event of a total loss. You may need to contact your protection provider or a separate GAP protection provider to obtain coverage.
    </p>
  },
  {
    title: 'How is the actual cash value of a boat determined for GAP protection purposes?',
    content: <p>
      The actual cash value (ACV) of a boat is typically determined based on several factors, including its age, condition, market value, and depreciation. In the context of GAP, the ACV is the value used to calculate the &quot;gap&quot; between the boat&apos;s value and the remaining loan balance. Protection adjusters or appraisers may consider factors such as comparable boat sales, market trends, and the boat&apos;s overall condition to determine its ACV.
    </p>
  },
];

import React, { memo, } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Ad } from '@dmm/react-common-components';
import AdProvider from '../../../components/Ads/AdProvider';
import { getAdsConfig } from '../MainContent/resources/adsConfig';
import queryString from 'query-string';

const SocialAd = ({ location, reloadAd }) => {
  const noAdsParam = get(queryString.parse(location.search), 'noAds', null) === 'true';
  const isWorking = useSelector((state) => state.app.isWorking);

  const mobileLeaderboardAd = {
    adId: 'div-gpt-mobile-leaderboard-1',
    classes: 'ad ad-inline-mobile-box',
    reloadAd: reloadAd.counter
  };
  return (
    <AdProvider
      url={location.pathname}
      isWorking={isWorking}
      targeting={{
        page: 'social-browse',
        refresh: mobileLeaderboardAd.reloadAd.toString()
      }}
      adsConfig={getAdsConfig()}
      noAdsParam={noAdsParam}
      retargetOnAdRefresh={true}>
      <div className="sbp-ad-slot">
        <Ad
          {...mobileLeaderboardAd}
          targeting={{refresh: mobileLeaderboardAd.reloadAd.toString()}}
        />
      </div>
    </AdProvider>
  );
};

export default memo(SocialAd, isEqual);

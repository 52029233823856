import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ENGINES_BASE_URL, MERCURY_REDIRECT_NAME, MERCURY_LEGACY_NAME } from '../../constants/Engines/';
import { getStateCode } from '../../utils/locationMapperHelper';

export const rewriteLegacyEnginesPage = props => {
  let params = get(props, 'match.params', {});
  if (!isEmpty(params) && params[0]) {
    if (params[0].includes('make')) {
      let make = params[0].split('/').join('-');
      make = make === MERCURY_LEGACY_NAME ? MERCURY_REDIRECT_NAME : make;
      return `${ENGINES_BASE_URL}${make}/`;
    }

    let stateCode = getStateCode(params[0]);
    if (stateCode) {
      return `${ENGINES_BASE_URL}state-${stateCode.substring(3).toLowerCase()}/`;
    }
  }
  return ENGINES_BASE_URL;
};

export const isOnlyZip = (params) => !!(params?.zip && (!params?.state || !params?.city));

export const detailsMapper = {
  description: {
    title: 'Curious about this boat?',
    content: 'Request more info',
    eventLabel: 'Curious about this boat'
  },
  measurements: {
    title: 'Need more details?',
    content: 'Message seller',
    eventLabel: 'Need more details'
  },
  propulsion: {
    title: 'Need more engine info?',
    content: 'Ask the seller',
    eventLabel: 'Need more engine info'
  },
  location: {
    title: 'Want to see this boat?',
    content: 'Schedule a visit or sea trial',
    eventLabel: 'Want to see this boat'
  },
  services: {
    title: 'Still have a question?',
    content: 'Contact seller',
    eventLabel: 'Still have a question'
  }
};

import React, { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import get from 'lodash/get';
import { CollapsibleContent, CollapsibleHeader, Collapsible } from '../../../components/Collapsible';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../components/ToolSet';
import { ENGINES_SORT_TYPES, getDefaultEnginesSort } from '../../../utils/sortHelper';
import { parseEngineSortParams } from '../../../utils/urlHelpers/listings';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSortByParam } from '../../../store/actions';

const EnginesSortBy = ({ closeOnOuterClick, closeOnInnerClick, initialState, onDataChange, ...props }) => {
  const [collapsibleInitialState] = useState(initialState || 'open');
  const [selectedValue, setSelectedValue] = useState('year:desc');

  const selectValue = () => {
    let urlParams = get(props, 'match.params', {});
    if (urlParams.sort) {
      let value = parseEngineSortParams(urlParams.sort).sort;
      setSelectedValue(value);
    } else {
      setSelectedValue(getDefaultEnginesSort(urlParams.zip, urlParams.city, urlParams.state));
    }
  };

  const handleDataChange = (value) => {
    setSelectedValue(value);

    if (onDataChange) {
      onDataChange(value);
    }

    props.getSortByParam(value);
  };

  useEffect(() => {
    selectValue();
  });

  let name = ENGINES_SORT_TYPES[selectedValue]?.label;

  return (
    <CollapsibleContent
      initialState={collapsibleInitialState}
      closeOnOuterClick={closeOnOuterClick}
      closeOnInnerClick={closeOnInnerClick}
    >
      <CollapsibleHeader type="title">
        <span className="criteria bp3">
          <span className="text">
            <span className="ref-length-longest-first-sort-by">Sort By: </span>
            <span className="ref-length-longest-first">{name}</span>
          </span>
        </span>
      </CollapsibleHeader>

      <Collapsible>
        <ToolSetOptions>
          {keys(ENGINES_SORT_TYPES).map((key) => (
            <ToolSetOptionsItem
              key={key}
              id={key}
              name="sort-by"
              value={key}
              onClick={handleDataChange}
              selected={selectedValue === key}
            >
              {ENGINES_SORT_TYPES[key].label}
            </ToolSetOptionsItem>
          ))}
        </ToolSetOptions>
      </Collapsible>
    </CollapsibleContent>
  );
};

export default connect(null, (dispatch) => bindActionCreators({ getSortByParam }, dispatch))(EnginesSortBy);

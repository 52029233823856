import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import './styles.css';
import * as utils from '../../utils/socialHelper';
import { trackSocialMedia, addLead, setSoftLeadEvent } from '../../store/actions/dataLayer';
import { getBoatUrl } from '../../utils/urlHelpers/boat';
import get from 'lodash/get';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import {SocialShare as SocialShareComponent} from '@dmm/lib-react-ui-components';

const SocialShare = (props) => {
  const { handleShareToggle } = props;
  const dispatch = useDispatch();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  useEffect(() => {
    setIsLinkCopied(false);
  }, [props.openShareModal]);

  const handleSocialShare = (name) => {
    const { listing } = props;
    const { id } = listing;
    const url = getBoatUrl(listing);
    utils.openSocialModal(`${name}`, listing);
    dispatch(addLead(id, `${name} share`));
    dispatch(trackSocialMedia(`${name}`, 'social share', url));
  };

  const handleShare = (name) => {
    if (name === 'print') {
      handleShareToggle();

      setTimeout(share(name), 1);
    } else {
      share(name);
    }
  };

  const share = (name) => {
    const { listing } = props;
    const { id } = listing;
    utils[name](listing);
    const url = getBoatUrl(listing, true);
    dispatch(addLead(id, `${name} listing`));
    dispatch(trackSocialMedia(`${name}`, `${name} listing`, url));
  };

  const handleCopyLink = () => {
    const { listing } = props;
    window.navigator.clipboard.writeText(getBoatUrl(listing, true));
    setIsLinkCopied(true);
  };

  const handleOpenContactForm = (e) => {
    handleShareToggle();

    if (props.breakpoint === 'desktop') {
      document.querySelector('.modal.fade').classList.toggle('hide');
    } else {
      props.handleOpenContactForm(e);
    }
  };

  const generateUrl = (owner) => {
    const dealerName = normalizeString(owner.name);
    return `/boats/dealer-${dealerName}-${owner.id}/`;
  };

  const handleTourClick = () => {
    setSoftLeadEvent('immersive tour');
    handleShareToggle();
    props.openImmersiveTourModal();
  };

  const handleAddLead = () => {
    const { listing } = props;
    dispatch(addLead(listing.id, 'view all inventory'));
  };

  const getMoreBoatsButtonText = () => {
    const { listing, breakpoint } = props;
    const isBranded = get(listing, 'oemDetails.brandedSearch', false);
    return isBranded ? 'See All Current Models' : 'More ' + (breakpoint === 'mobile' ? 'From' : 'Boats from this') + ' Dealer';
  };

  const getContactLinks = () => {
    const { listing, showMoreBoats, showInventoryLink } = props;

    const isBranded = get(listing, 'oemDetails.brandedSearch', false);
    const hasImmersiveTour = !!get(listing, 'virtualTour.0', false);

    let dealerUrl;
    if (get(listing, 'owner.boattraderId', false)) {
      dealerUrl = generateUrl(listing.owner);
    }

    const contactLinks = [{
      text: 'Contact',
      onClick: handleOpenContactForm.bind(this)
    }];

    if (dealerUrl && showMoreBoats) {
      contactLinks.push({
        text: getMoreBoatsButtonText(),
        onClick: handleAddLead.bind(this, listing.id, 'view all inventory'),
        hidden: !showInventoryLink
      });
    }

    if (isBranded) {
      contactLinks.push({
        text: getMoreBoatsButtonText(),
        onClick: handleAddLead.bind(this, listing.id, 'view all inventory'),
        hidden: !showInventoryLink
      });
    }

    if (hasImmersiveTour) {
      contactLinks.push({
        text: 'Immersive Tour',
        onClick: handleTourClick.bind(this)
      });
    }

    return contactLinks;
  };

  const { type, listing, isBdp } = props;
  return (
    <div data-testid="social-share"
      className={classnames('social-share', { [`social-share-${type}`]: type })}>
      { props.openShareModal &&
        <SocialShareComponent
          title="Share this boat!"
          buttons={
            [
              { icon: 'facebook',  text: 'Facebook', onClick: handleSocialShare.bind(this, 'facebook') },
              { icon: 'twitter',  text: 'Twitter', onClick: handleSocialShare.bind(this, 'twitter') },
              { icon: 'pinterest',  text: 'Pinterest', onClick: handleSocialShare.bind(this, 'pinterest') },
              { icon: 'whatsapp',  text: 'Whatsapp', onClick: handleSocialShare.bind(this, 'whatsapp') },
              { icon: 'email',  text: 'Email', onClick: handleShare.bind(this, 'email')},
              isBdp ?
                { icon: 'print',  text: 'Print', onClick: handleShare.bind(this, 'print')}
                :
                { icon: 'more',  text: 'More', onClick: handleShare.bind(this, 'more')},
            ]
          }
          contact= { isBdp ? getContactLinks() : [] }
          input = {{
            buttonText: isLinkCopied ? 'Copied!' : 'Copy Link',
            value: getBoatUrl(listing, true),
            onClick: handleCopyLink.bind(this)
          }}
          modal={{
            onClose: props.handleShareButton.bind(this)
          }}
        />
      }
    </div>
  );
};

export default SocialShare;

import React, { PureComponent } from 'react';
import './styles.css';

const TEXT_TYPE = 'text';

export default class LoadingPlaceholder extends PureComponent {

  renderTextLoader() {
    return (
      <div className="loading-text" />
    );
  }

  render() {
    const { type } = this.props;
    switch (type) {
    case TEXT_TYPE:
      return this.renderTextLoader();
    default:
      return null;
    }
  }
}

import React from 'react';
import PropTypes from 'prop-types';

import {
  HERO_TITLE_V2,
  HERO_TEXT_V2,
} from '../../../../../../constants/BoatLoansCalculator';
import './Hero.styles.css';

export const Hero = ({ dataTestId = 'hero-component' }) => {
  return (
    <div data-e2e="calc-hero-container" className="calc-hero-container" data-testid={dataTestId}>
      <h1 data-e2e="calc-hero-title" className="calc-hero-title">{HERO_TITLE_V2}</h1>
      <div data-e2e="calc-hero-text" className="calc-hero-text">{HERO_TEXT_V2}</div>
    </div>
  );
};

Hero.propTypes = {
  dataTestId: PropTypes.string,
};

import {
  TRIDENT_SERVICES_CDN_URL,
  TIRE_AND_WHEEL_FLAT_TIRE,
  TIRE_AND_WHEEL_TOW_TRUCK,
  TIRE_AND_WHEEL_TRAILER,
  TIRE_AND_WHEEL_UMBRELLA,
  TIRE_AND_WHEEL_SHIELD,
  TIRE_AND_WHEEL_USA_MAP,
  TIRE_AND_WHEEL_WRENCH,
} from '.';

export const tireAndWheelPageInfoSection = {
  topSection: {
    title: 'Tire & Wheel Coverage for Your Vehicle & Towed Boat',
    bullets: [
      {
        title: 'Nationwide Coverage',
        text: 'Use the service provider of your choice with zero deductible.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_USA_MAP}`,
      },
      {
        title: 'Extended Vehicle Coverage',
        text: 'Up to 5 years of coverage for your towed boat and connected tow vehicles.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_WRENCH}`,
      },
      {
        title: 'Peace of Mind',
        text: 'Includes flat tire repair, mounting & balancing, and wheel replacement.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_SHIELD}`,
      },
    ],
  },
  middleSection: {
    title: 'Why Invest in Tire & Wheel Protection for Your Boat?',
    text: 'Tire & wheel protection plans help safeguard you against substantial out-of-pocket costs for tire and wheel repairs or replacements so you can tow your boat worry-free!',
  },
  bottomSection: {
    title: 'Tire & Wheel Protection Benefits',
    bullets: {
      firstRow: [
        {
          title: 'Lowers Financial Risk',
          text: 'Road hazards happen. Avoid out-of-pocket costs with tire & wheel protection.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_UMBRELLA}`,
        },
        {
          title: 'Extended Lifespan',
          text: 'Prolonged life of your trailer with covered maintenance and timely tire & wheel repairs.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_TRAILER}`,
        },
      ],
      secondRow: [
        {
          title: 'Tire & Wheel Replacement',
          text: 'If your tire is not repairable, we’ll cover the replacement cost.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_FLAT_TIRE}`,
        },
        {
          title: 'Roadside Assistance',
          text: 'Services available 24/7 to change your flat tire or tow your vehicle to the nearest repair facility.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_TOW_TRUCK}`,
        },
      ],
    },
  },
};

import { getSocialTitle } from './listingHelper';
import { getBoatUrl } from './urlHelpers/boat';
import get from 'lodash/get';

export const email = (listing) => {
  let url = encodeURI(get(listing,'portalLink',''));
  let title = getSocialTitle(listing);
  url = `/email/?url=${encodeURIComponent(url + '&title=' + title)}`;
  let win = window.open(url, '_blank');
  win.focus();
};

export const print = () => {
  window.print();
};

export const more = (listing) => {
  window.navigator.share({
    title: getSocialTitle(listing),
    url: getBoatUrl(listing),
  });
};

export const openSocialModal = (id, listing) => {
  let width = 650;
  let height = 450;
  let url = get(listing,'portalLink','');
  let title = getSocialTitle(listing);
  let mediaImage = `https:${process.env.REACT_APP_CDN_URL}/img/default/boat-image.jpg`;
  const medias = get(listing,'media',[]);

  for (let media of medias) {
    if (media.mediaType === 'image') {
      mediaImage = media.url;
      break;
    }
  }

  let links = {
    'facebook': {
      'href': encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}`)
    },
    'twitter': {
      'href': `https://twitter.com/intent/tweet?url=${escape(url)}&text=${title}`
    },
    'whatsapp': {
      'href': `https://wa.me/?text=${encodeURIComponent(`Check out this ${title} on Boattrader.com`)} ${escape(url)}`
    },
    'pinterest': {
      'href': `https://pinterest.com/pin/create/link/?url=${url}&media=${mediaImage}`
    },
  };

  let { href } = links[id];

  /* eslint-disable max-len */
  window.open(href, 'Share Dialog', `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${width},height=${height},top=${(window.screen.height / 2 - height / 2)},left=${(window.screen.width / 2 - width / 2)}`);
};

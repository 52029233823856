import {Ad} from '@dmm/react-common-components';
import React from 'react';

export const dealersAdsConfig = {
  'div-gpt-leaderboard-1': {
    unitPath: '/252108799/BoatTrader/dealer-search/leaderboard-1',
    breakpoint: {
      min: 769,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728,90]
    ],
    targeting: {
      pos: 'leaderboard-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  }/*,
  'div-gpt-leaderboard-2': {
    unitPath: '/252108799/BoatTrader/dealer-search/leaderboard-2',
    breakpoint: {
      min: 769,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728,90]
    ],
    targeting: {
      pos: 'leaderboard-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  }*/,
  'div-gpt-mobile-box-1': {
    unitPath: '/252108799/BoatTrader/dealer-search/mobile-box-1',
    breakpoint: {
      min: 0,
      max: 768
    },
    size: [
      [300,250]
    ],
    targeting: {
      pos: 'mobile-box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  }/*,
  'div-gpt-mobile-box-2': {
    unitPath: '/252108799/BoatTrader/dealer-search/mobile-box-2',
    breakpoint: {
      min: 0,
      max: 768
    },
    size: [
      [300,250]
    ],
    targeting: {
      pos: 'mobile-box-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  }*/
};


export const DealerAd = ({desktopId, mobileId, display = true, desktopClasses = 'dealers-ads',  mobileClasses = 'dealers-ads' }) => {
  if (!display) {
    return null;
  }

  return (
    <>
      <Ad adId={desktopId} classes={desktopClasses} />
      <Ad adId={mobileId} classes={mobileClasses} />
    </>
  );
};

import {RenderHTML} from '@dmm/react-common-components';
import React, {useEffect, useState} from 'react';
import {YoutubeVideos} from '@dmm/lib-react-videos';
import {isMobileResolution} from '../../../../utils/commonHelper';

export const StickyButton = ({classLink}) => {
  const [stickyClass, setStickyClass] = useState('');
  const checkMenu = () => {
    const header = document.querySelector('.show-header');
    setStickyClass(header ? 'sticky-down' : '');
  };
  useEffect(() => {
    window.addEventListener('scroll', checkMenu);
    return () => {
      window.removeEventListener('scroll', checkMenu);
    };
  }, []);
  const classes = `boatclass-cta ${stickyClass}`;
  return (
    <div className={classes}>
      <a href={classLink}>
        <span>Shop now</span>
      </a>
    </div>
  );
};

export const BoatClassArticle = ({introHtml, classLink, contentHtml}) => {
  if (!introHtml || !contentHtml) {
    return null;
  }
  return (<>
    <div className="boatclass-intro">
      <RenderHTML html={introHtml}/>
    </div>
    <StickyButton classLink={classLink}/>
    <div className="boatclass-content">
      <RenderHTML html={contentHtml}/>
    </div>
  </>
  );
};

export const BoatClassVideos = ({videos, boatsClassName}) => {
  const [device, setDevice] = useState('desktop');
  if (!videos?.length) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const isMobile = isMobileResolution();
    setDevice(isMobile ? 'mobile' : 'desktop');
  }, []);
  return (
    <div className="boatclass-videos">
      <h3>{`Expert ${boatsClassName} Reviews`}</h3>
      <YoutubeVideos videos={videos} device={device} />
    </div>
  );
};

const RelatedArticle = ({article}) => {
  const {title, link, image, imageAlt} = article;
  return (
    <div className="boatclass-related-article">
      <a href={link} title={title} target="_blank" rel="noreferrer">
        <img src={image} alt={imageAlt}/>
        <div>
          <h4>Boat Buying</h4>
          <h3>{title}</h3>
        </div>
      </a>
    </div>
  );
};

export const BoatClassRelatedArticles = ({articles}) => {
  if (!articles?.length) {
    return null;
  }
  const fourArticles = articles.slice(0, 4).map((article, index) => {
    const key = `${article.id}-${index}`;
    return (<RelatedArticle key={key} article={article}/>);
  });
  return (<div className="boatclass-social">
    <div className="boatclass-related-articles">
      {fourArticles}
    </div>
  </div>);
};

export const BoatClassMakes = ({makes, name}) => {
  if (!makes) {
    return null;
  }
  const {classMakes} = makes;
  if (!classMakes?.length) {
    return null;
  }
  if (classMakes.length % 2 !== 0) {
    classMakes.push('');
  }
  const title = `${name} Makes`;
  const makeRows = classMakes.map((make, index) => (
    <span key={`${index}`}>
      <a href={make.url}>
        {make.make}
      </a>
    </span>)
  );
  return (
    <div className="boatclass-makes">
      <h3>{title}</h3>
      <figure>
        <h3>Popular Makes</h3>
        <div className="popular-makes">{makeRows}</div>
      </figure>
    </div>
  );
};


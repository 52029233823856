import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import * as actions from '../../store/actions/dataLayer';
import { getPageParams } from '../../utils/dataLayerHelper';
import { getDefaultParams} from '../../utils/urlHelpers/listings';
import {IDLE_MAIN_REQUEST, PAGES_WITH_API_REQUEST} from '../../store/constants';
import {MainRequest} from '../../store/reducers/app';
import {digitalDataBuilderSet} from '../../store/actions/dataLayer';

class DataLayer extends PureComponent {
  state = {}
  componentDidMount(){
    this.updateDataLayer();
  }
  componentDidUpdate(){
    this.updateDataLayer();
    this.updatePostDataLayer();
  }
  updateDataLayer(){
    const pathname = get(this.props, 'location.pathname', '');
    //only update Data Layer once per path change
    if (this.state.pathname === pathname) {
      return;
    }
    this.setState({ pathname, isWorking: true});
    const pageType = get(this.props, 'pageType', 'default');
    const oemDetails = get(this.props, 'oemDetails', {});
    const searchResultsCount = get(this.props, 'data.search.count', undefined);
    const params = getPageParams(pageType, oemDetails.brandId, searchResultsCount);
    const searchParams = getDefaultParams(get(this.props, 'match.params', {}));
    params.searchParams = searchParams;
    this.props.initDataLayer(params);
  }
  updatePostDataLayer(){
    if (!this.state.isWorking) {
      return;
    }
    if (
      (this.props.mainRequestState === MainRequest.LOADED && PAGES_WITH_API_REQUEST.includes(this.props.pageType))
      || !PAGES_WITH_API_REQUEST.includes(this.props.pageType) ) {
      this.setState({ isWorking: false });
      const pathname = get(this.props, 'location.pathname', '');
      const searchResultsCount = get(this.props, 'data.search.count', undefined);
      digitalDataBuilderSet('Page Search Results Count', searchResultsCount);
      this.props.registerPageView({ pathname });
      this.props.registerProductImpressions();
      this.props.dispatch({type: IDLE_MAIN_REQUEST});
    }
  }
  render(){
    return null;
  }
}

const mapStateToProps = state => {
  return {
    isWorking: state.app.isWorking,
    mainRequestState: state.app.mainRequestState,
    data: state.app.data
  };
};

const mapDispatchToProps = dispatch => {
  const binded = bindActionCreators({ ...actions, }, dispatch);
  return {...binded, dispatch};
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);
const connectedDataLayer = reduxConnect(DataLayer);

const connectedToRouter = withRouter(connectedDataLayer);

export default connectedToRouter;

import React, { PureComponent } from 'react';
import Slider from '../../Slider';
import {DEFAULT_NO_MAX_LENGTH, SLIDER_MAX_VALUE, DEBOUNCE_TIMEOUT} from '../../../constants/boats';
import debounce from 'lodash/debounce';


class FilterLength extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      min: props.min || '',
      max: props.max || '',
      dragging: false
    };
    this.handleSliderDrag = this.handleSliderDrag.bind(this);
    this.handleSliderDragEnd = this.handleSliderDragEnd.bind(this);
  }

  updateMultiInput() {
    if (this.state.editing || !this.state.dragging) {
      this.setState({ max: this.props.max || '' });
    }
    if (this.state.editing || !this.state.dragging) {
      this.setState({ min: this.props.min || '' });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  handleSliderMaxValue(val) {
    if (val === DEFAULT_NO_MAX_LENGTH) {
      return SLIDER_MAX_VALUE;
    }
    return val;
  }

  handleSliderDrag(handle,val) {
    if (this.state.editing) {
      return;
    }
    if (handle === 'min') {
      this.setState({min: val.toString(), dragging: true});
    } else {
      this.setState({max: this.handleSliderMaxValue(val.toString()), dragging: true});
    }

  }

  handleSliderDragEnd(handle,val) {
    if (!this.state.dragging) {
      return;
    }
    this.setState({dragging: false});
    if (handle === 'min') {
      if (this.props.tracking) {
        if (!val || val === '0') {
          this.debounceTrackingRemove('minimum length removed');
        } else {
          this.debounceTracking({ ['length']: {'min': val }});
        }
      }
      this.props.handleDataChange('length', {min: val.toString(), max: this.state.max});
    } else {
      if (this.props.tracking) {
        if (!val || val === DEFAULT_NO_MAX_LENGTH) {
          this.debounceTrackingRemove('maximum length removed');
        } else {
          this.debounceTracking({ ['length']: {'max': val }});
        }
      }
      this.props.handleDataChange('length', {min: this.state.min, max: this.handleSliderMaxValue(val.toString())});
    }
  }

  handleDataChange(type, value) {
    if (type === 'min') {
      if (this.props.tracking) {
        if (!value) {
          this.debounceTrackingRemove('minimum length removed');
        } else {
          this.debounceTracking({ ['length']: {'min': value }});
        }
      }
      this.props.handleDataChange('length', {min: value, max: this.state.max});
    } else {
      if (this.props.tracking) {
        if (!value) {
          this.debounceTrackingRemove('maximum length removed');
        } else {
          this.debounceTracking({ ['length']: {'max': value }});
        }
      }
      this.props.handleDataChange('length', {min: this.state.min, max: value});
    }
  }

  debounceTracking = debounce((value) => this.props.tracking.facetAdded(value), DEBOUNCE_TIMEOUT);
  debounceTrackingRemove = debounce((value) => this.props.tracking.facetRemoved(value), DEBOUNCE_TIMEOUT);

  onFocusLength = () => {
    this.setState({editing: true});
  }

  onBlurLength = () => {
    this.setState({editing: false});
  }

  onChangeLengthMin = (value) => {
    this.handleDataChange('min', value.target.value);
  }

  onChangeLengthMax = (value) => {
    this.handleDataChange('max', value.target.value);
  }

  render() {
    return (
      <div data-e2e="search-filter-length" className="search-filter length">
        <div data-e2e="slider-container" className="slider-container">
          <Slider
            min={0}
            max={parseInt(DEFAULT_NO_MAX_LENGTH)}
            initialMin={'0'}
            initialMax={this.props.initialMax || DEFAULT_NO_MAX_LENGTH.toString()}
            value={[parseInt(this.state.min) || 0,parseInt(this.state.max) || parseInt(DEFAULT_NO_MAX_LENGTH)]}
            marks={{0: ' '}}
            onChange={this.handleSliderDrag}
            onAfterChange={this.handleSliderDragEnd}
            handle={this.props.handle || ''}
          />
        </div>
        <div data-e2e="search-filter-group" className="search-filter-group">
          <input
            type="number"
            data-e2e="minimum-length"
            className="small length-min"
            placeholder={'No Min'}
            value={this.state.min === DEFAULT_NO_MAX_LENGTH ? '100' : this.state.min}
            onFocus={this.onFocusLength}
            onBlur={this.onBlurLength}
            onChange={this.onChangeLengthMin}
            title="Minimum length"
          />
          <span>to</span>
          <input
            type="number"
            data-e2e="maximum-length"
            className="small length-max"
            value={this.state.max === SLIDER_MAX_VALUE ? '' : this.state.max}
            placeholder={'No Max'}
            onFocus={this.onFocusLength}
            onBlur={this.onBlurLength}
            onChange={this.onChangeLengthMax}
            title="Maximum length"
          />
          <div className="filled-block">
            ft.
          </div>
        </div>
      </div>
    );
  }
}

export default FilterLength;

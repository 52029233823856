/**
 * Part of MOOL-417 was to make this `DealerLocatorModal` a common component; however,
 * `@dmm/react-common-components` does not currently support [React] functional components,
 * until it does, the component will live in the `portal-react-boattrader` project.
 */
import { isValidUsZipCode } from '@dmm/lib-common/lib/validation';
import * as httpClient from './httpClient';

import { DEALERS_PER_PAGE, MAX_PAGE_NUMBER } from '../constants/dealerLocator';

export default function dealerLocatorModalHelper({
  pageIndex,
  oemDealers,
  oemDetails,
  setOemDealers,
  setPageIndex,
  setPostalCode
}) {
  const totalListings = () => oemDealers?.length || 0;

  const getLastPage = () => Math.ceil(totalListings() / DEALERS_PER_PAGE) || 1;

  const goToPageIndex = (newPageIndex) => {
    if (newPageIndex === pageIndex || newPageIndex < 0 || newPageIndex > getLastPage() - 1) {
      return;
    }
    setPageIndex(newPageIndex);
  };

  const nextPage = () => goToPageIndex(pageIndex + 1);
  const previousPage = () => goToPageIndex(pageIndex - 1);

  const hasNextPage = (pageNumber = pageIndex) => pageNumber < getLastPage() - 1;
  const hasPreviousPage = (pageNumber = pageIndex) => pageNumber > 0;

  const pagination = () => {
    const lastPage = getLastPage();

    let currentPage = pageIndex + 1;

    currentPage = currentPage < 1 ? 1 : currentPage;
    currentPage = currentPage > lastPage ? lastPage : currentPage;

    const fillLength = Math.floor(MAX_PAGE_NUMBER / 2);

    let startPage = 1;
    if (lastPage > MAX_PAGE_NUMBER && currentPage > fillLength) {
      startPage = currentPage + fillLength >= lastPage
        ? lastPage - MAX_PAGE_NUMBER + 1
        : currentPage - fillLength;
    }

    let sequence = [];

    for (let i = 0, nextPage = startPage; i < MAX_PAGE_NUMBER; i++, nextPage++) {
      if (nextPage > lastPage) {
        break;
      }
      sequence.push(nextPage);
    }

    if (lastPage > MAX_PAGE_NUMBER && currentPage > fillLength + 1) {
      sequence.shift();
      sequence.unshift(1, '..');
    }

    if (lastPage > MAX_PAGE_NUMBER && sequence[sequence.length - 1] !== lastPage) {
      sequence.pop();
      sequence.push('..', lastPage);
    }

    return sequence;
  };

  const getStartIndex = () => pageIndex * DEALERS_PER_PAGE;

  const dealers = (device) => {
    if (!totalListings()) {
      return [];
    }

    if (device !== 'desktop') {
      return oemDealers;
    }

    const startIndex = getStartIndex();
    return oemDealers.slice(startIndex, startIndex + DEALERS_PER_PAGE);
  };

  const fetchDealers = async (postalCode) => {
    const http = httpClient.getSecureProxyHttpClient();
    let url = `/app/oem/dealers/${oemDetails.brandId}`;

    if (postalCode && isValidUsZipCode(postalCode)) {
      url = `${url}?postalCode=${postalCode}`;
    }
    if (!http?.get) {
      throw Error('http.get is not defined');
    }
    const results = await http.get(url);
    setPostalCode(results.data ? results.data.postalCode : '');
    setOemDealers(results.data ? results.data.dealers : []);
  };

  const oemTerm = () => oemDetails.name;

  return {
    dealers,
    fetchDealers,
    getLastPage,
    goToPageIndex,
    hasNextPage,
    hasPreviousPage,
    nextPage,
    oemTerm,
    pagination,
    previousPage,
    getStartIndex,
    totalListings,
    partyId: oemDetails.brandId
  };
}

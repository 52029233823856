/**
 * One layer of abstraction is required for the function setOemDealers amd setPostalCode.
 * This is because it will be set by an async call within DealerLocatorModal
 * Without one layer of abstraction it will run and complete, but it will throw errors
 *  because of running an async set state function.
 * dealerIndex is here because I can't figure out how to get 100% coverage without being able to manipulate it
 */
import React, { useState } from 'react';

import DealerLocatorModal from '../../components/DealerLocatorModal';

const DealerLocator = ({ device, hideModal, isOpen, oemDetails, listing, isWorking, origin }) => {
  const [dealerIndex, setDealerIndex] = useState(undefined);
  const [oemDealers, setOemDealers] = useState(undefined);
  const [postalCode, setPostalCode] = useState();

  return (
    <>
      <DealerLocatorModal
        device={device}
        hideModal={hideModal}
        isOpen={isOpen}
        oemDetails={oemDetails}
        oemDealers={oemDealers}
        setOemDealers={setOemDealers}
        dealerIndex={dealerIndex}
        setDealerIndex={setDealerIndex}
        listing={listing}
        isWorking={isWorking}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        origin={origin}
      />
    </>
  );
};

export default DealerLocator;

import React, {useContext} from 'react';
import DealerLocator from '../../DealerLocator';
import {BDPContext} from '../MainContent/BoatDetailsPage';
import DealerLocatorForm from '../../../components/DealerLocatorForm';

const BDPDealerLocator = ({device, isOpen, hideModal, isWorking}) => {
  const {listing} = useContext(BDPContext);
  const {oemDetails} = listing;
  if (!oemDetails?.oemDealerLocator) {
    return null;
  }
  return (<DealerLocator
    device={device}
    hideModal={hideModal}
    isOpen={isOpen}
    oemDetails={oemDetails}
    origin="BDP"
    listing={listing}
    isWorking={isWorking}
  />);
};

const BDPDealerLocatorForm = ({openModalDealerLocator}) => {
  const {listing} = useContext(BDPContext);
  const {oemDetails} = listing;
  if (!oemDetails?.oemDealerLocator) {
    return null;
  }
  return <DealerLocatorForm oemDetails={oemDetails} showModalDealerLocator={openModalDealerLocator} />;
};

export {BDPDealerLocator, BDPDealerLocatorForm};

import React from 'react';
import {frontloadConnect} from 'react-frontload';
import {withCookies} from 'react-cookie';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import get from 'lodash/get';
import * as actions from '../../store/actions';
import * as utils from '../../store/utils';
import { ENGINES_ID } from '../../constants/Engines';
import { isOnlyZip } from '../../utils/urlHelpers/engines';
import {urlFromMissingLocationData, generateReductiveSearchUrl } from '../../utils/urlHelpers/listings';
import {runOnce} from '../../utils/runOnceHelper';
import ErrorMessage from '../../components/ErrorMessage';
import DefaultLayout from '../../layouts/Default';
import EnginesMainContent from './EnginesMainContent';

const Engines = (props) => {

  const locationRedirectUrl = (urlMatchParams) => {
    const {zipGeoData} = props;
    if (isOnlyZip(urlMatchParams) && zipGeoData){
      return urlFromMissingLocationData(urlMatchParams, zipGeoData, ENGINES_ID);
    }
    return '';
  };
  const urlMatchParams = get(props, 'match.params', {});
  const locationRedirect = locationRedirectUrl(urlMatchParams);
  if (locationRedirect && locationRedirect !== props.location.pathname){
    return <Redirect to={{pathname: locationRedirect}} {...props} />;
  }

  let hasNoListings = get(props, 'search.count', 0) < 1;
  let isNotRoot = props.location.pathname !== '/engines';
  if (utils.isServer() && isNotRoot && hasNoListings && props.success){
    return <Redirect to={{pathname: generateReductiveSearchUrl(props.location.pathname)}} {...props} />;
  }

  if (props.errors) {
    return (
      <DefaultLayout {...props} pageType="Engines" id={'engines_error'}>
        <ErrorMessage {...props} />
      </DefaultLayout>
    );
  }
  return (
    <EnginesMainContent {...props} />
  );
};

const mapStateToProps = state => {
  return {
    params: get(state.app, 'params', {}),
    search: get(state.app, 'data.search', {}),
    success: state.app.success,
    errors: state.app.errors,
    message: state.app.message,
    statusCode: state.app.statusCode,
    isWorking: state.app.isWorking,
    user: state.app.user,
    facets: get(state.app, 'data.facets', {}),
    partyDetails: get(state.app, 'data.partyDetails'),
    allState: state,
    zipGeoData: get(state.app, 'data.zipGeoData')
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

/**
 * Here we manage the bootstrapping of the component both with client and server side rendering.
 * If we want to debug properly the component, we need to do client-side-render and to be able to fetch data
 * without conflicts. We must dispatch the action just once.
 *
 * The environment variable could be declared on the .env file if we want so.
 * This same approach could, probably, be applied to other containers
 */
const frontloadConnected = frontloadConnect(({dispatch, location, debug = process.env.REACT_APP_LOCAL_DEBUG}) => {
  if (utils.isServer()) {
    return dispatch(actions.getEnginesData(location.pathname));
  }

  // Live reload on page change
  if (actions.shouldGetData(location)) {
    return dispatch(actions.getEnginesData(location.pathname));
  }

  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  if (justFirsTime() && debug) {
    return dispatch(actions.getEnginesData(location.pathname));
  }

}, {
  onUpdate: true,
  onMount: true
})(Engines);

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(frontloadConnected));

import { Action } from '@dmm/lib-react-ui-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { BOAT_LEAD_SERVICES_TYPE, TRACKING_EVENT_LEAD_SERVICES_SUBMISSION } from '../../constants/BoatLoans';
import { checkPreQualVariation } from '../../utils/trident';
import './styles.css';

export const BoatLoansAndServices = ({financeable = true, showModalServices, listing}) => {
  let getProtectedText = 'Ensure an uninterrupted voyage with our boat \
      protection services for as low as $999. Offering trailer protection, \
      GAP coverage, 24/7 emergency support, and priority dealership service \
      appointments, we\'ve got your sea journey covered.';

  if (!financeable) {
    getProtectedText = 'Boat protection services let you cruise with \
      confidence for as low as $999. Get trailer protection, GAP coverage, \
      24/7 emergency support, and priority dealership service appointments on \
      any boat, any time.';
  }

  return (
    <div className="boat-loans-and-services-wrapper">
      {financeable && (
        <div className="get-prequal-container-blob"
          style={{
            backgroundImage: 'linear-gradient(0deg, rgba(15, 77, 119, 0.85), rgba(15, 77, 119, 0.85)), url("/static/boat-loans/get-prequal-background.jpeg")',
          }}
        >
          <div className="get-prequal-title-blob">Get Pre-Qualified</div>
          <p className="get-prequal-text-blob">
            Make sure your offer is ready when you are. With our flexible and easy to secure boat loans, owning a boat has
            never been more affordable, working with our team gets you on the water faster.
          </p>
          <Action
            onClick={() =>{
              checkPreQualVariation({utmCampaign: 'bdpapplynowprivate', utmContent: 'BDPapplynowprivate', listing});
            }}
            data-reporting-click-internal-link-type="click"
            data-reporting-click-internal-link-id="private listing boat loans - apply now"
            data-e2e="boat-loans-apply-now-button"
            label="Apply Now"
            stretch
          />
        </div>
      )}

      <div className={classNames('get-protected-container', { 'get-protected-container-full': !financeable })}
        style={{
          backgroundImage: 'linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url("/static/boat-loans/get-protected-background.jpeg")',
        }}
      >
        <div className="get-protected-title">24/7 Boat &amp; Trailer Protection</div>
        <p className="get-protected-text">
          {getProtectedText}
        </p>
        <Action
          variant={'secondary'}
          stretch
          className="get-protected-button"
          data-reporting-click-internal-link-type="click"
          data-reporting-click-internal-link-id="get protected button"
          onClick={() => {
            showModalServices(true, BOAT_LEAD_SERVICES_TYPE, 'BDP', {'event': TRACKING_EVENT_LEAD_SERVICES_SUBMISSION});
          }}
          data-testid="blns-get-protected-button"
          label="Get Protected"
        />
      </div>
    </div>
  );
};

BoatLoansAndServices.propTypes = {
  financeable: PropTypes.bool,
  showModalServices: PropTypes.func,
  listing: PropTypes.object.isRequired,
};

import React, {useState} from 'react';
import { bindActionCreators } from 'redux';
import './styles.css';
import PropTypes from 'prop-types';
import {DealerItem} from './DealerItem';
import {AddressModal} from './AddressModal';
import { addLead, setGenericEvent } from '../../store/actions/dataLayer';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { generateDealerAddress } from '../../utils/dealerSRPHelper';
import { getStateByCode } from '../../utils/commonHelper';
import ModalFormDealerWrapper from '../ModalFormDealerWrapper';

const EmptyDealerList = () => (
  <div className="no-dealers" data-e2e="no-dealers">
    <p>Sorry, there were no dealers that matched your search criteria.</p>
    <p>Please try adjusting your search.</p>
  </div>
);

const DealerList = ({dealers, page, addLead, setGenericEvent}) => {
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [dealerLocationData, setDealerLocationData] = useState(null);

  if (!dealers || !dealers.length) {
    return <EmptyDealerList/>;
  }
  const onSelectDealer = (dealer) => {

    const mappedDealer = {
      id: dealer.id,
      name: dealer.displayName,
      address: generateDealerAddress(dealer.street, dealer.city, get(getStateByCode(dealer.countrySubDivisionCode), 'name')),
      phoneNumber: dealer.phone,
      linkContactInfo: false,
      displayPhoneMessage: false,
      showCloseButton: true
    };

    addLead(dealer.id, 'email lead', 'contact dealer');
    setSelectedDealer(mappedDealer);
  };

  const onSelectLocation = (dealer) => {
    if (dealer) {
      addLead(dealer.id, 'map view');
    }
    setDealerLocationData(dealer);
  };

  const closeModalForm = () => {
    setSelectedDealer(null);
  };


  const dealerElements = dealers.map((d, i) => (
    <DealerItem key={i} dealer={d} onClickContact={onSelectDealer} onSelectLocation={onSelectLocation} page={page} addLead={addLead} setGenericEvent={setGenericEvent}/>));

  return (
    <>
      <div className="dealer-list">
        {dealerElements}
      </div>
      <ModalFormDealerWrapper dealer={selectedDealer} onClose={closeModalForm} show={selectedDealer !== null}/>
      {
        dealerLocationData &&
        <AddressModal addressProps={dealerLocationData} onClickContact={onSelectDealer} onClose={() => onSelectLocation(null)} addLead={addLead} setGenericEvent={setGenericEvent}/>
      }
    </>
  );
};

DealerList.propTypes = {
  dealers: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.any,
    website: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    logoPath: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number)
  }))
};

export default connect(
  null,
  dispatch => bindActionCreators({
    addLead,
    setGenericEvent
  }, dispatch)
)(DealerList);

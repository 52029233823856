import find from 'lodash/find';
import get from 'lodash/get';
import { GROUPS_CLASSES_MAPPING } from '../../constants/boats';
import {
  LEGACY_BROWSE_MAKE_PATTERN,
  LEGACY_BROWSE_MAKE_STATE_PATTERN,
  LEGACY_BROWSE_MAKE_MODEL_PATTERN,
  LEGACY_BROWSE_MAKE_MAPPER
} from '../../constants/browse';
import { getClassCategory, legacyMappings, legacyBrowseTypes } from '../classHelper';
import { generateSearchPath } from './listings';
import { UsaStates } from 'usa-states';
import { hyphenateUrlComponents, unslugify } from './searchResults';

const generateBrowsePath = (params) => {
  const { type, state, class: boatClass, make, model = [] } = getBrowseParams(params);
  const stateFacet = generateFacet('state', state);
  const makeFacet = generateFacet('makeModel', make ? { [make]: model } : undefined);
  if (boatClass) {
    if (legacyBrowseTypes[boatClass]) {
      return generateSearchPath({
        multiFacetedBoatTypeClass: {
          ...legacyBrowseTypes[boatClass]
        }
      }, {});
    }
    if (legacyMappings[boatClass]) {
      return generateSearchPath({
        multiFacetedBoatTypeClass: {
          [getClassCategory(legacyMappings[boatClass][0])]: legacyMappings[boatClass]
        }
      }, {});
    }
  }
  if (legacyMappings[type]) {
    return generateSearchPath({
      multiFacetedBoatTypeClass: {
        [getClassCategory(legacyMappings[type][0])]: legacyMappings[type]
      },
      ...stateFacet,
      ...makeFacet
    }, {});
  }
  if (legacyBrowseTypes[type]) {
    return generateSearchPath({
      multiFacetedBoatTypeClass: {
        ...legacyBrowseTypes[type]
      },
      ...stateFacet,
      ...makeFacet
    }, {});
  }
  if (GROUPS_CLASSES_MAPPING[type]) {
    return generateSearchPath({
      group: type,
      ...stateFacet,
      ...makeFacet
    }, {});
  }
  if (make) {
    return generateSearchPath({
      ...stateFacet,
      ...makeFacet
    }, {});
  }
  return generateSearchPath({}, {});
};

const parseMake = (make) => {
  if (LEGACY_BROWSE_MAKE_PATTERN.test(make)) {
    const matches = LEGACY_BROWSE_MAKE_PATTERN.exec(make);
    const normalizedMake = normalizeBrowseMake(matches[1]);
    return {
      make: normalizedMake
    };
  }
  return {};
};

const parseMakeStateOrModel = (makeStateOrModel) => {
  if (LEGACY_BROWSE_MAKE_STATE_PATTERN.test(makeStateOrModel)) {
    const matches = LEGACY_BROWSE_MAKE_STATE_PATTERN.exec(makeStateOrModel);
    const normalizedMake = normalizeBrowseMake(matches[1]);
    return {
      make: normalizedMake,
      ...parseTypeState(matches[2])
    };
  }
  if (LEGACY_BROWSE_MAKE_MODEL_PATTERN.test(makeStateOrModel)) {
    const matches = LEGACY_BROWSE_MAKE_MODEL_PATTERN.exec(makeStateOrModel);
    const normalizedMake = normalizeBrowseMake(matches[1]);
    const normalizedModel = normalizeBrowseModel(matches[2]);
    return {
      make: normalizedMake,
      model: [normalizedModel]
    };
  }
  return {};
};

const parseTypeState = (state) => {
  if (state) {
    return {
      state: getStateCodeFromName(state)
    };
  }
  return {};
};

export const getBrowseParams = (params) => {
  params = sanitizeParams(params);
  return {
    type: params.type,
    class: params.class,
    ...parseMakeStateOrModel(params.makeState),
    ...parseMake(params.make || params.typeMake),
    ...parseTypeState(params.typeState)
  };
};

export const sanitizeParams = (params) => {
  const sanitizedParams = {};
  Object.entries(params).forEach(([key, param]) => {
    sanitizedParams[key] = sanitizeParam(param);
  });
  return sanitizedParams;
};

export const rewriteLegacyBrowseUrl = props => {
  const params = get(props, 'match.params', {});
  const pathname = get(props, 'location.pathname', {});
  let path = generateBrowsePath(params, pathname);
  return path;
};

const mapLegacyBrowseMakeName = (makeName) => {
  if (LEGACY_BROWSE_MAKE_MAPPER[makeName]) {
    return LEGACY_BROWSE_MAKE_MAPPER[makeName];
  }
  return makeName;
};

const getStateCodeFromName = (name) => {
  const states = new UsaStates().format(
    {
      $value: 'abbr',
      $name: 'name'
    }
  );
  return find(states, (state) => {
    const normalisedName = state.name.toLowerCase().split(' ').join('-');
    return normalisedName === name;
  }).value.toLowerCase();
};

const generateFacet = (key, value) => {
  if (value) {
    return {
      [key]: value
    };
  }
  return {};
};

const sanitizeParam = (param) => {
  if (param) {
    return decodeURI(encodeURI(param).replace(/%E2%80%8B/g, ''));
  }
  return param;
};

const removeMultipleSpaces = (string) => {
  return string.replace(/\s\s+/g, ' ');
};

const removeSpecialCharacters = (string) => {
  return string.replace(/\s-\s+/g, '-');
};

const normalizeBrowseMake = (make) => {
  return hyphenateUrlComponents(mapLegacyBrowseMakeName(
    removeSpecialCharacters(removeMultipleSpaces(unslugify(unslugify(make, '-', ' '), '+', '-'))))
  );
};

const normalizeBrowseModel = (model) => {
  return hyphenateUrlComponents(removeSpecialCharacters(
    removeMultipleSpaces(unslugify(unslugify(model, '-', ' '), '+', '-')))
  );
};

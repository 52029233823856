import indexOf from 'lodash/indexOf';

export const canonicalizeDataString = (param) => {
  var canonical;
  if (param) {
    canonical = decodeURIComponent(param);
    canonical = canonical.toLowerCase();
    canonical = canonical.replace('amp;', '').replace(/\W/g, '');
  }
  return canonical;
};

const _validConditions = ['new', 'used'];
export const isValidCondition = (condition) => {
  return condition && indexOf(_validConditions, condition) !== -1;
};

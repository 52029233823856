import React, { PureComponent } from 'react';
import classnames from 'classnames';
import './styles.css';


class Spinner extends PureComponent {
  render() {
    const { hidden = false } = this.props;
    return (
      <div className={classnames('spinner-preloader', { hidden: hidden })}></div>
    );
  }
}

export default Spinner;

import {bindActionCreators} from 'redux';
import {
  changeCarouselImage,
  setGenericEvent,
  setListingView,
  setModalEvent,
  setProduct,
  trackContactFormOpen,
  openCarouselModal,
  setOemBrandProductImpression
} from '../../../../store/actions/dataLayer';
import {carouselButtonClick, closeAdditionalLeadsAlert, showAdditionalLeadsModal} from '../../../../store/actions';
import {useDispatch} from 'react-redux';

export const useBdpActions = () => {
  const dispatch = useDispatch();
  const bdpBoundActions = bindActionCreators({
    setGenericEvent,
    setProduct,
    trackContactFormOpen,
    openCarouselModal,
    setListingView,
    closeAdditionalLeadsAlert,
    showAdditionalLeadsModal,
    setModalEvent,
    carouselButtonClick,
    changeCarouselImage,
    setOemBrandProductImpression
  }, dispatch);
  return bdpBoundActions;
};

import React, { PureComponent } from 'react';
import ResultsPagination from '../ResultsPagination';
import * as scroll from '../../../utils/scrollTo';

class ResultsFooter extends PureComponent {

  onClickResultsPagination = () => {
    scroll.scrollToTop();
  }

  render() {
    return (
      <div className="results-footer">
        <ResultsPagination
          {...this.props}
          onClick={this.onClickResultsPagination}
        />
      </div>
    );
  }
}

export default ResultsFooter;


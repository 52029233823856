import find from 'lodash/find';
import {hyphenateTrimmed} from './urlHelpers/listings';
import {normalizeString} from '@dmm/lib-common/lib/formatting';

export const typeAheadMatchesValue = (typeAheadText, value) => {
  return value.toLowerCase().includes(typeAheadText.toLowerCase());
};

export const compareNormalizations = (selectedItems, value) => {
  const slugTrim = hyphenateTrimmed(value);
  const normalized = normalizeString(hyphenateTrimmed(value));
  const res = find(selectedItems, (selectedItem) => {
    let normalizedSelectedItem = normalizeString(hyphenateTrimmed(selectedItem));
    return normalizedSelectedItem === normalized || normalizedSelectedItem === slugTrim;
  });
  return res;
};

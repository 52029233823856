import React from 'react';
import './styles.css';

const DealerColumn = ({dealerCols}) => {
  return (
    <div className = "dealer-location-column">
      {dealerCols.map((item, i) =>
        (
          <div data-e2e="state-name-div" key={i}>
            <a data-e2e="state-name-link" href={item.url}>{item.name}&nbsp;</a>
            { item.count && <span>({item.count})</span> }
          </div>
        )
      )}
    </div>
  );
};

export default DealerColumn;

export const TRACKING_BOAT_LOANS_CLICK_ACTION_TYPE = 'click';
export const TRACKING_BOAT_LOANS_FINANCE_SITE_SECTION = 'finance';
export const TRACKING_BOAT_LOANS_PAGE_NAME = 'finance';
export const TRACKING_BOAT_LOANS_PORTAL = 'boat trader';
export const TRACKING_BOAT_LOANS_SITE_SUB_SECTION = 'services';
export const TRACKING_BOAT_LOANS_PRIMARY_CATEGORY = 'services';
export const TRACKING_BOAT_LOANS_CATEGORY_SUB_SECTION = 'services';

export const TRACKING_BOAT_LOANS_APPLY_ONLINE_ACTION_LABEL = 'boat loans - apply online in 5 minutes';
export const TRACKING_BOAT_LOANS_CLICK_TYPE_OF_LOANS_ACTION_LABEL = 'types of loans - apply now';
export const TRACKING_BOAT_LOANS_FINANCING_APPLY_ONLINE_ACTION_LABEL = 'financing - apply online in 5 minutes';
export const TRACKING_BOAT_LOANS_HERO_CLICK_ACTION_LABEL = 'hero - apply online in 5 minutes';
export const TRACKING_BOAT_LOANS_HOW_TO_APPLY_CLICK_ACTION_LABEL = 'how to apply - apply online in 5 minutes';
export const TRACKING_BOAT_LOANS_MENU_GET_LOAN_ACTION_LABEL = 'menu - get a boat loan';
export const TRACKING_BOAT_LOANS_GET_STARTED_ACTION_LABEL = 'boat loan - get started';

export const TRACKING_BOAT_LOANS_CALCULATOR_SHOW_FORM_EVENT = 'link_click';
export const TRACKING_BOAT_LOANS_CALCULATOR_DETAILED_PAGE_NAME = 'boat details';
export const TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_EVENT = 'boat loan lead submission';
export const TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_ACTION_TYPE = 'boat loan form submit’';
export const TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SECTION = 'listings';
export const TRACKING_BOAT_LOANS_CALCULATOR_SUBMIT_FORM_SITE_SUB_SECTION = 'boat listings';

export const DEFAULT_BOAT_LOANS_DATA_LAYER_TRACKING_PARAMS = {
  'action_type': TRACKING_BOAT_LOANS_CLICK_ACTION_TYPE,
  'ga4': {
    page: {
      'detailed_page_name': TRACKING_BOAT_LOANS_PAGE_NAME,
      'section': TRACKING_BOAT_LOANS_FINANCE_SITE_SECTION,
      'subsection': TRACKING_BOAT_LOANS_SITE_SUB_SECTION,
      portal: TRACKING_BOAT_LOANS_PORTAL,
      pageInfo: {
        pageName: TRACKING_BOAT_LOANS_PAGE_NAME,
      },
      category: {
        primaryCategory: TRACKING_BOAT_LOANS_PRIMARY_CATEGORY,
        subSection: TRACKING_BOAT_LOANS_CATEGORY_SUB_SECTION
      }
    }
  }
};

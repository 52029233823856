import React from 'react';
import {frontloadConnect} from 'react-frontload';
import {withCookies} from 'react-cookie';
import {connect} from 'react-redux';
import ErrorMessage from '../../components/ErrorMessage';
import DefaultLayout from '../../layouts/Default';
import MainContent from './MainContent';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';
import {runOnce} from '../../utils/runOnceHelper';
import {temporaryValidClass} from '../../utils/metaContentHelper';
import {pontoonArticle} from './pontoonHardcoded';
import {jonArticle} from './jonHardcoded';
import {centerConsoleArticle} from './centerConsoleHardcoded';

export const BOAT_CLASS_ID = 'BoatClass';

const BoatClass = ({boatClass, displayName, name, errors}) => {
  const getData = boatClass => {
    const classContentObj = {
      'pontoon-boats': pontoonArticle,
      'jon-boats': jonArticle,
      'center-console-boats': centerConsoleArticle
    };
    return classContentObj[boatClass] || {};
  };
  const urlParsedParams = {boatClass, displayName};
  const article = temporaryValidClass(displayName) ? getData(boatClass) : {};
  return (
    <DefaultLayout pageType={BOAT_CLASS_ID} id={'boat_class'} urlParsedParams={urlParsedParams}>
      {
        errors ?
          <ErrorMessage {...errors} /> :
          <MainContent
            boatClass={boatClass}
            displayName={displayName}
            boatClassArticle={article}
            name={name}
          />
      }
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  return {
    boatClass: state.app?.params?.boatClass || '',
    displayName: state.app?.params?.displayName || '',
    name: state.app?.params?.name || '',
    // records: get(state.app, 'data.records', {}),
    errors: state.app.errors,
    // message: state.app.message,
    // statusCode: state.app.statusCode
  };
};

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

// eslint-disable-next-line no-empty-pattern
const frontLoadConnected = frontloadConnect(async ({dispatch, location, debug = process.env.REACT_APP_LOCAL_DEBUG}) => {
  if (utils.isServer()) {
    // dispatch action to get boat types data
    return dispatch(actions.getBoatClass(location?.pathname));
  }

  if (actions.shouldGetData(location)) {
    // dispatch action to get boat types data
    return dispatch(actions.getBoatClass(location?.pathname));
  }

  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  if (justFirsTime() && debug) {
    return dispatch(actions.getBoatClass(location?.pathname));
  }

}, {
  onMount: true,
  onUpdate: true
})(BoatClass);

export default withCookies(connect(mapStateToProps)(frontLoadConnected));

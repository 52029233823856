import { useEffect, useState } from 'react';
import { isLaptopResolution, isPhoneResolution, isTabletOrSmallLaptopResolution } from '../../commonHelper';

export const useDeviceViewport = () => {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(window && window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isMobile: isPhoneResolution(width),
    isTablet: isTabletOrSmallLaptopResolution(width),
    isDesktop: isLaptopResolution(width),
  };
};

import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import * as scroll from '../../utils/scrollTo';

class ScrollManager extends PureComponent {
  componentDidMount() {
    scroll.scrollToTop();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      scroll.scrollToTop();
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollManager);

import React, { Component } from 'react';
import { Ad } from '@dmm/react-common-components';
import '../styles.css';

export default class MobileAdOne extends Component {
  render() {
    let {adNum, suffix} = this.props;
    const mobileAdOneParams = {
      adId: 'div-gpt-mobile-box-' + adNum + (suffix ? `-${suffix}` : ''),
      classes: 'ad ad-inline-mobile-box'
    };
    return (
      <li className="mobile-liner-ad premium bp1 ad-320x80">
        <Ad {...mobileAdOneParams}  />
      </li>
    );
  }
}

export const ENGINES_SEARCH_URL_PATTERN = '/engines/:ownerId(dealer-[^/]+)?/:condition(condition-[a-z]+)?/:type(type-[^/]+)?/:make(make-[^/]+)?/:model(model-[^/]+)?/:makeModel(makemodel-[^/]+)?/:state(state-[a-z|0-9|%]+)?/:city(city-[^/]+)?/:zip(zip-[0-9]+)?/:radius(radius-[0-9|exact|any]+)?/:fuelType(fuel-[a-z|,]+)?/:horsepower(horsepower-[0-9|,?]+)?/:year(year-[0-9|,]+)?/:price(price-[0-9|,?]+)?/:page(page-[0-9]+)?/:sort(sort-[a-z]+:[a-z]+)?/:modal(modal-[a-z|+]+)?/';
export const ENGINES_BASE_URL = '/engines/';
export const ENGINES_LEGACY_URL = '/browse/engines/(.+)?';
// We can find rest of split filter patterns here: src/constants/boats/index.js
export const ENGINES_SEARCH_URL_TYPE_PATTERN = /type-([^/]+)/;
export const ENGINES_SEARCH_URL_HORSEPOWER_PATTERN = /horsepower-(\d*),?(\d*)?/;
export const ENGINES_SEARCH_URL_PRICE_PATTERN = /price-(\d*),?(\d*)?/;
export const ENGINES_PAGE_SIZE = 28;
export const ENGINES_ID = 'engines';
export const ENGINES_ALL = 'all';
export const PARAGRAPH_TEXT = 'There are two main types of propulsion systems for boats - outboard motors and inboard engines. Outboard engines are designed specifically for boating, whereas inboard motors are usually just automobile engines that have been modified for marine use. Outboards are generally lower cost, lighter weight, achieve higher speeds and are easier to access for maintenance.';
export const MERCURY_LEGACY_NAME = 'make-mercury-outboards';
export const MERCURY_REDIRECT_NAME = 'make-mercury';

export const DEFAULT_ENGINE_REFINE_SEARCH = {
  engine: '',
  make: '',
  price: {},
  year: {},
  makeModel: {},
  condition: 'any',
  state: '',
  city: [],
  zip: '',
  model: '',
  radius: '200',
  category: '',
  powerHp: {},
  horsepower: {},
  fuelType: ['all'],
  page: '',
  sort: undefined,
  ownerId: undefined
};
export const DEFAULT_NO_MAX_HORSEPOWER = '500';
export const SLIDER_MAX_VALUE = 'MAX';

export const INDEXABLE_ENGINES_FACETS = [
  'engine',
  'condition',
  'type',
  'make',
  'model',
  'makeModel',
  'state',
  'city',
  'zip',
  'fuelType',
  'horsepower',
  'page'
];

import React from 'react';
import {ENGINES_ID} from '../../../constants/Engines';
import EnginesRefineSearch from '../EnginesRefineSearch';
import BoatsRefineSearch from '../BoatsRefineSearch';
import {engineRefineSearchProps} from '../../../utils/engineTypeHelper';

const RefineSearch = (props) => {
  const {searchPage = ''} = props;

  switch (searchPage) {
  case ENGINES_ID: {
    return <EnginesRefineSearch {...engineRefineSearchProps(props)} />;
  }
  default:
    return <BoatsRefineSearch {...props} />;
  }
};

export default RefineSearch;

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pull from 'lodash/pull';
import size from 'lodash/size';
import { getDefaultParams, getActiveParams, unhyphenateUrlComponents } from '../../utils/urlHelpers/listings';
import { formatNumber, formatPriceWithDecimal } from '@dmm/lib-common/lib/formatting';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import './styles.css';

class DynamicContent extends PureComponent {

  showDynamicModelContent (model) {
    const dynamicContentType = {
      'power boats': 'powerboat',
      'sailboats': 'sailboat',
      'small boats': 'small boat',
      'pwcs': 'PWC'
    };
    const { facets, count, params } = this.props;
    const defaultParams = getDefaultParams(params);
    const makeModel = get(facets, 'makeModel', { value: ''});
    let allModelsArray = makeModel;
    if (size(makeModel) > 1) {
      let paramsMakeNamesArray = Object.keys(defaultParams.makeModel);
      let selectedMake = head(paramsMakeNamesArray);
      allModelsArray = makeModel.filter(make => {
        return normalizeString(make.value) === normalizeString(selectedMake);
      });
    }
    const allModels = head(allModelsArray);
    const title = omit(allModels, ['count', 'model']);
    const make =  get(title, 'value', '');
    const modelsList = get(allModels, 'model', []).slice();
    const classes = get(facets, 'class', []);
    const type = size(classes) ? get(dynamicContentType, head(classes).heading.toLowerCase(), 'boat') :  'boat';
    const condition = get(facets, 'condition', []);
    const numNew = condition.find(obj => { return obj.value === 'new'; });
    const numUsed = condition.find(obj => { return obj.value === 'used'; });
    const minYear = get(facets, 'minYear', []);
    const maxYear = get(facets, 'maxYear', []);
    const minMaxPercentilPrices = get(facets, 'minMaxPercentilPrices', []);
    const minPrice = minMaxPercentilPrices.find(obj => { return obj.value === 'minPrice'; });
    const maxPrice = minMaxPercentilPrices.find(obj => { return obj.value === 'maxPrice'; });
    const averagePrice = minMaxPercentilPrices.find(obj => { return obj.value === 'avgPrice'; });
    const activities = get(facets, 'activities', []);
    if (activities.length === 0) {
      activities.push({value: 'other'});
    }
    const hullShape = get(facets, 'hullShape', []).filter(obj => { return obj.value !== 'other'; });
    const hullMaterial = get(facets, 'hullMaterial', []).filter(obj => { return obj.value !== 'other'; });
    const enginesConfig = get(facets, 'enginesConfiguration', []).filter(obj => { return obj.value !== 'other'; });
    const fuelType = get(facets, 'fuelType', []).filter(obj => { return obj.value !== 'other'; });

    const modelName = unhyphenateUrlComponents(model);
    //If current model is included in the top three elements in model aggregation it is removed.
    modelsList.splice(modelsList.findIndex(item => item.value.toLowerCase() === modelName), 1);
    const modelListString = modelsList.slice(0,3).map(modelObj => modelObj.value );
    const activitiesString = activities.slice(0,3).map(modelObj => modelObj.value);

    return {
      model: capitalize(modelName),
      make: make,
      class: classes.length > 0 ? classes[0].name.toLowerCase() : '',
      type: type,
      activities: this.generateEnumString(activitiesString),
      hullMaterial: hullMaterial.length > 0 ? hullMaterial[0].value : '',
      hullShapes: hullShape.length > 0 ? hullShape[0].value : '',
      enginesConfigurations: enginesConfig.length > 0 ? enginesConfig[0].value : '',
      fuelTypes: fuelType.length > 0 ? fuelType[0].name : '',
      count: formatNumber(Math.round(count)),
      numNew: numNew ? formatNumber(Math.round(numNew.count)) : '',
      numUsed: numUsed ? formatNumber(Math.round(numUsed.count)) : '',
      minYear: minYear.length > 0 ? minYear[0].count : '',
      maxYear: maxYear.length > 0 ? maxYear[0].count : '',
      minPrice: minPrice ? formatPriceWithDecimal(Math.round(minPrice.count)) : '',
      maxPrice: maxPrice ? formatPriceWithDecimal(Math.round(maxPrice.count)) : '',
      averagePrice: averagePrice ? formatPriceWithDecimal(Math.round(averagePrice.count)) : '',
      modelsList: this.generateEnumString(modelListString)
    };
  }

  shouldShowDynamicContent() {
    const { params } = this.props;
    const searchParams = omitBy(params, (value) => {return isNil(value);});
    let selectedParams = keys(searchParams);
    selectedParams = pull(selectedParams, 'make', 'model', 'makeModel');
    if (selectedParams.length !== 0 || searchParams.make === 'make-other') {
      return false;
    }
    const defaultParams = getDefaultParams(params);
    const currentParams = getActiveParams(defaultParams);
    const makeModel = get(currentParams, 'makeModel', {});
    const makeModelKeys = Object.keys(makeModel);
    if (makeModelKeys.length === 1){
      const models = makeModel[makeModelKeys[0]];
      if (models.length === 1) {
        return this.showDynamicModelContent(models[0]);
      }
    }
    return false;
  }

  generateEnumString (elements) {
    if (elements.length === 3) {
      return `${elements[0]}, ${elements[1]} and ${elements[2]}`;
    } else if (elements.length === 2) {
      return `${elements[0]} and ${elements[1]}`;
    } else if (elements.length === 1) {
      return `${elements[0]}`;
    }
  }

  render () {
    let showDynamicContent = this.shouldShowDynamicContent();

    return (
      <>
        {
          showDynamicContent &&
          <div className="dynamic__content">
            <div className="model_content">
              <h2 className="model">{`${showDynamicContent.make} ${showDynamicContent.model}`}</h2>
              <p>
                {`A ${showDynamicContent.type} built by ${showDynamicContent.make}, the ${showDynamicContent.model} is a
                ${showDynamicContent.class} vessel. ${showDynamicContent.make} ${showDynamicContent.model}
                boats are typically used for ${showDynamicContent.activities}. `}
                {showDynamicContent.hullShapes &&
                  <span className="hullshapes">
                    {`These boats were built with a ${showDynamicContent.hullMaterial}
                    ${showDynamicContent.hullShapes}`}
                  </span>
                }
                {showDynamicContent.hullShapes && showDynamicContent.enginesConfigurations &&
                  <span className="enginesconfigurations">
                    {`; usually with an ${showDynamicContent.enginesConfigurations}`}
                  </span>
                }
                {showDynamicContent.hullShapes && showDynamicContent.enginesConfigurations &&
                showDynamicContent.fuelTypes &&
                  <span className="fueltypes">
                    {` and available in ${showDynamicContent.fuelTypes}.`}
                  </span>
                }
              </p>

              <h3>{`Got a specific ${showDynamicContent.make} ${showDynamicContent.model} in mind?`}</h3>
              <p>
                {`There are currently ${showDynamicContent.count} listings available on Boat Trader by both private
                sellers and professional boat dealers. `}
                {showDynamicContent.numNew && showDynamicContent.numUsed &&
                  <span className="numboats">
                    {`Of those available, we have ${showDynamicContent.numNew} new and
                    ${showDynamicContent.numUsed} used. `}
                  </span>
                }
                {showDynamicContent.minYear && showDynamicContent.maxYear &&
                  <span className="minmaxyear">
                    {`The oldest boat was built in ${showDynamicContent.minYear} and the newest model is
                ${showDynamicContent.maxYear}. `}
                  </span>
                }
                {showDynamicContent.minPrice && showDynamicContent.maxPrice &&
                  <span className="minpaxprice">
                    {`The starting price
                    is ${showDynamicContent.minPrice}, the most expensive is ${showDynamicContent.maxPrice}, and the
                    average price of ${showDynamicContent.averagePrice}. `}
                  </span>
                }
                {`Related boats include the following models: ${showDynamicContent.modelsList}.`}
              </p>
              <p>
                {`Boat Trader works with thousands of boat dealers and brokers to bring you one of the largest
                collections of
                ${showDynamicContent.make} ${showDynamicContent.model} boats on the market. You can also browse boat
                dealers to find a boat near you today.`}
              </p>
            </div>
          </div>
        }
      </>
    );
  }
}

DynamicContent.propTypes = {
  facets: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  count: PropTypes.number,
};

export default DynamicContent;

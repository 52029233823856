import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { LOCALSTORAGE_KEY_LOCATION, ONE_WEEK_IN_MILLISECONDS } from '../../../constants/home';
import { getLocalItem, getLocalItemWithExpiry, setLocalItemWithExpiry } from '../../../utils/storage';
import { isValidZipCodeUS } from '../SearchSubmission/searchHelper';

const LocationEntry = ({ id, style, zipcode, onZipCodeChange, radiusMiles }) => {
  const [isOpen, setOpen] = useState(false);
  const [isValidZip, setIsValidZip] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!getLocalItem(LOCALSTORAGE_KEY_LOCATION)) {
      setLocalItemWithExpiry(LOCALSTORAGE_KEY_LOCATION, zipcode, ONE_WEEK_IN_MILLISECONDS);
    } else {
      const localItemExpiry = getLocalItemWithExpiry(LOCALSTORAGE_KEY_LOCATION);
      const localItemValue = localItemExpiry !== null ?  getLocalItemWithExpiry(LOCALSTORAGE_KEY_LOCATION) : zipcode;
      onZipCodeChange(localItemValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  const handleOnChange = (e) => {
    const zipcodeValue = e.target.value.replace(/[^\d]+/ig, '');
    onZipCodeChange(zipcodeValue);

    if (zipcodeValue === '' || isValidZipCodeUS(zipcodeValue)) {
      setLocalItemWithExpiry(LOCALSTORAGE_KEY_LOCATION, zipcodeValue, ONE_WEEK_IN_MILLISECONDS);
      setIsValidZip(true);
    } else {
      setIsValidZip(false);
    }
  };

  const closeInput = (e) => {
    const zipcodeValue = e.target.value;

    if (zipcodeValue === '' || isValidZipCodeUS(zipcodeValue)) {
      setOpen(false);
      setIsValidZip(true);
    } else {
      setIsValidZip(false);
    }
  };

  const blurOnEnter = (e) => {
    const zipcodeValue = e.target.value;
    if (e.keyCode === 13 && !(zipcodeValue === '' || isValidZipCodeUS(zipcodeValue))) {
      e.target.blur();
    }
  };

  return (
    <fieldset id={id} className="locationSet" style={style} >
      <label htmlFor="radius">Within {radiusMiles || 200} Miles of </label>
      <span data-e2e="hp-location" onClick={ handleClick } className="location-display" id="boatLocation">{zipcode || 'Your Location'}</span>
      <div className={classnames('location-entry', { open: isOpen })}  id="boatEntry" role="location">
        <div className="location-heading">Zip Code</div>
        <input
          data-e2e="hp-location-input"
          ref={inputRef}
          onBlur={(e) => closeInput(e)}
          type="text"
          className={classnames('location-value', { 'location-error': !isValidZip })}
          id="location"
          data-validation="searchLocation"
          tabIndex="4"
          aria-label="Boat Zip Code"
          value={zipcode}
          onChange={handleOnChange}
          inputMode="numeric"
          maxLength="5"
          onKeyDown={(e) => blurOnEnter(e)}
        />
      </div>
    </fieldset>
  );
};

export default LocationEntry;

import React, { PureComponent } from 'react';
import { AdProvider as CommonAdProvider } from '@dmm/react-common-components';

class AdProvider extends PureComponent {
  registerAdsCallback = (callback) => {
    this.adsCallback = callback;
  }

  componentDidUpdate() {
    if (!this.url) {
      this.url = this.props.url;
      return;
    }

    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      if (this.props.url !== this.url && !this.props.isWorking) {
        this.url = this.props.url;
        this.adsCallback();
      }
    }, 0);
  }

  render(){
    return (
      <CommonAdProvider
        targeting={this.props.targeting}
        refreshSubscribe={this.registerAdsCallback}
        adsConfig={this.props.adsConfig}
        noAdsParam={this.props.noAdsParam}
        retargetOnAdRefresh={this.props.retargetOnAdRefresh}
      >
        {this.props.children}
      </CommonAdProvider>
    );
  }
}

export default AdProvider;

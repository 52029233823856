import {
  TRIDENT_SERVICES_CDN_URL,
  EXTENDED_SERVICES_REPAIR_COVERAGE_ICON,
  EXTENDED_SERVICES_WORRY_FREE_PROTECTION_ICON,
  EXTENDED_SERVICES_FINANCIAL_SECURITY_ICON,
  EXTENDED_SERVICES_COMPREHENSIVE_PROTECTION_ICON,
  EXTENDED_SERVICES_GAP_PEACE_OF_MIND_ICON,
  EXTENDED_SERVICES_COVERAGE_FOR_DEDUCTIBLES_ICON,
  EXTENDED_SERVICES_GAP_LOW_MONTHLY_COST_ICON,
} from '.';

export const gapProtectionInfo = {
  topSection: {
    title: 'GAP Coverage is a Valuable Safeguard for Boat Owners',
    bullets: [
      {
        title: 'Financial Security',
        text: 'Peace of mind knowing you won’t face significant debt in case of an unfortunate event.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_FINANCIAL_SECURITY_ICON}`,
      },
      {
        title: 'Low Monthly Cost',
        text: 'Mitigates potential financial risks for as low as $25/month.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_GAP_LOW_MONTHLY_COST_ICON}`,
      },
      {
        title: 'Comprehensive Protection',
        text: 'Steps in to cover areas that standard boat insurance may not.',
        icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_COMPREHENSIVE_PROTECTION_ICON}`,
      },
    ],
  },
  middleSection: {
    title: 'Why Invest in GAP Coverage for Your Boat?',
    text: 'In the event of a total loss of your boat due to an accident or theft, GAP (Guaranteed Asset Protection) protection covers the difference between the boat’s actual cash value and the remaining loan balance so you’re protected from significant financial losses.',
  },
  bottomSection: {
    title: 'GAP Coverage is a Wise Investment for Boat Owners',
    bullets: {
      firstRow: [
        {
          title: 'Loan Balance Protection',
          text: 'Coverage for loans up to $250,000 and payouts up to $50,000.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_REPAIR_COVERAGE_ICON}`,
        },
        {
          title: 'Coverage for Deductibles',
          text: 'Coverage for primary protection deductible up to $1,000.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_COVERAGE_FOR_DEDUCTIBLES_ICON}`,
        },
      ],
      secondRow: [
        {
          title: 'Coverage for New and Used Boats',
          text: 'Protection for boats up to 14 years of age.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_WORRY_FREE_PROTECTION_ICON}`,
        },
        {
          title: 'Peace of Mind',
          text: 'Get on the water knowing you’re financially protected.',
          icon: `${TRIDENT_SERVICES_CDN_URL}${EXTENDED_SERVICES_GAP_PEACE_OF_MIND_ICON}`,
        },
      ],
    },
  },
};

import { normalizeString } from '@dmm/lib-common/lib/formatting';
import constant from 'lodash/constant';
import get from 'lodash/get';
import map from 'lodash/map';
import times from 'lodash/times';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MiniListing from '../../../components/MiniListing';
import LoadingMiniListing from '../../../components/MiniListing/LoadingMiniListing';
import Link from '../../../components/SEO/Link';
import { LISTING_SAME_DEALER } from '../../../constants/boats';
import { addLead } from '../../../store/actions/dataLayer';
import { cleanExternalHREF } from '../../../utils/listingHelper';
import { oemUrlBuild } from '../../../utils/urlHelpers/oemBrandedSrp';

import './styles.css';

class DealerOtherBoats extends PureComponent {
  clickDealerWebsite = () => {
    this.props.addLead(this.props.listingId, 'website referral');
  }

  renderMiniListings() {
    let { dealerListings, myboats, cookies, url } = this.props;
    return dealerListings.map( (listing, index) => {
      return <MiniListing
        key={listing.id}
        listing={listing}
        myboats={myboats}
        cookies={cookies}
        url={url}
        listingType={LISTING_SAME_DEALER}
        position={index}
      />;
    });
  }

  renderLoading() {
    let list = times(4, constant(null));
    return map(list, (listing, index) => {
      return <LoadingMiniListing key={index} />;
    });
  }

  generateUrl = (owner) => {
    const dealerName = normalizeString(owner.name);
    return `/boats/dealer-${dealerName}-${owner.id}/`;
  }

  render() {
    const { isLoading, dealerListings, website, owner, showMoreBoats, mobile, listingId, listing } = this.props;
    const isBranded = get(listing.oemDetails, 'brandedSearch', false);
    const moreBoatsButtonText = isBranded ? 'See All Current Models' : 'More ' + (mobile ? 'From' : 'Boats from this') + ' Dealer';

    let dealerWebsite = null;
    if (website && !get(this.props, 'hideMoreLink', false)) {
      const cleanWebsite = cleanExternalHREF(website);
      dealerWebsite = (
        <a
          className="dealer-website"
          data-e2e="dealer-website"
          href={cleanWebsite}
          onClick={this.clickDealerWebsite}
          target="_blank"
          rel="noopener nofollow"
        >
          View Dealer Website
        </a>
      );
    }
    let dealerUrl;
    if (owner && owner.boattraderId) {
      dealerUrl = this.generateUrl(owner);
    }

    return (
      dealerListings.length > 0 && <div id="dealer-boats-section" className="dealer-boats" data-e2e="dealer-boats-section">
        <ul>
          {isLoading
            ? this.renderLoading()
            : this.renderMiniListings()}
        </ul>
        <div>
          {owner && owner.boattraderId && showMoreBoats &&
            <Link className="more-boats-button" data-e2e="more-boats-button" href={dealerUrl} onClick={() => { this.props.addLead(listingId, 'view all inventory'); }}>
              {moreBoatsButtonText}
            </Link>
          }

          {isBranded &&
            <Link className="more-boats-button" data-e2e="more-boats-button" href={oemUrlBuild(listing.oemDetails)} onClick={() => { this.props.addLead(listingId, 'view all inventory'); }}>
              {moreBoatsButtonText}
            </Link>
          }
          <div></div>
          {dealerWebsite}
        </div>
      </div>
    );
  }
}

export default connect(null, dispatch => bindActionCreators({
  addLead
}, dispatch))(DealerOtherBoats);

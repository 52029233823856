import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getClassCategory } from '../../../utils/classHelper';
import { normalizeString }  from '@dmm/lib-common/lib/formatting';
import { hyphenateTrimmed } from '../../../utils/urlHelpers/listings';
import {
  BOAT_TYPES,
  BOAT_MAKES,
  BOATS_ID,
  ENGINE_TYPES_MAP,
  ENGINE_MAKES,
  ENGINES_ALL,
  ENGINE_TYPES,
  INITIAL_EMPTY_FACETS,
  US_ZIP_CODE_REGEX,
  ENGINES_ID
} from '../../../constants/home';


const _mapMakeItems = (items) => {
  return items.map((item) => {
    const slugMake = normalizeString(hyphenateTrimmed(item.value));

    return {
      value: slugMake,
      name: item.value
    };
  });
};

// Orders the boat types (class) by orderedKeys
export const setBoatClassesOrder = (boatClasses) => {
  const orderedKeys = ['power', 'sail', 'pwc', 'small'];
  const orderedProducts = {};

  orderedKeys.forEach((key) => {
    if (boatClasses[key] !== undefined) {
      orderedProducts[key] = boatClasses[key];
    }
  });

  return orderedProducts;
};

const _buildBoatTypes = (classes) => {
  if (classes?.length > 0) {

    const boatClasses = classes.reduce((boatTypes, item) => {
      let boatType = getClassCategory(item.value);

      if (!boatTypes[boatType]) {
        boatTypes[boatType] = {
          name: item.heading,
          id: window?.crypto?.randomUUID(),
          data: []
        };

        if (BOAT_TYPES[boatType]) {
          boatTypes[boatType].data = [
            ...boatTypes[boatType]?.data,
            BOAT_TYPES[boatType]
          ];
        }
      }

      boatTypes[boatType].data = [
        ...boatTypes[boatType]?.data,
        {
          value: item.value,
          name: item.name
        }
      ];

      return boatTypes;
    }, {});

    const boatClassesOrdered = setBoatClassesOrder(boatClasses);

    return sortProductTypes({ ...{ 0: BOAT_TYPES.all }, ...boatClassesOrdered });
  }

  return {};
};

/**
 * Orders and splits the makes in two sections.
 * @param {array} makes - Makes for boats or engines.
 * @returns object
 */
const _buildOrderedMakes = (makes) => {
  if (makes?.length > 0) {
    const topItems = makes.filter((item, index) => index < 10 && !'');
    const otherItems = sortBy(makes.filter((item, index) => index >= 10), 'value');

    const topMakes = _mapMakeItems(topItems);
    const otherMakes = _mapMakeItems(otherItems);

    return {
      topMakes,
      otherMakes
    };
  }

  return {};
};

/**
 * Builds the make dropdown structure
 * @param {object} orderedMakes - Makes for boats or engines.
 * @param {object} defaultMakeContent - Default make content for boats or engines.
 * @returns object
 */
const _buildMakesDropdown = (orderedMakes, defaultMakeContent) => {
  if (isEmpty(orderedMakes) || isEmpty(defaultMakeContent)) {
    return {};
  }

  const filteredTopMakes = orderedMakes.topMakes.filter(topMake => {
    return get(defaultMakeContent,'top.data',[]).filter(defaultMake => defaultMake.name === topMake.name).length === 0;
  });
  const filteredOtherMakes = orderedMakes.otherMakes.filter(otherMake => {
    return get(defaultMakeContent,'top.data',[]).filter(defaultMake => defaultMake.name === otherMake.name).length === 0;
  });

  const topMakesDropdownSection = {
    0: {
      ...defaultMakeContent.top,
      data: [ ...defaultMakeContent.top.data, ...filteredTopMakes ]
    },
  };

  const otherMakesDropdownSection = {
    1: {
      ...defaultMakeContent.other,
      data: [ ...defaultMakeContent.other.data, ...filteredOtherMakes ]
    },
  };


  return {
    ...topMakesDropdownSection,
    ...otherMakesDropdownSection
  };
};

const _buildEngineTypes = (categories) => {
  if (categories?.length > 0) {
    const dataEngineTypes = {
      0: {
        id: 0,
        data: []
      }
    };

    const engineCategories = categories.map(mappedEngine);
    const allCategory = mappedEngine({ value: ENGINES_ALL, count: 1 });

    dataEngineTypes[0].data = [allCategory, ...engineCategories];

    return sortProductTypes( dataEngineTypes );
  }

  return {};
};

const mappedEngine = engine => ({
  name: ENGINE_TYPES_MAP[engine.value]?.name || engine.value,
  value: engine.value
});

export const sortProductTypes = (productTypes) => {
  const productTypesClone = cloneDeep(productTypes);

  for (const property in productTypesClone) {
    productTypesClone[property].data.sort((a,b) => {
      if (a.name.startsWith('All ')) { return -1;}
      if (b.name.startsWith('All ')) { return 1;}
      if (a.name < b.name) { return -1;}
      if (a.name > b.name) { return 1; }
      return 0;
    });
  }
  return productTypesClone;
};

export const buildParams = (options) => {
  const { zipcode, facetType, facetMake, activeTab } = options;
  let facetTypeObj = {};
  let facetMakeModelObj = {};
  let zipcodeObj = {};
  let boatType = getClassCategory(facetType);

  if (activeTab === BOATS_ID && facetType) {
    facetTypeObj.multiFacetedBoatTypeClass = {};
    facetTypeObj.multiFacetedBoatTypeClass[boatType] = [facetType];
  }

  if (activeTab === ENGINES_ID && facetType) {
    facetTypeObj.engine = facetType;
  }

  if (facetMake && facetMake !== 'all') {
    facetMakeModelObj.makeModel = {};
    facetMakeModelObj.makeModel[facetMake] = [];
  }

  if (zipcode) {
    zipcodeObj = { zip: zipcode };
  }

  return { ...facetTypeObj, ...facetMakeModelObj, ...zipcodeObj };
};

export const buildDropdownList = (facets, facetsType) => {
  if (!isEqual(facets, INITIAL_EMPTY_FACETS)) {
    const { class: classes = [], make: makes = [], category: categories = [] } = facets;
    const orderedMakes = _buildOrderedMakes(makes);
    let productTypes;
    let productMakes;
    if (facetsType === BOATS_ID) {
      productTypes = _buildBoatTypes(classes);
      productMakes = _buildMakesDropdown(orderedMakes, BOAT_MAKES);
    } else {
      productTypes = _buildEngineTypes(categories);
      productMakes = _buildMakesDropdown(orderedMakes, ENGINE_MAKES);
    }

    return {
      productTypes: !isEmpty(productTypes) ? productTypes : facetsType === BOATS_ID ? { 0: BOAT_TYPES.all } : { 0: ENGINE_TYPES.all },
      productMakes: !isEmpty(productMakes) ? productMakes : facetsType === BOATS_ID ? { 0: BOAT_MAKES.top } : { 0: ENGINE_MAKES.top }
    };
  }

  return {
    productTypes: facetsType === BOATS_ID ? { 0: BOAT_TYPES.all } : { 0: ENGINE_TYPES.all },
    productMakes: facetsType === BOATS_ID ? { 0: BOAT_MAKES.top } : { 0: ENGINE_MAKES.top }
  };

};

export const isValidZipCodeUS = (value) => US_ZIP_CODE_REGEX.test((value.trim()));


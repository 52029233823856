import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setGenericEvent } from '../../../../store/actions/dataLayer';

const DealerLocation = ({ dealerKey, dealer, setDealerIndex, setGenericEvent, partyId }) => {
  const onClick = () => {
    setGenericEvent('modal action', 'view dealer', partyId);
    setDealerIndex(dealerKey);
  };

  return (
    <>
      <div className="col-1">
        <div className="rank"><strong>{dealerKey + 1}</strong></div>
        <div className="distance" data-e2e="dealer-distance">{dealer.distance?.map((term, key) => <p key={key}>{term}</p>)}</div>
      </div>
      <div className="col-2">
        <h3>{dealer.companyName}</h3>
        <address className="location" data-e2e="dealer-location">{dealer.location}</address>
        <button className={`dealer-${dealerKey}`} data-e2e="view-dealer-info" onClick={onClick} >View Dealer Info</button>
      </div>
    </>
  );
};

export default connect(
  null,
  dispatch => bindActionCreators({
    setGenericEvent
  }, dispatch)
)(DealerLocation);

import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import LoadingPlaceholder from '../LoadingPlaceholder';
import { isOverlapped } from '../../utils';
import './styles.css';
import { RenderHTML } from '@dmm/react-common-components';

const DescriptionBox = props => {
  const [ showMore, setShowMore ] = useState(props.showMore);
  const [ overlap, setOverlap ] = useState(false);
  const detailBox = useRef();

  useEffect(() => {
    const onLoad = () => {
      if (isOverlapped(detailBox.current)) {
        setOverlap(true);
      }
    };
    window.addEventListener('load', onLoad);
    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, []);

  const toggleShowMore = () => {
    if (showMore && props.tracking) {
      props.tracking('show more');
    }
    setShowMore(!showMore);
  };

  const renderData = data => {
    if (props.multiItem) {
      return renderMultipleDescription(data);
    }
    return renderDescription(data);

  };

  const renderDescription = data => (
    <>
      <RenderHTML html={data} />
      <div className="more-less">
        <span className="toggle" tabIndex="0" onClick={toggleShowMore}>
          {showMore ? 'Show More' : 'Show Less'}
        </span>
      </div>
    </>
  );

  const renderMultipleDescription = data => data.map((description, index) =>
    (<div className="description-item" key={index}>
      <div className="title"><h3>{description.title}</h3></div>
      <RenderHTML html={description.value} />
    </div>)
  );

  const renderLoading = () => (
    <>
      <LoadingPlaceholder type="text" />
      <LoadingPlaceholder type="text" />
    </>
  );

  const { data, dataName, isLoading } = props;
  return (
    <div ref={detailBox} className={classnames('detail-description', { [dataName]: dataName, overlap: overlap, more: showMore, less: !showMore })}>
      {isLoading || !data ? renderLoading() : renderData(data)}
    </div>
  );
};

export default DescriptionBox;

import React from 'react';
import { isBTClass } from '../../../utils/classHelper';
import NotFoundPage from '../../NotFoundPage';
import './styles.css';

import {
  BoatClassArticle,
  BoatClassMakes,
  BoatClassRelatedArticles,
  BoatClassVideos
} from './BoatClassComponents/BoatClassSections';

const getClassLink = (boatClass) => {
  const boatClassLinksMap = {
    'pontoon-boats': '/boats/type-power/class-power-pontoon/',
    'jon-boats': '/boats/type-power/class-power-jon/',
    'center-console-boats': '/boats/type-power/class-power-center/'
  };
  return boatClassLinksMap[boatClass];
};

const getClassName = (boatClass) => {
  const boatClassNameMap = {
    'pontoon-boats': 'Pontoon Boat',
    'jon-boats': 'Jon Boat',
    'center-console-boats': 'Center Console Boat'
  };
  return boatClassNameMap[boatClass];
};

const BoatClassContent = ({contentHtml, introHtml, videos, relatedArticles, makes, name, boatClass}) => {
  if (!contentHtml) {
    return null;
  }
  const classLink = getClassLink(boatClass);
  const boatsClassName = getClassName(boatClass);

  return (
    <article className="boatclass-article">
      <BoatClassArticle classLink={classLink} introHtml={introHtml} contentHtml={contentHtml}/>
      <BoatClassVideos videos={videos} boatsClassName={boatsClassName} />
      <BoatClassRelatedArticles articles={relatedArticles} />
      <BoatClassMakes makes={makes} name={name} />
    </article>
  );
};

const MainContent = ({boatClass, displayName, name, boatClassArticle = {}}) => {
  if (!isBTClass(boatClass)) {
    return (<NotFoundPage/>);
  }
  const {intro, content, videos, relatedArticles, makes} = boatClassArticle;
  return (
    <div className="boat-class-container">
      <h1>{displayName}</h1>
      <BoatClassContent
        contentHtml={content}
        introHtml={intro}
        videos={videos}
        relatedArticles={relatedArticles}
        makes={makes}
        name={name}
        boatClass={boatClass}
      />
    </div>
  );
};

export default MainContent;

/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const TrustBox = ({
  locale,
  templateId,
  height,
  width,
  theme,
  color,
  reviewLanguages = '',
  stars = '',
}) => {
  const ref = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
      setScriptLoaded(true);
    } else {
      window.addEventListener('load', () => {
        if (typeof window !== 'undefined' && window.Trustpilot) {
          setScriptLoaded(true);
        }
      });
    }
  }, []);

  return (
    <div>
      {scriptLoaded ? (
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale={locale}
          data-template-id={templateId}
          data-businessunit-id="5f11b53f9ad85600017c3844"
          data-style-height={height}
          data-style-width={width}
          data-theme={theme}
          data-text-color={color}
          data-stars={stars}
          data-review-languages={reviewLanguages}
        >
          <a
            href="https://www.trustpilot.com/review/tridentfunding.com"
            target="_blank"
          ></a>
        </div>
      ) : (
        <div className="trustpilot-widget-loading"></div>
      )}
    </div>
  );
};

TrustBox.propTypes = {
  locale: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  reviewLanguages: PropTypes.string,
  stars: PropTypes.string,
};

export default TrustBox;

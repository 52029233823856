export const boatTypeAdsConfig = {
  'div-gpt-leaderboard-top': {
    unitPath: '/252108799/BoatTrader/boat-types/leaderboard-top',
    breakpoint: {
      min: 768,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728,90]
    ],
    targeting: {
      pos: 'leaderboard-top'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  },
  'div-gpt-leaderboard-mid': {
    unitPath: '/252108799/BoatTrader/boat-types/leaderboard-mid',
    breakpoint: {
      min: 1200,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728,90],
      [970,90],
      [970,250]
    ],
    targeting: {
      pos: 'leaderboard-mid'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  },
  'div-gpt-leaderboard-bottom': {
    unitPath: '/252108799/BoatTrader/boat-types/leaderboard-bottom',
    breakpoint: {
      min: 1200,
      max: Number.MAX_SAFE_INTEGER
    },
    size: [
      [728,90],
      [970,90],
      [970,250]
    ],
    targeting: {
      pos: 'leaderboard-bottom'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309651'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '484131561'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599611'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1198850'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346677'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-box-1': {
    unitPath: '/252108799/BoatTrader/boat-types/mobile-box-1',
    breakpoint: {
      min: 0,
      max: 768
    },
    size: [
      [300, 250],
      [320, 100]
    ],
    targeting: {
      pos: 'mobile-box-1'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '14309631'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '188838085'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540599588'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1199154'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346708'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-box-2': {
    unitPath: '/252108799/BoatTrader/boat-types/mobile-box-2',
    breakpoint: {
      min: 0,
      max: 768
    },
    size: [
      [300, 250],
      [320, 100]
    ],
    targeting: {
      pos: 'mobile-box-2'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '15803730'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '429854578'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540724224'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1271356'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346706'
          }
        }
      ]
    }
  },
  'div-gpt-mobile-box-3': {
    unitPath: '/252108799/BoatTrader/boat-types/mobile-box-3',
    breakpoint: {
      min: 0,
      max: 768
    },
    size: [
      [300, 250],
      [320, 100]
    ],
    targeting: {
      pos: 'mobile-box-3'
    },
    prebid: {
      bids: [
        {
          bidder: 'appnexus',
          params: {
            placementId: '15803730'
          }
        },
        {
          bidder: 'medianet',
          params: {
            cid: '8CU7PN9JY',
            crid: '429854578'
          }
        },
        {
          bidder: 'openx',
          params: {
            delDomain: 'dominionmedia-d.openx.net',
            unit: '540724224'
          }
        },
        {
          bidder: 'rubicon',
          params: {
            accountId: 16902,
            siteId: 242602,
            zoneId: '1271356'
          }
        },
        {
          bidder: 'districtmDMX',
          params: {
            memberid: 101828,
            dmxid: '346706'
          }
        }
      ]
    }
  },
};

export const boatTypeAddProps = (canonicalUrl) => {
  return {
    url: canonicalUrl,
    isWorking: false,
    targeting: {pageSize: 8, page: 1},
    adsConfig: boatTypeAdsConfig
  };
};

import replace from 'lodash/replace';
import get from 'lodash/get';
import {
  DEFAULT_POWER,
  DEFAULT_SAIL,
  DEFAULT_PWC,
  DEFAULT_SMALL,
  smallBoatClasses,
  legacyMappings
} from '../../../utils/classHelper';
import { unhyphenateUrlComponents, unslugify } from '../../../utils/urlHelpers/listings';

const DEFAULT_BOATS_TYPES = [DEFAULT_POWER, DEFAULT_SAIL, DEFAULT_PWC, DEFAULT_SMALL];

export const getReportedBoatTypeByClass = (boatClass) => {
  if (!boatClass || boatClass === 'all') {
    return undefined;
  }

  switch (boatClass){
  case DEFAULT_PWC:
    return 'personal watercraft';
  case DEFAULT_SMALL:
    return 'small boat';
  }

  if (smallBoatClasses.includes(boatClass)) {
    return 'small boat';
  }

  return boatClass.split('-')[0];
};


const legacyMappingsForAds = {};
const specialLegacyClasses = {
  'sail-trimaran': 'trimaran',
  'power-cruiser': 'cruiser'
};
for (let legacyClass in legacyMappings) {
  legacyMappings[legacyClass].forEach(boatClass => {
    legacyMappingsForAds[boatClass] = legacyClass;
  });
}

smallBoatClasses.forEach(boatClass => {
  legacyMappingsForAds[boatClass] = boatClass;
});

for (let legacyClass in specialLegacyClasses) {
  legacyMappingsForAds[legacyClass] = specialLegacyClasses[legacyClass];
}

export const getReportedBoatClassName = (boatClass) => {
  if (!boatClass || DEFAULT_BOATS_TYPES.includes(boatClass) || boatClass === 'all') {
    return undefined;
  }

  return unhyphenateUrlComponents(unslugify(replace(get(legacyMappingsForAds, boatClass, boatClass),/\+/g, ' ')));
};

import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { bindActionCreators } from 'redux';
import {
  BANNER_ATTRIBUTES,
  BANNER_TEXTS,
  LISTING_DEALER,
  LISTING_ENHANCED,
  LISTING_STANDARD,
  LIVE_VIDEO_TOUR,
  LOCAL_DELIVERY
} from '../../constants/boats';
import { likeBoat, unlikeBoat } from '../../store/actions';
import { addLead, setGenericEvent, setListingClick, setProductImpression } from '../../store/actions/dataLayer';
import { toShortFormat } from '../../utils';
import { getEnhancedLogo, getLocation, getPrice, getPriceAlternate, getPriceBeforeDiscount, getPriceCut, getTitle } from '../../utils/listingHelper';
import { getListingReactUrlParams } from '../../utils/urlHelpers/boat';
import LikedBoat from '../LikedBoat';
import ListingImage from '../ListingImage';
import Link from '../SEO/Link';
import SafeLogo from '../SafeLogo';
import MonthlyPaymentTooltip from './components/MonthlyPaymentTooltip';
import './enhanced-styles.css';
import './styles.css';

const ContactForm = lazy(() => import('../ContactForm'));

const mailSvg = (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 0H1.5C0.675 0 0.00749999 0.675 0.00749999 1.5L0 10.5C0 11.325 0.675 12 1.5 12H13.5C14.325 12 15 11.325 15 10.5V1.5C15 0.675 14.325 0 13.5 0ZM13.5 3L7.5 6.75L1.5 3V1.5L7.5 5.25L13.5 1.5V3Z" fill="#FFF"/>
  </svg>
);


/* This component is only being used for Engines SRP */
class LegacyListingResult extends PureComponent {
  constructor(props) {
    super(props);
    this.handleContactButtonClick = this.handleContactButtonClick.bind(this);
    this.handleFormButtonClose = this.handleFormButtonClose.bind(this);
    this.handleTourButtonClick = this.handleTourButtonClick.bind(this);
  }

  handleContactButtonClick() {
    if (!this.props.contactForm.form || this.props.contactForm.type !== 'contact') {
      const { listing, dealerId } = this.props;
      if (dealerId) {
        this.props.addLead(listing.id, 'email lead', 'email lead');
      }
      this.addContactForm(
        'contact',
        'Contact Seller'
      );
    } else {
      this.handleFormButtonClose();
    }
  }

  handleTourButtonClick() {
    if (!this.props.contactForm.form || this.props.contactForm.type !== 'tour') {
      const {
        listing
      } = this.props;

      this.props.addLead(listing.id, 'video tour open');
      this.addContactForm(
        'tour',
        'Schedule Live Video Tour',
        'Submit your information below and the seller will be in touch to schedule a live video tour.',
        true,
        'I\'d like to schedule a Live Video Tour of this boat!',
        false
      );
    } else {
      this.handleFormButtonClose();
    }
  }

  handleFormButtonClose() {
    this.props.addContactForm({
      type: '',
      form: undefined
    });
  }

  onContactClick = () => {
    const { listing } = this.props;
    const isOemDealer = get(this.props, 'oemDetails.oemDealerLocator', false);
    if (isOemDealer) {
      this.props.setGenericEvent('soft lead', 'find a dealer', 'page navbar');
    }
    const partyId = get(this.props, 'oemDetails.brandId');
    const isOemManufacturer = get(this.props, 'oemDetails.emailManufacturer', false);
    isOemDealer ?
      this.props.handleContactDealer('locator', { isOemDealer, isOemManufacturer, listing }) :
      this.props.handleContactDealer('oem', { partyId, isOemDealer, isOemManufacturer, listing });
  };

  renderLoader = () => <div className="preloader"></div>;

  addContactForm(type, title, subtitle, hideComments, comment, analytics = true) {
    let { listing } = this.props;
    let listingType = get(listing, 'featureType.enhanced', false) ? LISTING_ENHANCED : LISTING_STANDARD;
    let contactData = {
      boatClass: listing.class,
      make: listing.make,
      length: get(listing, 'specifications.dimensions.lengths.nominal.ft', 0),
      state: listing.location.address.state,
      listingType
    };

    this.props.addContactForm({
      type: type,
      form:
      <Suspense fallback={this.renderLoader()}>
        <ContactForm
          hideComments={hideComments}
          title={title}
          subtitle={subtitle}
          key={listing.id}
          open={true}
          emailLead={analytics}
          listingId={listing.id}
          data={contactData}
          prePopulatedText={comment}
          onClose={this.handleFormButtonClose}
          type={type}
          listing={listing}
          contactSubmitTrack={true}
        />
      </Suspense>
    });
  }


  componentDidMount(){
    let { listing, position } = this.props;
    let listingType = get(listing, 'featureType.enhanced', false) ? LISTING_ENHANCED : LISTING_STANDARD;
    let { id } = listing;
    const region = this.props.dataLayer?.region || undefined;
    const category = this.props.dataLayer?.category || undefined;
    const container = this.props.dataLayer?.container || undefined;
    this.props.setProductImpression(id, listingType, position.position, position.page, container, region, category);
    window?.permutiveHelper?.addListing && window?.permutiveHelper?.addListing(listing);
  }

  render() {
    let { listing, myboats = [], cookies, position, itemCount, dealerId, isEngine = false, tridentTeaserRate } = this.props;
    let listingType = get(listing, 'featureType.enhanced', false) ? LISTING_ENHANCED : LISTING_STANDARD;
    listingType = dealerId ? LISTING_DEALER : listingType;
    let title = getTitle(listing);
    let resize = get(listing, 'featureType.enhanced', false) ? {width: 400, height: 267} : {width: 300, height: 222};
    let location = getLocation(listing, false, true);
    let portalLink = getListingReactUrlParams(listing, isEngine);
    let listingAnchorParams = {
      'data-reporting-click-product-id': dealerId ? dealerId.split('-').pop() : listing.id,
      'data-reporting-click-listing-type': listingType,
      'data-reporting-rank': position.position,
      'data-reporting-page': position.page,
      ...portalLink
    };

    const priceDiscount = get(listing, 'price.discount');
    const listingAttributes = get(listing,  'attributes', []);
    const distanceToFilteredLocation = get(listing, 'location.distanceToFilteredLocation', undefined);
    const hasLocalDelivery = listingAttributes.includes(LOCAL_DELIVERY);
    const hasLiveVideoTour = listingAttributes.includes(LIVE_VIDEO_TOUR);
    const bannerAttribute = find(listingAttributes, (a) => BANNER_ATTRIBUTES.includes(a));
    const contact = 'Contact';
    const bannerText = get(BANNER_TEXTS, bannerAttribute);
    const contactClickType = bannerAttribute ? `${listingType} ${bannerAttribute}` : listingType;
    const brandedContactText = get(this.props.oemDetails, 'oemDealerLocator', false) ? 'Find a Dealer' : 'Contact';

    let brandedFindDealerProperties = {};
    if (get(this.props.oemDetails, 'oemDealerLocator', false)) {
      brandedFindDealerProperties = {
        'data-reporting-event-category': 'soft lead',
        'data-reporting-event-action': 'find a dealer',
        'data-reporting-event-label': 'page navbar'
      };
    }
    const specificationsLength = get(listing, 'specifications.dimensions.lengths.nominal.ft', 0);

    return (<>
      <li
        className={classnames({'premium': get(listing, 'featureType.enhanced')})}
        data-listing-id={listing.id}
        data-reporting-click-product-id={listing.id}
        data-reporting-click-listing-type={listingType}
      >
        <div>
          {bannerAttribute &&
            <div className={classnames('banner', bannerAttribute)}>
              {bannerText}
            </div>
          }
          <div className="inner">
            <div className="image-container">
              <div className="dummy"/>
              <div className="image">
                {
                  listing.showSaveButton &&
                  <LikedBoat
                    listingId={listing.aliases['boat-trader']}
                    listing={listing}
                    discount={priceDiscount}
                    myboats={myboats}
                    imtId={listing.aliases.imt}
                    cookies={cookies}
                    url={this.props.url}/>
                }
                {hasLocalDelivery &&
                  <Tooltip
                    content="Local Delivery Available"
                    useHover={true}
                    eventOn="onClick"
                    eventOff="onClick"
                    className="tooltip"
                    direction="down"
                  >
                    <div className="delivery"></div>
                  </Tooltip>
                }
                <Link {...listingAnchorParams}
                  onClick={() => {
                    setListingClick(listing.id, listingType, '', '');
                  }}
                >
                  <ListingImage
                    listing={listing}
                    resize={resize}
                    lazyLoad={itemCount > 4}
                  />
                </Link>

                {priceDiscount &&
                <div className="price-cut  display-none">
                  {`Price Drop: ${getPriceCut(listing)} (${toShortFormat(priceDiscount.revisedDate)})`}
                </div>
                }

              </div>
            </div>

            <div className="description">
              <div className="top">
                <div className="fire-icon hide">
                  <span className="fire"></span>
                  <span className="fire-text"></span>
                </div>

                {bannerAttribute &&
                  <div className={classnames('banner', 'banner-variant', bannerAttribute)}>
                    {bannerText}
                  </div>
                }
                <div className="name">
                  <Link {...listingAnchorParams}
                    onClick={() => {
                      setListingClick(listing.id, listingType, '', '');
                    }}>{title}</Link>
                  <Link className="main-link" {...listingAnchorParams}
                    onClick={() => {
                      setListingClick(listing.id, listingType, '', '');
                    }}>
                    <h2 className="visuallyhidden">{title}</h2>
                  </Link>

                  <div className="location">{location}</div>
                </div>

                <div className="prices">
                  <div className="price alternate hide">
                    {priceDiscount && <span className="priceDiscount">
                      {getPriceBeforeDiscount(listing)}
                    </span>}
                    <span className={classnames({'discount': priceDiscount})}>
                      {getPriceAlternate(listing)}
                    </span>
                  </div>
                  <div className="price">{getPrice(listing)}</div>
                  {priceDiscount &&
                  <div className="price-cut">
                    {`Price Drop: ${getPriceCut(listing)}`}
                  </div>
                  }

                  {tridentTeaserRate &&
                   <MonthlyPaymentTooltip
                     listing={listing}
                     teaserRate={tridentTeaserRate}
                   />
                  }
                </div>

                {!isNaN(distanceToFilteredLocation) &&
                    <span name="distance" className="location-distance">{distanceToFilteredLocation}</span>
                }
              </div>

              <div className="center hide">
                {hasLocalDelivery && <div>
                  <span className="delivery"></span>
                  Local Delivery Available
                </div>}
                {hasLiveVideoTour && <div>
                  <span className="video"></span>
                  Live Video Tour
                </div>}
                {location && <div>
                  <span className="location"></span>
                  {location}
                </div>}
                <div>
                  <span className="broker"></span>
                  {get(listing.owner, 'rootName', false) ? listing.owner.rootName : get(listing.owner, 'name', '')}
                </div>
              </div>

              <div className="bottom">
                <div className="offered-by">
                  {get(listing, 'featureType.enhanced', false)  &&
                  <a
                    className="dealer-logo"
                    data-reporting-contact-click-product-id={listing.id}
                    data-reporting-contact-click-boat-classname={listing.class}
                    data-reporting-contact-click-make={listing.make}
                    data-reporting-contact-click-length={specificationsLength}
                    data-reporting-contact-click-listing-type={listingType}
                    data-reporting-contact-click-state={listing.location.state}
                    onClick={this.handleContactButtonClick}
                  >
                    <SafeLogo src={getEnhancedLogo(listing)} alt="dealer logo" className="dealer-img" />
                  </a>
                  }
                  <span>{get(listing.owner, 'rootName', false) ? listing.owner.rootName : get(listing.owner, 'name', '')}</span>
                </div>

                <div className="offered-by-alternate hide">
                  {get(listing, 'featureType.enhanced', false) && <a className="dealer-logo"
                    data-reporting-contact-click-product-id={listing.id}
                    data-reporting-contact-click-boat-classname={listing.class}
                    data-reporting-contact-click-make={listing.make}
                    data-reporting-contact-click-length={specificationsLength}
                    data-reporting-contact-click-listing-type={listingType}
                    data-reporting-contact-click-state={listing.location.state}
                    onClick={this.handleContactButtonClick}>
                    <SafeLogo src={getEnhancedLogo(listing)} alt="dealer logo" className="dealer-img" />
                  </a>}
                </div>

                <div className={classnames('contact', { 'has_delivery': hasLocalDelivery })}>

                  {hasLiveVideoTour && !this.props.isBrandedSrp &&
                  <Link className="contact-seller-alt"
                    data-reporting-contact-click-product-id={listing.id}
                    data-reporting-contact-click-boat-classname={listing.class}
                    data-reporting-contact-click-make={listing.make}
                    data-reporting-contact-click-length={specificationsLength}
                    data-reporting-contact-click-listing-type={listingType}
                    data-reporting-contact-click-state={listing.location.state}
                    onClick={this.handleTourButtonClick}
                  >
                    <span className="video_icon"></span>
                    <span className="video_text" >Live Video Tour</span>
                  </Link>
                  }

                  {(hasLiveVideoTour || get(listing, 'featureType.enhanced', false)) && !this.props.isBrandedSrp &&
                  <Action
                    data-reporting-contact-click-product-id={listing.id}
                    data-reporting-contact-click-boat-classname={listing.class}
                    data-reporting-contact-click-make={listing.make}
                    data-reporting-contact-click-length={specificationsLength}
                    data-reporting-contact-click-listing-type={contactClickType}
                    data-reporting-contact-click-state={listing.location.state}
                    onClick={this.handleContactButtonClick}
                    label={contact}
                    icon={mailSvg}
                    className="contact-seller"
                  />
                  }
                  {this.props.isBrandedSrp &&
                    <Action
                      as="a"
                      id="oem-button"
                      className="contact-seller"
                      {
                        ...brandedFindDealerProperties
                      }
                      onClick={this.onContactClick}
                      label={brandedContactText}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </>);
  }
}

const mapStateToProps = (state) => {
  return {
    sponsorCriteria: get(state.app, 'data.sponsored.criteria'),
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false),
    userRef: state.app.userRef
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    setProductImpression,
    addLead,
    setGenericEvent,
    likeBoat, unlikeBoat
  }, dispatch)
)(LegacyListingResult);

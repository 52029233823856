import React from 'react';
import './styles.css';
import {pluralize, safeWindowSize} from '../../utils/dealerSRPHelper';
import {getDealerSearchUrl, makeDealerUrl} from '../../utils/urlHelpers/boatDealers';
import DealerViewAll from '../DealerViewAll';
import {hasCountedKey, toColumns} from '../../utils/dealerHelpers/custom';
import DealerColumn from '../DealerColumn';

const toMakeLink = (make, state, city, postalCode) => {
  if (!hasCountedKey(make)) {
    return null;
  }
  return {
    name: make.key,
    count: make.doc_count,
    url: makeDealerUrl(state, city, postalCode, make.key)
  };
};

const DealerMakes = ({topMakes = '', singleView = false, allMakes = [], filters = {}}) => {
  const lis = singleView ? allMakes : topMakes?.match(/<li>.*?/g);
  const dealerStr = pluralize('Dealer', lis?.length);
  const makes = pluralize('Make', lis?.length);
  const viewAllTitle = `${dealerStr} by Make`;
  const title = singleView ?  viewAllTitle : `Featured ${makes}`;
  const {postalCode, countrySubDivisionCode, city} = filters;
  const modalUrlParams = { modalMakes: true, city, countrySubDivisionCode, postalCode };
  let makesColumns = [];
  let modalMakesColumns;
  const allMakeLinks = allMakes.map(makeName => toMakeLink(makeName, countrySubDivisionCode, city, postalCode)).filter(link => !!link);
  const totalCols = safeWindowSize().innerWidth <= 768 ? 1 : 4;
  const width = totalCols === 1 ? 90 : 60;
  const height = 50;
  if (!singleView) {
    const modalColSize = Math.ceil(allMakeLinks.length / totalCols);
    modalMakesColumns = toColumns(allMakeLinks, modalColSize, totalCols);
    makesColumns = modalMakesColumns.map((colList, i) => <DealerColumn dealerCols={colList} key={i}/>);
  }

  return (
    <div className="dealer-makes-container" data-e2e="dealer-makes-container">
      <h2 data-e2e="featured-makes-title">{title}</h2>
      {
        singleView ? <DealerColumn dealerCols={allMakeLinks} /> :
          <>
            <div dangerouslySetInnerHTML={{__html: topMakes}} className="dealer-makes" data-e2e="dealer-makes"></div>
            <DealerViewAll title={viewAllTitle} href={getDealerSearchUrl(modalUrlParams)} modalContent={makesColumns} width={width} height={height}/>
          </>
      }
    </div>
  );
};

export default DealerMakes;

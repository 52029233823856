import * as httpClient from '../httpClient';
import { cookiesLib } from '../../utils/cookies';
import { LEAD_MARINE_TYPE } from '@dmm/lib-common/lib/mates/matesBmMessageGenerator';

export const prepareLeadData = (listing, formFields, leadSource) => {
  const { id, owner } = listing;
  const { id: partyId } = owner;
  const leadData = {
    imtId: id,
    source: leadSource,
    formFields: formFields,
    id: partyId
  };
  leadData.type = id && partyId ? LEAD_MARINE_TYPE.dealerLocatorLineItem : LEAD_MARINE_TYPE.serviceProvider;
  leadData.gaClientID = cookiesLib.get('_ga');
  //oem specific
  if (listing.isOemModel && listing.oemDetails) {
    leadData.copyOem = listing.oemDetails.oemDealerLocator && listing.oemDetails.emailManufacturer;
    leadData.oemId = listing.oemDetails.ownerId;
    leadData.brandId = listing.oemDetails.brandId;
  }
  return leadData;
};

export const prepareLeadDataForDealer = (dealer, leadSource, customer) => {
  const leadData = {
    id: dealer.id,
    imtId: dealer.id,
    source: leadSource,
    formFields: customer,
    type: LEAD_MARINE_TYPE.serviceProvider,
    customer: customer,
    gaClientID: cookiesLib.get('_ga')
  };
  return leadData;
};

export const sendLead = leadData => {
  let lead = buildLead(leadData);
  return httpClient.getSecureProxyHttpClient()
    .post('/app/lead', lead);
};

const buildLead = leadData => {
  let fullName = leadData.formFields.name.split(' ');
  const lead = {
    id: leadData.id,
    imtId: leadData.imtId,
    leadSource: leadData.source,
    type: leadData.type,
    requestType: leadData.cta,
    customer: {
      firstName: fullName[0],
      lastName: fullName[1],
      email: leadData.formFields.email,
      comments: leadData.formFields.comments,
      phone: {
        type: 'Phone',
        number: leadData.formFields.phone
      },
      address: {
        zip: leadData.formFields.zip,
        country: leadData.formFields.zip ? 'US' : undefined
      }
    },
    gaClientID: leadData.gaClientID,
    captchaData: {
      token: leadData.captchaData?.token,
      expectedAction: leadData.captchaData?.expectedAction
    }
  };

  if (leadData.copyOem) {
    lead.copyOem = leadData.copyOem;
  }

  if (leadData.oemId) {
    lead.oemId = leadData.oemId;
  }
  if (leadData.brandId) {
    lead.brandId = leadData.brandId;
  }
  return lead;
};

import React from 'react';
import './styles.css';
import {InfoDealerPanel, ExternalLinks} from '@dmm/lib-react-ui-components';
import {RenderHTML} from '@dmm/react-common-components';
import {generateSearchPath} from '../../../utils/urlHelpers/listings';
import {normalizeString} from '@dmm/lib-common/lib/formatting';
import {prefixedUrl} from '../../../utils/urlHelpers/boat';
import { setSoftLeadEvent } from '../../../store/actions/dataLayer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const OemFooter = ({oemDetails, setSoftLeadEvent}) => {
  const {brandId, name, aboutOemImg, website, aboutOem} = oemDetails;
  const title = `About ${name}`;
  const learnMoreText = `Learn more about ${name}`;
  const buttonLink = generateSearchPath({make: normalizeString(name)});
  const externalUrl = prefixedUrl(website);
  const showExternalLinks = !!(oemDetails?.externalLinks?.length);
  const showInfoDealerPanel = !!(aboutOem);
  const showOemFooter = showInfoDealerPanel || showExternalLinks;

  if (!showOemFooter) {
    return null;
  }
  const brandExternalLinksProps = (oemDetails) => {
    const externalLinks = {
      title: `${oemDetails.name} Information`,
      links: oemDetails.externalLinks.map((obj) => {
        return {
          onClick: () => { window.open(obj.url, '_blank'); },
          icon: obj.icon,
          title: {
            text: obj.title
          }
        };
      }),
      showItemsMax: 4
    };
    return externalLinks;
  };

  const handleOnClickLink = () => {
    setSoftLeadEvent('website referral', 'partyId', brandId);
  };

  return (<>
    <div id="oem-footer">
      { showExternalLinks && <ExternalLinks {...brandExternalLinksProps(oemDetails)} /> }
      { showInfoDealerPanel &&
          <InfoDealerPanel
            imgSrc={aboutOemImg}
            imgAlt={name}
            title={title}
            linkText={learnMoreText}
            linkUrl={externalUrl}
            onClickLinkHandler={() => handleOnClickLink(brandId)}
            buttonText={'See All New & Used Listings'}
            buttonLink={buttonLink}
            linkUrlRel={'nofollow'}
            linkUrlTarget={'_blank'}
          >
            <RenderHTML html={aboutOem}/>
          </InfoDealerPanel>
      }
    </div>
  </>
  );
};

export default connect(
  null,
  dispatch => bindActionCreators({
    setSoftLeadEvent
  }, dispatch )
)(OemFooter);


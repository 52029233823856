import React, { Component } from 'react';
import constant from 'lodash/constant';
import times from 'lodash/times';
import classnames from 'classnames';

/* Component used for Boats SRP */
export default class LoadingListingListV2 extends Component {
  render(){
    let { number } = this.props;
    let list = times(number, constant(null));

    return (
      <ol className={classnames('boat-list', 'loading')}>
        <li className="boat-list-modal"></li>
        { list.map((item, index) => {
          return (
            <li key={index} className="lib-card">
              <div className="inner">
                <div className="image-container">
                  <div className="dummy"></div>
                  <div className="image">
                    <div className="preloader"></div>
                  </div>
                </div>
              </div>
            </li>
          );}
        )}
      </ol>
    );
  }
}

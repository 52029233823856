import { getLegacyMappingFromBoatClass } from './classHelper';
import { unhyphenateUrlComponents, unslugify } from './urlHelpers/listings';
import { isServer } from '../store/utils';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import join from 'lodash/join';
import map from 'lodash/map';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import replace from 'lodash/replace';
import uniq from 'lodash/uniq';
import { roundLength } from '../utils/listingHelper';
import { ENGINES_ID } from '../constants/Engines';
import { SOCIAL_BROWSE_PAGE } from '../constants/SocialBrowsePage';

const pageParamDefaults = {
  description: 'website',
  country: 'US',
  portalName: 'Boat Trader',
  section: '',
  subSection: '',
  pageName: '',
  detailedPageName: ''
};

const page = {
  HOME: 'Home',
  SEARCH_RESULTS: 'SearchResults',
  OEM_SEARCH_RESULTS: 'OemSearchResults',
  SERVICES: 'Services',
  BOAT_DETAILS: 'BoatDetails',
  FINANCE: 'BoatLoans',
  SHIPPING: 'ShippingServices',
  INSURANCE: 'InsuranceServices',
  WARRANTY: 'WarrantyServices',
  DOCUMENTATION: 'DocumentationServices',
  RENTAL: 'RentalServices',
  DEALER_GALLERY: 'DealerGallery',
  BOAT_DEALERS: 'BoatDealers',
  ENGINE_SEARCH_RESULTS: ENGINES_ID,
  SBP: SOCIAL_BROWSE_PAGE,
  BOAT_TYPES: 'BoatTypes'
};

export const searchFilterHeader = {
  postalCode: 'Location',
  postalCodeRange: 'Location',
  condition: 'Condition',
  make: 'Make',
  model: 'Model',
  type: 'Type',
  class: 'Class',
  yearLow: 'Year',
  yearHigh: 'Year',
  lengthLow: 'Length',
  lengthHigh: 'Length',
  priceLow: 'Price',
  priceHigh: 'Price',
  state: 'Location',
  city: 'Location',
  region: 'Location',
  engine: 'Engine Type',
  fuel: 'Fuel Type',
  hull: 'Hull Type',
  forSale: 'Seller Type',
  hasPriceDrop: 'Price Drop',
  dealer: 'Owner Id'
};

export const getPageParams = (pageType, brandId, searchResultsCount) => {
  switch (pageType){
  case page.HOME:
    return {
      ...pageParamDefaults,
      section: 'home',
      subSection: 'home',
      pageName: 'home',
      detailedPageName: 'home'
    };
  case page.SEARCH_RESULTS:
    return {
      ...pageParamDefaults,
      section: 'search',
      subSection: 'listing search',
      pageName: 'listing search results',
      detailedPageName: 'listing search results',
      searchResultsCount: searchResultsCount
    };
  case page.OEM_SEARCH_RESULTS:
    return {
      ...pageParamDefaults,
      section: 'search',
      subSection: 'branded search',
      pageName: brandId,
      detailedPageName: 'branded search results'
    };
  case page.SERVICES:
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'resources',
      pageName: 'boat resources',
      detailedPageName: 'boat resources'
    };
  case page.BOAT_DETAILS:
    return {
      ...pageParamDefaults,
      section: 'listings',
      subSection: 'boat listings',
      pageName: 'boat details',
      detailedPageName: 'boat details'
    };
  case 'BoatLoansV2':
  case page.FINANCE:
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'services',
      pageName: '/finance',
      detailedPageName: 'finance'
    };
  case page.SHIPPING:
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'services',
      pageName: '/transport',
      detailedPageName: 'transport'
    };
  case page.INSURANCE:
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'services',
      pageName: '/insurance',
      detailedPageName: 'insurance'
    };
  case page.WARRANTY:
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'services',
      pageName: '/warranty',
      detailedPageName: 'warranty'
    };
  case page.DOCUMENTATION:
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'services',
      pageName: '/documentation',
      detailedPageName: 'documentation'
    };
  case page.RENTAL:
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'services',
      pageName: '/rental',
      detailedPageName: 'rental'
    };
  case 'BoatHistory':
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'resources',
      pageName: 'boat history',
      detailedPageName: 'boat resources'
    };
  case page.DEALER_GALLERY:
    return {
      ...pageParamDefaults,
      subSection: 'broker-dealer',
      pageName: 'broker-dealer gallery',
      detailedPageName: 'broker-dealer gallery',
      section: 'seller'
    };
  case page.BOAT_DEALERS:
    return {
      ...pageParamDefaults,
      subSection: 'broker-dealer search',
      pageName: 'broker-dealer search results',
      detailedPageName: 'broker-dealer search results',
      section: 'search'
    };
  case page.ENGINE_SEARCH_RESULTS:
    return {
      ...pageParamDefaults,
      subSection: 'part-accessory search',
      pageName: 'engine search',
      detailedPageName: 'engine search',
      section: 'search'
    };
  case page.SBP:
    return {
      ...pageParamDefaults,
      section: 'listings',
      subSection: 'boat listings',
      pageName: 'social browse',
      detailedPageName: 'social browse'
    };
  case page.BOAT_TYPES:
    return {
      ...pageParamDefaults,
      section: 'boat types',
      subSection: 'boat types',
      pageName: 'boat types',
      detailedPageName: 'boat types'
    };
  case 'BadCreditBoats':
  case 'BoatAuctions':
  case 'BoatCovers':
  case 'BoatDonation':
  case 'BoatLettering':
  case 'BoatLifts':
  case 'BusinessOpportunities':
  case 'DealerResources':
  case 'Docks':
  case 'ExtendedServiceContracts':
  case 'FactorySpecials':
  case 'InternationalBoatExport':
  case 'MarineElectronics':
  case 'MarineInteriors':
  case 'WaterfrontProperty':
  case 'DealerCertification':
  case 'IndustryIdeas':
  case 'MarineSupplies':
  case 'NewBoatOwnerProgram':
  case 'Repower':
    return {
      ...pageParamDefaults,
      section: 'services',
      subSection: 'resources',
      detailedPageName: 'boat resources'
    };
  default:
    return pageParamDefaults;
  }
};

export const getConnectionClickData = (imtID, leadType) => {

  const ConnectionClickDefaults = {
    connectionType: leadType,
    connectionCategory: '',
    connectionTarget: '',
    connectionSubjectId: imtID
  };

  switch (leadType) {
  case 'facebook share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'facebook'
    };
  case 'twitter share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'twitter'
    };
  case 'whatsapp share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'whatsapp'
    };
  case 'pinterest share':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionType: 'social share',
      connectionTarget: 'pinterest'
    };
  case 'print listing':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'soft lead',
      connectionTarget: 'self'
    };
  case 'email listing':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'social share',
      connectionTarget: 'self'
    };
  case 'phone lead':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'lead',
      connectionTarget: 'seller'
    };
  case 'email lead':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'lead',
      connectionTarget: 'seller'
    };
  case 'save listing':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'soft lead',
      connectionTarget: 'self'
    };
  case 'view all inventory':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'soft lead',
      connectionTarget: 'self'
    };
  case 'contact dealer':
    return {
      ...ConnectionClickDefaults,
      connectionType: 'contact professional',
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  case 'map view':
    return {
      ...ConnectionClickDefaults,
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  case 'website referral':
    return {
      ...ConnectionClickDefaults,
      connectionType: 'website referral',
      connectionCategory: 'soft lead',
      connectionTarget: 'seller'
    };
  default:
    return ConnectionClickDefaults;
  }
};

const getKeysFromObject = (obj) => {
  let keys = [];
  map(obj, (value, key) => { keys.push(unhyphenateUrlComponents(unslugify(replace(key,/\+/g, ' ')))); });
  return keys.length > 0 ? join(keys, ',') : undefined;
};

const getValuesFromObject = (obj ) => {
  let values = [];
  map(obj, (value) => {
    let vals = [];
    map(value, (val) =>  {
      values.push(unhyphenateUrlComponents(unslugify(replace(getLegacyMappingFromBoatClass(val),/\+/g, ' '))));
    });
    return join(vals, ',');
  });
  return values.length > 0 ? join(values, ',') : undefined;
};

export const getSearchLabel = (obj) => {
  obj = omit(obj, ['currency', 'uom']);
  let labels = [];
  map(obj,(param, key) => {
    labels.push(searchFilterHeader[key].toLowerCase());
  });
  labels = uniq(labels);
  return join(labels, ',');
};

const joinArray = (arr, separator) => {
  let values = '';
  if (arr && arr.length > 0) {
    arr = map(arr, (val) => { return unhyphenateUrlComponents(unslugify(replace(val,/\+/g, ' '))); });
    values = arr.join(separator);
  }
  return values.length > 0 ? values : undefined;
};

export const setSearchData = (params, searchType) => {
  let trackData = {
    'postalCode': get(params, 'zip'),
    'postalCodeRange': get(params, 'radius'),
    'condition': get(params, 'condition'),
    'make': getKeysFromObject(get(params, 'makeModel')),
    'model': getValuesFromObject(get(params, 'makeModel')),
    'type': getKeysFromObject(get(params, 'multiFacetedBoatTypeClass')),
    'class': getValuesFromObject(get(params, 'multiFacetedBoatTypeClass')),
    'yearLow': get(params, 'year.min'),
    'yearHigh': get(params, 'year.max'),
    'lengthLow': get(params, 'length.min'),
    'lengthHigh': get(params, 'length.max'),
    'priceLow': get(params, 'price.min'),
    'priceHigh': get(params, 'price.max'),
    'state': get(params, 'state'),
    'city': get(params, 'city'),
    'region': get(params, 'region'),
    'engine': get(params, 'engine') !== '' ? get(params, 'engine') : undefined,
    'fuel': joinArray(get(params, 'fuelType'), ','),
    'hull': joinArray(get(params, 'hullMaterial'), ','),
    'currency': get(params, 'price.min') || get(params, 'price.max')  ? 'usd' : undefined,
    'uom': get(params, 'length.min') || get(params, 'length.max')  ? 'ft' : undefined,
    'forSale': get(params, 'forSale'),
    'hasPriceDrop': get(params, 'priceRevisedSince') ? true : false,
    'dealer': joinArray(get(params, 'OwnerId'), ',')
  };

  if (searchType === 'single') {
    trackData.make = trackData.model ? undefined : trackData.make;
    trackData.type = trackData.class ? undefined : trackData.type;
  }

  return omitBy(trackData, isNil);
};

export const buildProduct = (listing, category) => {

  let product = {
    imtID: listing.id.toString(),
    manufacturer: listing.make,
    length: String(roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft'), '')),
    state: get(listing, 'location.address.state'),
    category: category,
    city: get(listing, 'location.address.city'),
    country: get(listing, 'location.address.country'),
    listedPrice: !listing.price.hidden ? String(get(listing, 'price.type.amount.USD', '')) : 'Request a Price',
    model: listing.model,
    yearBuilt: String(listing.year),
    productType: listing.type,
    sellerID: String(get(listing, 'owner.id')),
    productClass: get(listing, 'class'),
    condition: get(listing, 'condition')
  };

  return omitBy(product, isNil);
};

export const isEventSent = (eventName) => {
  if (isServer()){
    return false;
  }
  const eventsAlreadySent = get(window, 'dataLayer', []);
  return eventsAlreadySent.some(e => e.event === eventName);
};

import React, { PureComponent, Children } from 'react';

class Sticky extends PureComponent {

  state = {}
  handleScroll = this.handleScroll.bind(this);

  handleScroll() {
    let { position, height = 0, offset = 0 } = this.props;
    this.lastScrollY = window.scrollY;
    let { element } = this.refs;

    if (!this.ticking && element) {
      window.requestAnimationFrame(() => {
        let { top } = element.getBoundingClientRect();
        let browserHeight = window.innerHeight;
        let shouldStick;
        if (position === 'bottom'){
          shouldStick = this.lastScrollY + browserHeight < this.lastScrollY + top + height;
        } else {
          shouldStick = this.lastScrollY >= (this.lastScrollY + top) - offset;
        }
        if (shouldStick && !this.state.sticky || !shouldStick && this.state.sticky) {
          this.setState({
            sticky: shouldStick
          });
          if (this.props.onChange) {
            this.props.onChange(shouldStick);
          }
        }
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    let { sticky } = this.state;
    let { children } = this.props;
    return <div ref="element" data-testid="sticky">

      {Children.map(children, child => {
        return React.cloneElement(child, {
          sticky
        });
      })}
    </div>;
  }
}

export default Sticky;

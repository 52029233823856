import React, { useState, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loading from '../LoadingPlaceholder';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../Collapsible';
import { Calculator } from '../Calculator';
import { setGenericEvent } from '../../store/actions/dataLayer';
import { CDN_URL } from '../../constants/BoatLoans';
import './styles.css';
import { canPrequalify } from '../../utils/listingHelper';
import { PrivateSellerCalculator } from '../PrivateSellerCalculator';
import { getApplyNowLink, checkPreQualVariation } from '../../utils/trident';

const PaymentCardTitle = () => {
  return (
    <div className="calc-card-title-container">
      <div>
        <img width="42" height="42" alt="loan-calculator" src={`${CDN_URL}/calculator.svg`} />
      </div>
      <div className="calc-card-title">
        <img width="108" height="14" alt="BoatTrader" src={`${process.env.REACT_APP_CDN_URL}/img/boattrader-logo_blue.svg`} />
        <br/>
        <span className="calc-card-text">Loan Calculator</span>
      </div>
    </div>
  );
};

const PaymentCalculator = (props) => {

  const {
    isLoading,
    listing,
    tridentLoanAmount,
    tridentTeaserRate,
    tridentTermInMonths,
    counter,
    showMonthlyCalculatorOnly,
    useAsCard,
    isSRP,
    initialState = props.isBranded ? 'closed' : 'open',
    showPreQualified = true,
    trackGetPreQualifiedClicked,
    utmCampaign,
    utmContent = 'BDPcalculatorapply',
    isLeadSubmitted,
  } = useMemo(() => props, [props]);

  const [price, setPrice] = useState(tridentLoanAmount);
  const [openComponent, setOpenComponent] = useState(initialState);
  const shouldShowPreQualified = listing ? canPrequalify(listing) : showPreQualified;
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;

  useEffect(() => {
    const hidden = get(listing, 'price.hidden');
    if (!hidden) {
      setPrice(tridentLoanAmount);
    }
    setOpenComponent(openComponent);
  }, [listing, tridentLoanAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToApplyNow = () => {
    if (isSRP){
      const ctaLink = getApplyNowLink({ isVariant: false, utmCampaign, utmContent, listing });
      window.open(ctaLink, '_blank');
    } else {
      checkPreQualVariation({utmCampaign, utmContent, listing});
    }
  };

  const renderPaymentCalculator = () => {
    return (
      <CollapsibleContent
        open={openComponent}
        openCounter={counter}
        initialState={initialState}
        {...(!useAsCard && { classOverride: 'details' })}
      >
        <CollapsibleHeader>
          {useAsCard ? <PaymentCardTitle /> : 'Payment Calculator'}
        </CollapsibleHeader>

        <Collapsible type="no-pad">
          <div className="auto-calculator">
            {(!isSRP) ? (
              <PrivateSellerCalculator
                setGenericEvent={setGenericEvent}
                tridentLoanAmount={price}
                goToApplyNow={goToApplyNow}
                listing={listing}
                tridentTeaserRate={tridentTeaserRate}
                tridentTermInMonths={tridentTermInMonths}
                isLeadSubmitted={isLeadSubmitted}
              />
            ) : (
              <Calculator
                setGenericEvent={setGenericEvent}
                tridentLoanAmount={price}
                tridentTeaserRate={tridentTeaserRate}
                tridentTermInMonths={tridentTermInMonths}
                goToApplyNow={goToApplyNow}
                showPreQualified={shouldShowPreQualified}
                trackGetPreQualifiedClicked={trackGetPreQualifiedClicked}
                showMonthlyCalculatorOnly={showMonthlyCalculatorOnly}
                isBDP={true}
                listing={listing}
                isLeadSubmitted={isLeadSubmitted}
                hideTridentFeatures={hideTridentFeatures}
              />
            )}
          </div>
        </Collapsible>
      </CollapsibleContent>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Loading type="text" />
        <Loading type="text" />
        <Loading type="text" />
      </>
    );
  };

  return (
    <div className="payment-calculator-wrapper">
      <div id="payment-calculator-anchor"></div>
      <div className={classNames('payment-calculator', { 'payment-calculator-card': useAsCard })} >
        {isLoading ?
          renderLoading()
          :
          renderPaymentCalculator()}
      </div>
    </div>
  );
};

PaymentCalculator.propTypes = {
  isLoading: PropTypes.bool,
  listing: PropTypes.any,
  tridentLoanAmount: PropTypes.number,
  tridentTeaserRate: PropTypes.number,
  tridentTermInMonths: PropTypes.number,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showMonthlyCalculatorOnly: PropTypes.bool,
  useAsCard: PropTypes.bool,
  isSRP: PropTypes.bool,
  initialState: PropTypes.string,
  open: PropTypes.string,
};

export default PaymentCalculator;

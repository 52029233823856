import React, {useEffect, useRef} from 'react';
import {Ad} from '@dmm/react-common-components';

// We need explicit height for ads to be sticky
const updateRightAdHeight = (rightAd) => {
  const leftSide = document.querySelector('.broker-bdp-left .content');
  if (leftSide) {
    const rectLeft = leftSide.getBoundingClientRect();
    const rectAd = rightAd.getBoundingClientRect();
    const offset = rectAd.top - rectLeft.top;
    const newHeight = rectLeft.height - offset;
    rightAd.style.height = newHeight + 'px';
  }
};

const BDPDesktopAds = ({addParams, show, noAdsParam}) => {
  if (!show || noAdsParam) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const adsRef = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    updateRightAdHeight(adsRef.current);
  }, [addParams]);

  const {sideBarAd1Params, sideBarAd2Params, sideBarAd3Params} = addParams;
  return (<div className="right-ad" ref={adsRef}>
    <div className="add-tmp"><Ad {...sideBarAd1Params} targeting={{refresh: sideBarAd1Params.reloadAd.toString()}}/></div>
    <div className="sticky-ads">
      <div className="add-tmp"><Ad {...sideBarAd2Params}  /></div>
      <div className="add-tmp"><Ad {...sideBarAd3Params}  /></div>
    </div>
  </div>);
};

export default BDPDesktopAds;

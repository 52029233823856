import { Action } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addLead, trackContactFormOpen, trackPhoneCall } from '../../../store/actions/dataLayer';
import { isFSBOContact } from '../../../utils/listingHelper';
import './styles.css';

class ContactGroupInLine extends PureComponent {
  getContactText = () => {
    let oemName = get(this.props, 'listing.oemDetails.name');
    if (oemName && get(this.props, 'listing.oemDetails.oemDealerLocator', false)) {
      oemName = (oemName.trim().length) ? ` ${oemName.trim()} ` : ' ';
      return 'Find a Dealer Near You';
    }

    return 'Request Information';
  }

  render() {
    const isBranded = !!get(this.props, 'listing.oemDetails');
    const contact = get(this.props, 'listing.contact');
    const phoneNumber = get(contact, 'phone');
    if (isFSBOContact(contact) || isBranded) {
      return (
        <div className="contact-group-in-line">
          <Action className="contact-button" stretch onClick={this.handleContactOpen} label={this.getContactText()} />
        </div>
      );
    }
    return (
      <div className="contact-group-in-line">
        <Action label={this.getContactText()} className="contact-button" onClick={this.handleContactOpen} size="small" stretch/>
        <div>
          <Action as="a" variant="secondary" className="call-button" href={`tel:${phoneNumber}`} onClick={this.handlePhoneCall} label="Call Now" size="small" stretch/>
        </div>
      </div>
    );
  }

  handleContactOpen = () => {
    this.props.onOpenContact();
    const isBranded = !!get(this.props, 'listing.oemDetails');
    if (!isBranded) {
      this.props.trackContactFormOpen();
    }
  }

  handlePhoneCall = () => {
    this.props.trackPhoneCall();
    this.props.addLead(get(this.props, 'listing.id'), 'phone lead');
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    trackContactFormOpen,
    trackPhoneCall,
    addLead
  }, dispatch)
)(ContactGroupInLine);

import { capitalizeEachWord } from '@dmm/lib-common/lib/formatting';
import find from 'lodash/find';
import { US_STATES } from '../constants/boats';
import classes from './data/classes.json';
import homepageClasses from './data/homepageClasses.json';
import types from './data/types.json';

export const getStateByCode = (stateCode) => {
  let state = find(US_STATES, (item) => { return item.value === stateCode;});
  return state;
};

export const getType = (typeCode) => {
  let type = find(types, (item) => { return item.value === typeCode;} );
  return type;
};

export const getClass = (classCode) => {
  let category = find(classes, (item) => { return item.value === classCode;});
  return category;
};

export const getClassByNameforHomePageSearch = (className) => {
  let category = homepageClasses[className];
  return category;
};

export const isMobileResolution = () => {
  return typeof window !== 'undefined' && window.innerWidth < 970;
};

export const isTabletResolution = () => {
  return typeof window !== 'undefined' && window.innerWidth >= 768 && window.innerWidth < 970;
};

export const isLaptopLResolution = () => {
  return typeof window !== 'undefined' && window.innerWidth >= 1440;
};

export const isLaptopResolution = (width = null) => {
  return width ? width >= 1320 : typeof window !== 'undefined' && window.innerWidth >= 1320;
};

export const isTabletOrSmallLaptopResolution = (width = null) => {
  return width ? width >= 768 && width < 1320 : typeof window !== 'undefined' && window.innerWidth >= 768 && window.innerWidth < 1320;
};

export const isPhoneResolution = (width = null) => {
  return width ? width < 768 : typeof window !== 'undefined' && window.innerWidth < 768;
};

export const isHomepageDesktop = () => {
  return typeof window !== 'undefined' && window.innerWidth > 699;
};

export const BREAKPOINTS = { tablet: 'tablet', desktop: 'desktop', mobile: 'mobile' };

export const getBreakpoint = () => {
  if (typeof window !== 'undefined') {
    const isTablet = isTabletResolution();
    const isMobile = isMobileResolution();
    if (isTablet) {
      return 'tablet';
    }
    if (isMobile) {
      return 'mobile';
    }
    return 'desktop';
  }
  return 'mobile';
};

export const getDesktopMobileBreakpoint = () => {
  const breakpoint = getBreakpoint();
  return breakpoint === BREAKPOINTS.tablet ? BREAKPOINTS.mobile : breakpoint;
};

export const getFilterCount = (searchQuery = {}) => {
  const defaultValues = {condition: 'any', radius: '200', type: 'any'};
  const ignoreObjects = ['page', 'pageSize'];
  let objectLength = 0;
  return Object.keys(searchQuery).filter(key => {
    let hasData;
    let query = searchQuery[key];
    switch (typeof query) {
    case 'object':
      if (Array.isArray(query)) {
        objectLength += query.length;
      } else {
        Object.keys(query).forEach(key => {
          if (Array.isArray(query[key])) {
            objectLength += query[key].length;
          } else {
            objectLength += 1;
          }
        });
      }
      hasData = false; break;
    case 'undefined':
      hasData = false; break;
    default:
      hasData = (query !== defaultValues[key]) && !ignoreObjects.includes(key) && query.length;
    }
    return hasData;
  }).length + objectLength;
};

export const clearEmptyProps = ob => {
  const newOb = {...ob};
  for (const key in newOb) {
    if (!newOb[key]) {
      delete newOb[key];
    }
  }
  return newOb;
};

export const clearEmptyEngineProps = ob => {
  const newOb = {...ob};
  for (const key in newOb) {
    if (!newOb[key] && !(key === 'state' || key === 'zip')) {
      delete newOb[key];
    }
  }
  return newOb;
};

export const sortByDefinedOrder = (ar, order = []) => {
  return order.map(key => ar.includes(key) ? key : null).filter(key => !!key);
};

export const asArray = (ob) => !ob ? [] : Array.isArray(ob) ? ob : [ob];

export const parseClassNameFromUrl = (theUrl) => {
  const url = theUrl || '';
  const boatClass = url.split('/')[2] || '';
  const splitted = boatClass.split('-').join(' ');
  const displayName = capitalizeEachWord(splitted);
  const name = displayName.replace(/ boats|boats/i, '');
  return {displayName, boatClass, name};
};

export const windowIsDefined = () => typeof window !== 'undefined' ? true : false;

export const safeWindow = () => typeof window !== 'undefined' ? window : {};

export const safeUserAgent = () => typeof navigator !== 'undefined' ? navigator.userAgent : '';

export const isSSR = () => typeof window === 'undefined';

const truthyCollection = (value) => !!value && (!!Object.keys(value).length || !!value?.size);

export const emptyCollection = (value) => !truthyCollection(value);

export const toBoolean = (value) => typeof value === 'object' ? truthyCollection(value) : !!value;

export const getRenderedHeight = (htmlString, widthSelector) => {
  const renderedWidth = document.querySelector(widthSelector).offsetWidth;
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  tempElement.style.position = 'absolute';
  tempElement.style.left = '-9999px';
  tempElement.style.top = '-9999px';
  tempElement.style.width = `${renderedWidth}px`;
  document.body.appendChild(tempElement);
  const height = tempElement.offsetHeight;
  document.body.removeChild(tempElement);
  return height;
};

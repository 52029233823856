import get from 'lodash/get';
import React, { createContext, useMemo } from 'react';
import { getBoatDetailsBreadCrumb } from '../../../utils/breadCrumbsHelper';
import { formatMediaItems, getMediaImages, getMediaVideos, getTitle, isFSBOContact } from '../../../utils/listingHelper';
import { getBoatUrl } from '../../../utils/urlHelpers/boat';

import { formatNumber } from '@dmm/lib-common/lib/formatting';
import find from 'lodash/find';
import { BANNER_ATTRIBUTES, BANNER_TEXTS } from '../../../constants/boats';
export const BDPContext = createContext();
const BDPContextProvider = BDPContext.Provider;


const calculateMonthlyPrice = (rate, term, loanAmount) => {
  const ratePerMonth = rate / 100 / 12;
  const x = Math.pow(1 + ratePerMonth, term);
  const monthlyPayment = Math.round((loanAmount * ratePerMonth * x) / (x - 1), 2);
  return formatNumber(monthlyPayment);
};

const parseListing = (listing) => {
  const url = getBoatUrl(listing);
  const mediaImages = getMediaImages(listing);
  const mediaVideos = getMediaVideos(listing);
  const breadCrumbItems = getBoatDetailsBreadCrumb(listing);
  const listingTitle = getTitle(listing);
  const virtualTourUrl = get(listing, 'virtualTour.0.url');
  const oemDetails = listing.oemDetails;
  return { url, mediaImages, mediaVideos, breadCrumbItems, listingTitle, virtualTourUrl, oemDetails };
};

const parseBdpProps = (props) => {
  const {listing, ficoScores} = props;
  const parsedListing = parseListing(listing);
  const locationPath = get(props, 'location.pathname');
  const resize = props.resize || {};
  const {mediaImages, mediaVideos, listingTitle} = parsedListing;
  const carouselItems = formatMediaItems(mediaImages, mediaVideos, listingTitle, resize, true);
  const bannerAttribute = find(listing.attributes, (a) => BANNER_ATTRIBUTES.includes(a));
  const bannerText = get(BANNER_TEXTS, bannerAttribute);
  const {tridentTermInMonths, tridentLoanAmount, tridentTeaserRate, isLeadSubmitted} = props;
  const monthlyPrice = calculateMonthlyPrice(tridentTeaserRate, tridentTermInMonths, tridentLoanAmount);
  const tridentValues = {tridentTermInMonths, tridentLoanAmount, tridentTeaserRate, monthlyPrice, ficoScores};
  const isFSBO = isFSBOContact(get(listing, 'contact'), {});
  const cookies = props.cookies;
  const myboats = props.myboats || [];
  const location = props.location;
  const history = props.history;

  const buttonThumbnailText = `View ${mediaImages.length} ${mediaImages.length > 1 ? 'Photos' : 'Photo'}`;
  const buttonThumbnail = {
    buttonText: buttonThumbnailText,
    alt: buttonThumbnailText,
    src: carouselItems?.mediaThumbnailItems[0]?.src,
    show: mediaImages.length > 0,
  };

  const carouselThumbnailsMediaItems = carouselItems?.mediaThumbnailItems?.map((item) => {
    return {
      ...item,
      alt: item.alt,
      src: item.src,
    };
  });
  const carouselThumbnails = [
    buttonThumbnail,
    ...carouselThumbnailsMediaItems
  ];

  return {
    cookies,
    locationPath,
    carouselItems,
    carouselThumbnails,
    bannerAttribute,
    bannerText,
    tridentValues,
    isLeadSubmitted,
    listing,
    myboats,
    isFSBO,
    location,
    history,
    ...parsedListing
  };
};

const BoatDetailsPage = ({children, ...props}) => {
  const bdpContext = useMemo(() => parseBdpProps(props), [props]);

  return (
    <BDPContextProvider value={bdpContext}>
      {children}
    </BDPContextProvider>
  );
};

export default BoatDetailsPage;

export const BRANCH_EVENT = {
  CONTACT_SUBMIT: 'INITIATE_PURCHASE',
  LIKE_BOAT: 'ADD_TO_WISHLIST',
  LOGIN: 'LOGIN',
  REGISTER: 'COMPLETE_REGISTRATION',
  SAVE_SEARCH: 'SUBSCRIBE',
  SEARCH: 'SEARCH',
  SHARE_BOAT: 'SHARE',
  PAGE_VIEW: 'pageview',
};

export const BRANCHIO_META_TAG_NAME = 'branch:deeplink:user_event';

export const BRANCHIO_CONTENT_TYPES = {
  SAVE_BOAT: 'save_boat',
  SAVE_SEARCH: 'save_search',
  CONTACT_SELLER: 'contact_seller',
  CONTACT_SUBMITTED: 'contact_form_submitted'
};


export const branchIoEventsManager = (event, obj = {}) => {
  return window?.branch?.logEvent(event, obj);
};

export default branchIoEventsManager;

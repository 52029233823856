import classnames from 'classnames';
import get from 'lodash/get';
import React, { Children, PureComponent } from 'react';
import { isOuterClick } from '../../utils';
import './details.css';
import './styles.css';

class CollapsibleContent extends PureComponent {
  state = {
    open: this.props.initialState === 'open',
    openCounter: 0
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }



  componentDidUpdate() {
    if (this.props.open === 'open' && this.state.open !== 'open' && this.state.openCounter !== this.props.openCounter){
      this.setState({
        open: this.props.open,
        openCounter: this.props.openCounter
      });
      if (this.props.onOpen){
        this.props.onOpen();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (e) => {
    let { closeOnOuterClick } = this.props;
    if (!closeOnOuterClick) {
      return;
    }
    const outerClicked = isOuterClick(e.target, this.refs.container);
    if (outerClicked) {
      this.setState({
        open: false
      });
    }
  }

  isInnerClick(element) {
    let target = element;
    let { container } = this.refs;
    while (target){
      if (target.classList.contains('collapsible')) {
        return true;
      }
      if (target === container) {
        return false;
      }
      target = target.parentNode;
    }
  }

  handleInnerClick(e) {
    let { closeOnInnerClick } = this.props;
    if (!closeOnInnerClick) {
      return;
    }
    const innerClicked = this.isInnerClick(e.target);
    if (innerClicked) {
      this.setState({
        open: false
      });
    }
  }

  handleToggle(e, child){
    if (child.props.onClick){
      child.props.onClick(e, !this.state.open);
    }
    this.setState({
      open: !this.state.open
    });
    if (!this.state.open && this.props.onOpen){
      this.props.onOpen();
    }
  }

  render(){
    let { children, id, extraClasses, testid, testsp } = this.props;
    let { open } = this.state;
    let mainClassName = 'collapse-content';
    if (this.props.classOverride) {
      mainClassName += `-${this.props.classOverride}`;
    }

    return (
      <div ref="container" className={classnames(mainClassName, extraClasses, { open, closed: !open })} id={id} data-testid={testid} data-test-sp={testsp}  onClick={this.handleInnerClick.bind(this)}>
        {Children.map(children, child => {
          //pass down an onClick function to children that are
          //of type CollapsibleHeader
          if (get(child, 'type.displayName') === 'CollapsibleHeader') {
            return React.cloneElement(child, {
              onClick: (e) => this.handleToggle(e, child)
            });
          }
          return child;

        })}
      </div>
    );
  }
}

class CollapsibleHeader extends PureComponent {

  render(){
    let { children, type, empty, onClick } = this.props;
    return (
      <div className={classnames('header', { [type]: type, empty })} onClick={onClick}>
        { empty ? children :
          <h3>
            <a className="toggle-btn" data-e2e="srp-toggle-btn">{children}<span className="icon"></span></a>
          </h3>
        }
      </div>
    );
  }
}

CollapsibleHeader.displayName = 'CollapsibleHeader';

class Collapsible extends PureComponent {
  render(){
    let { children, type, doubleHeight } = this.props;
    return (
      <div className={classnames('collapsible', {'double-height': doubleHeight}, {[type]: type })}>
        {children}
      </div>
    );
  }
}

export {
  Collapsible, CollapsibleContent,
  CollapsibleHeader
};


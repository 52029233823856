import React, { Component } from 'react';
import { Ad } from '@dmm/react-common-components';
import '../styles.css';

export default class NativeAd extends Component {
  render() {
    let {adNum, adParams, suffix} = this.props;
    const nativeAdParams = adParams || {
      adId: `div-gpt-ad-mobile-native-${adNum}` + (suffix ? `-${suffix}` : ''),
      classes: 'ad ad-mobile-native'
    };

    return (
      <li className="mobile-native-ad">
        <Ad {...nativeAdParams}  />
      </li>
    );
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { calculateMonthlyPrice, isFinanceable, makeFinanceableSummaryTrackingEvent } from '../../../../utils/trident';
import { getLocation, getPrice, getTitle, roundLength } from '../../../../utils/listingHelper';
import { find, get } from 'lodash';
import { DOWN_PAYMENT_PERCENTAGE } from '../../../../constants/BoatLoans';
import { BANNER_ATTRIBUTES, BANNER_TEXTS } from '../../../../constants/boats';
import { SummarySection } from '@dmm/lib-react-ui-components/dist/cjs';
import { scrollTo } from '../../../../utils/scrollTo';
import Tooltip from 'react-tooltip-lite';
import classNames from 'classnames';
import './styles.css';

const CDN_URL = process.env.REACT_APP_CDN_URL;

export const BoatDescription = (props) => {
  const { listing, tridentTeaserRate, tridentTermInMonths, tridentLoanAmount } = props;
  const [tipOpen, setTipOpen] = useState(false);
  const buttonRef = useRef(null);
  const tipContentRef = useRef(null);

  const handleCustomizeLinkClick = (event) => {
    event.preventDefault();
    const customizeTracking = makeFinanceableSummaryTrackingEvent('customize', 'click');
    window.dataLayer.push(customizeTracking);
    scrollTo('#payment-calculator-anchor', { behavior: 'smooth' });
  };

  const toggleTip = () => {
    setTipOpen(!tipOpen);
  };

  const bodyClick = (e) => {
    if (tipContentRef && tipContentRef.current === e.target) {
      return;
    }
    setTipOpen(false);
  };

  const length = roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft')) + '\'';
  const downPayment = tridentLoanAmount * (DOWN_PAYMENT_PERCENTAGE / 100);
  const monthlyPrice =  calculateMonthlyPrice(tridentTeaserRate, tridentTermInMonths, tridentLoanAmount - downPayment, false);

  const listingAttributes = get(listing,  'attributes', []);
  const bannerAttribute = find(listingAttributes, (a) => BANNER_ATTRIBUTES.includes(a));
  const bannerText = get(BANNER_TEXTS, bannerAttribute);
  const price = get(listing, 'price.type.amount.USD');
  const showFinanceSummary = isFinanceable(price, listing.year);

  useEffect(() =>{
    document.addEventListener('mousedown', bodyClick);
    return () =>{
      document.removeEventListener('mousedown', bodyClick);
    };
  }, []);

  return (
    <div className="financeable-boat-summary-boat-description">
      <SummarySection
        status={bannerText}
        title={getTitle(listing, true)}
        price={getPrice(listing)}
        location={getLocation(listing)}
        boatLength={length}
      />

      {showFinanceSummary &&
            <div className="boat-payment-container">
              <div className="boat-monthly-payment-container">
                <div className="payment-month">
                  Own for {monthlyPrice}/mo
                  <div className="payment-tooltip">
                    <Tooltip
                      content={
                        <div
                          role="tooltip"
                          ref={tipContentRef}
                          className={classNames('financeable-boat-summary-monthly-price-tooltip-info', { 'hidden-tooltip': !tipOpen })}>
                          Estimated monthly payment based on a {tridentTermInMonths}-month loan at {tridentTeaserRate}%
                          APR with 20% down payment.
                        </div>
                      }
                      useHover={false}
                      isOpen={tipOpen}
                      eventToggle="onClick"
                      className="monthly-payment-tooltip-wrapper"
                      direction="up"
                      distance={10}
                      background="#FFFFFF"
                    >
                      <img
                        role="icon"
                        ref={buttonRef}
                        className="info-icon"
                        width="14" height="14"
                        alt="info icon"
                        src={`${CDN_URL}/boat-loans/finance-variant/Info.svg`}
                        onClick={toggleTip}
                      ></img>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div
                className="customize-link"
                onClick={handleCustomizeLinkClick}>Customize</div>
            </div>
      }
    </div>
  );
};

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import './styles.css';

const AccordionItemComponent = ({ title, content, baseClassName }) => {
  return (
    <AccordionItem key={title}>
      <AccordionItemHeading>
        <AccordionItemButton>
          <h3 className={baseClassName ? `${baseClassName}__subtitle` : 'extendedServicesFaqs__subtitle'}>
            {title}
          </h3>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {content}
      </AccordionItemPanel>
    </AccordionItem>
  );
};

const MemoizedAccordionItem = memo(AccordionItemComponent);

export const FAQSection = ({ pageTitle, accordionItems, disclaimerText, baseClassName }) => {
  return (
    <div id="extendedServicesFaqs__accordion">
      <h2 className={baseClassName ? `${baseClassName}__title` : 'extendedServicesFaqs__title'}>
        {pageTitle}
      </h2>
      <Accordion allowMultipleExpanded allowZeroExpanded>
        {accordionItems.map(({ title, content }) => (
          <MemoizedAccordionItem
            key={title}
            title={title}
            content={content}
            baseClassName={baseClassName}
          />
        ))}
      </Accordion>
      <div className={baseClassName ? `${baseClassName}__disclaimer` : 'extendedServicesFaqs__disclaimer'}>
        <p>{disclaimerText}</p>
      </div>
    </div>
  );
};

FAQSection.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  accordionItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
  disclaimerText: PropTypes.string.isRequired,
  baseClassName: PropTypes.string,
};

FAQSection.defaultProps = {
  baseClassName: null,
};

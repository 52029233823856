import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Ad, AdProvider } from '@dmm/react-common-components';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import DefaultLayout from '../../../../layouts/Default';
import ServiceChildTitle from '../../../../components/ServiceChildTitle';
import BreadCrumb from '../../../../components/BreadCrumb';
import RichSnippet from '../../../../components/RichSnippet';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import { adsConfig } from './resources/adsConfig';
import { setExternalLinkImpression } from '../../../../store/actions/dataLayer';
import {
  SERVICES_URL,
  INSURANCE_URL,
  INSURANCE_SERVICE
} from '../../../../constants/services';

import faq from './faq.json';
import '../styles.css';

class Insurance extends PureComponent {
  componentDidMount() {
    this.props.setExternalLinkImpression(INSURANCE_SERVICE, 'dfp', 'box 1');
    this.props.setExternalLinkImpression(INSURANCE_SERVICE, 'dfp', 'box 2');
    this.props.setExternalLinkImpression(INSURANCE_SERVICE, 'dfp', 'box 3');
  }

  renderAccordionTitle (text) {
    return (
      <AccordionItemHeading>
        <AccordionItemButton>
          <h2 className="accordion__title">
            {text}
          </h2>
        </AccordionItemButton>
      </AccordionItemHeading>
    );
  }
  render() {
    return (
      <>
        <RichSnippet schema={getFAQSchema(faq)} />
        <AdProvider targeting={{ page: 'insurance' }} adsConfig={adsConfig}>
          <DefaultLayout {...this.props} pageType="InsuranceServices">
            <div className="service-page">
              <ServiceChildTitle title="Boat Insurance" />
              <div className="service-content">
                <BreadCrumb items={[{
                  title: 'Home',
                  link: '/'
                }, {
                  title: 'Services',
                  link: SERVICES_URL
                }, {
                  title: 'Boat Insurance',
                  link: INSURANCE_URL
                }]} />
                <div className="main-section dfp">
                  <div className="service-info">
                    <h1>Boat Insurance</h1>
                    <p>
                  Enjoy peace of mind as you navigate your boat or personal watercraft. Whether
                  you&apos;re looking for a new boat insurance policy or just want to save money on an
                  existing policy, Boat Trader can help you get started with a free boat insurance quote.
                    </p>
                  </div>
                </div>
                <div className="ads">
                  <Ad {...{ adId: 'div-gpt-custom-box-1', classes: 'box-ad' }} />
                  <Ad {...{ adId: 'div-gpt-custom-box-2', classes: 'box-ad' }} />
                  <Ad {...{ adId: 'div-gpt-custom-box-3', classes: 'box-ad' }} />
                </div>
                <Accordion
                  allowMultipleExpanded
                  allowZeroExpanded
                  preExpanded={['insurance-cost-id']}
                >
                  <AccordionItem uuid="insurance-cost-id">
                    {this.renderAccordionTitle('Cost of Boat Insurance Varies By Location')}
                    <AccordionItemPanel>
                      <p>
                      Where you live plays a role in the cost of boat insurance. In fact, boat
                      insurance quotes often begin with your ZIP code. For example, Florida offers
                      year-round boating, so Florida residents pay some of the highest rates in the
                      nation for marine insurance. There’s also the annual threat of hurricanes that
                      can increase costs. Yikes!
                      </p>
                      <p>
                      Additionally, where you go boating makes a difference. If the boat goes into the
                      ocean, you can expect to pay more than if you do your boating strictly in freshwater.
                      Corrosion caused by salt is one of the key factors for the surcharge.
                      </p>
                      <p>
                      If you live in the Northeast, you also can expect to pay a premium for insurance,
                      while boaters in the Great Lakes Region have among the lowest rates in the country
                      because the boating season is short.
                      </p>
                      <p>
                      Also remember that marine protection can be limited to a specific region. For example,
                      if you live in Illinois and decide to pull your boat to Lake of the Ozarks in
                      Missouri and you have an accident, the claim could be denied because it wasn’t within
                      the policy region. Before you travel, call your insurance company to see if you need a binder.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Getting A Boat Insurance Quote')}
                    <AccordionItemPanel>
                      <p>
                      A lot of companies that offer boat insurance have online portals that will get you a
                      quote – often within just a few minutes. The National Boat Owners Association (NBOA)
                      is one great resource, but of course other major companies such as Progressive, Geico,
                      Allstate, Nationwide, State Farm, Travelers and Farmers all offer ways to get boat
                      insurance quotes without having to talk to a person.
                      </p>
                      <p>
                      Collect and compare as many quotes online as you like, but also be sure to call an
                      agent you have a relationship with. They might be able to save you some money.
                      </p>
                      <p>
                      Once you find a rate you are comfortable with, most companies will begin coverage
                      right away or as soon as you pay the first premium. To reiterate, the time to get a
                      boat insurance quote, is before you buy the boat.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Required Boat Insurance Documents')}
                    <AccordionItemPanel>
                      <p>
                      To get a boat insurance quote, you’ll likely need to provide the following information:
                      </p>
                      <ul>
                        <li>
                        Mailing address, including ZIP code
                        </li>
                        <li>
                        Birthdate
                        </li>
                        <li>
                        Year, make and model
                        </li>
                        <li>
                        Market value
                        </li>
                      </ul>
                      <p>
                      Depending on where you get your quote, you might need a bit more information, but these
                      are the key questions on online boat insurance quote portals.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('Boat Insurance Rate Factors')}
                    <AccordionItemPanel>
                      <p>
                      If you have no prior experience in boating, insurance companies are going to rely heavily
                      on your automobile driving record. Insurance companies will also examine credit records to
                      screen applicants and use credit scores to set rates. According to insurance companies’
                      research, people with credit problems are more likely to be involved in an accident.
                      </p>
                      <p>
                      That said, if you can shore up your DMV and credit histories before shopping for boat
                      insurance, it’s probably worth the time it will take you to do so.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('How To Get Cheaper Boat Insurance')}
                    <AccordionItemPanel>
                      <p>
                      Insurance experts say that if you can save 10 to 15 percent or more on an annual premium,
                      it may be worth opting for a higher deductible. The standard deductible is 2 percent for
                      most boats, and insurance experts suggest raising it to 3 or 4 percent. Realize, however,
                      that a higher deductible could cost thousands of dollars more if an accident occurs.
                      </p>
                      <p>
                      A higher deductible is one way to save on premiums, but there are a number of other
                      things you can do to reduce the cost of boat insurance.
                      </p>
                      <p>
                      Let’s take a look at some of them:
                      </p>
                      <h3 className="question">
                      Take a Boating Safety Course
                      </h3>
                      <p>
                      Successfully completing a boating safety course from one of the main providers such
                      as U.S. Power Squadrons, Boat U.S. or state-approved outfits can save you as much as 10
                      percent on your premiums.
                      </p>
                      <h3 className="question">
                      Limit Your Range and Season
                      </h3>
                      <p>
                      Paying to insure your boat all over the country when all you do is take it local lakes
                      is a waste of money. Likewise, so is paying to insure your boat year-round when it spends
                      six months on the trailer in your garage. Ask your agent about limiting range and season.
                      </p>
                      <h3 className="question">
                      Omit Full Coverage
                      </h3>
                      <p>
                      This option isn’t available to you if you’re financing your boat, but if you own it
                      outright, you might consider a liability-only policy. It won’t pay for damage to your
                      boat due to theft or damage, but it will protect you financially in the event of an
                      accident. However, don’t scrimp to bare bones coverage. Be sure your policy covers
                      salvage costs. If your boat sinks, say, at the ramp, and you don’t have salvage coverage,
                      you will have to pay someone to retrieve the boat, which might not be worth much afterward.
                      </p>
                      <h3 className="question">
                      Insure for the Right Hull Value
                      </h3>
                      <p>
                      As a boat ages, it loses value, but lots of owners never adjust what insurers call the
                      agreed hull value, the amount an insurer pays in a total loss event. If your boat is getting
                      up there in age, say five to 10 years, it might be time to adjust the value. You might
                      also discuss with your agent going with an actual cash value policy—and if often gives a
                      substantial savings, which can help reduce your premiums.
                      </p>
                      <h3 className="question">
                      Avoid Filing Lower-Cost Claims
                      </h3>
                      <p>
                      Yes, the whole point of paying for insurance is to be covered in the event of an accident,
                      but experts say paying for damage that is a little more than the cost of the deductible
                      can prevent insurers from raising your premiums. Paying for that $1,500 gelcoat and rubrail
                      repair now might save you more than that over time in the form of increased premiums that
                      might result if you had filed a claim.
                      </p>
                      <h3 className="question">
                      Join A Boating Club
                      </h3>
                      <p>
                      Check with your insurer first, but joining a boating safety organizations, such as the U.S.
                      Power Squadrons or the U.S. Coast Guard Auxiliary might be good for a discount on your
                      insurance premiums. You’ll have to weigh whether the savings are greater than the
                      membership costs.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    {this.renderAccordionTitle('FAQs')}
                    <AccordionItemPanel>
                      {
                        faq.map((faqItem, index) => (
                          <>
                            <h3 key={`question-${index}`} className="question">{faqItem.question}</h3>
                            <p key={`answer-${index}`}>{faqItem.answer}</p>
                          </>
                        ))
                      }
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </DefaultLayout>
        </AdProvider>
      </>
    );
  }
}

export default connect(null, (dispatch) => bindActionCreators({
  setExternalLinkImpression
}, dispatch))(Insurance);

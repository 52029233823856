import React from 'react';
import Modal from '../Modal';
import closeBtn from './close-btn.svg';
import './styles.css';

const ImmersiveTourModal = props => {
  const onEscPress = event => {
    if (event.key === 'Escape' && props.isOpen) {
      props.closeModal();
    }
  };
  return <Modal
    isOpen={props.isOpen}
    onRequestClose={props.closeModal}
    portalClassName="virtual-tour-modal"
    onKeyPress={onEscPress}>
    <div data-testid="virtual-tour-modal" className="modal-container">
      <header>
        <h2>{props.title}</h2>
        <button className="close-btn" onClick={() => props.closeModal()}><img src={closeBtn} /><span className="visuallyhidden">Close Modal</span></button>
      </header>
      <iframe id="virtual-tour-content" src={props.url} frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} />
    </div>
  </Modal>;
};

export default ImmersiveTourModal;

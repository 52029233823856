import React from 'react';
import './styles.css';
import LoadingPlaceholder from '../../../components/LoadingPlaceholder';
import ContactGroup from '../ContactGroup';

const StickyContact = ((props) => {
  const {isLoading, fadeButtons} = props;

  const renderLoading = () => {
    return <LoadingPlaceholder type="text" />;
  };

  const renderContact = () => {
    return <ContactGroup {...props} isSticky={true} />;
  };

  return (
    <div className={`sticky-contact ${fadeButtons ? 'fade' : ''} `}>
      {isLoading ? renderLoading() : renderContact()}
    </div>
  );
});

export default StickyContact;

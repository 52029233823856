import React from 'react';
import '../styles.css';
import AddressMap from '../AddressMap';
import {usePhone} from '../../../utils/dealerHelpers/custom';

const ModalSkeleton = ({children, onClose, title}) => {
  const hideOnClickOutside = (event) => {
    if (event.target === document.querySelector('.modal-skeleton')) {
      onClose();
    }
  };
  return (
    <>
      <div className="modal-skeleton" onClick={hideOnClickOutside}>
        <div className={'skeleton-content'} data-e2e={'skeleton-content'}>
          <button className="contact-close" data-e2e="contact-close" onClick={onClose}><span className="visuallyhidden">Close</span></button>
          <div className={'skeleton-container'} data-e2e={'skeleton-container'}>
            {title && <div className={'title'} data-e2e={'title'}>{title}</div>}
            {children}
          </div>
        </div>
      </div>
    </>);
};

const ModalDealerAddress = ({name, street, city, postalCode}) => {
  return (
    <div className="modal-dealer-address" data-e2e="modal-dealer-address">
      {name && <div>{name}</div>}
      {street && <div>{street}</div>}
      {city && <div>{`${city} ${postalCode || ''}`}</div>}
    </div>
  );
};

const onCallClick = (id, addLead, setGenericEvent, call ) => {
  addLead(id, 'phone lead');
  setGenericEvent('phone click', 'dealer address', 'dealer search page');
  call();
};

const ModalDealerButtons = ({id, phone, onContact, addLead, setGenericEvent}) => {
  const [callText, call] = usePhone(phone);
  return (
    <div className="modal-dealer-buttons" data-e2e="modal-dealer-buttons">
      <a type="button" className="modal-dealer-contact-btn" data-e2e="dealer-contact" onClick={() => onContact()}>Contact</a>
      {
        phone &&
        <a type="button" href={`tel:${phone}`} className="modal-dealer-call-btn" data-e2e="dealer-call" onClick={() => onCallClick(id, addLead, setGenericEvent, call)}>{callText}</a>
      }
    </div>
  );
};

const AddressModal = ({addressProps, onClickContact, onClose, title = 'Address', addLead, setGenericEvent}) => {
  const {name, id, street, city, postalCode, phone} = addressProps;
  const address = {name, street, city, postalCode};
  const onContactButton = () => {
    onClose();
    onClickContact(addressProps);
  };

  return (
    <ModalSkeleton show={!!addressProps} title={title} onClose={onClose}>
      <ModalDealerAddress {...address} />
      <ModalDealerButtons id={id} onContact={onContactButton} phone={phone} addLead={addLead} setGenericEvent={setGenericEvent}/>
      <AddressMap coordinates={addressProps.coordinates} name={name} />
    </ModalSkeleton>
  );
};

export {ModalSkeleton, AddressModal};

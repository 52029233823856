import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { Router } from 'react-router-dom';
import { Frontload } from 'react-frontload';
import { CookiesProvider } from 'react-cookie';
import { Provider as ReduxProvider } from 'react-redux';
import 'intersection-observer';
import { ABTestProvider} from './server/abTest';
import createStore from './store/config';
import App from './App';

export default createStore()
  .then(({ store, history, abTestContext }) => {
    // uncomment to hardcode abTestContext on development
    /*abTestContext = {
      'abTestConfiguration':
      [
        {'id': 2137,'key': 'dynamic_pricing'}
        ,{'id': 2437,'key': 'bt-bdp-update-phase1'}
        ,{'id': 3520, 'key': 'bt-bdp-update-phase2'}
        ,{'id': 3520, 'key': 'bt-bdp-update-phase3'}
        ,{'id': 3520, 'key': 'bt-bdp-update-phase4'}
        , {'id': 3520, 'key': 'bt-bdp-lead-form-phone-required'}
        ,{'id': 3520, 'key': 'bt-bdp-update-phase2', variation: 'bt-bdp-update-phase2_5'}
        ,{'id': 5674, 'key': 'bt-srp-ab-exact-match-featured'}
        ,{'id': 2062, 'key': 'bt-server-ads'}
      ]
    };*/

    const Application = (
      <ABTestProvider value={abTestContext}>
        <CookiesProvider>
          <ReduxProvider store={store}>
            <Router history={history}>
              <Frontload noServerRender={true}>
                <App />
              </Frontload>
            </Router>
          </ReduxProvider>
        </CookiesProvider>
      </ABTestProvider>
    );

    const root  = document.querySelector('#root');
    /* istanbul ignore if */
    if (root.hasChildNodes()){
      Loadable.preloadReady().then(() => {
        ReactDOM.hydrate(Application, root);
      });
    } else {
      ReactDOM.render(Application, root);
    }
  });

import { generatePath } from 'react-router-dom';
import { capitalizeEachWord, normalizeString } from '@dmm/lib-common/lib/formatting';
import concat from 'lodash/concat';
import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import pick from 'lodash/pick';
import reverse from 'lodash/reverse';
import {
  getActiveParams, generateSearchPath, hyphenateUrlComponents, unhyphenateUrlComponents, unslugify
} from './urlHelpers/listings';
import { getStateByCode, getType, getClass } from './commonHelper';
import { handleLegacyAllType, getClassCategory } from './classHelper';
import { SEARCH_URL_PATTERN, BRANDS_ID } from '../constants/boats';
import { ENGINES_ID } from '../constants/Engines';
import { engineSeoName } from './engineTypeHelper';
import { getTitle } from './listingHelper';

export const getSearchResultBreadCrumb = (params, makeModel, partyDetails, seoMakeInfo, searchPage, isBranded, oemDetails) => {
  makeModel = shouldReplaceMakeWithSeoMake(seoMakeInfo, makeModel);
  params = searchPage === ENGINES_ID ?
    pick(params, 'zip', 'city', 'state', 'makeModel', 'fuelType', 'engine', 'condition', 'ownerId')
    :
    pick(params, 'zip', 'city', 'state', 'makeModel', 'multiFacetedBoatTypeClass', 'fuelType', 'hullMaterial', 'ownerId', 'group');
  let active = getActiveParams(params, searchPage);
  let seoElements = [];
  if (isBranded) {
    seoElements.push(setBreadCrumb('brandedSRP', params, undefined, BRANDS_ID, oemDetails.name));
  }

  if (get(active, 'group')) {
    seoElements.push(setBreadCrumb('group', params, undefined, searchPage));
    params.group = undefined;
  }

  if (get(active, 'fuelType')) {
    if (searchPage === ENGINES_ID) {
      seoElements.push(setBreadCrumb('fuelType', params, undefined, searchPage));
      params.fuelType = ['all'];
    } else {
      // only electric for now
      const isElectric = active.fuelType.find(fuel => fuel.match(/electric/i));
      if (isElectric) {
        seoElements.push(setBreadCrumb('Electric boats', params));
      }
      params.fuelType = {};
    }
  }

  if (get(active, 'hullMaterial')) {
    seoElements.push(setBreadCrumb('hullMaterial', params, undefined, searchPage));
    params.hullMaterial = [];
  }

  if (get(active, 'zip')) {
    seoElements.push(setBreadCrumb('zip', params, undefined, searchPage));
    params.zip = '';
  }
  if (get(active, 'city')) {
    seoElements.push(setBreadCrumb('city', params, undefined, searchPage));
    params.city = [];
  }
  if (get(active, 'state')) {
    seoElements.push(setBreadCrumb('state', params, undefined, searchPage));
    params.state = '';
  }
  if (get(active, 'makeModel')) {
    map(setMultifacetedBreadCrumb('makeModel', params, makeModel, searchPage), (element) => {
      seoElements.push(element);
    });
    params.makeModel = {};
  }
  if (get(active, 'multiFacetedBoatTypeClass')) {
    map(setMultifacetedBreadCrumb('multiFacetedBoatTypeClass', params, makeModel), function(element) {
      seoElements.push(element);
    });
    params.multiFacetedBoatTypeClass = {};
  }

  if (searchPage === ENGINES_ID) {
    if (get(active, 'engine')) {
      seoElements.push(setBreadCrumb('engine', params, undefined, searchPage));
      params.engine = '';
    }

    if (get(active, 'condition')) {
      seoElements.push(setBreadCrumb('condition', params, undefined, searchPage));
      params.condition = 'any';
    }
  }

  if (get(active, 'ownerId')) {
    seoElements.push(setBreadCrumb('dealer', params, partyDetails));
    seoElements.push(setBreadCrumb('dealers'));
  } else {
    if (isBranded) {
      seoElements.push({ title: 'Brands', link: ''});
    } else if (searchPage === ENGINES_ID) {
      seoElements.push(setBreadCrumb('Engines', params, undefined, searchPage));
    } else {
      seoElements.push(setBreadCrumb('Boats for Sale', params));
    }
  }

  let breadCrumbs = concat([{ title: 'Home', link: '/' }], reverse(seoElements));

  return { breadCrumbs };
};

export const getBoatDetailsBreadCrumb = listing => {
  const breadcrumbs = [
    {
      title: 'Home',
      link: '/'
    },
    {
      title: 'Boats For Sale',
      link: generatePath(SEARCH_URL_PATTERN, {})
    }
  ];

  const searchUrlParams = {};
  const makeUrlParam = {};

  const classTypeBreadCrumb = value => ({
    title: capitalizeEachWord(unhyphenateUrlComponents(unslugify(value, '-', ' '))),
    link: generatePath(SEARCH_URL_PATTERN, searchUrlParams)
  });

  const makeBreadCrumb = value => ({
    title: capitalizeEachWord(unhyphenateUrlComponents(unslugify(value, '-', ' '))),
    link: generatePath(SEARCH_URL_PATTERN, makeUrlParam)
  });

  const titleBreadCrumb = value => ({
    title: capitalizeEachWord(unhyphenateUrlComponents(unslugify(value, '-', ' ')))
  });

  if (listing.type && listing.class) {
    const normalizedType = getClassCategory(listing.class);
    searchUrlParams.types = `type-${normalizedType}`;
    breadcrumbs.push(classTypeBreadCrumb(normalizedType));

    searchUrlParams.classes = `class-${listing.class.toLowerCase()}`;
    const normalizedClass = get(getClass(listing.class), 'name');
    breadcrumbs.push(classTypeBreadCrumb(normalizedClass));
  }
  if (listing.make) {
    const displayedMake = listing.validMake ? listing.validMake : listing.make;
    makeUrlParam.make = `make-${normalizeString(hyphenateUrlComponents(displayedMake))}`;
    breadcrumbs.push(makeBreadCrumb(displayedMake));
  }
  if (listing.year && listing.make && listing.model) {
    breadcrumbs.push(titleBreadCrumb(getTitle(listing)));
  }
  return breadcrumbs;
};

const setBreadCrumb = (key, params, partyDetails, searchPage, brandName) => {
  let param = get(params, key);
  let link = generateSearchPath(undefined, params, true, searchPage);
  switch (key) {
  case 'zip':
    return { title: param, link: link };
  case 'state':
  {
    let state = getStateByCode(param);
    return { title: get(state, 'name'), link: link };
  }
  case 'city':
    return { title: capitalizeEachWord(unslugify(param[0], '-', ' ')), link: link };
  case 'dealers':
    return { title: 'dealers', link: '/boat-dealers/'};
  case 'dealer':
    return { title: partyDetails.name, link: link};
  case 'engine':
    return { title: engineSeoName(param), link: link};
  case 'fuelType':
    return { title: capitalizeEachWord(unslugify(param[0], '-', ' ')), link: link};
  case 'hullMaterial':
    return { title: capitalizeEachWord(unslugify(param[0], '-', ' ')), link: link};
  case 'condition':
    return { title: capitalizeEachWord(unslugify(param, '-', ' ')), link: link};
  case 'group':
    return { title: capitalizeEachWord(unslugify(params.group, '-', ' ')), link };
  case 'brandedSRP':
    return { title: brandName, link: link};
  default:
    return { title: key, link: link };
  }
};

const setMultifacetedBreadCrumb = (key, params, makeModel, searchPage) => {
  let breadCrumbs = [];
  let items = get(params, key);
  let item = keys(items)[0];
  if (items[item]) {
    if (items[item][0]) {
      let subItem = items[item][0];
      if (subItem !== handleLegacyAllType(item, 'all')) {
        params[key] = { [item]: [subItem] };
        let subItemTitle = key === 'multiFacetedBoatTypeClass' ?
          get(getClass(subItem), 'name') :
          capitalizeEachWord(unhyphenateUrlComponents(unslugify(subItem)));
        breadCrumbs.push({ title: subItemTitle, link: generateSearchPath(undefined, params, true, searchPage) });
      }
    }
    params[key] = key === 'multiFacetedBoatTypeClass' ?
      params[key] = { [item]: [handleLegacyAllType(item, 'all')] } :
      params[key] = { [item]: [] };
    let itemTitle = key === 'multiFacetedBoatTypeClass' ?
      get(getType(item), 'name') :
      makeModel ? makeModel :
        capitalizeEachWord(unhyphenateUrlComponents(unslugify(item)));
    let itemType = key !== 'multiFacetedBoatTypeClass' && makeModel ? 'make' : 'other';
    breadCrumbs.push({ title: itemTitle, link: generateSearchPath(undefined, params, true, searchPage), type: itemType });
  }
  return breadCrumbs;
};

const shouldReplaceMakeWithSeoMake = (seoMakeInfo, makeModel) => {
  if (seoMakeInfo && seoMakeInfo.seoMakeName) {
    return seoMakeInfo.seoMakeName;
  }
  return makeModel;

};

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { BoatDescription } from './BoatDescription';
import { GetPrequalifiedCTA } from './GetPrequalifiedCTA';
import { ContactLeadForm } from './ContactLeadForm';
import { ModalContactLead } from './ModalContactLead';
import './styles.css';

export const FinanceableBoatSummaryDesktop = (props) => {

  const {
    isUserLoginExperiment,
    listing,
    tridentTeaserRate,
    tridentTermInMonths,
    tridentLoanAmount,
    isMobile,
    breakpoint,
    userEmail,
    setDisableRequestInfo,
    disableRequestInfo,

    showLeadOverlay,
    showDetailsContact,
    showDetailsSuccess,
    showFinanceCTAs,

    handleDetailsSuccess,
    handleLoginContactButtonClick,
    handleCloseContactForm
  } = props;

  const userEmailAvailable = userEmail !== undefined;

  return (
    <>
      <BoatDescription
        listing={listing}
        tridentTeaserRate={tridentTeaserRate}
        tridentTermInMonths={tridentTermInMonths}
        tridentLoanAmount={tridentLoanAmount}
      />

      <div id="prequalified-and-contact-seller" className="info-container">
        <ContactLeadForm
          listing={listing}
          isMobile={isMobile}
          breakpoint={breakpoint}
          userEmailAvailable={userEmailAvailable}
          isUserLoginExperiment={isUserLoginExperiment}
          disableRequestInfo={disableRequestInfo}
          setDisableRequestInfo={setDisableRequestInfo}
          showDetailsSuccess={showDetailsSuccess}
          handleDetailsSuccess={handleDetailsSuccess}
          handleLoginContactButtonClick={handleLoginContactButtonClick}
        />
        {showFinanceCTAs &&
          <GetPrequalifiedCTA listing={listing}/>
        }
      </div>

      <ModalContactLead
        listing={listing}
        isMobile={isMobile}
        isUserLoginExperiment={isUserLoginExperiment}
        userEmail={userEmail}
        breakpoint={breakpoint}
        showLeadOverlay={showLeadOverlay}
        showDetailsContact={showDetailsContact}
        setDisableRequestInfo={setDisableRequestInfo}
        handleDetailsSuccess={handleDetailsSuccess}
        handleCloseContactForm={handleCloseContactForm} />

    </>);};

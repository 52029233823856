import React, { PureComponent } from 'react';
import Slider from '../../Slider';
import { DEFAULT_MAX_YEAR, DEFAULT_MIN_YEAR, DEBOUNCE_TIMEOUT } from '../../../constants/boats';
import debounce from 'lodash/debounce';

class FilterYear extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      min: props.min || '',
      max: props.max || ''
    };
    this.handleSliderDrag = this.handleSliderDrag.bind(this);
    this.handleSliderDragEnd = this.handleSliderDragEnd.bind(this);
  }

  updateMultiInput() {
    if (!this.state.dragging) {
      this.setState({ max: this.props.max || '' });
    }
    if (!this.state.dragging) {
      this.setState({ min: this.props.min || '' });
    }
  }

  componentDidMount() {
    this.updateMultiInput();
  }

  componentDidUpdate() {
    this.updateMultiInput();
  }

  handleDataChange(type, value) {
    this.setState({[type]: value.toString()});

    if (type === 'min') {
      if (this.props.tracking) {
        if (!value) {
          this.debounceTrackingRemove('minimum year removed');
        } else {
          this.debounceTracking({ ['year']: {'min': value }});
        }
      }
    } else {
      if (this.props.tracking) {
        if (!value) {
          this.debounceTrackingRemove('maximum year removed');
        } else {
          this.debounceTracking({ ['year']: {'max': value }});
        }
      }
    }

    this.props.handleDataChange('year', {
      min: this.state.min,
      max: this.state.max,
      [type]: value
    });
  }

  debounceTracking = debounce((value) => this.props.tracking.facetAdded(value), DEBOUNCE_TIMEOUT);
  debounceTrackingRemove = debounce((value) => this.props.tracking.facetRemoved(value), DEBOUNCE_TIMEOUT);

  handleSliderDrag(handle,val) {
    if (this.state.editing) {
      return;
    }
    if (handle === 'min') {
      this.setState({min: val.toString(), dragging: true});
    } else {
      this.setState({max: val.toString(), dragging: true});
    }

  }

  handleSliderDragEnd(handle,val) {
    if (!this.state.dragging) {
      return;
    }
    this.setState({dragging: false});
    if (handle === 'min') {
      if (this.props.tracking) {
        this.debounceTracking({ ['year']: {'min': val }});
      }
      this.props.handleDataChange('year', {min: val.toString(), max: this.state.max});
    } else {
      if (this.props.tracking) {
        this.debounceTracking({ ['year']: {'max': val }});
      }
      this.props.handleDataChange('year', {min: this.state.min, max: val.toString()});
    }
  }

  onFocusYear = () => {
    this.setState({editing: true});
  }

  onBlurYear = () => {
    this.setState({editing: false});
  }

  onChangeYearFrom = (value) => {
    this.handleDataChange('min', value.target.value);
  }

  onChangeYearTo = (value) => {
    this.handleDataChange('max', value.target.value);
  }

  render() {
    let { min, max } = this.state;
    let sliderMax = DEFAULT_MAX_YEAR + 1;

    return (
      <div className="search-filter year">
        <div className="slider-container">
          <Slider
            min={DEFAULT_MIN_YEAR}
            max={sliderMax}
            initialMin={this.props.initialMin || DEFAULT_MIN_YEAR.toString()}
            initialMax={this.props.initialMax || sliderMax.toString()}
            value={[
              parseInt(this.state.min) || DEFAULT_MIN_YEAR,
              parseInt(this.state.max) || sliderMax
            ]}
            marks={{0: ' '}}
            onChange={this.handleSliderDrag}
            onAfterChange={this.handleSliderDragEnd}
            handle={this.props.handle || ''}
          />
        </div>
        <div className="search-filter-group">
          <input
            type="number"
            data-e2e="minimum-year"
            className="year-from"
            placeholder="No Min"
            min={0}
            value={min}
            onFocus={this.onFocusYear}
            onBlur={this.onBlurYear}
            onChange={this.onChangeYearFrom}
            title="Minimum year"
          />
          <span>to</span>
          <input
            type="number"
            data-e2e="maximum-year"
            className="year-to"
            placeholder="No Max"
            min={0}
            value={max}
            onFocus={this.onFocusYear}
            onBlur={this.onBlurYear}
            onChange={this.onChangeYearTo}
            title="Maximum year"
          />
        </div>
      </div>
    );
  }
}

export default FilterYear;

import React, { PureComponent } from 'react';
//import { Ad, AdProvider } from '@dmm/react-common-components';
import BreadCrumb from '../../../components/BreadCrumb';
import './styles.css';
//import { adsConfig } from './resources/adsConfig';
import { serviceGroupsConfig } from './resources/servicesConfig';
import ServiceGroup from '../ServiceGroup';
export default class MainContent extends PureComponent {
  render() {
    return (<>
      {/*<AdProvider targeting={{ page: 'resources' }} adsConfig={adsConfig}>*/}
      <BreadCrumb items={[{ title: 'Home', link: '/' }, { title: 'Services', link: '/services/' }]} />
      <div className="services-head">
        <h1>BOAT SERVICES</h1>
        <h2>Discover tips for buying, selling and owning a boat</h2>
      </div>
      <div className="services-content">
        <div className="left-content">
          {serviceGroupsConfig.map((servicesGroup, i) =>
            <ServiceGroup
              key={i}
              image={servicesGroup.image}
              title={servicesGroup.title}
              services={servicesGroup.services}
            />
          )}
        </div>
        {/*<div className="right-content">
          <div className="right-ad">
            <Ad {...{ adId: 'div-gpt-box-1', classes: 'right-rail-ad ad-300' }} />
            <Ad {...{ adId: 'div-gpt-box-2', classes: 'right-rail-ad ad-300' }} />
            <Ad {...{ adId: 'div-gpt-box-3', classes: 'right-rail-ad ad-300' }} />
          </div>
          </div>*/}
      </div>
      {/*</AdProvider>*/}
    </>);
  }
}

import { formatPrice } from '@dmm/lib-common/lib/formatting';
import { SelectTypeAhead } from '@dmm/lib-react-typeahead';
import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BOAT_LEAD_PURCHASE_TYPE } from '../../constants/BoatLoans';
import { US_STATES } from '../../constants/boats';
import { setSubmittedPreQualifyForm } from '../../store/actions/tridentApi';
import { clearEmptyProps } from '../../utils/commonHelper';
import { sendFinanceLead } from '../../utils/trident';
import ValidatedInput from '../ValidatedInput';
import './styles.css';

const MIN_LOAN_AMOUNT = 10000;
class GetPreQualifiedForm extends Component {
  states = US_STATES.map((state) => {
    return state.name;
  });

  ficoScores = (this.props.ficoScores || []);
  purchaseTimeframes = ['Right Away', 'Within 3 months', 'Within 3 - 6 months', 'Greater than 6 months'];

  setInitialState() {

    this.ficoScores.push('Below 600');
    this.ficoScores = [...new Set(this.ficoScores)];

    return {
      formValid: {
        ...(this.props.leadType === BOAT_LEAD_PURCHASE_TYPE ?
          { loanAmount: this.props.preFillLoanAmount ? true : false,
            ficoScore: false,
            purchaseTimeframe: false,
          }
          : {}),
        state: false,
        name: false,
        email: false,
        phone: false,
      },
      formFields: {
        ...(this.props.leadType === BOAT_LEAD_PURCHASE_TYPE ?
          { loanAmount: this.props.preFillLoanAmount ? this.props.loanAmount : '',
            ficoScore: 'Below 600',
            purchaseTimeframe: 'none',
          }
          : {}),
        state: 'none',
        name: '',
        email: '',
        phone: '',
        marketingOptIn: false
      },
      formPristine: {
        ...(this.props.leadType === BOAT_LEAD_PURCHASE_TYPE ?
          { loanAmount: this.props.preFillLoanAmount ? false : true,
            ficoScore: true,
            purchaseTimeframe: true}
          : {}),
        state: true,
        name: true,
        email: true,
        phone: true,
      },
      validationErrors: false,
      showSuccess: false,
      showError: false,
    };
  }
  state = this.setInitialState();

  updateField = (field, valid, value) => {
    if (valid) {
      this.setState({
        formValid: {
          ...this.state.formValid,
          [field]: valid,
        },
        formFields: {
          ...this.state.formFields,
          [field]: value,
        },
        formPristine: {
          ...this.state.formPristine,
          [field]: false,
        },
      });
    } else {
      this.setState({
        formValid: {
          ...this.state.formValid,
          [field]: valid,
        },
        formPristine: {
          ...this.state.formPristine,
          purchaseTimeframe: false,
          [field]: false,
        },
        validationErrors: true,
      });
    }
  };

  formIsInvalid() {
    const invalid = Object.values(this.state.formValid).filter((value) => {
      return !value;
    });

    const isInvalid = invalid.length > 0;

    return isInvalid;
  }

  removeLastOccurrence = (str, char) => {
    const lastIndexOfL = str.lastIndexOf(char);
    return str.slice(0, lastIndexOfL) + str.slice(lastIndexOfL + 1);
  };

  validateLoanAmount = (loanAmount) => {
    if (!loanAmount){
      return false;
    }

    const purchasePriceNumber = Number(loanAmount.replace(/[^0-9.-]+/g, ''));
    if (purchasePriceNumber < MIN_LOAN_AMOUNT){
      return false;
    }
    return true;
  }

  setLoanAmount = (event) => {
    const { name, value } = event.target;
    let processedValue = value;
    const numOfPeriods = (value.match(/\./g) || []).length;
    let decimalPart = '';

    if (numOfPeriods > 1) {
      processedValue = this.removeLastOccurrence(processedValue, '.');
    }
    decimalPart =
      processedValue.indexOf('.') > -1
        ? processedValue.substring(processedValue.indexOf('.'))
        : '';
    processedValue =
      formatPrice(
        processedValue.replace(/[^0-9.]/g, '').replace(/\..*/, ''),
        'USD',
        'en-US'
      ) + decimalPart.replace(/[^0-9.]/g, '');

    this.updateField(name, true, processedValue);
    let isValid = this.validateLoanAmount(processedValue);
    this.updateField(name, isValid, processedValue);
  }

  findStateAbbreviation(state) {
    const stateIndex = US_STATES.findIndex((item) => item.name === state);
    if (stateIndex >= 0){
      return US_STATES[stateIndex].value;
    }
    return '';
  }

  selectChanger = (value) => {
    let state = this.findStateAbbreviation(value);
    this.updateField('state', this.isStateValid(value), state);
  };

  isStateValid(value) {
    const stateIndex = this.states.findIndex((item) => item === value);
    return stateIndex >= 0;
  }

  ficoScoreChanger = (value) => {
    const valueIsFromOptions = this.ficoScores.includes(value);
    const valueIsBelow600 = value === 'Below 600';

    if (!valueIsFromOptions) {
      this.setState({ ficoScoresErrorType: 'isNotFromOptions' });
    } else if (valueIsBelow600) {
      this.setState({ ficoScoresErrorType: 'isBelow600' });
    }

    const isValid = valueIsFromOptions && !valueIsBelow600;
    this.updateField('ficoScore', isValid, value);
  };

  purchaseTimeframeChanger = (value) => {
    const valueIsFromOptions = this.purchaseTimeframes.includes(value);
    const valueIsNone = value === 'none';

    if (valueIsNone) {
      this.setState({ purchaseTimeframeErrorType: 'isNone' });
    } else if (!valueIsFromOptions) {
      this.setState({ purchaseTimeframeErrorType: 'isNotFromOptions' });
    }

    const isValid = valueIsFromOptions && !valueIsNone;
    this.updateField('purchaseTimeframe', isValid, value);
  };

  setSubmitError(isError) {
    this.setState({
      ...this.state,
      showError: isError,
    });
  }

  isValidEngineType(engineType) {
    return engineType && (engineType.toLowerCase() === 'gas'
      || engineType.toLowerCase() === 'diesel'
      || engineType.toLowerCase() === 'gasoline'
    );
  }

  handleToggleMarketingOptIn() {
    this.setState({
      ...this.state,
      formFields: {
        ...this.state.formFields,
        marketingOptIn: !this.state.formFields.marketingOptIn,
      }
    });
  }

  doSubmit = async (event) => {
    event.preventDefault();
    this.setSubmitError(false);
    const {
      loanAmount,
      ficoScore,
      purchaseTimeframe,
      name: fullName,
      state,
      email,
      phone,
      marketingOptIn
    } = this.state.formFields;

    let [ firstName, lastName ] = fullName.split(' ');

    // When the lastName is empty, then use the firstName as the lastName. The
    // lastName field is required in Salesforce.
    if (!lastName?.trim()) {
      lastName = firstName;
      firstName = null;
    }

    let data = {
      ...(this.props.leadType === BOAT_LEAD_PURCHASE_TYPE ?
        { loanAmount: Number(loanAmount.replace(/[^0-9.-]+/g, '')),
          ficoScore,
          purchaseTimeframe
        }
        : {}),
      state,
      firstName,
      lastName,
      email,
      phone,
      marketingOptIn,
      leadSource: 101458,
      leadPage: this.props.leadPage,
      leadType: this.props.leadType,
      utmContent: this.props.utmContent
    };

    if (this.props.listing) {
      const {
        year,
        model,
        location,
        make,
        type: boatType,
        owner,
        price,
        id: listingId,
        portalLink: listingURL,
        specifications,
        propulsion,
        fuelType: engineType,
        hull
      } = this.props.listing;

      const monthlyPayment = parseInt(this.props.monthlyPrice.replace(/,/g, ''));
      const advertisedRate = this.props.interestRate;
      const purchasePrice =  !price.hidden && price?.type?.amount && price?.type?.amount.USD > 1 ?
        price?.type?.amount.USD :
        undefined;
      const { name: sellerName, boattraderId } = owner;
      const { city: listingCity, state: listingState } = location.address;
      const length = specifications.dimensions.lengths.nominal.ft;
      const engineCount = propulsion?.engines?.length || 0;
      const manufacturer = encodeURIComponent(make);
      const boatName = `${manufacturer} ${model}`;
      const seller = encodeURIComponent(sellerName);
      const sellerPartyId = parseInt(boattraderId);
      const { hin } = hull;

      data = {
        ...data,
        advertisedRate,
        year,
        model,
        manufacturer,
        boatType,
        boatName,
        length,
        seller,
        sellerPartyId,
        monthlyPayment,
        listingCity,
        listingState,
        listingId,
        listingURL,
        engineCount,
        ...(this.isValidEngineType(engineType) && { engineType: engineType.replace('gasoline', 'gas') }),
        purchasePrice,
        hin
      };
    }

    const leadData = clearEmptyProps(data);
    try {
      await sendFinanceLead(leadData);
      if (this.props.onSuccess) {
        this.props.setSubmittedPreQualifyForm();
        this.props.onSuccess();

        if (this.props.trackingEvent){
          const trackingEvent  = {
            ...this.props.trackingEvent,
            requestId: ''
          };

          window.dataLayer.push(trackingEvent);
        }
        if (this.props.kameleoonGoal){
          window.kameleoonQueue.push(['Goals.processConversion', this.props.kameleoonGoal]);
        }
      }
    } catch {
      this.setSubmitError(true);
    }

  };

  render() {

    const { buttonText = 'Send Request' } = this.props;

    return (
      <>
        <div className="prequalified-form" data-e2e="prequalified-form">
          {this.props.leadType === BOAT_LEAD_PURCHASE_TYPE &&
            <div className="prequalified-instructions" data-e2e="prequalified-instructions">
              Get matched with the best boat loan option for your unique needs.
            </div>
          }
          <form
            noValidate
            onSubmit={(event) => this.doSubmit(event)}
            className={classnames('form', {
              error: this.state.validationErrors,
            })}
            data-e2e="form"
          >

            {this.props.leadType === BOAT_LEAD_PURCHASE_TYPE &&
            <>
              <fieldset
                className={classnames('fieldset fieldset-loanAmount', {
                  error: !this.state.formValid.loanAmount,
                  selected: this.state.formFields.loanAmount !== 'none'
                })}
                data-e2e="fieldset-loanAmount"
                htmlFor="loanAmount">
                <input
                  type="text"
                  id="loanAmount"
                  data-e2e="loanAmount"
                  name="loanAmount"
                  placeholder="Loan Amount *"
                  onChange={(event) => this.setLoanAmount(event)}
                  value={this.state.formFields.loanAmount}
                  aria-label="Loan Amount"
                />
              </fieldset>

              {(!this.state.formValid.loanAmount && !this.state.formPristine.loanAmount) &&
              <div className="message-error" data-e2e="loan-program-message-error">
                We do not currently have a loan program under which to pre-qualify your request.
              </div>
              }

              <fieldset
                className={classnames('fieldset fieldset-ficoScore', {
                  error: !this.state.formValid.ficoScore,
                  selected: this.state.formFields.ficoScore !== 'Below 600'
                })}
                data-e2e="fieldset-ficoScore"
                htmlFor="ficoScore"
              >
                <SelectTypeAhead
                  list={this.ficoScores}
                  label="ficoScore"
                  onChange={(event) => this.ficoScoreChanger(event)}
                  inputId="ficoScore"
                  placeholderText="FICO Score *"
                />
              </fieldset>

              {(!this.state.formValid.ficoScore && !this.state.formPristine.ficoScore) &&
              <div className="message-error pt-5" data-e2e="message-error pt-5">
                {this.state.ficoScoresErrorType === 'isNotFromOptions' && 'Please select a FICO range from the available options.'}
                {this.state.ficoScoresErrorType === 'isBelow600' && 'We do not currently have a loan program under which to pre-qualify your request.'}
              </div>
              }
            </>
            }

            <ValidatedInput
              type="text"
              name="name"
              id="name"
              displayLabel={false}
              labelText="Name"
              placeholder="Name *"
              onChange={(field, valid, value) =>
                this.updateField(field, valid, value)
              }
              validation="name"
            />
            { (!this.state.formValid.name && !this.state.formPristine.name) &&
              <div className="message-error" data-e2e="name-message-error">
                Please enter your first name and last name.
              </div>
            }

            <>
              <fieldset
                className={classnames('fieldset fieldset-state', {
                  error: !this.state.formValid.state,
                  selected: this.state.formFields.state !== 'none'
                })}
                data-e2e= "fieldset-state"
                htmlFor="state"
              >
                <SelectTypeAhead
                  list={this.states}
                  onChange={(event) => this.selectChanger(event)}
                  inputId="state"
                  placeholderText="State *"
                  label="state"
                />
              </fieldset>

              {(!this.state.formValid.state && !this.state.formPristine.state) &&
              <div className="message-error pt-5" data-e2e="state-message-error">
                Please enter your State.
              </div>
              }
            </>

            <ValidatedInput
              type="text"
              name="email"
              id="email"
              displayLabel={false}
              labelText="Email"
              placeholder="Email *"
              onChange={(field, valid, value) =>
                this.updateField(field, valid, value)
              }
              validation="email"
            />

            {(!this.state.formValid.email && !this.state.formPristine.email) &&
            <div className="message-error" data-e2e="email-message-error">
              Please enter a valid email.
            </div>
            }

            <ValidatedInput
              type="text"
              name="phone"
              id="phone"
              displayLabel={false}
              labelText="Phone"
              placeholder="Phone *"
              onChange={(field, valid, value) =>
                this.updateField(field, valid, value)
              }
              validation="phone-not-empty"
            />
            {(!this.state.formValid.phone && !this.state.formPristine.phone) &&
            <div className="message-error" data-e2e="phone-message-error">
              Please enter your phone number.
            </div>
            }

            {this.props.leadType === BOAT_LEAD_PURCHASE_TYPE &&
              <>
                <fieldset
                  className={classnames('fieldset fieldset-purchaseTimeframe', {
                    error: !this.state.formValid.purchaseTimeframe,
                    selected: this.state.formFields.purchaseTimeframe !== 'none'
                  })}
                  data-e2e="fieldset-purchaseTimeframe"
                  htmlFor="purchaseTimeframe"
                >
                  <SelectTypeAhead
                    list={this.purchaseTimeframes}
                    label="purchaseTimeframe"
                    onChange={(event) => this.purchaseTimeframeChanger(event)}
                    inputId="purchaseTimeframe"
                    placeholderText="Purchase Timeframe *"
                  />
                </fieldset>
                {(!this.state.formValid.purchaseTimeframe && !this.state.formPristine.purchaseTimeframe) &&
                <div className="message-error pt-5" data-e2e="message-error pt-5">
                  {this.state.purchaseTimeframeErrorType === 'isNotFromOptions' && 'Please select a purchase timeframe from the available options.'}
                  {this.state.purchaseTimeframeErrorType === 'isNone' && 'We need you to select a timeframe in which you plan on purchasing.'}
                </div>
                }
              </>
            }

            <div
              className={classnames('message-error submit-error', {
                hidden: !this.state.showError,
              })}
              data-e2e="submit-error-message"
            >
              There was an error trying to send your message. Please try again
              later.
            </div>

            {!this.props.isLeadSubmitted &&
              <Action
                disabled={this.formIsInvalid()}
                className="send-request-button"
                data-e2e="send-request-button"
                data-testid="send-request-prequalified-button"
                type="submit"
                label={buttonText}
                stretch
              />
            }

            <div className="pre-qualified-get-info" data-e2e="pre-qualified-get-info">
              <input
                type="checkbox"
                id="marketingOptInCheckbox"
                data-e2e="marketingOptInCheckbox"
                selected={this.state.formFields.marketingOptIn}
                onChange={() => this.handleToggleMarketingOptIn()}
                aria-describedby="marketingOptInDescription"
                name="Marketing OptIn"
              />
              <label htmlFor="marketingOptInCheckbox" id="marketingOptInDescription" data-e2e="marketingOptInDescription">Yes, I’d like to receive helpful boat services and finance information from Boat Trader.</label>
            </div>

          </form>
        </div>
      </>
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    setSubmittedPreQualifyForm
  }, dispatch)
)(GetPreQualifiedForm);

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { isFSBOContact } from '../../utils/listingHelper';
import { addLead, trackPhoneCall, trackPhoneNumberReveal } from '../../store/actions/dataLayer';
import './styles.css';

class SellerInformation extends PureComponent {

  handlePhoneCall = () => {
    this.props.trackPhoneCall();
    this.props.addLead(this.props.listingId, 'phone lead');
  }

  getAddress = () => {
    const shouldOpenLocation = get(this.props, 'shouldOpenLocation', false);
    const address = get(this.props, 'contact.address', {});
    const addressString = [address.street, address.city, address.state, address.zip]
      .filter(addressItem => !!addressItem).join(', ');
    const addressComponent = <address>{addressString}</address>;

    return <div className="seller-info-location">
      {shouldOpenLocation ?
        <a href={`https://maps.google.com/?q=${addressString}`} target="_blank" rel="nofollow noopener">{addressComponent}</a> :
        addressComponent
      }
    </div>;
  }

  getPhone = () => {
    const contact = get(this.props, 'contact');
    const name = get(this.props, 'name');
    let phoneElement = '';
    let phoneDesktopElement = '';

    if (!isFSBOContact(contact)) {
      const phoneNumber = get(this.props, 'contact.phone');
      phoneElement = <a className="seller-tel-anchor" href={'tel:' + phoneNumber} title={`Call ${name ? name : get(contact, 'name', '')} now`}
        onClick={(e) => this.handlePhoneCall(false, e)}>{phoneNumber}</a>;
      phoneDesktopElement = <p className="seller-tel-paragraph">{phoneNumber}</p>;
    }

    return <div className="seller-info-tel">{phoneElement}{phoneDesktopElement}</div>;
  };

  render() {
    const { contact, name, reverse, zipCode, proximity } = this.props;

    return (
      <div className="seller-info-details" id={this.props.id}>
        <p className="seller-info-name">{name ? name : contact.name}</p>
        {proximity && zipCode && <p className="seller-proximity">{proximity}&nbsp;from&nbsp;{zipCode}</p>}
        {reverse ? this.getAddress() : this.getPhone()}
        {reverse ? this.getPhone() : this.getAddress()}
      </div>
    );
  }

}

export default connect(
  null,
  dispatch => bindActionCreators({
    addLead,
    trackPhoneCall,
    trackPhoneNumberReveal
  }, dispatch)
)(SellerInformation);

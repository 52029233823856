import { PureComponent } from 'react';

export default class Status extends PureComponent {

  render() {
    const { staticContext, message = '' } = this.props;
    if (staticContext) {
      if (message.includes('500')) {
        staticContext.statusCode = 500;
      } else if (message.includes('404')) {
        staticContext.statusCode = 404;
      }
    }
    return null;
  }

}

import { SEARCH_MAX_BOATS } from '../constants/boats';
import { formatNumber, normalizeString} from '@dmm/lib-common/lib/formatting';
import range from 'lodash/range';
import { ENGINES_ID } from '../constants/Engines';
import { BRANDS_ID } from '../constants/boats';

export const setPosition = (pos, i) => {
  return { position: i, page: pos.page};
};

export const getResultsCount = (params, totalItems, cityPostalCode, searchPage, radius = 25, oemDetails) => {
  let { city, zip} = params;
  let end;
  switch (searchPage) {
  case ENGINES_ID:
    end = 'motors and engines';
    break;
  case BRANDS_ID:
    end = `${oemDetails?.name} Models`;
    break;
  default:
    end = 'boats';
    break;
  }

  let viewingMessage = '0 listings found';
  if (totalItems) {
    viewingMessage = `${formatNumber(totalItems)} ${end}`;
    if (Array.isArray(city) && city.length === 1) {
      if (zip) {
        viewingMessage = `${viewingMessage} within ${radius} miles from ${zip}`;
      } else {
        const cityName = Array.isArray(cityPostalCode) &&
          cityPostalCode.find(cityObj => normalizeString(cityObj.value) === city[0]);
        if (cityName) {
          viewingMessage = `${viewingMessage} within ${radius} miles from ${cityName.value}`;
        }
      }
    }
  }
  return viewingMessage;
};

export const getPaginationData = (params, totalItems, visiblePages) => {
  let { page, pageSize } = params;
  page = +(page);
  pageSize = +(pageSize);
  totalItems = Math.min(SEARCH_MAX_BOATS, totalItems);
  const maxPages =  Math.ceil(SEARCH_MAX_BOATS / pageSize) - 1;
  let pages = Math.ceil(totalItems / pageSize) < maxPages ? Math.ceil(totalItems / pageSize) : maxPages;
  let toPage;
  let fromPage;
  let delta;

  if (visiblePages >= pages) {
    fromPage = 1;
    toPage = pages;
  } else {
    delta = Math.floor(visiblePages / 2);
    if (page - delta < 1) {
      fromPage = 1;
      toPage = visiblePages;
    } else {
      if (page + delta >= pages) {
        toPage = pages;
        fromPage = pages - visiblePages + 1;
      } else {
        toPage = page + delta;
        fromPage = page - delta;
      }
    }
  }

  let response = {
    page: page,
    pageSize: pageSize,
    isFirstPage: page === 1,
    isLastPage: page === pages || totalItems <= 28,
    visibleNumbers: range(fromPage, toPage + 1)
  };

  return response;
};

import classnames from 'classnames';
import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setGenericEvent } from '../../../store/actions/dataLayer';

import { Action } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import { ENGINES_ID } from '../../../constants/Engines';
import { BRANCHIO_CONTENT_TYPES } from '../../../utils/branchIoEventsManager';
import useBranchIoMetaTag from '../../../utils/hooks/useBranchIoMetaTag';
import * as searchParamsHelper from '../../../utils/urlHelpers/listings';
import './light.css';
import './styles.css';

const SearchAlertModal = lazy(() => import('../../SearchAlerts/SearchAlertsModal'));
const ConfirmationModal = lazy(() => import('../../SearchAlerts/ConfirmationModal'));

const SearchAlertsContainer = (props) => {
  const {type, children, isBranded} = props;
  return (
    <div className="search-alerts-container">
      <div className={classnames('search-alerts', { [`search-alerts-${type}`]: type, 'search-alerts-branded': isBranded } ) }>
        {children}
      </div>
    </div>
  );
};
const SearchAlerts = (props) => {
  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();
  return (
    <>
      <BranchIoMetaTagComponent/>
      <SearchAlertsContainer>
        <SearchAlertsButton eventCategory="above search filter" branchIoClick={fireBranchioMetaTag} {...props} />
      </SearchAlertsContainer>
    </>
  );
};

class SearchAlertsButtonComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSearchAlertModal: false,
      showConfirmationModal: false,
      eventCategory: this.props.eventCategory,
      hasSearchCriteria: false
    };

    this.scrollEvent = this.checkScroll.bind(this);
    this.showSearch = this.showSearchAlertsModal.bind(this);
    this.hideSearch = this.hideSearchAlertsModal.bind(this);
    this.submitSearch = this.submitSearchAlert.bind(this);
    this.hideConfirmation = this.hideConfirmationModal.bind(this);
  }

  componentDidMount() {
    const hasSearchCriteria = this.hasSearchCriteria(this.props);

    if (!window.hasSearchAlertScrollEventListener) {
      this.addScrollEventListener();
      this.checkScroll();
    }
    this.setState({ hasSearchCriteria: hasSearchCriteria });
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      const hasSearchCriteria = this.hasSearchCriteria(this.props);
      this.setState({ hasSearchCriteria: hasSearchCriteria });
    }
  }

  componentWillUnmount() {
    this.removeScrollEventListener();
  }

  showConfirmationModal() {
    this.setState({showConfirmationModal: true});
  }

  hideConfirmationModal() {
    this.setState({showConfirmationModal: false});
  }

  showSearchAlertsModal() {
    const { searchPage, branchIoClick } = this.props;
    branchIoClick(BRANCHIO_CONTENT_TYPES.SAVE_SEARCH);
    if (!document.querySelector('.search-alerts-modal') && ![ENGINES_ID].includes(searchPage)) {
      this.props.setGenericEvent('search alerts', 'create search', this.state.eventCategory);
      this.setState({showSearchAlertModal: true});
    }
  }

  hideSearchAlertsModal() {
    this.setState({showSearchAlertModal: false});
  }

  submitSearchAlert() {
    this.props.setGenericEvent('search alerts', 'save search', 'modal');
    this.hideSearchAlertsModal();
    this.showConfirmationModal();
  }

  addScrollEventListener() {
    window.hasSearchAlertScrollEventListener = true;
    window.addEventListener('scroll', this.scrollEvent);
  }

  removeScrollEventListener() {
    window.hasSearchAlertScrollEventListener = false;
    window.removeEventListener('scroll', this.scrollEvent);
  }

  hasSearchCriteria(props) {
    const makes = get(props, 'makes', []);
    const makeModels = get(props, 'makeModels', {});
    const active = get(props, 'active', {});

    const paramsAsArray = searchParamsHelper
      .getFormattedParamsArray(active, makes, makeModels)
      .filter((param) => param.key !== 'page' && param.key !== 'sort');

    const hasSearchCriteria = (paramsAsArray?.length > 0 || false);
    return hasSearchCriteria;
  }

  checkScroll() {
    if (window.scrollY + window.innerHeight >= document.body.scrollHeight * 0.55) {
      this.removeScrollEventListener();

      if (this.state.hasSearchCriteria) {
        this.showSearchAlertsModal();
      }
    }
  }

  renderLoader = () => <div className="preloader"></div>;

  render() {
    const { searchPage } = this.props;
    const { showSearchAlertModal, showConfirmationModal, hasSearchCriteria } = this.state;
    const isBranded = !!this.props?.match?.params?.oem;
    return (
      <>
        {![ENGINES_ID].includes(searchPage) && (
          <>
            <Action className="search-alerts-button" data-e2e="srp-save-search" onClick={this.showSearch} label="Save Search" stretch/>
            {
              !isBranded &&
              <button className="search-alerts-button-mobile" data-e2e="srp-save-search-mobile" onClick={this.showSearch}>Save Search</button>
            }
          </>
        )}

        { showSearchAlertModal &&
        <Suspense fallback={this.renderLoader()}>
          <SearchAlertModal
            show={true}
            close={this.hideSearch}
            submit={this.submitSearch}
            hasSearchCriteria={hasSearchCriteria}
            {...this.props}
          />
        </Suspense>
        }

        { showConfirmationModal &&
        <Suspense fallback={this.renderLoader()}>
          <ConfirmationModal
            show={true}
            close={this.hideConfirmation}
          />
        </Suspense>
        }
      </>
    );
  }
}

const SearchAlertsButtonContainer = (props) => {
  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();
  return (
    <>
      <BranchIoMetaTagComponent />
      <SearchAlertsButtonComponent {...props} branchIoClick={fireBranchioMetaTag} />
    </>
  );
};

const SearchAlertsButton = connect(null, (dispatch) => bindActionCreators({
  setGenericEvent
}, dispatch))(SearchAlertsButtonContainer);

const SearchAlertsFilterButton = (props) => {
  const {onClick} = props;
  return (
    <button className="filter-button" onClick={onClick}>Filters </button>
  );
};

export {
  SearchAlerts,
  SearchAlertsButton, SearchAlertsContainer, SearchAlertsFilterButton
};


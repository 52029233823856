import React, { memo } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import './styles.css';
import { BOAT_TYPES_ACCORDION_CONTENT } from './accordionContent';

const AccordionItemComponent = ({uuid, title, content }) => {
  return (
    <AccordionItem key={title} uuid={uuid}>
      <AccordionItemHeading>
        <AccordionItemButton>
          <h3>
            {title}
          </h3>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        {content}
      </AccordionItemPanel>
    </AccordionItem>
  );
};

const MemoizedAccordionItem = memo(AccordionItemComponent);

export const BoatLoansTypes = () => {
  const accordionItems = BOAT_TYPES_ACCORDION_CONTENT;

  return (
    <>
      <div className="boat-loan-types-main">
        <h2 className="title">Types of Boat Loans</h2>
        <div className="container">
          <div className="accordion-container">
            <Accordion allowZeroExpanded preExpanded={[accordionItems[0].uuid]}>
              {accordionItems.map(({ title, content, uuid }) => (
                <MemoizedAccordionItem
                  uuid={uuid}
                  key={title}
                  title={title}
                  content={content}
                />
              ))};
            </Accordion>
          </div>

          <div className="image-container">
            <img src={`${process.env.REACT_APP_CDN_URL}/boat-loans/boat-loan-types.webp`} alt="Boat Loans Types"/>
          </div>
        </div>
      </div>
      <div className="boat-loan-types-footer-container">
        <img src={`${process.env.REACT_APP_CDN_URL}/boat-loans/boat-types-section-footer.svg`} alt="Boat Loans Types Footer"/>
      </div>
    </>
  );
};

import React from 'react';

const DealerCertificationText = () =>
  <>
    <p className="resourcesTopPad">
      <strong>
      The Marine Industry Certification Program is designed to ensure that buying and maintaining a boat is easy.
      When you visit a Marine Industry Certified Dealer, you can feel confident you are choosing a business that is committed to your satisfaction.<br />
        <br />You can expect to find:
      </strong>
    </p>
    <strong>
      <ul>
        <li>Friendly, knowledgeable employees</li>
        <li>Quality products</li>
        <li>Reliable service</li>
      </ul>
      <p className="dealerListTitle">
        <strong>Dealers who would like to become certified must demonstrate their commitment to excellence by meeting high standards in key areas:</strong><br/>
      </p>
      <ul>
        <li>Operations</li>
        <li>Facilities</li>
        <li>Professional sales/service processes</li>
        <li>Customer satisfaction and employee training/satisfaction</li>
      </ul>
    </strong>
  </>;

const IndustryIdeasText = () =>
  <>
    <p className="resourcesTopPad">
      <strong>From finding ways to reach a new group of customers to building a new employee training facility,
      dealers across the country are finding creative ways to improve their businesses.</strong>
    </p>
  In this white paper, brought to you by Boat Trader, the staff of Boating Industry magazine has gathered 46
  of the best ideas that dealers implemented in their companies. With advice in business management,
  customer satisfaction, marketing, sales and service, these are ideas you can put to work today to grow your dealership.<br/>
    <br/>
    <a target="_blank" href={`${process.env.REACT_APP_CDN_URL}/services/boating-industry-best-ideas.pdf`} rel="nofollow noopener">Download the white paper here</a>
  </>;

const MarineSuppliesText = () =>
  <>
    <p>
      <strong>
        <address>The Boating Lifestyle-</address>
        <address>Boating is a lifestyle and no matter what kind of boat you choose the memories that will be created with your family and friends will last a lifetime.&nbsp;&nbsp; </address>
        <address>Equipping your boat with quality parts and accessories can be as important to your enjoyment on the water as the boat you choose.
        Visit our sponsors below to get ready for the season!
        From fishing to cruising, Boat Trader will help you find marine supplies to fit your lifestyle.</address>
      </strong>
    </p>
  </>;

const NewBoatOwnerProgramText = () =>
  <>
    <p className="resourcesTopPad">
      <strong>
        <address className="mainText"><span><strong style={{'font-size': '14px'}}>Welcome to a world created exclusively for boaters!</strong></span><br /><br /><font face="arial,helvetica,sans-serif" style={{'font-size': '13px'}}>This is where you get the <u>Savings</u>, <u>Services</u> and <u>Security</u> offerings that ensure you&rsquo;ll get the most out of your boating experiences.</font></address><address className="mainText"><font face="arial,helvetica,sans-serif" style={{'font-size': '13px'}}>Regardless of whether you&rsquo;re a power boater, sailor, water sports enthusiast, coastal cruiser, lake fisherman, saltwater angler or professional mariner, you value your time on the water and we&rsquo;re here to help you get the most out of that time.</font></address><address className="mainText"></address>
      </strong>
    </p>
  </>;

const RepowerText = () =>
  <>
    <p className="resourcesTopPad"></p>
    <h2><font style={{'font-size': '16px'}}>Giving New Life to Your Old Boat</font></h2>
    <p style={{'margin': '12px 0px'}}><font><strong><em style={{'font-size': '13px'}}>By Capt. Parke Brinkley</em></strong></font></p>
    <p style={{'margin': '12px 0px'}}><font style={{'font-size': '13px'}}><strong style={{'font-size': '13px'}}>Oftentimes, half of the value of your boat is in the engines. Boat hulls generally last longer than their engines and after 7-9 years it&rsquo;s often a practical choice to repower your boat with a new trouble-free and fuel-efficient power plant. Here are a few things to consider when making the repower choice:</strong></font></p>
    <h3><font style={{'font-size': '16px'}}>OUTBOARD REPOWERING:</font>&nbsp; <font style={{'font-size': '13px'}}>Today&rsquo;s four stroke&nbsp;and direct injection outboards are much cleaner and more fuel efficient than their predecessors. 50% less fuel consumption can mean $875/year in fuel savings for a typical 500 gallon boating season. Repowering an older boat with 4-strokes can result in significant weight increases, so make sure to simulate the weight of the new engine on your transom and check for water entering the scuppers from the outside. One way to do this is to have one or two people sit on the transom with the old motor, and check the water level outside to make sure you&rsquo;re not creating a hazardous situation by replacing with a heavier motor. The DI motors like the Evinrude E-TEC&nbsp;</font><font style={{'font-size': '13px'}}>do not weigh as much as their 4-stroke counterparts, and can be just as efficient. It is possible to switch from I/O to outboard power, with the use of fabricated engine brackets.</font></h3>
    <h3><font style={{'font-size': '16px'}}>I/O (STERNDRIVE) REPOWERING:</font>&nbsp; <font style={{'font-size': '13px'}}>New units from your Mercruiser dealer&nbsp;</font><font style={{'font-size': '13px'}}>are readily available, as are less expensive ready-to-go rebuilt models from respected companies like Michigan Motorz.</font></h3>
    <h3><font style={{'font-size': '16px'}}>INBOARD REPOWERING:</font>&nbsp; <font style={{'font-size': '13px'}}>Gas or diesel? New fuel-injected gasoline boat engines are basically truck and SUV engines, marinized for marine use. If switching from gas to diesel keep in mind that you may need to replace the shafts, struts, and propellers in addition to the engine.&nbsp; Yanmar&nbsp;</font><font style={{'font-size': '13px'}}>is a popular choice for diesel repowers, while companies like Michigan Motorz&nbsp;</font><font style={{'font-size': '13px'}}>provide ready-to-go gasoline engine packages.</font></h3>
    <p style={{'margin': '12px 0px'}}><font style={{'font-size': '13px'}}><strong style={{'font-size': '13px'}}>See the fine engine companies listed below for your best bet on marine repowers.</strong></font></p>
  </>;

const configs = {
  BadCreditBoats: {
    path: '/services/bad-credit-boats',
    pageType: 'BadCreditBoats',
    pageName: 'bad-credit-boats',
    title: 'Bad Credit Boats',
    upperTitle: 'BAD CREDIT LOANS',
    paragraph: 'Bad credit does not have to stop you from owning the boat of your dreams. Your solution to bad credit problems is just a click away! Our partners will help put you in the boat you want, regardless of past credit history.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309730'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599687'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198826'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309735'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599690'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198828'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309736'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599692'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198830'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309737'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599694'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198832'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  BoatAuctions: {
    path: '/services/boat-auctions',
    pageType: 'BoatAuctions',
    pageName: 'boat-auctions',
    title: 'Boat Auctions',
    upperTitle: 'BOAT AUCTION RESOURCES',
    paragraph: 'Auctions are a great source to find deals on boats, parts, and other marine equipment. Visit our partners to find out the latest in the auction market.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309735'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599690'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198828'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309736'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599692'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198830'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309737'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599694'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198832'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309738'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599695'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198834'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  BoatCovers: {
    path: '/services/boat-covers',
    pageType: 'BoatCovers',
    pageName: 'boat-covers',
    title: 'Boat Covers',
    upperTitle: 'BOAT COVERS',
    paragraph: 'Find top-quality boat covers from industry-leading retailers. Durable, sturdy, canvas boat covers help to maintain the value of your boat over time. Find lift canopies, pontoon boat tops, and other marine accessories. Click on a boat cover provider below to learn more about protecting your boat investment.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309764'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599720'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198848'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309765'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599723'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198836'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309766'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599721'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198838'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309767'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599722'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198840'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  BoatDonation: {
    path: '/services/boat-donation',
    pageType: 'BoatDonation',
    pageName: 'boat-donation',
    title: 'Boat Donation',
    upperTitle: 'BOAT DONATION',
    paragraph: 'Thinking about selling your boat? Donating could be an option for you. Learn more about donating your boat from our sponsors below.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309768'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599724'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198862'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309770'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599725'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198864'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309771'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599726'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198866'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309772'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599727'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198868'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  BoatLettering: {
    path: '/services/boat-lettering',
    pageType: 'BoatLettering',
    pageName: 'boat-lettering',
    title: 'Boat Lettering',
    upperTitle: 'BOAT LETTERING',
    paragraph: 'What\'s in a name? The day you name your boat is the day you make it your own. Design and order custom boat lettering, boat numbers and signage online. Get embroidered clothing, custom vinyl designs and more.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309731'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599688'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198878'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309732'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599689'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198880'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309733'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599691'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198882'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309734'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599693'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198884'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  BoatLifts: {
    path: '/services/boat-lifts',
    pageType: 'BoatLifts',
    pageName: 'boat-lifts',
    title: 'Boat Lifts',
    upperTitle: 'BOAT LIFTS',
    paragraph: 'Boat lifts help preserve the value of your boat, minimize maintenance headaches and can fit most any dock or seawall. Learn more from our sponsors below.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309759'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599716'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198886'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309760'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599717'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198888'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309761'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599718'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198890'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309762'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599719'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198892'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  BusinessOpportunities: {
    path: '/services/business-opportunities',
    pageType: 'BusinessOpportunities',
    pageName: 'business-opportunities',
    title: 'Business Opportunities',
    upperTitle: 'BOAT BUSINESS OPPORTUNITIE',
    paragraph: 'Marine-related opportunities are a perfect match for boaters. From real estate to job opportunities, making money in an industry you enjoy is always a plus!',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309783'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599736'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198936'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309785'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599737'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198938'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309786'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599738'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198940'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309787'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599739'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198942'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  DealerResources: {
    path: '/services/dealer-resources',
    pageType: 'DealerResources',
    pageName: 'dealer-resources',
    title: 'Dealer Resources',
    upperTitle: 'BOAT DEALER RESOURCES',
    paragraph: 'Welcome to the Boat Dealer Resource page. Dealers will find opportunities to identify new products that fuel growth in their marine business. Whether you are looking to add new lines to your inventory or the latest accessories for your showroom floor, you will find quality products and services.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309794'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599744'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198962'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309796'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599745'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198964'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309797'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599746'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198966'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309798'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599747'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198968'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  Docks: {
    path: '/services/docks',
    pageType: 'Docks',
    pageName: 'docks',
    title: 'Boat Docks',
    upperTitle: 'BOAT DOCKS',
    paragraph: 'Whether you use your boat every day or just on weekends, making an investment in a quality boat dock gives you peace of mind and can reduce maintenance. Learn more from our sponsors below.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309751'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599708'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198854'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309752'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599709'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198856'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309753'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599710'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198858'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309754'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599712'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198860'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  ExtendedServiceContracts: {
    path: '/services/extended-service-contracts',
    pageType: 'ExtendedServiceContracts',
    pageName: 'extended-service-contracts',
    title: 'Extended Service Contracts',
    upperTitle: 'EXTENDED SERVICE CONTRACTS',
    paragraph: 'Enjoy your boating experience even more by protecting your investment with a flexible extended service contract. Find out right now if your boat qualifies for this exclusive coverage for Boat Trader customers.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309701'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599658'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199006'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309702'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599659'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199008'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309703'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599660'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199010'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309704'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599661'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199012'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  FactorySpecials: {
    path: '/services/factory-specials',
    pageType: 'FactorySpecials',
    pageName: 'factory-specials',
    title: 'Factory Specials',
    upperTitle: 'FACTORY SPECIALS',
    paragraph: 'Be the first to know about manufacturer close-outs, factory close-outs, rebate programs and blow-out sales! Visit BoatTrader.com often to learn about new specials from your favorite manufacturers.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309777'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599732'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199014'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309779'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599733'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199016'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309780'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599734'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199018'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309781'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599735'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199020'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  InternationalBoatExport: {
    path: '/services/international-boat-export',
    pageType: 'InternationalBoatExport',
    pageName: 'international-boat-exports',
    title: 'International Boat Export',
    upperTitle: 'INTERNATIONAL BOAT EXPORTS',
    paragraph: 'Boaters who plan to transport their boat internationally may need help from an expert. These services will transport your vessel world-wide and provide comprehensive assistance to any boater.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309755'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599711'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199050'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309756'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599713'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199052'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309757'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599714'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199054'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309758'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599715'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199056'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  MarineElectronics: {
    path: '/services/marine-electronics',
    pageType: 'MarineElectronics',
    pageName: 'marine-electronics',
    title: 'Marine Electronics',
    upperTitle: 'MARINE ELECTRONICS',
    paragraph: 'Whether you are looking for the best fishing spot or navigating your way through shallow waters, boat electronics have become essential to your safety and enjoyment on the water. Find GPS, radios, fish finders, radars, satellite phones and more.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309743'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599700'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199062'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309744'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599701'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199064'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309745'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599702'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199066'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309746'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599703'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199068'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  MarineInteriors: {
    path: '/services/marine-interiors',
    pageType: 'MarineInteriors',
    pageName: 'marin-interiors',
    title: 'Marine Interiors',
    upperTitle: 'MARINE INTERIORS',
    paragraph: 'Find retailers that can help you refurbish your boat interiors. From boat cushions, recovering, and foam replacement, our providers will get you started!',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309739'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599696'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199070'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309740'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599697'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199072'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309741'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599698'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199074'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309742'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599699'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199076'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  WaterfrontProperty: {
    path: '/services/waterfront-property',
    pageType: 'WaterfrontProperty',
    pageName: 'waterfront-property',
    title: 'Waterfront Property',
    upperTitle: 'WATERFRONT PROPERTY',
    paragraph: 'Boat Trader offers a selection of quality brokers in various locales to help you find the perfect waterfront property.',
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309800'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599748'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199140'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309801'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599749'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199142'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309803'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599751'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199144'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309804'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599753'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199146'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  DealerCertification: {
    path: '/services/dealer-certification',
    pageType: 'DealerCertification',
    pageName: 'dealer-certification',
    title: 'Dealer Certification',
    upperTitle: 'MARINE INDUSTRY DEALER CERTIFICATION',
    formattedText: DealerCertificationText,
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309789'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599740'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198944'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309790'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599741'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198946'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309791'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599742'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198948'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309793'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599743'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1198950'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  IndustryIdeas: {
    path: '/services/industry-ideas',
    pageType: 'IndustryIdeas',
    pageName: 'industry-ideas',
    title: 'Industry Ideas',
    upperTitle: 'Boating Industry\'s Best Ideas',
    formattedText: IndustryIdeasText,
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309773'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599728'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199042'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309774'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599729'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199044'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309775'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599730'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199046'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309776'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599731'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199048'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  MarineSupplies: {
    path: '/services/marine-supplies',
    pageType: 'MarineSupplies',
    pageName: 'marine-supplies',
    title: 'Marine Supplies',
    upperTitle: 'MARINE SUPPLIES',
    formattedText: MarineSuppliesText,
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309747'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599704'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199078'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309748'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599705'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199080'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309749'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599706'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199082'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309750'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599707'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199084'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  NewBoatOwnerProgram: {
    path: '/services/new-boat-owner-program',
    pageType: 'NewBoatOwnerProgram',
    pageName: 'new-boat-owner-program',
    title: 'New Boat Owner Program',
    upperTitle: '',
    formattedText: NewBoatOwnerProgramText,
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309719'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599676'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199086'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309720'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599677'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199088'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309721'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599678'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199090'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309722'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599679'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199092'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  },
  Repower: {
    path: '/services/repower',
    pageType: 'Repower',
    pageName: 'repower',
    title: 'Repower Your Boat',
    upperTitle: '',
    formattedText: RepowerText,
    /*prebids: {
      'box-1': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309723'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '767284432'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599680'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199098'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-2': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309724'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '487618234'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599681'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199100'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-3': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309725'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599682'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199102'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ],
      'box-4': [
        {
          'bidder': 'appnexus',
          'params': {
            'placementId': '14309726'
          }
        },
        {
          'bidder': 'medianet',
          'params': {
            'cid': '8CU7PN9JY',
            'crid': '155588488'
          }
        },
        {
          'bidder': 'openx',
          'params': {
            'delDomain': 'dominionmedia-d.openx.net',
            'unit': '540599683'
          }
        },
        {
          'bidder': 'rubicon',
          'params': {
            'accountId': 16902,
            'siteId': 242602,
            'zoneId': '1199104'
          }
        },
        {
          'bidder': 'districtmDMX',
          'params': {
            'memberid': 101828,
            'dmxid': '346706'
          }
        }
      ]
    }*/
  }
};

export default configs;

import React from 'react';
const ServiceGroup = (props) =>  {
  var headerStyle = {
    backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/services/${props.image})`,
  };
  return (
    <div className="service-group">
      <div style={ headerStyle } className="service-group__header">
        <h4 className="service-group__title" >{props.title}</h4>
      </div>
      {props.services.map((service, i) => <a key={i} className="service-group__link" href={service.link}>{service.text}</a>)}
    </div>
  );
};
export default ServiceGroup;


import React, { PureComponent } from 'react';
import Spinner from '../Spinner';
import { getLocation } from '../../utils/listingHelper';
import { detailsMapper } from '../../utils/infoBoxButtonHelper';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../Collapsible';
import get from 'lodash/get';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { InfoBoxButton } from '@dmm/lib-react-ui-components';
import './styles.css';

const Modal = ({ handleClose, handleInputChange, calculateAndDisplayRoute, sourceLocation, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <span className="close-map-modal" onClick={handleClose}>Close Map</span>
        <div className="calculate-route">
          <input aria-label="source place" onChange={handleInputChange} value={sourceLocation} />
          <button className="calculate-route-button" onClick={calculateAndDisplayRoute}>Calculate Route</button>
        </div>
      </section>
    </div>
  );
};

class LocationMap extends PureComponent {

  state = {
    showModalMap: false,
    activeMarker: null,
    sourceLocation: '',
    mapInstance: {},
    showInfoWindow: false,
    open: false
  }

  handleInputChange = (event) => {
    this.setState({sourceLocation: event.target.value});
  }

  calculateAndDisplayRoute = () => {
    const { directionsService, directionsRenderer } = this.state;
    const { listing } = this.props;
    const coordinatesBoat = get(listing, 'location.coordinates', {});
    directionsService.route(
      {
        origin: {query: this.state.sourceLocation},
        destination: {query: coordinatesBoat[1] + ',' + coordinatesBoat[0]},
        travelMode: 'DRIVING'
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      });
  }

  handleOnClickModalMarker = (props, marker) => {
    this.setState({
      showInfoWindow: true,
      activeMarker: marker
    });
  }

  handleOnClickMarker = (props, marker) => {
    this.setState({
      showInfoWindow: true,
      activeMarker: marker
    });
  }

  hideModal = () => {
    this.state.directionsRenderer.setMap(null);
    this.setState({ showModalMap: false });
  };

  showModal = () => {
    this.setState({
      showModalMap: true,
      showInfoWindow: false,
      activeMarker: null
    });
  };

  setMapToState = (mapProps, map) => {
    this.state.directionsRenderer.setMap(map);
    this.props.addLead(this.props.listing.id, 'map view');
  }

  componentDidMount = () => {
    const mapProps = this.props.google;
    this.setState({
      directionsService: new mapProps.maps.DirectionsService(),
      directionsRenderer: new mapProps.maps.DirectionsRenderer()
    });
  }

  componentDidUpdate = () => {
    if (this.props.open !== this.state.openComponent){
      this.setState({
        openComponent: this.props.open
      });
    }
  }

  renderMap (coordinatesBoat, address){
    const coordinatesBoatFormated = {
      lat: coordinatesBoat[1],
      lng: coordinatesBoat[0]
    };
    return (
      <div data-testid="location-map">
        <div className="location-map" onClick={this.showModal}>
          <Map
            id="map"
            title="map showing the location of the boat"
            google={this.props.google}
            disableDefaultUI={true}
            zoom={14}
            initialCenter={coordinatesBoatFormated}>
            <Marker
              id="marker"
              name={'Current boat location'}
              onClick={this.handleOnClickMarker}
              icon= {{
                url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
                alt: 'Current boat location'
              }} />
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showInfoWindow}
            >
              <div >
                <p>{address}</p>
              </div>
            </InfoWindow>
          </Map>
        </div>
        <div className="address">{address}</div>
        <Modal show={this.state.showModalMap}
          handleClose={this.hideModal}
          handleInputChange={this.handleInputChange}
          sourceLocation={this.state.sourceLocation}
          calculateAndDisplayRoute={this.calculateAndDisplayRoute}>
          <Map
            id="map"
            title="map showing the location of the boat"
            google={this.props.google}
            zoom={14} initialCenter={coordinatesBoatFormated}
            onReady={this.setMapToState}>
            <Marker
              id="modal-marker"
              onClick={this.handleOnClickModalMarker}
              name={'Current boat location'}
              icon= {{
                url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
                alt: 'Current boat location'
              }} />
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showInfoWindow}
            >
              <div >
                <p>{address}</p>
              </div>
            </InfoWindow>
          </Map>
        </Modal>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="location-map">
        <Spinner/>
      </div>
    );
  }

  handleOnOpen = () => {
    this.setState({
      open: true
    });
  }

  render() {
    const { isLoading, listing, setCollapseContentTracking } = this.props;
    const coordinatesBoat = get(listing, 'location.coordinates', {});
    const address = getLocation(listing, 'locationMap');
    const { oemDetails } = listing;
    const showDealers = oemDetails && oemDetails.oemDealerLocator;
    return (
      <div className="location-map-wrapper">
        <div id="location-map-anchor"></div>
        <CollapsibleContent open={this.state.openComponent} openCounter={this.props.counter} onOpen={this.handleOnOpen} initialState="closed" classOverride="details">
          <CollapsibleHeader onClick={(e,open) => {if (open) {setCollapseContentTracking('map-view');}}}>
              Location
          </CollapsibleHeader>
          <Collapsible type="no-pad">
            {this.state.open && !isLoading && listing.id ?
              this.renderMap(coordinatesBoat, address)
              :
              this.renderLoading()
            }
          </Collapsible>
          { !showDealers && (
            <div className="cta-wrapper">
              <InfoBoxButton
                title={ detailsMapper.location.title }
                content={ detailsMapper.location.content }
                classNames={{
                  icon: true
                }}
                icon="location"
                onClick={ (event) => { this.props.onClickRequestInformation(event, { eventName: 'infobox', type: 'location' }, detailsMapper.location.eventLabel ); } }
              />
            </div>
          )}
        </CollapsibleContent>
      </div>
    );

  }

}

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
  LoadingContainer: Spinner
})(LocationMap);

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BRANCH_EVENT, BRANCHIO_META_TAG_NAME } from '../../branchIoEventsManager';

const FIND_BRANCHIO_META_TAG = 'meta[name^="branch:deeplink:"]';
const TIMEOUT = 500;

const useBranchIoMetaTag = () => {
  const [tagAdded, setTagAdded] = useState(false);
  const [metaInfo, setMetaInfo] = useState('');

  const addMetaTag = (content) => {
    setMetaInfo(content);
  };

  const trackPageView = () => window?.branch?.track(BRANCH_EVENT.PAGE_VIEW);

  const stopJourney = () => window?.branch?.closeJourney();

  const executeJavaScript = () => setTimeout(() => trackPageView(), TIMEOUT);

  const removeBranchMetaTags = () => {
    const metas = document.querySelectorAll(FIND_BRANCHIO_META_TAG);
    metas.forEach(meta => meta.parentNode.removeChild(meta));
  };

  const fireBranchioMetaTag = (content) => {
    if (!tagAdded) {
      removeBranchMetaTags();
      stopJourney();
      addMetaTag(content);
      setTagAdded(true);
      executeJavaScript();
    }
  };

  const BranchIoMetaTagComponent = () => (
    <React.Fragment>
      <Helmet>
        {tagAdded && <meta name={BRANCHIO_META_TAG_NAME} content={metaInfo} />}
      </Helmet>
    </React.Fragment>
  );

  return { BranchIoMetaTagComponent, fireBranchioMetaTag};
};

export default useBranchIoMetaTag;

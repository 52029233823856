import get from 'lodash/get';
import { normalizeString } from '@dmm/lib-common/lib/formatting';

import {
  LEGACY_SEARCH_URL_STATE_PATTERN,
  LEGACY_SEARCH_URL_ZIP_PATTERN,
  LEGACY_SEARCH_URL_RADIUS_PATTERN,
  LEGACY_SEARCH_URL_LENGTH_PATTERN,
  LEGACY_SEARCH_URL_ENGINETYPE_PATTERN,
  LEGACY_SEARCH_URL_PAGE_PATTERN,
  LEGACY_SEARCH_URL_SORT_PATTERN,
  LEGACY_SEARCH_URL_CONDITION_PATTERN,
  LEGACY_SEARCH_URL_MAKE_PATTERN,
  LEGACY_SEARCH_URL_MODEL_PATTERN,
  LEGACY_SEARCH_URL_CLASS_PATTERN,
  LEGACY_SEARCH_URL_TYPE_PATTERN,
  LEGACY_SEARCH_URL_YEAR_PATTERN,
  LEGACY_SEARCH_URL_FUEL_PATTERN,
  LEGACY_SEARCH_URL_HULL_PATTERN,
  LEGACY_SEARCH_URL_PRICE_PATTERN,
  LEGACY_SEARCH_URL_MAKE_MODEL_PATTERN,
  LEGACY_SEARCH_URL_FORSALEBY_PATTERN
} from '../../constants/searchResults';
import { engineLegacyTypes } from '../engineTypeHelper';
import { multiFacetUrlToObject } from '../multiFacetHelper';
import { getClassByNameforHomePageSearch } from '../commonHelper';
import { getClassCategory, legacyMappings, handleLegacyAllType, handleLegacyType } from '../classHelper';

const HULL_MATERIAL_BOAT_CLASS_MAPPING = {
  inflatable: 'power-inflatable',
  'rigid+inflatable': 'power-rib'
};

const SOURCE_CODE_MAPPING = {
  imt: 'dealer',
  fsbo: 'owner',
  nt: 'owner'
};

export const getDefaultParams = (params) => {
  let parsed = {
    ...parseStateParams(params.state),
    ...parseZipParams(params.zip),
    ...parseRadiusParams(params.radius),
    ...parseLegacyMakeModelParams(params.make, params.model),
    ...parseMakeModelParams(params.makeModel),
    ...parseLengthParams(params.length),
    ...parseEngineTypeParams(params.engine),
    ...parsePageParams(params.page),
    ...parseSortParams(params.sort),
    ...parseConditionParams(params.condition),
    ...parseYearParams(params.year),
    ...parseFuelParams(params.fuelType),
    ...parseTypeAndClassParams(params.types, params.classes),
    ...parsePriceParams(params.price),
    ...parseForSaleByParams(params.forSaleBy)
  };
  parsed = {
    ...parsed,
    ...parseHullParams(params.hullMaterial, parsed.multiFacetedBoatTypeClass)
  };

  return parsed;
};

export const parseStateParams = (state = '') => {
  const matches = LEGACY_SEARCH_URL_STATE_PATTERN.exec(state);
  if (matches) {
    const twoLetterState = matches[1].split('|');
    return {
      state: twoLetterState[0] //TODO add some validation to this data.
    };
  }
  return;
};

export const parseLegacyMakeModelParams = (make = '', model = '') => {
  const makeMatches = LEGACY_SEARCH_URL_MAKE_PATTERN.exec(make);
  if (makeMatches) {
    let makeValue = unslugify(makeMatches[1], '+', ' ');
    makeValue = hyphenateUrlComponents(makeValue);
    const modelMatches = model ? LEGACY_SEARCH_URL_MODEL_PATTERN.exec(model) : '';
    let modelValue = [];
    if (modelMatches) {
      modelValue = unslugify(modelMatches[1], '+', ' ');
      modelValue = [hyphenateUrlComponents(modelValue)];
    }
    return {
      makeModel: {
        [makeValue]: modelValue
      }
    };
  }
  return;
};

export const parseForSaleByParams = (forSaleBy) => {
  const matches = LEGACY_SEARCH_URL_FORSALEBY_PATTERN.exec(forSaleBy);
  if (matches) {
    const val = SOURCE_CODE_MAPPING[matches[1]];
    if (val) {
      return {
        forSale: val
      };
    }
    return {
      forSale: 'all'
    };
  }
  return;
};

export const parseMakeModelParams = (makeModel) => {
  const matches = LEGACY_SEARCH_URL_MAKE_MODEL_PATTERN.exec(makeModel);
  if (matches) {
    let value = unslugify(matches[1], '+', ' ');
    value = unslugify(value, ',', '+');
    value = hyphenateUrlComponents(value);
    return {
      makeModel: multiFacetUrlToObject(value, true, '+')
    };
  }
  return;
};

export const parseZipParams = (zip = '') => {
  const matches = LEGACY_SEARCH_URL_ZIP_PATTERN.exec(zip);
  if (matches) {
    return {
      zip: matches[1] //TODO add some validation to this data.
    };
  }
  return;
};

export const parseRadiusParams = (radius = '') => {
  const matches = LEGACY_SEARCH_URL_RADIUS_PATTERN.exec(radius);
  if (matches) {
    const radiusVal = matches[1];
    if (radiusVal === 'any') {
      return {
        radius: 4000
      };
    }
    return {
      radius: radiusVal //TODO add some validation to this data.
    };
  }
  return undefined;
};

export const parseYearParams = (year = '') => {
  const matches = LEGACY_SEARCH_URL_YEAR_PATTERN.exec(year);
  if (matches) {
    const ret = { year: {} };
    if (matches[1]) {
      ret.year.min = matches[1];
    }
    if (matches[2]) {
      ret.year.max = matches[2];
    }
    return ret;
  }
  return;
};

export const parseTypeAndClassParams = (types = '', classes = '') => {
  const matches = LEGACY_SEARCH_URL_TYPE_PATTERN.exec(types);
  if (matches) {
    let decodedClasses = {};
    let typeValue = matches[1].toLowerCase();
    if (typeValue.split(',')[0] === 'any') {
      return;
    }
    typeValue.split(',').map(type => {
      type = handleLegacyType(type);
      decodedClasses = { ...decodedClasses, [type]: [] };
    });
    const classMatches = LEGACY_SEARCH_URL_CLASS_PATTERN.exec(classes);
    if (classMatches) {
      let classValue = classMatches[1];
      classValue = unslugify(classValue, '--', '/');
      classValue = unslugify(classValue, ' ', '+');
      classValue.split(',').map((boatClass) => {
        boatClass = handleLegacyAllType(handleLegacyType(typeValue), boatClass);
        if (Object.keys(legacyMappings).includes(boatClass)) {
          legacyMappings[boatClass].map(legacyClass => {
            decodedClasses[getClassCategory(legacyClass)].push(legacyClass);
          });
        }
        else {
          decodedClasses[getClassCategory(boatClass)].push(boatClass);
        }
      });
      return { multiFacetedBoatTypeClass: decodedClasses };
    }
  }
  return;
};

export const parseLengthParams = (length = '') => {
  const matches = LEGACY_SEARCH_URL_LENGTH_PATTERN.exec(length);
  if (matches) {
    let value = { min: matches[1] };
    if (matches[2]) {
      value.max = matches[2];
    }
    return {
      length: value,
    };
  }
  return;
};

export const parsePriceParams = (price = '') => {
  const matches = LEGACY_SEARCH_URL_PRICE_PATTERN.exec(price);
  if (matches) {
    let value = { min: matches[1] };
    if (matches[2]) {
      value.max = matches[2];
    }
    return {
      price: value,
    };
  }
  return;
};

export const parsePageParams = (page = '') => {
  const matches = LEGACY_SEARCH_URL_PAGE_PATTERN.exec(page);
  if (matches) {
    return {
      page: matches[1],
      pageSize: matches[2]
    };
  }
  return;
};

export const parseSortParams = (sort = '') => {
  const matches = LEGACY_SEARCH_URL_SORT_PATTERN.exec(sort);
  if (matches) {
    return { sort: matches[1].toLowerCase() };
  }
  return;
};

export const parseConditionParams = (condition = '') => {
  const matches = LEGACY_SEARCH_URL_CONDITION_PATTERN.exec(condition);
  if (matches) {
    return {
      condition: matches[1]
    };
  }
  return;
};

export const parseEngineTypeParams = (engine = '') => {
  const matches = LEGACY_SEARCH_URL_ENGINETYPE_PATTERN.exec(engine);
  if (matches) {
    matches[1] = engineLegacyTypes[matches[1]] || matches[1];
    if (matches[1] === 'all') {
      return {};
    }
    return { engine: matches[1] };
  }
  return;
};

export const parseFuelParams = (fuelType = '') => {
  const matches = LEGACY_SEARCH_URL_FUEL_PATTERN.exec(fuelType);
  if (matches) {
    const fuelTypes = matches[1].toLowerCase().split(',');
    return {
      fuelType: fuelTypes
    };
  }
  return;
};

export const parseHullParams = (hullMaterial = '', multiFacetedBoatTypeClass = {}) => {
  const matches = LEGACY_SEARCH_URL_HULL_PATTERN.exec(hullMaterial);
  if (matches) {
    const hullMaterialValue = unslugify(matches[1], ' ', '+').split(',');
    let returnObject = {
      hullMaterial: hullMaterialValue
    };
    const classToAdd = get(HULL_MATERIAL_BOAT_CLASS_MAPPING, hullMaterialValue);
    if (classToAdd) {
      let multiFacetedBoatTypeClassNew;
      if (multiFacetedBoatTypeClass.small) {
        multiFacetedBoatTypeClassNew = {
          ...multiFacetedBoatTypeClass,
          small: [...multiFacetedBoatTypeClass.small, classToAdd]
        };
      } else {
        multiFacetedBoatTypeClassNew = {
          ...multiFacetedBoatTypeClass,
          small: [classToAdd]
        };
      }

      returnObject = {
        multiFacetedBoatTypeClass: multiFacetedBoatTypeClassNew
      };
    }
    return returnObject;
  }
  return;
};

export const slugify = (string) => string.toLowerCase().split(' ').map(encodeURIComponent).join('+');
export const unslugify = (string, splitter = '+', joiner = ' ') =>
  string.toLowerCase().split(splitter).map(decodeURIComponent).join(joiner);

export const hyphenateUrlComponents = (string) => {
  return string.toLowerCase().replace(/&/g, '$').replace(/([-#_\/\s])/g, (match) => {
    return match === '-' ? '--' : '-';
  });
};

const buildOriginalUrl = (paramName, value) => {
  return (value && value !== 'all' && value !== 'any') ? '/' + paramName + '-' + normalizeString(hyphenateUrlComponents(value.toLowerCase())) : '';
};

const buildClassUrl = (params) => {
  if (params.get('type')) {
    let splittedParams = params.get('type').split('|');
    if (splittedParams.length > 1) {
      let normalizedType = normalizeString(splittedParams[0]);
      let normalizedClass = normalizeString(splittedParams[1]);
      let classArray = getClassByNameforHomePageSearch(normalizedClass);
      if (classArray) {
        if (classArray.length > 1 && normalizedClass === 'other') {
          classArray = mapHomepageOtherClasses(normalizedType, classArray);
        }
        if (classArray) {
          return `/class-${classArray.join('+')}`;
        }
      }
    }
  }
  return '';
};

const mapHomepageOtherClasses = (normalizedType, classArray) => {
  switch (normalizedType) {
  case 'power':
    classArray = [classArray[0]];
    break;
  case 'sail':
    classArray = [classArray[1]];
    break;
  default:
    classArray = undefined;
    break;
  }
  return classArray;
};

const buildTypeUrl = (params) => {
  if (params.get('type')) {
    let splittedParams = params.get('type').split('|');
    let normalizedType = normalizeString(splittedParams[0]);
    normalizedType = normalizedType === 'small-boats' ? 'small' : normalizedType;
    return buildOriginalUrl('type', normalizedType);
  }
  return '';
};

export const rewriteOriginalSearchResultsUrl = props => {
  let query = get(props, 'location.search', {});
  const params = new URLSearchParams(query);
  let path = `/boats${buildTypeUrl(params)}${buildClassUrl(params)}${buildOriginalUrl('make', params.get('makes'))}${buildOriginalUrl('zip', params.get('location'))}/`;
  if (params.get('location')) {
    path = `${path.slice(0, -1)}${buildOriginalUrl('radius', params.get('radius'))}/`;
  }
  return path;
};

export const readGroupedUrlParams = urlParams => {
  if (urlParams.hullMaterial) {
    const tmp = urlParams.hullMaterial.split(',');
    const i = tmp.indexOf('fiberglass');
    if (i !== -1) {
      tmp[i] = 'fiberglass-composite,fiberglass-reinforced';
      urlParams.hullMaterial = tmp.join(',');
    }
  }
  return urlParams;
};

export const readGroupedUrlParamsState = urlState => {
  if (urlState?.hullMaterial) {
    const tmp = urlState.hullMaterial;
    const fiberGlassTypes = ['fiberglass-composite', 'fiberglass-reinforced', 'fiberglass'];
    if (tmp.includes(fiberGlassTypes[0]) && tmp.includes(fiberGlassTypes[1])) {
      const hullMaterial = tmp.filter(hull => !fiberGlassTypes.includes(hull) );
      hullMaterial.push('fiberglass');
      return {...urlState, hullMaterial};
    }
  }
  return urlState;
};

export const normalizeUrlEncoding = url => {
  return String(url)
    .split('/')
    .map(part => normalizeString(decodeURIComponent(part)))
    .join('/');
};

export const seoCrawlFix = (reqPath, fullUrl) => {
  const regex = /(\/10\/?|\/change%20me\/?|\/undefined\/?)$/;
  if (regex.test(reqPath)) {
    const urlObj = new URL(fullUrl);
    const newPath = reqPath.replace(regex, '/');
    urlObj.pathname = newPath;

    return {
      status: 301,
      url: urlObj.toString()
    };
  }

  return {
    status: 200,
    url: fullUrl
  };
};

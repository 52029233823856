import React, { useState, useRef, useEffect } from 'react';
import './styles.css';
import TooltipLite from 'react-tooltip-lite';
import classNames from 'classnames';
const CDN_URL = process.env.REACT_APP_CDN_URL;

const Tooltip = ({ contentText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const tipContentRef = useRef(null);
  const buttonRef = useRef(null);

  const bodyClick = (e) => {
    if (tipContentRef && tipContentRef.current === e.target) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() =>{
    document.addEventListener('mousedown', bodyClick);
    return () =>{
      document.removeEventListener('mousedown', bodyClick);
    };
  }, []);

  return <TooltipLite
    content={
      <div
        role="tooltip"
        className={classNames('subtitle-tooltip', { 'hidden-tooltip': !isOpen })}
        ref={tipContentRef}>
        {contentText}
      </div>
    }
    isOpen={isOpen}
    eventToggle="onClick"
    className="subtitle-tooltip"
    direction="up"
    distance={10}
    background="#FFFFFF"
  >
    <div
      role="con"
      ref={buttonRef}
      className="info-icon"
      onClick={() => setIsOpen(!isOpen)}
    >
      <img width="14" height="14" alt="info icon" src={`${CDN_URL}/boat-loans/finance-variant/Info.svg`}></img>
    </div>
  </TooltipLite>;
};

export const PrivateSellerInfo = () => {
  return (
    <div className="private-seller-info">
      <div className="title">
        <img width="292" height="42" alt="private seller connect logo" src={`${CDN_URL}/boat-services/private-seller-connect-logo.svg`}/>
      </div>
      <div className="subtitle">
        <span>Get protection for your peace of mind</span>
      </div>
      <div className="section">
        <div className="row">
          <span className="subtitle">GAP Protection</span>
          <Tooltip contentText="In the event of a total loss of your boat due to an accident or theft, GAP (Guaranteed Asset Protection) protection covers the difference between the boat’s actual cash value and the remaining loan balance so you’re protected from significant financial losses."/>
        </div>
        <div className="section-content">
          <span className="highlight">Boat Trader offers GAP coverage at an affordable cost.</span>
          <ul>
            <li>Coverage for as low as $8.50/month</li>
            <li>Coverage for loans up to $500,000 and payouts up to $50,000</li>
            <li>Roadside assistance available</li>
            <li>Tire and wheel coverage available</li>
          </ul>
          <a href="/boat-loans/apply/?purpose=Boat&source=101458&utm_source=boattrader&utm_medium=internalreferral&utm_campaign=btbdpgap&utm_content=btbdpgap" target="_blank">Finance with GAP</a>
        </div>
      </div>
      <div className="section">
        <div className="row">
          <span className="subtitle">Extended Warranty Breakdown <span className="deductible">| $100 deductible</span></span>
          <Tooltip contentText="Extended Marine Service Contracts (commonly known as “Extended Warranties”) help boat owners eliminate excessive, out-of-pocket expenses from unforeseen mechanical breakdowns."/>
        </div>
        <div className="section-content">
          <span className="highlight">Boat Trader offers transferable extended warranties</span>
          <div className="table">
            <div className="table-row">
              <span>Multiple Engine Coverage</span>
              <span>Up to 5 Years - Boats under 20 years old</span>
            </div>
            <div className="table-row dark">
              <span>Gas and Diesel Engine Coverage</span>
              <span>Up to 5 Years - Boats under 20 years old</span>
            </div>
            <div className="table-row">
              <span>Wear and Tear Coverage</span>
              <span>Up to 5 Years - Boats under 20 years old</span>
            </div>
            <div className="table-row dark">
              <span>Bow to Stern Coverage</span>
              <span>Up to 9 Years - Boats under 10 years old</span>
            </div>
            <div className="table-row">
              <span>Engine and Craft Only Coverage </span>
              <span>Available on select models and years*</span>
            </div>
          </div>
          <a href="/boat-loans/apply/?purpose=Boat&source=101458&utm_source=boattrader&utm_medium=internalreferral&utm_campaign=btbdpwarranty&utm_content=btbdpwarranty" target="_blank">Finance with a warranty</a>
        </div>
      </div>
      <div className="section">
        <span className="subtitle">Closing Services</span>
        <div className="section-content column">
          <div className="text">
            <span>Boat Trader&apos;s expert closing team handles closing paperwork and registration so you don’t have to.</span>
          </div>
          <a href="/boat-loans/apply/?purpose=Boat&source=101458&utm_source=boattrader&utm_medium=internalreferral&utm_campaign=btbdpyc&utm_content=btbdpyc" target="_blank">Finance with closing services</a>
        </div>
      </div>
    </div>
  );
};

import React, { PureComponent } from 'react';
import PaginationItem from '../../../components/PaginationItem';
import { generateSearchPath, getDefaultParams } from '../../../utils/urlHelpers/listings';
import { getPaginationData } from '../../../utils/paginationHelper';
import get from 'lodash/get';

class ResultsPagination extends PureComponent {
  render() {
    let { onClick } = this.props;
    let count = get(this.props, 'search.count', 28);
    let pageParams = get(this.props, 'match.params', {});
    let searchPage = get(this.props, 'searchPage', undefined);
    let params = getDefaultParams(pageParams, searchPage);
    let pagination = getPaginationData(params, count, 5);
    let pageSize = pagination.pageSize;
    return (
      <ul className="pagination" data-e2e="srp-pagination">
        <PaginationItem key="first" onClick={onClick} disabled={pagination.isFirstPage} type="prev" href={!pagination.isFirstPage ? generateSearchPath({ page: pagination.page - 1, pageSize }, params, undefined, searchPage) : null}>&lt;</PaginationItem>
        {pagination.visibleNumbers.map((value) => (
          <PaginationItem
            key={value}
            onClick={onClick}
            href={generateSearchPath({ page: value, pageSize }, params, undefined, searchPage)}
            active={pagination.page === value}
          >
            { value }
          </PaginationItem>
        ))}
        <PaginationItem key="last" onClick={onClick} disabled={pagination.isLastPage} type="next" href={!pagination.isLastPage ? generateSearchPath({ page: pagination.page + 1, pageSize }, params, undefined, searchPage) : null}>&gt;</PaginationItem>
      </ul>
    );
  }
}

export default ResultsPagination;

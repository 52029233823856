import React from 'react';

import './styles.css';
import { LOAN_PROCESS_TEXT_P1_V2, LOAN_PROCESS_TEXT_P2_V2 } from '../../../../../../constants/BoatLoansCalculator';

export const BoatLoansProcess = () => {
  return (
    <div className="boat-loans-process-component" data-e2e="boat-loans-process-component" data-testid="boat-loans-process-component" >
      <div className="loan-process-body">
        <p>{LOAN_PROCESS_TEXT_P1_V2}</p>
        <p dangerouslySetInnerHTML={{__html: LOAN_PROCESS_TEXT_P2_V2}} />
      </div>
    </div>
  );
};

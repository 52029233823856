import React from 'react';
import BDPEmailLeadForm from '../../BDPSections/BDPEmailLeadForm';
import classNames from 'classnames';
import './styles.css';

const CDN_URL = process.env.REACT_APP_CDN_URL;

export const ModalContactLead = (props) => {

  const {
    isUserLoginExperiment,
    showDetailsContact,
    isMobile,
    handleCloseContactForm,
    breakpoint,
    showLeadOverlay,
    setDisableRequestInfo,
    userEmail,
    listing,
    handleDetailsSuccess } = props;

  return <>
    {showLeadOverlay && <div className="lead-form-overlay" onClick={handleCloseContactForm}></div>}
    {((!isUserLoginExperiment && showDetailsContact && isMobile) ||
        (isUserLoginExperiment && showDetailsContact)) &&
        <div className={classNames('modal-email-form-container', { 'for-desktop': isUserLoginExperiment && !isMobile })}>
          <div className="contact-instructions-title">
            {isUserLoginExperiment && <img alt="private seller connect logo" src={`${CDN_URL}/boat-services/private-seller-connect-logo.svg`}/>}
          </div>
          <BDPEmailLeadForm
            listing={listing}
            breakpoint={breakpoint}
            contactFormPosition = "finance"
            initialValues={isUserLoginExperiment && {email: userEmail}}
            onSuccess={handleDetailsSuccess}
            setDisableRequestInfo={setDisableRequestInfo}
            buttonAttributes={{ 'data-testing-class': `form-submission-${breakpoint}-finance-modal` }}
          />
          <button
            className="contact-close"
            onClick={() => handleCloseContactForm()}>
            <img width="14" height="14" alt="close icon" src={`${CDN_URL}/img/icons/modal-close-button.svg`}></img>
          </button>
        </div>
    }
  </>;
};

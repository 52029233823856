import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Breadcrumb } from '@dmm/lib-react-ui-components';
import RichSnippet from '../../../../components/RichSnippet';
import { getBreakpoint } from '../../../../utils/commonHelper';
import DefaultLayout from '../../../../layouts/Default';
import {
  TIRE_AND_WHEEL_PLAN_URL,
  TRIDENT_SERVICES_CDN_URL,
  TIRE_AND_WHEEL_HERO,
} from '../../../../constants/TridentServices';
import {
  TRACKING_TIRE_WHEEL_TOP_FORM_ACTION_TYPE,
  TRACKING_TIRE_WHEEL_TOP_FORM_ACTION_LABEL,
  TRACKING_TIRE_WHEEL_TOP_FORM_EVENT,
  TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_ACTION_TYPE,
  TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_ACTION_LABEL,
  TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_EVENT,
} from '../../../../constants/BoatLoans';
import { tireAndWheelPageInfoSection } from '../../../../constants/TridentServices/tireAndWheelPageInfoSection';
import { Hero } from '../shared/Hero';
import InfoSection from '../shared/InfoSection';

const backgroundHero = `${TRIDENT_SERVICES_CDN_URL}${TIRE_AND_WHEEL_HERO}`;
const leadPageHero = 'Tire & Wheel Plan';
const leadPageInfo = 'Tire & Wheel Protection';
const encodedLeadPageHero = encodeURIComponent(leadPageHero); // Encoding the leadPage for compatibility with SQS processing
const encodedLeadPageInfo = encodeURIComponent(leadPageInfo);

import './styles.css';
import { FAQSection } from '../shared/FAQSection';
import { getFAQSchema } from '../../../../utils/metaContentHelper';
import metadata from './TireAndWheelFAQMeta.json';
import { FAQ_ACCORDION_CONTENT, FAQ_DISCLAIMER_TEXT, FAQ_SECTION_TITLE } from '../../../../constants/TridentServices/tireAndWheelFAQs';

const TireAndWheelPlanPage = (props) => {
  const { isLeadSubmitted } = props;
  const [isMobile, setIsMobile] = useState(getBreakpoint() === 'mobile');
  const [isReady, setIsReady] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setIsReady(true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleResize = () => {
    setIsMobile(getBreakpoint() === 'mobile');
  };

  return (
    <>
      <RichSnippet schema={getFAQSchema(metadata)} />
      <DefaultLayout {...props} pageType="TridentTireWheelProtection" hideOverflow>
        <div className="breadcrumb-container">
          <Breadcrumb
            items={[
              {
                text: 'Home',
                url: '/',
              },
              {
                text: 'Services',
              },
              {
                text: 'Tire & Wheel Plan',
                url: TIRE_AND_WHEEL_PLAN_URL,
              },
            ]}
          />
        </div>

        {isReady && (
          <Hero
            backgroundImageUrl={backgroundHero}
            title={<>Tire & Wheel{isMobile && <br/>} Protection for{isMobile && <br/>} Boat Owners</>}
            text="Coverage for as Low as"
            price="$25/month"
            formTitle="Get Your Free Quote"
            leadPage={encodedLeadPageHero}
            isLeadSubmitted={isLeadSubmitted}
            dataTestId="tire-and-wheel-hero"
            trackingEvent={
              {
                'action_type': TRACKING_TIRE_WHEEL_TOP_FORM_ACTION_TYPE,
                'action_label': TRACKING_TIRE_WHEEL_TOP_FORM_ACTION_LABEL,
                'event': TRACKING_TIRE_WHEEL_TOP_FORM_EVENT
              }
            }
          />
        )}

        <div className="main-section">
          <div className="tire-wheel-info">
            <InfoSection
              isMobile={isMobile}
              info={tireAndWheelPageInfoSection}
              leadPage={encodedLeadPageInfo}
              isLeadSubmitted={isLeadSubmitted}
              getProtectedGALinkId="get protected button tire & wheel page"
              trackingEvent={{
                'action_type': TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_ACTION_TYPE,
                'action_label': TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_ACTION_LABEL,
                'event': TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_EVENT,
              }}/>
          </div>

          <div className="extended-services-faqs">
            <FAQSection
              pageTitle={FAQ_SECTION_TITLE}
              accordionItems={FAQ_ACCORDION_CONTENT}
              disclaimerText={FAQ_DISCLAIMER_TEXT}
            />
          </div>

        </div>
      </DefaultLayout>
    </>
  );
};


// We will use state to Anti SPAM actions with the lead form
const mapStateToProps = (state) => {
  return {
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false)
  };
};

export default connect(
  mapStateToProps,
)(TireAndWheelPlanPage);

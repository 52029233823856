import { TRIDENT_APP_URL_FRM } from '../../utils/trident/constants';

export const BOAT_LOANS_CALCULATOR_URL = '/boat-loans/calculator/';

export const BOAT_LOANS = 'boat-loans';

export const CDN = `${process.env.REACT_APP_CDN_URL}`;
export const CDN_BOAT_LOANS = `${CDN}/${BOAT_LOANS}`;

export const RATE_DISCLAIMER = '*Estimated APR, or Annual Percentage Rate, is the annual cost of the loan, which includes other charges and fees. Actual rate and term can vary depending on credit history, collateral type, age of collateral, loan amount, loan term, loan to value, vessel registration type (LLC/Trust), state of residency and other criteria. Rates and terms may vary and are subject to change without notice.';
export const CALCULATOR_SMALL_PRINT = `${RATE_DISCLAIMER} Credit score can vary depending on the credit reporting agency. Payment example: A $76,000 loan for the purchase of a recreational use vessel for 20 years with a fixed rate of 5.49% would have an estimated APR of 5.49% and 240 payments of $522.37.`;

export const HERO_BACKGROUND = '/calculator-hero.png';

export const META_TITLE = 'Boat Loan Calculator - Boat Trader';
export const META_DESCRIPTION = 'Thinking about purchasing a new or used boat? Just enter in your loan amount and terms into Boat Trader’s boat loan calculator to estimate your monthly payment.';

export const HERO_TITLE = 'Boat Loan Calculator';
export const HERO_TEXT = 'Ready to buy a boat? Use our easy boat loan calculator to estimate your monthly payments or calculate your total loan amount.';

export const LOAN_PROCESS_LOGO_ALT_TEXT = 'BoatTrader and Trident Funding';
export const LOAN_PROCESS_TITLE = 'How Boat Trader Boat Loans Work';
export const LOAN_PROCESS_TEXT = 'Trident Funding is Boat Trader’s preferred partner for boat financing. We work with the top marine lenders to find you the boat financing that fits your lifestyle and budget.';
export const LOAN_PROCESS_BULLET_TITLE_1 = 'Estimate Your Boat Loan Payment';
export const LOAN_PROCESS_BULLET_TITLE_2 = 'Apply for a Loan';
export const LOAN_PROCESS_BULLET_TITLE_3 = 'Get on the Water!';
export const LOAN_PROCESS_BULLET_TEXT_1 = 'Ready to buy that new or used boat? Use Boat Trader’s boat loan calculator above to understand how much boat you can afford. Enter your total boat loan amount and loan terms to estimate your monthly payments or provide your desired monthly payment to get the total loan amount you can afford or provide.';
export const LOAN_PROCESS_BULLET_TEXT_2 = 'Now you’re ready to submit your boat loan application! Just answer a few questions about the type of boat you’re buying and some details about your financial health and we’ll match you with the marine lender that’s right for you.';
export const LOAN_PROCESS_BULLET_TEXT_3 = 'After you submit your loan application, the dedicated Trident Funding team of loan officers will get to work! You can expect a response in 24-48 hours with a loan decision or a request for additional information. Once your loan is funded, it’s time to get on the water!';

export const ACCORDION_MAIN_TITLE = 'Frequently Asked Questions';
export const ACCORDION_ITEM_1_TITLE = 'What is the average boat payment?';
export const ACCORDION_ITEM_1_TEXT_P1 = 'Every borrower is different and their credit score and income will affect the boat payment. The interest rate, the boat term, and the purchase price of the boat will also affect the amount of the monthly payment. If you want to roughly estimate what your boat payment will be, calculate 1 to 1.5% of the value of the boat. For example, if you buy a boat worth $100,000, expect to pay between $1,000 and $1,500 per month. Keep in mind, this is just a rough estimate, and you can get a better idea of your monthly payment, by using a boat loan calculator.';
export const ACCORDION_ITEM_2_TITLE = 'How much boat can I afford?';
export const ACCORDION_ITEM_2_TEXT_P1 = 'The amount of boat that you can afford depends on a number of factors. It will depend on your income, your credit score, and your liabilities. You may qualify for a million dollar boat solely based on your income, but when you factor in your monthly expenses, that number may change drastically.';
export const ACCORDION_ITEM_2_TEXT_P2 = 'The lender will take a look at your overall financial profile including your debt-to-income ratio to ensure that you can afford to purchase the boat and keep up with the monthly payments. You can check out a boat loan calculator to get an idea of how much boat you can afford. The calculator is a great place to start when you’re boat shopping, but don’t forget about additional expenses such as boat insurance, marina fees, and ongoing maintenance costs.';
export const ACCORDION_ITEM_3_TITLE = 'What is the typical down payment on a boat loan?';
export const ACCORDION_ITEM_3_TEXT_P1 = 'The amount of the down payment on a boat loan varies. It’s usually anywhere from 10 percent to more than 30 percent. It depends on the borrower’s credit score, income, and overall financial profile. It also depends on the loan amount and the loan term.';
export const ACCORDION_ITEM_3_TEXT_P2 = 'Here are some estimates to give you an example. The average cost of a new boat is around $70,000. If you have excellent credit, you should expect to put down at least $7,000. If your credit is less than excellent, you may need to put down anywhere from $14,000 to more than $21,000. This is just the amount needed for the downpayment and doesn’t include closing costs, insurance, etc.';
export const ACCORDION_ITEM_4_TITLE = 'How long does it take to get approved for my boat loan?';
export const ACCORDION_ITEM_4_ANCHOR_TEXT = 'submit your application';
export const ACCORDION_ITEM_4_HTML = `After you <a href="${TRIDENT_APP_URL_FRM.BoatLoansCalculator}" target="_blank" data-reporting-event-category="loan calculation" data-reporting-event-action="Apply Now - click" data-reporting-event-label="Apply Now">${ACCORDION_ITEM_4_ANCHOR_TEXT}</a> (online is fastest!) and your Trident Funding loan officer has all the required financial documents, they usually have a decision in 24-48 hours, often less.`;

export const MONTHLY_PAYMENT_CALCULATOR = 'monthlyPaymentCalculator';
export const LOAN_AMOUNT_CALCULATOR = 'loanAmountCalculator';
export const CTA_APPLY_NOW_LINK_ROOT = '/boat-loans/apply/?purpose=Boat&source=101458&utm_source=boattrader&utm_medium=internalreferral';
export const CTA_PREQUAL_LINK_ROOT = '/boat-loans/apply/pre-qualify/purchase-timeframe/?source=101458&utm_source=boattrader&utm_medium=internalreferral';


// CALCULATOR PAGE V2
export const HERO_TITLE_V2 = 'Boat Loan Calculator';
export const HERO_TEXT_V2 = 'Estimate your monthly payment with our Boat Loan Calculator';

export const LOAN_PROCESS_TEXT_P1_V2 = 'Whether you’re still just crunching numbers or are ready to make a purchase, Boat Trader’s boat loan calculator tells you everything you need to know. Use the calculator to determine how much a specific boat will cost you monthly, or work backward to calculate how much boat you can afford based on your budget. You can also see how different down payments and interest rates will affect your monthly payment.';
export const LOAN_PROCESS_TEXT_P2_V2 = 'Use Boat Trader’s convenient Boat <a href="/price-checker/" target="_blank" rel="noreferrer">Price Checker tool</a> to make sure you’re not overpaying as you shop. And when you’re ready to apply, Boat Trader’s easy online <a href="/boat-loans/" target="_blank" rel="noreferrer">boat loan application</a> puts multiple trusted lenders at your fingertips.';

export const ACCORDION_MAIN_TITLE_V2 = 'Frequently Asked Questions';
export const ACCORDION_ITEM_1_TITLE_V2 = 'How does the Boat Loan Calculator work?';
export const ACCORDION_ITEM_1_TEXT_P1_V2 = 'Our boat loan calculator makes it quick and easy to estimate your monthly boat loan payments just by entering some key information. Simply plug in your boat’s purchase price and down payment amount, then enter a loan repayment term in months or years to see how much you’ll pay monthly based on the interest rate shown.';
export const ACCORDION_ITEM_1_TEXT_P2_V2 = 'Want to see how much your boat loan will cost you in total? Just enter your desired monthly payment, loan term, and APR to get an accurate overall boat loan amount.';
export const ACCORDION_ITEM_2_TITLE_V2 = 'What are the benefits of using the Boat Loan Calculator?';
export const ACCORDION_ITEM_2_TEXT_P1_V2 = 'Our easy boat loan calculator helps you make informed buying decisions and saves you time when shopping for a new or used boat. Just enter a little information to get quick and clear estimates and decide how much boat you can actually afford. Want to make sure your boat purchase fits your budget? Interested in how much your boat will cost you in the end? Our calculator does both!';
export const ACCORDION_ITEM_3_TITLE_V2 = 'How does the APR impact my payments?';
export const ACCORDION_ITEM_3_TEXT_P1_V2 = 'Boat loan interest rates (expressed as APR) may vary based on market rates, loan amount, down payment amount, and your credit history. The higher your boat loan APR, the more you’ll pay monthly and over the course of your repayment.';
export const ACCORDION_ITEM_3_TEXT_P2_V2 = 'For example, say you’re buying an $80,000 boat with a $10,000 down payment and 120-month repayment term. Qualifying for a 7% APR would give you a monthly payment of $813, but a 9.5% APR would mean a monthly payment of $906 for the same period of time.';
export const ACCORDION_ITEM_4_TITLE_V2 = 'How much boat can I afford?';
export const ACCORDION_ITEM_4_TEXT_P1_V2 = 'When budgeting for a boat, be sure to account for your monthly payment as well as any additional boat expenses that might arise (like insurance, repairs, and maintenance). A boat loan calculator can help you determine affordability, even allowing you to work backward to find a purchase price and repayment terms that give you the monthly payment you need.';
export const ACCORDION_ITEM_5_TITLE_V2 = 'What is the typical down payment on a boat loan?';
export const ACCORDION_ITEM_5_TEXT_P1_V2 = 'Typical boat loan down payments range from 10 percent to 30 percent, with some loans even allowing for zero down payment for borrowers who qualify. The most common down payment on a boat loan is 10 percent.The more you put down on your boat, the less your monthly payment and total boat cost will be.';
export const ACCORDION_ITEM_6_TITLE_V2 = 'What is the average boat loan payment?';
export const ACCORDION_ITEM_6_TEXT_P1_V2 = 'Boat loan payments are affected by everything from purchase price, down payment amount, and interest rate to the borrower’s income and credit score… so they can vary widely. A rough estimate is 1% to 1.5% of the purchase price, but using a boat loan calculator is the best way to get your estimated boat loan monthly payment.';
export const ACCORDION_ITEM_7_TITLE_V2 = 'What factors influence boat loan interest rates?';
export const ACCORDION_ITEM_7_TEXT_P1_V2 = 'Your boat loan’s interest rate, expressed as APR, is influenced by a number of factors including how much you borrow, your chosen repayment term, and your credit score. This APR determines how much your loan will cost you, so lowering your interest rate — either by offering a larger down payment, improving your credit score, or choosing a shorter loan term — can save you money.';
export const ACCORDION_ITEM_8_TITLE_V2 = 'What is the cost of owning a boat?';
export const ACCORDION_ITEM_8_TEXT_P1_V2 = 'Your boat loan monthly payment isn’t the only expense to keep in mind when budgeting. You should also plan for additional costs such as boat insurance, maintenance, repairs, and storage. These can easily add hundreds or even thousands of dollars a year to your total boat ownership costs. Be sure to set aside enough to cover both the expected and unexpected when budgeting for your next boat.';
export const ACCORDION_ITEM_9_TITLE_V2 = 'Are there different types of boat loans?';
export const ACCORDION_ITEM_9_TEXT_P1_V2 = 'There are a few different types of boat loans to choose from depending on your needs and the lender you pick. Fixed-rate loans offer a set interest rate and amortization schedule over the duration of the loan term, while variable-rate loans have an APR that can fluctuate over time.';
export const ACCORDION_ITEM_9_TEXT_P2_V2 = 'Not sure which is best? Boat Trader makes it easy to shop and compare terms from over 15 marine lenders and get the boat loan that’s right for you.';

export const TRUST_PILOT_TITLE = 'Over 100,000 approved boat loans and counting';
export const TRUST_PILOT_TEXT = 'Read what customers have to say about our services, transparency, and customer service.';
export const TRUST_PILOT_TEXT_MOBILE = 'Read what customers have to say about our services, transparency, and customer service with Trust pilot star rating to match pre-qual splash page.';



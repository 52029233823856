import React from 'react';
import get from 'lodash/get';
import './styles.css';
import Article from '../Article';

const ArticlesAndReviews = ({ posts }) => {
  return (
    <section id="recent-articles-review">
      <h1 data-e2e="recent-articles-and-reviews">Recent Articles and Reviews</h1>
      <div className="articles" data-e2e="articles">
        {
          posts.map((article, index) => {
            const formattedLink = get(article, 'link', '').replace(/http:|https:/gi, '');
            const category = get(article, 'category[0]', '');
            return (
              <Article
                key={ index }
                title={ article.title }
                link={ formattedLink }
                category={ category }
                description={ article.description }
                blogId={ article.blogId }
                image={ article.image }
              />
            );
          })
        }
      </div>
      <div>
        <a className="see-more-articles" data-e2e="see-more-articles" href="/research/">See more articles</a>
      </div>
    </section>
  );

};

export default ArticlesAndReviews;

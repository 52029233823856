import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import TabForm from '../TabForm';
import { buildDropdownList } from './searchHelper';
import {
  BOATS_ID,
  ENGINES_ID,
  BOATS_FOR_SALE,
  ENGINES_FOR_SALE,
  INITIAL_EMPTY_FACETS,
  allBoatsFacet,
  alltEnginesFacet
} from '../../../constants/home';
import { getFacets, getEnginesFacets } from '../../../store/actions';
import get from 'lodash/get';

import './styles.css';

const CDN_URL = process.env.REACT_APP_CDN_URL;

const SearchSubmission = ({ zipcode, defaultTab, radiusMiles }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [facetsData, setFacetsData] = useState(allBoatsFacet);
  const [enginesFacetsData, setEnginesFacetsData] = useState(alltEnginesFacet);
  const [ sharedZipCode, setSharedzipCode ] = useState(zipcode);
  const dispatch = useDispatch();
  const facets = useSelector((state) => get(state.app, 'data.facets', INITIAL_EMPTY_FACETS));

  useEffect(() => {
    if (activeTab === BOATS_ID) {
      setFacetsData(buildDropdownList(facets, BOATS_ID));
    } else {
      setEnginesFacetsData(buildDropdownList(facets, ENGINES_ID));
    }
  }, [facets]); // eslint-disable-line react-hooks/exhaustive-deps

  const setActive = (tab) => {
    setActiveTab(tab);
  };

  const handleBoatsTabClick = () => {
    if (activeTab === ENGINES_ID) {
      setActive(BOATS_ID);
      dispatch(getFacets());
    }
  };

  const handleEnginesTabClick = () => {
    if (activeTab === BOATS_ID) {
      setActive(ENGINES_ID);
      dispatch(getEnginesFacets());
    }
  };

  return (
    <>
      <div className="tabs-container">
        <ul className="tabs">
          <li onClick={ handleBoatsTabClick } data-e2e="hp-search-boats-tab">
            <a href="#" title={BOATS_FOR_SALE} className={classnames(BOATS_ID, { 'active': activeTab === BOATS_ID })}>
              <img alt="search boats tab" src={`${CDN_URL}/img/icons/icon-qsearch-tabs-boat.png`} /><span>BOATS</span>
            </a>
          </li>
          <li onClick={ handleEnginesTabClick } data-e2e="hp-search-engines-tab">
            <a href="#" title={ENGINES_FOR_SALE} className={classnames(ENGINES_ID, { 'active': activeTab === ENGINES_ID })}>
              <img alt="search engines tab" src={`${CDN_URL}/img/icons/icon-qsearch-tabs-engine.png`} /><span>ENGINES</span>
            </a>
          </li>
        </ul>
      </div>
      <div className={classnames('search-window', { 'search-window-engine': activeTab === ENGINES_ID })} data-e2e={activeTab === ENGINES_ID ? 'search-engines' : 'search-boats'}>
        <TabForm
          heading={BOATS_FOR_SALE}
          activeTab={activeTab}
          formAttributes={
            {
              id: 'quickSearch',
              name: 'quickSearch',
              tabIndex: '5'
            }
          }
          locationEntryId={'boatSet'}
          productTypes={facetsData.productTypes}
          productMakes={facetsData.productMakes}
          zip={sharedZipCode}
          onZipCodeChange={setSharedzipCode}
          radiusMiles={radiusMiles}
        />
        <TabForm
          heading={ENGINES_FOR_SALE}
          activeTab={activeTab}
          formAttributes={
            {
              id: 'quickSearchEngine',
              name: 'quickSearch',
              tabIndex: '-1'
            }
          }
          locationEntryId={'engineSet'}
          productTypes={enginesFacetsData.productTypes}
          productMakes={enginesFacetsData.productMakes}
          zip={sharedZipCode}
          onZipCodeChange={setSharedzipCode}
        />
      </div>
    </>
  );
};

export default SearchSubmission;

import React, {PureComponent} from 'react';
import classnames from 'classnames';
import LoadingPlaceholder from '../LoadingPlaceholder';
import './styles.css';

export default class DataTable extends PureComponent {


  renderTitledTable(data) {
    return (
      data.map((category, index) => {
        return (
          <div className="datatable-category" key={index}>
            <h3 className="sub-title">{category.title}</h3>
            { this.renderDataItems(category.value) }
          </div>
        );
      }
      )
    );
  }

  renderDataItems(data) {
    return (
      <div className={classnames('datatable-section', `datatable-items-${data.length}`)}>
        { data.map((item, index) => {
          const value = item.value === true ? '\u2713' : item.value;
          return (
            <div className="datatable-item" key={index}>
              <div className="datatable-title">{item.title}</div>
              <div className="datatable-value">{value}</div>
            </div>
          );
        }) }
      </div>

    );
  }

  renderPriceItems(data, priceQualifier) {
    return (
      <>
        <div className="datatable-left-section">
          <div className={classnames('datatable-section', `datatable-items-${data.length}`)}>
            { data.map((item, index) => {
              return (
                <div className="datatable-item" key={index}>
                  { item.subtitle
                    ? <div className={classnames('datatable-title', 'subtitle')}>{item.subtitle}</div>
                    : <div className="datatable-title"><strong>{item.title}</strong></div>
                  }
                  <div className={classnames('datatable-value', {'datatable-value-bold': item.title === 'Your Price'})}>
                    {item.value}
                  </div>
                </div>
              );
            }) }
          </div>
        </div>
        {priceQualifier && (<div className="datatable-right-section">
          <div className={classnames('datatable-section', `datatable-items-${1}`)}>
            <div className="datatable-item">
              <div className="datatable-title"><strong>Additional Info:</strong></div>
              <div className="datatable-value">{priceQualifier}</div>
            </div>
          </div>
        </div>)}
      </>
    );
  }

  // Checks for subtitle property in item and returns a boolean result
  hasSubtitle = (data) => data.some((item) => item.subtitle);

  renderData(data, priceQualifier) {
    if (Array.isArray(data[0].value)) {
      return this.renderTitledTable(data);
    } else if (this.hasSubtitle(data)) {
      return this.renderPriceItems(data, priceQualifier);
    }
    return this.renderDataItems(data);

  }

  renderLoading() {
    return (
      <>
        <div className="item">
          <LoadingPlaceholder type="text" />
        </div>
        <div className="item">
          <LoadingPlaceholder type="text" />
        </div>
      </>
    );
  }

  render() {
    const { data, dataName, isLoading, size, priceQualifier } = this.props;
    return (
      <div className={classnames('detail-data-table', {[dataName]: dataName, [size]: size })}>
        {isLoading || data.length === 0 ?
          this.renderLoading()
          :
          this.renderData(data, priceQualifier)}
      </div>
    );
  }
}

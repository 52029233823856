import React from 'react';
import './styles.css';
import { CDN_URL } from '../../../../constants/BoatLoans';

const Card = ({ title, content, svgImage, imgAlt }) => {
  return (
    <div className="tool-card">
      <img className="card-svg" src={`${CDN_URL}/${svgImage}`} alt={imgAlt} />
      <div className="content col">
        <h3>{title}</h3>
        <div>{content}</div>
      </div>
    </div>
  );
};

export const GuideToolsSection = () => {
  return (
    <div className="guide-tools-sections col">
      <h2>Tools to Help Guide Your Loan Process</h2>
      <div className="content">
        <Card
          title="Boat Loan Rates"
          content={<>Check current boat loan rates to estimate your monthly payments and interest rates with our <a href="/boat-loans/calculator/">boat loan estimator.</a></>}
          svgImage="boat-get-prequalied.svg"
          imgAlt="Boat Loan Rates"
        />
        <Card
          title="Payment Calculator"
          content={<>Use our easy <a href="/boat-loans/calculator/">boat loan calculator</a> to estimate your monthly payments or calculate your total loan amount to see how much boat you can afford.</>}
          svgImage="calculator.svg"
          imgAlt="Payment Calculator"
        />
      </div>
    </div>
  );
};

import { allTypes } from '../constants/boats';
import get from 'lodash/get';
import classPageClasses from './data/classPageClasses.json';
import {normalizeString} from '@dmm/lib-common/lib/formatting';

export const DEFAULT_POWER = 'power-all';
export const DEFAULT_SAIL = 'sail-all';
export const DEFAULT_PWC = 'power-pwc';
export const DEFAULT_SMALL = 'small-all';

export const smallBoatClasses = [
  'power-dinghy',
  'sail-dinghy',
  'unpowered-dinghy',
  'unpowered-narrow',
  'power-narrow',
  'unpowered-kayak',
  'power-inflatable',
  'unpowered-inflatable',
  'power-rib',
  'power-tender',
  'unpowered-tender',
  'unpowered-other'
];

export const legacyMappings = {
  'aft-cabin': ['power-aft'],
  'aft+cabin': ['power-aft'],
  'airboat': ['power-airboat'],
  'aluminum+fish+boats': ['power-aluminum'],
  'aluminum-fish-boats': ['power-aluminum'],
  'angler': ['power-saltfish'],
  'bass+boat': ['power-bass'],
  'bass-boat': ['power-bass'],
  'bay+boat': ['power-bay'],
  'bay-boat': ['power-bay'],
  'bluewater+fishing': ['power-saltfish'],
  'bowrider': ['power-bowrider'],
  'canoe-kayak': ['unpowered-kayak'],
  'catamaran+(power)': ['power-pcatamaran'],
  'catamaran-power': ['power-pcatamaran'],
  'center+console': ['power-center'],
  'center-console': ['power-center'],
  'classic+(power)': ['power-antique'],
  'commercial': ['power-commercial'],
  'commercial-vessels': ['power-commercial'],
  'convertible': ['power-convertible'],
  'cruiser+(power)': ['power-cruiser'],
  'cruiser-(power)': ['power-cruiser'],
  'cruiser-power': ['power-cruiser'],
  'cuddy+cabin': ['power-cuddy'],
  'cuddy-cabin': ['power-cuddy'],
  'deck+boat': ['power-deck'],
  'deck-boat': ['power-deck'],
  'dive+boat': ['power-dive'],
  'dive-boat': ['power-dive'],
  'downeast': ['power-downeast'],
  'dual+console': ['power-dualconsole'],
  'dual-console': ['power-dualconsole'],
  'duck+boat': ['power-jon'],
  'duck-boat': ['power-jon'],
  'express+cruiser': ['power-expresscruiser'],
  'express-cruiser': ['power-expresscruiser'],
  'fish+and+ski': ['power-skifish'],
  'fish-and-ski': ['power-skifish'],
  'flats+boat': ['power-flats'],
  'flats-boat': ['power-flats'],
  'flybridge': ['power-flybridge'],
  'freshwater+fishing': ['power-fresh'],
  'freshwater-fishing-boats': ['power-fresh'],
  'high+performance': ['power-highperf'],
  'high-performance-boats': ['power-highperf'],
  'houseboat': ['power-house'],
  'houseboats': ['power-house'],
  'jet+boat': ['power-jet'],
  'jet-boat': ['power-jet'],
  'jon+boat': ['power-jon'],
  'jon-boat': ['power-jon'],
  'mega+yacht': ['power-mega'],
  'motoryacht': ['power-motor'],
  'motor-yachts': ['power-motor'],
  'other': [
    'power-other',
    'sail-other',
    'unpowered-other'
  ],
  'pilothouse': ['power-pilot'],
  'pontoon': ['power-pontoon'],
  'pontoon-deck-boats': ['power-pontoon'],
  'runabout': ['power-runabout'],
  'saltwater-fishing': ['power-saltfish'],
  'saltwater-fishing-boats': ['power-saltfish'],
  'saltwater+fishing': ['power-saltfish'],
  'ski+and+wakeboard-boat': ['power-skiwake'],
  'ski-and-wakeboard-boat': ['power-skiwake'],
  'ski+and+wakeboard+boat': ['power-skiwake'],
  'ski-wakeboard-boats': ['power-skiwake'],
  'skiff': ['power-skiff'],
  'sport+fisherman': ['power-sportfish'],
  'sport-fisherman': ['power-sportfish'],
  'submersible': ['power-commercial'],
  'trawler': ['power-trawler'],
  'walkaround': ['power-walk'],
  'weekender': ['power-cruiser'],
  'catamaran+(sail)': ['sail-catamaran'],
  'classic+(sail)': ['sail-antique'],
  'classic-(sail)': ['sail-antique'],
  'cruiser+(sail)': ['sail-cruiser'],
  'cruiser-(sail)': ['sail-cruiser'],
  'cruiser/racer': ['sail-racercruiser'],
  'cutter': ['sail-cutter'],
  'daysailor/weekender': ['sail-day'],
  'ketch': ['sail-ketch'],
  'motorsailer': ['sail-motor'],
  'multi+hull': [
    'sail-multihull',
    'sail-trimaran'
  ],
  'multi-hull': [
    'sail-multihull',
    'sail-trimaran'
  ],
  'racer': ['sail-racer'],
  'sloop': ['sail-sloop'],
  'yawl': ['sail-yawl'],
  'canoe/kayak': ['unpowered-kayak'],
  'inflatables': ['power-inflatable'],
  'inflatable-rib-boats': ['power-inflatable'],
  'rigid+inflatable': ['power-rib'],
  'rigid-inflatable': ['power-rib'],
  'tenders/small+boats': [
    'power-dinghy',
    'sail-dinghy',
    'unpowered-dinghy',
    'unpowered-narrow',
    'power-narrow',
    'power-tender',
    'unpowered-tender'
  ],
  'tenders-small-boats': ['power-tender'],
  'pwc-jet-boats': ['power-pwc']
};

export const legacyBrowseTypes =  {
  'sailboats': {
    'sail': []
  },
  'small-boats': {
    'small': []
  },
  'other': {
    'small': []
  }
};

export const pwcClasses = [
  'power-pwc'
];

export const handleLegacyType = (type) => {
  if (type === 'small+boats') {
    return 'small';
  }
  return type;
};

export const handleLegacyAllType = (type,category) => {
  if (category === 'all' || category === 'other') {
    switch (type) {
    case 'power': return `power-${category}`;
    case 'sail': return `sail-${category}`;
    case 'pwc': return 'power-pwc';
    case 'small': return `small-${category}`;
    }
  }
  return category;
};

export const movePWCToPower = (multiFacetedBoatTypeClass) => {
  if (multiFacetedBoatTypeClass.pwc) {
    let power = get(multiFacetedBoatTypeClass, 'power', []);
    multiFacetedBoatTypeClass.power = [...power, ...multiFacetedBoatTypeClass.pwc];
    delete multiFacetedBoatTypeClass.pwc;
  }
  return multiFacetedBoatTypeClass;
};

export const mapAllType = (classArr) => {
  if (classArr.some( cl => allTypes.includes(cl))) {
    return [];
  }
  return classArr;
};

export const mapAllClassForType = (multiFacetedBoatTypeClass) => {
  if (multiFacetedBoatTypeClass.power) {
    multiFacetedBoatTypeClass.power = mapAllType(multiFacetedBoatTypeClass.power);
  }
  if (multiFacetedBoatTypeClass.sail) {
    multiFacetedBoatTypeClass.sail = mapAllType(multiFacetedBoatTypeClass.sail);
  }
  return multiFacetedBoatTypeClass;
};

export const mapSmallBoatsToAPI = (multiFacetedBoatTypeClass) => {
  let smallBoats = multiFacetedBoatTypeClass.small;
  let mappedSmallBoats = {};
  if (smallBoats) {

    let allPower = multiFacetedBoatTypeClass.power && multiFacetedBoatTypeClass.power.includes('power-all');
    let allSail = multiFacetedBoatTypeClass.sail && multiFacetedBoatTypeClass.sail.includes('sail-all');
    let powerBoats = [];
    let sailBoats = [];
    let unpoweredBoats = [];

    if (smallBoats.includes('small-all')) {
      smallBoats = smallBoatClasses;
    }

    smallBoats.map( smallBoat => {
      if (smallBoat.split('-')[0] === 'power') {
        powerBoats.push(smallBoat);
      } else if (smallBoat.split('-')[0] === 'sail') {
        sailBoats.push(smallBoat);
      } else {
        unpoweredBoats.push(smallBoat);
      }
    });
    if (powerBoats.length  && !allPower) {
      mappedSmallBoats.power = powerBoats;
    }
    if (sailBoats.length  && !allSail) {
      mappedSmallBoats.sail = sailBoats;
    }
    if (unpoweredBoats.length) {
      mappedSmallBoats.unpowered = unpoweredBoats;
    }
  }
  return mappedSmallBoats;
};

export const getClassCategory = (boatClass) => {
  if (smallBoatClasses.includes(boatClass)) {
    return ('small');
  } else if (pwcClasses.includes(boatClass)) {
    return ('pwc');
  }
  return boatClass.split('-')[0];

};

export const checkNeedsDefaults = (types) => {
  if (types.power) {
    if (types.power.length === 0) {
      types.power.push(DEFAULT_POWER);
    }
  }
  if (types.sail) {
    if (types.sail.length === 0) {
      types.sail.push(DEFAULT_SAIL);
    }
  }
  if (types.pwc) {
    if (types.pwc.length === 0) {
      types.pwc.push(DEFAULT_PWC);
    }
  }
  if (types.small) {
    if (types.small.length === 0) {
      types.small.push(DEFAULT_SMALL);
    }
  }
  return types;
};

// legacyMappingsForAds is constructed from legacyMappings and then has 'exceptions' fixed
// since this is dependent on the order that legacyMappings entries are in it a fixe set of mappings should be considered for the future

const legacyMappingsForAds = {};

for (let legacyClass in legacyMappings) {
  legacyMappings[legacyClass].forEach(boatClass => {
    legacyMappingsForAds[boatClass] = legacyClass;
  });
}

pwcClasses.forEach(boatClass => {
  legacyMappingsForAds[boatClass] = 'pwc';
});

smallBoatClasses.forEach(boatClass => {
  legacyMappingsForAds[boatClass] = 'small+boats';
});

legacyMappingsForAds['power-cruiser'] = 'cruiser';



export const getLegacyMappingFromBoatClass = boatClass => get(legacyMappingsForAds, boatClass, boatClass);

export const isBTClass = (boatClass) => {
  return classPageClasses.some(item => normalizeString(item) === boatClass);
};

import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import max from 'lodash/max';
import omit from 'lodash/omit';
import { slugify, unslugify } from './urlHelpers/listings';

export const toggleSubFacet = (facetName, subFacet, selectedFacets, allowEmptySubFacet) => {
  let currentSubFacets = get(selectedFacets, facetName, []);
  let newSubFacets;
  if (includes(currentSubFacets, subFacet)){
    newSubFacets = filter(currentSubFacets, facetItem => facetItem !== subFacet);
  } else {
    newSubFacets = [...currentSubFacets, subFacet];
  }
  if (newSubFacets.length || allowEmptySubFacet) {
    return {
      ...selectedFacets,
      [facetName]: newSubFacets
    };
  }
  return omit(selectedFacets, facetName);
};

export const emptySubFacet = (facetName, selectedFacets) => {
  return {
    ...selectedFacets,
    [facetName]: []
  };
};

export const multiFacetUrlToObject = (string, shouldSlugify, splitter = ',', pairSplitter = ':') => {
  return string.split(splitter)
    .map(facetPair => facetPair.split(pairSplitter))
    .reduce((facets, [facetName, facetValue]) => {
      let newFacet;
      if (!facetValue) {
        newFacet = [];
      } else {
        if (shouldSlugify){
          facetValue = slugify(unslugify(facetValue));
        }
        newFacet = [...get(facets, facetName, []), facetValue];
      }
      const newFacets = {
        ...facets,
        [facetName]: newFacet
      };
      return newFacets;
    }, {});
};

export const multiFacetUrlToArray = (string, splitter = ',', pairSplitter = ':') => {
  const facets = multiFacetUrlToObject(string, false, splitter, pairSplitter);
  return Object.entries(facets);
};

export const formatMultiArrayUrlToArray = (string = '', separator = '|') => {
  return string.split(separator).map(item => item ? JSON.parse(item) : item);
};

export const isSingleFaceted = (multiFacetObject) => {
  if (isArray(multiFacetObject)) {
    return multiFacetObject.length < 2;
  }
  const facets = Object.entries(multiFacetObject);
  const facetsLength = facets.length;
  const maxSubFacetLength = max(facets.map(([, value]) => value.length)) || 0;
  return facetsLength < 2 && maxSubFacetLength < 2;
};

import React from 'react';
import { AccordionQuestion } from './AccordionQuestion';
import './styles.css';
import { FAQ_ACCORDION_CONTENT } from './faqContent';

export const FrequentlyQuestionsSection = () => {

  const faqContent = FAQ_ACCORDION_CONTENT;
  const half = Math.ceil(faqContent.length / 2);
  const firstColumn = faqContent.slice(0, half);
  const secondColumn = faqContent.slice(half);


  return (
    <div className="frequently-questions-section">
      <h2>Frequently Asked Questions About Boat Loans</h2>
      <div className="content">
        <div className="col">
          {firstColumn.map((item, i) => (
            <AccordionQuestion key={i} title={item.question}>
              <p>{item.answer}</p>
            </AccordionQuestion>
          ))}
        </div>
        <div className="col">
          {secondColumn.map((item, i) => (
            <AccordionQuestion key={i} title={item.question}>
              <p>{item.answer}</p>
            </AccordionQuestion>
          ))}
        </div>
      </div>
    </div>
  );
};

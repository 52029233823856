import React from 'react';
import SellerInformation from '../SellerInformation';
import ContactForm from '../ContactForm';
import './styles.css';

export const PLACEHOLDER_BY_SIZE = {
  mobile: {
    name: 'Name',
    email: 'Your Email',
    phone: 'Optional',
    zip: 'ZIP Code',
  },
  tablet: {
    name: 'Your Name',
    email: 'Your Email',
    phone: 'Your Phone',
    zip: 'Your ZIP'
  },
  desktop: {
    name: 'Your Name',
    email: 'Your Email',
    phone: 'Your Phone',
    zip: 'Your ZIP'
  }
};
export const PROPERTIES_BY_TYPE_AND_SIZE = {
  oem: {
    mobile: {
      title: 'Contact the Manufacturer',
      showTitleSeparator: true,
      centeredTitle: true,
      emailLead: true
    },
    desktop: {
      title: 'Contact the Manufacturer',
      subtitle: 'The manufacturer will reply to your request as soon as possible',
      emailLead: true,
    }
  },
  dealer: {
    mobile: {
      title: 'Contact the Dealer',
      showTitleSeparator: true
    },
    desktop: {
      title: 'Contact the Dealer',
      showTitleSeparator: true
    }
  }
};

const OemContactForm = props => {
  const {
    id,
    breakpoint,
    contact,
    partyId,
    prePopulatedText,
    privacyPolicyUrl,
    proximity,
    onSuccess,
    termsOfUseUrl,
    type,
    zipCode,
    oemDetails,
    listingId,
    isOem,
  } = props;

  return <div className="contact-broker" data-e2e="contact-broker">
    {contact && <SellerInformation contact={contact}
      proximity={proximity}
      zipCode={zipCode}
      id="oem-dealer-info"
      data-e2e="oem-dealer-info"
      reverse shouldOpenLocation/>}
    <ContactForm
      id={id}
      data-e2e={id}
      listingContact={contact}
      open={true}
      partyId={partyId}
      prePopulatedText={prePopulatedText}
      orderInputs={['name', 'phone', 'email', 'zip', 'comments', 'firstName']}
      labelText={{
        name: 'Name',
        email: 'Email',
        phone: 'Phone (Optional)',
        zip: 'ZIP Code',
        comments: 'Message'
      }}
      onSuccess={onSuccess}
      termsOfUseUrl={termsOfUseUrl}
      privacyPolicyUrl={privacyPolicyUrl}
      placeholder={PLACEHOLDER_BY_SIZE[breakpoint]}
      {...PROPERTIES_BY_TYPE_AND_SIZE[type][breakpoint]}
      showZipCode
      onlyShowError
      isOemDealer={true}
      copyOem={isOem && oemDetails.oemDealerLocator && oemDetails.emailManufacturer}
      oemId={isOem && oemDetails.ownerId}
      brandId={isOem && oemDetails.brandId}
      listingId={listingId}
      listing={props.listing}
      cta={props.cta}
      contactSubmitTrack={true}
    />
  </div>;
};

export default OemContactForm;

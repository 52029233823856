import React, { useState } from 'react';
import { FinancingContentSectionOne, FinancingContentSectionTwo } from './FinancingContentSections';
import { CDN_URL } from '../../../../../constants/BoatLoans';
import '../styles.css';

export const FINANCING_CONTENT = [
  'How to Qualify',
  'What You Need to Apply'
];

export const BFTabList = () => {
  const [checkedTab, setCheckedTab] = useState(1);

  return (
    <>
      <input data-testid="input1" className="bf-tablist-tab-1" type="radio" name="tabset" id="tab1" aria-controls="financing-tab-1" checked={checkedTab === 1} onClick={() => setCheckedTab(1)} />
      <label htmlFor="tab1" className="bf-tab1-label">{FINANCING_CONTENT[0]}</label>

      <input data-testid="input2" className="bf-tablist-tab-2" type="radio" name="tabset" id="tab2" aria-controls="financing-tab-2" checked={checkedTab === 2} onClick={() => setCheckedTab(2)}/>
      <label htmlFor="tab2" className="bf-tab2-label">{FINANCING_CONTENT[1]}</label>
    </>
  );
};

export const BFTabContent = () => {
  return (
    <div className="boat-loans-tab-panels">

      <section id="financing-tab-1" className="boat-loans-tab-panel">
        <div className="tab-content-with-img-container">
          <div className="tab-content-with-img-text">
            <FinancingContentSectionOne />
          </div>
          <img src={`${CDN_URL}/iphone-credit-score.webp`} alt="iphone showing credit score" className="iphone-credit-score" />
        </div>
      </section>

      <section id="financing-tab-2" className="boat-loans-tab-panel">
        <FinancingContentSectionTwo />
      </section>

    </div>
  );
};

export const DesktopTabView = () => {
  return (
    <div className="tabset financing-desktop-view">
      <BFTabList />
      <BFTabContent />
    </div>
  );
};

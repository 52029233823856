import { HULL_TYPE_FIBERGLASS_REINFORCED_NEW, HULL_TYPE_WOOD_NEW } from '../constants/boats/';

export const showSeoContent = (url, params) => {
  params.wordsmithContentType = showWordsmithContent(url);
  params.relatedBoatArticles = showRelatedBoatArticles(url);
  params.seoContentType = showWordpressContent(url);
  params.similarBoats = showSimilarBoats(url);
  params.videoType = showVideos(url);
  params.secondaryLocalities = showSecondaryLocalities(url);
  return params;
};

const showVideos = (url) => {
  const splittedURLParams = convertUrlToParams(url);
  if (splittedURLParams) {
    if (isSingleTypeFilter(splittedURLParams)) {
      return 'type';
    }
    if (isSingleClassFilter(splittedURLParams)) {
      return 'class';
    }
    if (isSingleMakeFilter(splittedURLParams)) {
      return 'make';
    }
    if (isSingleModelFilter(splittedURLParams)) {
      return 'model';
    }
    if (isSingleGroupFilter(splittedURLParams)) {
      return 'group';
    }
  }
  return undefined;
};

const showWordsmithContent = (url) => {
  const splittedURLParams = convertUrlToParams(url);
  if (splittedURLParams) {
    if (isSingleClassFilter(splittedURLParams)) {
      return 'class';
    }
    if (isSingleMakeFilter(splittedURLParams)) {
      return 'make';
    }
    if (isSingleStateFilter(splittedURLParams)) {
      return 'state';
    }
    if (isSingleCityFilter(splittedURLParams)) {
      return 'city';
    }
    if (isSingleZipFilter(splittedURLParams)) {
      return 'zip';
    }
  }
  return undefined;
};

const showWordpressContent = (url) => {
  const splittedURLParams = convertUrlToParams(url);
  if (splittedURLParams) {
    if (isSingleFilter(splittedURLParams, 'type')) {
      if (splittedURLParams.includes('type-pwc')) {
        return 'pwc';
      }
      if (splittedURLParams.includes('type-small')) {
        return 'small';
      }
      return 'type';
    }
    if (isSingleClassFilter(splittedURLParams)) {
      return 'class';
    }
    if (isSingleMakeFilter(splittedURLParams)) {
      return 'make';
    }
    if (isSingleFilter(splittedURLParams, 'group')) {
      return 'group';
    }
    if (isSingleFilter(splittedURLParams, 'fuel')) {
      return 'fuelType';
    }
    if (!isFiberglass(splittedURLParams) && isSingleFilter(splittedURLParams, 'hull')) {
      return 'hullMaterial';
    }
  }
  return undefined;
};

const showRelatedBoatArticles = (url) => {
  const splittedURLParams = convertUrlToParams(url);
  if (splittedURLParams) {
    // "model" must be before "make"
    if (isSingleModelFilter(splittedURLParams)) {
      return 'model';
    }
    if (isSingleMakeFilter(splittedURLParams)) {
      return 'make';
    }
    if (isSingleClassFilter(splittedURLParams)) {
      return 'class';
    }
  }
  return undefined;
};

const showSimilarBoats = (url) => {
  const splittedURLParams = convertUrlToParams(url);
  if (splittedURLParams) {
    if (isSingleModelFilter(splittedURLParams)) {
      return true;
    }
  }
  return undefined;
};

const showSecondaryLocalities = (url) => {
  const splittedURLParams = convertUrlToParams(url);
  if (splittedURLParams) {
    if (isSingleZipFilter(splittedURLParams)) {
      return 'zip';
    }
  }
  return undefined;
};

const convertUrlToParams = (url) => {
  // The url is splitted by / to be able to check the exact url params
  if (url.includes('+') || url.includes(',')) { return undefined; }
  if (url.slice(url.length - 1) === '/') {
    url = url.slice(0, -1);
  }
  return url.split('/');
};

const isSingleTypeFilter = (url) => {
  // Single class url example: /boats/type-power/
  // This url is also allowed if it includes sort param/boats/type-power/sort-year-asc
  if (url.length === 3 && url[2].startsWith('type')) {
    return true;
  }
  if (url.length === 4 && url[2].startsWith('type') && url[3].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleClassFilter = (url) => {
  // Single class url example: /boats/type-power/class-power-airboat
  // This url is also allowed if it includes sort param/boats/type-power/class-power-airboat/sort-year-asc
  if (url.length === 4 && url[2].startsWith('type') && url[3].startsWith('class')) {
    return true;
  }
  if (url.length === 5 && url[2].startsWith('type') && url[3].startsWith('class') && url[4].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleMakeFilter = (url) => {
  // Single make url example: /boats/make-sea-ray
  // This url is also allowed if it includes sort param /boats/make-sea-ray/sort-year-asc
  if (url.length === 3 && url[2].startsWith('make')) {
    return true;
  }
  if (url.length === 4 && url[2].startsWith('make') && url[3].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleModelFilter = (url) => {
  // Single make url example: /boats/make-other/model-sd-187-ob/
  // This url is also allowed if it includes sort param /boats/make-other/model-sd-187-ob/sort-year-asc/
  if (url.length === 4 && url[2].startsWith('make') && url[3].startsWith('model') && !url[3].startsWith('model-range-')) {
    return true;
  }
  if (url.length === 5 && url[2].startsWith('make') && url[3].startsWith('model') && !url[3].startsWith('model-range-') && url[4].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleStateFilter = (url) => {
  // Single state url example: /boats/state-fl
  // This url is also allowed if it includes sort param /boats/state-fl/sort-year-asc
  // The single state filter is not allowed if the url includes state and dealer in the same location
  if (url.length === 3 && url[2].startsWith('state') && !url[2].startsWith('dealer')) {
    return true;
  }
  if (url.length === 4 && url[2].startsWith('state') && url[3].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleCityFilter = (url) => {
  // Single city url example: /boats/state-fl/city-miami
  // This url is also allowed if it includes sort param /boats/state-fl/city-miami/sort-year-asc
  if (url.length === 4 && url[2].startsWith('state') && url[3].startsWith('city')) {
    return true;
  }
  if (url.length === 5 && url[2].startsWith('state') && url[3].startsWith('city') && url[4].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleZipFilter = (url) => {
  // Single zip url example: /boats/state-fl/city-miami/zip-33133.
  // This url is also allowed if it includes sort param /boats/state-fl/city-miami/zip-33133/sort-year-asc
  if (url.length === 5 && url[2].startsWith('state') && url[3].startsWith('city') && url[4].startsWith('zip')) {
    return true;
  }
  if (url.length === 6 && url[2].startsWith('state') && url[3].startsWith('city') && url[4].startsWith('zip') && url[5].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleGroupFilter = (url) => {
  // Single group url example: /boats/group-fishing-boats
  // This url is also allowed if it includes sort param /boats/group-fishing-boats/sort-year-asc
  if (url.length === 3 && url[2].startsWith('group')) {
    return true;
  }
  if (url.length === 4 && url[2].startsWith('group') && url[3].startsWith('sort')) {
    return true;
  }
  return false;
};

const isSingleFilter = (url, param) => {
  // Single type url example: /boats/<filterName>-<filterValue>
  // This url is also allowed if it includes sort param/boats//<filterName>-<filterValue>/sort-year-asc
  if (url.length === 3 && url[2].startsWith(param)) {
    return true;
  }
  if (url.length === 4 && url[2].startsWith(param) && url[3].startsWith('sort')) {
    return true;
  }
  return false;
};

export const redirectOldFiberglassReinforced = () => HULL_TYPE_FIBERGLASS_REINFORCED_NEW;
export const redirectOldWood = () => HULL_TYPE_WOOD_NEW;

const isFiberglass = (url) => {
  return url.includes('hull-fiberglass');
};

export const isSingleMake = (url) => {
  return url.includes('/make') && !url.includes('/makemodel');
};

import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const ServiceChildTitle = (props) =>  {
  const gradient = 'linear-gradient(146.9deg, #119BF5 0%, #2976AA 46.35%, #104F79 79.69%, #002A45 100%)';
  const imageStyle = {
    backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/services/boat_services_bg.png), ${gradient}`
  };

  return (
    <div style={imageStyle} className="title-bg">
      <div className="title-container">
        <span className="title-text bold">Services</span>
        <span className="title-text">{props.title}</span>
      </div>
    </div>
  );
};

ServiceChildTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default ServiceChildTitle;


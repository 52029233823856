import React, { useState, useEffect, useMemo } from 'react';
import { CollapsibleContent, CollapsibleHeader, Collapsible } from '../../../components/Collapsible';
import { ToolSetOptions, ToolSetOptionsItem } from '../../../components/ToolSet';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSortByParam } from '../../../store/actions';

import { parseSortParams } from '../../../utils/urlHelpers/listings';
import { sortTypes } from './sortTypes';



const SortBy = (props) => {
  const defaultSort = 'recommended:desc';
  const [initialState] = useState(props.initialState || 'open');
  const [selectedValue, setSelectedValue] = useState(defaultSort);

  const { closeOnOuterClick, closeOnInnerClick, onDataChange, getSortByParam} = props;
  const  urlParams = get(props, 'match.params', {});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const value = urlParams.sort ? parseSortParams(urlParams.sort).sort : defaultSort;
    setSelectedValue(value);
  }, [urlParams]);

  const handleDataChange = (value) => {
    setSelectedValue(value);

    if (onDataChange){
      onDataChange(value);
    }

    getSortByParam(value);
  };

  const sortByTitle = useMemo(() => {
    return sortTypes.find(sortType => sortType.value === selectedValue)?.title;
  }, [selectedValue]);

  return (
    <CollapsibleContent
      initialState={initialState}
      closeOnOuterClick={closeOnOuterClick}
      closeOnInnerClick={closeOnInnerClick}
    >
      <CollapsibleHeader type="title">
        <span className="criteria bp3">
          <span className="text">
            <span className="ref-length-longest-first"><strong>Sort</strong><strong className="sort-separator">: </strong> <span className="sort-title">{sortByTitle}</span></span>
          </span>
        </span>
      </CollapsibleHeader>
      <Collapsible>
        <ToolSetOptions>
          {sortTypes.map((sort, index) => (
            <ToolSetOptionsItem id={sort?.id} name="sort-by" value={sort?.value} onClick={handleDataChange} selected={selectedValue === sort?.value} key={`${index} - ${sort?.value}`}>
              {sort?.title}
            </ToolSetOptionsItem>
          ))}
        </ToolSetOptions>
      </Collapsible>
    </CollapsibleContent>
  );

};


export default connect(null, dispatch => bindActionCreators({ getSortByParam }, dispatch))(SortBy);

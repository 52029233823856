import React, { useState, useEffect } from 'react';
import { LISTING_SPONSORED } from '../../../constants/boats';
import './styles.css';
import { Listing } from '@dmm/lib-react-ui-components';
import { setProductImpression, setListingClick, addLead } from '../../../store/actions/dataLayer';
import { getTitle, getPrice, getLocation, addResizeParams, getFirstListingImage } from '../../../utils/listingHelper';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { likeBoat, unlikeBoat } from '../../../store/actions';
import useBranchIoMetaTag from '../../../utils/hooks/useBranchIoMetaTag';
import { BRANCHIO_CONTENT_TYPES } from '../../../utils/branchIoEventsManager';
import { DEFAULT_NO_BOAT_IMAGE } from '../../../constants/boats';
import { calculateMonthlyPrice } from '../../../utils/trident';
import { getListingReactUrlParams } from '../../../utils/urlHelpers/boat';
import * as utils from '../../../store/utils';
import { useDeviceViewport } from '../../../utils/hooks/useDeviceViewport';

const SponsoredBoatElement = ({listing, myboats = [], tridentTeaserRate, url = '', onRequestInfo, position}) => {
  const userRef = useSelector((state) => get(state, 'app.userRef', null));
  const dispatch = useDispatch();
  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();

  const { isDesktop } = useDeviceViewport();

  const updateLikedState = ()=>setLiked(getLikedState((get(listing, 'aliases', {}))['boat-trader']));

  useEffect(() => {
    let { id } = listing;
    dispatch(setProductImpression(id, LISTING_SPONSORED, position, undefined, undefined, undefined, undefined, undefined, getTitle(listing), listing.make));
    updateLikedState();

    if (myboats?.requireLogin) {
      window.addEventListener('storage', handleLocalStorageChange);
      return () => window.removeEventListener('storage', handleLocalStorageChange);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getQueuedBoatsFromLocalStorage = () => JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];

  const [queueLikedBoats, setQueueLikedBoats] = useState([]);

  const getLikedState = (listingId) => {

    if (utils.isServer()) {
      return false;
    }
    if (myboats?.requireLogin) {
      const queuedBoats = getQueuedBoatsFromLocalStorage();
      setQueueLikedBoats(queuedBoats);
      return queuedBoats.includes(listingId);
    }
    return myboats?.listings
      ? (myboats.listings.filter(
        (boat) => { return parseInt(boat.listingId) === parseInt(listingId);}).length > 0
      )
      : false;
  };


  const handleLocalStorageChange = () => {
    const queuedBoatsFromLocalStorage = getQueuedBoatsFromLocalStorage();
    if (queueLikedBoats.length !== queuedBoatsFromLocalStorage.length) {
      updateLikedState();
    }
  };


  useEffect(() => updateLikedState(), [myboats]); // eslint-disable-line react-hooks/exhaustive-deps

  let image = getFirstListingImage(listing);

  const priceInUSD = get(listing, 'price.type.amount.USD');
  const priceDuration = priceInUSD >= 50000 ? 240 : 180;
  const monthlyPrice = calculateMonthlyPrice(tridentTeaserRate, priceDuration, priceInUSD);
  const hiddenPrice = get(listing, 'price.hidden') || !listing.price?.type?.amount || listing.price?.type?.amount.USD <= 1;
  const [liked, setLiked] = useState(false);

  const forwardToLogin = () => {
    userRef?.current.onOpenModal();
  };

  const queueLikedBoat = (listingId) => {
    const queuedBoats = JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];
    if (!queuedBoats.includes(listingId)) {
      queuedBoats.push(listingId);
      setLiked(true);
      fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.SAVE_BOAT);
    } else {
      queuedBoats.splice(queuedBoats.indexOf(listingId), 1);
      setLiked(false);
    }
    localStorage.setItem('QUEUED_LIKED_BOATS', JSON.stringify(queuedBoats));
    window.dispatchEvent(new Event('storage'));
  };

  const addBoatToMyBoats = (listingId) => {
    let data = {listingId: listingId};
    dispatch(likeBoat(data));
  };

  const removeBoatFromMyBoats = (listingId) => {
    dispatch(unlikeBoat(listingId));
  };

  const addContactForm = (type, title, subtitle, hideComments, comment, analytics = true, connectionProperties) => {
    const formProps = {
      listing, url, type,
      title, subtitle, hideComments,
      comment, analytics, connectionProperties
    };
    onRequestInfo(formProps);
  };


  const listingProps = {
    onClick: () => {
      setListingClick(listing.id, LISTING_SPONSORED, '', '', getTitle(listing), -1, listing.make);
    },
    price: getPrice(listing),
    name: getTitle(listing),
    location: getLocation(listing),
    seller: (getLocation(listing) ? getLocation(listing) + ' | ' : '') + get(listing.owner, 'name', ''),
    like: {
      onClick: (state) => {
        const liked = state === 'on';
        if (liked) {
          fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.SAVE_BOAT);
        }
        if (myboats.requireLogin) {
          queueLikedBoat(listing.aliases['boat-trader']);
          forwardToLogin();
        } else {
          if (liked) {
            addBoatToMyBoats(listing.aliases['boat-trader']);
            setLiked(true);
            dispatch(addLead(listing.aliases['boat-trader'], 'save boat'));
          } else {
            removeBoatFromMyBoats(listing.aliases['boat-trader']);
            setLiked(false);
          }
        }
      },
      state: liked ? 'on' : 'off',
      on: 'on',
      off: 'off'
    },
    image: image ? addResizeParams(get(image, 'original.url', image.url), {width: 400, height: 267}, image.date.modified, true, 'webp') : DEFAULT_NO_BOAT_IMAGE,
    imageAlt: getTitle(listing),
    contact: {
      onClick: (ev) => {
        ev.preventDefault();
        setListingClick(listing.id, LISTING_SPONSORED, '', '',  getTitle(listing), -1, listing.make);

        addContactForm('contact', 'Contact Seller');
      },
      text: 'Contact Seller'
    },
    tags: [{
      text: 'Featured'
    }],
    monthlyPrice: monthlyPrice && !hiddenPrice ? {
      text: '$' + monthlyPrice + '/mo*',
      tooltip: {
        content: `Estimated monthly payment based on a 240-month loan at ${tridentTeaserRate}% APR.`
      }
    } : undefined,
    classNames: {
      sponsored: true
    },
    logo: listing.owner?.logos?.enhanced?.replace(/^http:\/\//, 'https://'),
    logoAlt: 'Dealer Logo',
    hyperlinkAttributes: {
      href: getListingReactUrlParams(listing).href,
      'data-reporting-click-product-id': listing.id,
      'data-reporting-click-listing-type': LISTING_SPONSORED,
      ...(isDesktop && { target: '_blank' })
    }
  };

  return <li className="lib-card sponsored" data-e2e="srp-sponsored-boat" data-listing-id={listing.id} data-reporting-click-product-id={listing.id}
    data-reporting-click-listing-type={LISTING_SPONSORED} data-testid={LISTING_SPONSORED}>
    <BranchIoMetaTagComponent/>
    <Listing {...listingProps} key={`csr-${listingProps.id}-${listingProps.like.state}`}></Listing>
  </li>;
};

export default SponsoredBoatElement;

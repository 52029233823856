import React from 'react';
import {frontloadConnect} from 'react-frontload';
import {withCookies} from 'react-cookie';
import {connect} from 'react-redux';
import get from 'lodash/get';
import * as actions from '../../store/actions';
import * as utils from '../../store/utils';
import MainContent from './MainContent';
import {runOnce} from '../../utils/runOnceHelper';
import ErrorMessage from '../../components/ErrorMessage';
import DefaultLayout from '../../layouts/Default';
import queryString from 'query-string';

const BoatDealers = (props) => {
  const noAdsParam = get(queryString.parse(props.location?.search), 'noAds', null) === 'true';

  if (props?.errors) {
    return (
      <DefaultLayout {...props} pageType="BoatDealers" id={'boatDealers'} noAdsParam={noAdsParam} >
        <ErrorMessage {...props} />
      </DefaultLayout>
    );
  }
  return (
    <MainContent {...props} noAdsParam={noAdsParam} />
  );
};

const mapStateToProps = state => ({
  params: get(state.app, 'params', {}),
  count: get(state.app, 'data.count', 0),
  records: get(state.app, 'data.records', {}),
  cities: get(state.app, 'data.cities', []),
  states: get(state.app, 'data.states', []),
  brands: get(state.app, 'data.brands', []),
  zips: get(state.app, 'data.zips', []),
  topMakes: get(state.app, 'data.topMakes', []),
  allStates: get(state.app, 'data.allStates', []),
  seoMakeInfo: get(state.app, 'data.seoMakeInfo', []),
  success: state.app.success,
  errors: state.app.errors,
  message: state.app.message,
  statusCode: state.app.statusCode,
  userCountryCode: state.app.userCountryCode,
  userCountryCodeError: state.app.userCountryCodeError,
  isWorking: state.app.isWorking,
  user: state.app.user
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

/**
 * Here we manage the bootstrapping of the component both with client and server side rendering.
 * If we want to debug properly the component, we need to do client-side-render and to be able to fetch data
 * without conflicts. We must dispatch the action just once.
 *
 * The environment variable could be declared on the .env file if we want so.
 * This same approach could, probably, be applied to other containers
 */
const frontloadConnected = frontloadConnect(({dispatch, location, debug = process.env.REACT_APP_LOCAL_DEBUG}) => {
  if (utils.isServer()) {
    return dispatch(actions.getDealersData(location.pathname));
  }

  // Live reload on page change
  if (actions.shouldGetData(location)) {
    return dispatch(actions.getDealersData(location.pathname));
  }

  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  if (justFirsTime() && debug) {
    return dispatch(actions.getDealersData(location.pathname));
  }

}, {
  onUpdate: true,
  onMount: true
})(BoatDealers);

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(frontloadConnected));

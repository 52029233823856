import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withCookies } from 'react-cookie';
import DefaultLayout from '../../layouts/Default';
import Status from '../../components/Status';
import BreadCrumb from '../../components/BreadCrumb';

/* eslint-disable react/prefer-stateless-function */
class NotFound extends React.PureComponent {
  render() {
    return (
      <DefaultLayout {...this.props} pageType="NotFound">
        <Status {...this.props} message="404" />
        <BreadCrumb items={[{
          title: 'Home',
          link: '/'
        },{
          title: 'Page Not Found'
        }]} />
        <div id="main-content" className="error-msg">
          <h1 className="h1-notfound">WE&apos;RE SORRY. WE CANNOT FIND THE PAGE YOU REQUESTED.</h1>
          <div>
            <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/btol-error-img.jpg`} alt="Page Not Found Error" className="error" />
            <h4>THIS PAGE MAY HAVE MOVED OR IS NO LONGER AVAILABLE.</h4>
            Please try one of the options below:
            <ul>
              <li>
                <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/btol-more-right-on.gif`} alt="Page Not Found Error" />
                Check our <a href="/">homepage</a> to find what you&apos;re looking for and reset your bookmark.
              </li>
              <li>
                <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/btol-more-right-on.gif`} alt="Page Not Found Error" />
                Make sure the web address used to get here is correct.
              </li>
              <li>
                <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/btol-more-right-on.gif`} alt="Page Not Found Error" />
                Follow the links below to the main areas of our site:
                <ul>
                  <li>
                    <a href="/boats/">Find Boats</a> &gt; Search our listings
                  </li>
                  <li>
                    <a href="/sell/">Sell</a> &gt; Place a classifieds listing or edit your as listing.
                  </li>
                  <li>
                    <a href="/services/">Services</a> &gt; Useful tools, news-filled blogs, and helpful resources.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    params: get(state.app, 'params', {}),
    search: get(state.app, 'data.search', {}),
    sponsored: get(state.app, 'data.sponsored', {}),
    facets: get(state.app, 'data.facets', {}),
    isWorking: state.app.isWorking,
    componentWorking: state.app.componentWorking,
    success: state.app.success,
    errors: state.app.errors,
    message: state.app.message,
    user: state.app.user,
    myboats: state.app.myboats
  };
};

export default withCookies(connect(mapStateToProps, null)(NotFound));

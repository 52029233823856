import React, { PureComponent, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';

// Stylesheets
import './styles.css';

export const getImageSrc = (blogId, imgName) => {
  let imageName = imgName || '';
  if (imageName.includes('/files/')) {
    imageName = imageName.substr(imageName.indexOf('files/'));
  }
  return `${process.env.REACT_APP_IMG_HOST}/resize/wp/${blogId}/${imageName}?w=450&h=450`;
};

export const ImageWithErrorHandler = ({ src, alt }) => {
  const [error, setError] = useState(false);
  return error ? null : <LazyLoadImage src={src} alt={alt} onError={() => setError(true)} />;
};

class RelatedBoatArticles extends PureComponent {
  render() {
    const { isLoading, isFirstPage, relatedBoatArticles } = this.props;

    if (!isFirstPage) {
      return null;
    }
    if (isLoading) {
      return null;
    }
    if (!relatedBoatArticles || relatedBoatArticles.length === 0) {
      return null;
    }

    return (
      <div data-test-sp="srp-related-articles" className="related__articles__container">
        <div className="related__articles__wrapper">
          {relatedBoatArticles.map((article) => (
            <div key={article.id} className="related__article__item">
              <a
                href={article.link}
                target="_blank"
                rel="noreferrer"
                data-reporting-click-internal-link-type="related boat article"
                data-reporting-click-internal-link-id={article.link}
              >
                <div className="related__article__item_image_wrapper">
                  <ImageWithErrorHandler src={getImageSrc(article.blogId, article.image)} alt={article.imageAlt || article.title} />
                </div>
                <p>Reviews</p>
                <h4>
                  {article.title}
                </h4>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

RelatedBoatArticles.propTypes = {
  isLoading: PropTypes.bool,
  isFirstPage: PropTypes.bool,
  relatedBoatArticles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      blogId: PropTypes.string
    })
  )
};

export default RelatedBoatArticles;

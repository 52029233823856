import { isValidUsZipCode } from '@dmm/lib-common/lib/validation';
import { Action } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import React, { useState } from 'react';
import closeBtn from '../close-btn.svg';

const LocatorHeader = ({closeModal, oemTerm, fetchDealers, postalCode}) => {
  const [validZipCode, setValidZipCode] = useState(true);

  const CloseBtn = () => <button className="close-btn" onClick={closeModal}><img src={closeBtn}/><span className="visuallyhidden">Close Modal</span></button>;

  const handleChange = e => {
    const { value: zipCode } = e.target;
    setValidZipCode( isValidUsZipCode(zipCode));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postalCode = get(e, 'target.postalCode.value', '').trim();
    try {
      if (validZipCode) {
        await fetchDealers(postalCode);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <header className="locator-header" data-e2e="locator-header">
      <h2 data-e2e="header">Find a {oemTerm?.()} Dealer</h2>
      <CloseBtn />
      <form className="search-form" data-e2e="search-form" onSubmit={handleSubmit}>
        <input
          placeholder="ZIP Code (US only)"
          type="text"
          name="postalCode"
          data-e2e="us-postalCode"
          onChange = {handleChange}
          defaultValue={postalCode}
        />
        <Action className="search-form-button" data-e2e="search-form-button" label="Search" stretch/>
        {!validZipCode && <ul className="zipcode-message-error" data-e2e="zipcode-message-error">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 -3 22 15">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
            </svg>Enter a 5 digit US zip code
          </li><i className="fas fa-circle" /></ul>}
      </form>
    </header>
  );
};

export default LocatorHeader;

import * as httpClient from '../httpClient';


/* This function check if the Captcha has been passed successfully. It can return 3 different options:
 1- If the captcha has been passed successfully or there has been an error passing the Captcha it will return true.
 2- If the captcha has not been passed successfully, it will return false.
*/
export const passCaptchaChallenge = async (leadData, captchaAction, executeRecaptcha) => {
  let captchaToken;
  try {
    captchaToken = await executeRecaptcha(captchaAction);
    leadData.captchaData = { token: captchaToken, expectedAction: captchaAction };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('There was an error retrieving a recaptcha token');
    return err;
  }
  if (!captchaToken) {
    return true;
  }
  try {
    let captchaResponse = await verify(captchaToken, captchaAction, leadData.formFields.email);
    if (captchaResponse.status === 200) {
      const { data } = captchaResponse;
      const { score: captchaScore, domainCheck: captchaDomain } = data;
      captchaToken = await executeRecaptcha(captchaAction);
      leadData.captchaData = { token: captchaToken, expectedAction: captchaAction };
      if (captchaScore <= 0.7 || captchaDomain) {
        return false;
      }
      return true;
    }
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('There was an error verifying and scoring the recaptcha token', err);
    return true;
  }
};

export const verify = (token, expectedAction, email) => {
  return httpClient.getSecureProxyHttpClient().post('/app/verify-recaptcha', { token, expectedAction, email });
};

import React, {useEffect, useState} from 'react';
import './styles.css';
import DefaultLayout from '../../layouts/Default';
import {Form, getSession} from '@dmm/lib-react-driveway-direct';
import '@dmm/lib-react-driveway-direct/dist/@dmm/lib-react-driveway-direct.min.css';
import formData from './formData.json';
import {Helmet} from 'react-helmet';
import {withCookies} from 'react-cookie';

import {questions} from './structured_data-faq.json';
import {useDispatch} from 'react-redux';
import {drivewayDirectClickEvent} from '../../store/actions/dataLayer';
import set from 'lodash/set';
import {isServer} from '../../store/utils';

import DrivewayDirectProcess from './DrivewayDirectProcess';

const DrivewayDirect = (props) => {
  const dispatch = useDispatch();
  const [showStaticData, setShowStaticData] = useState(true);
  const [session, setSession] = useState();

  const getTrackingEvent = (category, action, drivewayId) => {
    drivewayDirectClickEvent(dispatch && dispatch, category, action, drivewayId);
  };

  const getStructuredData = () => {
    const formattedQuestions = questions.map(({question, answer}) => (
      {
        '@type': 'Question',
        'name': question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': answer
        }
      }
    ));

    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      'mainEntity': formattedQuestions
    });
  };

  useEffect(() => {
    set(window.digitalData, 'page.category.pageName', '/driveway direct');
    set(window.digitalData, 'page.category.primaryCategory', 'seller');
    set(window.digitalData, 'page.category.subSection', 'driveway direct');
    set(window.digitalData, 'page.pageInfo.pageName', 'driveway direct');
    if (!isServer()) {
      let ddSession = getSession();
      setSession(ddSession);
    }
  }, []);

  useEffect(() => {
    if (session && session.isSession) {
      setShowStaticData(false);
    } else {
      setShowStaticData(true);
    }
  }, [session]);

  return (
    <DefaultLayout {...props} pageType="DrivewayDirect">
      <Helmet>
        <script type="application/ld+json">{getStructuredData()}</script>
      </Helmet>
      <div className={`drivewayDirect-head ${!showStaticData && 'funnel-head'}`}>
        {showStaticData &&
          <>
            <div className={'image-background'}
              style={{backgroundImage: 'url(' + process.env.REACT_APP_CDN_URL + '/services/DD-home-hero.jpg' + ')'}}/>
            <div className={'container-text'}>
              <img className={'logo'} src={process.env.REACT_APP_CDN_URL + '/services/Driveway-Direct-Logo.png'} alt=""/>
              <h1>Get a Fast Cash Offer</h1>
              <p>Get an offer directly from your local dealer! With Boat Trader’s strong network of local dealers, you’ll sell your boat fast.</p>
            </div>
          </>
        }
        <Form
          {...{
            ...formData,
            funnelMasterComponentProps: {
              ...formData.funnelMasterComponentProps,
              detailsProps: {
                ...formData.funnelMasterComponentProps.detailsProps,
                imageUploadEndpoint: process.env.REACT_APP_DRIVEWAY_DIRECT_IMAGE_UPLOAD_ENDPOINT,
                locationsEndpoint: process.env.REACT_APP_DRIVEWAY_DIRECT_LOCATIONS_ENDPOINT,
              }
            }
          }}
          endpoints={{
            leadEndpoint: process.env.REACT_APP_DRIVEWAY_DIRECT_ENDPOINT,
            leadCreationEndpoint: process.env.REACT_APP_DRIVEWAY_DIRECT_LEAD_ENDPOINT,
            makesEndpoint: process.env.REACT_APP_DRIVEWAY_DIRECT_MAKES_ENDPOINT,
            modelsEndpoint: process.env.REACT_APP_DRIVEWAY_DIRECT_MODELS_ENDPOINT,
            engineModelsEndpoint: process.env.REACT_APP_DRIVEWAY_DIRECT_ENGINE_MODELS_ENDPOINT
          }}
          trackingEvent={getTrackingEvent}
          funnelMode={true}
          showStaticData={setShowStaticData}
          session={session}
        />
      </div>
      { showStaticData &&
        <DrivewayDirectProcess
          getTrackingEvent={getTrackingEvent}
          questions={questions}/>
      }
    </DefaultLayout>
  );
};

export default withCookies(DrivewayDirect);

import React, { memo } from 'react';
import './styles.css';

const DataDetails = ({ data, isPricing = false, isSubLevel = false }) => (
  <section className="data-details-wrapper">
    {isSubLevel && (
      data.map((row) => (
        <div key={row.title} className="data-details-cell">
          <h4>{row.title}</h4>
          <div className="data-details-cell-content">
            {row.value.map((prop) => (
              <p key={`${prop.title}-content`}>
                {prop.title && <span className={`${isPricing ? 'cell-content-title ' : null}`}>{prop.title}:</span>}
                {prop.subtitle && (
                  <span className="cell-content-subtitle">
                    {prop.subtitle}
                  </span>
                )}
                <span className="cell-content-value">{prop.value}</span>
              </p>
            ))}
          </div>
        </div>
      ))
    )}

    {!isSubLevel && (
      <div className="data-details-cell">
        <div className="data-details-cell-content">
          {data.map((prop) => (
            <p key={`${prop.title}-content`}>
              {prop.title && <span className={`${isPricing ? 'cell-content-title ' : null}`}>{prop.title}:</span>}
              {prop.subtitle && (
                <span className="cell-content-subtitle">
                  {prop.subtitle}
                </span>
              )}
              <span className="cell-content-value">{prop.value}</span>
            </p>
          ))}
        </div>
      </div>
    )}
  </section>
);

export default memo(DataDetails);

import * as types from '../constants/dataLayer';
import { setSearchData, getSearchLabel, getConnectionClickData } from '../../utils/dataLayerHelper';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import {safeWindow} from '../../utils/commonHelper';

const init = (params) => {
  return {
    type: types.DATALAYER_INIT,
    params
  };
};

const failure = (message) => {
  return {
    type: types.DATALAYER_FAILURE,
    message
  };
};

const registerEvent = (eventType, params) => {
  return {
    type: types.DATALAYER_EVENT,
    eventType,
    params
  };
};

const exitLinkImpression = (id, name, position) => {
  return {
    type: types.DATALAYER_EXIT_LINK_IMPRESSION,
    id,
    name,
    position
  };
};

const externalLinkImpression = (id, name, position) => {
  return {
    type: types.DATALAYER_EXTERNAL_LINK_IMPRESSION,
    id,
    name,
    position
  };
};

const productImpression = (imtId, listingType, position, page) => {
  return {
    type: types.DATALAYER_PRODUCT_IMPRESSION,
    imtId,
    listingType,
    position,
    page
  };
};

const oemBrandProductImpression = (imtId, listingType, category, page) => {
  return {
    type: types.DATALAYER_PRODUCT_IMPRESSION,
    imtId,
    listingType,
    category,
    page
  };
};

const product = (productId, boatClass, boatMake, boatLength, state) => {
  return {
    type: types.DATALAYER_PRODUCT_ADD,
    productId,
    boatClass,
    boatMake,
    boatLength,
    state
  };
};

const productClick = (productId, listingType, rank, page) => {
  return {
    type: types.DATALAYER_PRODUCT_CLICK,
    productId,
    listingType,
    rank,
    page
  };
};

const lead = (imtId, leadType) => {
  return {
    type: types.DATALAYER_ADD_LEAD,
    imtId,
    leadType
  };
};

const connectionClick = (imtId, leadType) => {
  return {
    type: types.DATALAYER_CONNECTION_CLICK,
    imtId,
    leadType
  };
};

const ListingView = (imtId) => {
  return {
    type: types.DATALAYER_LISTING_VIEW,
    imtId
  };
};

export const initDataLayer = params => dispatch => {
  const {
    description,
    country,
    portalName,
    section,
    subSection,
    pageName,
    detailedPageName,
    primaryCategory,
    searchResultsCount
  } = params;

  try {
    get(window, 'google_tag_manager[\'GTM-K9W7CR\'].dataLayer.reset', () => {})();
    window.digitalData = window.digitalData || {};
    //Reset
    set(window.digitalData, 'page.attributes.productImpressions', []);
    set(window.digitalData, 'page.attributes.internalPromotionImpressions', []);
    set(window.digitalData, 'page.attributes.externalLinkImpressions', []);
    set(window.digitalData, 'page.attributes.exitLinkImpressions', []);
    set(window.digitalData, 'page.attributes.productClicks', []);
    set(window.digitalData, 'page.attributes.internalPromotionClicks', []);
    set(window.digitalData, 'page.attributes.externalLinkClicks', []);
    set(window.digitalData, 'page.attributes.exitLinkClicks', []);
    set(window.digitalData, 'user', []);
    set(window.digitalData, 'events', []);

    //Init
    window.digitalDataBuilder
      .init()
      .set('Site Description', description)
      .set('Site Country', country)
      .set('Portal Name', portalName)
      .set('Section', section)
      .set('Sub-Section', subSection)
      .set('Page Name', pageName)
      .set('Detailed Page Name', detailedPageName)
      .set('Primary Category', primaryCategory)
      .set('BG Source', 'react');
    if (searchResultsCount) {
      window.digitalDataBuilder.set('Page Search Results Count', searchResultsCount);
    }
    dispatch(init(params));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

// no point in dealing with redux at all...
export const digitalDataBuilderSet = (key, value) =>  {
  const theWindow = safeWindow();
  if (!key || !value || !theWindow.digitalDataBuilder?.set) {
    return;
  }
  return theWindow.digitalDataBuilder.set(key, value);
};

export const registerProductImpressions = () => dispatch => {
  setTimeout(() => {
    try {
      if (!window.digitalDataUpdates?.addProductImpression.length) {
        return;
      }
      let newDigitalData = JSON.parse(JSON.stringify(window.digitalData));
      newDigitalData.event = 'view_item_list';
      newDigitalData.ecommerce.items = [ ...window.digitalDataUpdates.addProductImpression ];
      window.dataLayer.push(newDigitalData);
      window.digitalDataUpdates.addProductImpression.length = 0;
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 1000);
};

export const registerPageView = params => dispatch => {
  setTimeout(() => {
    try {
      window.dataLayer.push(window.digitalData);
      window.dataLayer.push({'event': 'pageview'});
      dispatch(registerEvent('pageview', params));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 0);
  setTimeout(() => {
    try {
      window.digitalDataBuilder.addPageView();
      window.kameleoonHelper.kameleoonProcessGA4();
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 1000);
};

export const registerDownloadApp = (label) => dispatch => {
  setTimeout(() => {
    try {
      const event = {
        event: 'e_downloadApp',
        category: 'download_app',
        action: 'Boat Trader',
        label: label
      };
      window.dataLayer.push(event);
      dispatch(registerEvent('e_downloadApp', event));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }, 0);
};

export const setExitLinkImpression = (id, name, position) => dispatch => {
  try {
    window.digitalDataBuilder.setExitLinkImpression(id, name, position);
    dispatch(exitLinkImpression(id, name, position));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setExternalLinkImpression = (id, name, position, rank = '', page = '') => dispatch => {
  try {
    window.digitalDataBuilder.setExternalLinkImpression(id, name, position, rank, page);
    dispatch(externalLinkImpression(id, name, position, rank, page));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setListingClick = (id, listingType, location, model, title, position, make) => {
  try {
    window.digitalDataBuilder.addProductClick(id, listingType, '', '', '', location, model, title, position, make);
  }
  catch (err) {
    // This line was needed, console.error wasn't allowed by the linter, and no empty blocks are allowed.
  }
};

// position is rank and category undefined will be 'boat'
export const setProductImpression = (imtId, listingType, position, page, container, region, category = undefined,
  enhancementTriggerContainer = undefined, itemName, itemBrand) => dispatch => {
  try {
    window.digitalDataBuilder.setProductImpression(imtId, listingType, category, position, page, container, region, enhancementTriggerContainer, itemName, itemBrand);
    dispatch(productImpression(imtId, listingType, position, page));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setOemBrandProductImpression = (partyId, listingType, category, page) => dispatch => {
  try {
    window.digitalDataBuilder.setProductImpression(partyId, listingType, category, undefined, page);
    dispatch(oemBrandProductImpression(partyId, listingType, category, page));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setProduct = (listing) => dispatch => {
  try {
    let convertedClass = window.digitalDataBuilderBoatTraderMapper.convertClass(listing.productClass);
    listing.productClass = convertedClass;
    window.digitalData.product = [];
    window.digitalDataBuilder.setProduct(listing);
    dispatch(product(listing.id, listing.productClass, listing.manufacturer, listing.length, listing.state));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addProduct = (productId, boatClass, boatMake, boatLength, state) => dispatch => {
  try {
    let convertedClass = window.digitalDataBuilderBoatTraderMapper.convertClass(boatClass);
    window.digitalDataBuilder.addProduct(productId, convertedClass, boatMake, boatLength, state);
    dispatch(product(productId, boatClass, boatMake, boatLength, state));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addProductClick = (productId, listingType, page, container, region, enhancementTriggerContainer = undefined, title = '', position = -1, make = '') => dispatch => {
  try {
    window.digitalDataBuilder.addProductClick(productId, listingType, undefined, page, container, region, enhancementTriggerContainer, title, position, make);
    dispatch(productClick(productId, listingType, undefined, page));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addSaveBoat = () => {
  window.digitalDataBuilder.addSaveBoat();
};

export const setListingView = (productId) => dispatch => {
  try {
    window.digitalDataBuilder.addListingView(productId);
    dispatch(ListingView(productId));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addLead = (imtId, leadType, uniqueId, clickLocation) => dispatch => {
  try {
    window.digitalDataBuilder.addLead(imtId, leadType, clickLocation ,uniqueId);
    let connectionClickData = getConnectionClickData(imtId, leadType);
    window.digitalDataBuilder.addConnectionClick(connectionClickData);
    dispatch(lead(imtId, leadType));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setConnectionClick = (imtId, leadType) => dispatch => {
  try {
    let connectionClickData = getConnectionClickData(imtId, leadType);
    window.digitalDataBuilder.addConnectionClick(connectionClickData);
    dispatch(connectionClick(imtId, leadType));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const searchTracking = (data) => {
  return {
    type: types.DATALAYER_SEARCH_FILTER,
    data: data
  };
};

export const setSearchTracking = (data, searchType = 'single', action) => dispatch =>{
  if (!isEmpty(data)) {
    let trackData = setSearchData(data, searchType);
    trackData.label = getSearchLabel(trackData);
    if (action) {
      trackData.action = action;
    }
    try {
      window.digitalDataBuilder.tagManager.track('SEARCH_FILTER', trackData);
      dispatch(searchTracking(trackData));
    } catch (e) {
      dispatch(failure(e.toString()));
    }
  }
};

const genericEvent = (data) => {
  return {
    type: types.DATALAYER_GENERIC_EVENT,
    data: data
  };
};

export const setGenericEvent = (category, action, label, value, custom) => dispatch => {
  let event = { category, action, label, value, custom };
  try {
    window.digitalDataBuilder.addGenericEvent(event);
    dispatch(genericEvent(event));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setAiEvent = (keywords, numberOfResults, searchType) => dispatch => {
  const event = {
    keywords: keywords,
    numberOfResults: numberOfResults,
    searchType: searchType
  };
  try {
    window.digitalDataBuilder.addAiEvent(event);
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const collapseTracking = (data) => {
  return {
    type: types.DATALAYER_COLLAPSE_TRACKING,
    data: data
  };
};

export const setCollapseContentTracking = (data) => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('COLLAPSE_CONTENT', {'content': data});
    dispatch(collapseTracking(data));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const changeCarouselImageTracking = () => {
  return {
    type: types.DATALAYER_CAROUSEL_CHANGE_ITEM
  };
};


export const changeCarouselImage = () => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('CAROUSEL_NAVIGATION');
    dispatch(changeCarouselImageTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const clickCarouselThumbnailTracking = () => {
  return {
    type: types.DATALAYER_CAROUSEL_THUMBNAIL_CLICK
  };
};

export const clickCarouselThumbnail = () => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('CAROUSEL_THUMBNAIL');
    dispatch(clickCarouselThumbnailTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const openCarouselModalTracking = () => {
  return {
    type: types.DATALAYER_CAROUSEL_THUMBNAIL_CLICK
  };
};

export const openCarouselModal = () => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('CAROUSEL_MODAL_OPEN');
    dispatch(openCarouselModalTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const contactFormSubmitTracking = () => {
  return {
    type: types.DATALAYER_CONTACTFORM_SUBMIT
  };
};

export const trackContactFormSubmit = (data) => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('CONTACTFORM_SUBMIT', data);
    dispatch(contactFormSubmitTracking());
    window.digitalDataBuilder.addEmailLead();
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const trackMultiContactFormSubmit = (data) => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('MULTI_CONTACTFORM_SUBMIT', data);
    dispatch(contactFormSubmitTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const contactFormOpenTracking = () => {
  return {
    type: types.DATALAYER_CONTACTFORM_OPEN
  };
};

export const trackContactFormOpen = () => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('CONTACTFORM_OPEN');
    dispatch(contactFormOpenTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const phoneCallTracking = () => {
  return {
    type: types.DATALAYER_PHONE_CALL
  };
};

export const trackPhoneCall = () => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('PHONE_CALL');
    dispatch(phoneCallTracking());
    window.digitalDataBuilder.addPhoneLead();
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const phoneNumberReveal = () => {
  return {
    type: types.DATALAYER_PHONE_REVEAL
  };
};

export const trackPhoneNumberReveal = () => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('PHONE_REVEAL');
    dispatch(phoneNumberReveal());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const socialMediaTracking = () => {
  return {
    type: types.DATALAYER_SOCIAL_MEDIA
  };
};

export const trackSocialMedia = (socialNetwork, socialAction, socialTarget) => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('SOCIAL_MEDIA', {
      socialNetwork,
      socialAction,
      socialTarget
    });
    dispatch(socialMediaTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setModalEvent = (action, label, category) => dispatch => {
  const event = { eventName: 'e_modalAction', action, label, category };
  try {
    window.digitalDataBuilder.addGenericEvent(event);
    dispatch(modalEvent(event));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const modalEvent = data => ({
  type: types.DATALAYER_MODAL_EVENT,
  data: data
});

const drivewayDirectEvent = data => ({
  type: types.DATALAYER_DRIVEWAY_DIRECT_EVENT,
  data: data
});

export const drivewayDirectClickEvent = (dispatch, category, action, drivewayDirectLeadID) => {
  const event = {
    eventName: 'e_drivewayDirectClick',
    category,
    action,
  };
  if (drivewayDirectLeadID){
    event.custom = `drivewayDirectLeadID,${drivewayDirectLeadID}`;
  }
  try {
    window.digitalDataBuilder.addGenericEvent(event);
    dispatch(registerEvent('e_drivewayDirectClick', drivewayDirectEvent(event)));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const setSoftLeadEvent = (uniqueId, productType, productId) => dispatch => {
  try {
    window.digitalDataBuilder.addSoftLead(uniqueId, productType, productId);
    dispatch(softLeadEvent(uniqueId));
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

const softLeadEvent = data => ({
  type: types.DATALAYER_SOFT_LEAD_EVENT,
  data: data
});

const searchFormTracking = () => {
  return {
    type: types.DATALAYER_SEARCH_FORM
  };
};
export const trackSearchForm = (data) => dispatch => {
  try {
    window.digitalDataBuilder.tagManager.track('SEARCH', data);
    dispatch(searchFormTracking());
  } catch (e) {
    dispatch(failure(e.toString()));
  }
};

export const addFinanceAdvantageEmailLead = () => {
  window.digitalDataBuilder.addFinanceAdvantageEmailLead();
};

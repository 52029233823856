import React, { useEffect } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

let executeRecaptcha = null;

const ReCaptchaButtonChild = ({ children }) => {
  const { executeRecaptcha: googleExecuteRecaptcha } = useGoogleReCaptcha();
  useEffect(() => {
    executeRecaptcha = googleExecuteRecaptcha;
  }, [googleExecuteRecaptcha]);
  return (
    <>
      {children}
    </>
  );
};

const ReCaptchaButtonWrapper = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_ANTISPAM} useEnterprise={true}>
      <ReCaptchaButtonChild>
        {children}
      </ReCaptchaButtonChild>
    </GoogleReCaptchaProvider>
  );
};

export {
  ReCaptchaButtonWrapper,
  executeRecaptcha
};

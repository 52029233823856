import React, {useContext} from 'react';
import {GetPreQualifiedModal} from '../../../components/GetPreQualified';
import {BDPContext} from '../MainContent/BoatDetailsPage';

const BDPPreQualifiedModal = ({onClose, isMobile, preQualifiedState}) => {
  const {listing, tridentValues} = useContext(BDPContext);
  const {leadPage, leadType, trackingEvent, tridentABTest} = preQualifiedState;
  const {tridentTeaserRate, monthlyPrice, ficoScores} = tridentValues;

  return (
    <GetPreQualifiedModal
      onClose={onClose}
      isMobile={isMobile}
      interestRate={tridentTeaserRate}
      ficoScores={ficoScores}
      monthlyPrice={monthlyPrice}
      listing={listing}
      leadPage={leadPage}
      leadType={leadType}
      trackingEvent={trackingEvent}
      abTest={tridentABTest}
    />
  );
};

const BDPGetPreQualifiedModal = (props) => {
  const showModalPreQualified = props?.preQualifiedState?.showModalPreQualified;
  return showModalPreQualified ? <BDPPreQualifiedModal {...props}  /> : null;
};

export default BDPGetPreQualifiedModal;


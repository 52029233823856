import React, { Fragment, useMemo, useState } from 'react';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import Spinner from '../../Spinner';
import './styles.css';

const Modal = ({ children, onClose, onCalculateRoute, onInputChange, sourceLocation }) => (
  <div className="map-modal-wrapper">
    <section className="map-modal-content">
      {children}
      <span className="map-modal-close-btn" onClick={onClose}>Close Map</span>
      <div className="map-modal-input-route">
        <input
          aria-label="source place"
          onChange={onInputChange}
          value={sourceLocation}
          type="text"
        />
        <button
          onClick={onCalculateRoute}
        >
          Calculate Route
        </button>
      </div>
    </section>
  </div>
);

const mapContainerStyle = {
  height: 'inherit',
  position: 'relative',
  width: 'inherit',
};

const MapContainer = ({
  address,
  activeMarker,
  coordinates,
  google,
  isInsideModal = false,
  showInfoWindow,
  setMapToState,
  setActiveMarker,
  setShowInfoWindow,
  disableDefaultUI
}) => (
  <Map
    id="boat-location-map"
    disableDefaultUI={disableDefaultUI}
    google={google}
    initialCenter={coordinates}
    title="map showing the location of the boat"
    zoom={14}
    {...!isInsideModal && {
      containerStyle: mapContainerStyle,
      style: { borderRadius: '8px' }
    }}
    {...isInsideModal && { onReady: setMapToState }}
  >
    <Marker
      id="boat-location-marker"
      name="Current boat location"
      icon= {{
        url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
        alt: 'Current boat location'
      }}
      onClick={(_, marker) => {
        setActiveMarker(marker);
        setShowInfoWindow();
      }}
    />
    <InfoWindow
      marker={activeMarker}
      visible={showInfoWindow}
    >
      <p>{address}</p>
    </InfoWindow>
  </Map>
);

const MapOnly = ({ address, coordinates, google }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [sourceLocation, setSourceLocation] = useState('');
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const directions = useMemo(() => ({
    renderer: new google.maps.DirectionsRenderer(),
    service: new google.maps.DirectionsService(),
  }), []);

  const calculateAndDisplayRoute = () => {
    const { service, renderer } = directions;
    const coordinatesBoat = coordinates;

    service.route(
      {
        origin: { query: sourceLocation },
        destination: { query: `${coordinatesBoat.lat},${coordinatesBoat.lng}` },
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          renderer.setDirections(response);
        } else {
          window.alert(`Directions request failed due to ${status}`);
        }
      }
    );
  };

  return (
    <Fragment>
      <div className="map-container" onClick={() => setIsModalOpen(true)}>
        {!isModalOpen && (
          <MapContainer
            address={address}
            activeMarker={activeMarker}
            coordinates={coordinates}
            google={google}
            showInfoWindow={showInfoWindow}
            setActiveMarker={setActiveMarker}
            setShowInfoWindow={() => setShowInfoWindow(true)}
            disableDefaultUI={true}
          />
        )}
      </div>

      {isModalOpen && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          onCalculateRoute={calculateAndDisplayRoute}
          onInputChange={(e) => setSourceLocation(e.target.value)}
          sourceLocation={sourceLocation}
        >
          <MapContainer
            address={address}
            activeMarker={activeMarker}
            coordinates={coordinates}
            google={google}
            isInsideModal
            showInfoWindow={showInfoWindow}
            setActiveMarker={setActiveMarker}
            setMapToState={(_, map) => directions.renderer.setMap(map)}
            setShowInfoWindow={() => setShowInfoWindow(true)}
            disableDefaultUI={false}
          />
        </Modal>
      )}
    </Fragment>
  );
};

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_GOOGLE_MAPS_KEY),
  LoadingContainer: Spinner
})(MapOnly);

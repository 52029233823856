import {normalizeString} from '@dmm/lib-common/lib/formatting';
import {US_STATES} from '../constants/boats';
import * as httpClient from './httpClient';
import {getDealerSearchUrl} from './urlHelpers/boatDealers';

const generateDealerAddress = (street, city, countrySubDivisionCode) => {
  return [street, city, countrySubDivisionCode]
    .filter(stringPart => !!stringPart)
    .join(', ');
};

const dealerUrl = (name, id) => {
  if (!name || !id) {
    return '';
  }
  const dealerName = normalizeString(name);
  return `/boats/dealer-${dealerName}-${id}/`;
};

const safeWindowSize = (layout = 'mobile') => {
  if (typeof window !== 'undefined') {
    return window;
  }
  return layout === 'mobile' ? { innerWidth: 320, innerHeight: 480 } : { innerWidth: 1366, innerHeight: 768 };
};

const getSubdivisionCode = state => US_STATES.find(usState => usState.name === state)?.value?.toLowerCase() || '';

const getSubdivisioName = stateCode => US_STATES.find(usState => usState.value === stateCode)?.name || '';

const fetchCitiesFromState = async (state, make) => {
  const countrySubdivision = getSubdivisionCode(state);
  const normalizedMake = normalizeString(make);
  if (!countrySubdivision) {
    return [];
  }
  const http = httpClient.getSecureProxyHttpClient();
  let url = `/app/boat-dealers/cities-from-state?countrySubDivisionCode=${countrySubdivision.toLowerCase()}`;
  if (normalizedMake) {
    url = `${url}&make=${normalizedMake}`;
  }
  const result = await http.get(url);
  return result?.data?.cities?.map(city => city.key).sort() || [];
};

const fetchZipsFromCity = async (state, city, make) => {
  const countrySubdivision =  getSubdivisionCode(state);
  const normalizedMake = normalizeString(make);
  if (!countrySubdivision) {
    return [];
  }
  const http = httpClient.getSecureProxyHttpClient();
  let url = `/app/boat-dealers/zips-from-city?countrySubDivisionCode=${countrySubdivision.toLowerCase()}&city=${normalizeString(city)}`;
  if (normalizedMake) {
    url = `${url}&make=${normalizedMake}`;
  }
  const result = await http.get(url);
  return result?.data?.zips?.map(zip => zip.key).sort() || [];
};

const fetchStatesFromMake = async (make) => {
  const normalizedMake = normalizeString(make);
  const http = httpClient.getSecureProxyHttpClient();
  let url = '/app/boat-dealers/dealers?';
  if (normalizedMake) {
    url = `${url}&make=${normalizedMake}`;
  }
  const result = await http.get(url);
  const states = result?.data?.states?.map(state => state.key) || [];
  return states.map(key => getSubdivisioName(key)).sort();
};

const urlFromDealerFilters = filters => {
  const params = {
    name: normalizeString(filters?.keyword),
    make: normalizeString(filters?.brand),
    countrySubDivisionCode: getSubdivisionCode(filters?.state),
    city: normalizeString(filters?.city),
    postalCode: filters?.zip,
    page: 1,
    pageSize: 8
  };
  Object.keys(params).forEach(key => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return getDealerSearchUrl(params);
};

const getDealersSRPBreadCrumb = (params) => {
  const breadCrumbs = [
    {title: 'Home', link: '/'},
    {title: 'Dealers', link: '/boat-dealers/'}
  ];
  const urlParams = {};
  let link;
  if (params.name) {
    urlParams.keyword = params.name;
  }
  if (params.make) {
    urlParams.brand = params.make;
    link = params.countrySubDivisionCode ? urlFromDealerFilters(urlParams) : undefined;
    const title = params.seoMakeInfo && params.seoMakeInfo.seoMakeName ? params.seoMakeInfo.seoMakeName : params.make;
    breadCrumbs.push({title: title, link: link});
  }
  if (params.countrySubDivisionCode) {
    urlParams.state = params.countrySubDivisionCode;
    link = params.city ? urlFromDealerFilters(urlParams) : undefined;
    breadCrumbs.push({title: params.countrySubDivisionCode, link: link});
    if (params.city) {
      urlParams.city = params.city;
      link = params.postalCode ? urlFromDealerFilters(urlParams) : undefined;
      breadCrumbs.push({title: params.city, link: link});
      if (params.postalCode) {
        urlParams.zip = params.postalCode;
        breadCrumbs.push({title: params.postalCode, link: undefined});
      }
    }
  }
  return {breadCrumbs};
};

const pluralize = (word, count = 0, suffix = 's') => {
  const plural = count > 1 ? suffix : '';
  return `${word}${plural}`;
};

export {
  generateDealerAddress, dealerUrl, safeWindowSize, fetchCitiesFromState, fetchZipsFromCity, urlFromDealerFilters,
  getDealersSRPBreadCrumb, pluralize, fetchStatesFromMake, getSubdivisionCode, getSubdivisioName
};

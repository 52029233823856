import React from 'react';
import './styles.css';
import {frontloadConnect} from 'react-frontload';
import {withCookies} from 'react-cookie';
import { connect } from 'react-redux';
import ErrorMessage from '../../components/ErrorMessage';
import DefaultLayout from '../../layouts/Default';
import MainContent from './MainContent';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';
import get from 'lodash/get';
import {runOnce} from '../../utils/runOnceHelper';

const BoatTypes = (props) => {
  return (
    <DefaultLayout {...props} pageType="BoatTypes" id={'boat_types'}>
      {
        props?.errors ?
          <ErrorMessage {...props} /> :
          <MainContent {...props} />
      }
    </DefaultLayout>
  );
};

const mapStateToProps = state => {
  return {
    records: get(state.app, 'data.records', {}),
    errors: state.app.errors,
    message: state.app.message,
    statusCode: state.app.statusCode,
  };
};

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();

// eslint-disable-next-line no-empty-pattern
const frontLoadConnected = frontloadConnect(async ({ dispatch, location, debug = process.env.REACT_APP_LOCAL_DEBUG }) => {
  if (utils.isServer()) {
    // dispatch action to get boat types data
    return dispatch(actions.getBoatTypes());
  }

  if (actions.shouldGetData(location)) {
    // dispatch action to get boat types data
    return dispatch(actions.getBoatTypes());
  }

  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  if (justFirsTime() && debug) {
    return dispatch(actions.getBoatTypes());
  }

}, {
  onMount: true,
  onUpdate: true
})(BoatTypes);

export default withCookies(connect(mapStateToProps)(frontLoadConnected));
